import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CopyIcon from '../icons/CopyIcon';
import Action from '../atoms/Action';
import DropDown from '../atoms/DropDown';
import Dialog from './Dialog';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_ORANGE, COLOR_TEXT } from '../styles/colors';
import SearchesFolder from '../types/SearchesFolder';
import PlayersFolder from '../types/PlayersFolder';
import MEDIA from '../styles/media';

interface CopyToFolderProps {
  className?: string;
  folders: Array<SearchesFolder | PlayersFolder>;
  disabled?: boolean;
  onCopy: (folder:SearchesFolder | PlayersFolder) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  copyToFolder: {
    padding: theme.spacing(0, 1.5),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $text': {
        color: COLOR_BLUE,
      },
    },
  },
  disabled: {
    outlineColor: 'transparent',

    '& $text': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $text': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  text: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  dropDown: {},
  dropDownSelect: {
    height: '40px',
  },

  [MEDIA.MOBILE]: {
    icon: {
      width: '22px',
      height: '22px',
    },
    text: {
      display: 'none',
    },
  },
}), { name: CopyToFolder.name });

export default function CopyToFolder (props: CopyToFolderProps) {
  const {
    className,
    folders = [],
    disabled,
    onCopy,
  } = props;
  const classes = useStyles();
  const [selectedFolder, setSelectedFolder] = useState<SearchesFolder | PlayersFolder>(folders[0]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedFolder(folders[0]);
  }, [folders]);

  function onSelectFolder (folderId:number) {
    const folder = folders.find(folder => folder.id === folderId);

    if (folder) {
      setSelectedFolder(folder);
    }
  }

  return (
    <>
      <Action
        className={clsx(classes.copyToFolder, disabled && classes.disabled, className)}
        icon={CopyIcon}
        iconClassName={classes.icon}
        disabled={disabled}
        onClick={() => setDialogOpen(true)}
      >
        <span className={classes.text}>Copy to Folder</span>
      </Action>

      {(selectedFolder && selectedFolder.id) && (
        <Dialog
          open={dialogOpen}
          title='Copy to a folder'
          actions={[
            {
              name: 'Cancel',
              onClick: () => setDialogOpen(false),
            },
            {
              name: 'Copy',
              primary: true,
              onClick: () => {
                onCopy(selectedFolder);
                setDialogOpen(false);
              },
            },
          ]}
          onClose={() => setDialogOpen(false)}
        >
          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            items={folders.map(folder => ({
              content: folder.name,
              value: folder.id,
            }))}
            value={selectedFolder.id}
            onChange={onSelectFolder}
          />
        </Dialog>
      )}
    </>
  );
}
