import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RemoveIcon from '../icons/RemoveIcon';
import MoveIcon from '../icons/MoveIcon';
import Action from '../atoms/Action';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import NewFolder from '../molecules/NewFolder';
import CopyToFolder from '../molecules/CopyToFolder';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import SearchesTable, {
  SEARCH_ALL_COLUMNS,
  SEARCH_COLUMN,
  isFolder,
} from './SearchesTable';
import gql from '../services/gql';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Search from '../types/Search';
import SearchesFolder from '../types/SearchesFolder';
import { Order } from '../types/Order';

interface SavedSearchesTableProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  savedSearchesTable: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  titleRow: {
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: 0,
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    padding: theme.spacing(0, 2.5),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '32px',
    height: '32px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  newFolder: {
    margin: theme.spacing(0, 0, 0, 'auto'),
    paddingRight: 0,
  },

  [MEDIA.MOBILE]: {
    titleRow: {
      padding: theme.spacing(2),
    },

    dropdownWrapper: {
      fontSize: theme.typography.pxToRem(14),
    },
    dropdown: {
      minWidth: '70px',
    },
    dropdownSelector: {
      minHeight: 0,
    },

    actions: {
      padding: theme.spacing(2),
    },
    action: {
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(14),
    },
    actionIcon: {
      width: '22px',
      height: '22px',
    },
    actionText: {
      display: 'none',
    },
  },

  '@media (max-width: 500px)': {
    titleRow: {
      display: 'block',
    },

    dropdownWrapper: {
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },
  },
}), { name: SavedSearchesTable.name });

export default function SavedSearchesTable (props: SavedSearchesTableProps) {
  const { className } = props;
  const classes = useStyles();

  const [searches, setSearches] = useState<Search[]>([]);
  const [folders, setFolders] = useState<SearchesFolder[]>([]);
  const [needReprocessing, setNeedReprocessing] = useState<boolean>(true);
  const [refetchData, setRefetchData] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedSearches, setSelectedSearches] = useState<Array<Search>>([]);
  const [selectedFolders, setSelectedFolders] = useState<Array<SearchesFolder>>([]);
  const [editedItems, setEditedItems] = useState<Array<Search | SearchesFolder>>([]);
  const [order, setOrder] = useState<Order | undefined>();
  const [sortedByColumn, setSortedByColumn] = useState<string>(SEARCH_COLUMN.NAME);
  const [selectedFolder, setSelectedFolder] = useState<SearchesFolder>();
  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.SAVED_SEARCH_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.SAVED_SEARCH_TABLE) || {}).limit || 10);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.SAVED_SEARCH_TABLE) || {}).currentPage || 1);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    clientStorage.save(StorageKey.SAVED_SEARCH_TABLE, {limit, currentPage});
  }, [limit, currentPage]);

  useEffect(() => {
    if (prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [limit]);

  useEffect(() => {
    if (refetchData) {
      if (!loading) setLoading(true);

      Promise.allSettled([
        fetchSavedSearches(),
        fetchSavedSearchesFolders(),
      ])
        .finally(() => {
          setNeedReprocessing(true);
          setRefetchData(false);
          setLoading(false);
        });
    }
  }, [refetchData]);

  useEffect(() => {
    if (needReprocessing && folders.length && searches.length) {
      processSearchesFolders();
    }
  }, [needReprocessing, folders, searches]);

  function fetchSavedSearches() {
    return gql(`
      savedSearches {
        id
        name
        userId
        folderId
        folder {
          id
          userId
          name
        }
        numberOfPlayers
        numberOfSearches
        lastViewNumberOfPlayers
        criteria
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.savedSearches as Search[])
      .then((searches:Search[]) => setSearches(searches || []))
      .catch(error => console.error(error));
  }

  function fetchSavedSearchesFolders () {
    return gql(`
      savedSearchesFolders {
        id
        userId
        name
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.savedSearchesFolders as SearchesFolder[])
      .then((folders:SearchesFolder[]) => {
        setFolders((folders || []).map((folder:SearchesFolder) => ({
          ...folder,
          searches: [],
        } as SearchesFolder)));
      })
      .catch(error => console.error(error));
  }

  function processSearchesFolders () {
    setFolders(folders.map((folder:SearchesFolder) => ({
      ...folder,
      searches: searches.length
        ? searches.filter((search:Search) => search.folderId === folder.id)
        : [],
    } as SearchesFolder)));

    setNeedReprocessing(false);
  }

  function onDelete () {
    // delete selected folders (only if not inside a folder)
    if (!selectedFolder) {
      const foldersToDelete = selectedFolders;
      if (foldersToDelete.length) {
        deleteSelectedFolders(foldersToDelete);
      }
    }

    const searchesToDelete = selectedSearches;
    if (searchesToDelete.length) {
      // delete selected searches in the main list
      const updatedSearches = [...searches]
        .filter((item:Search) => !(searchesToDelete.find(search => search.id === item.id)));
      setSearches(updatedSearches);

      // delete selected searches inside folders
      const updatedFolders = folders.map((folder:SearchesFolder) => {
        const folderContainsDeletedSearch = !!(folder.searches
          .find((item:Search) => searchesToDelete.some(search => search.id === item.id)));

        if (folderContainsDeletedSearch) {
          const updatedSearches = [...folder.searches]
            .filter((item:Search) => !(searchesToDelete.find(search => search.id === item.id)));

          const updatedFolder = {
            ...folder,
            searches: updatedSearches,
          };

          if (selectedFolder && selectedFolder.id === updatedFolder.id) {
            setSelectedFolder(updatedFolder);
          }

          return updatedFolder;
        }

        return folder;
      });
      setFolders(updatedFolders);

      deleteSavedSearches(searchesToDelete);
    }

    setSelectedSearches([]);
    setSelectedFolders([]);
  }

  function deleteSelectedFolders(foldersToDelete:(SearchesFolder | Search)[]) {
    setLoading(true);

    const foldersToDeleteIds = foldersToDelete.map(folder => folder.id);

    gql(`
      mutation {
        deleteSavedSearchesFolders (folderIds: [${foldersToDeleteIds}])
      }
    `)
      .then((data:any) => data.deleteSavedSearchesFolders as boolean)
      .then((success:boolean) => {
        if (success) {
          setFolders(folders.filter(folder => !foldersToDeleteIds.includes(folder.id)));
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function deleteSavedSearches(searchesToDelete:(SearchesFolder | Search)[]) {
    setLoading(true);

    gql(`
      mutation {
        unsaveSearches (savedSearchIds: [${searchesToDelete.map(search => search.id)}])
      }
    `)
      .then((data:any) => data.unsaveSearches as boolean)
      .then((success:boolean) => {
        if (success) {
          setSelectedSearches([]);
          setSelectedFolders([]);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onCopyToFolder (folder: SearchesFolder | any) {
    setLoading(true);

    gql(`
      mutation {
        copySavedSearchesToFolder (
          savedSearchIds: [${selectedSearches.map(item => item.id)}],
          folderId: ${folder && folder.id}
        )
      }
    `)
      .then((data:any) => data.copySavedSearchesToFolder as boolean)
      .then((success:boolean) => {
        if (success) {
          setLoading(true);
          setRefetchData(true);
        } else {
          showToast('Failed to copy Saved Searces into folder.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to copy Saved Searces into folder.', ToastType.ERROR);
      })
      .finally(() => {
        setSelectedSearches([]);
        setSelectedFolders([]);
        setLoading(false);
      });
  }

  function onMoveOut () {
    setLoading(true);
    if (selectedFolder) {
      const updatedSearches = selectedFolder.searches
        .filter(search => !selectedFolders.some(item => item.id === search.id));
      const updatedFolder = {
        ...selectedFolder,
        searches: updatedSearches,
      };
      setSelectedFolder(updatedFolder);

      // update the list of folders
      const updatedFolders = [...folders];
      const updateIndex = folders.findIndex(item => item.id === updatedFolder.id);
      if (updateIndex > -1) {
        updatedFolders.splice(updateIndex, 1, updatedFolder);
      }
      setFolders(updatedFolders);
    }

    const selectedSearchesIds = selectedSearches
      .map((selectedItem:Search) => selectedItem.id);
    const savedSearchesIds = searches
      .filter((savedSearch:Search) => selectedSearchesIds.includes(savedSearch.id))
      .map((savedSearch:Search) => savedSearch.id);

    gql(`
      mutation {
        copySavedSearchesToFolder (savedSearchIds: [${savedSearchesIds}], folderId: null)
      }
    `)
      .then((data:any) => data.copySavedSearchesToFolder as boolean)
      .then((success:boolean) => {
        if (success) {
          setLoading(true);
          setRefetchData(true);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedFolder(undefined);
    setSelectedSearches([]);
    setSelectedFolders([]);
  }

  function onCreateNewFolder (name:string) {
    setLoading(true);

    gql(`
      mutation {
        createSavedSearchesFolder (name: "${name}")
      }
    `)
      .then((data:any) => data.createSavedSearchesFolder as number)
      .then((newFolderId:number) => {
        if (newFolderId) {
          const dateNow = new Date();
          const lastViewedDate = `${dateNow.getMonth()}/${dateNow.getDay()}/${dateNow.getFullYear()}`;

          setFolders([
            ...folders,
            {
              id: newFolderId,
              name,
              createdAt: lastViewedDate,
              updatedAt: lastViewedDate,
              searches: [],
            },
          ]);
        } else {
          showToast('Failed to create folder.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to create folder.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onUpdateName (updatedItem:SearchesFolder | Search, value:string) {
    setSelectedSearches([]);
    setSelectedFolders([]);

    if (isFolder(updatedItem)) {
      const updatedFolders = [...folders];
      const updateIndex = folders.findIndex(item => item.id === updatedItem.id);
      if (updateIndex > -1) {
        const newFolder = { ...updatedItem as SearchesFolder, name: value };
        updatedFolders.splice(updateIndex, 1, newFolder);
      }

      setFolders(updatedFolders);
    } else {
      // update searches in the main list
      const updatedSearches = [...searches];
      const updateIndex = searches.findIndex(item => item.id === updatedItem.id);
      if (updateIndex > -1) {
        const newSearch = { ...updatedItem as Search, name: value };
        updatedSearches.splice(updateIndex, 1, newSearch);
      }
      setSearches(updatedSearches);

      const updatedFolders = folders.map((folder:SearchesFolder) => {
        const updatedSearchInFolder = folder.searches.find((item:Search) => item.id === updatedItem.id);

        if (updatedSearchInFolder) {
          const updatedSearches = [...folder.searches];
          const updateIndex = folder.searches.findIndex(item => item.id === updatedItem.id);
          if (updateIndex > -1) {
            const newSearch = { ...updatedItem as Search, name: value };
            updatedSearches.splice(updateIndex, 1, newSearch);
          }

          const updatedFolder = {
            ...folder,
            searches: updatedSearches,
          };

          if (selectedFolder && selectedFolder.id === updatedFolder.id) {
            setSelectedFolder(updatedFolder);
          }

          return updatedFolder;
        }

        return folder;
      });
      setFolders(updatedFolders);
    }
  }

  function onSelectFolder (folder:SearchesFolder) {
    setSelectedFolder(folder);
    setSelectedSearches([]);
    setSelectedFolders([]);
  }

  function onGoBack () {
    setSelectedFolder(undefined);
    setSelectedSearches([]);
    setSelectedFolders([]);
  }

  function onRename (editedItem:Search | SearchesFolder) {
    if (isFolder(editedItem)) {
      renameSearchFolder(editedItem);
    } else {
      renameSearch(editedItem);
    }
  }

  function renameSearchFolder (editedItem:Search | SearchesFolder) {
    setLoading(true);

    const { name, id } = editedItem;

    gql(`
      mutation {
        renameSavedSearchFolder (
          savedSearchFolderId: ${id}
          name: "${name}"
        )
      }
    `)
      .then((data:any) => data.renameSavedSearchFolder as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function renameSearch (editedItem:Search | SearchesFolder) {
    setLoading(true);

    const { name, id } = editedItem;

    gql(`
      mutation {
        renameSavedSearch (
          savedSearchId: ${id}
          name: "${name}"
        )
      }
    `)
      .then((data:any) => data.renameSavedSearch as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onSort (columnName:string, newOrder:Order, sort:(searches:Search[], order:Order) => Search[]) {
    setSortedByColumn(columnName);
    setSearches(sort(selectedFolder ? selectedFolder.searches : searches, newOrder));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const withSelectedFolders = !!selectedFolders.length;
  const copyActionDisabled = withSelectedFolders || !selectedSearches.length;
  const moveOutActionDisabled = !selectedSearches.length;
  const deleteActionDisabled = !selectedFolders.length && !selectedSearches.length;

  return (
    <div className={clsx(classes.savedSearchesTable, className)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleRow}>
          <h2 className={classes.headerTitle}>
            {selectedFolder
              ? `${selectedFolder.name} Folder`
              : 'Your Saved Searches'
            }
          </h2>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Searches
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          {(!selectedFolder && !!folders?.length) && (
            <CopyToFolder
              className={clsx(classes.action, copyActionDisabled && classes.disabledAction)}
              folders={folders}
              disabled={copyActionDisabled}
              onCopy={onCopyToFolder}
            />
          )}

          {selectedFolder && (
            <Action
              className={clsx(classes.action, moveOutActionDisabled && classes.disabledAction)}
              icon={MoveIcon}
              iconClassName={classes.actionIcon}
              disabled={moveOutActionDisabled}
              onClick={onMoveOut}
            >
              <span className={classes.actionText}>Move Out</span>
            </Action>
          )}

          <Action
            className={clsx(classes.action, deleteActionDisabled && classes.disabledAction)}
            icon={RemoveIcon}
            iconClassName={classes.actionIcon}
            disabled={deleteActionDisabled}
            onClick={onDelete}
          >
            <span className={classes.actionText}>Delete</span>
          </Action>

          {!selectedFolder && (
            <NewFolder
              className={classes.newFolder}
              onCreate={onCreateNewFolder}
            />
          )}
        </div>
      </div>

      <SearchesTable
        items={selectedFolder ? selectedFolder.searches : [...folders, ...searches]}
        searches={selectedFolder ? selectedFolder.searches : searches}
        folders={folders}
        limit={limit}
        columns={SEARCH_ALL_COLUMNS}
        selectedSearches={selectedSearches}
        setSelectedSearches={setSelectedSearches}
        selectedFolders={selectedFolders}
        setSelectedFolders={setSelectedFolders}
        editedItems={editedItems}
        setEditedItems={setEditedItems}
        order={order}
        setOrder={setOrder}
        sortedByColumn={sortedByColumn}
        onSort={onSort}
        onSelectFolder={onSelectFolder}
        onUpdateName={onUpdateName}
        onGoBack={selectedFolder ? onGoBack : undefined}
        onRename={onRename}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}
