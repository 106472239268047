import { Dispatch } from 'redux';
import gql, { serializeGQLObject } from '../../services/gql';
import { State } from '../reducers';
import { CompareResultType } from '../../organisms/ComparePlayersTable';
import {
  setUserSettingsLoadingAction,
  setUserSettingsLoadedAction,
  setSearchColumnsAction,
  setSavedPlayersColumnsAction,
  setTeamPlayersColumnsAction,
  setComparePlayersColumnsAction,
  setManageUsersColumnsAction,
  setManageProgramsColumnsAction,
  setComparePlayersResultTypeAction,
} from '../actions/user-settings';
import UserSettings from '../../types/UserSettings';
import Column from '../../types/Column';

export function fetchUserSettings (isRefresh?:boolean) {
  return (dispatch:Dispatch, getState:() => State) => {
    if (isRefresh) dispatch(setUserSettingsLoadedAction(false));
    const { userSettings: { userSettingsLoading, userSettingsLoaded } } = getState();

    if (userSettingsLoading || userSettingsLoaded) return;

    dispatch(setUserSettingsLoadingAction(true));

    return gql(`
      userSettings {
        searchColumns
        savedPlayersColumns
        teamPlayersColumns
        comparePlayersColumns
        comparePlayersResultType
        manageUsersColumns
        manageProgramsColumns
      }
    `)
      .then((data:any) => data.userSettings as UserSettings)
      .then((userSettings:UserSettings) => {
        dispatch(setSearchColumnsAction(userSettings?.searchColumns || null));
        dispatch(setSavedPlayersColumnsAction(userSettings?.savedPlayersColumns || null));
        dispatch(setTeamPlayersColumnsAction(userSettings?.teamPlayersColumns || null));
        dispatch(setComparePlayersColumnsAction(userSettings?.comparePlayersColumns || null));
        dispatch(setComparePlayersResultTypeAction(userSettings?.comparePlayersResultType as CompareResultType || null));
        dispatch(setManageUsersColumnsAction(userSettings?.manageUsersColumns || null));
        dispatch(setManageProgramsColumnsAction(userSettings?.manageProgramsColumns || null));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setUserSettingsLoadingAction(false));
        dispatch(setUserSettingsLoadedAction(true));
      });
  };
}

export function saveSearchColumns (searchColumns:Column[]) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      savedPlayersColumns,
      teamPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    }} = getState();

    dispatch(setSearchColumnsAction(searchColumns));

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

export function saveTeamPlayersColumns (teamPlayersColumns:Column[]) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      searchColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    }} = getState();

    dispatch(setTeamPlayersColumnsAction(teamPlayersColumns));

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

export function saveSavedPlayersColumns (savedPlayersColumns:Column[]) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      searchColumns,
      teamPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    }} = getState();

    dispatch(setSavedPlayersColumnsAction(savedPlayersColumns));

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

export function saveComparePlayersColumnsAndType (comparePlayersColumns:Column[], resultType?:CompareResultType) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      searchColumns,
      savedPlayersColumns,
      comparePlayersResultType,
      teamPlayersColumns,
      manageUsersColumns,
      manageProgramsColumns,
    }} = getState();

    dispatch(setComparePlayersColumnsAction(comparePlayersColumns));

    if (resultType) {
      dispatch(setComparePlayersResultTypeAction(resultType));
    }

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType: resultType || comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

export function saveManageUsersColumns (manageUsersColumns:Column[]) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      searchColumns,
      savedPlayersColumns,
      teamPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageProgramsColumns,
    }} = getState();

    dispatch(setManageUsersColumnsAction(manageUsersColumns));

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

export function saveManageProgramsColumns (manageProgramsColumns:Column[]) {
  return (dispatch:Dispatch, getState:() => State) => {
    const { userSettings: {
      searchColumns,
      savedPlayersColumns,
      teamPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
    }} = getState();

    dispatch(setManageProgramsColumnsAction(manageProgramsColumns));

    return saveOrUpdateUserSettings({
      searchColumns,
      teamPlayersColumns,
      savedPlayersColumns,
      comparePlayersColumns,
      comparePlayersResultType,
      manageUsersColumns,
      manageProgramsColumns,
    });
  };
}

function saveOrUpdateUserSettings (userSettings:UserSettings) {
  return gql(`
    mutation {
      createOrUpdateUserSettings(
        userSettings: ${serializeGQLObject(userSettings)}
      )
    }
  `)
    .then((data:any) => data && data.createOrUpdateUserSettings as boolean)
    .catch(console.error);
}
