import React from 'react';
import Icon from '../atoms/Icon';

function DownloadIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
        <path
          d='M16 22.11l9.571-9.394-2.306-2.264-5.632 5.529V3h-3.266v12.98l-5.632-5.528-2.306 2.264L16 22.11zM32 28v-9.615h-3.265v6.41H3.265v-6.41H0V28h32z'
          fill='currentColor'
          fillRule='nonzero'
        />
    </Icon>
  );
}

export default React.forwardRef(DownloadIcon);
