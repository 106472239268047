import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import MessageContent from '../organisms/MessageContent';
import { MESSAGES_TYPE } from '../organisms/MessagesTable';

const useStyles = makeStyles(theme => ({
  messagesPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}), { name: MessagePageTemplate.name });

export default function MessagePageTemplate () {
  const classes = useStyles();
  const { messageId, type } = useParams<any>();

  return (
    <PageContainer className={classes.messagesPageTemplate}>
      <Paper className={classes.paper}>
        <MessageContent
          messageId={messageId}
          isSent={type === MESSAGES_TYPE.SENT}
          isDraft={type === MESSAGES_TYPE.DRAFT}
        />
      </Paper>
    </PageContainer>
  )
}
