import { Dispatch } from 'redux';
import gql from '../../services/gql';
import { State } from '../reducers';
import { CacheKey, CacheExpiration, cache, retrieve } from '../../services/cache';
import {
  setConfigurationsLoadingAction,
  setConfigurationsLoadedAction,
  setCurrentRecruitingYearAction,
  setNFLDraftYearAction,
  setNewHSPlayersDaysAction,
  setNewCollegePlayersDaysAction,
  setDashboardRolloverDayAction,
  setDashboardRolloverMonthAction,
  setRolloverDayAction,
  setRolloverMonthAction,
  setTrackDatePastYearLimitAction,
  setRolloverDateTransferPortal,
  setRolloverMonthTransferPortal,
} from '../actions/configurations';
import Configuration, { CONFIGURATION_KEY } from '../../types/Configuration';

export function fetchConfigurations () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { configurations: { configurationsLoading, configurationsLoaded } } = getState();

    if (configurationsLoading || configurationsLoaded) return;

    const cachedConfigurations = retrieve(CacheKey.CONFIGURATIONS);
    const cacheIsValid = !!cachedConfigurations?.length;

    if (cacheIsValid) {
      processConfigurations(cachedConfigurations, dispatch);

      if (!configurationsLoaded) {
        dispatch(setConfigurationsLoadedAction(true));
      }

      return;
    }

    dispatch(setConfigurationsLoadingAction(true));

    return gql(`
      configurations (keys: [
        "${CONFIGURATION_KEY.CURRENT_RECRUITING_YEAR}",
        "${CONFIGURATION_KEY.NFL_DRAFT_YEAR}",
        "${CONFIGURATION_KEY.NEW_HS_PLAYERS_DAYS}",
        "${CONFIGURATION_KEY.NEW_COLLEGE_PLAYERS_DAYS}",
        "${CONFIGURATION_KEY.DASHBOARD_ROLLOVER_DAY}",
        "${CONFIGURATION_KEY.DASHBOARD_ROLLOVER_MONTH}",
        "${CONFIGURATION_KEY.ROLLOVER_DAY}",
        "${CONFIGURATION_KEY.ROLLOVER_MONTH}",
        "${CONFIGURATION_KEY.TRACK_DATE_PAST_YEAR_LIMIT}",
        "${CONFIGURATION_KEY.ROLLOVER_DATE_TRANSFER_PORTAL}",
        "${CONFIGURATION_KEY.ROLLOVER_MONTH_TRANSFER_PORTAL}",
      ]) {
        key
        value
      }
    `)
      .then((data:any) => data.configurations as Configuration[])
      .then((configurations:Configuration[]) => {
        processConfigurations(configurations, dispatch);
        cache(CacheKey.CONFIGURATIONS, configurations, CacheExpiration.LONG);
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setConfigurationsLoadingAction(false));
        dispatch(setConfigurationsLoadedAction(true));
      });
  };
}

function processConfigurations (configurations:Configuration[], dispatch:Dispatch) {
  if (configurations && configurations.length) {
    const currentRecruitingYear = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.CURRENT_RECRUITING_YEAR) || {} as any)
      .value;
    if (currentRecruitingYear) {
      dispatch(setCurrentRecruitingYearAction(Number(currentRecruitingYear)));
    }

    const nflDraftYear = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.NFL_DRAFT_YEAR) || {} as any)
      .value;
    if (nflDraftYear) {
      dispatch(setNFLDraftYearAction(Number(nflDraftYear)));
    }

    const newHSPlayersDays = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.NEW_HS_PLAYERS_DAYS) || {} as any)
      .value;
    if (newHSPlayersDays) {
      dispatch(setNewHSPlayersDaysAction(Number(newHSPlayersDays)));
    }

    const newCollegePlayersDays = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.NEW_COLLEGE_PLAYERS_DAYS) || {} as any)
      .value;
    if (newCollegePlayersDays) {
      dispatch(setNewCollegePlayersDaysAction(Number(newCollegePlayersDays)));
    }

    const dashboardRolloverDay = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.DASHBOARD_ROLLOVER_DAY) || {} as any)
      .value;
    if (dashboardRolloverDay) {
      dispatch(setDashboardRolloverDayAction(Number(dashboardRolloverDay)));
    }

    const dashboardRolloverMonth = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.DASHBOARD_ROLLOVER_MONTH) || {} as any)
      .value;
    if (dashboardRolloverMonth) {
      dispatch(setDashboardRolloverMonthAction(Number(dashboardRolloverMonth)));
    }

    const rolloverDay = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.ROLLOVER_DAY) || {} as any)
      .value;
    if (rolloverDay) {
      dispatch(setRolloverDayAction(Number(rolloverDay)));
    }

    const rolloverMonth = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.ROLLOVER_MONTH) || {} as any)
      .value;
    if (rolloverMonth) {
      dispatch(setRolloverMonthAction(Number(rolloverMonth)));
    }

    const trackDatePastYearLimit = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.TRACK_DATE_PAST_YEAR_LIMIT) || {} as any)
      .value;
    if (trackDatePastYearLimit) {
      dispatch(setTrackDatePastYearLimitAction(Number(trackDatePastYearLimit)));
    }

    const rolloverDateTransferPortal = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.ROLLOVER_DATE_TRANSFER_PORTAL) || {} as any)
      .value;
    if (rolloverDateTransferPortal) {
      dispatch(setRolloverDateTransferPortal(Number(rolloverDateTransferPortal)));
    }

    const rolloverMonthTransferPortal = (configurations
      .find(configuration => configuration.key === CONFIGURATION_KEY.ROLLOVER_MONTH_TRANSFER_PORTAL) || {} as any)
      .value;
    if (rolloverMonthTransferPortal) {
      dispatch(setRolloverMonthTransferPortal(Number(rolloverMonthTransferPortal)));
    }
  }
}
