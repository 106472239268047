import React from 'react';
import Icon from '../atoms/Icon';

function HockeyIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(3.000000, 5.000000)'>
          <path
            d='M6,4 C3.790861,4 2,4.67157288 2,5.5 C2,6.32842712 3.790861,7 6,7 C8.209139,7 10,6.32842712 10,5.5 C10,4.67157288 8.209139,4 6,4 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M2,5.5 C2,5.886 2,8.114 2,8.5 C2,9.328 3.791,10 6,10 C8.209,10 10,9.328 10,8.5 C10,8.114 10,5.886 10,5.5'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M21.948,0 L17.526,12.139 L17.524,12.138 C16.821,13.876 15.157,15 13.283,15 L4,15 C1.794,15 0,16.794 0,19 C0,21.206 1.794,23 4,23 L11.498,23 C14.012,23 16.278,21.413 17.136,19.051 L24.076,0 L21.948,0 Z M15.257,18.367 C14.685,19.942 13.174,21 11.498,21 L4,21 C2.897,21 2,20.103 2,19 C2,17.897 2.897,17 4,17 L13.283,17 C14.228,17 15.136,16.801 15.96,16.435 L15.257,18.367 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M5,22 L5,16 M12,22 L12,16'
            stroke='currentColor'
            strokeWidth='2'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(HockeyIcon);
