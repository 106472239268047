import React from 'react';
import Icon from '../atoms/Icon';

function XLSIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M7.444 0h14.5L30 8v20.8c0 1.685-1.316 3.069-2.982 3.191l-.24.009H7.444a3.216 3.216 0 01-3.213-2.961l-.009-.239V24h3.222v4.8h19.334V9.326L20.608 3.2H7.445v9.6H4.222V3.2C4.222 1.515 5.54.131 7.204.009L7.444 0z'/>
        <path d='M27.583 9.6h-7.25V2.4l7.25 7.2zm-23.36 1.6C2.441 11.2 1 12.632 1 14.4v8c0 1.768 1.442 3.2 3.222 3.2H28.39V11.2H4.222zm4.412 11.2l-1.192-2.797-1.19 2.797h-2.03l2.086-4.034L4.263 14.4h2.01l1.168 2.747 1.17-2.747h2.007l-2.04 3.966 2.089 4.034H8.635zm8.476 0h-4.833v-8h1.61v6.4h3.223v1.6zm4.278-4.694c.36.13 2.146.921 2.16 2.534.003.342-.085 2.136-2.394 2.16-2.512.027-2.683-2.118-2.683-2.562h1.571c0 .236.021 1.372 1.112 1.284.656-.055.797-.524.82-.872.037-.588-.527-.94-1.123-1.224-.838-.4-2.164-.892-2.188-2.45-.02-1.403 1.018-2.216 2.459-2.176 2.292.066 2.433 2.053 2.433 2.406h-1.56c0-.164.027-1.116-.9-1.116-.283 0-.916.124-.867.897.046.709.987 1.043 1.16 1.119z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(XLSIcon);
