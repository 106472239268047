import React, { useState } from 'react';
import Dialog from './Dialog';
import Input from '../atoms/Input';
import HelpPopupFooter from '../atoms/HelpPopupFooter';
import Loader from '../atoms/Loader';
import gql from '../services/gql';

interface OtherHelpModalProps {
  className?: string;
  headingClassName?: string;
  inputClassName?: string;
  inputFieldClassName?: string;
  inputHalfClassName?: string;
  dropDownClassName?: string;
  dropDownSelectClassName?: string;
  fullNameClassName?: string;
  multiSelectClassName?: string;
  contentClassName?: string;
  isPopupVisible: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

export default function OtherHelpModal (props:OtherHelpModalProps) {
  const {
    className,
    headingClassName,
    inputClassName,
    inputFieldClassName,
    contentClassName,
    isPopupVisible = false,
    onClose,
    onError,
    onSuccess,
  } = props;

  const [helpText, setHelpText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleSubmit() {
    submitHelpText()
      .then((success:boolean | void) => {
        if (success) {
          onClose();
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(onError);
  }

  function submitHelpText () {
    setIsLoading(true);
    return gql(`
      mutation {
        submitOtherHelp (otherHelp: {
          message: "${helpText}"
        })
      }`
    )
      .then((data: any) => data && data.submitOtherHelp as boolean)
      .finally(() => setIsLoading(false));
  }

  return(
    <Dialog
      className={className}
      contentClassName={contentClassName}
      open={isPopupVisible}
      title='Other'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose
        },
        {
          name: 'Request Help',
          primary: true,
          disabled: isLoading,
          onClick: handleSubmit
        }
      ]}
      onClose={onClose}
    >
      <Loader inProgress={isLoading} />
      <p className={headingClassName}>
        Please describe for us other issues or needs you have:
      </p>
      <Input
        className={inputClassName}
        inputFieldClassName={inputFieldClassName}
        multiLine
        autoFocus
        value={helpText}
        onChange={(value: string) => setHelpText(value)}
      />
      <HelpPopupFooter />
    </Dialog>
  );
}
