import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { COLOR_GREEN, COLOR_MIDDLE_GRAY, COLOR_TEXT } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface ProgressLineProps {
  className?: string;
  valueClassName?: string;
  progressBarClassName?: string;
  value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  progressLine: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
  },

  progress: {
    width: '100%',
    minWidth: 84,
    height: 10,
    borderRadius: 10,
    backgroundColor: COLOR_MIDDLE_GRAY,
  },
  progressBar: {
    backgroundColor: COLOR_GREEN,
    borderRadius: 10,
  },
  value: {
    marginLeft: theme.spacing(2.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
    color: COLOR_TEXT,
  },
}), { name: ProgressLine.name });

export default function ProgressLine (props: ProgressLineProps) {
  const {
    className,
    valueClassName,
    progressBarClassName,
    value,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.progressLine, className)}>
      <LinearProgress
        className={classes.progress}
        classes={{ bar: clsx(classes.progressBar, progressBarClassName) }}
        variant='determinate'
        value={value}
      />
      <span className={clsx(classes.value, valueClassName)}>{Math.round(value)}%</span>
    </div>
  );
}
