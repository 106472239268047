import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { State } from '../redux/reducers';
import Input from '../atoms/Input';
import Loader from '../atoms/Loader';
import { ToastType } from '../atoms/Toast';
import Dialog from './Dialog';
import { resetPassword } from '../services/user';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT } from '../styles/colors';
import User from '../types/User';

interface ChangePasswordModalProps {
  open: boolean;
  user?: User;
  onClose: () => void;
  showToast: (message:any, toastType:ToastType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  changePasswordModal: {},

  resetPasswordText: {
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLOR_TEXT,
  },
  resetInputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  input: {
    marginTop: theme.spacing(3),
  },
}), { name: ChangePasswordModal.name });

function ChangePasswordModal (props:ChangePasswordModalProps) {
  const {
    open = false,
    user,
    onClose = () => {},
    showToast,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  function onResetPassword () {
    if (user && user.email) {
      setLoading(true);

      resetPassword(user.email)
        .then((success:boolean) => {
          if (success) {
            onClose();
            showToast('Success. Check you email.', ToastType.SUCCESS);
          } else {
            showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <Dialog
      open={open}
      title='Reset password'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose,
        },
        {
          name: 'Yes, reset',
          primary: true,
          disabled: !(user && user.email),
          onClick: onResetPassword,
        },
      ]}
      onClose={onClose}
    >
      <Loader inProgress={loading} />

      <p className={classes.resetPasswordText}>
        Confirm your email address, and we'll send you an email with a link to reset your password.
      </p>

      <Input
        className={classes.input}
        inputFieldClassName={classes.resetInputField}
        label='Email'
        value={user && user.email ? user.email : ''}
        fontSize={14}
        disabled
      />
    </Dialog>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(ChangePasswordModal);
