import React from 'react';
import Icon from '../atoms/Icon';

function EnduranceIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M18.54 4.263a4.263 4.263 0 108.526 0 4.263 4.263 0 00-8.525 0zm2.783 0a1.48 1.48 0 112.962 0 1.48 1.48 0 01-2.962 0zM11.43 20.211l1.967 1.968-2.046 2.047a2.779 2.779 0 01-1.97.817H3v-2.782h6.38l2.05-2.05zm-.593-13.719a2.906 2.906 0 013.754-.449l6.109 4.085.153.23 2.368 3.554 6.214.001v2.783h-6.212c-.93 0-1.797-.465-2.316-1.24l-2.215-3.323-5.648-3.776a.124.124 0 00-.16.02l-4.687 5.087-2.046-1.885 4.686-5.087z'/>
        <path d='M17.323 23.926L13.28 32h3.113l3.42-6.827a2.783 2.783 0 00-.52-3.214l-4.92-4.92a.822.822 0 01-.088-1.061l3.779-5.291-2.265-1.617-3.78 5.291a3.606 3.606 0 00.385 4.646l4.92 4.92z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(EnduranceIcon);
