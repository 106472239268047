import React from 'react';
import Icon from '../atoms/Icon';

function TennisIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(4, 4)'>
          <path
            d='M20.552,13.559 C23.293,10.818 25.988,6.133 21.927,2.072 C17.866,-1.989 13.18,0.708 10.44,3.448 C7.7,6.188 5.02,10.89 9.065,14.935 C13.11,18.98 17.811,16.3 20.552,13.559 Z M0,24 L3.501,20.499'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M5,0 C3.8954305,0 3,0.8954305 3,2 C3,3.1045695 3.8954305,4 5,4 C6.1045695,4 7,3.1045695 7,2 C7,0.8954305 6.1045695,0 5,0 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M4.401,19.599 C5.781,18.219 7,10.5 7,10.5 M13.5,17 C13.5,17 5.781,18.219 4.401,19.599'
            stroke='currentColor'
            strokeWidth='2'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TennisIcon);
