import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NotAvailable from './NotAvailable';
import CheckIcon from '../icons/CheckIcon';
import Tooltip from './Tooltip';
import {
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_MIDDLE_GRAY,
  COLOR_BLUE,
  COLOR_PURPLE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import PAIScore from '../types/PAIScore';
import CombineScore from '../types/CombineScore';
import PPIScore from '../types/PPIScore';
import Position from '../types/Position';
import HighSchoolCombine from '../types/HighSchoolCombine';
import NFLCombine from '../types/NFLCombine';
import ProCombineScore from '../types/ProCombineScore';

interface ScoreProps {
  className?: string;
  noScoreTextClass?: string;
  checkIconClass?: string;
  type: string;
  scoreList?: Array<PAIScore | CombineScore>;
  value?: number;
  position?: Position;
  combines?: Array<HighSchoolCombine | NFLCombine>;
  totalValue?: number;
  showCheckMarkExplict?: boolean;
  isNotHsMultiSport?: boolean;
  multiSportTooltip?: React.ReactNode;
}

export const SCORE_TYPE = {
  PAI: 'pai',
  COMBINE: 'combine',
  PRO_COMBINE: 'proCombine',
  PPI: 'ppi',
  MPH: 'mph',
  MULTISPORTS: 'multiSports'
};

const useStyles = makeStyles((theme: Theme) => ({
  score: {
    width: '35px',
    height: '35px',
    position: 'relative',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,

    '&:before': {
      content: '""',
      width: 'calc(100% - 1px)',
      height: 'calc(100% - 1px)',
      position: 'absolute',
      top: '1px',
      left: '1px',
      border: `2px solid ${COLOR_MIDDLE_GRAY}`,
      borderRadius: '50%',
    },
  },
  noScore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pai: {
    '& $progress': {
      color: COLOR_ORANGE,
    },
  },
  combine: {
    '& $progress': {
      color: COLOR_GREEN,
    },
  },
  ppi: {
    '& $progress': {
      color: COLOR_BLUE,
    },
  },
  mph: {
    '& $progress': {
      color: COLOR_PURPLE,
    },
  },
  proCombine: {
    '& $progress': {
      color: COLOR_TEXT,
    },
  },
  multiSports: {
    '& $progress': {
      color: COLOR_MIDDLE_GRAY,
    },
  },

  withProCombineCheckMark: {
    '& $checkIcon': {
      color: COLOR_TEXT,
    },
  },

  progress: {
    width: '100% !important',
    height: '100% !important',

    '& circle': {
      strokeLinecap: 'round',
    },
  },
  scoreValue: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    fontSize: 'inherit',
    fontWeight: 'bold',
    color: COLOR_TEXT,
    cursor: 'help',
  },
  checkIcon: {
    height: '16px',
    width: '16px',
    color: COLOR_GREEN,
  },
  noScoreText: {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 600,
    color: COLOR_TEXT,
  },

  '@media (max-width: 1600px)': {
    score: {
      width: '30px',
      height: '30px',
      fontSize: theme.typography.pxToRem(12),
    }
  },
}), { name: Score.name });

export function getScoreValue (
  scoreList: Array<PAIScore | CombineScore | PPIScore | ProCombineScore>,
  { position, combines }:{
    position?: Position;
    combines?: Array<HighSchoolCombine | NFLCombine>;
  } = {},
):number|null {
  const score = position
    ? ((scoreList || []).find(score => score && score.code === position)) || {}
    : ((scoreList || []).find(score => score && score.isPrimary)) || {};

  const hasCombineDataButNoValue = combines?.length
    && (!scoreList || !scoreList.length);

  if (typeof (score as PAIScore).pai === 'number') {
    return (score as PAIScore).pai;
  } else if (typeof (score as CombineScore).combine === 'number') {
    return (score as CombineScore).combine;
  } else if (typeof (score as ProCombineScore).proCombine === 'number') {
    return (score as ProCombineScore).proCombine;
  } else if (typeof (score as PPIScore).ppi === 'number') {
    return (score as PPIScore).ppi;
  } else {
    return hasCombineDataButNoValue ? 0.001 : null;
  }
}

export function getProCombineScoreValue (
  nflCombineScore: number | null,
  nflCombines: Array<NFLCombine>
):number|null {
  const hasProCombineDataButNoValue = nflCombines?.length && !nflCombineScore;

  return nflCombineScore
    ? nflCombineScore
    : hasProCombineDataButNoValue ? 0.001 : null;
}

export default function Score (props:ScoreProps) {
  const {
    className,
    noScoreTextClass,
    checkIconClass,
    type,
    scoreList = [],
    value,
    position,
    combines = [],
    totalValue = 5,
    showCheckMarkExplict = false,
    isNotHsMultiSport = true,
    multiSportTooltip,
  } = props;
  const classes = useStyles();

  const scoreValue:number|null = value || (position ? getScoreValue(scoreList, { position }) : getScoreValue(scoreList));
  const validScore = typeof scoreValue === 'number' && scoreValue >= 0;
  const roundedScore = validScore
    ? (Math.round((scoreValue as number) * 10) / 10).toFixed(1)
    : 0;
  const showCombineCheckMark = (type === SCORE_TYPE.COMBINE
    && !!combines?.length
    && !scoreList?.length) || showCheckMarkExplict;
  const showProCombineCheckMark = type === SCORE_TYPE.PRO_COMBINE
    && !!combines?.length
    && !value;
  const showCheckMark = showCombineCheckMark || showProCombineCheckMark;
  let checkmarkTooltipText = '';
  if (type === SCORE_TYPE.COMBINE) {
    checkmarkTooltipText = 'Attended verified HS combine no calculated score';
  } else if (type === SCORE_TYPE.PRO_COMBINE) {
    checkmarkTooltipText = 'Attended NFL Combine or Pro Day no calculated score';
  }
  return (
    <div
      className={clsx(
        classes.score,
        !validScore && classes.noScore,
        {
          [classes.pai]: type === SCORE_TYPE.PAI,
          [classes.combine]: type === SCORE_TYPE.COMBINE,
          [classes.ppi]: type === SCORE_TYPE.PPI,
          [classes.proCombine]: type === SCORE_TYPE.PRO_COMBINE,
          [classes.mph]: type === SCORE_TYPE.MPH,
          [classes.multiSports]: type === SCORE_TYPE.MULTISPORTS,
          [classes.withProCombineCheckMark]: showProCombineCheckMark,
        },
        className,
      )}
    >
      {(!validScore && !showCheckMark) && (
        isNotHsMultiSport ?
        <NotAvailable className={clsx(classes.noScoreText, noScoreTextClass)} />
        : <div>{0}</div>
      )}

      {validScore && (
        <>
          <CircularProgress
            classes={{
              root: classes.progress,
            }}
            variant='determinate'
            value={Number(((scoreValue as number) * 100 / totalValue).toFixed(2))}
            thickness={4}
          />

          {isNotHsMultiSport ? 
            <Tooltip title={multiSportTooltip ? multiSportTooltip : (scoreValue as number).toFixed(2)}>
              <div className={classes.scoreValue}>{multiSportTooltip ? Math.floor(scoreValue as number) : roundedScore }</div>
            </Tooltip>
            : <div className={classes.scoreValue} style={{cursor: 'pointer'}}>{Math.floor(scoreValue as number)}</div>
          }
        </>
      )}

      {(!validScore && !!showCheckMark) && (
        <>
          <CircularProgress
            classes={{
              root: classes.progress,
            }}
            variant='determinate'
            value={100}
            thickness={4}
          />

        <Tooltip title={checkmarkTooltipText}>
          <div className={classes.scoreValue}>
            <CheckIcon className={clsx(classes.checkIcon, checkIconClass)} />
          </div>
        </Tooltip>
        </>
      )}
    </div>
  );
}
