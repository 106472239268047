import React from 'react';
import Icon from '../atoms/Icon';

function CompareIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M26 9H12m8.485-6l6 6-6 6M5 23h14m-8.485 6l-6-6 6-6'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path 
          d='M4 8h2v2H4V8zm4 0h2v2H8V8zm13 14h2v2h-2v-2zm4 0h2v2h-2v-2z' 
          fill='currentColor' 
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(CompareIcon);
