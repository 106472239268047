import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FilePicker from '../atoms/FilePicker';
import Input from '../atoms/Input';
import Loader from '../atoms/Loader';
import { ToastType } from '../atoms/Toast';
import Dialog from './Dialog';
import gql from '../services/gql';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_LIGHT_GRAY, COLOR_TEXT } from '../styles/colors';
import Company from '../types/Company';

interface CreateCompanyModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (company:Company|null) => void;
  showToast: (message:any, toastType:ToastType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  createCompanyModal: {},

  text: {
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLOR_TEXT,
  },

  image: {
    height: 64,
    alignSelf: 'center',
  },

  input: {
    marginTop: theme.spacing(3),
  },
  inputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  filePicker: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  filePickerInput: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  fileInputLabel: {
    height: 51,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLOR_LIGHT_GRAY,
    borderBottom: `2px solid ${COLOR_DARK_GRAY}`,
    padding: theme.spacing(2),

    '&:hover': {
      borderColor: COLOR_TEXT,
    },

    '&:active, &:focus': {
      borderColor: COLOR_BLUE
    }
  },
  fileContainer: {
    height: 51,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: FONT_PROXIMA_NOVA,
    position: 'relative',
  },
}), { name: CreateCompanyModal.name });

export default function CreateCompanyModal (props:CreateCompanyModalProps) {
  const {
    open = false,
    onCreate = () => {},
    onClose = () => {},
    showToast,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  function createCompany () {
    setLoading(true);

    gql(`
      mutation {
        createCompany(
          name: "${name}",
          logoUrl: ${logoUrl ? `"${logoUrl}"` : null}
        ) {
          id
          name
          logoUrl
        }
      }
    `)
      .then((data:any) => data.createCompany as Company|null)
      .then((company:Company|null) => {
        if (company) {
          onCreate(company);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function uploadLogo (file:any) {
    if (!file?.name) return;

    setLoading(true);

    setFileName(file.name);

    let body = new FormData();
    body.append('file', file, file.name);

    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/companies/upload`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
      },
      body,
    })
      .then(res => res.json())
      .then(({ logoUrl }) => setLogoUrl(logoUrl))
      .catch(error => {
        console.error(error);
        showToast('Failed to upload the image', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Dialog
      open={open}
      title='Create a Company'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose,
        },
        {
          name: 'Create',
          primary: true,
          disabled: !name?.length,
          onClick: createCompany,
        },
      ]}
      onClose={onClose}
    >
      <Loader inProgress={loading} />

      {!logoUrl && (
        <p className={classes.text}>
          Specify the required company's details:
        </p>
      )}

      {logoUrl && (
        <img
          className={classes.image}
          src={logoUrl}
          alt='Selected Company Logo'
        />
      )}

      <Input
        className={classes.input}
        inputFieldClassName={classes.inputField}
        label='Organization Name'
        value={name}
        fontSize={14}
        onChange={setName}
      />

      <FilePicker
        className={classes.filePicker}
        inputClass={classes.filePickerInput}
        fileInputLabelClass={classes.fileInputLabel}
        fileContainerClass={classes.fileContainer}
        name='attachment'
        fileName={fileName}
        onChange={uploadLogo}
      >
        {fileName === '' ? 'Logo URL' : fileName}
      </FilePicker>
    </Dialog>
  );
}
