import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { datetimeToDate } from '../services/converter';
import { COLOR_ORANGE, COLOR_TEXT, COLOR_LIGHT_GRAY } from '../styles/colors';
import User from '../types/User';

interface RankCardProps {
  className?: string,
  user?: User;
}

const useStyles = makeStyles((theme:Theme) => ({
  rankCard: {
    overflow: 'visible',
    borderRadius: 0,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.05)',
    fontSize: theme.typography.pxToRem(14),
  },

  content: {
    display: 'flex',
    padding: '10px',

    '&:last-child': {
      paddingBottom: '10px',
    }
  },

  rankContainer: {
    minWidth: '34%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  rankTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },
  rank: {
    marginTop: 0,
    fontSize: theme.typography.pxToRem(36),
    lineHeight: 1,
    color: COLOR_ORANGE,
  },

  statsContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    paddingLeft: theme.spacing(2),
    color: COLOR_TEXT,
  },
  statRow: {
    marginTop: theme.spacing(1),

    '&:first-of-type': {
      marginTop: 0,
    },
  },
}), { name: RankCard.name });

export default function RankCard (props:RankCardProps) {
  const {
    className,
    user,
  } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.rankCard, className)}>
      <CardContent className={classes.content}>
        {(user && user.rank) && (
          <div className={classes.rankContainer}>
            <div className={classes.rankTitle}>Your Rank</div>
            <div className={classes.rank}>{user.rank}</div>
          </div>
        )}

        {user && (
          <div className={classes.statsContainer}>
            {user?.lastLogin && (
              <div className={classes.statRow}>
                Last Login: <strong>{datetimeToDate(user.lastLogin)}</strong>
              </div>
            )}

            {!!user?.totalLogins && (
              <div className={classes.statRow}>
                Logins last 30 days: <strong>{user.totalLogins}</strong>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
