import React from 'react';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Loader from '../atoms/Loader';
import NamedBox from '../atoms/NamedBox';
import Ranks, { RanksType } from '../atoms/Ranks';
import Accordion from '../atoms/Accordion';
import { RanksComparisonGroup } from '../molecules/RanksTooltip';
import { FormatType, getEventFormatType, getEventName } from '../services/event-names-and-formats';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import Player from '../types/Player';
import Team from '../types/Team';
import Position from '../types/Position';
import PlayerPAIStats from '../types/PlayerPAIStats';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import PlayerNFLPAIStats from '../types/PlayerNFLPAIStats';
import { formatDate } from '../services/player';

interface PlayerTrackAndFieldDataProps {
  loading: boolean;
  player?: Player;
  collegeTeam?: Team;
  nflTeam?: Team;
  position?: Position;
  sprints: Array<PlayerPAIStats | PlayerNFLPAIStats>;
  relays: Array<PlayerPAIStats | PlayerNFLPAIStats>;
  hurdles: Array<PlayerPAIStats | PlayerNFLPAIStats>;
  jumps: Array<PlayerPAIStats | PlayerNFLPAIStats>;
  throws: Array<PlayerPAIStats | PlayerNFLPAIStats>;
  isNFL: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerTrackAndFieldData: {
    borderBottom: 0,
    position: 'relative',
  },
}), { name: PlayerTrackAndFieldData.name });

export default function PlayerTrackAndFieldData (props: PlayerTrackAndFieldDataProps) {
  const {
    loading,
    sprints,
    relays,
    hurdles,
    jumps,
    throws,
    player,
    collegeTeam,
    nflTeam,
    position,
    isNFL,
  } = props;
  const classes = useStyles();

  const college = ((player && player.playerColleges) || [])
    .find((college:College) => college && college.isPrimary);
  const highSchool = ((player && player.highSchools) || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  const graduationYear = highSchool?.graduatingClass || 0;
  const recruitingClass = graduationYear || college?.recruitingClass;

  function displayPerformance (performance:number, formatType:FormatType):string {
    if (formatType === FormatType.FEET_AND_INCHES) {
      return inchesToFeetAndInches(performance);
    } else if (formatType === FormatType.INCHES) {
        return `${Number(performance).toFixed(2)}"`;
    } else if (formatType === FormatType.MINUTES_SECONDS_MS) {
      return secondsToMinutesSecondsMs(performance);
    }

    return Number(performance).toFixed(2);
  }

  function getYearFromDate(date: Date) {
    if (!date) return 0;

    const year = new Date(date).getFullYear();
    return year;
  }

  function renderCollegeStats (name:string, stats:PlayerPAIStats[]) {
    if (!stats || !stats.length) return null;

    return (
      <Accordion
        key={name}
        summary={name}
        expanded
      >
        {stats.map((stat:PlayerPAIStats, index) => (
          <Ranks
            key={index}
            type={RanksType.PAI}
            playerId={player?.id}
            teamId={collegeTeam?.id}
            position={position}
            comparisonEvent={stat.event}
            event={{
              type: getEventName(stat.event),
              name: stat.meet
                // ? `${stat.meet} ${stat.year ? `(${stat.year})` : (!!stat.date ? `(${formatDate(stat.date) || ''})` : '')}`
                // : (stat.year ? String(stat.year) : (!!stat.date && `(${formatDate(stat.date) || ''})`)),
                ? `${stat.meet} ${!!stat.date ? `(${formatDate(stat.date)})` : stat.year ? `(${stat.year})` : ''}`
                : (stat.date ? formatDate(stat.date) : stat.year ? String(stat.year) : ''),
              result: displayPerformance(stat.performance, getEventFormatType(stat.event)),
              isCombineEvent: stat.date ? !!stat.date && (getYearFromDate(stat.date) <= (graduationYear - 3)) : !!stat.year && (stat.year <= (graduationYear - 3)),
            }}
            values={[
              {
                percentile: Math.round(get(stat, 'hsClassStats.percentile', 0)),
                value: `${get(stat, 'hsClassStats.playerRank', 0)}/${get(stat, 'hsClassStats.playerCount', 0)}`,
              },
              {
                percentile: Math.round(get(stat, 'div1Stats.percentile', 0)),
                value: `${get(stat, 'div1Stats.playerRank', 0)}/${get(stat, 'div1Stats.playerCount', 0)}`,
              },
              {
                percentile: Math.round(get(stat, 'teamStats.percentile', 0)),
                value: `${get(stat, 'teamStats.playerRank', 0)}/${get(stat, 'teamStats.playerCount', 0)}`,
                comparisonGroup: RanksComparisonGroup.COLLEGE_TEAM,
              },
              {
                percentile: Math.round(get(stat, 'conferenceStats.percentile', 0)),
                value: `${get(stat, 'conferenceStats.playerRank', 0)}/${get(stat, 'conferenceStats.playerCount', 0)}`,
                comparisonGroup: RanksComparisonGroup.COLLEGE_CONFERENCE,
              },
              {
                percentile: Math.round(get(stat, 'nflDraftStats.percentile', 0)),
                value: `${get(stat, 'nflDraftStats.playerRank', 0)}/${get(stat, 'nflDraftStats.playerCount', 0)}`,
                comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
              },
            ]}
            bottomSpace
            noTopBorder
          />
          ))}
      </Accordion>
    );
  }

  function renderNFLStats (name:string, stats: PlayerNFLPAIStats[]) {
    if (!stats || !stats.length) return null;

    return (
      <Accordion
        key={name}
        summary={name}
        expanded
      >
        {stats.map((stat:PlayerNFLPAIStats, index) => (
          <Ranks
            key={index}
            type={RanksType.PAI}
            playerId={player && player.id}
            teamId={nflTeam && nflTeam.id}
            position={position}
            comparisonEvent={stat.event}
            event={{
              type: getEventName(stat.event),
              name: stat.meet
              // ? `${stat.meet} ${stat.year ? `(${stat.year})` : ''}`
              //   : (stat.year ? String(stat.year) : ''),
                ? `${stat.meet} ${!!stat.date ? `(${formatDate(stat.date)})` : stat.year ? `(${stat.year})` : ''}`
                : (stat.date ? formatDate(stat.date) : stat.year ? String(stat.year) : ''),
              result: displayPerformance(stat.performance, getEventFormatType(stat.event)),
              isCombineEvent: !!stat.year && stat.year === graduationYear - 3,
            }}
            values={[
              {
                percentile: Math.round(get(stat, 'collegeTeamStats.percentile', 0)),
                value: `${get(stat, 'collegeTeamStats.playerRank', 0)}/${get(stat, 'collegeTeamStats.playerCount', 1)}`,
              },
              {
                percentile: Math.round(get(stat, 'collegeConferenceStats.percentile', 0)),
                value: `${get(stat, 'collegeConferenceStats.playerRank', 0)}/${get(stat, 'collegeConferenceStats.playerCount', 1)}`,
              },
              {
                percentile: Math.round(get(stat, 'nflTeamStats.percentile', 0)),
                value: `${get(stat, 'nflTeamStats.playerRank', 0)}/${get(stat, 'nflTeamStats.playerCount', 1)}`,
                comparisonGroup: RanksComparisonGroup.NFL_TEAM,
              },
              {
                percentile: Math.round(get(stat, 'nflUDFAStats.percentile', 0)),
                value: `${get(stat, 'nflUDFAStats.playerRank', 0)}/${get(stat, 'nflUDFAStats.playerCount', 1)}`,
                comparisonGroup: RanksComparisonGroup.NFL_UDFA,
              },
              {
                percentile: Math.round(get(stat, 'nflDraftStats.percentile', 0)),
                value: `${get(stat, 'nflDraftStats.playerRank', 0)}/${get(stat, 'nflDraftStats.playerCount', 1)}`,
                comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
              },
            ]}
            bottomSpace
            noTopBorder
          />
          ))}
      </Accordion>
    );
  }

  return (
    <NamedBox
      className={classes.playerTrackAndFieldData}
      name={`Track & Field Data - ${position}`}
    >
      <Loader inProgress={loading} />

      {!isNFL && (
        <Ranks
          headerItems={[
            { title: 'HS Class', subtitle: `(${recruitingClass})` },
            { title: 'Division-I', subtitle: '(Since 2010)' },
            { logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt },
            { logo: get(collegeTeam, 'conference.iconUrl', '') },
            { logo: '/images/nfl-draft.png' },
          ]}
        />
      )}
      {isNFL && (
        <Ranks
          headerItems={[
            {
              logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt,
              subtitle: '(Since 2010)',
            },
            {
              logo: get(collegeTeam, 'conference.iconUrl', ''),
              subtitle: '(Since 2010)',
            },
            { logo: nflTeam?.logo247 || nflTeam?.logoESPN || nflTeam?.logoAlt },
            { title: 'NFL', subtitle: 'UDFA' },
            { logo: '/images/nfl-draft.png' },
          ]}
        />
      )}

      {isNFL
        ? [
          renderNFLStats('Sprints', sprints as PlayerNFLPAIStats[]),
          renderNFLStats('Relays', relays as PlayerNFLPAIStats[]),
          renderNFLStats('Hurdles', hurdles as PlayerNFLPAIStats[]),
          renderNFLStats('Jumps', jumps as PlayerNFLPAIStats[]),
          renderNFLStats('Throws', throws as PlayerNFLPAIStats[]),
        ]
      : [
          renderCollegeStats('Sprints', sprints as PlayerPAIStats[]),
          renderCollegeStats('Relays', relays as PlayerPAIStats[]),
          renderCollegeStats('Hurdles', hurdles as PlayerPAIStats[]),
          renderCollegeStats('Jumps', jumps as PlayerPAIStats[]),
          renderCollegeStats('Throws', throws as PlayerPAIStats[]),
        ]
      }
    </NamedBox>
  );
}
