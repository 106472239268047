import React from 'react';
import FormPageTemplate from '../templates/FormPageTemplate';
import CreatePasswordForm from '../molecules/CreatePasswordForm';

export default function CreatePasswordPage () {
  return (
    <FormPageTemplate>
      <CreatePasswordForm />
    </FormPageTemplate>
  );
}
