export enum PositionGroup {
  OFFENSE = 'offense',
  DEFENSE = 'defense',
  SPECIAL = 'special',
}

export enum OffensivePosition {
  C = 'C',
  OG = 'OG',
  OT = 'OT',
  QB = 'QB',
  FB = 'FB',
  WR = 'WR',
  RB = 'RB',
  TE = 'TE',
}

export enum SpecialPosition {
  P = 'P',
  K = 'K',
  LS = 'LS',
  H = 'H',
  KR = 'KR',
  PR = 'PR',
}

export enum DefensivePosition {
  DT = 'DT',
  DE = 'DE',
  LB = 'LB',
  MLB = 'MLB',
  OLB = 'OLB',
  CB = 'CB',
  DB = 'DB',
  S = 'S',
  SS = 'SS',
  FS = 'FS',
  RC = 'RC',
}

type Position = OffensivePosition | DefensivePosition | SpecialPosition;

export default Position;
