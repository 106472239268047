import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from './Page';
import SideBySidePageTemplate from '../templates/SideBySidePageTemplate';

export default function SideBySidePage () {
  const location = useLocation();
  const printed = location.pathname.endsWith('/pdf');

  return (
    <Page
       printed={printed}
       title='Side By Side'
    >
      <SideBySidePageTemplate printed={printed} />
    </Page>
  )
}
