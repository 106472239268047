import React, { useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Router from './routes/Router';
import { setSideDrawerOpenAction, setScreenSizeAction } from './redux/actions/ui';
import { State } from './redux/reducers';
import { getScreenSize, isDesktopLarge, ScreenSize } from './services/screen-size';
import { FONT_PROXIMA_NOVA } from './styles/fonts';

interface AppProps {
  setSideDrawerOpenAction: (open:boolean) => void,
  setScreenSizeAction: (screenSize:ScreenSize) => void,
  screenSize: ScreenSize,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: 16,
  },
}), { name: App.name });

function App (props: AppProps) {
  const {
    setSideDrawerOpenAction,
    setScreenSizeAction,
    screenSize,
  } = props;
  const classes = useStyles();
  const throttledOnResize = useCallback(throttle(onResize, 200), []);

  useEffect(() => {
    window.addEventListener('resize', throttledOnResize);

    onResize();

    return () => onUnmount();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isDesktopLarge(screenSize)) {
        setSideDrawerOpenAction(true);
      } else {
        setSideDrawerOpenAction(false);
      }
    }, 1000);
  }, [screenSize]);

  function onUnmount () {
    window.removeEventListener('resize', throttledOnResize);
  }

  function onResize () {
    setScreenSizeAction(getScreenSize());
  }

  return (
    <div className={classes.root}>
      <CssBaseline/>

      <Router />
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    screenSize: state.ui.screenSize,
  };
};

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      setSideDrawerOpenAction,
      setScreenSizeAction,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
