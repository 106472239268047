import React from 'react';
import Icon from '../atoms/Icon';

function VolleyballIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(4, 4)' stroke='currentColor' strokeWidth='2'>
          <path d='M12,0 C5.4,0 0,5.4 0,12 C0,18.6 5.4,24 12,24 C18.6,24 24,18.6 24,12 C24,5.4 18.6,0 12,0 L12,0 Z' />
          <path
            d='M7.325,1.664 C7.325,3.208 7.968,7.839 12.085,11.956 M5.396,21.348 C6.683,20.447 10.414,17.746 11.957,12.085 M23.407,13.115 C21.992,12.343 17.746,10.542 12.085,12.086 M7.84,4.495 C8.612,4.109 14.916,2.051 21.349,5.138 M9.384,8.354 C10.156,7.968 16.203,5.524 23.15,9.383 M7.711,19.547 C7.068,19.032 1.536,14.144 1.793,6.682 M10.285,16.074 C9.642,15.559 4.367,11.571 4.238,3.723 M20.577,12.085 C20.577,12.728 20.062,19.804 13.758,23.535 M16.46,11.442 C16.46,12.343 15.559,18.904 8.741,22.892'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(VolleyballIcon);
