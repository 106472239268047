import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PlusIcon from '../icons/PlusIcon';
import RemoveIcon from '../icons/RemoveIcon';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import TableHeadCell from '../atoms/TableHeadCell';
import Action from '../atoms/Action';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import ColorCode from '../atoms/ColorCode';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import gql from '../services/gql';
import { datetimeToDate } from '../services/converter';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { State } from '../redux/reducers';
import Message from '../types/Message';
import User from '../types/User';
import { Color } from '../types/Color';
import { Order } from '../types/Order';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_BORDER,
} from '../styles/colors';
import MEDIA from '../styles/media';

interface MessagesTableProps {
  className?: string;
  adminAccess?: boolean;
  user?: User;
}

export const MESSAGES_TYPE = {
  INBOX: 'inbox',
  SENT: 'sent',
  DRAFT: 'draft',
};

const TABS = [
  MESSAGES_TYPE.INBOX,
  MESSAGES_TYPE.SENT,
  MESSAGES_TYPE.DRAFT,
];

const TAB_TITLE = {
  [MESSAGES_TYPE.INBOX]: 'Your Messages',
  [MESSAGES_TYPE.SENT]: 'Sent Messages',
  [MESSAGES_TYPE.DRAFT]: 'Draft Messages',
};

const MESSAGES_COLUMN = {
  SUBJECT: 'subject',
  MESSAGE: 'text',
  DATE: 'date',
};

const MESSAGES_ALL_COLUMNS = [
  MESSAGES_COLUMN.SUBJECT,
  MESSAGES_COLUMN.MESSAGE,
  MESSAGES_COLUMN.DATE,
];

const MESSAGES_COLUMN_TITLE = {
  [MESSAGES_COLUMN.SUBJECT]: 'Subject',
  [MESSAGES_COLUMN.MESSAGE]: 'Message',
  [MESSAGES_COLUMN.DATE]: 'Date',
};

const STORAGE_KEY_MAPPING = {
  [MESSAGES_TYPE.INBOX]: StorageKey.MESSAGE_INBOX_TABLE,
  [MESSAGES_TYPE.SENT]: StorageKey.MESSAGE_SENT_TABLE,
  [MESSAGES_TYPE.DRAFT]: StorageKey.MESSAGE_DRAFT_TABLE,
};

const useStyles = makeStyles((theme: Theme) => ({
  messagesTable: {
    position: 'relative',
  },

  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  buttons: {
    position: 'absolute',
    top: '-66px',
    right: theme.spacing(0),
    display: 'flex',
    minHeight: '50px',
  },
  button: {
    boxSizing: 'border-box',
    marginRight: theme.spacing(2.5),

    '&:last-child': {
      marginRight: 0,
    },
  },
  newMessageBtn: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLOR_BLUE,
    borderRadius: theme.typography.pxToRem(5),
    color: COLOR_WHITE,
    textDecoration: 'none',
    padding: theme.spacing(1.25),

    '&:hover': {
      borderColor: COLOR_DARK_BLUE,
      background: COLOR_DARK_BLUE,
    }
  },
  plusIcon: {
    width: '15px',
    height: '15px',
    marginRight: theme.spacing(1),
  },

  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '56px',
  },
  tabs: {
    minHeight: 'initial',
    marginLeft: 'auto',
  },
  tabsIndicator: {
    height: '1px',
  },
  tab: {
    minWidth: 'auto',
    minHeight: '50px',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    fontFamily: FONT_PROXIMA_NOVA,
    borderBottom: '1px solid transparent',
    color: COLOR_TEXT,
    textTransform: 'none',
    transition: 'border 0.3s',

    '&:hover': {
      borderBottomColor: COLOR_DARK_GRAY,
    },
  },

  tableWrapper: {
    overflow: 'auto',
  },
  table: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  actions: {
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  action: {
    marginLeft: theme.spacing(0.5),
    fontSize: '18px',

    '&:first-of-type': {
      marginLeft: 0,
      paddingLeft: 0,
    },

    '&:hover': {
      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  actionIcon: {
    width: '32px',
    height: '32px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
  },
  disabledAction: {
    outlineColor: 'transparent',
    color: COLOR_DARK_GRAY,
    textDecoration: 'none',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionSelected: {
    '&:before': {
      display: 'block',
    },
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },
  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(16),
    border: 0,
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },

  messageLink: {
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0, 5),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    width: '100%',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },

  subject: {
    maxWidth: '200px'
  },
  message: {
    width: '100%',
    maxWidth: '620px'
  },

  noData: {
    color: COLOR_DARK_GRAY,
    textAlign: 'center',
  },

  colorCode: {
    width: '8px',
    height: '8px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: 'calc(100% - 4px)',
    transform: 'translateY(-50%)',
    marginLeft: '2px',
  },

  [MEDIA.MOBILE]: {
    header: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },

    buttons: {
      position: 'relative',
      top: 0,
      flexWrap: 'wrap',
      minHeight: 0,
      marginBottom: theme.spacing(2),
    },

    button: {
      marginTop: theme.spacing(1),
    },

    tabsWrapper: {
      width: '100%',
    },
    tabs: {
      marginLeft: 0,
    },

    actions: {
      flexWrap: 'wrap',
      padding: theme.spacing(1.5),
    },

    dropdowns: {
      width: '100%',
      marginLeft: 0,
    },
    dropdownWrapper: {
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP]: {
    button: {
      width: '170px',
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: 'auto',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
      textAlign: 'left',
    },

    pagination: {
      maxWidth: '60%',
    },
  },

  '@media (min-width: 1601px)': {
    header: {
      alignItems: 'flex-end',
      position: 'absolute',
      top: '-65px',
      left: 0,
      height: '65px',
    },

    buttons: {
      top: 0,
    },
  },
}), { name: MessagesTable.name });

function MessagesTable (props:MessagesTableProps) {
  const {
    className,
    adminAccess = false,
    user,
  } = props;
  const classes = useStyles();

  const history = useHistory();
  const { tab = MESSAGES_TYPE.INBOX } = useParams<any>();

  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).currentPage || 1);
  const [limit, setLimit] = useState<number>((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).limit || 10);
  const [sortedByColumn, setSortedByColumn] = useState<string>(MESSAGES_COLUMN.SUBJECT);
  const [order, setOrder] = useState<Order | undefined>();
  const [selectedTab, setSelectedTab] = useState<number>(TABS.indexOf(tab) || 0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedItems, setSelectedItems] = useState<Message[]>([]);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (TABS[selectedTab] !== tab) {
      setSelectedTab(TABS.indexOf(tab));
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    clientStorage.save(STORAGE_KEY_MAPPING[tab], {currentPage, limit});
  }, [currentPage, limit]);

  useEffect(() => {
    let currentTab = TABS[selectedTab];
    setLoading(true);

    if (currentTab === MESSAGES_TYPE.SENT) {
      fetchSentMessages();
    } else if (currentTab === MESSAGES_TYPE.DRAFT) {
      fetchDraftMessages();
    } else {
      fetchInboxMessages();
    }

    setLimit((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).limit || 10);
    setPrevLimit((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).limit || 10);
    setCurrentPage((clientStorage.get(STORAGE_KEY_MAPPING[tab]) || {}).currentPage || 2);
  }, [selectedTab]);

  useEffect (() => {
    if (limit !== prevLimit) {
      if (limit * currentPage > messages.length) {
        setCurrentPage(1);
      }
      setPrevLimit(limit);
    }
  }, [limit, messages, currentPage]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function fetchInboxMessages() {
    gql(`messages {
      id
      userId
      subject
      text
      isRead
      messageToBeSentId
      createdAt
      updatedAt
    }`)
    .then((data: any) => data.messages as Message[])
    .then((messages: Message[]) => setMessages(messages))
    .catch(() => {
      showToast('Failed to fetch messages. Try again.', ToastType.ERROR);
    })
    .finally(() => setLoading(false));
  }

  function fetchSentMessages() {
    gql(`
    sentMessages {
      id
      subject
      text
      to
      isDraft
      createdBy
      emailStatus
      createdAt
      updatedAt
    }
    `)
    .then((data: any) => data.sentMessages as Message[])
    .then((messages: Message[]) => setMessages(messages))
    .catch(() => {
      showToast('Failed to fetch messages. Try again.', ToastType.ERROR);
    })
    .finally(() => setLoading(false));
  }

  function fetchDraftMessages() {
    gql(`
    draftMessages {
      id
      subject
      text
      to
      isDraft
      createdBy
      emailStatus
      createdAt
      updatedAt
    }
    `)
    .then((data: any) => data.draftMessages as Message[])
    .then((messages: Message[]) => setMessages(messages))
    .catch(() => {
      showToast('Failed to fetch messages. Try again.', ToastType.ERROR);
    })
    .finally(() => setLoading(false));
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onCheckBoxChange (checkedItem:Message) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onDelete () {
    const messagesIdsToDelete = selectedItems.map((message: Message) => message.id);
    const deleteMessages = isDraft ? 'deleteDraftMessages' : 'deleteMessages';

    if (messagesIdsToDelete.length) {
      setLoading(true);

      gql(`
        mutation {
          ${deleteMessages} (ids: [${messagesIdsToDelete}])
        }`
      )
      .then((data: any) => data[deleteMessages] as boolean)
      .then((success: boolean) => {
        if (success) {
          showToast('Deleted', ToastType.SUCCESS);

          if (isDraft)
            fetchDraftMessages();
          else
            fetchInboxMessages();
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
    }

    setSelectedItems([]);
  }

  function onSort (columnName:string, newOrder:Order, sort:(messages: Message[], order: Order) => Message[]) {
    setSortedByColumn(columnName);
    setMessages(sort(messages, newOrder));
  }

  function sortMessages (
    messages: Message[],
    order: Order,
    getValue: (message: Message) => string | number | Date,
  ) {
    return messages.sort((first:Message, second:Message) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function renderRow (item:Message) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id);

    return (
      <TableRow
        key={item.id}
        className={classes.tableRow}
      >
        {(TABS[selectedTab] !== MESSAGES_TYPE.SENT) && (
          <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
            <Checkbox
              checked={checked}
              onChange={onCheckBoxChange(item)}
            />

            {(!item.isRead && !isDraft) && (
              <ColorCode
                className={classes.colorCode}
                color={Color.ORANGE}
              />
            )}
          </TableCell>
        )}
        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.className,
              classes.truncated
            )}
          >
            {tableColumn.renderContent(item)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function onTabChange (event: React.ChangeEvent<{}>, newValue: number) {
    history.push(`/messages/${TABS[newValue]}${history.location.search}`);
    setSelectedTab(newValue);
  }

  function onSortByColumn (sort:(messages: Message[], order:Order) => Message[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  const isDraft = TABS[selectedTab] === MESSAGES_TYPE.DRAFT;
  const deleteActionDisabled = !selectedItems.length;
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = messages.length < limit ? messages.length : (limit * currentPage);
  const totalPages = Math.ceil(messages.length / limit);
  const tableColumns = MESSAGES_ALL_COLUMNS.map((column:string) => {
    switch (column) {
      case MESSAGES_COLUMN.SUBJECT:
        return {
          value: MESSAGES_COLUMN.SUBJECT,
          title: MESSAGES_COLUMN_TITLE[MESSAGES_COLUMN.SUBJECT],
          sortable: true,
          className: classes.subject,
          renderContent: (message: Message) => (
            <Link
              className={classes.messageLink}
              to={`/message/${TABS[selectedTab]}/${message.id}`}
            >
              {message.subject}
            </Link>
          ),
          sort: (messages: Message[], order:Order) => {
            return sortMessages(
              messages,
              order,
              (message:Message) => `${message.subject}`
            );
          },
        };
      case MESSAGES_COLUMN.MESSAGE:
        return {
          value: MESSAGES_COLUMN.MESSAGE,
          title: MESSAGES_COLUMN_TITLE[MESSAGES_COLUMN.MESSAGE],
          sortable: true,
          className: classes.message,
          renderContent: (message: Message) => message.text,
          sort: (messages: Message[], order:Order) => {
            return sortMessages(
              messages,
              order,
              (message:Message) => `${message.text}`
            );
          },
        };
      case MESSAGES_COLUMN.DATE:
        return {
          value: MESSAGES_COLUMN.DATE,
          title: MESSAGES_COLUMN_TITLE[MESSAGES_COLUMN.DATE],
          sortable: true,
          renderContent: (message: Message) => TABS[selectedTab] === MESSAGES_TYPE.INBOX ? datetimeToDate(message.createdAt) : datetimeToDate(message.updatedAt),
          sort: (messages: Message[], order:Order) => {
            return sortMessages(
              messages,
              order,
              (message: Message) =>  TABS[selectedTab] === MESSAGES_TYPE.INBOX ? new Date(message.createdAt) : new Date(message.updatedAt)
            );
          },
        };
      default:
        return null;
    }
  });

  return (
    <>
      <div className={clsx(classes.messagesTable, className)}>
        <Loader inProgress={loading} />

        {adminAccess && (
          <div className={classes.header}>
            <div className={classes.buttons} >
              {(user.accessLevel.canSendMessages) && (
                <Link
                  className={clsx(classes.button, classes.newMessageBtn)}
                  to='/message/new'
                >
                  <PlusIcon className={classes.plusIcon} />
                  New Message
                </Link>
              )}
            </div>

            <div className={classes.tabsWrapper}>
              <Tabs
                className={classes.tabs}
                classes={{ indicator: classes.tabsIndicator }}
                value={selectedTab}
                onChange={onTabChange}
                variant='scrollable'
              >
                {TABS.map((tab:string, index:number) => (
                  <Tab
                    key={index}
                    className={classes.tab}
                    label={TAB_TITLE[tab]}
                    disableRipple
                  />
                ))}
              </Tabs>
            </div>
          </div>
        )}

        <div className={classes.actions}>
          <Action
            className={clsx(classes.action, deleteActionDisabled && classes.disabledAction)}
            icon={RemoveIcon}
            iconClassName={classes.actionIcon}
            disabled={deleteActionDisabled}
            onClick={onDelete}
          >
            <span className={classes.actionText}>Delete</span>
          </Action>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Messages
            </div>
          </div>
        </div>

        <div className={classes.tableWrapper}>
          <Table className={clsx(classes.table)}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {(TABS[selectedTab] !== MESSAGES_TYPE.SENT) && (
                  <TableHeadCell
                    key='check-box-cell'
                    className={classes.checkBoxCell}
                  >
                    <Checkbox
                      checked={selectedItems.length === messages.length}
                      onChange={() => selectedItems.length === messages.length
                        ? setSelectedItems([])
                        : setSelectedItems([...messages])
                      }
                    />
                  </TableHeadCell>
                )}

                {tableColumns.map((tableColumn:any) => (
                  <TableHeadCell
                    key={tableColumn.value}
                    name={tableColumn.sortable ? tableColumn.value : undefined}
                    sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                    order={tableColumn.sortable ? order : undefined}
                    onSort={tableColumn.sortable ? onSortByColumn(tableColumn.sort || ((messages:Message[]) => messages)) : undefined}
                    className={clsx(tableColumn.className, classes.truncated)}
                  >
                    {tableColumn.title}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {messages.length ?
                messages
                  .slice(itemsFrom, itemsTo)
                  .map(renderRow)
                :
                <TableRow>
                  <TableCell
                    className={clsx(classes.tableCell)}
                    colSpan={tableColumns.length + 1}
                  >
                    <div className={classes.noData}>No Messages</div>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </div>

        <div className={classes.paginationWrapper}>
          <span className={classes.paginationText}>
            Showing {itemsFrom + 1} to {itemsTo} of {messages.length}
          </span>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={page => setCurrentPage(page)}
            />
          )}
        </div>
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
}

const mapStateToProps = (state:State) => {
  return {
    adminAccess: state.ui.adminAccess,
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(MessagesTable);
