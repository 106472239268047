import React from 'react';
import Icon from '../atoms/Icon';

function HandIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M16.857 0a3.43 3.43 0 013.309 2.527 3.428 3.428 0 014.685 2.986l.006.201v9.143H22.57V5.714a1.142 1.142 0 00-2.278-.133l-.008.133v9.143H18V3.43a1.142 1.142 0 10-2.286 0v11.428h-2.286V5.714a1.142 1.142 0 00-2.278-.133l-.007.133v11.429H8.857V5.714a3.428 3.428 0 014.693-3.188A3.425 3.425 0 0116.857 0z'/>
        <path d='M8.857 18.009l-1.005-1.005a3.428 3.428 0 10-4.848 4.849l7.762 7.74C12.291 31.113 14.13 32 16.143 32h5.285a8 8 0 008-8V10.286a3.428 3.428 0 10-6.857 0v4.571h2.286v-4.571a1.142 1.142 0 112.286 0V24a5.714 5.714 0 01-5.715 5.714h-5.285c-1.356 0-2.639-.62-3.763-1.74l-7.76-7.739a1.142 1.142 0 011.616-1.614l4.907 4.906v-6.384H8.857v.866z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(HandIcon);
