import React from 'react';
import ModalPageTemplate from '../templates/ModalPageTemplate';
import OptOut from '../organisms/OptOut';

export default function OptOutPage () {
  return (
    <ModalPageTemplate>
      <OptOut />
    </ModalPageTemplate>
  );
}
