import React, { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Stars from '../atoms/Stars';
import LocationIcon from '../atoms/LocationIcon';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import ColorCode from '../atoms/ColorCode';
import Tooltip from '../atoms/Tooltip';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import TeamLogo from '../atoms/TeamLogo';
import Links from '../molecules/Links';
import NewPlayerColorCode from '../molecules/NewPlayerColorCode';
import MultiSportList from '../molecules/MultiSportList';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import FreshmanIcon from '../icons/FreshmanIcon';
import { isDesktop, ScreenSize } from '../services/screen-size';
import { inchesToFeetAndInches, datetimeToDate, secondsToMinutesSecondsMs } from '../services/converter';
import { State } from '../redux/reducers';
import {
  getPrimaryHighSchool,
  getPrimaryCollege,
  getPrimaryNFLTeam,
  getBestCombineEventForColumn,
  prepareLinks,
} from '../services/player';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_ORANGE,
  COLOR_GREEN,
  COLOR_TEXT,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import Team from '../types/Team';
import { PlayerColumn } from '../types/PlayerColumn';
import { Order } from '../types/Order';
import Avatar from '../atoms/Avatar';
import { Color } from '../types/Color';

interface SearchPlayerTableProps {
  className?: string;
  items: Player[];
  limit: number;
  totalPlayersCount: number;
  columns: string[];
  selectedItems: Player[];
  currentPage: number;
  order: Order | undefined;
  sortedByColumn: PlayerColumn | undefined;
  collegeTeams: Team[];
  loading: boolean;
  screenSize: ScreenSize;
  setCurrentPage: (page:number) => void;
  setSelectedItems: (items:Player[]) => void;
  onSort: (sortedByColumn:PlayerColumn) => void;
}

interface ColumnConfiguration {
  value: PlayerColumn;
  title: any;
  className: string;
  sticky: boolean;
  sortable: boolean;
  truncated: boolean;
  renderContent: (player:Player) => any;
}

export const PLAYER_DEFAULT_COLUMNS = [
  PlayerColumn.NAME,
  PlayerColumn.LINKS,
  PlayerColumn.HS_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.STARS,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_STATE,
  PlayerColumn.HS_CLASS,
  PlayerColumn.HEIGHT,
  PlayerColumn.WEIGHT,
  PlayerColumn.HS_SPORTS,
];

export const HS_PLAYER_DEFAULT_COLUMNS = [
  PlayerColumn.NAME,
  // PlayerColumn.HS_POSITION,
  // PlayerColumn.PAI_SCORE,
  // PlayerColumn.COMBINE_SCORE,
  // PlayerColumn.HS_SPORTS,
];

export const COLLEGE_PLAYER_DEFAULT_COLUMNS = [
  PlayerColumn.NAME,
  // PlayerColumn.COLLEGE_POSITION,
  // PlayerColumn.PAI_SCORE,
  // PlayerColumn.COMBINE_SCORE,
  // PlayerColumn.HS_SPORTS,
  // PlayerColumn.COLLEGE_NAME,
];

export const NFL_PLAYER_DEFAULT_COLUMNS = [
  PlayerColumn.NAME,
  // PlayerColumn.NFL_POSITION,
  // PlayerColumn.PAI_SCORE,
  // PlayerColumn.COMBINE_SCORE,
  // PlayerColumn.PRO_COMBINE_SCORE,
  // PlayerColumn.PPI_SCORE,
  // PlayerColumn.HS_SPORTS,
  // PlayerColumn.NFL_TEAM,
  // PlayerColumn.DRAFT_YEAR,
  // PlayerColumn.DRAFT_RD,
];

export const BLENDED_PLAYER_DEFAULT_COLUMNS = [
  PlayerColumn.NAME,
  PlayerColumn.HS_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.HS_SPORTS,
  PlayerColumn.COLLEGE_NAME,
  PlayerColumn.COLLEGE_POSITION,
  PlayerColumn.ROSTER_HEIGHT,
  PlayerColumn.ROSTER_WEIGHT,
  PlayerColumn.RECRUITING_CLASS,
  PlayerColumn.NFL_TEAM,
  PlayerColumn.NFL_POSITION,
  PlayerColumn.DRAFT_YEAR,
  PlayerColumn.DRAFT_RD,
  PlayerColumn.TEAM_HEIGHT,
  PlayerColumn.TEAM_WEIGHT,
];

export const HS_PLAYER_COLUMNS = [
  ...HS_PLAYER_DEFAULT_COLUMNS,
  PlayerColumn.HS_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.HS_MPH,
  PlayerColumn.HS_SPORTS,
    
  PlayerColumn.HS_CLASS,
  PlayerColumn.OFFER,
  PlayerColumn.OFFERED_BY,
  PlayerColumn.HEIGHT,
  PlayerColumn.WEIGHT,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_CITY,
  PlayerColumn.HS_STATE,
  PlayerColumn.STARS,
  PlayerColumn.COMMITTED_TO,
  PlayerColumn.LINKS,
  PlayerColumn.LAST_EDITED,
];

export const COLLEGE_PLAYER_COLUMNS = [
  ...COLLEGE_PLAYER_DEFAULT_COLUMNS,
  PlayerColumn.COLLEGE_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.HS_SPORTS,
  PlayerColumn.COLLEGE_NAME,

  PlayerColumn.ROSTER_HEIGHT,
  PlayerColumn.ROSTER_WEIGHT,
  PlayerColumn.RECRUITING_CLASS,
  PlayerColumn.HS_CLASS,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_CITY,
  PlayerColumn.HS_STATE,
  PlayerColumn.STARS,
  PlayerColumn.LINKS,
  PlayerColumn.LAST_EDITED,
];

export const NFL_PLAYER_COLUMNS = [
  ...NFL_PLAYER_DEFAULT_COLUMNS,
  PlayerColumn.NFL_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.PRO_COMBINE_SCORE,
  PlayerColumn.PPI_SCORE,
  PlayerColumn.HS_SPORTS,
  PlayerColumn.NFL_TEAM,
  PlayerColumn.DRAFT_YEAR,
  PlayerColumn.DRAFT_RD,
  PlayerColumn.WATCH_LIST,
  PlayerColumn.WATCH_LIST_YEAR,

  PlayerColumn.TEAM_HEIGHT,
  PlayerColumn.TEAM_WEIGHT,
  PlayerColumn.COLLEGE_NAME,
  PlayerColumn.RECRUITING_CLASS,
  PlayerColumn.HS_CLASS,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_CITY,
  PlayerColumn.HS_STATE,
  PlayerColumn.STARS,
  PlayerColumn.LINKS,
  PlayerColumn.LAST_EDITED,
];

export const BLENDED_PLAYER_COLUMNS = [
  PlayerColumn.HS_CLASS,
  PlayerColumn.OFFER,
  PlayerColumn.OFFERED_BY,
  PlayerColumn.HEIGHT,
  PlayerColumn.WEIGHT,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_CITY,
  PlayerColumn.HS_STATE,
  PlayerColumn.STARS,
  PlayerColumn.COMMITTED_TO,
  PlayerColumn.LINKS,
  PlayerColumn.LAST_EDITED,
];

export const PLAYER_ALL_COLUMNS = [
  PlayerColumn.NAME,
  PlayerColumn.HS_POSITION,
  PlayerColumn.PAI_SCORE,
  PlayerColumn.COMBINE_SCORE,
  PlayerColumn.HS_MPH,
  PlayerColumn.PRO_COMBINE_SCORE,
  PlayerColumn.PPI_SCORE,
  PlayerColumn.HS_SPORTS,
  PlayerColumn.HS_CLASS,
  PlayerColumn.OFFER,
  PlayerColumn.OFFERED_BY,
  PlayerColumn.HEIGHT,
  PlayerColumn.WEIGHT,
  PlayerColumn.HS_NAME,
  PlayerColumn.HS_CITY,
  PlayerColumn.HS_STATE,
  PlayerColumn.STARS,
  PlayerColumn.COMMITTED_TO,
  PlayerColumn.LINKS,
  PlayerColumn.LAST_EDITED,
  PlayerColumn.COLLEGE_NAME,
  PlayerColumn.COLLEGE_POSITION,
  PlayerColumn.ROSTER_HEIGHT,
  PlayerColumn.ROSTER_WEIGHT,
  PlayerColumn.RECRUITING_CLASS,
  PlayerColumn.NFL_POSITION,
  PlayerColumn.NFL_TEAM,
  PlayerColumn.DRAFT_YEAR,
  PlayerColumn.DRAFT_RD,
  PlayerColumn.TEAM_HEIGHT,
  PlayerColumn.TEAM_WEIGHT,
];

export const PLAYER_COLUMN_TITLE = {
  [PlayerColumn.NAME]: 'Name',
  [PlayerColumn.LINKS]: 'Links',
  [PlayerColumn.HS_POSITION]: <>HS&nbsp;Position</>,
  [PlayerColumn.PAI_SCORE]: 'PAI',
  [PlayerColumn.COMBINE_SCORE]: <>HS&nbsp;Com.</>,
  [PlayerColumn.HS_MPH]: <>HS&nbsp;MPH</>,
  [PlayerColumn.PPI_SCORE]: 'PPI',
  [PlayerColumn.PRO_COMBINE_SCORE]: <>Pro&nbsp;Com.</>,
  [PlayerColumn.STARS]: 'Stars',
  [PlayerColumn.HS_NAME]: <>High&nbsp;School</>,
  [PlayerColumn.HS_STATE]: <>HS&nbsp;State</>,
  [PlayerColumn.HS_CLASS]: <>HS&nbsp;Class</>,
  [PlayerColumn.WEIGHT]: 'Weight',
  [PlayerColumn.HEIGHT]: 'Height',
  [PlayerColumn.HS_SPORTS]: <>HS&nbsp;Sports</>,
  [PlayerColumn.OFFER]: 'Offers',
  [PlayerColumn.HS_CITY]: 'City',
  [PlayerColumn.TRACK_55M]: '55M',
  [PlayerColumn.TRACK_60M]: '60M',
  [PlayerColumn.TRACK_100M]: '100M',
  [PlayerColumn.TRACK_200M]: '200M',
  [PlayerColumn.TRACK_300M]: '300M',
  [PlayerColumn.TRACK_400M]: '400M',
  [PlayerColumn.TRACK_800M]: '800M',
  [PlayerColumn.TRACK_1600R]: '1600R',
  [PlayerColumn.TRACK_3200R]: '3200R',
  [PlayerColumn.TRACK_55HH]: '55HH',
  [PlayerColumn.TRACK_60HH]: '60HH',
  [PlayerColumn.TRACK_110HH]: '110HH',
  [PlayerColumn.TRACK_300IH]: '300IH',
  [PlayerColumn.TRACK_400H]: '400H',
  [PlayerColumn.TRACK_400R]: '400R',
  [PlayerColumn.TRACK_800R]: '800R',
  [PlayerColumn.TRACK_SHOTPUT]: 'Shot Put',
  [PlayerColumn.TRACK_DISCUS]: 'Discus',
  [PlayerColumn.TRACK_JAVELIN]: 'Javelin',
  [PlayerColumn.TRACK_HIGH_JUMP]: <>High&nbsp;Jump</>,
  [PlayerColumn.TRACK_LONG_JUMP]: <>Long&nbsp;Jump</>,
  [PlayerColumn.TRACK_TRIPLE_JUMP]: <>Triple&nbsp;Jump</>,
  [PlayerColumn.TRACK_POLE_VAULT]: <>Pole&nbsp;Vault</>,
  [PlayerColumn.OFFERED_BY]: <>Offered&nbsp;By</>,
  [PlayerColumn.COLLEGE_POSITION]: <>College&nbsp;Pos.</>,
  [PlayerColumn.NFL_POSITION]: <>NFL&nbsp;Pos.</>,
  [PlayerColumn.ROSTER_HEIGHT]: <>Roster&nbsp;Height</>,
  [PlayerColumn.ROSTER_WEIGHT]: <>Roster&nbsp;Weight</>,
  [PlayerColumn.RECRUITING_CLASS]: <>Recruiting&nbsp;Class</>,
  [PlayerColumn.NFL_TEAM]: <>Drafted&nbsp;By</>,
  [PlayerColumn.DRAFT_YEAR]: <>Draft&nbsp;Year</>,
  [PlayerColumn.DRAFT_RD]: <>Draft&nbsp;Round</>,
  [PlayerColumn.LAST_EDITED]: <>Last&nbsp;Edited</>,
  [PlayerColumn.COLLEGE_NAME]: <>College&nbsp;Name</>,
  [PlayerColumn.TEAM_HEIGHT]: <>Team&nbsp;Height</>,
  [PlayerColumn.TEAM_WEIGHT]: <>Team&nbsp;Weight</>,
  [PlayerColumn.COMMITTED_TO]: <>Committed&nbsp;School</>,
  [PlayerColumn.COMBINE_ARM]: 'Arm',
  [PlayerColumn.COMBINE_HAND]: 'Hand',
  [PlayerColumn.COMBINE_MPH]: 'MPH',
  [PlayerColumn.COMBINE_BROAD_JUMP]: 'Broad',
  [PlayerColumn.COMBINE_VERTICAL_JUMP]: 'Vertical',
  [PlayerColumn.COMBINE_3_CONE]: <>3&#8209;Cone</>,
  [PlayerColumn.COMBINE_10_SPLIT]: <>10&nbsp;Split</>,
  [PlayerColumn.COMBINE_40M_DASH]: <>40&nbsp;Dash</>,
  [PlayerColumn.COMBINE_WINGSPAN]: 'Wingspan',
  [PlayerColumn.COMBINE_SHUTTLE]: 'Shuttle',
  [PlayerColumn.COMBINE_POWER_TOSS]: <>Power&nbsp;Toss</>,
  [PlayerColumn.WATCH_LIST]: <>Watch&nbsp;List</>,
  [PlayerColumn.WATCH_LIST_YEAR]: <>Watch&nbsp;List&nbsp;Year</>,
};

const useStyles = makeStyles((theme:Theme) => ({
  searchPlayerTable: {
    overflow: 'hidden',
    position: 'relative',
  },

  fixedTableWrapper: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  stickyTableWrapper: {
    width: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: '0 10px 10px 0 rgb(0 0 0 / 5%)',
    overflow: 'hidden',
  },

  playersTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  truncated: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help',
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },

  nameCell: {
    minWidth: '205px',
  },
  hsName: {
    minWidth: '180px',
  },

  textCenter: {
    textAlign: 'center',
  },

  goBackCell: {
    paddingLeft: theme.spacing(1),
  },

  watchListYears: {
    maxWidth: '300px',
  },
  watchListNames: {
    maxWidth: '300px',
  },
  goBack: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },
  goBackIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
    color: 'inherit',
  },

  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  colorCodes: {
    width: '22px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: 'calc(100% - 4px)',
    transform: 'translateY(-50%)',
  },
  colorCode: {
    marginLeft: '2px',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  playerNameWithLocationIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  playerLink: {
    color: COLOR_BLUE,
    paddingLeft: '8px',
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'capitalize',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  playerScore: {
    width: '38px',
    height: '38px',
    fontSize: theme.typography.pxToRem(14),
  },
  freshmanIcon: {
    height: '28px',
    width: '28px',
    transform: 'scale(1.2)',
    cursor: 'help',
  },
  pai: {
    color: COLOR_ORANGE,
    display: 'flex',
    alignItems: 'center',
  },
  combine: {
    color: COLOR_GREEN,
    display: 'flex',
    alignItems: 'center',
  },

  hsMPH: {
    color: COLOR_TEXT,
    display: 'flex',
    alignItems: 'center',
  },

  multiSportIcon: {
    width: '24px',
    height: '24px',
  },
  team: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  teamLogo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },

  offers: {
    width: '300px',
  },

  bottomScrollbarWrapper: {
    visibility: 'hidden',
    width: '100%',
    overflowX: 'scroll',
    zIndex: 1,
  },
  bottomScrollbar: {
    width: '100%',
    height: 1,
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
    },

    pagination: {
      maxWidth: '60%',
    },
  },
}), { name: SearchPlayerTable.name });

function SearchPlayerTable (props:SearchPlayerTableProps) {
  const {
    className,
    items = [],
    limit,
    totalPlayersCount,
    columns,
    selectedItems,
    currentPage,
    order,
    sortedByColumn,
    collegeTeams,
    loading,
    screenSize,
    setCurrentPage = () => {},
    setSelectedItems = () => {},
    onSort = () => {},
  } = props;

  const [stickyColumnsTotalWidth, setStickyColumnsTotalWidth] = useState<number>(0);

  const classes = useStyles();

  useEffect(() => {
    // calculate the total width of sticky columns to restrict the width of the sticky table
    if (!loading) {
      updateStickyColumnsWidth();
    }
  }, [loading, columns, items]);

  function updateStickyColumnsWidth () {
    const stickyColumnsTotalWidth:number = stickyRefs
        .map((ref:any) => ref?.current?.offsetWidth ? ref.current.offsetWidth : 0)
        .reduce((totalWidth, currentWidth) => totalWidth + currentWidth, 0)
      + 1; // include the width of the left border

    setStickyColumnsTotalWidth(stickyColumnsTotalWidth);
  }

  function onCheckBoxChange (checkedItem:Player) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function getHSPositions (player:Player) {
    const primaryHS = getPrimaryHighSchool(player);
    const primaryPosition = ((primaryHS || {}).positions || [])
      .find((position:any) => !!position?.isPrimary);
    const otherPositions = ((primaryHS || {}).positions || [])
      .filter((position:any) => !position?.isPrimary);

    return `${[primaryPosition, ...otherPositions].map(position => position?.code).join('/')}`;
  }

  function getCollegePositions (player:Player) {
    const primaryCollege = getPrimaryCollege(player);
    const primaryPosition = ((primaryCollege || {}).positions || [])
      .find((position:any) => position && position.isPrimary);
    const otherPositions = ((primaryCollege || {}).positions || [])
      .filter((position:any) => !position?.isPrimary);

    return `${[primaryPosition, ...otherPositions].map(position => position?.code).join('/')}`;
  }

  function getNFLPositions (player:Player) {
    const primaryNFLTeam = getPrimaryNFLTeam(player);

    const primaryPosition = ((primaryNFLTeam || {}).positions || [])
      .find((position:any) => position && position.isPrimary);
    const otherPositions = ((primaryNFLTeam || {}).positions || [])
      .filter((position:any) => !position?.isPrimary);

    return `${[primaryPosition, ...otherPositions].map(position => position?.code).join('/')}`;
  }

  function renderPlayerRow (player:Player) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === player.id);
    let colors = [];
    let color = get(player, 'notes[0].colorCode', '');
    colors.push(get(player, 'notes[0].colorCode', ''));
    const isContactInfoAvailable = player && (player?.email || player?.cellPhone || player?.address) ? true : false;
    if (isContactInfoAvailable) {
      color = Color.GREY;
      colors.push(color);
    }

    const inGameRating = {
      mph: player && player.bestHighSchoolMPH?.mph || 0,
     }
    if (inGameRating.mph > 0) {
      color = Color.PURPLE;
      colors.push(color);
    }
    return (
      <TableRow
        key={player.id}
        className={classes.tableRow}
      >
        <TableCell
          key='check-box-cell'
          className={clsx(classes.tableCell, classes.checkBoxCell)}
        >
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(player)}
          />

          <div className={classes.colorCodes}>
            <NewPlayerColorCode
              className={classes.colorCode}
              player={player}
            />

            {(color || colors.length) && (
              <ColorCode
                className={classes.colorCode}
                color={color}
                colors={colors}
                showSearchTooltip={true}
              />
            )}
          </div>
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === PlayerColumn.NAME && classes.nameCell,
              tableColumn.value === PlayerColumn.STARS && classes.textCenter,
            )}
          >
            {tableColumn.renderContent(player)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const totalPages = Math.ceil(totalPlayersCount / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = totalPlayersCount < limit ? totalPlayersCount : (limit * currentPage);

  const tableColumns:ColumnConfiguration[] = columns
    .map((column:string) => {
      switch (column) {
        case PlayerColumn.NAME:
          return {
            value: PlayerColumn.NAME,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.NAME],
            sticky: true,
            sortable: true,
            renderContent: (player:Player) => {
              const primaryHighSchool = getPrimaryHighSchool(player);

              return (
                <div className={classes.playerNameWithLocationIcon}>
                  {player && (
                      <Avatar
                        className={classes.playerAvatar}
                        src={player.photoUrl}
                        alt='Profile picture'
                      />
                    )}
                  <Link
                    className={classes.playerLink}
                    to={`/player/${player.slug}?`}
                    target='_blank'
                  >
                    {player.firstName} {player.lastName}
                  </Link>

                  {primaryHighSchool?.state && (
                    <LocationIcon
                      className={classes.locationIcon}
                      stateCode={primaryHighSchool.state}
                    />
                  )}
                </div>
              );
            },
          } as ColumnConfiguration;
        case PlayerColumn.LINKS:
          return {
            value: PlayerColumn.LINKS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.LINKS],
            sortable: false,
            renderContent: (player:Player) => {
              const links = prepareLinks(player);
              return ( <Links links={links} onlySocial />)
            }
          } as ColumnConfiguration;
        case PlayerColumn.HS_POSITION:
          return {
            value: PlayerColumn.HS_POSITION,
            sticky: (columns[1] === PlayerColumn.HS_POSITION),
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_POSITION],
            sortable: true,
            renderContent: (player:Player) => getHSPositions(player) || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.PAI_SCORE:
          return {
            value: PlayerColumn.PAI_SCORE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.PAI_SCORE],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={classes.pai}>
                <Score
                  className={classes.playerScore}
                  type={SCORE_TYPE.PAI}
                  scoreList={player.pai}
                />

                {player.paiHasFreshmanData && (
                  <Tooltip title='Freshman Data'>
                    <FreshmanIcon className={classes.freshmanIcon} />
                  </Tooltip>
                )}
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_SCORE:
          return {
            value: PlayerColumn.COMBINE_SCORE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_SCORE],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={classes.combine}>
                <Score
                  className={classes.playerScore}
                  type={SCORE_TYPE.COMBINE}
                  scoreList={player.combine}
                  combines={player.hsCombines}
                />

                {player.combineHasFreshmanData && (
                  <Tooltip title='Freshman Data'>
                    <FreshmanIcon className={classes.freshmanIcon} />
                  </Tooltip>
                )}
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.HS_MPH:
          return {
            value: PlayerColumn.HS_MPH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_MPH],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={classes.hsMPH}>
                {player?.bestHighSchoolMPH?.mph || <NotAvailable />}
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.PPI_SCORE:
          return {
            value: PlayerColumn.PPI_SCORE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.PPI_SCORE],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={clsx(classes.score, classes.combineScore)}>
                <Score
                  className={classes.playerScore}
                  type={SCORE_TYPE.PPI}
                  value={player?.ppi || undefined}
                />
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.PRO_COMBINE_SCORE:
          return {
            value: PlayerColumn.PRO_COMBINE_SCORE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.PRO_COMBINE_SCORE],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={clsx(classes.score, classes.combineScore)}>
                <Score
                  className={classes.playerScore}
                  type={SCORE_TYPE.PRO_COMBINE}
                  value={player?.nflCombineScore || undefined}
                  combines={player.nflCombines}
                />
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.STARS:
          return {
            value: PlayerColumn.STARS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.STARS],
            sortable: true,
            renderContent: (player:Player) => {
              const stars = (typeof player?._247Star === 'number' || typeof player?.compStar === 'number')
                ? player?._247Star || player?.compStar || 0
                : null;
              return typeof stars === 'number'
                ? <Stars value={stars}/>
                : <NotAvailable />
            },
          } as ColumnConfiguration;
        case PlayerColumn.HS_NAME:
          return {
            value: PlayerColumn.HS_NAME,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_NAME],
            sortable: true,
            renderContent: (player:Player) => (
              <div className={classes.hsName}>
                {(getPrimaryHighSchool(player) || {}).name || <NotAvailable />}
              </div>
            ),
          } as ColumnConfiguration;
        case PlayerColumn.HS_STATE:
          return {
            value: PlayerColumn.HS_STATE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_STATE],
            sortable: true,
            renderContent: (player:Player) => (getPrimaryHighSchool(player) || {}).state || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.HS_CLASS:
          return {
            value: PlayerColumn.HS_CLASS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_CLASS],
            sortable: true,
            renderContent: (player:Player) => (getPrimaryHighSchool(player) || {}).graduatingClass || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.WEIGHT:
          return {
            value: PlayerColumn.WEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.WEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const highSchool = getPrimaryHighSchool(player);
              return highSchool && highSchool.weight
                ? `${highSchool.weight}lbs`
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.HEIGHT:
          return {
            value: PlayerColumn.HEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const highSchool = getPrimaryHighSchool(player);
              return highSchool && highSchool.height
                ? inchesToFeetAndInches(highSchool.height)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.HS_SPORTS:
          return {
            value: PlayerColumn.HS_SPORTS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_SPORTS],
            sortable: true,
            renderContent: (player:Player) => {
              return !!player.multiSport?.length
                ? (
                  <MultiSportList
                    iconClassName={classes.multiSportIcon}
                    list={player.multiSport}
                  />
                )
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.HS_CITY:
          return {
            value: PlayerColumn.HS_CITY,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.HS_CITY],
            sortable: true,
            renderContent: (player:Player) => (getPrimaryHighSchool(player) || {}).city || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.OFFERED_BY:
          return {
            value: PlayerColumn.OFFERED_BY,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.OFFERED_BY],
            sortable: false,
            renderContent: (player:Player) => {
              const cellContent = (player.offers || [])
                .sort((offer1, offer2) => {
                  if (offer1 < offer2) return -1;
                  if (offer1 > offer2) return 1;

                  return 0;
                })
                .join(', ');

              return (player.offers && !!player.offers.length)
                ? <Tooltip title={cellContent}>
                    <div className={clsx(classes.offers, classes.truncated)}>
                      {cellContent}
                    </div>
                  </Tooltip>
                : <NotAvailable />
            },
          } as ColumnConfiguration;
        case PlayerColumn.OFFER:
          return {
            value: PlayerColumn.OFFER,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.OFFER],
            sortable: true,
            renderContent: (player:Player) => player.offers?.length || 0,
          } as ColumnConfiguration;
        case PlayerColumn.COMMITTED_TO:
          return {
            value: PlayerColumn.COMMITTED_TO,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMMITTED_TO],
            sortable: true,
            renderContent: (player:Player) => {
              const collegeTeam = collegeTeams.find((collegeTeam:Team) => collegeTeam.id === player.committedToTeamId);

              return (player.committedToTeamId && collegeTeam) ? collegeTeam.name : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.LAST_EDITED:
          return {
            value: PlayerColumn.LAST_EDITED,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.LAST_EDITED],
            sortable: true,
            renderContent: (player:Player) => (player.updatedAt) ? datetimeToDate(player.updatedAt) : <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.COLLEGE_NAME:
          return {
            value: PlayerColumn.COLLEGE_NAME,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COLLEGE_NAME],
            sortable: true,
            renderContent: (player:Player) => {
              const college = getPrimaryCollege(player);
              return (college && college.team)
                ? <div className={classes.team}>
                    <TeamLogo
                      className={classes.teamLogo}
                      team={college.team}
                    />
                    {college.team.name}
                  </div>
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COLLEGE_POSITION:
          return {
            value: PlayerColumn.COLLEGE_POSITION,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COLLEGE_POSITION],
            sticky: (columns[1] === PlayerColumn.COLLEGE_POSITION),
            sortable: true,
            renderContent: (player:Player) => getCollegePositions(player) || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.ROSTER_HEIGHT:
          return {
            value: PlayerColumn.ROSTER_HEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.ROSTER_HEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const college = getPrimaryCollege(player);
              return college && college.height
                ? inchesToFeetAndInches(college.height)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.ROSTER_WEIGHT:
          return {
            value: PlayerColumn.ROSTER_WEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.ROSTER_WEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const college = getPrimaryCollege(player);
              return college && college.weight
                ? `${college.weight}lbs`
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.RECRUITING_CLASS:
          return {
            value: PlayerColumn.RECRUITING_CLASS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.RECRUITING_CLASS],
            sortable: true,
            renderContent: (player:Player) => {
              const college = getPrimaryCollege(player);
              return college && college.recruitingClass
                ? college.recruitingClass
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.NFL_TEAM:
          return {
            value: PlayerColumn.NFL_TEAM,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.NFL_TEAM],
            sortable: true,
            renderContent: (player:Player) => {
              const nflTeam = getPrimaryNFLTeam(player);
              return nflTeam && nflTeam.team
                ? <div className={classes.team}>
                    <TeamLogo
                      className={classes.teamLogo}
                      team={nflTeam.team}
                    />
                    {nflTeam.team.name}
                  </div>
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.DRAFT_YEAR:
          return {
            value: PlayerColumn.DRAFT_YEAR,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.DRAFT_YEAR],
            sortable: true,
            renderContent: (player:Player) => {
              const nflTeam = getPrimaryNFLTeam(player);
              return nflTeam && nflTeam.draftYear
                ? nflTeam.draftYear
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.DRAFT_RD:
          return {
            value: PlayerColumn.DRAFT_RD,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.DRAFT_RD],
            sortable: true,
            renderContent: (player:Player) => {
              const nflTeam = getPrimaryNFLTeam(player);
              return typeof nflTeam?.draftRound === 'number'
                ? nflTeam.draftRound
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TEAM_HEIGHT:
          return {
            value: PlayerColumn.TEAM_HEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TEAM_HEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const nflTeam = getPrimaryNFLTeam(player);
              const collegeTeam = getPrimaryCollege(player);
              const teamHeight = nflTeam?.height || collegeTeam?.height;

              return teamHeight
                ? inchesToFeetAndInches(teamHeight)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TEAM_WEIGHT:
          return {
            value: PlayerColumn.TEAM_WEIGHT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TEAM_WEIGHT],
            sortable: true,
            renderContent: (player:Player) => {
              const nflTeam = getPrimaryNFLTeam(player);
              const collegeTeam = getPrimaryCollege(player);
              const teamWeight = nflTeam?.weight || collegeTeam?.weight;

              return teamWeight
                ? `${teamWeight}lbs`
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.NFL_POSITION:
          return {
            value: PlayerColumn.NFL_POSITION,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.NFL_POSITION],
            sticky: (columns[1] === PlayerColumn.NFL_POSITION),
            sortable: true,
            renderContent: (player:Player) => getNFLPositions(player) || <NotAvailable />,
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_55M:
          return {
            value: PlayerColumn.TRACK_55M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_55M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_55M]
                ? Number(trackEvent[PlayerColumn.TRACK_55M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_60M:
          return {
            value: PlayerColumn.TRACK_60M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_60M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_60M]
                ? Number(trackEvent[PlayerColumn.TRACK_60M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_100M:
          return {
            value: PlayerColumn.TRACK_100M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_100M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_100M]
                ? Number(trackEvent[PlayerColumn.TRACK_100M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_200M:
          return {
            value: PlayerColumn.TRACK_200M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_200M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_200M]
                ? Number(trackEvent[PlayerColumn.TRACK_200M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_300M:
          return {
            value: PlayerColumn.TRACK_300M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_300M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_300M]
                ? Number(trackEvent[PlayerColumn.TRACK_300M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_400M:
          return {
            value: PlayerColumn.TRACK_400M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_400M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_400M]
                ? Number(trackEvent[PlayerColumn.TRACK_400M].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_800M:
          return {
            value: PlayerColumn.TRACK_800M,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_800M],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_800M]
                ? secondsToMinutesSecondsMs(trackEvent[PlayerColumn.TRACK_800M].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_400R:
          return {
            value: PlayerColumn.TRACK_400R,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_400R],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_400R]
                ? Number(trackEvent[PlayerColumn.TRACK_400R].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_800R:
          return {
            value: PlayerColumn.TRACK_800R,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_800R],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_800R]
                ? Number(trackEvent[PlayerColumn.TRACK_800R].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_1600R:
          return {
            value: PlayerColumn.TRACK_1600R,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_1600R],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_1600R]
                ? Number(trackEvent[PlayerColumn.TRACK_1600R].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_3200R:
          return {
            value: PlayerColumn.TRACK_3200R,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_3200R],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_3200R]
                ? Number(trackEvent[PlayerColumn.TRACK_3200R].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_55HH:
          return {
            value: PlayerColumn.TRACK_55HH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_55HH],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_55HH]
                ? Number(trackEvent[PlayerColumn.TRACK_55HH].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_60HH:
          return {
            value: PlayerColumn.TRACK_60HH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_60HH],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_60HH]
                ? Number(trackEvent[PlayerColumn.TRACK_60HH].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_110HH:
          return {
            value: PlayerColumn.TRACK_110HH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_110HH],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_110HH]
                ? Number(trackEvent[PlayerColumn.TRACK_110HH].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_300IH:
          return {
            value: PlayerColumn.TRACK_300IH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_300IH],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_300IH]
                ? Number(trackEvent[PlayerColumn.TRACK_300IH].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_400H:
          return {
            value: PlayerColumn.TRACK_400H,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_400H],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_400H]
                ? Number(trackEvent[PlayerColumn.TRACK_400H].performance).toFixed(2)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_SHOTPUT:
          return {
            value: PlayerColumn.TRACK_SHOTPUT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_SHOTPUT],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_SHOTPUT]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_SHOTPUT].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_DISCUS:
          return {
            value: PlayerColumn.TRACK_DISCUS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_DISCUS],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_DISCUS]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_DISCUS].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_JAVELIN:
          return {
            value: PlayerColumn.TRACK_JAVELIN,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_JAVELIN],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_JAVELIN]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_JAVELIN].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_HIGH_JUMP:
          return {
            value: PlayerColumn.TRACK_HIGH_JUMP,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_HIGH_JUMP],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_HIGH_JUMP]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_HIGH_JUMP].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_LONG_JUMP:
          return {
            value: PlayerColumn.TRACK_LONG_JUMP,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_LONG_JUMP],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_LONG_JUMP]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_LONG_JUMP].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_TRIPLE_JUMP:
          return {
            value: PlayerColumn.TRACK_TRIPLE_JUMP,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_TRIPLE_JUMP],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_TRIPLE_JUMP]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_TRIPLE_JUMP].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.TRACK_POLE_VAULT:
          return {
            value: PlayerColumn.TRACK_POLE_VAULT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.TRACK_POLE_VAULT],
            sortable: true,
            renderContent: (player:Player) => {
              const trackEvent = player.bestTrackEvent;
              return trackEvent && trackEvent[PlayerColumn.TRACK_POLE_VAULT]
                ? inchesToFeetAndInches(trackEvent[PlayerColumn.TRACK_POLE_VAULT].performance)
                : <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_ARM:
          return {
            value: PlayerColumn.COMBINE_ARM,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_ARM],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_ARM, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_HAND:
          return {
            value: PlayerColumn.COMBINE_HAND,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_HAND],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_HAND, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_MPH:
          return {
            value: PlayerColumn.COMBINE_MPH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_MPH],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_MPH, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_10_SPLIT:
          return {
            value: PlayerColumn.COMBINE_10_SPLIT,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_10_SPLIT],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_10_SPLIT, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_3_CONE:
          return {
            value: PlayerColumn.COMBINE_3_CONE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_3_CONE],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_3_CONE, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_40M_DASH:
          return {
            value: PlayerColumn.COMBINE_40M_DASH,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_40M_DASH],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_40M_DASH, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_BROAD_JUMP:
          return {
            value: PlayerColumn.COMBINE_BROAD_JUMP,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_BROAD_JUMP],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_BROAD_JUMP, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_VERTICAL_JUMP:
          return {
            value: PlayerColumn.COMBINE_VERTICAL_JUMP,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_VERTICAL_JUMP],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_VERTICAL_JUMP, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_WINGSPAN:
          return {
            value: PlayerColumn.COMBINE_WINGSPAN,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_WINGSPAN],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_WINGSPAN, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_SHUTTLE:
          return {
            value: PlayerColumn.COMBINE_SHUTTLE,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_SHUTTLE],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_SHUTTLE, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.COMBINE_POWER_TOSS:
          return {
            value: PlayerColumn.COMBINE_POWER_TOSS,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.COMBINE_POWER_TOSS],
            sortable: true,
            renderContent: (player:Player) => {
              return getBestCombineEventForColumn(PlayerColumn.COMBINE_POWER_TOSS, player.hsCombines) || <NotAvailable />;
            },
          } as ColumnConfiguration;
        case PlayerColumn.WATCH_LIST:
          return {
            value: PlayerColumn.WATCH_LIST,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.WATCH_LIST],
            sortable: true,
            renderContent: (player:Player) => {
              const watchListNames = (player.watchList || []).map((watchList) => watchList.name).join(', ');

              return (
                !!watchListNames.length ? (
                  <Tooltip title={watchListNames}>
                    <div className={clsx(classes.watchListNames, classes.truncated)}>{watchListNames}</div>
                  </Tooltip>
                )
                : <NotAvailable />
              );
            },
          } as ColumnConfiguration;
        case PlayerColumn.WATCH_LIST_YEAR:
          return {
            value: PlayerColumn.WATCH_LIST_YEAR,
            title: PLAYER_COLUMN_TITLE[PlayerColumn.WATCH_LIST_YEAR],
            sortable: true,
            renderContent: (player:Player) => {
              const watchListYears = (player.watchList || []).map((watchList) => watchList.year).join(', ');
              return (
                !!watchListYears.length ? (
                  <div className={classes.watchListYears}>{watchListYears}</div>
                )
                : <NotAvailable />
              );
            },
          };
        default:
          return null;
    }
    })
    .filter(Boolean) as ColumnConfiguration[];

  const stickyRefs:any[] = [
    createRef(), // for the checkbox column
    ...tableColumns.map((column:ColumnConfiguration) => column.sticky ? createRef() : undefined),
  ];

  function renderTable ({ sticky = false }:{ sticky:boolean }) {
    return (
      <>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableHeadCell
              key='check-box-cell'
              className={classes.checkBoxCell}
              ref={sticky ? undefined : stickyRefs[0]}
            >
              <Checkbox
                checked={items.length === selectedItems.length}
                onChange={() => items.length === selectedItems.length
                  ? setSelectedItems([])
                  : setSelectedItems([...items])
                }
              />
            </TableHeadCell>

            {tableColumns.map((tableColumn:ColumnConfiguration, index:number) => (
              <TableHeadCell
                key={tableColumn.value}
                ref={sticky ? undefined : stickyRefs[index + 1]}
                name={tableColumn.sortable ? tableColumn.value : undefined}
                sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                order={tableColumn.sortable ? order : undefined}
                onSort={tableColumn.sortable
                  ? () => onSort(tableColumn.value)
                  : undefined
                }
              >
                {tableColumn.title}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!items.length && (
            <TableRow
              key='empty'
              className={classes.tableRow}
            >
              <TableCell
                className={clsx(classes.tableCell, classes.emptyCell)}
                colSpan={tableColumns.length + 1}
              >
                <div className={classes.empty}>
                  No players
                </div>
              </TableCell>
            </TableRow>
          )}

          {items.map(renderPlayerRow)}
        </TableBody>
      </>
    );
  }

  const isDesktopScreenSize = isDesktop(screenSize);

  return (
    <>
      <div className={classes.searchPlayerTable}>
        <TableWithDoubleScrollBars
          className={classes.fixedTableWrapper}
          tableClassName={clsx(classes.playersTable, className)}
        >
          {renderTable({ sticky: false })}
        </TableWithDoubleScrollBars>

        {isDesktopScreenSize && (
          <TableWithDoubleScrollBars
            className={classes.stickyTableWrapper}
            tableClassName={clsx(classes.playersTable, className)}
            style={{ width: `${stickyColumnsTotalWidth}px` }}
            disableBottomScrollbar
          >
            {renderTable({ sticky: true })}
          </TableWithDoubleScrollBars>
        )}
      </div>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {totalPlayersCount}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    screenSize: state.ui.screenSize,
  };
};

export default connect(mapStateToProps)(SearchPlayerTable);
