import React from 'react';
import Icon from '../atoms/Icon';

function LogoutIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='currentColor'
        strokeWidth='2'
      >
        <path d='M20 16H6.375m3-4l-4 4 4 4'/>
        <path d='M7 9.674A10.988 10.988 0 0116 5c6.075 0 11 4.925 11 11s-4.925 11-11 11c-3.72 0-7.008-1.846-8.999-4.673'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(LogoutIcon);
