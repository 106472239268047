import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TeamLogo from '../atoms/TeamLogo';
import Tooltip from '../atoms/Tooltip';
import { COLOR_GREEN } from '../styles/colors';
import Team from '../types/Team';

interface OfferStatusProps {
  className?: string;
  committedTo?: Team;
}

const useStyles = makeStyles((theme: Theme) => ({
  offerStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  offeredIconContainer: {
    padding: theme.spacing(1),
  },
  offeredIcon: {
    width: '10px',
    height: '10px',
    background: COLOR_GREEN,
    borderRadius: '50%',
  },
  teamLogo: {
    width: '24px',
    height: '24px',
  },
}), { name: OfferStatus.name });

export default function OfferStatus (props: OfferStatusProps) {
  const {
    className,
    committedTo,
  } = props;
  const classes = useStyles();
  const uncommitted = !committedTo?.name || committedTo.name.toLowerCase() === 'none';

  let content = null;
  if (uncommitted) {
    content = (
      <div className={classes.offeredIconContainer}>
        <div className={classes.offeredIcon} />
      </div>
    );
  } else if (committedTo) {
    content = (
      <div>
        <TeamLogo
          className={classes.teamLogo}
          team={committedTo}
        />
      </div>
    );
  }

  if (!content) return null;

  return (
    <div className={clsx(classes.offerStatus, className)}>
      <Tooltip
        title={uncommitted
          ? 'Uncommitted'
          : `Committed to ${committedTo?.name}`
        }
      >
        {content}
      </Tooltip>
    </div>
  );
}
