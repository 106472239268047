import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import StarIcon from '../icons/StarFilledIcon';
import { COLOR_MIDDLE_GRAY, COLOR_LIGHT_ORANGE } from '../styles/colors';

interface StarsProps {
  className?: string;
  starClassName?: string;
  value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  stars: {
    display: 'flex',
    justifyContent: 'center',
  },
  star: {
    width: '14px',
    height: '14px',
    marginLeft: '1px',
    color: COLOR_MIDDLE_GRAY,

    '&:first-of-type': {
      marginLeft: 0,
    },

    '& svg': {
      display: 'block',
    },
  },
  highlightedStar: {
    color: COLOR_LIGHT_ORANGE,
  },
}), { name: Stars.name });

export default function Stars (props:StarsProps) {
  const {
    className,
    starClassName,
    value,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.stars, className)}>
      {Array.from(new Array(5)).map((_, index) => (
        <StarIcon
          key={index}
          className={clsx(
            classes.star,
            value >= (index + 1) && classes.highlightedStar,
            starClassName,
          )}
        />
      ))}
    </div>
  );
}
