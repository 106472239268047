import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_BLUE
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface SeeMoreButtonProps {
  href?: string;
  onClick?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  seeMoreButton: {
    appearance: 'none',
    padding: '4px 12px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    border: `2px solid ${COLOR_DARK_GRAY}`,
    borderRadius: '5px',
    background: COLOR_WHITE,
    color: COLOR_TEXT,
    cursor: 'pointer',
    outlineColor: COLOR_TEXT,
    textDecoration: 'none',

    '&:hover' : {
      borderColor: COLOR_BLUE,
      backgroundColor: COLOR_BLUE,
      color: COLOR_WHITE,
    },
  },
}), { name: SeeMoreButton.name });

export default function SeeMoreButton (props: SeeMoreButtonProps) {
  const { href, onClick } = props;
  const classes = useStyles();

  const Wrapper = href ? 'a' : 'button';

  return (
    <Wrapper
      className={classes.seeMoreButton}
      href={href}
      onClick={onClick}
    >
      See More
    </Wrapper>
  );
}
