import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import SportIcon from '../atoms/SportIcon';
import { COLOR_DARK_GRAY } from '../styles/colors';
import { Sport } from '../types/Sport';
import MEDIA from '../styles/media';

interface MultiSportListProps {
  className?: string;
  iconClassName?: string;
  list: Array<Sport>;
  limit?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  multiSportList: {
    display: 'flex',
    height: '20px',
  },
  icon: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(2),
    color: COLOR_DARK_GRAY,

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    icon: {
      width: '24px',
      height: '24px',
    },
  },
}), { name: MultiSportList.name });

export default function MultiSportList (props: MultiSportListProps) {
  const classes = useStyles();
  const {
    className,
    iconClassName,
    list,
    limit,
  } = props;

  const maxNumberOfIcons = limit && limit > 0
    ? limit
    : (list || []).length;

  return (
    <div className={clsx(classes.multiSportList, className)}>
      {list
        .slice(0, maxNumberOfIcons)
        .map((sport:Sport, index) => (
          <Tooltip key={index} title={sport}>
            <SportIcon
              className={clsx(classes.icon, iconClassName)}
              sport={sport}
            />
          </Tooltip>
        ))
      }
    </div>
  );
}
