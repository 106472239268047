import Position, { DefensivePosition, OffensivePosition, SpecialPosition } from '../types/Position';
import ValidationResult from '../types/ValidationResult';

const OFFENSIVE_POSITIONS = [
  { content: 'C / Center', value: OffensivePosition.C },
  { content: 'OG / Offensive Guard', value: OffensivePosition.OG },
  { content: 'OT / Offensive Tackle', value: OffensivePosition.OT },
  { content: 'TE / Tight End', value: OffensivePosition.TE },
  { content: 'FB / Fullback', value: OffensivePosition.FB },
  { content: 'RB / Running Back', value: OffensivePosition.RB },
  { content: 'WR / Wide Receiver', value: OffensivePosition.WR },
  { content: 'QB / Quarterback', value: OffensivePosition.QB },
];

const DEFENSIVE_POSITIONS = [
  { content: 'DT / Defensive Tackle', value: DefensivePosition.DT },
  { content: 'DE / Defensive End', value: DefensivePosition.DE},
  { content: 'LB / Linebacker', value: DefensivePosition.LB },
  { content: 'DB / Safety', value: DefensivePosition.DB},
  { content: 'CB / Cornerback', value: DefensivePosition.CB },
];

const SPECIAL_POSITIONS = [
  { content: 'K / Kicker', value: SpecialPosition.K },
  { content: 'P / Punter', value: SpecialPosition.P },
  { content: 'LS / Long Snapper', value: SpecialPosition.LS },
];

const ACCEPTED_POSITIONS:Position[] = [...OFFENSIVE_POSITIONS, ...DEFENSIVE_POSITIONS, ...SPECIAL_POSITIONS]
  .map(item => item.value)
  .sort();

export function validatePrimaryPosition (
  primary:string|undefined|null,
  allPositions:string|undefined|null,
):ValidationResult {
  const primaryPosition = (primary || '')?.toString()?.trim();
  const positions = (allPositions || '')
    .split('/')
    .map((position:string) => position?.toString()?.trim())
    .filter(Boolean);

  const allPositionsDefined = positions?.length > 0;
  const acceptedPrimaryPosition = ACCEPTED_POSITIONS.includes(primaryPosition as Position);
  const includedInAllPositions = acceptedPrimaryPosition && allPositionsDefined && positions.includes(primaryPosition);

  const valid = (typeof primary !== 'string' && !allPositionsDefined)
    || (typeof primary === 'string' && primary.length === 0 && !allPositionsDefined)
    || (acceptedPrimaryPosition && includedInAllPositions)
    || (acceptedPrimaryPosition && !allPositionsDefined);

  let errorMessage = '';
  if (acceptedPrimaryPosition && (!allPositionsDefined || !includedInAllPositions)) {
    errorMessage = 'Add this position to the list of all positions';
  } else if (primaryPosition.length === 0 && allPositionsDefined) {
    errorMessage = 'Specify the primary position';
  } else if (!acceptedPrimaryPosition) {
    errorMessage = 'Invalid position';
  }

  return {
    valid,
    error: valid ? '' : errorMessage,
  };
}

export function validatePositions (
  primary:string|undefined|null,
  allPositions:string|undefined|null,
):ValidationResult {
  const primaryPosition = (primary || '')?.toString()?.trim();
  const positions = (allPositions || '')
    .split('/')
    .map((position:string) => position?.toString()?.trim())
    .filter(Boolean);

  const hasDuplicates = positions.length > Array.from(new Set(positions)).length;
  const needPrimaryPosition = primaryPosition?.length > 0 && !allPositions;
  const invalidPositions = positions.filter((position:string) => !ACCEPTED_POSITIONS.includes(position as Position));
  const commaSeparatedInvalidPositions = invalidPositions.join(', ');
  const valid = !needPrimaryPosition
    && !hasDuplicates
    && (typeof allPositions !== 'string'
      || allPositions?.length === 0
      || invalidPositions.length === 0
    );

  let errorMessage = '';
  if (needPrimaryPosition) {
    errorMessage = 'Add the primary position to this list';
  } else if (invalidPositions?.length) {
    errorMessage = `Some positions are invalid ${commaSeparatedInvalidPositions ? `: ${commaSeparatedInvalidPositions}. Use "/" to separate values.` : ''}`;
  } else if (hasDuplicates) {
    errorMessage = 'Some positions are repeated';
  }

  return {
    valid,
    error: valid ? '' : errorMessage,
  };
}

export function allPositionsValid (positions:string[]) {
  if (!positions.length) return true;

  return positions.every((position:string) => ACCEPTED_POSITIONS.includes(position as Position));
}
