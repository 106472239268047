import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import Action from '../atoms/Action';
import FilePicker from '../atoms/FilePicker';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import AddUserIcon from '../icons/AddUserIcon';
import FilterIcon from '../icons/FilterIcon';
import ImportPageTable from './ImportPageTable';
import { uploadAndPostXlsFile } from '../services/export-xls-report';
import { State } from '../redux/reducers';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import User from '../types/User';

interface ImportPageProps {
  user?: User;
}

const useStyles = makeStyles(theme => ({
  importPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  import: {
    padding: theme.spacing(4),
  },
  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1.5),
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    fontSize: theme.typography.pxToRem(14),

    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
      color: COLOR_WHITE,
      textDecoration: 'none',
    }
  },
  filePicker: {
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    cursor: 'pointer',
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),

    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
    }
  },
  fileInputLabelClass: {
    height: '100%',
    width: '100%',
    lineHeight: '50px',
    cursor: 'pointer',
    textAlign: 'center',
  }
}), { name: ImportPage.name });

function ImportPage (props:ImportPageProps) {
  const classes = useStyles();
  const { user } = props;

  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [refreshStagedPlayers, setRefreshStagedPlayers] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canImport) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onFileSelect(file:any) {
    if (file) {
      setLoading(true);

      uploadAndPostXlsFile(file)
        .then((success:boolean) => {
          if (success) {
            showToast('Uploaded', ToastType.SUCCESS);
          } else {
            showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <AddUserIcon className={classes.icon} />
            Import players
          </h1>

          <div className={classes.actions}>
            <FilePicker
              className={clsx(classes.action, classes.filePicker)}
              fileInputLabelClass={classes.fileInputLabelClass}
              name='xls-import'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={(file:any) => onFileSelect(file)}
            >
              Import
            </FilePicker>

            <Action
              className={classes.action}
              onClick={() => {
                setRefreshStagedPlayers(true);
              }}
              icon={RefreshIcon}
              iconClassName={classes.icon}
            >
              Refresh
            </Action>

            <Action
              className={classes.action}
              onClick={() => setIsDrawerOpen(true)}
              icon={FilterIcon}
              iconClassName={classes.icon}
            >
              Filter
            </Action>
          </div>
        </div>

        <ImportPageTable
          className={classes.import}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          showToast={showToast}
          refreshStagedPlayers={refreshStagedPlayers}
          setRefreshStagedPlayers={setRefreshStagedPlayers}
        />
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(ImportPage);
