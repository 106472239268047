import React from 'react';
import Icon from '../atoms/Icon';

function HelpIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        stroke='none"'
        strokeWidth='1"'
        fill='none"'
        fillRule='evenodd'
      >
        <path
          d='M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C32,7.163444 24.836556,0 16,0 Z M16,2.66666667 C23.3637967,2.66666667 29.3333333,8.63620334 29.3333333,16 C29.3333333,23.3637967 23.3637967,29.3333333 16,29.3333333 C8.63620334,29.3333333 2.66666667,23.3637967 2.66666667,16 C2.66666667,8.63620334 8.63620334,2.66666667 16,2.66666667 Z'
          fill='currentColor'
          fillRule='nonzero'
          />
        <polygon
          fill='currentColor'
          fillRule='nonzero'
          points='15 21 18 21 18 24 15 24'
        />
        <path
          d='M17.875,20 L17.875,18.2946667 L18.4523718,18.1072756 C20.5701993,17.4239348 22,15.4997535 22,13.3333333 C22,10.3876203 19.5377665,8 16.5,8 C13.4622335,8 11,10.3876203 11,13.3333333 L13.75,13.3333333 C13.75,11.8603797 14.9810165,10.6666667 16.5,10.6666667 C18.0189835,10.6666667 19.25,11.8603797 19.25,13.3333333 C19.25,14.3518497 18.5778854,15.2563606 17.5825028,15.5775325 L17.0047532,15.7647244 C15.882519,16.1265784 15.125,17.1468485 15.125,18.2946667 L15.125,20 L17.875,20 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(HelpIcon);
