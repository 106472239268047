import React, { useState } from 'react';
import Dialog from './Dialog';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import HelpPopupFooter from '../atoms/HelpPopupFooter';
import Loader from '../atoms/Loader';
import gql from '../services/gql';

interface AddRemoveAccountModalProps {
  className?: string;
  headingClassName?: string;
  inputClassName?: string;
  inputHalfClassName?: string;
  dropDownClassName?: string;
  dropDownSelectClassName?: string;
  fullNameClassName?: string;
  multiSelectClassName?: string;
  contentClassName?:string;
  isPopupVisible: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

const HELP_OPTIONS = {
  ADD_ACCOUNT: 'add',
  REMOVE_ACCOUNT: 'remove',
};

const HELP_QUES = {
  [HELP_OPTIONS.ADD_ACCOUNT] : {
    question: 'Need to add an account?',
    helpText: 'Please provide the following:',
    submitBtnText: 'Request Addition',
  },
  [HELP_OPTIONS.REMOVE_ACCOUNT] : {
    question: 'Need to remove an account?',
    helpText: 'Please provide the following:',
    submitBtnText: 'Request Removal',
  }
};

export default function AddRemoveAccountModal (props:AddRemoveAccountModalProps) {
  const {
    className,
    headingClassName,
    inputClassName,
    inputHalfClassName,
    dropDownClassName,
    dropDownSelectClassName,
    fullNameClassName,
    contentClassName,
    isPopupVisible = false,
    onClose,
    onError,
    onSuccess,
  } = props;

  const [helpType, setHelpType] = useState<string>(HELP_OPTIONS.ADD_ACCOUNT);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleSubmit() {
    submitHelpText()
      .then((success:boolean | void) => {
        if (success) {
          onClose();
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(onError);
  }

  function submitHelpText () {
    setIsLoading(true);

    return gql(`
      mutation {
        submitAddRemoveAccountHelp (addRemoveAccountHelp: {
          requestType: "${helpType}",
          firstName: "${firstName}",
          lastName: "${lastName}",
          email: "${email}"
        })
      }`
    )
      .then((data: any) => data && data.submitAddRemoveAccountHelp as boolean)
      .finally(() => setIsLoading(false));
  }

  return(
    <Dialog
      className={className}
      contentClassName={contentClassName}
      open={isPopupVisible}
      title='Add / Remove Account'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose
        },
        {
          name: HELP_QUES[helpType].submitBtnText,
          primary: true,
          disabled: isLoading,
          onClick: handleSubmit
        }
      ]}
      onClose={onClose}
    >
      <Loader inProgress={isLoading} />
      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        items={[
          { content: 'Add Account', value: HELP_OPTIONS.ADD_ACCOUNT },
          { content: 'Remove Account', value: HELP_OPTIONS.REMOVE_ACCOUNT },
        ]}
        value={helpType}
        onChange={(value: string) => setHelpType(value)}
      />
      <div className={headingClassName}>
        <strong>{HELP_QUES[helpType].question}</strong>
        {HELP_QUES[helpType].helpText}
      </div>
      <div className={fullNameClassName}>
        <Input
          className={inputHalfClassName}
          label='First Name'
          value={firstName}
          onChange={(value: string) => setFirstName(value)}
        />
        <Input
          className={inputHalfClassName}
          label='Last Name'
          value={lastName}
          onChange={(value: string) => setLastName(value)}
        />
      </div>
      <Input
        className={inputClassName}
        label='Email'
        value={email}
        onChange={(value: string) => setEmail(value)}
      />
      <HelpPopupFooter />
    </Dialog>
  );
}
