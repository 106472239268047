import React from 'react';
import Page from './Page';
import ManagePageTemplate from '../templates/ManagePageTemplate';

export default function ManagePage () {
  return (
    <Page title='Manage'>
      <ManagePageTemplate />
    </Page>
  );
}
