import {
  SET_ACHIEVEMENTS,
  SET_ACHIEVEMENTS_VISIBILITY,
} from '../actions/achievements';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import { Achievement } from '../../types/Achievement';

export enum AchievementsVisibility {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  MINIMIZED = 'MINIMIZED',
}

export const COLLEGE_ACHIEVEMENTS = [
  Achievement.LOGIN,
  Achievement.VIEW_PLAYER,
  Achievement.SAVE_PREFERENCES,
  Achievement.SAVE_PLAYER,
  Achievement.SAVE_SEARCH,
  Achievement.VIEW_ACCOUNT_PAGE,
  Achievement.CHECK_MESSAGES,
  Achievement.VIEW_SIDE_BY_SIDE_PAGE,
];

export const NFL_ACHIEVEMENTS = [
  Achievement.LOGIN,
  Achievement.VIEW_PLAYER,
  Achievement.VIEW_COMPARE_PAGE,
  Achievement.SAVE_PLAYER,
  Achievement.SAVE_SEARCH,
  Achievement.VIEW_ACCOUNT_PAGE,
  Achievement.CHECK_MESSAGES,
  Achievement.VIEW_SIDE_BY_SIDE_PAGE,
];

export interface AchievementsState {
  achievements: Achievement[];
  achievementsVisibility: AchievementsVisibility;
}

const INITIAL_STATE:AchievementsState = {
  achievements: clientStorage.get(StorageKey.ACHIEVEMENTS) || [],
  achievementsVisibility: AchievementsVisibility.CLOSED,
};

export function getAchievementsInitialState () {
  return INITIAL_STATE;
}

export default function achievementsReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_ACHIEVEMENTS:
      return {
        ...state,
        achievements: action.achievements,
      };
    case SET_ACHIEVEMENTS_VISIBILITY:
      return {
        ...state,
        achievementsVisibility: action.achievementsVisibility,
      };
    default:
      return state;
  }
};
