import React from 'react';
import Icon from '../atoms/Icon';

function ExportsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M11.5,15 L20.5,15 C21.3284271,15 22,14.3284271 22,13.5 C22,12.6715729 21.3284271,12 20.5,12 L11.5,12 C10.6715729,12 10,12.6715729 10,13.5 C10,14.3284271 10.6715729,15 11.5,15 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          d='M26.9090909,0 L32,6.78787879 L32,32 L0,32 L0,6.78787879 L5.09090909,0 L26.9090909,0 Z M29.0909091,8.72727273 L2.90763636,8.72727273 L2.90909091,29.0909091 L29.0909091,29.0909091 L29.0909091,8.72727273 Z M25.4545455,2.90909091 L6.54545455,2.90909091 L4.36218182,5.81818182 L27.6363636,5.81818182 L25.4545455,2.90909091 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(ExportsIcon);
