import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { usePagination } from '@material-ui/lab/Pagination';
import SelectPage from '../organisms/SelectPage';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface PaginationProps {
  className?: string;
  currentPage: number;
  totalPages: number;
  onChange: (page:number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  paginationItem: {
    display: 'flex',
    margin: theme.spacing(2, 1, 0, 1),
    alignItems: 'center',
  },

  button: {
    boxSizing: 'border-box',
    appearance: 'none',
    margin: 0,
    padding: theme.spacing(1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: '4px',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'color 0.3s, border-color 0.3s, background 0.3s',
    userSelect: 'none',
  },
  page: {
    minWidth: '50px',
    minHeight: '50px',
    color: COLOR_DARK_GRAY,
    background: COLOR_WHITE,
    outlineColor: COLOR_DARK_GRAY,

    '&:hover': {
      color: COLOR_TEXT,
      borderColor: COLOR_DARK_GRAY,
    },
    '&:focus': {
      color: COLOR_TEXT,
      fontWeight: 'bold',
    },

    '&$active': {
      color: COLOR_WHITE,
      background: COLOR_DARK_GRAY,
      borderColor: COLOR_DARK_GRAY,
      outline: 'none',
      cursor: 'default',
    }
  },
  nextOrPrevious: {
    minWidth: '100px',
    minHeight: '50px',
    color: COLOR_WHITE,
    background: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    outlineColor: '#87c4f3',

    '&:hover': {
      borderColor: '#87c4f3',
    },
    '&:focus': {
      fontWeight: 'bold',
      outlineColor: '#87c4f3',
    },

    '&$disabled': {
      background: '#87c4f3',
      borderColor: '#87c4f3',
      outline: 'none',
      cursor: 'default',
    }
  },
  active: {},
  disabled: {},
}), { name: Pagination.name });

export default function Pagination (props: PaginationProps) {
  const {
    className,
    currentPage,
    totalPages,
    onChange,
  } = props;
  const classes = useStyles();
  const { items } = usePagination({
    page: currentPage,
    count: totalPages,
    onChange: (event, page) => onChange(page),
  });

  return (
    <ul className={clsx(classes.pagination, className)}>
      {items.map(({ page, type, selected, ...item }, index) => {
        return (
          <li key={index} className={classes.paginationItem}>
            {type === 'previous' && (
              <button
                className={clsx(
                  classes.button,
                  classes.nextOrPrevious,
                  currentPage === 1 && classes.disabled,
                )}
                {...item}
              >
                Previous
              </button>
            )}

            {type === 'page' && (
              <button
                className={clsx(
                  classes.button,
                  classes.page,
                  currentPage === page && classes.active,
                )}
                style={{ fontWeight: selected ? 'bold' : undefined }}
                {...item}
              >
                {page}
              </button>
            )}

            {(type === 'start-ellipsis' || type === 'end-ellipsis') && (
              <SelectPage
                pages={Array.from(new Array(totalPages)).map((_, index:number) => index + 1)}
                currentPage={currentPage}
                onSelect={(page:string|number) => onChange(Number(page))}
              />
            )}

            {type === 'next' && (
              <button
                className={clsx(
                  classes.button,
                  classes.nextOrPrevious,
                  currentPage === totalPages && classes.disabled,
                )}
                {...item}
              >
                Next
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
}
