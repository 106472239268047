import React from 'react';
import Icon from '../atoms/Icon';

function InfoIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(4, 4)'>
          <path
            d='M11,10 L13,10 L13,18 L11,18 L11,10 Z M11,6 L13,6 L13,8 L11,8 L11,6 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M11.9999998,0 C7.71281288,0 3.75128858,2.287187 1.60769501,6 C-0.535898574,9.7128129 -0.535898574,14.2871871 1.60769501,18 C3.75128858,21.712813 7.71281288,24 11.9999998,24 C16.2871871,24 20.2487114,21.712813 22.392305,18 C24.5358986,14.2871871 24.5358986,9.7128129 22.392305,6 C20.2487114,2.287187 16.2871871,0 11.9999998,0 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(InfoIcon);
