const EVENT_NAME = {
  event_55m: '55 Meters',
  event_60m: '60 Meters',
  event_100m: '100 Meters',
  event_200m: '200 Meters',
  event_300m: '300 Meters',
  event_400m: '400 Meters',
  event_800m: '800 Meter',
  // event_60hh: '60 Meter High Hurdles',
  // event_55hh: '55 Meter High Hurdles',
  // event_110hh: '110 Meter High Hurdles',
  // event_300ih: '300 Int. Hurdles',
  // event_400h: '400 Meter Hurdles',
  // event_400r: '4x100 Relay',
  // event_800r: '4x200 Relay',
  // event_1600r: '4x400 Relay',
  // event_3200r: '4x800 Relay',
  event_60hh: '60HH',
  event_55hh: '55HH',
  event_110hh: '110HH',
  event_300ih: '300IH',
  event_400h: '400H',
  event_400r: '4x100R',
  event_800r: '4x200R',
  event_1600r: '4x400R',
  event_3200r: '4x800R',
  event_discus: 'Discus',
  event_javelin: 'Javelin',
  event_shotput: 'Shot Put',
  event_high_jump: 'High Jump',
  event_long_jump: 'Long Jump',
  event_pole_vault: 'Pole Vault',
  event_triple_jump: 'Triple Jump',
  '40mDash': '40 Yard Dash',
  '10Split': '10 Split',
  '20Split': '20 Split',
  '3Cone': '3-Cone',
  shuttle: 'Shuttle',
  powerToss: 'Power Toss',
  verticalJump: 'Vertical Jump',
  broadJump: 'Broad Jump',
}

const RELAY_EVENT_DESCRIPTION = {
  event_400r: '(4x100)',
  event_800r: '(4x200)',
  event_1600r: '(4x400)',
  event_3200r: '(4x800)',
}

export enum FormatType {
  SECONDS = 'SECONDS', // s
  MINUTES_SECONDS_MS = 'MINUTES_SECONDS_MS', // m.ss:ms (3.21:13)
  INCHES = 'INCHES', // 99"
  FEET_AND_INCHES = 'FEET_AND_INCHES', // 0'00"
  WEIGHT = 'WEIGHT' // {n} lbs,
}

const EVENT_FORMAT = {
  event_55m: FormatType.SECONDS,
  event_60m: FormatType.SECONDS,
  event_60hh: FormatType.SECONDS,
  event_100m: FormatType.SECONDS,
  event_55hh: FormatType.SECONDS,
  event_110hh: FormatType.SECONDS,
  event_200m: FormatType.MINUTES_SECONDS_MS,
  event_300m: FormatType.MINUTES_SECONDS_MS,
  event_300ih: FormatType.MINUTES_SECONDS_MS,
  event_400m: FormatType.MINUTES_SECONDS_MS,
  event_400h: FormatType.MINUTES_SECONDS_MS,
  event_400r: FormatType.MINUTES_SECONDS_MS,
  event_800m: FormatType.MINUTES_SECONDS_MS,
  event_800r: FormatType.MINUTES_SECONDS_MS,
  event_1600r: FormatType.MINUTES_SECONDS_MS,
  event_3200r: FormatType.MINUTES_SECONDS_MS,
  event_discus: FormatType.FEET_AND_INCHES,
  event_javelin: FormatType.FEET_AND_INCHES,
  event_shotput: FormatType.FEET_AND_INCHES,
  event_high_jump: FormatType.FEET_AND_INCHES,
  event_long_jump: FormatType.FEET_AND_INCHES,
  event_pole_vault: FormatType.FEET_AND_INCHES,
  event_triple_jump: FormatType.FEET_AND_INCHES,
  '40mDash': FormatType.SECONDS,
  '10Split': FormatType.SECONDS,
  '20Split': FormatType.SECONDS,
  '3Cone': FormatType.SECONDS,
  shuttle: FormatType.SECONDS,
  powerToss: FormatType.SECONDS,
  verticalJump: FormatType.INCHES,
  broadJump: FormatType.INCHES,
};

export enum EventComparison {
  BIGGER_BETTER = 'BIGGER_BETTER',
  SMALLER_BETTER = 'SMALLER_BETTER',
}

const EVENT_COMPARISON = {
  event_55m: EventComparison.SMALLER_BETTER,
  event_60m: EventComparison.SMALLER_BETTER,
  event_60hh: EventComparison.SMALLER_BETTER,
  event_100m: EventComparison.SMALLER_BETTER,
  event_55hh: EventComparison.SMALLER_BETTER,
  event_110hh: EventComparison.SMALLER_BETTER,
  event_200m: EventComparison.SMALLER_BETTER,
  event_300m: EventComparison.SMALLER_BETTER,
  event_300ih: EventComparison.SMALLER_BETTER,
  event_400m: EventComparison.SMALLER_BETTER,
  event_400h: EventComparison.SMALLER_BETTER,
  event_400r: EventComparison.SMALLER_BETTER,
  event_800m: EventComparison.SMALLER_BETTER,
  event_800r: EventComparison.SMALLER_BETTER,
  event_1600r: EventComparison.SMALLER_BETTER,
  event_3200r: EventComparison.SMALLER_BETTER,
  event_discus: EventComparison.BIGGER_BETTER,
  event_javelin: EventComparison.BIGGER_BETTER,
  event_shotput: EventComparison.BIGGER_BETTER,
  event_high_jump: EventComparison.BIGGER_BETTER,
  event_long_jump: EventComparison.BIGGER_BETTER,
  event_pole_vault: EventComparison.BIGGER_BETTER,
  event_triple_jump: EventComparison.BIGGER_BETTER,
  '40mDash': EventComparison.SMALLER_BETTER,
  '10Split': EventComparison.SMALLER_BETTER,
  '20Split': EventComparison.SMALLER_BETTER,
  '3Cone': EventComparison.SMALLER_BETTER,
  shuttle: EventComparison.SMALLER_BETTER,
  powerToss: EventComparison.BIGGER_BETTER,
  verticalJump: EventComparison.BIGGER_BETTER,
  broadJump: EventComparison.BIGGER_BETTER,
  mph: EventComparison.BIGGER_BETTER,
};

export function getEventTypeDescription (key:any):string {
  // @ts-ignore  
  return RELAY_EVENT_DESCRIPTION[key] || key;
}

export function getEventName (key:any):string {
  // @ts-ignore
  return EVENT_NAME[key] || key;
}

export function getEventFormatType (key:any):FormatType {
  // @ts-ignore
  return EVENT_FORMAT[key];
}

export function getEventComparisonType (key:any):EventComparison {
  // @ts-ignore
  return EVENT_COMPARISON[key];
}
