import React from 'react';
import Icon from '../atoms/Icon';

function MessageIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(4,6)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <polygon points='24 16 10 16 5 20 5 16 0 16 0 0 24 0' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(MessageIcon);
