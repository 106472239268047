export enum Color {
  ORANGE = 'ORANGE',
  BLACK = 'BLACK',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  GREY = 'GREY',
  PURPLE = 'PURPLE',
  NONE = 'NONE',
}
