import React from 'react';
import Icon from '../atoms/Icon';

function SchoolIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M29.538 14.429V26.57H2.462V14.43h8.615V12H0v17h32V12H20.923v2.429z'/>
        <path d='M1 19h10v-2H1zM5 22v2h2v-2zM25 22v2h2v-2z'/>
        <path d='M22 30H10V7.193L16 1l6 6.193V30zm-2.4-2.477V8.219L16 4.503 12.4 8.22v19.304h7.2z'/>
        <path d='M21 19h10v-2H21zM15 24v5h2v-5zM17 21v-2h-2v2zM17 16v-2h-2v2zM17 11V9h-2v2z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SchoolIcon);
