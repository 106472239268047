import React from 'react';
import FormPageTemplate from '../templates/FormPageTemplate';
import LoginForm from '../molecules/LoginForm';

export default function LoginPage () {
  return (
    <FormPageTemplate>
      <LoginForm />
    </FormPageTemplate>
  );
}
