import React from 'react';
import Icon from '../atoms/Icon';

function EditUserIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(3, 3)'
          fill='currentColor'
          fillRule='nonzero'
        >
            <path d='M10,0 C6.144531,0 3,3.144531 3,7 C3,9.410156 4.230469,11.550781 6.09375,12.8125 C2.527344,14.34375 0,17.882813 0,22 L2,22 C2,17.570313 5.570313,14 10,14 C12.144531,14 14.0625,14.878906 15.5,16.25 L10.78125,20.96875 L10.71875,21.28125 L10.03125,24.8125 L9.71875,26.28125 L11.1875,25.96875 L14.71875,25.28125 L15.03125,25.21875 L25.125,15.125 C26.285156,13.964844 26.285156,12.035156 25.125,10.875 C24.542969,10.292969 23.769531,10 23,10 C22.246094,10 21.484375,10.285156 20.90625,10.84375 L16.9375,14.8125 C16.066406,13.976563 15.035156,13.292969 13.90625,12.8125 C15.769531,11.550781 17,9.410156 17,7 C17,3.144531 13.855469,0 10,0 Z M10,2 C12.773438,2 15,4.226563 15,7 C15,9.773438 12.773438,12 10,12 C7.226563,12 5,9.773438 5,7 C5,4.226563 7.226563,2 10,2 Z M23,12 C23.253906,12 23.519531,12.082031 23.71875,12.28125 C24.117188,12.679688 24.117188,13.289063 23.71875,13.6875 L14.03125,23.375 L12.25,23.75 L12.625,21.96875 L22.3125,12.28125 C22.511719,12.082031 22.746094,12 23,12 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(EditUserIcon);
