import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BaseballIcon from '../icons/BaseballIcon';
import BasketballIcon from '../icons/BasketballIcon';
import GolfIcon from '../icons/GolfIcon';
import HockeyIcon from '../icons/HockeyIcon';
import LacrosseIcon from '../icons/LacrosseIcon';
import PowerLiftingIcon from '../icons/PowerLiftingIcon';
import RugbyIcon from '../icons/RugbyIcon';
import SoccerIcon from '../icons/SoccerIcon';
import SwimmingIcon from '../icons/SwimmingIcon';
import TennisIcon from '../icons/TennisIcon';
import TrackIcon from '../icons/TrackIcon';
import VolleyballIcon from '../icons/VolleyballIcon';
import WrestlingIcon from '../icons/WrestlingIcon';
import { COLOR_DARK_GRAY } from '../styles/colors';
import { Sport } from '../types/Sport';

interface SportIconProps {
  className?: string;
  sport: Sport;
}

const useStyles = makeStyles(() => ({
  sportIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_DARK_GRAY,
  },
}), { name: SportIcon.name });

function getIconForSport (sport: Sport) {
  switch (sport) {
    case Sport.Baseball:
      return BaseballIcon;
    case Sport.Basketball:
      return BasketballIcon;
    case Sport.Golf:
      return GolfIcon;
    case Sport.Hockey:
      return HockeyIcon;
    case Sport.Lacrosse:
      return LacrosseIcon;
    case Sport.Powerlifting:
      return PowerLiftingIcon;
    case Sport.Rugby:
      return RugbyIcon;
    case Sport.Soccer:
      return SoccerIcon;
    case Sport.Swimming:
      return SwimmingIcon;
    case Sport.Tennis:
      return TennisIcon;
    case Sport.Track:
      return TrackIcon;
    case Sport.Volleyball:
      return VolleyballIcon;
    case Sport.Wrestling:
      return WrestlingIcon;
    default:
      return null;
  }
}

function SportIcon (props: SportIconProps, ref: React.Ref<any>) {
  const { className, sport } = props;
  const classes = useStyles();

  const Icon:any = getIconForSport(sport) || (() => null);

  return (
    <Icon
      className={clsx(classes.sportIcon, className)}
      ref={ref}
    />
  );
}

export default React.forwardRef(SportIcon);
