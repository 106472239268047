import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialAvatar from '@material-ui/core/Avatar';
import UserIcon from '../icons/UserIcon';
import { COLOR_DARK_GRAY, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';

interface AvatarProps {
  className?: string;
  src: string | null;
  alt?: string;
  initials?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: '32px',
    height: '32px',
    userSelect: 'none',
  },
  initials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(48),
    fontWeight: 300,
    lineHeight: 1,
    background: COLOR_TEXT,
    color: COLOR_WHITE,
    borderRadius: '50%',
  },
  icon: {
    padding: theme.spacing(.5,.5,0,.5),
    color: COLOR_WHITE,
    background: COLOR_DARK_GRAY,
    borderRadius: '50%',
  },

  [MEDIA.MOBILE]: {
    initials: {
      fontSize: theme.typography.pxToRem(38),
    },
  },
}), { name: Avatar.name });

export default function Avatar (props:AvatarProps) {
  const {
    className,
    src,
    alt = 'Profile picture',
    initials = '',
  } = props;
  const classes = useStyles();

  if (src) {
    return (
      <MaterialAvatar
        className={clsx(classes.avatar, className)}
        src={src}
        alt={alt}
      />
    );
  } else if (initials) {
    return (
      <div className={clsx(classes.avatar, classes.initials, className)}>
        {initials}
      </div>
    );
  } else {
    return <UserIcon className={clsx(classes.avatar, classes.icon, className)} />;
  }
}
