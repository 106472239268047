import React from 'react';
import Icon from '../atoms/Icon';

function TrendIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M23.733 17.103L32 10.49v21.441H0V16.628l7.844 3.138 7.959-5.306 7.93 2.643zm5.6 12.161V16.038l-5.066 4.054-8.07-2.69-8.041 5.36-5.49-2.195v8.697h26.667z'/>
        <path d='M2.667 12.224l5.613 1.123L16.3 8l8.058 1.342 4.975-3.88V15.93H32V0l-8.358 6.52-7.943-1.324-7.98 5.32L0 8.97v12.293h2.667z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TrendIcon);