import React from 'react';
import Icon from '../atoms/Icon';

function SearchIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        transform='translate(-9, -9)'
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M38.7243549,41 L41,38.7243549 L32.5217118,30.2460667 C34.387875,27.8563038 35.3209566,25.1577512 35.3209566,22.1504091 C35.3209566,20.3916509 34.9886721,18.7100902 34.3241032,17.1057269 C33.6595343,15.5013635 32.7029578,14.0816027 31.4543738,12.8464443 C30.2192154,11.6112859 28.7994546,10.6614223 27.1950913,9.99685337 C25.5907279,9.33228446 23.9091672,9 22.1504091,9 C20.4050766,9 18.7302287,9.33228446 17.1258653,9.99685337 C15.521502,10.6614223 14.0950283,11.6112859 12.8464443,12.8464443 C11.6112859,14.0816027 10.6614223,15.5013635 9.99685337,17.1057269 C9.33228446,18.7100902 9,20.3916509 9,22.1504091 C9,23.9091672 9.33228446,25.5907279 9.99685337,27.1950913 C10.6614223,28.7994546 11.6112859,30.2192154 12.8464443,31.4543738 C14.0950283,32.6895322 15.521502,33.6393958 17.1258653,34.3039648 C18.7302287,34.9685337 20.4050766,35.3008181 22.1504091,35.3008181 C25.1711768,35.3008181 27.8697294,34.3744493 30.2460667,32.5217118 L30.2460667,32.5217118 L38.7243549,41 Z M22.1504091,32.0988043 C19.398154,32.0988043 17.0486679,31.1254458 15.1019509,29.1787288 C13.1686595,27.2454374 12.2020138,24.9026641 12.2020138,22.1504091 C12.2020138,19.398154 13.1753724,17.0486679 15.1220894,15.1019509 C17.0553807,13.1686595 19.398154,12.2020138 22.1504091,12.2020138 C24.8892385,12.2020138 27.2387246,13.1686595 29.1988672,15.1019509 C31.1321586,17.0620936 32.0988043,19.4115796 32.0988043,22.1504091 C32.0988043,24.9026641 31.1321586,27.2454374 29.1988672,29.1787288 C27.2521502,31.1254458 24.9026641,32.0988043 22.1504091,32.0988043 Z' />
      </g>
    </Icon>
  );
}

export default React.forwardRef(SearchIcon);
