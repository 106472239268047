import React from 'react';
import Icon from '../atoms/Icon';

function LinkIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M10.667 21.333l10.666-10.666'
          stroke='currentColor'
          strokeWidth='2.667'
        />
        <path
          d='M15.904 20.81c.272 1.726-.237 3.55-1.567 4.878l-1.982 1.981a5.627 5.627 0 01-4.014 1.664 5.633 5.633 0 01-4.013-1.662 5.681 5.681 0 010-8.027l1.983-1.981A5.636 5.636 0 0110.325 16c.299 0 .591.033.878.084l2.153-2.153a8.173 8.173 0 00-3.03-.598 8.28 8.28 0 00-5.9 2.444L2.445 17.76A8.287 8.287 0 000 23.657c0 2.228.868 4.323 2.444 5.899A8.277 8.277 0 008.341 32a8.28 8.28 0 005.899-2.444l1.983-1.981a8.287 8.287 0 002.444-5.899 8.175 8.175 0 00-.598-3.032l-2.165 2.165zM29.556 2.443A8.277 8.277 0 0023.659 0a8.28 8.28 0 00-5.899 2.444l-1.983 1.981a8.287 8.287 0 00-2.444 5.899c0 1.059.218 2.077.598 3.032l2.165-2.165c-.272-1.727.237-3.551 1.567-4.879l1.982-1.981a5.627 5.627 0 014.014-1.664c1.516 0 2.941.589 4.013 1.662a5.681 5.681 0 010 8.027l-1.983 1.981A5.636 5.636 0 0121.675 16a5.03 5.03 0 01-.878-.084l-2.153 2.153c.955.38 1.973.598 3.03.598a8.28 8.28 0 005.9-2.444l1.982-1.982A8.287 8.287 0 0032 8.343a8.287 8.287 0 00-2.444-5.899z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(LinkIcon);
