import React from 'react';
import Icon from '../atoms/Icon';

function PowerIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M14 9.333A4.667 4.667 0 1014 0a4.667 4.667 0 000 9.333zm0-2.666a2 2 0 110-4 2 2 0 010 4z' />
        <path d='M10.613 13.947c.48-.4 1.08-.614 1.707-.614h9.013l1.334-2.666H12.32a5.34 5.34 0 00-3.52 1.32c-.04.04-.08.066-.107.106L5.12 16.227c-.853.986-1.173 2.333-.853 3.613.32 1.267 1.24 2.293 2.386 2.72l6.2 2.08L9.173 32h2.986l3.694-7.4c.174-.333.187-.707.067-1.04a1.306 1.306 0 00-.707-.773l-7.546-2.68a1.36 1.36 0 01-.827-.92c-.107-.44 0-.88.28-1.2l3.493-4.04z' />
        <path d='M32 29.333c-5.892 0-10.667-4.775-10.667-10.666C21.333 12.775 26.108 8 32 8V5.333c-7.364 0-13.333 5.97-13.333 13.334S24.636 32 32 32v-2.667zM7.59 25.315l.162-.98-1.523-.511a5.061 5.061 0 01-1.017-.524l-.193 1.215L0 32h3.519l3.765-6.032c.16-.187.267-.413.307-.653z' />
      </g>
    </Icon>
  );
}

export default React.forwardRef(PowerIcon);
