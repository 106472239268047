export enum ValidationRuleType {
  TEXT = 'text',
  TEXT_LIST = 'textList',
  INT = 'int',
  INT_LIST = 'intList',
  FLOAT = 'float',
  DATE = 'date',
  URL = 'url',
  LINK_URL = 'linkUrl',
  LINK_TEXT = 'linkText',
  SECONDS = 'seconds',
  INCHES = 'inches',
  HS_COMBINE_TYPE = 'hsCombineType',
}

export default interface ValidationRule {
  key: string;
  type: ValidationRuleType;
  intStart: number | null;
  intEnd: number | null;
  floatStart: number | null;
  floatEnd: number | null;
  enumValues: string[] | null;
  pattern: string;
  nullable: boolean;
  dependentColumns: string;
}

// If we change this string need to do in Backend and listner too
export const TEAM_CAMP_STRING = 'Team Camp';