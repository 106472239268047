export const CONFIGURATION_KEY = {
  CURRENT_RECRUITING_YEAR: 'RecruitingClassYear',
  NFL_DRAFT_YEAR: 'NFLDraftYear',
  NEW_HS_PLAYERS_DAYS: 'NewHSPlayersDays',
  NEW_COLLEGE_PLAYERS_DAYS: 'NewCollegePlayersDays',
  DASHBOARD_ROLLOVER_DAY: 'DashboardRolloverDate',
  DASHBOARD_ROLLOVER_MONTH: 'DashboardRolloverMonth',
  ROLLOVER_DAY: 'RolloverDate',
  ROLLOVER_MONTH: 'RolloverMonth',
  TRACK_DATE_PAST_YEAR_LIMIT: 'TrackDatePastYearLimit',
  ROLLOVER_DATE_TRANSFER_PORTAL: 'RolloverDateTransferPortal',
  ROLLOVER_MONTH_TRANSFER_PORTAL: 'RolloverMonthTransferPortal',
};

export default interface Configuration {
  key: string;
  value: string;
}
