import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

const useStyles = makeStyles(theme => ({
  dialogFooter: {
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_PROXIMA_NOVA,
    lineHeight: theme.typography.pxToRem(16),
    padding: theme.spacing(0, 1),
    textAlign: 'center'
  },
}), { name: HelpPopupFooter.name });

export default function HelpPopupFooter () {
  const classes = useStyles();

  return (
    <p className={classes.dialogFooter}>
      Tracking Football will make every attempt to provide prompt attention to questions and needs received.
    </p>
  )
}