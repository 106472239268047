import Conference from './Conference';
import Player from './Player';
import Top5TrackFieldPerformers from './Top5TrackFieldPerformers';
import PlayerStats from './PlayerStats';
import PAIScore from './PAIScore';
import CombineScore from './CombineScore';
import ProCombineScore from './ProCombineScore';
import PPIScore from './PPIScore';

export const TEAM_TYPE = {
  NFL: 'NFL',
  COLLEGE: 'College',
}

export default interface Team {
  id: number;
  type: 'NFL' | 'College';
  name: string;
  shortName: string;
  slug: string;
  logo247: string;
  logoESPN: string;
  logoAlt: string;
  level: string;
  conference: Conference;
  totalWithPAI: number;
  pai: PAIScore[];
  combine: CombineScore[];
  proCombine: ProCombineScore[];
  ppi: PPIScore[];
  averagePAI: number;
  city: string;
  state: string;
  stateAbbr: string;
  division: string;
  top5TrackFieldPerformers?: Top5TrackFieldPerformers;
  playerStats?: PlayerStats;
  positionStats: any;
  recruitingClassStats: any;
  players?: Player[];
  playerCount: number;
  color: string;
  ppiDate: string;
}
