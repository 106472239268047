import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import ColorCode from '../atoms/ColorCode';
import { State } from '../redux/reducers';
import AccessLevel, { AccessLevelType } from '../types/AccessLevel';
import { Color } from '../types/Color';
import Player from '../types/Player';

interface NewPlayerColorCodeProps {
  className?: string;
  player: Player;
  accessLevel: AccessLevel;
  newHSPlayersDays: number;
  newCollegePlayersDays: number;
}

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

const useStyles = makeStyles((theme:Theme) => ({
  newPlayerColorCode: {},
}), { name: NewPlayerColorCode.name });

export function isNewPlayer (
  player: Player,
  accessLevel: AccessLevel|undefined,
  newHSPlayersDays: number,
  newCollegePlayersDays: number,
) {
  const dateNowMs = +(new Date());
  const createdDateMs = +(new Date(player.createdAt));
  const isAdminOrNFLAccount = accessLevel?.type === AccessLevelType.ADMIN || accessLevel?.type === AccessLevelType.NFL;
  const newPlayersDaysMs = (isAdminOrNFLAccount ? newCollegePlayersDays : newHSPlayersDays) * ONE_DAY_MS;

  return (dateNowMs - createdDateMs) < newPlayersDaysMs;
}

function NewPlayerColorCode (props:NewPlayerColorCodeProps) {
  const {
    className,
    player,
    accessLevel,
    newHSPlayersDays,
    newCollegePlayersDays,
  } = props;
  const classes = useStyles();

  return isNewPlayer(player, accessLevel, newHSPlayersDays, newCollegePlayersDays)
    ? <Tooltip title='New'>
        <ColorCode
          className={clsx(classes.newPlayerColorCode, className)}
          color={Color.ORANGE}
          tooltipText="New player"
        />
      </Tooltip>
    : null;
}

const mapStateToProps = (state:State) => {
  return {
    accessLevel: state.user.accessLevel,
    newHSPlayersDays: state.configurations.newHSPlayersDays,
    newCollegePlayersDays: state.configurations.newCollegePlayersDays,
  };
};

export default connect(
  mapStateToProps,
)(NewPlayerColorCode);
