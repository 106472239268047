import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import ExportsIcon from '../icons/ExportsIcon';
import ExportsTable from '../organisms/ExportsTable';
import { COLOR_TEXT } from '../styles/colors';

const useStyles = makeStyles(theme => ({
  exportsPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  exportsTable: {
    padding: theme.spacing(4),
  },
}), { name: ExportsPageTemplate.name });

export default function ExportsPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.exportsPageTemplate}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <ExportsIcon className={classes.headerIcon} />
            Exports
          </h1>
        </div>

        <ExportsTable className={classes.exportsTable} />
      </Paper>
    </PageContainer>
  );
}
