import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from './Tooltip';
import {
  COLOR_WHITE,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_TEXT,
  COLOR_YELLOW,
  COLOR_DARK_GRAY,
  COLOR_PURPLE,
} from '../styles/colors';
import { Color } from '../types/Color';

interface ColorCodeProps {
  className?: string;
  color: Color;
  colors?: Color[];
  tooltipText?: string;
  selected?: boolean;
  showSearchTooltip?: boolean;
  onClick?: (event:any) => void;
}

const useStyles = makeStyles((theme:Theme) => ({
  colorCode: {
    appearance: 'none',
    width: '6px',
    height: '6px',
    padding: 0,
    border: 0,
    borderRadius: '50%',
    color: COLOR_TEXT,
    background: 'currentColor',
    position: 'relative',
    outline: 'none',
    cursor: 'help',

    '&:after': {
      content: '""',
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
      border: '2px solid transparent',
      borderRadius: '50%',
      boxSizing: 'border-box',
    }
  },
  [Color.ORANGE]: {
    color: COLOR_ORANGE,
  },
  [Color.BLACK]: {
    color: COLOR_TEXT,
  },
  [Color.GREEN]: {
    color: COLOR_GREEN,
  },
  [Color.RED]: {
    color: COLOR_RED,
  },
  [Color.YELLOW]: {
    color: COLOR_YELLOW,
  },
  [Color.NONE]: {
    color: COLOR_WHITE,
  },
  [Color.GREY]: {
    color: COLOR_DARK_GRAY,
  },
  [Color.PURPLE]: {
    color: COLOR_PURPLE,
  },

  selected: {
    '&:after': {
      borderColor: 'currentColor',
    }
  },
  selectable: {
    cursor: 'pointer',
  },
}), { name: ColorCode.name });

function ColorCode (props: ColorCodeProps, ref: React.Ref<any>) {
  const {
    className,
    color = Color.BLACK,
    tooltipText,
    showSearchTooltip = false,
    selected = false,
    colors,
    onClick,
  } = props;
  const classes = useStyles();

  const searchTooltipText = {
    [Color.ORANGE]: 'New player',
    [Color.BLACK]: 'User notes',
    [Color.GREEN]: 'User evaluation',
    [Color.RED]: 'User evaluation',
    [Color.YELLOW]: 'User evaluation',
    [Color.GREY]: 'Contact info',
    [Color.PURPLE]: 'In-game data',
    [Color.NONE]: 'No Color',
  };

  if (!color || color === Color.NONE) {
    return null;
  }

  const colorName = `${color.slice(0, 1)}${color.slice(1).toLowerCase()}`;

  let title:string|undefined = tooltipText;
  if (onClick) {
    if (selected) {
      title = 'Remove Color';
    } else {
      title = `Set ${colorName} Color`;
    }
  }
  if (colors?.length) {
    return (
      <>
      {
        colors?.filter(c => c).map((colour, index) => {
          return (
          <Tooltip key={index} 
          title={showSearchTooltip ? searchTooltipText[colour] : tooltipText}
          >
            <button
              className={clsx(
                classes.colorCode,
                classes[colour],
                selected && classes.selected,
                onClick && classes.selectable,
                className,
              )}
              ref={ref}
              onClick={onClick}
            />
          </Tooltip>
          )
        })
      }
      </>
    );  
  } else {
    return (
      <Tooltip title={title}>
        <button
          className={clsx(
            classes.colorCode,
            classes[color],
            selected && classes.selected,
            onClick && classes.selectable,
            className,
          )}
          ref={ref}
          onClick={onClick}
        />
      </Tooltip>
    );
  }
}

export default React.forwardRef(ColorCode);
