import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_TEXT,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface DatePickerProps {
  className?: string;
  inputFieldClassName?: string;
  fontSize?: number;
  label?: string;
  defaultValue?: Date | string;
  value: Date | string | null;
  disabled?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (value:Date | null, event:any) => void;
  onBlur?: (event:any) => void;
}

function convertDateToPickerFormat (date?:Date | string | null):string {
  if (!date) return '';

  const rawDate = date instanceof Date ? date : new Date(date);
  const year = rawDate.getFullYear();
  const month = ('0' + (rawDate.getMonth() + 1)).slice(-2);
  const day = ('0' + rawDate.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

function convertPickerFormatToDate (date:string):Date | null {
  if (!date) return null;

  const [year, month, day] = date.split('-');

  return new Date(`${month}/${day}/${year}`);
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    borderRadius: 0,
    backgroundColor: COLOR_LIGHT_GRAY,

    '&:before': {
      borderWidth: '2px',
      borderColor: COLOR_DARK_GRAY,
    },
    '&:after': {
      borderWidth: '2px',
      borderColor: COLOR_BLUE,
    },

    '&:hover': {
      backgroundColor: COLOR_LIGHT_GRAY,

      '&:before': {
        borderWidth: '2px',
      },
    }
  },
  inputLabel: {
    top: (props:DatePickerProps) => theme.typography.pxToRem(-1 * (16 - (props.fontSize || 16))),
    left: theme.spacing(0.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: (props:DatePickerProps) => theme.typography.pxToRem(props.fontSize || 16),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&$inputLabelFocused': {
      color: COLOR_BLUE,
      fontWeight: 600,
    }
  },
  inputLabelShrink: {
    fontWeight: 600,
    fontSize: (props:DatePickerProps) => theme.typography.pxToRem((props.fontSize || 16) * 0.8),
    color: COLOR_BLUE,
  },
  inputLabelFocused: {},

  inputField: {
    padding: theme.spacing(3, 2, 1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: (props:DatePickerProps) => theme.typography.pxToRem(props.fontSize || 16),
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  calendarIcon: {
    height: '24px',
    width: '24px',
  }
}), { name: DatePicker.name });

export default function DatePicker (props: DatePickerProps) {
  const classes = useStyles(props);
  const {
    className,
    label = 'Select Date',
    defaultValue,
    value,
    inputFieldClassName,
    disabled,
    onChange = () => {},
    onBlur = () => {},
    minDate,
    maxDate
  } = props;

  return (
    <TextField
      className={className}
      InputProps={{
        classes: {
          root: classes.input,
          input: clsx(classes.inputField, inputFieldClassName),
        }
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.inputLabel,
        classes: {
          shrink: classes.inputLabelShrink,
          focused: classes.inputLabelFocused,
        }
      }}
      type='date'
      variant='filled'
      disabled={disabled}
      label={label}
      defaultValue={defaultValue ? convertDateToPickerFormat(defaultValue) : ''}
      value={value ? convertDateToPickerFormat(value) : ''}
      onChange={(event:any) => onChange(convertPickerFormatToDate(event.target.value), event)}
      onBlur={onBlur}
      inputProps={{
        min: minDate ? convertDateToPickerFormat(minDate) : undefined,
        max: maxDate ? convertDateToPickerFormat(maxDate) : undefined
      }}
    />
  )
}
