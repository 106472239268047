import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import get from 'lodash/get';
import ColorCode from '../atoms/ColorCode';
import MultiSelect from './MultiSelect';
import { isNewPlayer } from './NewPlayerColorCode';
import { Color } from '../types/Color';
import Player from '../types/Player';
import AccessLevel from '../types/AccessLevel';

interface SurfaceToTopProps {
  className?: string;
  values: string[];
  displayOnlyNew?: boolean;
  onSelect: (values:string[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  surfaceToTop: {
    width: '160px',
  },
  surfaceToTopSelector: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'pre',

    '& $surfaceToTopColor': {
      display: 'none',
    },
  },
  surfaceToTopValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  surfaceToTopColor: {
    marginRight: theme.spacing(1),
  },
}), { name: SurfaceToTop.name });

export default function SurfaceToTop (props: SurfaceToTopProps) {
  const {
    className,
    displayOnlyNew = false,
    values = [],
    onSelect = () => {},
  } = props;
  const classes = useStyles();

  const items = [
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.ORANGE}
          />
          New Players
        </div>
      ),
      value: Color.ORANGE,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.GREY}
          />
          Contact Info or Ryzer Info
        </div>
      ),
      value: Color.GREY,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.PURPLE}
          />
          In-Game Data (MPH)
        </div>
      ),
      value: Color.PURPLE,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.BLACK}
          />
          User Notes
        </div>
      ),
      value: Color.BLACK,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.GREEN}
          />
          User Eval
        </div>
      ),
      value: Color.GREEN,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.YELLOW}
          />
          User Eval
        </div>
      ),
      value: Color.YELLOW,
    },
    {
      content: (
        <div className={classes.surfaceToTopValue}>
          <ColorCode
            className={classes.surfaceToTopColor}
            color={Color.RED}
          />
          User Eval
        </div>
      ),
      value: Color.RED,
    }
  ];

  return (
    <MultiSelect
      className={clsx(classes.surfaceToTop, className)}
      selectorRootClassName={classes.surfaceToTopSelector}
      labelWhenSelectedNone='Select'
      items={displayOnlyNew ? items.slice(0, 1) : items}
      values={values}
      onChange={(values:string[]) => onSelect(values)}
    />
  );
}

export function surfaceToTopPlayers (
  players: Player[],
  surfaceToTopValues: string[],
  accessLevel: AccessLevel|undefined,
  newHSPlayersDays: number,
  newCollegePlayersDays: number,
):Player[] {
  if (players && players.length) {
    const needToFilterByNewPlayers = surfaceToTopValues.includes(Color.ORANGE);

    const newPlayersIds = needToFilterByNewPlayers
      ? players
        .filter((player:Player) => isNewPlayer(player, accessLevel, newHSPlayersDays, newCollegePlayersDays))
        .map((player:Player) => player.id)
      : [];

    let surfacedToTopPlayers = surfaceToTopValues
      .map((color:string) => {
        if (color === Color.ORANGE) {
          return players.filter((player:Player) => newPlayersIds.includes(player.id));
        } else if (color === Color.PURPLE) {
          return players.filter((player:Player) => {
            const inGameRating = {
              mph: player && player?.bestHighSchoolMPH?.mph || 0,
            }
            if (inGameRating.mph > 0) {
              return player;
            }
          });
        } else if (color === Color.GREY) {
          return players.filter((player:Player) => {
            const isContactInfoAvailable = player && (player?.email || player?.cellPhone || player?.address) ? true : false;
            if (isContactInfoAvailable) {
              return player;
            }
          });
        } else {
          const notNewPlayers = needToFilterByNewPlayers
            ? players.filter(player => !newPlayersIds.includes(player.id))
            : players;
          return notNewPlayers.filter((player:Player) => get(player, 'notes[0].colorCode') === color);
        }
      })
      .flat();
    let surfacedToTopPlayersIds = surfacedToTopPlayers.map(player => player.id);
    surfacedToTopPlayersIds = Array.from(new Set(surfacedToTopPlayersIds))
    surfacedToTopPlayers = Array.from(new Set(surfacedToTopPlayers))
    return [
      ...surfacedToTopPlayers,
      ...(players.filter(player => !surfacedToTopPlayersIds.includes(player.id))),
    ];
  } else {
    return players;
  }
}
