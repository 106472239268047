import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import RemoveIcon from '../icons/RemoveIcon';
import WeightIcon from '../icons/WeightIcon';
import HeightIcon from '../icons/HeightIcon';
import TrackDataIcon from '../icons/TrackIcon';
import PAIIcon from '../icons/PAIIcon';
import StarRatingIcon from '../icons/StarIcon';
import CombineDataIcon from '../icons/CombineDataIcon';
import UpdateIcon from '../icons/UpdateIcon';
import CombineIcon from '../icons/CombineIcon';
import Avatar from '../atoms/Avatar';
import Action from '../atoms/Action';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Download from '../atoms/Download';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import TeamLogo from '../atoms/TeamLogo';
import Offers from '../molecules/Offers';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import { State } from '../redux/reducers';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import { datetimeToDate } from '../services/converter';
import { fetchCollegeTeams } from '../redux/dispatchers/teams';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { getPrimaryHighSchool } from '../services/player';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_BACKGROUND_LIGHT,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_BORDER,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import PlayerUpdate, { PlayerUpdateType } from '../types/PlayerUpdate';
import Player from '../types/Player';
import Team from '../types/Team';
import { Order } from '../types/Order';
import MEDIA from '../styles/media';
import ConfirmDialog from './ConfirmDialog';

interface AlertsTableProps {
  className?: string;
  nflAccess?: boolean;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  fetchCollegeTeams: () => void;
}

const COLUMN = {
  NAME: 'name',
  HOME_TOWN: 'homeTown',
  COLLEGE: 'college',
  POSITION: 'position',
  CLASS_YEAR: 'class',
  UPDATE: 'update',
  DATE_UPDATED: 'createdAt',
};

const COLUMN_TITLE = {
  [COLUMN.NAME]: 'Name',
  [COLUMN.HOME_TOWN]: 'Home Town',
  [COLUMN.COLLEGE]: 'College',
  [COLUMN.POSITION]: 'Position',
  [COLUMN.CLASS_YEAR]: 'Class',
  [COLUMN.UPDATE]: 'Update',
  [COLUMN.DATE_UPDATED]: 'Date Updated',
};

const useStyles = makeStyles(theme => ({
  alertsTable: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(3, 5, 0),
  },

  tableWrapper: {
    overflow: 'auto',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },

  titleRow: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  titleTextWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 4, 0, 0),
  },
  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    flexShrink: 0,
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    padding: theme.spacing(0,0,0,2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-of-type': {
      paddingLeft: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },
  exportReport: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    marginLeft: 'auto',
  },

  table: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(16),
    border: 0,
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },
  emptyCell: {
    paddingLeft: theme.spacing(1),
  },

  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },
  playerUpdate: {
    display: 'flex',
    alignItems: 'center',
  },
  playerUpdateIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_DARK_GRAY,
  },
  playerUpdateText: {
    marginLeft: theme.spacing(1),
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },
  playerLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },
  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  playerHometown: {
    color: COLOR_DARK_GRAY,
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  [MEDIA.MOBILE]: {
    alertsTable: {
      margin: theme.spacing(2, 2, 0),
    },

    titleRow: {
      display: 'block',
      padding: theme.spacing(2),
    },

    dropdownWrapper: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
}), { name: AlertsTable.name });

function AlertsTable (props: AlertsTableProps) {
  const {
    className,
    nflAccess,
    collegeTeams,
    collegeTeamsLoading,
    fetchCollegeTeams,
  } = props;
  const classes = useStyles();

  const [updates, setUpdates] = useState<PlayerUpdate[]>([]);

  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.ALERTS_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.ALERTS_TABLE) || {}).limit || 10);
  const [order, setOrder] = useState<Order | undefined>(Order.asc);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.ALERTS_TABLE) || {}).currentPage || 1);
  const [sortedByColumn, setSortedByColumn] = useState<string>(COLUMN.DATE_UPDATED);
  const [selectedItems, setSelectedItems] = useState<PlayerUpdate[]>([]);

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [removeAlertDialogOpen, setRemoveAlertDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchCollegeTeams();
    fetchPlayerUpdates();
  }, []);

  useEffect(() => {
    clientStorage.save(StorageKey.ALERTS_TABLE, {currentPage, limit});
  }, [currentPage, limit]);

  useEffect(() => {
    if (prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [limit]);

  function fetchPlayerUpdates() {
    if (!pageLoading) setPageLoading(true);

    gql(`
      playerUpdates (limit: 100) {
        id
        type
        eventDetails
        player {
          id
          slug
          firstName
          lastName
          photoUrl
          highSchools
          offers
          committedToTeamId
          playerColleges {
            positions
            recruitingClass
            isPrimary
            team {
              id
              name
            }
          }
        }
        createdAt
      }
    `)
      .then((data:any) => data.playerUpdates as PlayerUpdate[])
      .then((updates:PlayerUpdate[]) => {
        setUpdates(updates);
      })
      .catch(console.error)
      .finally(() => setPageLoading(false));
  }

  function removeAllAlerts () {
    setPageLoading(true);

    return gql(`
      mutation {
        removeAllAlerts
      }
    `)
      .then((data:any) => data.removeAllAlerts as boolean)
      .then((success:boolean) => {
        console.log('success', success)
        setRemoveAlertDialogOpen(false)
        if (success) {
          fetchPlayerUpdates();
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
        .catch((err) => {
          console.log('catch err', err);
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        })
      .finally(() => setPageLoading(false));
  }
  
  function removeAlerts () {
    setPageLoading(true);
    let uniquePlayerIds = Array.from(new Set(selectedItems.map((item:PlayerUpdate) => item.player.id)));

    return gql(`
      mutation {
        removeAlerts (playerIds: [${uniquePlayerIds}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((success:boolean) => {
        setRemoveAlertDialogOpen(false)
        if (success) {
          fetchPlayerUpdates();
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
        .catch(() => {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        })
      .finally(() => setPageLoading(false));
  }

  function onXLSDownload () {
    if (updates && updates.length) {
      setPageLoading(true);

      exportXLSReport(
        XLSExportType.PLAYERS,
        { ids: updates.map(update => update.player.id) },
      )
        .catch(error => {
          if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
            showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
          } else {
            console.error(error);
            showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
          }
        })
        .finally(() => setPageLoading(false));
    }
  }

  function onCheckBoxChange (checkedItem:PlayerUpdate) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onSort (columnName:string, newOrder:Order, sort:(playerUpdate:PlayerUpdate[], order:Order) => PlayerUpdate[]) {
    setSortedByColumn(columnName);
    setUpdates(sort(updates, newOrder));
  }

  function onSortByColumn (sort:(exports:PlayerUpdate[], order:Order) => PlayerUpdate[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function updateTypeToText (updateType:PlayerUpdateType):string {
    const words = updateType
      .split('-')
      .map(word => word[0].toUpperCase() + word.toLowerCase().slice(1));

    switch (updateType) {
      case PlayerUpdateType.COMMITTED:
      case PlayerUpdateType.OFFERS:
        return words.join(' ');
      default:
        return `Updated ${words.join(' ')}`;
    }
  }

  function getIconForType (updateType:PlayerUpdateType, playerUpdate:PlayerUpdate) {
    const player:Player = playerUpdate.player;
    switch (updateType) {
      case PlayerUpdateType.WEIGHT:
        return WeightIcon;
      case PlayerUpdateType.HEIGHT:
        return HeightIcon;
      case PlayerUpdateType.TRACK_DATA:
        return TrackDataIcon;
      case PlayerUpdateType.PAI_SCORE:
        return PAIIcon;
      case PlayerUpdateType.COMBINE_SCORE:
        return CombineIcon;
      case PlayerUpdateType.STAR_RATING:
        return StarRatingIcon;
      case PlayerUpdateType.COMBINE_DATA:
        return CombineDataIcon;
      case PlayerUpdateType.COMMITTED:
        if (player.committedToTeamId) {
          return () => (
            <TeamLogo
              className={classes.playerUpdateIcon}
              team={collegeTeams.find(team => team.id === player.committedToTeamId)}
            />
          );
        }
        break;
      case PlayerUpdateType.OFFERS:
        if (!!playerUpdate?.eventDetails?.offers?.length) {
          return () => (
            <Offers
              offers={playerUpdate.eventDetails.offers}
              displayTotalOffersCount
              limit={4}
            />
          );
        }
        break;
      default:
        return UpdateIcon;
    }
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function sortPlayers (
    players:PlayerUpdate[],
    order:Order,
    getValue: (players:PlayerUpdate) => string | number,
  ) {
    return players.sort((first:PlayerUpdate, second:PlayerUpdate) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function renderTableRow (playerUpdate:PlayerUpdate, index:number) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === playerUpdate.id);

    const { id } = playerUpdate;

    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
      >
        <TableCell
          key='check-box-cell'
          className={clsx(classes.tableCell, classes.checkBoxCell)}
        >
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(playerUpdate)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
            )}
          >
            {tableColumn.renderContent(playerUpdate)}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  function getPrimaryPosition (player:Player) {
    const primaryHS = getPrimaryHighSchool(player);
    const primaryPosition = ((primaryHS || {}).positions || [])
      .find((position:any) => position && position.isPrimary);

    return primaryPosition ? primaryPosition.code : '';
  }

  const columns = [
    COLUMN.NAME,
    nflAccess ? COLUMN.COLLEGE : COLUMN.HOME_TOWN,
    COLUMN.POSITION,
    COLUMN.CLASS_YEAR,
    COLUMN.UPDATE,
    COLUMN.DATE_UPDATED,
  ];

  const tableColumns = columns.map((column:string) => {
    switch (column) {
      case COLUMN.NAME:
        return {
          value: COLUMN.NAME,
          title: COLUMN_TITLE[COLUMN.NAME],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => {
            const {
              player: {
                slug,
                firstName,
                lastName,
                photoUrl,
              },
            } = playerUpdate;

            return (
              <div className={classes.playerInfoContainer}>
                <Avatar
                  className={classes.playerAvatar}
                  src={photoUrl}
                  alt='Profile picture'
                />

                <div className={classes.playerNameAndHometown}>
                  <Link
                    className={classes.playerLink}
                    to={`/player/${slug}?`}
                    target='_blank'
                  >
                    {firstName} {lastName}
                  </Link>
                </div>
              </div>
            )
          },
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (update:PlayerUpdate) => `${update.player.lastName} ${update.player.firstName}`
            );
          },
        };
      case COLUMN.HOME_TOWN:
        return {
          value: COLUMN.HOME_TOWN,
          title: COLUMN_TITLE[COLUMN.HOME_TOWN],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => {
            const highSchool = getPrimaryHighSchool(playerUpdate.player);
            return highSchool ? `${highSchool.city}, ${highSchool.state}` : <NotAvailable />
          },
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => {
                const highSchool = getPrimaryHighSchool(playerUpdate.player);
                return highSchool ? `${highSchool.city}, ${highSchool.state}` : '';
              }
            );
          },
        };
      case COLUMN.COLLEGE:
        return {
          value: COLUMN.COLLEGE,
          title: COLUMN_TITLE[COLUMN.COLLEGE],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => {
            const college = get(playerUpdate.player, 'playerColleges[0].team');
            return college ? college.name : <NotAvailable />
          },
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => get(playerUpdate, 'player.playerColleges[0].team.name',  ''),
            );
          },
        };
      case COLUMN.POSITION:
        return {
          value: COLUMN.POSITION,
          title: COLUMN_TITLE[COLUMN.POSITION],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => (getPrimaryPosition(playerUpdate.player) || <NotAvailable />),
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => (getPrimaryPosition(playerUpdate.player))
            );
          },
        };
      case COLUMN.CLASS_YEAR:
        return {
          value: COLUMN.CLASS_YEAR,
          title: COLUMN_TITLE[COLUMN.CLASS_YEAR],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => {
            const highSchool = getPrimaryHighSchool(playerUpdate.player);
            return (highSchool ? highSchool.graduatingClass : <NotAvailable />)},
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => ((getPrimaryHighSchool(playerUpdate.player) || {}).graduatingClass || '')
            );
          },
        };
      case COLUMN.UPDATE:
        return {
          value: COLUMN.UPDATE,
          title: COLUMN_TITLE[COLUMN.UPDATE],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => {
            const Icon:any = getIconForType(playerUpdate.type, playerUpdate) || (() => null);

            return playerUpdate.type ? (
              <div className={classes.playerUpdate}>
                <Icon className={classes.playerUpdateIcon} />

                <span className={classes.playerUpdateText}>
                  {updateTypeToText(playerUpdate.type)}
                </span>
              </div>
            ) : <NotAvailable />;
          },
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => (playerUpdate.type)
            );
          },
        };
      case COLUMN.DATE_UPDATED:
        return {
          value: COLUMN.DATE_UPDATED,
          title: COLUMN_TITLE[COLUMN.DATE_UPDATED],
          sortable: true,
          renderContent: (playerUpdate:PlayerUpdate) => playerUpdate.createdAt ? datetimeToDate(playerUpdate.createdAt) : <NotAvailable />,
          sort: (playerUpdates:PlayerUpdate[], order:Order) => {
            return sortPlayers(
              playerUpdates,
              order,
              (playerUpdate:PlayerUpdate) => playerUpdate.createdAt
            );
          },
        };
    }
  });

  const actionDisabled = !selectedItems.length;
  const totalPages = Math.ceil(updates.length / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = updates.length < limit ? updates.length : (limit * currentPage);

  const loading = pageLoading || collegeTeamsLoading;

  return (
    <div className={clsx(classes.alertsTable, className)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleRow}>
          <div className={classes.titleTextWrap}>
            <h2 className={classes.headerTitle}>
              Your Alert List Players
            </h2>
          </div>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Players
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <Action
            className={clsx(classes.action, actionDisabled && classes.disabledAction)}
            icon={RemoveIcon}
            iconClassName={classes.actionIcon}
            disabled={actionDisabled}
            onClick={removeAlerts}
          >
            <span className={classes.actionText}>Remove Alert</span>
          </Action>

          <Action
            className={clsx(classes.action, classes.disabledAction)}
            icon={RemoveIcon}
            iconClassName={classes.actionIcon}
            onClick={() => setRemoveAlertDialogOpen(true)}
          >
            <span className={classes.actionText}>Remove All</span>
          </Action>

          <ConfirmDialog
            title={"Remove alerts on all players?"}
            open={removeAlertDialogOpen}
            confirmActionText={`Remove`}
            onConfirm={() => removeAllAlerts()}
            onClose={() => setRemoveAlertDialogOpen(false)}
            onCancel={() => setRemoveAlertDialogOpen(false)}
          />

          <Download
            className={classes.exportReport}
            label='XLS'
            onClick={onXLSDownload}
          />
        </div>
      </div>

      <div className={classes.tableWrapper}>
        <Table className={clsx(classes.table, className)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
              >
                <Checkbox
                  checked={updates.length === selectedItems.length}
                  onChange={() => updates.length === selectedItems.length
                    ? setSelectedItems([])
                    : setSelectedItems([...updates])
                  }
                />
              </TableHeadCell>

              {tableColumns.map((tableColumn:any) => (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable
                    ? onSortByColumn(tableColumn.sort || ((updates:PlayerUpdate[]) => updates))
                    : undefined
                  }
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!updates.length && (
              <TableRow
                key='empty'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.emptyCell)}
                  colSpan={tableColumns.length + 1}
                >
                  <div className={classes.empty}>
                    No Alert
                  </div>
                </TableCell>
              </TableRow>
            )}

            {updates
              .slice(itemsFrom, itemsTo)
              .map(renderTableRow)
            }
          </TableBody>
        </Table>
      </div>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {updates.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    nflAccess: state.ui.nflAccess,
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertsTable);
