import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FolderIcon from '../icons/FolderIcon';
import Input from '../atoms/Input';
import Dialog from './Dialog';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_ORANGE } from '../styles/colors';
import MEDIA from '../styles/media';

interface NewFolderProps {
  className?: string;
  onCreate: (name:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  newFolder: {
    appearance: 'none',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    border: 0,
    padding:  theme.spacing(0, 1),
    margin: theme.spacing(0, 1, 0, 'auto'),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_BLUE,
    cursor: 'pointer',
    outlineColor: COLOR_ORANGE,

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  folderIcon: {
    width: '22px',
    height: '22px',
    marginRight: theme.spacing(1),
    color: COLOR_BLUE,
  },

  input: {
    marginTop: theme.spacing(3),
  },
  inputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  [MEDIA.MOBILE]: {
    newFolder: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}), { name: NewFolder.name });

export default function NewFolder (props: NewFolderProps) {
  const {
    className,
    onCreate,
  } = props;
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className={clsx(classes.newFolder, className)}
        onClick={() => setDialogOpen(true)}
      >
        <FolderIcon className={classes.folderIcon} />
        New Folder
      </button>

      <Dialog
        open={dialogOpen}
        title='Create a new folder'
        actions={[
          {
            name: 'Cancel',
            onClick: () => setDialogOpen(false),
          },
          {
            name: 'Create',
            primary: true,
            onClick: () => {
              onCreate(name);
              setDialogOpen(false);
              setName('');
            },
          },
        ]}
        onClose={() => setDialogOpen(false)}
      >
        <Input
          className={classes.input}
          inputFieldClassName={classes.inputField}
          label='Folder Name'
          fontSize={14}
          value={name}
          autoFocus
          onChange={setName}
        />
      </Dialog>
    </>
  );
}
