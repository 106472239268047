import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '../atoms/Tooltip';
import TableHeadCell from '../atoms/TableHeadCell';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import NotAvailable from '../atoms/NotAvailable';
import MyExportsIcon from '../icons/MyExportsIcon';
import gql from '../services/gql';
import exportXLSReport, {
  downloadAndSaveXLSReport,
  ERROR_MESSAGE_CHECK_EMAIL,
  XLSExportType,
} from '../services/export-xls-report';
import { datetimeToDate, formatNumbersToCommaSeparatedString } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_BACKGROUND_WARM,
  COLOR_BLUE,
  COLOR_ORANGE,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import PlayerExport from '../types/Export';

interface UserInfoProps {
  className?: string;
}

const COLUMN = {
  NAME: 'name',
  PLAYERS: 'players',
  EXPIRES: 'expires',
};

const COLUMNS = [
  COLUMN.NAME,
  COLUMN.PLAYERS,
  COLUMN.EXPIRES,
];

const COLUMN_TITLE = {
  [COLUMN.NAME]: 'Name',
  [COLUMN.PLAYERS]: 'Players',
  [COLUMN.EXPIRES]: 'Expires',
};

const useStyles = makeStyles(theme => ({
  myExportsTable: {
    position: 'relative',
  },

  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0, 2, 0, 4),
    fontFamily: FONT_PROXIMA_NOVA,
    position: 'relative',
  },
  headerTitleText: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(0.5),
  },
  exportsRemaining: {
    color: COLOR_ORANGE,
    cursor: 'help',
    fontSize: theme.typography.pxToRem(12),
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    textAlign: 'right',
    textTransform: 'capitalize',
  },
  exportsTableWrap: {
    overflow: 'auto'
  },
  exportsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: 'none',
    background: COLOR_WHITE,
  },
  tableHead: {
    border: 'none',
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderTop: `1px solid ${COLOR_LIGHT_GRAY}`,
  },
  tableHeadCell: {
    borderLeft: 'none',
    color: COLOR_DARK_GRAY,
    padding: theme.spacing(1.25),

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  tableRow: {
    height: '50px',
  },
  tableCell: {
    padding: theme.spacing(1.25),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  nameCell: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  playerCountCell: {
    textAlign: 'center',
  },
  empty: {
    alignItems: 'center',
    borderColor: COLOR_BACKGROUND_WARM,
    borderStyle: 'solid',
    borderWidth: `${theme.typography.pxToRem(1)} 0`,
    color: COLOR_DARK_GRAY,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    padding: theme.spacing(2, 0),
  },
  fileLink: {
    cursor: 'pointer',
    color: COLOR_BLUE,
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(16),
    padding: 0,

    '&:focus': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'none',
      outline: 'none',
    }
  },
  tooltip: {
    padding: theme.spacing(1.5),
  },

  [MEDIA.MOBILE]: {
    headerTitle: {
      padding: theme.spacing(0, 2),
    },
  },
}), { name: MyExportsTable.name });

export default function MyExportsTable (props: UserInfoProps) {
  const classes = useStyles();
  const {
    className,
  } = props;

  const [remainingExports, setRemainingExports] = useState<any>('');
  const [playersExports, setPlayersExports] = useState<PlayerExport[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    Promise.allSettled([
      fetchExportsRemaining(),
      fetchPlayersExports(),
    ])
      .finally(() => setLoading(false));
  }, []);

  function fetchExportsRemaining () {
    return gql(`remainingExports`)
      .then((data: any) => data.remainingExports as any)
      .then((remainingExports: any) => {
        setRemainingExports(formatNumbersToCommaSeparatedString(remainingExports));
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch remaining exports count', ToastType.ERROR);
      });
  }

  function fetchPlayersExports () {
    return gql(`
      playersExports {
        id
        playerIds
        playerCount
        fileUrl
        fileName
        expirationDate
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.playersExports as PlayerExport[])
      .then((playersExports:PlayerExport[]) => setPlayersExports(playersExports))
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch the list of exports', ToastType.ERROR);
      });
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function renderExportRow (exportRowData: PlayerExport) {
    return (
      <TableRow
        key={exportRowData.id}
        className={classes.tableRow}
      >
        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === COLUMN.NAME && classes.nameCell,
              tableColumn.value === COLUMN.PLAYERS && classes.playerCountCell,
              )}
              >
            {tableColumn.renderContent(exportRowData)}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  function onXLSDownload () {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: playersExports.map(player => player.id) }
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  const tableColumns = COLUMNS.map((column: string) => {
    switch (column) {
      case COLUMN.NAME:
        return {
          value: COLUMN.NAME,
          title: COLUMN_TITLE[COLUMN.NAME],
          renderContent: (playerExport:PlayerExport) => (
            <a
              className={classes.fileLink}
              href={playerExport.fileUrl}
              onClick={event => {
                event.preventDefault();
                downloadAndSaveXLSReport(playerExport.fileName, undefined, playerExport.fileUrl);
              }}
            >
              {playerExport.fileName}
            </a>
          ),
        };
      case COLUMN.PLAYERS:
        return {
          value: COLUMN.PLAYERS,
          title: COLUMN_TITLE[COLUMN.PLAYERS],
          renderContent: (playerExport: PlayerExport) => playerExport.playerCount,
        };
      case COLUMN.EXPIRES:
        return {
          value: COLUMN.EXPIRES,
          title: COLUMN_TITLE[COLUMN.EXPIRES],
          renderContent: (playerExport:PlayerExport) => playerExport.expirationDate ? datetimeToDate(playerExport.expirationDate) : <NotAvailable />,
        };
    }
  });

  return (
    <div className={clsx(classes.myExportsTable, className)}>
      <Loader inProgress={loading} />

      <h2 className={classes.headerTitle}>
        <span className={classes.headerTitleText}>
          <MyExportsIcon  className={classes.headerIcon} />
          My Exports
        </span>
        <Tooltip
          title={`${remainingExports} players are available to export each month`}
        >
          <div
            className={classes.exportsRemaining}
            onClick={onXLSDownload}
          >
            {`${remainingExports} Exports Remaining`}
          </div>
        </Tooltip>
      </h2>

      <div className={classes.exportsTableWrap}>
        {(!loading && playersExports && !playersExports.length) && (
          <div className={classes.empty}>
            No Files Available
          </div>
        )}

        {(playersExports && !!playersExports.length) && (
          <Table className={classes.exportsTable}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {tableColumns.map((tableColumn:any) => (
                  <TableHeadCell
                    key={tableColumn.value}
                    className={clsx(
                      classes.tableHeadCell,
                      tableColumn.value === COLUMN.NAME && classes.nameCell,
                    )}
                  >
                    {tableColumn.title}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {playersExports
                .map((item: PlayerExport) => (
                  renderExportRow(item as PlayerExport)
                ))}
            </TableBody>
          </Table>
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}
