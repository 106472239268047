import React from 'react';
import SavedPlayersPageTemplate from '../templates/SavedPlayersPageTemplate';
import Page from './Page';

export default function SavedPlayersPage () {
  return (
    <Page title='Saved Players'>
      <SavedPlayersPageTemplate />
    </Page>
  );
}
