import React from 'react';
import Icon from '../atoms/Icon';

function TeamsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='currentColor'
        strokeWidth='2'
      >
        <path d='M11 23v4h14V15h3v-4l-4-1.143'/>
        <path d='M17 5s-.977 1-3 1-3-1-3-1L4 7v4h3v12h14V11h3V7l-7-2z'/>
        <path d='M12 18h2.5a1.5 1.5 0 001.5-1.5h0a1.5 1.5 0 00-1.5-1.5H14m-1 0h1.5a1.5 1.5 0 001.5-1.5h0a1.5 1.5 0 00-1.5-1.5H12'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TeamsIcon);
