import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BackIcon from '../icons/BackIcon';
import { getTeamLogo } from '../atoms/TeamLogo';
import Avatar from '../atoms/Avatar';
import Loader from '../atoms/Loader';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import User from '../types/User';
import Team from '../types/Team';
import { COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import MEDIA from '../styles/media';

interface TeamProfileHeaderProps {
  loading: boolean;
  team?: Team;
  teams?: Team[];
  user?: User;
  onTeamSelect?: (team:Team) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    minHeight: '150px',
    marginBottom: '100px',
    background: COLOR_WHITE,
    transition: 'background 0.3s',
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    background: 'none',
    border: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_WHITE,
    cursor: 'pointer',
    transition: 'color 0.3s',
    outline: 'none',

    '&:focus $backButtonIcon': {
      border: `1px solid ${COLOR_WHITE}`,
      borderRadius: '50%',
    },
    '&:hover:focus $backButtonIcon': {
      borderColor: `1px solid ${COLOR_TEXT}`,
      borderRadius: '50%',
    },
  },
  backButtonIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_WHITE,
  },
  backButtonText: {
    marginLeft: theme.spacing(1),
  },

  logo: {
    width: '128px',
    height: '128px',
    padding: theme.spacing(2),
    left: theme.spacing(5),
    bottom: '-64px',
    position: 'absolute',
    background: COLOR_WHITE,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    zIndex: 2,

    '& > img': {
      height: 'auto',
    },
  },

  teamSelectWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2,5),
  },
  teamSelectLabel: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: COLOR_WHITE,
  },
  teamSelect: {
    minWidth: '260px',
    marginLeft: theme.spacing(2),

    '& input': {
      boxSizing: 'border-box',
      height: '70px',
    },
  },

  [MEDIA.MOBILE]: {
    header: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      minHeight: 0,
      paddingBottom: '80px',
    },

    backButton: {
      top: 'auto',
      left: 'auto',
      bottom: 0,
      right: 0,
      padding: theme.spacing(2),
    },

    teamSelectWrapper: {
      width: '100%',
      padding: theme.spacing(2),
    },
    teamSelectLabel: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(2),
    },
    teamSelect: {
      width: '100%',
      marginLeft: 0,
      minWidth: '220px',

      '& label': {
        fontSize: theme.typography.pxToRem(14),
      },

      '& input': {
        height: '50px',
      },
    },

    logo: {
      left: theme.spacing(2),
    },
  },

  '@media (max-width: 480px)': {
    teamSelectWrapper: {
      display: 'block',
    },
    teamSelectLabel: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
  },

  [MEDIA.DESKTOP]: {
    header: {
      minHeight: '200px',
    },

    teamSelect: {
      minWidth: '340px',
    },

    logo: {
      left: '68px',
    },
  },
}), { name: TeamProfileHeader.name });

export default function TeamProfileHeader (props: TeamProfileHeaderProps) {
  const {
    loading,
    team,
    teams,
    user,
    onTeamSelect = () => {},
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [selectedTeam, setSelectedTeam] = useState<Team>();

  useEffect(() => {
    if (team) {
      setSelectedTeam(team);
    }
  }, [team]);

  return (
    <header
      className={classes.header}
      style={team && { backgroundColor: team.color || COLOR_TEXT }}
    >
      <Loader inProgress={loading} />

      <button
        className={classes.backButton}
        onClick={() => history.goBack()}
      >
        <BackIcon className={classes.backButtonIcon} />
        <span className={classes.backButtonText}>Back</span>
      </button>

      {((team && teams && !!teams.length) &&
        (user && (user.accessLevel.canTeamSelectNFL || user.accessLevel.canTeamSelectCollege))) && (
        <div className={classes.teamSelectWrapper}>
          <span className={classes.teamSelectLabel}>TeamSelect:</span>

          <TeamAutoComplete
            className={classes.teamSelect}
            teams={teams}
            selectedTeam={selectedTeam}
            onSelect={(team:Team | null) => {
              if (team) {
                setSelectedTeam(team);
                onTeamSelect(team);
              }
            }}
          />
        </div>
      )}

      {team && (
        <Avatar
          className={classes.logo}
          src={getTeamLogo(team)}
          alt={`${team.name} logo`}
        />
      )}
    </header>
  );
}
