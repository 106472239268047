import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '../icons/SearchIcon';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import Loader from '../atoms/Loader';
import ColorCode from '../atoms/ColorCode';
import {
  getPrimaryPosition,
  getPrimaryCollege,
  PlayerType,
  sortPlayersByLastName,
} from '../services/player';
import { inchesToFeetAndInches } from '../services/converter';
import {
  COLOR_BACKGROUND_WARM,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_RED,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { Color } from '../types/Color';
import Player from '../types/Player';
import MEDIA from '../styles/media';

interface AddDepthChartPlayersProps {
  className?: string;
  players: Player[];
  selectedPlayers: Player[];
  onSelect: (players:Player[]) => void;
  onAdd: () => void;
}

enum PlayerField {
  HEIGHT = 'height',
  WEIGHT = 'weight',
  POSITION = 'position',
  CLASS_YEAR = 'classYear'
}

const useStyles = makeStyles((theme: Theme) => ({
  addDepthChartPlayers: {
    width: '360px',
    margin: 0,
    padding: theme.spacing(1.5, 2),
    position: 'relative',
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    color: COLOR_TEXT,
    outline: 'none',
    cursor: 'pointer',
    appearance: 'none',
    textAlign: 'left',

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(90deg)',
    },

    '&:focus': {
      color: COLOR_BLUE,
      borderColor: COLOR_BLUE,
      boxShadow: 'none',
    },
  },
  open: {
    color: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    boxShadow: 'none',

    '&:before': {
      background: COLOR_BLUE,
    },
    '&:after': {
      background: COLOR_BLUE,
    },
  },

  text: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,
  },

  popover: {},
  paper: {
    borderRadius: 0,
  },
  popoverContent: {
    width: '300px',
    height: '292px',
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    overflowY: 'scroll',
  },

  searchWrapper: {
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  searchIconContainer: {
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  searchIcon: {
    color: COLOR_TEXT,
    width: '18px',
    height: '18px',
  },
  searchInputRoot: {
    width: '100%',
    fontFamily: FONT_PROXIMA_NOVA,
  },
  searchInputField: {
    width: '100%',
    height: '36px',
    padding: theme.spacing(1, 1, 1, 4.75),
    transition: theme.transitions.create('width'),
    background: COLOR_BACKGROUND_WARM,
    color: COLOR_TEXT,
    boxSizing: 'border-box',
    borderRadius: '4px',
  },

  closeButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    border: 0,
    appearance: 'none',
    background: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '5px',
    right: '4px',
    outline: 'none',

    '&:focus': {
      border: `1px solid ${COLOR_DARK_GRAY}`,
      borderRadius: '50%',
    },

    '&:hover': {
      '& $closeIcon': {
        color: COLOR_RED,
      }
    },
  },
  closeIcon: {
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',
  },

  players: {
    appearance: 'none',
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  player: {
    padding: '6px 8px 6px 8px',
    background: `${COLOR_WHITE} !important`,
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:hover': {
      background: COLOR_BACKGROUND_WARM,
    }
  },
  playerRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
  },

  duplicatePlayer: {
    marginLeft: theme.spacing(2),
  },

  cell: {
    marginLeft: theme.spacing(0.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  name: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  position: {
    width: '20px',
  },
  year: {
    width: '36px',
  },
  height: {
    width: '36px',
  },
  weight: {
    width: '52px',
  },

  actions: {
    width: '100%',
    padding: theme.spacing(1, 0),
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLOR_WHITE,
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    width: 'auto',
    padding: theme.spacing(1, 2),
  },

  [MEDIA.DESKTOP]: {
    text: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: AddDepthChartPlayers.name });

export default function AddDepthChartPlayers (props: AddDepthChartPlayersProps) {
  const {
    className,
    players,
    selectedPlayers,
    onSelect = () => {},
    onAdd = () => {},
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const open = !!anchorEl;

  function onOpen (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onClose () {
    setAnchorEl(null);
  }

  function onPlayerClick (newPlayer:Player) {
    return () => {
      const newSelectedPlayers = players
        .filter((player:Player) => (player.id === newPlayer.id) || (!!selectedPlayers.find(selectedPlayer => selectedPlayer.id === player.id)));
      const playerIsAlreadySelected = !!selectedPlayers.find(selectedPlayer => selectedPlayer.id === newPlayer.id);

      if (playerIsAlreadySelected) {
        // remove the selected player
        const index = newSelectedPlayers.findIndex((player:Player) => player.id === newPlayer.id);
        newSelectedPlayers.splice(index, 1);
      }

      onSelect(newSelectedPlayers);
    };
  }

  const filteredPlayers = players
    .filter((player:Player) => {
      return `${player.firstName} ${player.lastName}`.toLowerCase().includes(searchValue.toLowerCase())
        || `${player.lastName} ${player.firstName}`.toLowerCase().includes(searchValue.toLowerCase());
    })
    .sort(sortPlayersByLastName);

  function getPlayerData (player:Player, field:string) {
    const isCollegeDataAvailable = getPrimaryCollege(player);

    switch (field) {
      case PlayerField.HEIGHT :
        return inchesToFeetAndInches((isCollegeDataAvailable && player?.playerColleges[0]?.height) || player?.highSchools[0]?.height);
      case PlayerField.WEIGHT:
        return `${(isCollegeDataAvailable && player?.playerColleges[0]?.weight) || player?.highSchools[0]?.weight} lbs.`;
      case PlayerField.POSITION:
        return (isCollegeDataAvailable && getPrimaryPosition(player, PlayerType.COLLEGE)) || getPrimaryPosition(player, PlayerType.HIGH_SCHOOL);
      case PlayerField.CLASS_YEAR:
        return (isCollegeDataAvailable && player?.playerColleges[0]?.recruitingClass) || player?.highSchools[0]?.graduatingClass;
    }
  }

  return (
    <button
      className={clsx(
        classes.addDepthChartPlayers,
        open && classes.open,
        className,
      )}
      onClick={!open ? onOpen : undefined}
      tabIndex={0}
    >
      <span className={classes.text}>Add Players</span>

      <Popover
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <div
          className={classes.popoverContent}
          style={{ width: `${anchorEl ? `${anchorEl.offsetWidth}px` : '300px' }` }}
        >
          <div className={classes.searchWrapper}>
            <div className={classes.search}>
              <div className={classes.searchIconContainer}>
                <SearchIcon className={classes.searchIcon} />
              </div>

              <InputBase
                classes={{
                  root: classes.searchInputRoot,
                  input: classes.searchInputField,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue}
                placeholder='Search Players'
                onChange={(event:any) => setSearchValue(event.target.value)}
              />
            </div>
          </div>

          <ul className={classes.players}>
            <Loader inProgress={!players || !players.length} />

            {filteredPlayers.map((player:Player, index) => (
              <MenuItem
                key={index}
                classes={{ root: classes.player }}
                value={player.id}
                onClick={onPlayerClick(player)}
              >
                <div className={classes.playerRow}>
                  <Checkbox
                    checked={!!selectedPlayers.find((selectedPlayer:Player) => selectedPlayer.id === player.id)}
                  />

                  {player.duplicated && (
                    <ColorCode
                      className={classes.duplicatePlayer}
                      color={Color.YELLOW}
                      tooltipText='Already Added'
                    />
                  )}

                  <span className={clsx(classes.cell, classes.name)}>
                    {player.firstName} {player.lastName}
                  </span>
                  <span className={clsx(classes.cell, classes.position)}>{getPlayerData(player, PlayerField.POSITION)}</span>
                  <span className={clsx(classes.cell, classes.year)}>{getPlayerData(player, PlayerField.CLASS_YEAR)}</span>
                  <span className={clsx(classes.cell, classes.height)}>{getPlayerData(player, PlayerField.HEIGHT)}</span>
                  <span className={clsx(classes.cell, classes.weight)}>{getPlayerData(player, PlayerField.WEIGHT)}</span>
                </div>
              </MenuItem>
            ))}
          </ul>

          <div className={classes.actions}>
            <Button
              className={classes.action}
              onClick={() => {
                onClose();
                onAdd();
              }}
            >Add Players</Button>
          </div>
        </div>
      </Popover>
    </button>
  );
}
