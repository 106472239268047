import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHeadCell from '../atoms/TableHeadCell';
import Loader from '../atoms/Loader';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import Toast, { ToastType } from '../atoms/Toast';
import NotAvailable from '../atoms/NotAvailable';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import gql from '../services/gql';
import { datetimeToDate } from '../services/converter';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_BLUE,
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import Program from '../types/Program';
import Team from '../types/Team';
import Company from '../types/Company';
import AccessLevel from '../types/AccessLevel';
import { Order } from '../types/Order';

interface ProgramsTableProps {
  className?: string;
  programs: Program[];
  setPrograms: (programs:Program[]) => void;
  currentPage?: number;
  limit?: number;
  accessLevels: AccessLevel[];
  selectedItems: Program[];
  selectedColumns: string[];
  setCurrentPage: (page: number) => void;
  setSelectedItems: (items: any) => void;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
}

export enum ManagedProgramColumn {
  NAME = 'name',
  ACCESS_LEVEL = 'accessLevel',
  COLLEGE_TEAM = 'collegeTeam',
  NFL_TEAM = 'nflTeam',
  COMPANY = 'company',
  START_DATE = 'startDate',
  EXPIRATION_DATE = 'expirationDate',
  API_KEY = 'apiKey',
  INVOICE = 'invoice',
  TRANSFER_PORTAL = 'hasTransferPortal',
  IS_PROGRAM_ENABLED = 'isEnabled',
  IS_EMAIL_DISABLED = 'isEmailDisabled',
}

export const PROGRAM_ALL_COLUMNS = [
  ManagedProgramColumn.NAME,
  ManagedProgramColumn.ACCESS_LEVEL,
  ManagedProgramColumn.COLLEGE_TEAM,
  ManagedProgramColumn.NFL_TEAM,
  ManagedProgramColumn.COMPANY,
  ManagedProgramColumn.START_DATE,
  ManagedProgramColumn.EXPIRATION_DATE,
  ManagedProgramColumn.INVOICE,
  ManagedProgramColumn.TRANSFER_PORTAL,
  ManagedProgramColumn.IS_PROGRAM_ENABLED,
  ManagedProgramColumn.IS_EMAIL_DISABLED,
];

export const PROGRAM_COLUMN_TITLE = {
  [ManagedProgramColumn.NAME]: 'Name',
  [ManagedProgramColumn.ACCESS_LEVEL]: <>Access&nbsp;Level</>,
  [ManagedProgramColumn.COLLEGE_TEAM]: <>College&nbsp;Team</>,
  [ManagedProgramColumn.NFL_TEAM]: <>NFL&nbsp;Team</>,
  [ManagedProgramColumn.COMPANY]: 'Company',
  [ManagedProgramColumn.START_DATE]: <>Start&nbsp;Date</>,
  [ManagedProgramColumn.EXPIRATION_DATE]: <>Expiration&nbsp;Date</>,
  [ManagedProgramColumn.API_KEY]: <>API&nbsp;Key</>,
  [ManagedProgramColumn.INVOICE]: <>Invoice&nbsp;#</>,
  [ManagedProgramColumn.TRANSFER_PORTAL]: <>Transfer&nbsp;Portal</>,
  [ManagedProgramColumn.IS_PROGRAM_ENABLED]: 'Enabled',
  [ManagedProgramColumn.IS_EMAIL_DISABLED]: 'Email',
};

const useStyles = makeStyles(theme => ({
  header: {
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    position: 'relative',
    margin: 0,
    padding: theme.spacing(3, 2),
    lineHeight: 1,
    ...theme.typography.h2,
  },

  programsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },
  tableHeadCell: {
    borderLeft: 'none',
    color: COLOR_DARK_GRAY,
    padding: theme.spacing(1.25),

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  tableRow: {
    height: '50px',
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.25),
    whiteSpace: 'nowrap',
  },
  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },
  nameCell: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  rankCell: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },

  paginationWrapper: {
    minHeight: '24px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 0),
    position: 'relative',
    boxSizing: 'content-box',
  },
  paginationText: {
    position: 'absolute',
    left: theme.spacing(0),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
}), { name: ProgramsTable.name });

function ProgramsTable(props: ProgramsTableProps) {
  const {
    className,
    programs,
    setPrograms,
    currentPage = 1,
    limit = 10,
    accessLevels,
    selectedItems,
    selectedColumns,
    setSelectedItems,
    setCurrentPage,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    fetchCollegeTeams,
    fetchNFLTeams,
  } = props;

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order | undefined>();
  const [companies, setCompanies] = useState<Company[]>([]);

  const [sortedByColumn, setSortedByColumn] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const classes = useStyles();

  useEffect(() => {
    fetchCollegeTeams();
    fetchNFLTeams();
    fetchCompanies();
  }, []);

  function fetchCompanies () {
    setPageLoading(true);

    gql(`
      companies {
        id
        name
      }
    `)
      .then((data: any) => data.companies as Company[])
      .then((companies: Company[]) => {
        if (companies && companies.length) {
          setCompanies(companies);
        } else {
          showToast('Failed to fetch Companies.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Failed to fetch Companies.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function onSortByColumn (sort:(programs: Program[], order:Order) => Program[]) {
    return (columnName: string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function onSort (columnName:string, newOrder:Order, sort:(programs: Program[], order: Order) => Program[]) {
    setSortedByColumn(columnName);
    setPrograms(sort(programs, newOrder));
  }

  function onCheckBoxChange (checkedItem: Program) {
    return (value: boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }
        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function renderRow (item: Program) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id);

    return (
      <TableRow
        key={item.id}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(item)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          (tableColumn && <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.className,
              tableColumn.value === ManagedProgramColumn.NAME && classes.nameCell,
            )}
          >
            {tableColumn.renderContent(item)}
          </TableCell>)
        ))}
      </TableRow>
    );
  }

  function sortPrograms (
    programs: Program[],
    order: Order,
    getValue: (program:Program) => string | number,
  ) {
    return programs.sort((first:Program, second:Program) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const tableColumns = selectedColumns.map((column: string) => {
    switch (column) {
      case ManagedProgramColumn.NAME:
        return {
          value: ManagedProgramColumn.NAME,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.NAME],
          sortable: true,
          renderContent: (program: Program) => (
            <Link
              className={classes.link}
              to={`/manage/program/edit/${program.id}`}
            >
              {`${program.name}`}
            </Link>
          ),
          sort: (programs: Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program: Program) => `${program.name}`
            );
          },
        };
      case ManagedProgramColumn.ACCESS_LEVEL:
        return {
          value: ManagedProgramColumn.ACCESS_LEVEL,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.ACCESS_LEVEL],
          sortable: true,
          renderContent: (program:Program) => {
            if (accessLevels && program.accessLevelId) {
              const accessLevel = accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === program.accessLevelId);
              return accessLevel ? accessLevel.name : <NotAvailable />;
            } else return <NotAvailable />;
          },
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => {
                const accessLevel = accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === program.accessLevelId);
                return accessLevel ? accessLevel.name : '';
              }
            );
          },
        };
      case ManagedProgramColumn.COLLEGE_TEAM:
        return {
          value: ManagedProgramColumn.COLLEGE_TEAM,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.COLLEGE_TEAM],
          sortable: true,
          renderContent: (program:Program) => {
            if (collegeTeams.length && program.collegeId) {
              const collegeTeam = collegeTeams.find((team:Team) => team.id === program.collegeId);
              return collegeTeam ? collegeTeam.name : <NotAvailable />;
            } else return <NotAvailable />;
          },
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => {
                const collegeTeam = collegeTeams.find((team:Team) => team.id === program.collegeId);
                return collegeTeam ? collegeTeam.name : '';
              }
            );
          },
        };
      case ManagedProgramColumn.NFL_TEAM:
        return {
          value: ManagedProgramColumn.NFL_TEAM,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.NFL_TEAM],
          sortable: true,
          renderContent: (program:Program) => {
            if (nflTeams.length && program.nflTeamId) {
              const nflTeam = nflTeams.find((team:Team) => team.id === program.nflTeamId);
              return nflTeam ? nflTeam.name : <NotAvailable />;
            } else return <NotAvailable />;
          },
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => {
                const nflTeam = nflTeams.find((team:Team) => team.id === program.nflTeamId);
                return nflTeam ? nflTeam.name : '';
              }
            );
          },
        };
      case ManagedProgramColumn.COMPANY:
        return {
          value: ManagedProgramColumn.COMPANY,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.COMPANY],
          sortable: true,
          renderContent: (program:Program) => {
            if (companies.length && program.companyId) {
              const company = companies.find((company:Company) => company.id === program.companyId);
              return company ? company.name : <NotAvailable />;
            } else return <NotAvailable />;
          },
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.name
            );
          },
        };
      case ManagedProgramColumn.INVOICE:
        return {
          value: ManagedProgramColumn.INVOICE,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.INVOICE],
          sortable: true,
          renderContent: (program:Program) => program.invoice || <NotAvailable />,
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.invoice
            );
          },
        };
      case ManagedProgramColumn.TRANSFER_PORTAL:
        return {
          value: ManagedProgramColumn.TRANSFER_PORTAL,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.TRANSFER_PORTAL],
          sortable: true,
          renderContent: (program:Program) => program.hasTransferPortal ? 'Yes' : 'No',
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.hasTransferPortal ? 'Yes' : 'No'
            );
          },
        };
      case ManagedProgramColumn.START_DATE:
        return {
          value: ManagedProgramColumn.START_DATE,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.START_DATE],
          sortable: true,
          renderContent: (program:Program) => program.startDate ? datetimeToDate(program.startDate) : datetimeToDate(program.createdAt),
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.startDate ? +(new Date(program.startDate)) : +(new Date(program.createdAt))
            );
          },
        };
      case ManagedProgramColumn.EXPIRATION_DATE:
        return {
          value: ManagedProgramColumn.EXPIRATION_DATE,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.EXPIRATION_DATE],
          sortable: true,
          renderContent: (program:Program) => program.expirationDate ? `${datetimeToDate(program.expirationDate)}` : <NotAvailable />,
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => +(new Date(program.expirationDate))
            );
          },
        };
      case ManagedProgramColumn.IS_PROGRAM_ENABLED:
        return {
          value: ManagedProgramColumn.IS_PROGRAM_ENABLED,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.IS_PROGRAM_ENABLED],
          sortable: true,
          renderContent: (program:Program) => program.isEnabled ? 'Yes' : 'No',
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.isEnabled ? 'Yes' : 'No'
            );
          },
        };
      case ManagedProgramColumn.IS_EMAIL_DISABLED:
        return {
          value: ManagedProgramColumn.IS_EMAIL_DISABLED,
          title: PROGRAM_COLUMN_TITLE[ManagedProgramColumn.IS_EMAIL_DISABLED],
          sortable: true,
          renderContent: (program:Program) => program.isEmailDisabled ? 'Disabled' : 'Enabled',
          sort: (programs:Program[], order:Order) => {
            return sortPrograms(
              programs,
              order,
              (program:Program) => program.isEmailDisabled ? 'Disabled' : 'Enabled',
            );
          },
        };
    }
  });
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = programs?.length < limit ? programs.length : (limit * currentPage);
  const totalPages = Math.ceil(programs.length / limit);

  const loading = pageLoading || collegeTeamsLoading || nflTeamsLoading;

  return (
    <div className={className}>
      <Loader inProgress={loading} />

      <TableWithDoubleScrollBars tableClassName={classes.programsTable}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableHeadCell
              key='check-box-cell'
              className={classes.checkBoxCell}
            >
              <Checkbox
                checked={selectedItems.length === programs.length}
                onChange={() => selectedItems.length === programs.length
                  ? setSelectedItems([])
                  : setSelectedItems([...programs])
                }
              />
            </TableHeadCell>

            {tableColumns.map((tableColumn: any) => (
              (tableColumn && (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable ? onSortByColumn(tableColumn.sort || ((programs: Program[]) => programs)) : undefined}
                  className={clsx(tableColumn.className, classes.tableHeadCell)}
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {programs
            .slice(itemsFrom, itemsTo)
            .map(renderRow)
          }
        </TableBody>
      </TableWithDoubleScrollBars>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {programs.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramsTable);
