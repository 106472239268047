import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import { RanksType } from '../atoms/Ranks';
import Loader from '../atoms/Loader';
import { COLOR_LIGHT_ORANGE, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import gql from '../services/gql';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import { FormatType, getEventFormatType, getEventName } from '../services/event-names-and-formats';
import { State } from '../redux/reducers';
import Position from '../types/Position';
import StatsPlayer from '../types/StatsPlayer';
import Team from '../types/Team';

interface RanksTooltipProps {
  type?: RanksType;
  playerId?: number;
  teamId?: number;
  position?: Position;
  combineId?: number | null;
  combineCategory?: 'hs' | 'nfl' | null;
  comparisonGroup: RanksComparisonGroup;
  comparisonEvent?: 'pai' | 'combine' | 'proCombine' | string;
  rank?: number;
  children: any;
  selectedCollegeTeam: Team | undefined;
}

export enum RanksComparisonGroup {
  COLLEGE_TEAM = 'collegeTeam',
  COLLEGE_CONFERENCE = 'collegeConference',
  NFL_TEAM = 'nflTeam',
  NFL_UDFA = 'nflUDFA',
  NFL_DRAFT = 'nflDraft',
}

const useStyles = makeStyles((theme: Theme) => ({
  ranksTooltip: {},

  table: {
    marginTop: '6px',
    marginBottom: '6px',
    borderCollapse: 'collapse',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  tableRow: {
    color: COLOR_WHITE,
  },
  tableCell: {
    textAlign: 'center',
    padding: '3px 4px',
    color: 'inherit',

    '&:first-of-type': {
      paddingRight: theme.spacing(1.5),
    },

    '&:last-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
  },

  nameLink: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  emptySpace: {
    height: 100,
  },

  comparisonEvent: {
    textTransform: 'capitalize',
  },

  currentValueRow: {
    color: COLOR_LIGHT_ORANGE,
  },
}), { name: RanksTooltip.name });

function RanksTooltip (props: RanksTooltipProps) {
  const {
    type = RanksType.PAI,
    playerId,
    teamId,
    position,
    comparisonGroup,
    combineId = null,
    combineCategory = null,
    comparisonEvent,
    rank,
    selectedCollegeTeam,
    children,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [players, setPlayers] = useState<StatsPlayer[]>([]);

  useEffect(() => {
    if (open && !loading && playerId && teamId && position && rank && comparisonEvent) {
      if (type === RanksType.PAI) {
        fetchPAIPlayers();
      } else if (type === RanksType.COMBINE) {
        fetchCombinePlayers();
      } else if (type === RanksType.PPI) {
        fetchPPIPlayers();
      } else if (type === RanksType.PRO_COMBINE) {
        fetchProCombinePlayers();
      }
    }
  }, [open, type, playerId, teamId, position, comparisonGroup, combineId, combineCategory, comparisonEvent, rank]);

  function fetchPAIPlayers () {
    if (!loading) setLoading(true);

    gql(`
      paiStatsPlayerList (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}",
        comparisonGroup: "${comparisonGroup}",
        event: "${comparisonEvent}"
      ) {
        id
        slug
        firstName
        lastName
        teamName
        performance
      }
    `)
      .then((data:any) => get(data, 'paiStatsPlayerList') as StatsPlayer[])
      .then((players:StatsPlayer[]) => {
        if (players && players.length) {
          const selectedPlayerIndex = players
            .findIndex((player:StatsPlayer) => playerId === player.id);
          const playersWithOrder = [
            ...players
              .slice(0, selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) - selectedPlayerIndex + index,
              })),
            ...players
              .slice(selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) + index,
              })),
          ];

          setPlayers(playersWithOrder);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchCombinePlayers () {
    if (!loading) setLoading(true);

    gql(`
      combineStatsPlayerList (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}",
        playerCombineId: ${combineId},
        comparisonGroup: "${comparisonGroup}",
        combineType: "${combineCategory}",
        event: "${comparisonEvent}"
      ) {
        id
        slug
        firstName
        lastName
        teamName
        performance
      }
    `)
      .then((data:any) => get(data, 'combineStatsPlayerList') as StatsPlayer[])
      .then((players:StatsPlayer[]) => {
        if (players && players.length) {
          const selectedPlayerIndex = players
            .findIndex((player:StatsPlayer) => playerId === player.id);
          const playersWithOrder = [
            ...players
              .slice(0, selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) - selectedPlayerIndex + index,
              })),
            ...players
              .slice(selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) + index,
              })),
          ];

          setPlayers(playersWithOrder);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchPPIPlayers () {
    if (!loading) setLoading(true);

    gql(`
      ppiStatsPlayerList (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}",
        comparisonGroup: "${comparisonGroup}",
        event: "${comparisonEvent}"
      ) {
        id
        slug
        firstName
        lastName
        teamName
        performance
      }
    `)
      .then((data:any) => data?.ppiStatsPlayerList as StatsPlayer[])
      .then((players:StatsPlayer[]) => {
        if (!!players?.length) {
          const selectedPlayerIndex = players.findIndex((player:StatsPlayer) => playerId === player.id);
          const playersWithOrder = [
            ...players
              .slice(0, selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) - selectedPlayerIndex + index,
              })),
            ...players
              .slice(selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) + index,
              })),
          ];

          setPlayers(playersWithOrder);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchProCombinePlayers () {
    if (!loading) setLoading(true);

    gql(`
      proCombineStatsPlayerList (
        id: ${playerId},
        compareToTeamId: ${teamId},
        positionCode: "${position}",
        playerNFLCombineId: ${combineId},
        comparisonGroup: "${comparisonGroup}",
        event: "${comparisonEvent}"
      ) {
        id
        slug
        firstName
        lastName
        teamName
        performance
      }
    `)
      .then((data:any) => get(data, 'proCombineStatsPlayerList') as StatsPlayer[])
      .then((players:StatsPlayer[]) => {
        if (players && players.length) {
          const selectedPlayerIndex = players
            .findIndex((player:StatsPlayer) => playerId === player.id);
          const playersWithOrder = [
            ...players
              .slice(0, selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) - selectedPlayerIndex + index,
              })),
            ...players
              .slice(selectedPlayerIndex)
              .map((player, index) => ({
                ...player,
                order: (rank || 0) + index,
              })),
          ];

          setPlayers(playersWithOrder);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function formatPerformance (performance:number, formatType:FormatType):string {
    if (formatType === FormatType.FEET_AND_INCHES) {
      return inchesToFeetAndInches(performance);
    } else if (formatType === FormatType.INCHES) {
      return `${Number(performance).toFixed(2)}"`;
    } else if (formatType === FormatType.MINUTES_SECONDS_MS) {
      return secondsToMinutesSecondsMs(performance);
    }

    return Number(performance).toFixed(2);
  }

  return (
    <Tooltip
      className={classes.ranksTooltip}
      open={open}
      title={(
        <>
          <Loader inProgress={loading} />

          <table className={classes.table}>
            <thead>
            <tr className={classes.tableRow}>
              <th className={classes.tableCell}>Rank</th>
              <th className={classes.tableCell}>Name</th>
              <th className={classes.tableCell}>College</th>
              <th className={clsx(classes.tableCell, classes.comparisonEvent)}>
                {getEventName(comparisonEvent)}
              </th>
            </tr>
            </thead>

            <tbody>
              {players.map((player:StatsPlayer) => (
                <tr
                  key={player.id}
                  className={clsx(
                    classes.tableRow,
                    playerId === player.id && classes.currentValueRow
                  )}
                >
                  <td className={classes.tableCell}>{(player as any).order}</td>
                  <td className={classes.tableCell}>
                    <Link
                      className={classes.nameLink}
                      to={`/player/${player.slug}?`}
                      target='_blank'
                    >
                      {player.firstName} {player.lastName}
                    </Link>
                  </td>
                  <td className={classes.tableCell}>{comparisonGroup === RanksComparisonGroup.COLLEGE_TEAM ? selectedCollegeTeam?.name : player.teamName}</td>
                  <td className={classes.tableCell}>
                    {formatPerformance(player.performance, getEventFormatType(comparisonEvent))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {(!players.length) && (
            <div className={classes.emptySpace} />
          )}
        </>
      )}
      interactive
      placement='bottom'
      onClose={() => setOpen(false)}
    >
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
    </Tooltip>
  );
}

const mapStateToProps = (state:State) => {
  return {
    selectedCollegeTeam: state.ui.selectedCollegeTeam,
  };
};

export default connect(
  mapStateToProps,
)(RanksTooltip);
