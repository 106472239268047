import React from 'react';
import Icon from '../atoms/Icon';

function SearchPlayersIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M17 22.5c0 3 2.5 5.5 5.5 5.5 1.2 0 2.2-.4 3.1-1l3 3 1.4-1.4-3-3c.6-.9 1-2 1-3.1 0-3-2.5-5.5-5.5-5.5S17 19.5 17 22.5zm9 0c0 1.9-1.6 3.5-3.5 3.5S19 24.4 19 22.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5zM16.781 17.601l.027.023a7.523 7.523 0 011.51-1.35 9.947 9.947 0 00-2.403-1.474A6.999 6.999 0 0019 9c0-3.86-3.14-7-7-7S5 5.14 5 9c0 2.41 1.224 4.54 3.083 5.799C4.51 16.326 2 19.875 2 24h2a7.992 7.992 0 013.201-6.397A6.002 6.002 0 0012 20a5.99 5.99 0 004.781-2.399zM12 18a4.005 4.005 0 01-3.041-1.4A7.955 7.955 0 0112 16c1.075 0 2.1.216 3.037.603A4 4 0 0112 18zm0-4c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SearchPlayersIcon);
