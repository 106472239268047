import React from 'react';
import Icon from '../atoms/Icon';

function DepthChartIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(5, 6)'>
          <polygon
            stroke='currentColor'
            strokeWidth='2'
            points='0 0 20 0 20 20 0 20'
          />
          <polygon
            fill='currentColor'
            fillRule='nonzero'
            points='18 11 15 8 12 11'
          />
          <path
            d='M10,15 L13,15 C14.105,15 15,14.105 15,13 L15,10'
            stroke='currentColor'
            strokeWidth='2'
          />
          <polygon
            fill='currentColor'
            fillRule='nonzero'
            points='11 12 8 15 11 18'
          />
          <polygon
            fill='currentColor'
            fillRule='nonzero'
            transform='translate(10.5, 6) rotate(-90) translate(-10.5, -6)'
            points='9.5 -4.5 11.5 -4.5 11.5 16.5 9.5 16.5'
          />
          <polygon
            fill='currentColor'
            fillRule='nonzero'
            points='5 0 7 0 7 20 5 20'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(DepthChartIcon);
