import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import { getQueryParameter } from '../services/query-parameters';
import gql from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

const useStyles = makeStyles(theme => ({
  unsubscribe: {
    padding: theme.spacing(2, 4, 4),
    position: 'relative',
  },
  heading: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
  },
  textContent: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  bold: {
    fontWeight: 700,
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  action: {
    width: 'auto'
  }
}), { name: Unsubscribe.name });

export default function Unsubscribe () {
  const classes = useStyles();

  const [displaySuccessMessage, setDisplaySuccessMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  const email = getQueryParameter('email');

  function onUnsubscribe() {
    setLoading(true);

    gql(`
      mutation {
        unsubscribeEmail(email: "${email}")
      }`
    )
      .then((data:any) => data.unsubscribeEmail as boolean)
      .then((success:boolean) => {
        if (success) {
          setDisplaySuccessMessage(true);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  return (
    <>
      <div className={classes.unsubscribe}>
        <Loader inProgress={loading} />

        <div className={classes.heading}>
          {displaySuccessMessage
            ? 'Success! Unsubscribed'
            : 'Unsubscribe'
          }
        </div>

        {!displaySuccessMessage && (
          <>
            <p className={classes.textContent}>
              Your email address is <span className={classes.bold}>{email}</span>
            </p>
            <p className={classes.textContent}>
              You will not receive future email alerts from <span className={classes.bold}>no-reply@trackingfootball.com</span>
            </p>

            <div className={classes.actions}>
              <Button
                className={classes.action}
                primary
                disabled={loading}
                onClick={onUnsubscribe}
              >
                Unsubscribe
              </Button>
            </div>
          </>
        )}

        {displaySuccessMessage && (
          <p className={classes.textContent}>
            <span className={classes.bold}>{email}</span> will not receive future email alerts from <span className={classes.bold}>no-reply@trackingfootball.com</span>
          </p>
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
