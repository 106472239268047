import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import RankCard from '../molecules/RankCard';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BORDER,
  COLOR_LIGHT_ORANGE,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';

interface UserRankButtonWithPopoverProps {
  className?: string;
  user?: User;
}

const useStyles = makeStyles((theme:Theme) => ({
  userRankButtonWithPopover: {
    width: '34px',
    height: '34px',
    appearance: 'none',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: (props:UserRankButtonWithPopoverProps) => props?.user?.rank && String(props?.user?.rank).length > 1
      ? theme.typography.pxToRem(16)
      : theme.typography.pxToRem(20),
    fontWeight: 700,
    color: COLOR_ORANGE,
    lineHeight: 1,
    border: `1px solid ${COLOR_BORDER}`,
    borderRadius: '50%',
    background: COLOR_WHITE,
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_LIGHT_ORANGE,
    },
  },

  userRankPopover: {},
  paper: {
    overflow: 'visible',
    borderRadius: 0,
    border: 0,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },

  rankCard: {
    width: 280,
    position: 'relative',

    '&:after': {
      content: '""',
      width: 16,
      height: 16,
      position: 'absolute',
      top: -8,
      left: 97,
      background: COLOR_WHITE,
      zIndex: 1,
      transform: 'rotate(45deg)',
      border: `1px solid ${COLOR_BORDER}`,
      borderRight: 0,
      borderBottom: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    rankCard: {
      '&:after': {
        left: 53,
      },
    },
  },

  '@media (min-width:1801px)': {
    rankCard: {
      '&:after': {
        left: 97,
      },
    },
  },
}), { name: UserRankButtonWithPopover.name });

export default function UserRankButtonWithPopover (props:UserRankButtonWithPopoverProps) {
  const {
    className,
    user,
  } = props;
  const classes = useStyles(props);
  const [userRankButtonWithPopoverAnchorEl, setUserRankButtonWithPopoverAnchorEl] = useState<HTMLElement|null>(null);

  function onClick (event:any) {
    setUserRankButtonWithPopoverAnchorEl(event.currentTarget);
  }

  function onClose () {
    setUserRankButtonWithPopoverAnchorEl(null)
  }

  if (!user?.rank) return null;

  return (
    <>
      <button
        className={clsx(classes.userRankButtonWithPopover, className)}
        onClick={onClick}
      >
        {user.rank}
      </button>

      <Popover
        className={classes.userRankPopover}
        classes={{ paper: classes.paper }}
        open={Boolean(userRankButtonWithPopoverAnchorEl)}
        anchorEl={userRankButtonWithPopoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -12,
          horizontal: 'center',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <RankCard
          className={classes.rankCard}
          user={user}
        />
      </Popover>
    </>
  );
}
