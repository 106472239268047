import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import RemoveIcon from '../icons/RemoveIcon';
import Action from '../atoms/Action';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Download from '../atoms/Download';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import Tooltip from '../atoms/Tooltip';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import gql from '../services/gql';
import { downloadAndSaveXLSReport } from '../services/export-xls-report';
import { datetimeToDate, formatNumbersToCommaSeparatedString } from '../services/converter';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_BACKGROUND_LIGHT,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_BORDER,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import PlayerExport from '../types/Export';
import { Order } from '../types/Order';

interface ExportsTableProps {
  className?: string;
}

const COLUMN = {
  NAME: 'name',
  PLAYER_COUNT: 'players',
  DATE_CREATED: 'createdAt',
  DATE_EXPIRES: 'expirationDate',
};

const COLUMNS = [
  COLUMN.NAME,
  COLUMN.PLAYER_COUNT,
  COLUMN.DATE_CREATED,
  COLUMN.DATE_EXPIRES,
];

const COLUMN_TITLE = {
  [COLUMN.NAME]: 'Name',
  [COLUMN.PLAYER_COUNT]: 'Players',
  [COLUMN.DATE_CREATED]: 'Date Created',
  [COLUMN.DATE_EXPIRES]: 'Expires',
};

const useStyles = makeStyles(theme => ({
  exportsTable: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  tableWrapper: {
    overflow: 'auto',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },

  titleRow: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  titleTextWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 4, 0, 0),
  },
  exportsRemaining: {
    color: COLOR_ORANGE,
    cursor: 'help',
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(3),
    textTransform: 'capitalize',
  },
  headerHint: {
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'italic',
    color: COLOR_TEXT,
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    flexShrink: 0,
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    padding: theme.spacing(0,0,0,2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-of-type': {
      paddingLeft: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  table: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },
  downloadFileCell: {
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },
  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  playerCount: {
    textAlign: 'center',
  },

  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  fileLink: {
    cursor: 'pointer',
    color: COLOR_BLUE,
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(16),
    padding: 0,

    '&:focus': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'none',
      outline: 'none',
    }
  },
  downloadIcon: {
    height: '32px',
    width: '32px',
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
}), { name: ExportsTable.name });

export default function ExportsTable (props: ExportsTableProps) {
  const {
    className
  } = props;
  const classes = useStyles();

  const [remainingExports, setRemainingExports] = useState<any>('');
  const [exportsData, setExportsData] = useState<PlayerExport[]>([]);
  const [selectedItems, setSelectedItems] = useState<PlayerExport[]>([]);

  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.EXPORTS_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.EXPORTS_TABLE) || {}).limit || 10);
  const [order, setOrder] = useState<Order | undefined>(Order.desc);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.EXPORTS_TABLE) || {}).currentPage || 1);
  const [sortedByColumn, setSortedByColumn] = useState<string>(COLUMN.DATE_EXPIRES);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      fetchExportsRemaining(),
      fetchPlayersExports(),
    ])
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    clientStorage.save(StorageKey.EXPORTS_TABLE, {currentPage, limit});
  }, [currentPage, limit]);

  useEffect(() => {
    if (prevLimit !== limit && !!exportsData.length) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [exportsData.length, limit]);

  function fetchExportsRemaining () {
    return gql(`remainingExports`)
      .then((data:any) => data.remainingExports as any)
      .then((remainingExports:any) => {
        setRemainingExports(formatNumbersToCommaSeparatedString(remainingExports));
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch remaining exports count', ToastType.ERROR);
      });
  }

  function fetchPlayersExports () {
    return gql(`
      playersExports {
        id
        playerIds
        playerCount
        fileUrl
        fileName
        expirationDate
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.playersExports as PlayerExport[])
      .then((playersExports:PlayerExport[]) => setExportsData(playersExports))
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch the list of exports', ToastType.ERROR);
      });
  }

  function removePlayerExports() {
    setLoading(true);

    gql(`
      mutation {
        removePlayerExports(ids: [${selectedItems.map(item => item.id)}])
      }
    `)
      .then((data: any) => data.removePlayerExports as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Removed', ToastType.SUCCESS);
          return fetchPlayersExports();
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onSort (columnName:string, newOrder:Order, sort:(exports:PlayerExport[], order:Order) => PlayerExport[]) {
    setSortedByColumn(columnName);
    setExportsData(sort(exportsData, newOrder));
  }

  function onSortByColumn (sort:(exports:PlayerExport[], order:Order) => PlayerExport[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function onCheckBoxChange (checkedItem:PlayerExport) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function sortPlayers (
    players:PlayerExport[],
    order:Order,
    getValue: (players:PlayerExport) => string | number,
  ) {
    return players.sort((first:PlayerExport, second:PlayerExport) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function renderTableRow (playerExport: PlayerExport) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === playerExport.id);

    return (
      <TableRow
        key={playerExport.id}
        className={classes.tableRow}
      >
        <TableCell
          key='check-box-cell'
          className={clsx(classes.tableCell, classes.checkBoxCell)}
        >
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(playerExport)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === COLUMN.PLAYER_COUNT && classes.playerCount,
            )}
          >
            {tableColumn.renderContent(playerExport)}
          </TableCell>
        ))}

        <TableCell
          key='download-file-cell'
          className={clsx(classes.tableCell, classes.downloadFileCell)}
        >
          <Download
            className={clsx(classes.downloadIcon, classes.fileLink)}
            label=''
            onClick={event => {
              event.preventDefault();
              downloadAndSaveXLSReport(playerExport.fileName, undefined, playerExport.fileUrl);
            }}
          />
        </TableCell>
      </TableRow>
    )
  }

  const tableColumns = COLUMNS.map((column:string) => {
    switch (column) {
      case COLUMN.NAME:
        return {
          value: COLUMN.NAME,
          title: COLUMN_TITLE[COLUMN.NAME],
          sortable: true,
          renderContent: (playerExport:PlayerExport) => (
            <a
              className={classes.fileLink}
              href={playerExport.fileUrl}
              onClick={event => {
                event.preventDefault();
                downloadAndSaveXLSReport(playerExport.fileName, undefined, playerExport.fileUrl);
              }}
            >
              {playerExport.fileName}
            </a>
          ),
          sort: (playerExports:PlayerExport[], order:Order) => {
            return sortPlayers(
              playerExports,
              order,
              (player:PlayerExport) => player.fileName
            );
          },
        };
      case COLUMN.PLAYER_COUNT:
        return {
          value: COLUMN.PLAYER_COUNT,
          title: COLUMN_TITLE[COLUMN.PLAYER_COUNT],
          sortable: true,
          renderContent: (playerExport:PlayerExport) => playerExport.playerCount ? playerExport.playerCount : <NotAvailable />,
          sort: (playerExports:PlayerExport[], order:Order) => {
            return sortPlayers(
              playerExports,
              order,
              (player:PlayerExport) => player.playerCount
            );
          },
        };
      case COLUMN.DATE_CREATED:
        return {
          value: COLUMN.DATE_CREATED,
          title: COLUMN_TITLE[COLUMN.DATE_CREATED],
          sortable: true,
          renderContent: (playerExport:PlayerExport) => playerExport.createdAt ? datetimeToDate(playerExport.createdAt) : <NotAvailable />,
          sort: (playerExports:PlayerExport[], order:Order) => {
            return sortPlayers(
              playerExports,
              order,
              (player:PlayerExport) => player.createdAt
            );
          },
        };
      case COLUMN.DATE_EXPIRES:
        return {
          value: COLUMN.DATE_EXPIRES,
          title: COLUMN_TITLE[COLUMN.DATE_EXPIRES],
          sortable: true,
          renderContent: (playerExport:PlayerExport) => playerExport.expirationDate ? datetimeToDate(playerExport.expirationDate) : <NotAvailable />,
          sort: (playerExports:PlayerExport[], order:Order) => {
            return sortPlayers(
              playerExports,
              order,
              (player:PlayerExport) => player.expirationDate,
            );
          },
        };
    }
  });

  const actionDisabled = !selectedItems.length;
  const totalPages = Math.ceil(exportsData.length / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = exportsData.length < limit ? exportsData.length : (limit * currentPage);

  return (
    <div className={clsx(classes.exportsTable, className)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleRow}>
          <div className={classes.titleTextWrap}>
            <h2 className={classes.headerTitle}>
              Your Exports
            </h2>

            <Tooltip title={`${remainingExports} players are available to export each month`}>
              <div className={classes.exportsRemaining}>
                {`${remainingExports} Exports Remaining`}
              </div>
            </Tooltip>
          </div>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Exports
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <Action
            className={clsx(classes.action, actionDisabled && classes.disabledAction)}
            icon={RemoveIcon}
            iconClassName={classes.actionIcon}
            disabled={actionDisabled}
            onClick={removePlayerExports}
          >
            <span className={classes.actionText}>Remove</span>
          </Action>
        </div>
      </div>

      <div className={classes.tableWrapper}>
        <Table className={clsx(classes.table, className)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
              >
                <Checkbox
                  checked={exportsData.length === selectedItems.length}
                  onChange={() => exportsData.length === selectedItems.length
                    ? setSelectedItems([])
                    : setSelectedItems([...exportsData])
                  }
                />
              </TableHeadCell>

              {tableColumns.map((tableColumn:any) => (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable
                    ? onSortByColumn(tableColumn.sort || ((exports:PlayerExport[]) => exports))
                    : undefined
                  }
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))}

              <TableHeadCell
                key='download-file-cell'
                className={classes.downloadFileCell}
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {!exportsData.length && (
              <TableRow
                key='empty'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.emptyCell)}
                  colSpan={tableColumns.length + 1}
                >
                  <div className={classes.empty}>
                    No exports
                  </div>
                </TableCell>
              </TableRow>
            )}

            {exportsData
              .slice(itemsFrom, itemsTo)
              .map((item:PlayerExport) => renderTableRow(item as PlayerExport))
            }
          </TableBody>
        </Table>
      </div>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {exportsData.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}
