import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../icons/SearchIcon';
import { sortPlayersByLastName } from '../services/player';
import {
  COLOR_LIGHT_GRAY,
  COLOR_TEXT,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Player from '../types/Player';
import MEDIA from '../styles/media';

interface PlayersAutoCompleteProps {
  className?: string;
  itemClassName?: string;
  label?: string;
  value?: Player | null;
  players: Player[];
  onSelect: (player:Player | null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  playersAutoComplete: {
    background: COLOR_LIGHT_GRAY,
    position: 'relative',
    borderRadius: '4px',
  },
  open: {
    '& $label': {
      display: 'none',
    },
  },
  withValue: {
    '& $label': {
      display: 'none',
    },
  },

  endAdornment: {
    display: 'none',
  },

  input: {
    minHeight: '50px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },

    '& > input': {
      padding: theme.spacing(1.5),
    },
  },

  label: {
    transform: 'none',
    top: theme.spacing(1.75),
    left: theme.spacing(1.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  labelWrapper: {
    display: 'flex',
  },

  inputAdornment: {
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(0),
  },
  searchIcon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
    color: COLOR_TEXT,
  },

  playerItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  photo: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2),
    borderRadius: '50%',
  },

  [MEDIA.DESKTOP_LARGE]: {
    input: {
      fontSize: theme.typography.pxToRem(16),
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: PlayersAutoComplete.name });

export default function PlayersAutoComplete (props: PlayersAutoCompleteProps) {
  const {
    className,
    itemClassName,
    label = 'Search Players',
    value,
    players,
    onSelect,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Autocomplete
      className={clsx(
        classes.playersAutoComplete,
        open && classes.open,
        value && classes.withValue,
        className,
      )}
      classes={{ endAdornment: classes.endAdornment }}
      options={[...players].sort(sortPlayersByLastName)}
      getOptionLabel={(player:Player) => `${player.firstName} ${player.lastName}`}
      renderOption={(player:Player) => (
        <div className={clsx(classes.playerItem, itemClassName)}>
          {player.photoUrl && (
            <img
              className={classes.photo}
              src={player.photoUrl}
              alt={`${player.firstName} ${player.lastName}`}
            />
          )}
          {player.firstName} {player.lastName}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ className: classes.label }}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            startAdornment: (
              <InputAdornment
                className={classes.inputAdornment}
                position='start'
              >
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            )
          }}
          placeholder={label}
          variant='standard'
        />
      )}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event:any, player: Player | null) => onSelect(player)}
    />
  );
}
