import React from 'react';
import Icon from '../atoms/Icon';

function StarFilledIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
        <path
          fill='currentColor'
          fillRule='nonzero'
          d='M16 25l-9.405 4.944 1.797-10.472-7.609-7.416 10.515-1.528L16 1l4.702 9.528 10.515 1.528-7.609 7.416 1.797 10.472z'
        />
    </Icon>
  );
}

export default React.forwardRef(StarFilledIcon);