import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface ChartMarkerProps {
  position: number; // 0 - 100
  label: string;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  chartMarker: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: 'calc(50% - 2px)',
    borderColor: 'currentColor transparent',
    borderStyle: 'solid',
    borderWidth: '0px 12px 24px 12px',
    transform: 'translate(-50%, -50%)',
    fontFamily: FONT_PROXIMA_NOVA,
    zIndex: 1,

    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      top: '7px',
      left: '-7px',
      borderColor: `${COLOR_WHITE} transparent`,
      borderStyle: 'solid',
      borderWidth: '0px 7px 14px 7px',
    },
  },
  label: {
    position: 'absolute',
    left: 0,
    top: '-20px',
    transform: 'translateX(-50%)',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1,
    color: COLOR_TEXT,
    textAlign: 'center',
  },
}), { name: ChartMarker.name });

export default function ChartMarker (props: ChartMarkerProps) {
  const {
    position,
    label = '',
    color,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.chartMarker}
      style={{
        left: `${position}%`,
        color,
      }}
    >
      <div className={classes.label}>{label}</div>
    </div>
  );
}
