import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { State } from '../redux/reducers';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_BACKGROUND_LIGHT,
} from '../styles/colors';
import DropDown from '../atoms/DropDown';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import Download from '../atoms/Download';
import exportCSVReport, { CSVExportType } from '../services/export-csv-report';
import { getDateFromDays } from '../services/converter';
import Loader from '../atoms/Loader';

interface UsageTableProps {
  className?: string;
}

const useStyles = makeStyles(theme => ({
  header: {
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    position: 'relative',
    margin: 0,
    padding: theme.spacing(3, 2),
    lineHeight: 1,
    ...theme.typography.h2,
  },
  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },
  dropDown: {
    width: '100%',
    maxWidth: '400px',
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  dropDownSelect: {
    height: '50px',
  },
  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.25),
    whiteSpace: 'nowrap',
  },
  download: {
    paddingTop: '6px',
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },

}), { name: UsageTable.name });

function UsageTable(props: UsageTableProps) {
  const {
    className,
  } = props;

  const [selectedLoginReportDays, setSelectedLoginReportDays] = useState<number>(30);
  const [selectedAPIAccessReportDays, setSelectedAPIAccessReportDays] = useState<number>(30);
  const [loading, setLoading] = useState<boolean>(false);

  const days = [{ content: '30 Days', value: 30 }, { content: '60 Days', value: 60 }, { content: '90 Days', value: 90 }]
  const classes = useStyles();

  function downloadReport(rowIndex: number = 0) {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to get a 1-based month
    const date = currentDate.getDate().toString().padStart(2, '0');
    const mmddFormat = `${month}-${date}`;
    let fileName = 'User Login Report';
    let selectedDays = 30;
    let exportType:CSVExportType = CSVExportType.USER_LOGIN;

    switch (rowIndex) {
      case 0:
        selectedDays = selectedLoginReportDays;
        exportType = CSVExportType.USER_LOGIN;
        fileName = `User Login Report ${mmddFormat}`;
        break;
      case 1:
        selectedDays = selectedAPIAccessReportDays;
        exportType = CSVExportType.API_ACCESS;
        fileName = `API Access Report ${mmddFormat}`;
        break;
      default:
        break;
    }
    const { startDate, endDate } = getDateFromDays(selectedDays);
    setLoading(true);
    exportCSVReport(exportType, { startDate, endDate }, fileName)
        .catch(error => {
          setLoading(false);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
  }

  return (
    <div className={className}>
      <Loader inProgress={loading} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <h4 style={{paddingTop: '6px'}}>User Login:</h4>
            </TableCell>
            <TableCell className={classes.tableCell}>
            <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              <DropDown
                className={classes.dropDown}
                selectorClassName={classes.dropDownSelect}
                items={days}
                value={selectedLoginReportDays}
                onChange={setSelectedLoginReportDays}
              />
            </div>
          </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
            <Download
              className={classes.download}
              label='Export Report (CSV)'
              onClick={() => downloadReport(0)}
            />
            </TableCell>
          </TableRow>

          {/* API access Report */}
          <TableRow>
          <TableCell className={classes.tableCell}>
            <h4 style={{paddingTop: '6px'}}>API Access:</h4>
          </TableCell>
          <TableCell className={classes.tableCell}>
          <div className={classes.dropdowns}>
          <div className={classes.dropdownWrapper}>
            <DropDown
              className={classes.dropDown}
              selectorClassName={classes.dropDownSelect}
              items={days}
              value={selectedAPIAccessReportDays}
              onChange={setSelectedAPIAccessReportDays}
            />
          </div>
        </div>
          </TableCell>
          <TableCell className={classes.tableCell}>
          <Download
            className={classes.download}
            label='Export Report (CSV)'
            onClick={() => downloadReport(1)}
          />
          </TableCell>
        </TableRow>
        </TableBody>
      </Table>

    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsageTable);
