import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import MessageIcon from '../icons/MessageIcon';
import MessagesTable from '../organisms/MessagesTable';
import MEDIA from '../styles/media';

const useStyles = makeStyles(theme => ({
  messagesPageTemplate: {
    minHeight: '100vh',
  },

  paper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    margin: theme.spacing(3.5, 5, 0),
    ...theme.typography.h2,
  },

  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  table: {
    margin: theme.spacing(2,5,0),
  },

  [MEDIA.MOBILE]: {
    header: {
      minHeight: 0,
      margin: theme.spacing(2, 2, 0),
    },

    table: {
      margin: theme.spacing(2, 2, 0),
    },
  },

  '@media (min-width: 1601px)': {
    table: {
      marginTop: '15px',
    },
  },
}), { name: MessagesPageTemplate.name });

export default function MessagesPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.messagesPageTemplate}>
      <Paper className={classes.paper}>
        <h1 className={classes.header}>
          <MessageIcon className={classes.icon} />
          Your Messages
        </h1>
        <MessagesTable className={classes.table} />
      </Paper>
    </PageContainer>
  )
}
