import React from 'react';
import Icon from '../atoms/Icon';

function AddUserIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(2, 2)' fill='currentColor' fillRule='nonzero'>
          <path d='M10,0 C6.144531,0 3,3.144531 3,7 C3,9.410156 4.230469,11.550781 6.09375,12.8125 C2.527344,14.34375 0,17.882813 0,22 L2,22 C2,17.570313 5.570313,14 10,14 C11.375,14 12.65625,14.359375 13.78125,14.96875 C12.671875,16.34375 12,18.101563 12,20 C12,24.40625 15.59375,28 20,28 C24.40625,28 28,24.40625 28,20 C28,15.59375 24.40625,12 20,12 C18.253906,12 16.628906,12.574219 15.3125,13.53125 C14.871094,13.253906 14.390625,13.019531 13.90625,12.8125 C15.769531,11.550781 17,9.410156 17,7 C17,3.144531 13.855469,0 10,0 Z M10,2 C12.773438,2 15,4.226563 15,7 C15,9.773438 12.773438,12 10,12 C7.226563,12 5,9.773438 5,7 C5,4.226563 7.226563,2 10,2 Z M20,14 C23.324219,14 26,16.675781 26,20 C26,23.324219 23.324219,26 20,26 C16.675781,26 14,23.324219 14,20 C14,16.675781 16.675781,14 20,14 Z M19,16 L19,19 L16,19 L16,21 L19,21 L19,24 L21,24 L21,21 L24,21 L24,19 L21,19 L21,16 L19,16 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(AddUserIcon);
