import React, {useState, useRef, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NamedBox from '../atoms/NamedBox';
import ColorCode from '../atoms/ColorCode';
import EditButton from '../atoms/EditButton';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import gql from '../services/gql';
import { COLOR_BLUE, COLOR_LIGHT_GRAY, COLOR_ORANGE, COLOR_TEXT } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { Color } from '../types/Color';
import Note from '../types/Note';

interface PlayerProfileNotesProps {
  className?: string;
  canEdit?: boolean;
  notes: Note[];
  playerId: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileNotes: {
    position: 'relative',
  },
  header: {
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  headerColorContainer: {
    width: 22,
    display: 'flex',
    alignItems: 'center',
  },

  colorCode: {
    marginLeft: theme.spacing(2),
  },
  disclaimer: {
    margin: 0,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    fontStyle: 'italic',
    fontWeight: 400,
    color: COLOR_TEXT,
  },
  text: {
    padding: theme.spacing(2),
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
  },
  addNotesWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  addNotes: {
    appearance: 'none',
    border: 0,
    background: 'none',
    padding: theme.spacing(1),
    margin: theme.spacing(1.5, 'auto'),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
    outlineColor: COLOR_ORANGE,
  },

  textAreaWrapper: {
    width: '100%',
    padding: theme.spacing(2, 2, 1.5, 2),
  },
  textArea: {
    width: 'calc(100% - 32px)',
    padding: theme.spacing(2),
    border: 0,
    borderBottom: `2px solid ${COLOR_BLUE}`,
    background: COLOR_LIGHT_GRAY,
    boxSizing: 'content-box',
    fontFamily: FONT_PROXIMA_NOVA,
    outline: 'none',
    transition: 'border-color 0.3s',

    '&:focus': {
      borderColor: COLOR_ORANGE,
    },
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 2, 2),
  },
  action: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.5, 4),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  evaluation: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  evaluationText: {
    paddingLeft: theme.spacing(1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    color: COLOR_TEXT,
    textAlign: 'right',
  },
  evaluationColor: {
    marginLeft: theme.spacing(1.5),

    '&:first-of-type': {
      marginLeft: theme.spacing(2),
    },
  }
}), { name: PlayerProfileNotes.name });

export default function PlayerProfileNotes (props:PlayerProfileNotesProps) {
  const {
    className,
    canEdit = true,
    notes,
    playerId,
  } = props;
  const classes = useStyles();
  const textAreaRef = useRef();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<Color>();

  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  useEffect(() => {
    if (notes && notes.length) {
      const { colorCode, text } = notes[0] || {};

      if (colorCode) {
        setSelectedColor(colorCode);
      }

      if (text) {
        setTextValue(text);
      }
    }
  }, [notes])

  function onSave () {
    const textValue = (textAreaRef.current || { value: '' }).value;
    setTextValue(textValue);

    setLoading(true);

    gql(`
      mutation {
        saveNote(
          playerId:${playerId},
          noteText: "${textValue}",
          colorCode: "${selectedColor}"
        )
      }
    `)
      .then((data:any) => data.saveNote as boolean)
      .then((success:boolean) => {
        if (success) {
          setEditMode(false);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false))
  }

  function onEdit () {
    if (!selectedColor) {
      setSelectedColor(Color.BLACK);
    }

    setEditMode(true);
  }

  function onCancel () {
    setEditMode(false);
  }

  function onToggleColor (color:Color) {
    return () => {
      if (selectedColor === color) {
        setSelectedColor(Color.NONE);
      } else {
        setSelectedColor(color);
      }
    }
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  return (
    <>
      <NamedBox
        className={clsx(classes.playerProfileNotes, className)}
        headerClassName={classes.header}
        name='Your Notes'
        headerContent={(
          <div className={classes.headerContent}>
            <div className={classes.headerColorContainer}>
              {selectedColor && (
                <ColorCode
                  className={classes.colorCode}
                  color={selectedColor}
                />
              )}
            </div>

            <p className={classes.disclaimer}>Your notes can only be seen by you</p>

            {canEdit && (
              <EditButton
                onClick={onEdit}
                disabled={!notes}
              />
            )}
          </div>
        )}
      >
        <Loader inProgress={loading} />

        {(!editMode && textValue) && (
          <p className={classes.text}>
            {textValue}
          </p>
        )}

        {(canEdit && !editMode && !textValue) && (
          <div className={classes.addNotesWrapper}>
            <button
              className={classes.addNotes}
              onClick={onEdit}
            >
              Add Notes
            </button>
          </div>
        )}

        {editMode && (
          <>
            <div className={classes.textAreaWrapper}>
              <TextareaAutosize
                ref={textAreaRef as any}
                className={classes.textArea}
                placeholder='Your notes'
                defaultValue={textValue}
                rowsMin={4}
              />
            </div>

            <div className={classes.actions}>
              <Button
                className={classes.action}
                disabled={loading}
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                className={classes.action}
                primary
                disabled={loading}
                onClick={onSave}
              >
                Save
              </Button>

              <div className={classes.evaluation}>
                <div className={classes.evaluationText}>
                  Your&nbsp;Evaluation
                  (Surface&nbsp;to&nbsp;Top):
                </div>

                <ColorCode
                  className={classes.evaluationColor}
                  color={Color.BLACK}
                  selected={selectedColor === Color.BLACK}
                  onClick={onToggleColor(Color.BLACK)}
                />
                <ColorCode
                  className={classes.evaluationColor}
                  color={Color.GREEN}
                  selected={selectedColor === Color.GREEN}
                  onClick={onToggleColor(Color.GREEN)}
                />
                <ColorCode
                  className={classes.evaluationColor}
                  color={Color.YELLOW}
                  selected={selectedColor === Color.YELLOW}
                  onClick={onToggleColor(Color.YELLOW)}
                />
                <ColorCode
                  className={classes.evaluationColor}
                  color={Color.RED}
                  selected={selectedColor === Color.RED}
                  onClick={onToggleColor(Color.RED)}
                />
              </div>
            </div>
          </>
        )}
      </NamedBox>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
