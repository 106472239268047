import React from 'react';
import { useLocation } from 'react-router-dom';
import ComparePlayersPageTemplate from '../templates/ComparePlayersPageTemplate';
import Page from './Page';

export default function ComparePlayersPage () {
  const location = useLocation();
  const printed = location.pathname.endsWith('/pdf');

  return (
    <Page
      printed={printed}
      title='Compare Players'
    >
      <ComparePlayersPageTemplate printed={printed} />
    </Page>
  );
}
