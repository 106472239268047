export enum LinkType {
  twitter = 'twitter',
  facebook = 'facebook',
  instagram = 'instagram',
  youtube = 'youtube',
  twoFourSeven = '247',
  rivals = 'rivals',
  on3 = 'on3',
  hudl = 'hudl',
  athletic = 'athletic.net',
  maxpreps = 'maxpreps',
  milesplit = 'milesplit',
  directAthletics = 'directAthletics',
  pff = 'pff',
  ssa = 'ssa',
  trackingFootball = 'trackingFootball',
}

export default interface Link {
  key: LinkType;
  value: string;
}
