import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BORDER, COLOR_WHITE, COLOR_BACKGROUND_LIGHT } from '../styles/colors';

interface SelectPopoverProps {
  className?: string;
  paperClassName?: string;
  itemClassName?: string;
  currentItemClassName?: string;
  anchorEl: HTMLElement | null;
  items: SelectPopoverItem[];
  value?: string;
  onSelect: (item:string) => void;
  onClose: () => void;
}

export interface SelectPopoverItem {
  content: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectPopover: {},
  paper: {
    borderRadius: '12px',
    border: 0,
  },

  items: {
    maxHeight: 308,
    appearance: 'none',
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    padding: theme.spacing(1, 2),
    background: `${COLOR_WHITE} !important`,
    borderTop: `1px solid ${COLOR_BORDER}`,
    cursor: 'pointer',

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:hover': {
      backgroundColor: `${COLOR_BACKGROUND_LIGHT} !important`,
    },
  },
}), { name: SelectPopover.name });

export default function SelectPopover (props:SelectPopoverProps) {
  const classes = useStyles();
  const {
    className,
    paperClassName,
    itemClassName = '',
    currentItemClassName = '',
    anchorEl,
    items,
    value,
    onSelect,
    onClose,
  } = props;
  const open = Boolean(anchorEl);

  return (
    <Popover
      className={clsx(classes.selectPopover, className)}
      classes={{ paper: clsx(classes.paper, paperClassName) }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <ul className={classes.items}>
        {items.map((item:SelectPopoverItem) => (
          <MenuItem
            key={item.value}
            classes={{
              root: clsx(
                classes.item,
                itemClassName,
                value === item.value && currentItemClassName,
              ),
            }}
            value={item.value}
            onClick={() => onSelect(item.value)}
            disableRipple
          >
            {item.content}
          </MenuItem>
        ))}
      </ul>
    </Popover>
  );
}
