import { Dispatch } from 'redux';
import {
  setAllStates,
  setAmericanStates,
  setStatesLoading,
} from '../actions/states';
import gql from '../../services/gql';
import { cache, CacheExpiration, CacheKey, retrieve } from '../../services/cache';
import { State } from '../reducers';
import StateType from '../../types/State';

export function fetchStates () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { states: { allStates, statesLoading } } = getState();

    if (statesLoading || allStates.length > 0) return;

    const cachedAllStates = retrieve(CacheKey.ALL_STATES);
    const cacheIsValid = !!cachedAllStates?.length;

    if (cacheIsValid) {
      processStates(cachedAllStates, dispatch);
      return;
    }

    dispatch(setStatesLoading(true));

    return gql(`
      states (includeAllCountries: true) {
        id
        code
        name
        isAmerican
      }
    `)
      .then((data:any) => data.states || [] as StateType[])
      .then((allStates:StateType[]) => {
        processStates(allStates, dispatch);
        cache(CacheKey.ALL_STATES, allStates, CacheExpiration.LONG);
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setStatesLoading(false));
      });
  };
}

function processStates (allStates:StateType[], dispatch:Dispatch) {
  if (!!allStates?.length) {
    dispatch(setAllStates(allStates));

    const americanStates = allStates.filter((state:StateType) => state.isAmerican);
    dispatch(setAmericanStates(americanStates));
  }
}
