import React from 'react';
import Page from './Page';
import HelpPageTemplate from '../templates/HelpPageTemplate';

export default function HelpPage () {
  return (
    <Page title='Help'>
      <HelpPageTemplate />
    </Page>
  );
}
