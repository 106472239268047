import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import PinIcon from '../icons/PinIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotAvailable from '../atoms/NotAvailable';
import Download from '../atoms/Download';
import DropDown from '../atoms/DropDown';
import Avatar from '../atoms/Avatar';
import DragNDropDots from '../atoms/DragNDrop';
import Score, { SCORE_TYPE } from '../atoms/Score';
import LocationIcon from '../atoms/LocationIcon';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import Action from '../atoms/Action';
import MultiSportList from '../molecules/MultiSportList';
import PinButton, { PIN_MODE } from '../molecules/PinButton';
import ConfirmDialog from './ConfirmDialog';
import PlayerNameWithPopover from './PlayerNameWithPopover';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  COLOR_BORDER,
  COLOR_RED,
} from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';
import PinnedPlayer from '../types/PinnedPlayer';
import Position from '../types/Position';
import HighSchool from '../types/HighSchool';

interface MyRecruitingBoardProps {
  className?: string;
  user: User;
  position?: Position;
  printed?: boolean;
}

const useStyles = makeStyles<Theme, MyRecruitingBoardProps>(theme => ({
  myRecruitingBoard: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  headerIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 0, 0, 1),
  },
  headerDownloads: {
    display: 'flex',
    marginLeft: 'auto',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  dropDown: {
    marginLeft: theme.spacing(2),
  },
  dropDownSelect: {
    height: '40px',
  },

  tableWrap: {
    overflow: 'auto',
  },

  noPlayers: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
  },
  noPlayersIcon: {
    width: '50px',
    height: '50px',
    opacity: 0.5,
  },
  noPlayersText: {
    marginTop: theme.spacing(2),
    color: COLOR_DARK_GRAY,
  },

  table: {
    position: 'relative',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1),
    },
  },
  tableRow: {
    backgroundColor: COLOR_WHITE,
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,

      '& $dragNDropDots': {
        opacity: 1,
      },
    },
  },
  tableRowDragging: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableCell: {
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,

    '&:first-of-type': {
      paddingLeft: (props) => props.printed ? theme.spacing(1) : 0,
    },
    '&:nth-of-type(2)': {
      paddingLeft: (props) => props.printed ? theme.spacing(1) : 0,
    },
  },

  multiSportCell: {
    maxWidth: '116px',
    minWidth: '92px',
    overflow: 'hidden'
  },
  sportIcon: {
    flexShrink: 0,
  },

  tableCellWithPin: {
    width: '50px',
    height: '50px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  tableSectionTitle: {
    background: COLOR_BACKGROUND_LIGHT,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    padding: theme.spacing(0.5, 2),
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: 0,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: 'rgba(0, 0, 0, 0.04)',
      bottom: 0,
      left: 0,
    },
  },

  clearAll: {
    height: 48,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    color: COLOR_RED,
    textDecoration: 'underline',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    border: 'none',

    '&:hover': {
      color: COLOR_RED,
    },
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },
  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  playerHometown: {
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  playerScore: {
    margin: '0 auto',
  },

  pinButtonContainer: {
    height: '100%',
    display: 'flex',
  },

  dragNDropDots: {
    opacity: 0,
    transition: 'opacity 0.2s',
  },
  blankCell: {
    display: 'none',
  },

  '@media (max-width: 1600px)': {
    dropDownSelect: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  '@media (min-width: 1025px) and (max-width: 1260px), (max-width: 480px)': {
    header: {
      flexWrap: 'wrap',
    },
    headerTitleWrap: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    headerDownloads: {
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    tableHeadCell: {
      padding: theme.spacing(1, 0, 1, 1.5),
      fontSize: theme.typography.pxToRem(14),

      '&:first-of-type': {
        paddingLeft: theme.spacing(1.5),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(1.5),
      },
    },
  },

  [MEDIA.MOBILE]: {
    multiSportCell: {
      paddingLeft: 6,
    },

    clearAll: {
      paddingRight: `6px !important`,
    },
  },

  [MEDIA.PRINT]: {
    tableCellWithPin: {
      display: 'none',
    },
    header: {
      display: 'none',
    },
  }
}), { name: MyRecruitingBoard.name });

export default function MyRecruitingBoard (props: MyRecruitingBoardProps) {
  const {
    className,
    user,
    position,
    printed = false,
  } = props;
  const classes = useStyles(props);

  const [pinnedPlayers, setPinnedPlayers] = useState<PinnedPlayer[]>([]);
  const [positions, setPositions] = useState<any>([]);
  const [players, setPlayers] = useState<PinnedPlayer[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<Position>(position as Position);
  const [playersIdsMapping, setPlayersIdsMapping] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    fetchPinnedPlayers();
  }, []);

  useEffect(() => {
    if (!!players.length) {
      const sortedPinnedPlayerByPosition = [...players]
      .filter((pinned:PinnedPlayer) => pinned.position === selectedPosition)
      .sort((player1:PinnedPlayer, player2:PinnedPlayer) => player1.order - player2.order);

      if (sortedPinnedPlayerByPosition && sortedPinnedPlayerByPosition.length) {
        setPinnedPlayers(sortedPinnedPlayerByPosition);
      } else {
        setPinnedPlayers(players);
      }
    }
  }, [selectedPosition, players]);

  function fetchPinnedPlayers () {
    setLoading(true);

    gql(`
      pinnedPlayers {
        id
        playerId
        player {
          id
          firstName
          lastName
          photoUrl
          pai
          combine
          multiSport
          hsCombines {
            id
          }
          highSchools
        }
        order
        position
      }
    `)
    .then((data:any) => data.pinnedPlayers as any)
    .then((players:PinnedPlayer[]) => {
      if (players) {
        updateAndSetPlayersData(players);
      }
    })
    .catch(console.error)
    .finally(() => setLoading(false));
  }

  function updateAndSetPlayersData (pinnedPlayers:PinnedPlayer[]) {
    setPinnedPlayers(pinnedPlayers);
    updatePositions(pinnedPlayers);
    groupPlayersByPosition(pinnedPlayers);
  }

  function unpinPlayers(playerIds:(number | string | undefined) []) {
    return gql(`
      mutation {
        unpin (playerIds: [${playerIds.map(playerId => playerId)}])
      }
    `)
      .then((data:any) => data && data.unpin as boolean)
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      });
  }

  function unpinSinglePlayer(playerId:number | string) {
    setLoading(true);

    unpinPlayers([playerId])
      .then((success:boolean | void) => {
        if (success) {
          showToast('Unpinned', ToastType.SUCCESS);
          const pinndePlayersAfterUnpin = pinnedPlayers.filter((player: PinnedPlayer) => !(player.playerId === playerId));
          updateAndSetPlayersData(pinndePlayersAfterUnpin);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function clearBoard() {
    setLoading(true);
    setDialogOpen(false);

    unpinPlayers(pinnedPlayers.map(pinnedPlayer => pinnedPlayer.playerId))
      .then((success:boolean | void) => {
        if (success) {
          showToast('All players unpinned', ToastType.SUCCESS);
          updateAndSetPlayersData([]);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .finally(() => {
        setLoading(false);
        setSelectedPosition(position as Position);
      });
  }

  function reorderPinnedPlayers (playerIds:number[], reorderedPlayers:PinnedPlayer[]) {
    setLoading(true);

    gql(`
      mutation {
        reorderPinnedPlayers (
          pinnedPlayersIds: [${playerIds}],
          position: "${selectedPosition}"
        )
      }
    `)
      .then((data:any) => data && data.reorderPinnedPlayers as boolean)
      .then((success:boolean) => {
        if (success) {
          fetchPinnedPlayers();
          showToast('Success.', ToastType.SUCCESS);
          setPinnedPlayers(reorderedPlayers);
        } else {
          showToast('Failed to save the new order. Please refresh the page.', ToastType.ERROR);
        }
      })
      .catch(() => showToast('Failed to save the new order. Please refresh the page.', ToastType.ERROR))
      .finally(() => setLoading(false));
  }

  function groupPlayersByPosition(players:PinnedPlayer[]) {
    const groupedPlayers = [...players]
      .sort((a: PinnedPlayer, b: PinnedPlayer) => {
        if (a.position < b.position) { return -1; }
        if (a.position > b.position) { return 1; }
        return a.order - b.order;
      });

    setPinnedPlayers(groupedPlayers);

    let idsMapping:{[key: number]: number} = {};
    groupedPlayers.map((pinnedPlayer: any) => {
      idsMapping[pinnedPlayer.playerId] = pinnedPlayer.id;
    });
    setPlayersIdsMapping(idsMapping);

    setPlayers(groupedPlayers);
  }

  function updatePositions(pinnedPlayers:PinnedPlayer[]) {
    const positions = pinnedPlayers
      .map(pinnedPlayer => pinnedPlayer.position)
      .sort();

      setPositions(positions
        .filter((position:Position, index:number) => positions.indexOf(position) === index)
        .map((position:Position) => ({
          content: position,
          value: position,
        }))
      );
  }

  function onDragEnd (result:any) {
    if (!result.destination || !selectedPosition) return;

    const fromPosition = result.source.index;
    const toPosition = result.destination.index;
    const reorderedPlayers = [...pinnedPlayers];
    const draggedPlayer = pinnedPlayers[fromPosition];

    reorderedPlayers.splice(fromPosition, 1); // cut out the dragged player
    reorderedPlayers.splice(toPosition, 0, draggedPlayer); // insert it to the position

    const playerIds = reorderedPlayers.map((pinnedPlayer:PinnedPlayer) => playersIdsMapping[pinnedPlayer.player.id]);

    reorderPinnedPlayers(playerIds, reorderedPlayers);
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onXLSDownload () {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: players.map((pinnedPlayer:PinnedPlayer) => pinnedPlayer.playerId) }
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function onPDFDownload () {
    setLoading(true);

    exportPDFReport(
      PDFExportPage.RECRUITING_BOARD,
      '',
      selectedPosition ? { position: selectedPosition } : undefined,
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function renderTableRow (pinnedPlayer:PinnedPlayer, index:number) {
    const {
      player: {
        id,
        firstName,
        lastName,
        photoUrl,
        pai,
        combine,
        multiSport,
        highSchools,
        hsCombines,
      },
      position,
    } = pinnedPlayer;

    const primaryHighSchool = (highSchools || []).find((highSchool:HighSchool) => !!highSchool?.isPrimary);

    const itemId = `${id}_${index}`;
    const isDragDisabled = !selectedPosition;

    return (
      <Draggable
        key={itemId}
        index={index}
        draggableId={itemId}
        isDragDisabled={isDragDisabled}
      >
        {({ draggableProps, dragHandleProps, innerRef }, snapshot) => (
          <TableRow
            key={index}
            className={clsx(classes.tableRow, snapshot.isDragging && classes.tableRowDragging)}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
          >
            <TableCell className={clsx(classes.tableCell, printed && classes.blankCell)}>
              {!isDragDisabled && <DragNDropDots className={classes.dragNDropDots} />}
            </TableCell>

            <TableCell className={classes.tableCell} align='left'>
              <div className={classes.playerInfoContainer}>
                <Avatar
                  className={classes.playerAvatar}
                  src={photoUrl}
                  alt='Profile picture'
                />

                <div className={classes.playerNameAndHometown}>
                  <PlayerNameWithPopover
                    playerId={id}
                    name={`${firstName} ${lastName}`}
                  />

                  {(primaryHighSchool?.city || primaryHighSchool?.state) && (
                    <div className={classes.playerHometown}>
                      {primaryHighSchool.city ? `${primaryHighSchool.city},`: ''}{primaryHighSchool.state ? `${primaryHighSchool.state}` : ''}
                    </div>
                  )}
                </div>

                {primaryHighSchool?.state && (
                  <LocationIcon
                    className={classes.locationIcon}
                    stateCode={primaryHighSchool.state}
                  />
                )}
              </div>
            </TableCell>

            <TableCell className={classes.tableCell} align='center'>
              {position || <NotAvailable />}
            </TableCell>

            <TableCell className={classes.tableCell} align='center'>
              {(primaryHighSchool && primaryHighSchool.graduatingClass)
                ? primaryHighSchool.graduatingClass
                : <NotAvailable />
              }
            </TableCell>

            <TableCell className={classes.tableCell} align='center'>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PAI}
                scoreList={pai}
              />
            </TableCell>

            <TableCell className={classes.tableCell} align='center'>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.COMBINE}
                scoreList={combine}
                combines={hsCombines}
              />
            </TableCell>

            <TableCell className={clsx(classes.tableCell, classes.multiSportCell)} align='left'>
              {(multiSport && multiSport.length)
                ? <MultiSportList
                    iconClassName={classes.sportIcon}
                    list={multiSport}
                    limit={4}
                  />
                : <NotAvailable />
              }
            </TableCell>

            <TableCell
              className={clsx(
                classes.tableCell,
                classes.tableCellWithPin,
                printed && classes.blankCell
              )}
              align='center'
            >
              <div className={classes.pinButtonContainer}>
                <Divider orientation='vertical' light flexItem />

                <PinButton
                  mode={PIN_MODE.REMOVE}
                  active
                  onClick={() => unpinSinglePlayer(id)}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    );
  }

  return (
    <Paper className={clsx(classes.myRecruitingBoard, className)}>
      <Loader inProgress={loading} />

      {!printed && (
        <div className={classes.header}>
          <div className={classes.headerTitleWrap}>
            <PinIcon className={classes.headerIcon} />
            <h2 className={classes.headerTitle}>My Recruiting Board</h2>
          </div>

          <div className={classes.headerDownloads}>
            {(user.accessLevel.exportAllowanceType !== 'none') && (
              <Download
                label='XLS'
                onClick={onXLSDownload}
                disabled={!players.length}
              />
            )}

            <Download
              label='PDF'
              onClick={onPDFDownload}
            />
          </div>

          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            emptyItem={{
              content: 'By Position',
              value: '',
            }}
            items={positions}
            value={selectedPosition}
            onChange={setSelectedPosition}
          />
        </div>
      )}

      <Divider variant='fullWidth' light />

      {!players.length && (
        <div className={classes.noPlayers}>
          <PinIcon className={classes.noPlayersIcon} />
          <div className={classes.noPlayersText}>No Player Pinned</div>
        </div>
      )}

      {players.length > 0 && (
        <div className={classes.tableWrap}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='recruiting-board'>
              {({ droppableProps, placeholder, innerRef }) => (
                <div
                  ref={innerRef}
                  {...droppableProps}
                >
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={clsx(classes.tableHeadCell, printed && classes.blankCell)}>&nbsp;</TableCell>
                        <TableCell className={classes.tableHeadCell} align='left'>Name&nbsp;|&nbsp;Hometown</TableCell>
                        <TableCell className={classes.tableHeadCell} align='center'>Pos.</TableCell>
                        <TableCell className={classes.tableHeadCell} align='center'>Class</TableCell>
                        <TableCell className={classes.tableHeadCell} align='center'>PAI</TableCell>
                        <TableCell className={classes.tableHeadCell} align='center'>Com.</TableCell>
                        <TableCell className={clsx(classes.tableHeadCell, classes.multiSportCell)} align='left'>Multi-sport</TableCell>
                        <TableCell className={clsx(classes.tableHeadCell, printed && classes.blankCell)}>&nbsp;</TableCell>

                        {(!printed && user.isMaster) && (
                          <Action
                            className={clsx(classes.tableHeadCell, classes.clearAll)}
                            onClick={() => setDialogOpen(true)}
                          >
                            Clear
                          </Action>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pinnedPlayers.map(renderTableRow)}
                      {placeholder}
                    </TableBody>
                  </Table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>

      <ConfirmDialog
        confirmActionText='Yes, Clear the board'
        open={dialogOpen}
        onConfirm={clearBoard}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
      />
    </Paper>
  );
}
