import React from 'react';
import Icon from '../atoms/Icon';

function EyeIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(5, 8)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <path
            d='M11,4 C8.791,4 7,5.791 7,8 C7,10.209 8.791,12 11,12 C13.209,12 15,10.209 15,8 C15,5.791 13.209,4 11,4 Z M11,10 C9.895,10 9,9.105 9,8 C9,6.895 9.895,6 11,6 C12.105,6 13,6.895 13,8 C13,9.105 12.105,10 11,10 Z'
          />
          <path
            d='M11,0 C3,0 0,8 0,8 C0,8 3,16 11,16 C19,16 22,8 22,8 C22,8 19,0 11,0 Z M11,14 C5.724,14 3.056,9.732 2.192,8.003 C3.058,6.286 5.745,2 11,2 C16.276,2 18.944,6.268 19.808,7.997 C18.943,9.714 16.255,14 11,14 Z'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(EyeIcon);
