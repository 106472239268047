import React, { useState } from 'react';
import Dialog from './Dialog';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import HelpPopupFooter from '../atoms/HelpPopupFooter';
import Loader from '../atoms/Loader';
import gql from '../services/gql';

interface LoginSubscriptionModalProps {
  className?: string;
  headingClassName?: string;
  inputClassName?: string;
  inputFieldClassName?: string;
  inputHalfClassName?: string;
  dropDownClassName?: string;
  dropDownSelectClassName?: string;
  fullNameClassName?: string;
  multiSelectClassName?: string;
  contentClassName?:string;
  isPopupVisible: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

const HELP_OPTIONS = {
  LOGIN_HELP: 'login',
  SUBSCRIPTION_HELP: 'subscription',
};

const HELP_QUES = {
  [HELP_OPTIONS.LOGIN_HELP] : {
    question: 'Issues logging in?',
    helpText: 'Please describe the issue you are having with login:'
  },
  [HELP_OPTIONS.SUBSCRIPTION_HELP] : {
    question: 'Question about your subscription?',
    helpText: 'Please describe questions about your subscription:'
  }
};

export default function LoginSubscriptionModal (props:LoginSubscriptionModalProps) {
  const {
    className,
    headingClassName,
    inputClassName,
    inputFieldClassName,
    dropDownClassName,
    dropDownSelectClassName,
    contentClassName,
    isPopupVisible = false,
    onClose,
    onError,
    onSuccess,
  } = props;

  const [helpType, setHelpType] = useState<string>(HELP_OPTIONS.LOGIN_HELP);
  const [helpText, setHelpText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleSubmit() {
    submitHelpText()
      .then((success:boolean | void) => {
        if (success) {
          onClose();
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(onError);
  }

  function submitHelpText () {
    setIsLoading(true);

    return gql(`
      mutation {
        submitLoginSubscriptionHelp (loginSubscriptionHelp: {
          requestType: "${helpType}",
          message: "${helpText}"
        })
      }`
    )
      .then((data: any) => data && data.submitLoginSubscriptionHelp as boolean)
      .finally(() => setIsLoading(false));
  }

  return(
    <Dialog
      className={className}
      contentClassName={contentClassName}
      open={isPopupVisible}
      title='Login / Subscription'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose
        },
        {
          name: 'Request Help',
          primary: true,
          disabled: isLoading,
          onClick: handleSubmit,
        }
      ]}
      onClose={onClose}
    >
      <Loader inProgress={isLoading} />
      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        items={[
          { content: 'Login Help', value: HELP_OPTIONS.LOGIN_HELP },
          { content: 'Subscription Help', value: HELP_OPTIONS.SUBSCRIPTION_HELP },
        ]}
        value={helpType}
        onChange={(value: string) => setHelpType(value)}
      />
      <div className={headingClassName}>
        <strong>{HELP_QUES[helpType].question}</strong>
        {HELP_QUES[helpType].helpText}
      </div>

      <Input
        className={inputClassName}
        inputFieldClassName={inputFieldClassName}
        multiLine
        value={helpText}
        onChange={(value: string) => setHelpText(value)}
      />
      <HelpPopupFooter />
    </Dialog>
  );
}
