import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MaterialSwitch from '@material-ui/core/Switch';
import {
  COLOR_GREEN,
  COLOR_WHITE,
  COLOR_MIDDLE_GRAY,
  COLOR_BACKGROUND_LIGHT,
} from '../styles/colors';

interface SwitchProps {
  className?: string;
  onChange: () => void;
  checked: boolean;
}

const useStyles = makeStyles(theme => ({
  switch: {
    width: 80,
    height: 40,
    padding: 0,
    display: 'flex',
  },

  switchBase: {
    padding: 5,
    color: COLOR_WHITE,

    '&$checked': {
      transform: 'translateX(40px)',
      color: COLOR_WHITE,

      '& + $track': {
        opacity: 1,
        backgroundColor: COLOR_GREEN,
        borderColor: COLOR_MIDDLE_GRAY,
      },
    },
  },
  checked: {
    transform: 'translateX(40px)',
    color: COLOR_WHITE,
  },
  thumb: {
    width: 30,
    height: 30,
    boxShadow: 'none',
    border: `1px solid ${COLOR_MIDDLE_GRAY}`,
  },
  track: {
    border: `1px solid ${COLOR_MIDDLE_GRAY}`,
    borderRadius: 40 / 2,
    opacity: 1,
    backgroundColor: COLOR_BACKGROUND_LIGHT,
  },
}), { name: Switch.name });

export default function Switch (props: SwitchProps) {
  const {
    className,
    checked = false,
    onChange,
  } = props;
  const classes = useStyles();

  return (
    <MaterialSwitch
      className={clsx(classes.switch, className)}
      classes={{
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      checked={checked}
      onChange={onChange}
    />
  )
}
