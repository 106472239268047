import React from 'react';
import Icon from '../atoms/Icon';

function SoccerIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g fill='currentcolor' fillRule='nonzero'>
        <path d='M10.842 23.049l2.212 6.803-2.341.761-1.659-5.103H3.69V23.05h7.153zm17.4-.043v2.461h-5.368l-1.658 5.103-2.341-.76 2.211-6.804h7.155zM16 8.743l7.05 5.123-2.692 8.288h-8.716L8.95 13.866 16 8.743zm0 3.043l-4.157 3.02 1.588 4.886h5.138l1.588-4.886L16 11.786zM5.473 6.494l2.211 6.805-5.788 4.204L.45 15.51l4.34-3.153-1.658-5.104 2.341-.76zm21-.036l2.342.76-1.659 5.103 4.342 3.155-1.447 1.991-5.788-4.206 2.21-6.803zM20.342 1.06l1.447 1.991L16 7.257 10.212 3.05l1.447-1.991L16 4.214l4.341-3.154z' />
        <path d='M16 0a16 16 0 100 32 16 16 0 000-32zm0 2.462a13.538 13.538 0 110 27.076 13.538 13.538 0 010-27.076z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SoccerIcon);
