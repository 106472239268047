import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import CloseButton from '../atoms/CloseButton';
import Toast, { ToastType } from '../atoms/Toast';
import { State } from '../redux/reducers';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_GREEN,
  COLOR_WHITE
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import User from '../types/User';
import LastUpdated from '../molecules/LastUpdated';
import Button from '../atoms/Button';

interface ContactInfoPopoverProps {
  anchorEl: HTMLElement | null;
  player: Player;
  user: User;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  ContactInfoPopover: {
    position: 'relative'
  },
  paper: {
    width: 'calc(100% - 32px)',
    maxWidth: '556px',
    borderRadius: '12px',
  },

  header: {
    height: '60px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    background: COLOR_BACKGROUND_LIGHT,
    position: 'relative',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  playerContact: {
    flexGrow: 1
  },

  contentWrap: {
    padding: theme.spacing(0, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: COLOR_WHITE,
    position: 'relative',
  },

  name: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,
    fontWeight: 600,
    textDecoration: 'underline',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  infoList: {
    width: '100%',
    marginBottom: 0,
    padding: 0,
    marginTop: theme.spacing(3),
    listStyle: 'none',
  },
  infoListItem: {
    display: 'flex',
    marginTop: '5px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,

    '&:first-child': {
      marginTop: 0,
    },
  },
  infoListItemName: {
    width: '50%',
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  infoListItemValue: {
    width: '50%',
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
  },
  infoListItemValueExtend: {
    width: '100%',
    textAlign: 'left',
    fontWeight: 600,
  },

  actions: {
    height: '48px',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  ryzerLogo: {
    height: '80%'
  },

  moreInfo: {
    padding: theme.spacing(0, 1, 2),
  },

  ryzerLoginAnchor: {
    textDecoration: 'none',
    border: 0,
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    }
  },

  ryzerLogin: {
    width: 'auto',
    padding: theme.spacing(0.25,1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    fontWidth: 600,
    color: COLOR_WHITE,
    border: 0,
    background: COLOR_GREEN,
    textDecoration: 'none',
    borderRadius: '5px',
  },

  [MEDIA.MOBILE]: {
    paper: {
      maxWidth: '400px',
    },
    header: {
      fontSize: theme.typography.pxToRem(14),
    },
    avatar: {
      width: '80px',
      height: '80px',
    },
    paiScore: {
      left: theme.spacing(2),
    },
    combineScore: {
      right: theme.spacing(2),
    },
  },
}), { name: ContactInfoPopover.name });

function ContactInfoPopover (props:ContactInfoPopoverProps) {
  const classes = useStyles();
  const {
    player,
    anchorEl,
    onClose,
  } = props;

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const open = Boolean(anchorEl);

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  return (
    <>
      <Popover
        className={classes.ContactInfoPopover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <div className={classes.header}>
          <div className={classes.playerContact}>
          Player Contact
          </div>

          <div>
              <LastUpdated
              className={classes.lastUpdatedText}
              playerId={player && player.id || 0}
              datetime={player?.lastPublishedDate}
              hideTooltip
              />
          </div>

          <CloseButton
            onClick={onClose}
          />
        </div>

        <div className={classes.contentWrap}>

          <ul className={classes.infoList}>
            {
              player?.cellPhone && (
                <li className={classes.infoListItem}>
                  <div className={classes.infoListItemName}>Cell:</div>
                  <div className={classes.infoListItemValue}>{player?.cellPhone}</div>
                </li>
              )
            }
            {
              player?.email && (
                <li className={classes.infoListItem}>
                  <div className={classes.infoListItemName}>Email:</div>
                  <div className={classes.infoListItemValue}>{player?.email}</div>
                </li>
              )
            }
            {
              player?.address && (
                <li className={classes.infoListItem}>
                  <div className={classes.infoListItemName}>Address:</div>
                  <div className={classes.infoListItemValue}>
                    <div className={classes.infoListItemValueExtend}>{player?.address}</div>
                    <div className={classes.infoListItemValueExtend}>{player?.cityStateZip}</div>
                  </div>
                </li>
              )
            }

          </ul>
        </div>
        {
          player?.ryzerId && (
            <div>
              <div className={classes.moreInfo}>
                <span> For More Info Contact Ryzer: </span>
                <Button className={classes.ryzerLogin}>
                  <a className={classes.ryzerLoginAnchor} href={"https://www.ryzer.com/login/"} target="_blank" rel="noopener noreferrer"> Ryzer Profile</a>
                  </Button>
              </div>
              <Divider variant='fullWidth' light />
              <div className={classes.actions}>
                <div>
                  <span>All Information Powered by Ryzer Recruit</span>
                </div>
                <div>
                  <img
                    className={classes.ryzerLogo}
                    src='/images/players/ryzer.png'
                    alt='Ryzer'
                  />
                </div>
              </div>
            </div>
          )
        }
      </Popover>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(ContactInfoPopover);
