import Column from '../../types/Column';
import { CompareResultType } from '../../organisms/ComparePlayersTable';

export const USER_SETTINGS_LOADING = 'user-settings/USER_SETTINGS_LOADING';
export const USER_SETTINGS_LOADED = 'user-settings/USER_SETTINGS_LOADED';
export const SET_SEARCH_COLUMNS = 'user-settings/SET_SEARCH_COLUMNS';
export const SET_SAVED_PLAYERS_COLUMNS = 'user-settings/SET_SAVED_PLAYERS_COLUMNS';
export const SET_TEAM_PLAYERS_COLUMNS = 'user-settings/SET_TEAM_PLAYERS_COLUMNS';
export const SET_COMPARE_PLAYERS_COLUMNS = 'user-settings/SET_COMPARE_PLAYERS_COLUMNS';
export const SET_MANAGE_USERS_COLUMNS = 'user-settings/SET_MANAGE_USERS_COLUMNS';
export const SET_MANAGE_PROGRAMS_COLUMNS = 'user-settings/SET_MANAGE_PROGRAMS_COLUMNS';
export const SET_COMPARE_PLAYERS_RESULT_TYPE = 'user-settings/SET_COMPARE_PLAYERS_RESULT_TYPE';

export function setUserSettingsLoadingAction (userSettingsLoading:boolean) {
  return {
    type: USER_SETTINGS_LOADING,
    userSettingsLoading,
  };
}

export function setUserSettingsLoadedAction (userSettingsLoaded:boolean) {
  return {
    type: USER_SETTINGS_LOADED,
    userSettingsLoaded,
  };
}

export function setSearchColumnsAction (searchColumns:Column[] | null) {
  return {
    type: SET_SEARCH_COLUMNS,
    searchColumns,
  };
}

export function setSavedPlayersColumnsAction (savedPlayersColumns:Column[] | null) {
  return {
    type: SET_SAVED_PLAYERS_COLUMNS,
    savedPlayersColumns,
  };
}

export function setTeamPlayersColumnsAction (teamPlayersColumns:Column[] | null) {
  return {
    type: SET_TEAM_PLAYERS_COLUMNS,
    teamPlayersColumns,
  };
}

export function setComparePlayersColumnsAction (comparePlayersColumns:Column[] | null) {
  return {
    type: SET_COMPARE_PLAYERS_COLUMNS,
    comparePlayersColumns,
  };
}

export function setComparePlayersResultTypeAction (comparePlayersResultType:CompareResultType | null) {
  return {
    type: SET_COMPARE_PLAYERS_RESULT_TYPE,
    comparePlayersResultType,
  };
}

export function setManageUsersColumnsAction (manageUsersColumns:Column[] | null) {
  return {
    type: SET_MANAGE_USERS_COLUMNS,
    manageUsersColumns,
  };
}

export function setManageProgramsColumnsAction (manageProgramsColumns:Column[] | null) {
  return {
    type: SET_MANAGE_PROGRAMS_COLUMNS,
    manageProgramsColumns,
  };
}
