import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import { ToastType } from '../atoms/Toast';
import { datetimeToDate } from '../services/converter';
import {
  COLOR_MIDDLE_GRAY,
  COLOR_ORANGE,
  COLOR_TEXT,
} from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';
import Team from '../types/Team';

interface UserInfoProps {
  className?: string;
  user?: User;
  nflAccess: boolean;
  nflTeams: Team[];
  collegeTeams: Team[];
  showToast: (message:any, type:ToastType) => void;
}

const LOGIN_INFO_COLUMN = {
  LAST_LOGIN_DATE: 'lastLoginDate',
  LOGINS_IN_LAST_30_DAYS: 'loginsInLast30Days',
  TOTAL_LOGINS: 'logins',
};

const LOGIN_INFO_COLUMNS = [
  LOGIN_INFO_COLUMN.LAST_LOGIN_DATE,
  LOGIN_INFO_COLUMN.LOGINS_IN_LAST_30_DAYS,
  LOGIN_INFO_COLUMN.TOTAL_LOGINS,
];

const LOGIN_INFO_COLUMN_LABEL = {
  [LOGIN_INFO_COLUMN.LAST_LOGIN_DATE]: 'Last Login',
  [LOGIN_INFO_COLUMN.LOGINS_IN_LAST_30_DAYS]: 'Logins Last 30 Days',
  [LOGIN_INFO_COLUMN.TOTAL_LOGINS]: 'Total Logins',
};

const SUBSCRIPTION_COLUMN = {
  ACCOUNT_TYPE: 'accountType',
  START_DATE: 'createdAt',
  EXPIRATION_DATE: 'expirationDate',
  API_KEY: 'apiKey',
  INVOICE: 'invoice',
  COLLEGE: 'college',
  PROGRAM: 'program',
  NFL_TEAM: 'nflTeam',
};

const SUBSCRIPTION_COLUMNS = [
  SUBSCRIPTION_COLUMN.ACCOUNT_TYPE,
  SUBSCRIPTION_COLUMN.START_DATE,
  SUBSCRIPTION_COLUMN.EXPIRATION_DATE,
  SUBSCRIPTION_COLUMN.INVOICE,
  SUBSCRIPTION_COLUMN.PROGRAM,
  SUBSCRIPTION_COLUMN.COLLEGE,
];

const SUBSCRIPTION_COLUMN_LABEL = {
  [SUBSCRIPTION_COLUMN.ACCOUNT_TYPE]: 'Account Type',
  [SUBSCRIPTION_COLUMN.START_DATE]: 'Subscription Start Date',
  [SUBSCRIPTION_COLUMN.EXPIRATION_DATE]: 'Subscription Expiration Date',
  [SUBSCRIPTION_COLUMN.API_KEY]: 'API Key',
  [SUBSCRIPTION_COLUMN.INVOICE]: 'Invoice #',
  [SUBSCRIPTION_COLUMN.COLLEGE]: 'Default College',
  [SUBSCRIPTION_COLUMN.PROGRAM]: 'Program',
  [SUBSCRIPTION_COLUMN.NFL_TEAM]: 'Default NFL Team',
};

const useStyles = makeStyles(theme => ({
  loginInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  rank: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0,
    height: '300px',
    width: '300px',
    margin: theme.spacing(2, 10, 2, 0),
    padding: theme.spacing(2),
    borderRadius: '50%',
    border: `1px solid ${COLOR_MIDDLE_GRAY}`,
    textAlign: 'center',
    fontWeight: 700,
  },
  percent: {
    color: COLOR_ORANGE,
    fontSize: theme.typography.pxToRem(48),
    lineHeight: 1,
  },
  detailsWrapper: {
    width: '100%',
  },
  loginData: {},
  subscriptionData: {},
  separator: {
    width: '100%',
    borderTop: `1px solid ${COLOR_MIDDLE_GRAY}`,
    margin: theme.spacing(2, 0),
  },
  info: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  label: {
    width: '50%',
    fontWeight: 'normal',
    textAlign: 'right',
    padding: theme.spacing(0.5),
  },
  value: {
    width: '50%',
    fontWeight: 700,
    padding: theme.spacing(0.5),
  },

  apiKey: {
    width: 170,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'help',
  },

  '@media (max-width: 1600px)': {
    rank: {
      width: '250px',
      height: '250px',
      margin: theme.spacing(0, 5, 0, 0),
    },
  },

  '@media (max-width: 1260px)': {
    rank: {
      margin: '0 auto 40px auto',
    },
  },

  [MEDIA.MOBILE]: {
    rank: {
      width: '200px',
      height: '200px',
      marginBottom: theme.spacing(2.5),
      fontSize: theme.typography.pxToRem(14),
    },

    detailsWrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },

    info: {
      justifyContent: 'flex-start',
      fontSize: theme.typography.pxToRem(14),
    },

    label: {
      paddingRight: theme.spacing(2),
      textAlign: 'left',
    },

    value: {
      wordBreak: 'break-word',
    },
  },
}), { name: MyLoginInfo.name });

export default function MyLoginInfo (props: UserInfoProps) {
  const classes = useStyles();
  const {
    className,
    user,
    nflAccess,
    collegeTeams,
    nflTeams,
    showToast,
  } = props;

  const [subscriptionColumns, setSubscriptionColumns] = useState<string[]>(SUBSCRIPTION_COLUMNS);

  useEffect(() => {
    let columns = nflAccess ? [...SUBSCRIPTION_COLUMNS, SUBSCRIPTION_COLUMN.NFL_TEAM] :  SUBSCRIPTION_COLUMNS;

    if (user?.isMaster) {
      columns = [
        ...columns.filter(columnName => columnName !== SUBSCRIPTION_COLUMN.API_KEY),
        SUBSCRIPTION_COLUMN.API_KEY,
      ];
    }

    setSubscriptionColumns(columns);
  }, [nflAccess, user?.isMaster]);

  if (!user) {
    return null;
  }

  function copyToClipboard (text:string) {
    navigator.clipboard.writeText(text);
    showToast('Copied to clipboard', ToastType.INFO);
  }

  const collegeTeam = (collegeTeams || []).find(team => team.id === user.program.collegeId)?.name;
  const nflTeam = (nflTeams || []).find(team => team.id === user.program.nflTeamId)?.name;

  const loginDetailsColumns = LOGIN_INFO_COLUMNS.map((column: string) => {
    switch (column) {
      case LOGIN_INFO_COLUMN.LAST_LOGIN_DATE:
        return {
          label: LOGIN_INFO_COLUMN_LABEL[LOGIN_INFO_COLUMN.LAST_LOGIN_DATE],
          value: datetimeToDate(user.lastLogin),
        };
      case LOGIN_INFO_COLUMN.LOGINS_IN_LAST_30_DAYS:
        return {
          label: LOGIN_INFO_COLUMN_LABEL[LOGIN_INFO_COLUMN.LOGINS_IN_LAST_30_DAYS],
          value: user.last30DaysLoginCount,
        };
      case LOGIN_INFO_COLUMN.TOTAL_LOGINS:
        return {
          label: LOGIN_INFO_COLUMN_LABEL[LOGIN_INFO_COLUMN.TOTAL_LOGINS],
          value: user.totalLogins,
        };
    }
  });

  const subscriptionColumnsToRender = subscriptionColumns.map((column:string) => {
    switch (column) {
      case SUBSCRIPTION_COLUMN.ACCOUNT_TYPE:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.ACCOUNT_TYPE],
          value: user.accessLevel.name,
        };
      case SUBSCRIPTION_COLUMN.START_DATE:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.START_DATE],
          value: (user.startDate && datetimeToDate(user.startDate))
            || (user.program.startDate && datetimeToDate(user.program.startDate))
            || datetimeToDate(user.program.createdAt),
        };
      case SUBSCRIPTION_COLUMN.EXPIRATION_DATE:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.EXPIRATION_DATE],
          value: (user.expirationDate && datetimeToDate(user.expirationDate))
            || (user.program.expirationDate && datetimeToDate(user.program.expirationDate))
            || 'Unlimited',
        };
      case SUBSCRIPTION_COLUMN.API_KEY:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.API_KEY],
          value: (user.isMaster && user.program.apiKey)
            ? <Tooltip title='Click to copy'>
                <div
                  className={classes.apiKey}
                  onClick={() => copyToClipboard(user.program.apiKey)}
                >{user.program.apiKey}</div>
              </Tooltip>
            : 'N/A',
        };
      case SUBSCRIPTION_COLUMN.INVOICE:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.INVOICE],
          value: user.program.invoice || 'N/A',
        };
      case SUBSCRIPTION_COLUMN.COLLEGE:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.COLLEGE],
          value: collegeTeam || 'N/A',
        };
      case SUBSCRIPTION_COLUMN.PROGRAM:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.PROGRAM],
          value: user.program.name || 'N/A',
        };
      case SUBSCRIPTION_COLUMN.NFL_TEAM:
        return {
          label: SUBSCRIPTION_COLUMN_LABEL[SUBSCRIPTION_COLUMN.NFL_TEAM],
          value: nflTeam || 'N/A',
        };
    }
  });

  return (
    <div className={clsx(classes.loginInfo, className)}>
      <div className={classes.rank}>
        Ranking vs. All Site Users
        <span className={classes.percent}>
          {Math.round(user.percVSUsers)}%
        </span>
      </div>

      <div className={classes.detailsWrapper}>
        <div className={classes.loginData}>
          {loginDetailsColumns.map((column:any) => (
            <div key={column.label} className={classes.info}>
              <span className={classes.label}>{column.label}:</span>
              <span className={classes.value}>{column.value}</span>
            </div>
          ))}
        </div>

        <div className={classes.separator} />

        <div className={classes.subscriptionData}>
          {subscriptionColumnsToRender.map((column:any) => (
            <div
              key={column.label}
              className={clsx(classes.info, column.className)}
            >
              <div className={classes.label}>{column.label}:</div>
              <div className={classes.value}>{column.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
