import React from 'react';
import Icon from '../atoms/Icon';

function SaveIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M0,32 L32,32 L32,6.67023482 L25.3297652,0 L0,0 L0,32 Z M2.90909091,29.0909091 L2.90909091,2.90909091 L24.1247803,2.90909091 L29.0909091,7.87521973 L29.0909091,29.0909091 L2.90909091,29.0909091 Z'/>
        <path d='M26,16 L26,32 L6,32 L6,16 L26,16 Z M23.1428571,18.9090909 L8.85714286,18.9090909 L8.85714286,29.0909091 L23.1428571,29.0909091 L23.1428571,18.9090909 Z M24.5714286,0 L24.5714286,11.6363636 L7.42857143,11.6363636 L7.42857143,0 L24.5714286,0 Z M21.7142857,2.90909091 L18.8571429,2.90909091 L18.8571429,5.81818182 L16,5.81818182 L16,2.90909091 L10.2857143,2.90909091 L10.2857143,8.72727273 L21.7142857,8.72727273 L21.7142857,2.90909091 Z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SaveIcon);
