import React from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_TEXT } from '../styles/colors';

interface TooltipProps {
  className?: string;
  title: any;
  open?: boolean;
  placement?: any;
  interactive?: boolean;
  children: React.ReactElement;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: COLOR_TEXT,
  },
  tooltip: {
    padding: theme.spacing(1, 2),
    borderRadius: '20px',
    backgroundColor: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(12),
  },
}), { name: Tooltip.name });

export default function Tooltip (props: TooltipProps) {
  const {
    className,
    title,
    open = undefined,
    placement = 'top',
    interactive = false,
    children,
    onClose = () => {},
  } = props;
  const classes = useStyles();

  return (
    <MaterialTooltip
      title={title}
      open={open}
      arrow
      placement={placement}
      className={className}
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
      }}
      interactive={interactive}
      leaveDelay={0}
      enterTouchDelay={0}
      onClose={onClose}
    >
      {children}
    </MaterialTooltip>
  );
}
