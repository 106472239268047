import React from 'react';
import Icon from '../atoms/Icon';

function MouseIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(7, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M18,9 C18,4.029 13.971,0 9,0 C4.029,0 0,4.029 0,9 C0,9.688 0,12.312 0,13 C0,17.971 4.029,22 9,22 C13.971,22 18,17.971 18,13 C18,12.312 18,9.688 18,9 Z M0,9 L18,9 M9,9 L9,0' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(MouseIcon);
