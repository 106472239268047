import {
  SET_DIV_I_TEAMS,
  SET_DIV_I_TEAMS_LOADING, 
  SET_COLLEGE_TEAMS,
  SET_COLLEGE_TEAMS_LOADING,
  SET_NFL_TEAMS,
  SET_NFL_TEAMS_LOADING,
  SET_JUCO_TEAMS,
  SET_JUCO_TEAMS_LOADING
} from '../actions/teams';
import Team from '../../types/Team';

export interface TeamsState {
  divITeams: Team[];
  divITeamsLoading: boolean;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  jucoTeams: Team[];
  jucoTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
}

const INITIAL_STATE:TeamsState = {
  divITeams: [],
  divITeamsLoading: false,
  collegeTeams: [],
  collegeTeamsLoading: false,
  jucoTeams: [],
  jucoTeamsLoading: false,
  nflTeams: [],
  nflTeamsLoading: false,
};

export function getTeamsInitialState () {
  return INITIAL_STATE;
}

export default function teamsReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_DIV_I_TEAMS:
      return {
        ...state,
        divITeams: action.divITeams,
      };
    case SET_DIV_I_TEAMS_LOADING:
      return {
        ...state,
        divITeamsLoading: action.divITeamsLoading,
      };
    case SET_COLLEGE_TEAMS:
      return {
        ...state,
        collegeTeams: action.collegeTeams,
      };
    case SET_COLLEGE_TEAMS_LOADING:
      return {
        ...state,
        collegeTeamsLoading: action.collegeTeamsLoading,
      };
    case SET_JUCO_TEAMS:
      return {
        ...state,
        jucoTeams: action.jucoTeams,
      };
    case SET_JUCO_TEAMS_LOADING:
      return {
        ...state,
        jucoTeamsLoading: action.jucoTeamsLoading,
      };
    case SET_NFL_TEAMS:
      return {
        ...state,
        nflTeams: action.nflTeams,
      };
    case SET_NFL_TEAMS_LOADING:
      return {
        ...state,
        nflTeamsLoading: action.nflTeamsLoading,
      };
    default:
      return state;
  }
};
