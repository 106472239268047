import Player from './Player';

export enum PlayerUpdateType {
  WEIGHT = 'weight',
  HEIGHT = 'height',
  TRACK_DATA = 'track-data',
  PAI_SCORE = 'pai-score',
  COMBINE_SCORE = 'combine-score',
  STAR_RATING = 'star-rating',
  OFFERS = 'offers',
  COMMITTED = 'committed',
  COMBINE_DATA = 'combine-data',
  PPI = 'ppi',
  WATCH_LIST ='watch-list',
  ALL_AMERICAN = 'all-american',
  ALL_CONFERENCE = 'all-conference'
}

export default interface PlayerUpdate {
  id: string;
  type: PlayerUpdateType;
  player: Player;
  eventDetails: any;
  createdAt: string;
  updatedAt: string;
}
