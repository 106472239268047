import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Badge from '../atoms/Badge';
import TeamLogo from '../atoms/TeamLogo';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BACKGROUND_WARM,
  COLOR_TEXT,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Team from '../types/Team';

interface TeamsListProps {
  className?: string;
  teams: Team[];
}

const useStyles = makeStyles((theme: Theme) => ({
  teamsList: {
    width: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
  },
  team: {
    width: '100%',
    background: COLOR_BACKGROUND_LIGHT,
    borderTop: `1px solid ${COLOR_BACKGROUND_WARM}`,
    fontSize: theme.typography.pxToRem(14),
    transition: 'background 0.2s',
    cursor: 'pointer',

    '&:hover': {
      background: COLOR_BACKGROUND_WARM,
    }
  },
  teamLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    minHeight: '56px',
    padding: theme.spacing(2),
    textDecoration: 'none',
  },
  teamLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40px',
    marginRight: theme.spacing(1),
  },
  teamLogo: {
    height: 'auto',
    maxWidth: '30px',
    maxHeight: '30px',
  },
  teamName: {
    fontWeight: 700,
    color: COLOR_TEXT,
  },

  players: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  playersBadge: {
    height: '24px',
    fontSize: theme.typography.pxToRem(14),
  },
  playersText: {
    marginLeft: theme.spacing(1),
    color: COLOR_TEXT,
  },

  [MEDIA.MOBILE]: {
    teamLink: {
      flexWrap: 'wrap',
    },

    teamLogoWrapper: {
      minWidth: '30px',
    },

    players: {
      width: '100%',
      marginLeft: '0',
      marginTop: theme.spacing(1),
      paddingLeft: '0',
    },
    
    playersBadge: {
      margin: '0 3px',
    },
  },

  [MEDIA.DESKTOP]: {
    playersText: {
      fontSize: theme.typography.pxToRem(14),
    },

    teamLink: {
      padding: theme.spacing(1,3,1,2),
    },

    teamLogoWrapper: {
      marginRight: theme.spacing(2.5),
    },

    teamLogo: {
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    teamLogoWrapper: {
      minWidth: '60px',
    },

    teamLink: {
      padding: theme.spacing(1,10,1,3),
    },
  },
}), { name: TeamsList.name });

export default function TeamsList (props: TeamsListProps) {
  const {
    className,
    teams = [],
  } = props;
  const classes = useStyles();

  if (!teams || !teams.length) return null;

  return (
    <ul className={clsx(classes.teamsList, className)}>
      {teams.map((team:Team, index) => (
        <li
          key={index}
          className={classes.team}
        >
          <Link
            className={classes.teamLink}
            to={`/team/${team.slug}`}
          >
            <div className={classes.teamLogoWrapper}>
              <TeamLogo
                className={classes.teamLogo}
                team={team}
              />
            </div>

            <span className={classes.teamName}>{team.name}</span>

            <div className={classes.players}>
              <Badge
                className={classes.playersBadge}
                value={team.playerCount.toString()}
              />
              <span className={classes.playersText}>Player Profiles</span>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
