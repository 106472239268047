import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TeamProfileHeader from '../organisms/TeamProfileHeader';
import TeamProfileOverview from '../organisms/TeamProfileOverview';
import TeamProfileTopPerformers from '../organisms/TeamProfileTopPerformers';
import TeamProfilePlayersTable from '../organisms/TeamProfilePlayersTable';
import TeamPositionStats from '../organisms/TeamPositionStats';
import TeamRecruitingClassStats from '../organisms/TeamRecruitingClassStats';
import TeamCurrentPlayerStats from '../organisms/TeamCurrentPlayerStats';
import PageContainer from './PageContainer';
import gql from '../services/gql';
import { updatePageTitle } from '../services/page-title';
import { State } from '../redux/reducers';
import { fetchDivITeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Team, { TEAM_TYPE } from '../types/Team';
import User from '../types/User';

interface TeamPageTemplateProps {
  user?: User;
  divITeams: Team[],
  divITeamsLoading: boolean,
  nflTeams: Team[],
  nflTeamsLoading: boolean,
  fetchDivITeams: () => void;
  fetchNFLTeams: () => void;
}

const CONFERENCE = {
  DIV_I_A: 'Div I-A',
  DIV_I_AA: 'Div I-AA',
};

export const CONFERENCE_LONG_NAME = {
  [CONFERENCE.DIV_I_A]: 'Division I (FBS)',
  [CONFERENCE.DIV_I_AA]: 'Division I (FCS)',
};

export const CONFERENCE_NAME = {
  [CONFERENCE.DIV_I_A]: 'FBS',
  [CONFERENCE.DIV_I_AA]: 'FCS',
};

const useStyles = makeStyles(theme => ({
  teamPageTemplate: {
    fontFamily: FONT_PROXIMA_NOVA,
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  content: {
    position: 'relative',
  },

  gridContainer: {
    padding: theme.spacing(0,5,5,5),
  },
  gridContainerColumn: {
    paddingLeft: theme.spacing(2.5),

    '&:first-of-type': {
      paddingLeft: 0,
    },
  },

  teamNameWrap: {
    padding: theme.spacing(0,5),
  },
  teamName: {
    margin: theme.spacing(0, 2, 1, 0),
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(30),
    color: COLOR_TEXT,
  },

  box: {
    marginTop: theme.spacing(4),
  },

  divider: {
    margin: theme.spacing(0, 5),
  },

  table: {
    margin: theme.spacing(5, 5, 0, 5),
  },

  [MEDIA.MOBILE]: {
    gridContainer: {
      padding: theme.spacing(0,2,2,2),
    },
    gridContainerColumn: {
      paddingLeft: 0,
    },

    teamNameWrap: {
      padding: theme.spacing(0,2),
    },

    divider: {
      margin: theme.spacing(0, 2),
    },

    table: {
      margin: theme.spacing(2, 2, 0, 2),
    },
  },

  [MEDIA.MOBILE_AND_TABLET]: {
    gridContainer: {
      flexDirection: 'column',
    },
    gridContainerColumn: {
      maxWidth: '100%',
      paddingLeft: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    gridContainerColumn: {
      paddingLeft: theme.spacing(5),
    },
  },
}), { name: TeamPageTemplate.name });

function TeamPageTemplate (props:TeamPageTemplateProps) {
  const {
    user,
    divITeams,
    divITeamsLoading,
    nflTeams,
    nflTeamsLoading,
    fetchDivITeams,
    fetchNFLTeams,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const { teamSlug } = useParams<any>();

  const [teamLoading, setTeamLoading] = useState<boolean>(true);
  const [team, setTeam] = useState<Team>();
  const [isNFL, setIsNFL] = useState<boolean>(false);

  useEffect(() => {
    if (user && Object.keys(user).length && team) {
      if ((team.type === 'NFL' && !user.accessLevel.accessNFLTeamPage) ||
        (team.type === 'College' && !user.accessLevel.accessCollegeTeamPage)
      ) {
        history.push(`/dashboard?no-access=${history.location.pathname}`);
      }
    }
  }, [user, team]);

  useEffect(() => {
    fetchTeam();
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      updatePageTitle(team.shortName || team.name);
    }
  }, [team]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function fetchTeam() {
    setTeamLoading(true);

    gql(`
      team(slug: "${teamSlug}") {
        id
        type
        slug
        name
        shortName
        logo247
        logoESPN
        logoAlt
        city
        state
        stateAbbr
        color
        division
        conference {
          name
          abbr
          iconUrl
          mainLevel
        }
        top5TrackFieldPerformers
        recruitingClassStats
        positionStats
        playerStats
      }
    `)
      .then((data:any) => data.team as Team)
      .then((team:Team) => {
        if (team) {
          setTeam(team);
          setIsNFL(team.type === TEAM_TYPE.NFL);

          if (team.type === TEAM_TYPE.NFL){
            fetchNFLTeams();
          } else {
            fetchDivITeams();
          }
        }
      })
      .catch(console.error)
      .finally(() => setTeamLoading(false));
  }

  const loading = teamLoading || divITeamsLoading || nflTeamsLoading;
  const teams = isNFL ? nflTeams : divITeams;

  return (
    <PageContainer className={classes.teamPageTemplate}>
      <Paper className={classes.paper}>
        <TeamProfileHeader
          loading={loading}
          user={user}
          team={team}
          teams={teams}
          onTeamSelect={(team:Team) => history.push(`/team/${team.slug}`)}
        />

        {team && (
          <div className={classes.content}>

            <div className={classes.teamNameWrap}>
              <h1 className={classes.teamName}>
                {team.name}
              </h1>
            </div>

            <Grid
              className={classes.gridContainer}
              container
            >

              <Grid
                className={classes.gridContainerColumn}
                item
                xs={6}
              >
                <TeamProfileOverview
                  className={classes.box}
                  team={team}
                  isNFL={isNFL}
                />

                {team.top5TrackFieldPerformers && (
                  <TeamProfileTopPerformers
                    className={classes.box}
                    events={team.top5TrackFieldPerformers}
                    isNFL={isNFL}
                  />
                )}
              </Grid>

              <Grid
                className={classes.gridContainerColumn}
                item
                xs={6}
              >
                <TeamCurrentPlayerStats
                  className={classes.box}
                  team={team}
                  nflAccess={isNFL}
                />

                <TeamPositionStats
                  className={classes.box}
                  team={team}
                  nflAccess={isNFL}
                />

                <TeamRecruitingClassStats
                  className={classes.box}
                  team={team}
                  nflAccess={isNFL}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <Divider
          className={classes.divider}
          variant='fullWidth'
          light
        />

        <TeamProfilePlayersTable
          className={classes.table}
          team={team}
          isNFL={isNFL}
        />
      </Paper>
    </PageContainer>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchDivITeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamPageTemplate);
