import CombineScore from './CombineScore';
import PAIScore from './PAIScore';
import HighSchool from './HighSchool';
import Player from './Player';
import { Sport } from './Sport';
import College from './College'
import NFLTeam from './NFLTeam';

export enum ComparePlayerType {
  HIGH_SCHOOL = 'HighSchool',
  COLLEGE = 'College',
  NFL = 'NFL',
}

export default interface ComparePlayer extends Player {
  id: number;
  index: number;
  slug: string;
  uniqueId: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  highSchools: HighSchool[];
  playerColleges: College[];
  playerNFLTeams: NFLTeam[];
  pai: PAIScore[];
  combine: CombineScore[];
  _247Star: number | null;
  compStar: number | null;
  multiSport: Sport[];
  percentMatch: number;
}
