import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PlayerPopover from '../molecules/PlayerPopover';
import { State } from '../redux/reducers';
import { COLOR_BLUE, COLOR_DARK_BLUE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface PlayerNameWithPopoverProps {
  className?: string;
  playerId: string | number;
  name: string;
  isNFL?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerNameWithPopover: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}), { name: PlayerNameWithPopover.name });

function PlayerNameWithPopover (props: PlayerNameWithPopoverProps) {
  const classes = useStyles();
  const {
    className,
    playerId,
    name,
    isNFL,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function onOpen (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onClose () {
    setAnchorEl(null);
  }

  return (
    <>
      <span
        className={clsx(classes.playerNameWithPopover, className)}
        onClick={onOpen}
      >
        {name}
      </span>

      <PlayerPopover
        playerId={playerId}
        isNFL={isNFL}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    isNFL: state.ui.nflAccess,
  };
};

export default connect(
  mapStateToProps,
)(PlayerNameWithPopover);