import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../redux/reducers';
import { setUserAction } from '../redux/actions/user';
import FilePicker from '../atoms/FilePicker';
import { ToastType } from '../atoms/Toast';
import UpdateProfilePhotoModal from '../molecules/UploadProfilePhotoModal';
import { uploadProfilePhoto } from '../services/upload-photo';
import gql from '../services/gql';
import User from '../types/User';

interface UpdateProfilePhotoButtonProps {
  filePickerClassName?: string;
  filePickerLabelClassName?: string;
  user?: User;
  setUserAction: (user:User) => void;
  showToast: (message:any, toastType:ToastType) => void;
}

function UpdateProfilePhotoButton (props:UpdateProfilePhotoButtonProps) {
  const {
    filePickerClassName,
    filePickerLabelClassName,
    user,
    setUserAction,
    showToast,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const [selectedPhotoFileSrc, setSelectedPhotoFileSrc] = useState<string>('');
  const [selectedPhotoFileName, setSelectedPhotoFileName] = useState<string>('');
  const [selectedPhotoFileMimeType, setSelectedPhotoFileMimeType] = useState<string>('');
  const [croppedImageBlob, setCroppedImageBlob] = useState<Blob>();

  function onFileSelect(file:any) {
    if (file) {
      setLoading(true);

      const reader = new window.FileReader();
      reader.onload = () => {
        setSelectedPhotoFileSrc(reader.result as any);
        setSelectedPhotoFileName(file.name);
        setSelectedPhotoFileMimeType(file.type);
        setDialogIsOpen(true);
        setLoading(false);
      };
      reader.readAsDataURL(file);
    }
  }

  function onSaveProfilePhoto (photoUrl:string | null) {
    if (!loading) setLoading(true);

    return gql(`
      mutation {
        saveProfilePhoto (photoUrl: ${photoUrl ? `"${photoUrl}"` : 'null'})
      }
    `)
      .then((data:any) => data.saveProfilePhoto as boolean)
      .then((success:boolean) => {
        if (success) {
          setUserAction({
            ...user,
            photoUrl,
          } as User);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => {
        setDialogIsOpen(false);
        setLoading(false);
        setSelectedPhotoFileSrc('');
        setSelectedPhotoFileName('');
        setSelectedPhotoFileMimeType('');
      });
  }

  function onCropConfirm () {
    if (croppedImageBlob) {
      setLoading(true);
      uploadProfilePhoto(croppedImageBlob, selectedPhotoFileName)
        .then(onSaveProfilePhoto)
        .catch((error) => {
          showToast(error.message, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <FilePicker
        className={filePickerClassName}
        fileInputLabelClass={filePickerLabelClassName}
        name='profile-photo'
        onChange={(file:any) => onFileSelect(file)}
      >
        Upload New Profile Photo
      </FilePicker>

      <UpdateProfilePhotoModal
        open={dialogIsOpen}
        confirmDisabled={!croppedImageBlob}
        loading={loading}
        selectedPhotoFileSrc={selectedPhotoFileSrc}
        selectedPhotoFileMimeType={selectedPhotoFileMimeType}
        onClose={() => setDialogIsOpen(false)}
        onCancel={() => {
          setSelectedPhotoFileSrc('');
          setSelectedPhotoFileName('');
          setSelectedPhotoFileMimeType('');
          setDialogIsOpen(false);
        }}
        onConfirm={onCropConfirm}
        onCrop={setCroppedImageBlob}
      />
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    { setUserAction },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateProfilePhotoButton);
