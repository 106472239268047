import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddProgramIcon from '../icons/AddProgramIcon'
import EditProgramIcon from '../icons/EditProgramIcon';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import AddEditHsCombineTypeForm from '../organisms/AddEditHsCombineTypeForm';
import PageContainer from './PageContainer';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';
import HsCombineType from '../types/HsCombineType';

interface AddCombinePageTemplateProps {
  user?: User;
  collegeTeamsLoading: boolean;
  nflTeamsLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  addProgramPageTemplate: {
    minHeight: '100vh',
    position: 'relative',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(5, 5, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  switchWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(8),
    marginRight: 'auto',
  },
  switchValue: {
    color: COLOR_TEXT,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  addProgram: {
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(5),
  },

  [MEDIA.MOBILE]: {
    header: {
      display: 'block',
      margin: theme.spacing(2, 2, 0),
    },

    switchWrapper: {
      margin: theme.spacing(2, 0, 0),
    },

    addProgram: {
      padding: theme.spacing(2),
    }
  },
}), { name: AddCombinePageTemplate.name });

function AddCombinePageTemplate (props:AddCombinePageTemplateProps) {
  const classes = useStyles();

  const history = useHistory();
  const { combinesId } = useParams<any>();

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [hsCombineType, setHsCombineType] = useState<HsCombineType>();

  useEffect(() => {
    if (history.location.pathname.startsWith('/manage/combines/edit')) {
      setIsEditMode(true);
      fetchHsCombineTypeById();
    }
  }, []);

  function fetchHsCombineTypeById () {
    setPageLoading(true);

    gql(`
      hsCombineType (id: ${combinesId}) {
        id
        type
        teamCamp
        isAccessControlled
        programIds
      }
    `)
      .then((data:any) => data.hsCombineType as HsCombineType)
      .then((hsCombineType:HsCombineType) => hsCombineType && setHsCombineType(hsCombineType))
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const loading = pageLoading;

  return (
    <PageContainer className={classes.addProgramPageTemplate}>
      <Loader inProgress={loading} />

      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            {isEditMode ?
              <EditProgramIcon className={classes.headerIcon} />
            :
              <AddProgramIcon className={classes.headerIcon} />
            }
            {isEditMode ? 'Edit Hs Combine Type' : 'Add Hs Combine Type'}
          </h1>
        </div>

        <AddEditHsCombineTypeForm
          className={classes.addProgram}
          hsCombineType={hsCombineType}
          isEditMode={isEditMode}
          loading={loading}
          setLoading={setPageLoading}
        />
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </PageContainer>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

export default connect(
  mapStateToProps,
)(AddCombinePageTemplate);
