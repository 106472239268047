import React from 'react';
import Icon from '../atoms/Icon';

function DoneIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(0.5, 0.5) scale(1.2)'>
          <polyline
            stroke='currentColor'
            strokeWidth='2'
            points='25 5 13 17 8 12'
          />
          <path
            d='M23.7,10.5 C23.9,11.3 24,12.1 24,13 C24,19.1 19.1,24 13,24 C6.9,24 2,19.1 2,13 C2,6.9 6.9,2 13,2 C16,2 18.7,3.2 20.6,5.1 L22,3.7 C19.7,1.4 16.5,0 13,0 C5.8,0 0,5.8 0,13 C0,20.2 5.8,26 13,26 C20.2,26 26,20.2 26,13 C26,11.6 25.8,10.2 25.3,8.9 L23.7,10.5 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(DoneIcon);
