import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import RemoveIcon from '../icons/RemoveIcon';
import SettingsGearIcon from '../icons/SettingsGearIcon';
import Tooltip from '../atoms/Tooltip';
import Avatar from '../atoms/Avatar';
import Switch from '../atoms/Switch';
import Toast, { ToastType } from '../atoms/Toast';
import ChangePasswordButton from './ChangePasswordButton';
import UpdateProfilePhotoButton from './UpdateProfilePhotoButton';
import { State } from '../redux/reducers';
import { AchievementsVisibility } from '../redux/reducers/achievements';
import { hideAchievements, showAchievements } from '../redux/dispatchers/achievements';
import { setUserAction } from '../redux/actions/user';
import gql from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_DARK_GRAY, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';

interface UserInfoProps {
  className?: string;
  user?: User;
  adminAccess?: boolean;
  nflAccess?: boolean;
  achievementsVisibility?: AchievementsVisibility;
  showAchievements: () => void;
  hideAchievements: () => void;
  setUserAction: (user:User) => void;
}

const useStyles = makeStyles(theme => ({
  userInfo: {
    position: 'relative',
  },
  infoWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5,5,0,5),
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(0),
    padding: theme.spacing(0, 5),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  avatarWrapper: {
    position: 'relative',
  },
  avatar: {
    width: '122px',
    height: '122px',
  },

  textInfo: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    ...theme.typography.h2,
  },
  title: {
    marginTop: '4px',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    fontWeight: 'bold',
    color: COLOR_DARK_GRAY,
  },
  email: {
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
  button: {
    width: '100%',
    maxWidth: '240px',
    height: '50px',
    marginTop: theme.spacing(2.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
  },

  uploadPhotoButton: {
    width: '240px',
  },
  uploadPhotoLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    border: `2px solid ${COLOR_DARK_GRAY}`,
    borderRadius: '6px',
    background: COLOR_WHITE,
    color: COLOR_TEXT,
    cursor: 'pointer',
    outlineColor: COLOR_TEXT,
    textDecoration: 'none',
    transition: 'background 0.2s, color 0.2s, border-color 0.2s',

    '&:hover': {
      borderColor: COLOR_TEXT,
      background: COLOR_TEXT,
      color: COLOR_WHITE,
    },
  },

  switchWrapper: {
    width: '240px',
    display: 'flex',
    marginTop: theme.spacing(5),
    padding: theme.spacing(0, 0, 0, 1),
  },
  switchValue: {
    color: COLOR_TEXT,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
  },

  removeIconButton: {
    appearance: 'none',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    border: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0)',
    transition: 'background 0.3s',
    cursor: 'pointer',
    borderRadius: '50%',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.5)',

      '& $removeIcon': {
        opacity: 1,
      },
    },
  },
  removeIcon: {
    width: '60px',
    height: '60px',
    color: COLOR_TEXT,
    opacity: 0,
    transition: 'color 0.3s, opacity 0.2s',
  },

  [MEDIA.MOBILE]: {
    headerTitle: {
      marginBottom: 0,
      padding: theme.spacing(0, 2),
    },

    infoWrapper: {
      padding: theme.spacing(2,2,0,2),
    },

    switchWrapper: {
      marginTop: theme.spacing(2),
    },
  },
}), { name: UserInfo.name });

function UserInfo (props: UserInfoProps) {
  const {
    className,
    user,
    nflAccess = false,
    achievementsVisibility,
    showAchievements = () => {},
    hideAchievements = () => {},
    setUserAction = () => {},
  } = props;
  const classes = useStyles();

  const [emailNotification, setEmailNotification] = useState<boolean>(!(user && user.isEmailDisabled) || false);
  const [optOut, setOptOut] = useState<boolean>( user?.isSMSOptedOut || false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  if (!user) return null;

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onRemoveProfilePhoto () {
    return gql(`
      mutation {
        saveProfilePhoto (photoUrl: null)
      }
    `)
      .then((data:any) => data.saveProfilePhoto as boolean)
      .then((success:boolean) => {
        if (success) {
          setUserAction({
            ...user,
            photoUrl: null,
          } as User);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      });
  }

  function onEmailSubscribeChange () {
    return gql(`
      mutation {
        subscribeEmail(isEmailDisabled: ${emailNotification})
      }
    `)
      .then((data:any) => data.subscribeEmail as boolean)
      .then((success:boolean) => {
        if (success) {
          setEmailNotification(!emailNotification);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function onOptIn () {
    setOptOut(true);

    return gql(`
      mutation {
        optInSMS
      }
    `)
      .then((data:any) => data.optInSMS as boolean)
      .then((success:boolean) => {
        if (!success) {
          setOptOut(false);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        setOptOut(false);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function onOptOut () {
    setOptOut(false);

    return gql(`
      mutation {
        optOutSMS(email: "${user?.email}")
      }
    `)
      .then((data:any) => data.optOutSMS as boolean)
      .then((success:boolean) => {
        if (!success) {
          setOptOut(true);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        setOptOut(true);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  const achievementsEnabled = achievementsVisibility === AchievementsVisibility.OPENED
    || achievementsVisibility === AchievementsVisibility.MINIMIZED;

  return (
    <>
      <div className={clsx(classes.userInfo, className)}>
        <h2 className={classes.headerTitle}>
          <SettingsGearIcon  className={classes.headerIcon} />
          My Account
        </h2>

        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            <div className={classes.avatarWrapper}>
              <Avatar
                className={classes.avatar}
                src={user.photoUrl}
                alt={`Photo of ${user.firstName} ${user.lastName}`}
                initials={`${(user.firstName || ' ')[0]}${(user.lastName || ' ')[0]}`.trim()}
              />

              {!!user.photoUrl && (
                <Tooltip title='Remove profile photo'>
                  <button
                    className={classes.removeIconButton}
                    onClick={onRemoveProfilePhoto}
                  >
                    <RemoveIcon className={classes.removeIcon} />
                  </button>
                </Tooltip>
              )}
            </div>

            <div className={classes.textInfo}>
              <div className={classes.name}>{user.firstName} {user.lastName}</div>
              <div className={classes.title}>{user.designation}</div>
              <div className={classes.email}>{user.email}</div>
            </div>
          </div>

          <UpdateProfilePhotoButton
            filePickerClassName={classes.uploadPhotoButton}
            filePickerLabelClassName={clsx(classes.button, classes.uploadPhotoLabel)}
            showToast={showToast}
          />

          <ChangePasswordButton
            className={classes.button}
            showToast={showToast}
          />

          {!nflAccess && (
            <div className={classes.switchWrapper}>
              <Switch
                checked={emailNotification}
                onChange={onEmailSubscribeChange}
              />

              <span className={classes.switchValue}>
                Email Notifications
                <strong>{emailNotification ? 'On' : 'Off'}</strong>
              </span>
            </div>
          )}

          <div className={classes.switchWrapper}>
            <Switch
              checked={optOut}
              onChange={optOut ? onOptOut : onOptIn}
            />

            <span className={classes.switchValue}>
              Opt out of SMS
              <strong>{optOut ? 'On' : 'Off'}</strong>
            </span>
          </div>

          <div className={classes.switchWrapper}>
            <Switch
              checked={achievementsEnabled}
              onChange={() => {
                if (achievementsEnabled) {
                  hideAchievements();
                } else {
                  showAchievements();
                }
              }}
            />

            <span className={classes.switchValue}>
              Achievements
              <strong>{achievementsEnabled ? 'On' : 'Off'}</strong>
            </span>
          </div>
        </div>
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    achievementsVisibility: state.achievements.achievementsVisibility,
  };
};
const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      showAchievements,
      hideAchievements,
      setUserAction,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
