import React from 'react';
import Icon from '../atoms/Icon';

function BaseballIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(5, 5)'>
          <path
            d='M8,0 C6.8954305,0 6,0.8954305 6,2 C6,3.1045695 6.8954305,4 8,4 C9.1045695,4 10,3.1045695 10,2 C10,0.8954305 9.1045695,0 8,0 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M20.995,2.012 C20.126,1.088 19.026,0.742 18.562,1.204 L11.256,8.608 C10.329,9.532 9.461,10.513 8.708,11.553 L5.354,17.392 L5.578,17.612 L11.488,14.325 C12.531,13.574 13.515,12.708 14.442,11.785 L21.806,4.439 C22.27,3.975 21.864,2.936 20.995,2.012 Z M1,22 L5.486,17.514'
            stroke='currentColor'
            strokeWidth='2'
          />
          <line
            x1='0'
            y1='21'
            x2='2'
            y2='23'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <line
            x1='10.816'
            y1='8.676'
            x2='14.248'
            y2='12.109'
            stroke='currentColor'
            strokeWidth='2'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(BaseballIcon);
