import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import Loader from '../atoms/Loader';
import gql from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface StaffRecordTooltipProps {
  userId: number;
  children: any;
}

interface UserTooltip {
  userId: number;
  savedPlayers: number;
  alertListPlayers: number;
  pinnedPlayers: number;
  exportsCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  staffRecordTooltip: {
    position: 'relative',
  },

  tooltipContent: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    whiteSpace: 'pre',
  },
}), { name: StaffRecordTooltip.name });

export default function StaffRecordTooltip (props: StaffRecordTooltipProps) {
  const {
    userId,
    children,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userTooltip, setUserTooltip] = useState<UserTooltip>();

  useEffect(() => {
    if (userId && open) {
      fetchUserTooltipContent();
    }
  }, [userId, open]);

  function fetchUserTooltipContent () {
    if (!loading) setLoading(true);

    gql(`
      userTooltip(userId: ${userId}) {
        userId
        savedPlayers
        alertListPlayers
        pinnedPlayers
        exportsCount
      }
    `)
      .then((data:any) => data.userTooltip as UserTooltip)
      .then((tooltipData:UserTooltip) => setUserTooltip(tooltipData))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  return (
    <Tooltip
      className={classes.staffRecordTooltip}
      open={open}
      title={(
        <>
          <Loader inProgress={loading} />

          {userTooltip && (
            <div className={classes.tooltipContent}>
              <div className={classes.text}>
                <strong>{userTooltip.savedPlayers || 0} </strong>Saved Players
              </div>

              <div className={classes.text}>
                <strong>{userTooltip.alertListPlayers || 0} </strong>Alert List Players
              </div>

              <div className={classes.text}>
                <strong>{userTooltip.pinnedPlayers || 0} </strong>Pinned Players
              </div>

              <div className={classes.text}>
                <strong>{userTooltip.exportsCount || 0} </strong>Exports Players
              </div>
            </div>
          )}
        </>
      )}
      interactive
      placement='top'
      onClose={() => setOpen(false)}
    >
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
    </Tooltip>
  );
}
