import React from 'react';
import Icon from '../atoms/Icon';

function StarIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <path 
        d='M32 12.46l-11.352-1.182L16 1l-4.648 10.278L0 12.459l8.48 7.533L6.113 31 16 25.377 25.887 31 23.52 19.992 32 12.459zM16 22.836l-6.666 3.79 1.596-7.422-5.717-5.077 7.653-.796L16 6.404l3.133 6.929 7.653.796-5.717 5.077 1.596 7.422L16 22.837z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </Icon>
  );
}

export default React.forwardRef(StarIcon);