import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CoachIcon from '../icons/CoachIcon';
import Badge from '../atoms/Badge';
import Loader from '../atoms/Loader';
import TeamLogo from '../atoms/TeamLogo';
import TabulatedPlayersList from '../organisms/TabulatedPlayersList';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import { isDuringRollover } from '../services/user';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { setSelectedCollegeTeam, setSelectedNFLTeam } from '../redux/dispatchers/ui';
import { COLOR_LIGHT_ORANGE, COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import Team, { TEAM_TYPE } from '../types/Team';
import DashboardStats, { DashboardStatsNFL, HSTractDashboardStats} from '../types/DashboardStats';
import DashboardPlayer from '../types/DashboardPlayer';
import { HSYearsAccessType } from '../types/AccessLevel';
import User from '../types/User';

interface DashboardTeamSelectProps {
  className?: string;
  teams: Team[];
  selectedCollegeTeam: Team | undefined;
  selectedNFLTeam: Team | undefined;
  isNFL?: boolean;
  nflDraftYear?: number;
  user?: User;
  rolloverDay: number;
  rolloverMonth: number;
  setSelectedCollegeTeam: (selectedCollegeTeam:Team) => void;
  setSelectedNFLTeam: (selectedNFLTeam:Team) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dashboardTeamSelect: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    padding: theme.spacing(3),
    position: 'relative',
  },
  isNFL: {
    '& $headerLabel': {
      marginTop: theme.spacing(4.5),
      ...theme.typography.h2,
    },
  },

  header: {
    display: 'flex',
  },

  headerColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(5),

    // '&:last-child': {
    '&:nth-of-type(even)': {
      paddingRight: 0,
    },
  },
  headerRow: {
    display: 'flex',
    marginTop: '5px',
  },

  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  headerTitleIcon: {
    width: '27px',
    height: '27px',
  },

  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },
  headerLabel: {
    color: COLOR_TEXT,
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: 600,
    lineHeight: 1,

    '&:first-of-type': {
      marginTop: 0,
    },
  },

  teamAutoComplete: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(4, 0, 0, 0),
  },
  teamAutoCompleteInput: {
    height: 110,
    boxSizing: 'border-box',
  },

  teamLogo: {
    width: '100%',
    maxWidth: '120px',
    height: 'auto',
    margin: theme.spacing(3, 'auto', 0),
  },

  badgeLink: {
    marginLeft: theme.spacing(1),
    textDecoration: 'none',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',
  },
  badge: {
    minHeight: '30px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    transition: 'background 0.2s',

    '&:hover': {
      backgroundColor: COLOR_LIGHT_ORANGE,
    },
  },

  tablesRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },

  playersList: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  watchList: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },

  watchListLogo: {
    width: '10%',
    height: 'auto',
  },

  alignCenter: {
    alignItems: 'center',
    // marginTop: theme.spacing(3),
  },

  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
  [MEDIA.DESKTOP_LARGE]: {
    playersList: {
      width: '50%',
      marginTop: theme.spacing(5),
      paddingRight: theme.spacing(2),

      // '&:last-child': {
      //   paddingRight: 0,
      // },

      '&:nth-of-type(even)': {
        paddingRight: 0,
      },
    },
  },

  '@media (min-width:1801px)': {
    teamSelect: {
      padding: theme.spacing(5),
    },

    playersList: {
      paddingRight: theme.spacing(5),
    },
  },

  [MEDIA.MOBILE]: {
    dashboardTeamSelect: {
      padding: theme.spacing(2),
    },

    header: {
      flexWrap: 'wrap',
    },

    headerColumn: {
      width: '100%',
      paddingRight: 0,
      marginBottom: theme.spacing(4),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    teamAutoComplete: {
      marginTop: theme.spacing(2),
    },
    teamAutoCompleteInput: {
      height: 'auto',
    },
    teamLogo: {
      display: 'none',
    },
  },
}), { name: DashboardTeamSelect.name });

function DashboardTeamSelect (props:DashboardTeamSelectProps) {
  const {
    className,
    teams = [],
    selectedCollegeTeam,
    selectedNFLTeam,
    isNFL,
    nflDraftYear,
    user,
    rolloverDay,
    rolloverMonth,
    setSelectedCollegeTeam,
    setSelectedNFLTeam,
  } = props;
  const classes = useStyles();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [hsTrackloading, setHsTrackLoading] = useState<boolean>(false);
  const [dashboardStats, setDashboardStats] = useState<DashboardStats>();
  const [hsTrackStats, setHsTrackStats] = useState<HSTractDashboardStats>();
  const [dashboardStatsNFL, setDashboardStatsNFL] = useState<DashboardStatsNFL>();
  const [userHasAccessOnlyToJuniorHSYears, setUserHasAccessOnlyToJuniorHSYears] = useState<boolean>(true);
  const [selectedTrack, setSelectedTrack] = useState<string>('event_100m');

  useEffect(() => {
    if (selectedCollegeTeam?.id && !isNFL) {
      fetchDashboardStats(selectedCollegeTeam.id);
      fetchDashboardStatsForTopHSTrackPerformer(selectedTrack);
    } else if (selectedNFLTeam?.id && isNFL) {
      fetchDashboardStatsNFL(selectedNFLTeam.id);
      fetchDashboardStats(selectedNFLTeam.id);
    }
  }, [selectedCollegeTeam?.id, selectedNFLTeam?.id, isNFL]);

  useEffect(() => {
    let userAccessYears:HSYearsAccessType[] = user?.accessLevel?.hsYearsAugust1 || [];
    if (isDuringRollover(rolloverDay, rolloverMonth)) {
      userAccessYears = user?.accessLevel?.hsYearsMarch1 || [];
    }

    setUserHasAccessOnlyToJuniorHSYears(userAccessYears.length === 1 && userAccessYears[0] === HSYearsAccessType.JUNIOR);
  }, [user?.id]);

  useEffect(() => {
    fetchDashboardStatsForTopHSTrackPerformer(selectedTrack);
  }, [selectedTrack]);

  if (!user || !Object.keys(user).length) {
    return <Loader inProgress />;
  }

  function fetchDashboardStats (teamId:number) {
    setLoading(true);

    gql(`
      dashboardStats(teamId: ${teamId}) {
        id
        offerCounts {
          year
          count
        }
        commitCounts {
          year
          count
        }
        speedRecruitsCounts {
          year
          count
        }
        powerRecruitsCounts {
          year
          count
        }
        inStatePlayersCounts {
          year
          count
        }
        rosterCounts {
          isCurrent
          count
        }
        commitLists {
          year
          list {
            id
            firstName
            lastName
            pai
            combine
            highSchools
            hsCombines {
              id
            }
          }
        }
        offerLists {
          year
          list {
            id
            firstName
            lastName
            pai
            combine
            highSchools
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
          }
        }
        speedRecruitsLists {
          year
          list {
            id
            firstName
            lastName
            pai
            combine
            highSchools
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
          }
        }
        instatePlayerLists {
          year
          list {
            id
            firstName
            lastName
            pai
            combine
            highSchools
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
          }
        }
        powerRecruitsLists {
          year
          list {
            id
            firstName
            lastName
            pai
            combine
            highSchools
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
          }
        }
      }
    `)
      .then((data:any) => data.dashboardStats as DashboardStats)
      .then((dashboardStats:DashboardStats) => setDashboardStats(dashboardStats))
      .then(() => setLoading(false))
      .catch(console.error);
  }

  function fetchDashboardStatsForTopHSTrackPerformer (event:string) {
    setHsTrackLoading(true);

    gql(`
      dashboardHSTrackStats(event: "${event}") {
        topHsTrackPlayerCounts {
          year
          count
        }
        topHsTrackPlayerLists {
          year
          list {
            id
            firstName
            lastName
            pai
            bestTrackEvent
            bestHighSchoolMPH {
            mph
            isBest
            }
            combine
            highSchools
            hsCombines {
              id
            }
          }
        }
      }
    `)
      .then((data:any) => data.dashboardHSTrackStats as HSTractDashboardStats)
      .then((dashboardStats:HSTractDashboardStats) => {
        setHsTrackStats(dashboardStats);
      })
      .then(() => setHsTrackLoading(false))
      .catch(console.error);
  }

  function fetchDashboardStatsNFL (teamId:number) {
    setLoading(true);

    gql(`
    dashboardStatsNFL(teamId: ${teamId}) {
        id
        draftedCounts {
          year
          count
        }
        udfaCounts {
          year
          count
        }
        speedRecruitsCounts {
          year
          count
        }
        powerRecruitsCounts {
          year
          count
        }
        draftedLists {
          year
          list {
            id
            firstName
            lastName
            pai
            ppi
            combine
            nflCombineScore
            nflCombines {
              id
            }
            playerColleges {
              team {
                name
              }
              positions
              isPrimary
            }
            highSchools
            hsCombines {
              id
            }
            playerNFLTeams {
              draftRound
              isPrimary
              positions
            }
          }
        }
        udfaLists {
          year
          list {
            id
            firstName
            lastName
            pai
            ppi
            combine
            nflCombineScore
            nflCombines {
              id
            }
            highSchools
            playerColleges {
              team {
                name
              }
              positions
              isPrimary
            }
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
            playerNFLTeams {
              draftRound
              isPrimary
              positions
            }
          }
        }
        speedRecruitsLists {
          year
          list {
            id
            firstName
            lastName
            pai
            ppi
            combine
            nflCombineScore
            nflCombines {
              id
            }
            highSchools
            playerColleges {
              team {
                name
              }
              positions
              isPrimary
            }
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
            playerNFLTeams {
              draftRound
              isPrimary
              positions
            }
          }
        }
        powerRecruitsLists {
          year
          list {
            id
            firstName
            lastName
            pai
            ppi
            combine
            nflCombineScore
            nflCombines {
              id
            }
            highSchools
            playerColleges {
              team {
                name
              }
              positions
              isPrimary
            }
            committedToTeam {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
            hsCombines {
              id
            }
            playerNFLTeams {
              draftRound
              isPrimary
              positions
            }
          }
        }
      }
    `)
      .then((data:any) => data.dashboardStatsNFL as DashboardStatsNFL)
      .then((dashboardStats:DashboardStatsNFL) => setDashboardStatsNFL(dashboardStats))
      .then(() => setLoading(false))
      .catch(console.error);
  }

  function preventClick (event:any) {
    event.preventDefault();
  }

  return (
    <Paper
      className={clsx(
        classes.dashboardTeamSelect,
        isNFL && classes.isNFL,
        className,
      )}
    >
      <Loader inProgress={loading} />

      <div className={classes.header}>
        {((isNFL && user.accessLevel.canTeamSelectNFL) || (!isNFL && user.accessLevel.canTeamSelectCollege)) && (
          <div className={classes.headerColumn}>
            <div className={classes.headerTitleContainer}>
              <CoachIcon className={classes.headerTitleIcon}/>

              <h2 className={classes.headerTitle}>Football TeamSelect:</h2>
            </div>

            <TeamAutoComplete
              className={classes.teamAutoComplete}
              inputClassName={classes.teamAutoCompleteInput}
              teams={teams}
              selectedTeam={isNFL ? selectedNFLTeam : selectedCollegeTeam}
              onSelect={(team:Team | null) => {
                if (team?.type === TEAM_TYPE.NFL) {
                  setSelectedNFLTeam(team);
                } else if (team?.type === TEAM_TYPE.COLLEGE) {
                  setSelectedCollegeTeam(team);
                }
              }}
            />

            {(isNFL && selectedNFLTeam) && (
              <TeamLogo
                className={classes.teamLogo}
                team={selectedNFLTeam}
              />
            )}
          </div>
        )}

        <div className={classes.headerColumn}>
          {(!isNFL && dashboardStats) && (
            <>
              {(dashboardStats.offerCounts && dashboardStats.offerCounts.length) && (
                <>
                  <h4 className={classes.headerLabel}>Offers</h4>
                  <div className={classes.headerRow}>
                    {dashboardStats.offerCounts.map((offer, index:number) => {
                      const disabled = index > 0 && userHasAccessOnlyToJuniorHSYears;

                      return (
                        <Link
                          key={offer.year}
                          className={clsx(
                            classes.badgeLink,
                            disabled && classes.disabled,
                          )}
                          to={(selectedCollegeTeam && !disabled)
                            ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&offers=true&hs-class-year=${offer.year}&sort-by=pai`
                            : ''
                          }
                          onClick={disabled ? preventClick : undefined}
                        >
                          <Badge
                            className={classes.badge}
                            value={`${offer.year} (${offer.count})`}
                          />
                        </Link>
                      );
                    })}

                    <Link
                      key='all'
                      className={classes.badgeLink}
                      to={selectedCollegeTeam
                        ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&offers=true&sort-by=pai`
                        : ''
                      }
                    >
                      <Badge
                        key='all'
                        className={classes.badge}
                        value='All'
                      />
                    </Link>
                  </div>
                </>
              )}

              {(dashboardStats.commitCounts && dashboardStats.commitCounts.length) && (
                <>
                  <h4 className={classes.headerLabel}>Commits</h4>
                  <div className={classes.headerRow}>
                    {dashboardStats.commitCounts.map((commit, index:number) => {
                      const disabled = index > 0 && userHasAccessOnlyToJuniorHSYears;

                      return (
                        <Link
                          key={commit.year}
                          className={clsx(classes.badgeLink, disabled && classes.disabled)}
                          to={(selectedCollegeTeam && !disabled)
                            ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&commits=true&hs-class-year=${commit.year}&sort-by=pai`
                            : ''
                          }
                          onClick={disabled ? preventClick : undefined}
                        >
                          <Badge
                            className={classes.badge}
                            value={`${commit.year} (${commit.count})`}
                          />
                        </Link>
                      );
                    })}

                    <Link
                      key='all'
                      className={classes.badgeLink}
                      to={selectedCollegeTeam ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&commits=true&sort-by=pai` : ''}
                    >
                      <Badge
                        key='all'
                        className={classes.badge}
                        value='All'
                      />
                    </Link>
                  </div>
                </>
              )}

              {(dashboardStats?.rosterCounts?.length && user.accessLevel.hasCollegeAccess) && (
                <>
                  <h4 className={classes.headerLabel}>Roster</h4>
                  <div className={classes.headerRow}>
                    <Link
                      className={classes.badgeLink}
                      to={selectedCollegeTeam ? `/team/${selectedCollegeTeam.slug}?roster=current` : ''}
                    >
                      <Badge
                        className={classes.badge}
                        value={`Current (${(dashboardStats.rosterCounts.find(count => count.isCurrent) || {}).count || 0})`}
                      />
                    </Link>

                    <Link
                      className={classes.badgeLink}
                      to={selectedCollegeTeam ? `/team/${selectedCollegeTeam.slug}?roster=former` : ''}
                    >
                      <Badge
                        className={classes.badge}
                        value={`Former (${(dashboardStats.rosterCounts.find(count => !count.isCurrent) || {}).count || 0})`}
                      />
                    </Link>

                    <Link
                      className={classes.badgeLink}
                      to={selectedCollegeTeam ? `/team/${selectedCollegeTeam.slug}?roster=all` : ''}
                    >
                      <Badge className={classes.badge} value='All' />
                    </Link>
                  </div>
                </>
              )}
            </>
          )}

          {(isNFL && nflDraftYear) && (
            <>
              <h4 className={classes.headerLabel}>Draft</h4>
              <div className={classes.headerRow}>
                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?draft-year=${nflDraftYear}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?draft-year=${nflDraftYear - 1}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear - 1} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?draft-year=${nflDraftYear - 2}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear - 2} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?draft=true` : ''}
                >
                  <Badge className={classes.badge} value='All' />
                </Link>
              </div>

              <h4 className={classes.headerLabel}>UDFA</h4>
              <div className={classes.headerRow}>
                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?udfa-year=${nflDraftYear}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?udfa-year=${nflDraftYear - 1}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear - 1} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?udfa-year=${nflDraftYear - 2}` : ''}
                >
                  <Badge className={classes.badge} value={nflDraftYear - 2} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam ? `/team/${selectedNFLTeam.slug}?udfa=true` : ''}
                >
                  <Badge className={classes.badge} value='All' />
                </Link>
              </div>

              <h4 className={classes.headerLabel}>Watch List</h4>
              <div className={clsx(classes.headerRow, classes.alignCenter)}>
                <img
                  className={clsx(classes.watchListLogo, className)}
                  src='/images/watch-list/senior-bowl.png'
                  alt='asds'
                />
                <h4 className={clsx(classes.headerLabel, classes.paddingLeft)}>Senior Bowl</h4>
              </div>
              <div className={classes.headerRow}>
                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear+1}&watch-list-type=seniorBowl`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear + 1} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear}&watch-list-type=seniorBowl`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={`/search?watch-list=true&&watch-list-type=seniorBowl`}
                >
                  <Badge className={classes.badge} value='All' />
                </Link>
              </div>

              <div className={clsx(classes.headerRow, classes.alignCenter)}>
              <img
                className={clsx(classes.watchListLogo, className)}
                src='/images/watch-list/east-west-shrine.png'
                alt='asds'
              />
              <h4 className={clsx(classes.headerLabel, classes.paddingLeft)}>East/West Shrine</h4>
              </div>
              <div className={classes.headerRow}>
                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear+1}&watch-list-type=eastWestShrine`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear + 1} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear}&watch-list-type=eastWestShrine`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={`/search?watch-list=true&watch-list-type=eastWestShrine`}
                >
                  <Badge className={classes.badge} value='All' />
                </Link>
              </div>

              <div className={clsx(classes.headerRow, classes.alignCenter)}>
                <img
                  className={clsx(classes.watchListLogo, className)}
                  src='/images/watch-list/cgs.png'
                  alt='asds'
                />
                <h4 className={clsx(classes.headerLabel, classes.paddingLeft)}>CGS</h4>
              </div>
              <div className={classes.headerRow}>
                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear+1}&watch-list-type=cgsShowcase`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear + 1} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={selectedNFLTeam
                    ? `/search?watch-list=true&watch-list-year=${nflDraftYear}&watch-list-type=cgsShowcase`
                    : ''
                  }
                >
                  <Badge className={classes.badge} value={nflDraftYear} />
                </Link>

                <Link
                  className={classes.badgeLink}
                  to={`/search?watch-list=true&watch-list-type=cgsShowcase`}
                >
                  <Badge className={classes.badge} value='All' />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      {(!isNFL && dashboardStats) && (
        <div className={classes.tablesRow}>
          {dashboardStats.commitLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='Commits'
              showIcon='commits'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&commits=true&hs-class-year=${dashboardStats.commitLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStats.commitLists[0].year]: (dashboardStats.commitLists[0].list || []) as DashboardPlayer[],
                [dashboardStats.commitLists[1].year]: (dashboardStats.commitLists[1].list || []) as DashboardPlayer[],
              }}
              counts={dashboardStats.commitCounts}
            />
          )}

          {dashboardStats.speedRecruitsLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='Speed Recruits'
              showIcon='speed'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&speed-picks=true&hs-class-year=${dashboardStats.speedRecruitsLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStats.speedRecruitsLists[0].year]: (dashboardStats.speedRecruitsLists[0].list || [])  as DashboardPlayer[],
                [dashboardStats.speedRecruitsLists[1].year]: (dashboardStats.speedRecruitsLists[1].list || [])  as DashboardPlayer[],
              }}
              counts={dashboardStats.speedRecruitsCounts}
              showStatus
            />
          )}
        </div>
      )}

      {(!isNFL && dashboardStats) && (
        <div className={classes.tablesRow}>
          {dashboardStats.offerLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='Offers'
              showIcon='offers'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&offers=true&hs-class-year=${dashboardStats.offerLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStats.offerLists[0].year]: (dashboardStats.offerLists[0].list || [])  as DashboardPlayer[],
                [dashboardStats.offerLists[1].year]: (dashboardStats.offerLists[1].list || [])  as DashboardPlayer[],
              }}
              counts={dashboardStats.offerCounts}
              showStatus
            />
          )}

          {dashboardStats.powerRecruitsLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              showIcon='power'
              name='Power Recruits'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&power-picks=true&hs-class-year=${dashboardStats.powerRecruitsLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStats.powerRecruitsLists[0].year]: (dashboardStats.powerRecruitsLists[0].list || [])  as DashboardPlayer[],
                [dashboardStats.powerRecruitsLists[1].year]: (dashboardStats.powerRecruitsLists[1].list || [])  as DashboardPlayer[],
              }}
              counts={dashboardStats.powerRecruitsCounts}
              showStatus
            />
          )}

          {dashboardStats.instatePlayerLists?.length === 2 && (
             <TabulatedPlayersList
               className={classes.playersList}
               name='In-State Players'
               showIcon='inState'
               getSeeMoreUrl={(selectedTabIndex: number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&hs-class-year=${dashboardStats.instatePlayerLists[selectedTabIndex].year}&in-state-player=true&state=${selectedCollegeTeam.stateAbbr}&sort-by=pai`
                 : ''
               }
               tabs={{
                [dashboardStats.instatePlayerLists[0].year]: (dashboardStats.instatePlayerLists[0].list || []) as DashboardPlayer[],
                [dashboardStats.instatePlayerLists[1].year]: (dashboardStats.instatePlayerLists[1].list || []) as DashboardPlayer[],
               }}
              counts={dashboardStats.inStatePlayersCounts}
              showStatus
             />
           )}

          {hsTrackStats?.topHsTrackPlayerLists?.length === 2 && (
             <TabulatedPlayersList
               className={classes.playersList}
               showIcon='trackers'
               name='Top HS Track & MPH'
               getSeeMoreUrl={(selectedTabIndex: number) => selectedCollegeTeam
                ? `/search?team-slug=${encodeURIComponent(selectedCollegeTeam.slug)}&hs-class-year=${hsTrackStats.topHsTrackPlayerLists[selectedTabIndex].year}&top-hs-track-player=true&event=${selectedTrack}&sort-by=${selectedTrack == 'mph' ? 'mph' : 'performance'}`
                 : ''
               }
               tabs={{
                [hsTrackStats.topHsTrackPlayerLists[0].year]: (hsTrackStats.topHsTrackPlayerLists[0].list || []) as DashboardPlayer[],
                [hsTrackStats.topHsTrackPlayerLists[1].year]: (hsTrackStats.topHsTrackPlayerLists[1].list || []) as DashboardPlayer[],
               }}
               counts={hsTrackStats?.topHsTrackPlayerCounts}
               setSelectedTrack={setSelectedTrack}
               selectedTrack={selectedTrack}
               loading={hsTrackloading}
               showMark={true}
               sort={'performance'}
             />
           )}
        </div>
      )}

      {(isNFL && dashboardStatsNFL) && (
        <div className={classes.tablesRow}>
          {dashboardStatsNFL.draftedLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='Draft Picks'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedNFLTeam
                ? `/team/${selectedNFLTeam.slug}?draft-year=${dashboardStatsNFL.draftedLists[selectedTabIndex].year}`
                : ''
              }
              tabs={{
                [dashboardStatsNFL.draftedLists[0].year]: (dashboardStatsNFL.draftedLists[0].list || []) as DashboardPlayer[],
                [dashboardStatsNFL.draftedLists[1].year]: (dashboardStatsNFL.draftedLists[1].list || []) as DashboardPlayer[],
              }}
              counts={dashboardStatsNFL.draftedCounts}
              reverseColumn={true}
              isNFL
              sort={'draftRound'}
            />
          )}

          {dashboardStatsNFL.speedRecruitsLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              showIcon='speed'
              name='Speed Picks'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedNFLTeam
                ? `/team/${selectedNFLTeam.slug}?speed-picks=true&hs-class-year=${dashboardStatsNFL.speedRecruitsLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStatsNFL.speedRecruitsLists[0].year]: (dashboardStatsNFL.speedRecruitsLists[0].list || []) as DashboardPlayer[],
                [dashboardStatsNFL.speedRecruitsLists[1].year]: (dashboardStatsNFL.speedRecruitsLists[1].list || []) as DashboardPlayer[],
              }}
              counts={dashboardStatsNFL.speedRecruitsCounts}
              reverseColumn={true}
              isNFL
            />
          )}
        </div>
      )}

      {(isNFL && dashboardStatsNFL) && (
        <div className={classes.tablesRow}>

          {dashboardStatsNFL.udfaLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='UDFAs'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedNFLTeam
                ? `/team/${selectedNFLTeam.slug}?udfa-year=${dashboardStatsNFL.udfaLists[selectedTabIndex].year}`
                : ''
              }
              tabs={{
                [dashboardStatsNFL.udfaLists[0].year]: (dashboardStatsNFL.udfaLists[0].list || [])  as DashboardPlayer[],
                [dashboardStatsNFL.udfaLists[1].year]: (dashboardStatsNFL.udfaLists[1].list || [])  as DashboardPlayer[],
              }}
              counts={dashboardStatsNFL.udfaCounts}
              showStatus
              reverseColumn={true}
              isNFL
              sort={'pai'}
            />
          )}

          {dashboardStatsNFL.powerRecruitsLists?.length === 2 && (
            <TabulatedPlayersList
              className={classes.playersList}
              name='Power Picks'
              showIcon='power'
              getSeeMoreUrl={(selectedTabIndex:number) => selectedNFLTeam
                ? `/team/${encodeURIComponent(selectedNFLTeam.slug)}?power-picks=true&hs-class-year=${dashboardStatsNFL.powerRecruitsLists[selectedTabIndex].year}&sort-by=pai`
                : ''
              }
              tabs={{
                [dashboardStatsNFL.powerRecruitsLists[0].year]: (dashboardStatsNFL.powerRecruitsLists[0].list || [])  as DashboardPlayer[],
                [dashboardStatsNFL.powerRecruitsLists[1].year]: (dashboardStatsNFL.powerRecruitsLists[1].list || [])  as DashboardPlayer[],
              }}
              counts={dashboardStatsNFL.powerRecruitsCounts}
              showStatus
              reverseColumn={true}
              isNFL
            />
          )}
        </div>
      )}
    </Paper>
  );
}

const mapStateToProps = (state:State) => {
  return {
    nflDraftYear: state.configurations.nflDraftYear,
    user: state.user,
    selectedCollegeTeam: state.ui.selectedCollegeTeam,
    selectedNFLTeam: state.ui.selectedNFLTeam,
    rolloverDay: state.configurations.rolloverDay,
    rolloverMonth: state.configurations.rolloverMonth,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      setSelectedCollegeTeam,
      setSelectedNFLTeam,
    },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardTeamSelect);
