import React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../styles/colors';

interface PdfPageContainerProps {
  className?: string;
  children: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: COLOR_WHITE,
  },
  container: {
    width: '100%',
    minHeight: '100vh',
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    position: 'relative',
    boxSizing: 'border-box',
    transition: 'margin 0.3s, width 0.3s',
    backgroundColor: `${COLOR_WHITE}`,
  },
}), { name: PdfPageContainer.name });

export default function PdfPageContainer (props: PdfPageContainerProps) {
  const { className, children } = props;
  const classes = useStyles();

  return (
    <main className={classes.pageContainer}>
      <Container className={clsx(classes.container, className)}>
        {children}
      </Container>
    </main>
  );
}
