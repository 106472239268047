import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import VideoIcon from '@material-ui/icons/PlayCircleOutline';
import NotAvailable from '../atoms/NotAvailable';
import {
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_MIDDLE_GRAY,
} from '../styles/colors';
import Link, { LinkType } from '../types/Link';

interface LinksProps {
  className?: string;
  linkClassName?: string;
  links: Link[];
  onlySocial?: boolean;
}

interface SiteLink {
  logo: string;
  url?: string;
  logoClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  links: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  socialIcon: {
    width: '24px',
    height: '24px',
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',

    '&:hover': {
      color: COLOR_ORANGE,
    },
  },
  socialIconTwitter: {
    width: '18px',
    height: '18px',
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',

    '&:hover': {
      color: COLOR_ORANGE,
    },
  },
  divider: {
    width: '1px',
    height: '100%',
    margin: theme.spacing(0, 2),
    background: COLOR_MIDDLE_GRAY,
  },
  linksList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  link: {
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginTop: 2,
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    filter: 'saturate(0) brightness(0) contrast(0.1)',
    transition: 'filter 0.3s',

    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover': {
      filter: 'saturate(1) brightness(1) contrast(1)',
    },

    '& img': {
      height: '100%',
      display: 'block',
    },
  },

  maxPrepsLogo: {
    minHeight: 14,
  },
  pffLogo: {
    minHeight: 16,
  },

  siteLogo: {
    maxHeight: '12px',
  },
}), { name: Links.name });

export default function Links (props: LinksProps) {
  const {
    className,
    linkClassName,
    links = [],
    onlySocial = false,
  } = props;
  const classes = useStyles();

  if (!links || !links.length) return <NotAvailable />;

  const twitterLink = (links.find(link => link.key === LinkType.twitter) || {}).value;
  const facebookLink = (links.find(link => link.key === LinkType.facebook) || {}).value;
  const instagramLink = (links.find(link => link.key === LinkType.instagram) || {}).value;
  const youtubeLink = (links.find(link => link.key === LinkType.youtube) || {}).value;
  const hasSocialLinks = !!(twitterLink || facebookLink || instagramLink || youtubeLink);

  const link247 = (links.find(link => link.key === LinkType.twoFourSeven) || {}).value;
  const rivalsLink = (links.find(link => link.key === LinkType.rivals) || {}).value;
  const on3Link = (links.find(link => link.key === LinkType.on3) || {}).value;
  const hudlLink = (links.find(link => link.key === LinkType.hudl) || {}).value;
  const athleticLink = (links.find(link => link.key === LinkType.athletic) || {}).value;
  const maxPrepsLink = (links.find(link => link.key === LinkType.maxpreps) || {}).value;
  const mileSplitLink = (links.find(link => link.key === LinkType.milesplit) || {}).value;
  const directAthleticsLink = (links.find(link => link.key === LinkType.directAthletics) || {}).value;
  const pffLink = (links.find(link => link.key === LinkType.pff) || {}).value;

  const websites:SiteLink[] = [
    {
      logo: '/images/websites/247.png',
      url: link247,
    },
    {
      logo: '/images/websites/rivals.png',
      url: rivalsLink,
    },
    {
      logo: '/images/websites/on3.png',
      url: on3Link,
    },
    {
      logo: '/images/websites/hudl.png',
      url: hudlLink,
    },
    {
      logo: '/images/websites/athletic.png',
      url: athleticLink,
    },
    {
      logo: '/images/websites/maxpreps.png',
      url: maxPrepsLink,
      logoClassName: classes.maxPrepsLogo,
    },
    // {
    //   logo: '/images/websites/milesplit.png',
    //   url: mileSplitLink,
    // },
    // {
    //   logo: '/images/websites/direct-athletics.png',
    //   url: directAthleticsLink,
    // },
    // {
    //   logo: '/images/websites/pff.png',
    //   url: pffLink,
    //   logoClassName: classes.pffLogo,
    // },
  ].filter((site:any) => !!site.url);

  return (
    <div className={clsx(classes.links, className)}>
      {((onlySocial && !hasSocialLinks) || (!hasSocialLinks && !websites.length)) && (
        <NotAvailable />
      )}

      {twitterLink && (
        <a
          className={classes.socialLink}
          href={twitterLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          {/* <TwitterIcon className={classes.socialIcon} /> */}
          <img
            className={clsx(classes.socialIconTwitter)}
            src='/images/email/icon-twitter.png'
            alt=''
          />
        </a>
      )}

      {facebookLink && (
        <a
          className={classes.socialLink}
          href={facebookLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          <FacebookIcon className={classes.socialIcon} />
        </a>
      )}

      {instagramLink && (
        <a
          className={classes.socialLink}
          href={instagramLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          <InstagramIcon className={classes.socialIcon} />
        </a>
      )}

      {youtubeLink && (
        <a
          className={classes.socialLink}
          href={youtubeLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          <VideoIcon className={classes.socialIcon} />
        </a>
      )}

      {(hasSocialLinks && !!websites.length && !onlySocial) && (
        <div className={classes.divider} />
      )}

      {(!!websites.length && !onlySocial) && (
        <ul className={classes.linksList}>
          {websites.map((site:SiteLink) => (
            <li
              key={site.url}
              className={clsx(classes.link, linkClassName)}
            >
              <a
                href={site.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  className={clsx(classes.siteLogo, site.logoClassName)}
                  src={site.logo}
                  alt=''
                />
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
