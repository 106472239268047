import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TeamProfileStatistics from './TeamProfileStatistics';
import { SCORE_TYPE } from '../atoms/Score';
import DropDown from '../atoms/DropDown';
import RecruitingClassStats from '../types/RecruitingClassStats';
import Team from '../types/Team';

interface TeamRecruitingClassStatsProps {
  className?: string;
  team?: Team;
  nflAccess?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamRecruitingClassStats: {},

  statisticsLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  statisticsDropDown: {
    marginTop: theme.spacing(1),
  },
  statisticsDropDownSelector: {
    boxSizing: 'border-box',
    minHeight: '40px',
    fontSize: theme.typography.pxToRem(16),
  },
}), { name: TeamRecruitingClassStats.name });

export default function TeamRecruitingClassStats (props: TeamRecruitingClassStatsProps) {
  const {
    className,
    team,
    nflAccess,
  } = props;
  const classes = useStyles();
  const [recruitingClassStats, setRecruitingClassStats] = useState<RecruitingClassStats[]>();
  const [selectedPAIYear, setSelectedPAIYear] = useState<number>();
  const [selectedCombineYear, setSelectedCombineYear] = useState<number>();

  useEffect(() => {
    if (!!team?.recruitingClassStats) {
      const recruitingClassStats = team.recruitingClassStats;
      setRecruitingClassStats(recruitingClassStats);

      if (!!recruitingClassStats?.length) {
        const defaultSelectedYear = Number((recruitingClassStats[0] || {}).year);
        setSelectedPAIYear(defaultSelectedYear);
        setSelectedCombineYear(defaultSelectedYear);
      }
    }
  }, [team, nflAccess]);

  if (!team || !recruitingClassStats || !recruitingClassStats.length) return null;

  const selectedYearPAIStats:any = (recruitingClassStats.find((stat:any) => stat.year === selectedPAIYear) || {}).data || {};
  const selectedYearPAIValues = {
    team: get(selectedYearPAIStats, 'team.pai') || 0,
    level: get(selectedYearPAIStats, 'level.pai') || 0,
    conference: get(selectedYearPAIStats, 'conference.pai') || 0,
  };

  const selectedYearCombineStats:any = (recruitingClassStats.find((stat:any) => stat.year === selectedCombineYear) || {}).data || {};
  const selectedYearCombineValues = {
    team: get(selectedYearCombineStats, 'team.combine') || 0,
    level: get(selectedYearCombineStats, 'level.combine') || 0,
    conference: get(selectedYearCombineStats, 'conference.combine') || 0,
  };

  return (
    <TeamProfileStatistics
      className={clsx(classes.teamRecruitingClassStats, className)}
      title={nflAccess
        ? 'Draft Class Statistics'
        : 'Recruiting Class Statistics'
      }
      team={team}
      isNFL={nflAccess}
      rows={[
        {
          scoreType: SCORE_TYPE.PAI,
          label: (
            <div className={classes.statisticsLabel}>
              PAI:
              <DropDown
                className={classes.statisticsDropDown}
                selectorRootClassName={classes.statisticsDropDownSelector}
                items={recruitingClassStats.map((stat:any) => ({
                  content: String(stat.year),
                  value: String(stat.year),
                }))}
                value={String(selectedPAIYear)}
                onChange={(year:string) => setSelectedPAIYear(Number(year))}
              />
            </div>
          ),
          values: selectedYearPAIValues,
        },
        {
          scoreType: SCORE_TYPE.COMBINE,
          label: (
            <div className={classes.statisticsLabel}>
              { nflAccess ? 'HS Combine' : 'Combine'}:
              <DropDown
                className={classes.statisticsDropDown}
                selectorRootClassName={classes.statisticsDropDownSelector}
                items={recruitingClassStats.map((stat:any) => ({
                  content: String(stat.year),
                  value: String(stat.year),
                }))}
                value={String(selectedCombineYear)}
                onChange={(year:string) => setSelectedCombineYear(Number(year))}
              />
            </div>
          ),
          values: selectedYearCombineValues,
        }
      ]}
    />
  );
}
