import {
  SET_SIDE_DRAWER_OPEN,
  SET_SCREEN_SIZE,
  SET_COLLEGE_ACCESS,
  SET_NFL_ACCESS,
  SET_ADMIN_ACCESS,
  SET_SELECTED_COLLEGE_TEAM,
  SET_SELECTED_NFL_TEAM,
} from '../actions/ui';
import { ScreenSize } from '../../services/screen-size';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import Team from '../../types/Team';

export interface UIState {
  sideDrawerOpen: boolean;
  screenSize: ScreenSize;
  collegeAccess: boolean;
  nflAccess: boolean;
  adminAccess: boolean;
  selectedCollegeTeam: Team | undefined;
  selectedNFLTeam: Team | undefined;
}

const INITIAL_STATE:UIState = {
  sideDrawerOpen: false,
  screenSize: ScreenSize.MOBILE,
  collegeAccess: true,
  nflAccess: clientStorage.get(StorageKey.VIEW_MODE) === 'nfl',
  adminAccess: false,
  selectedCollegeTeam: undefined,
  selectedNFLTeam: undefined,
};

export function getUIInitialState () {
  return INITIAL_STATE;
}

export default function uiReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_SIDE_DRAWER_OPEN:
      return {
        ...state,
        sideDrawerOpen: action.sideDrawerOpen,
      };
    case SET_SCREEN_SIZE:
      return {
        ...state,
        screenSize: action.screenSize,
      };
    case SET_COLLEGE_ACCESS:
      return {
        ...state,
        collegeAccess: action.collegeAccess,
      };
    case SET_NFL_ACCESS:
      return {
        ...state,
        nflAccess: action.nflAccess,
      };
    case SET_ADMIN_ACCESS:
      return {
        ...state,
        adminAccess: action.adminAccess,
      };
    case SET_SELECTED_COLLEGE_TEAM:
      return {
        ...state,
        selectedCollegeTeam: action.selectedCollegeTeam,
      };
    case SET_SELECTED_NFL_TEAM:
      return {
        ...state,
        selectedNFLTeam: action.selectedNFLTeam,
      };
    default:
      return state;
  }
};
