import React from 'react';
import Icon from '../atoms/Icon';

function MyImportsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(5, 4)">
          <polygon
            fill="currentColor"
            fillRule="nonzero"
            points="20 9 20 0 0 0 0 24 20 24 20 15 18 15 18 22 2 22 2 2 18 2 18 9"
          />
          <path
            d="M7.9,12 L22,12 M12.2,7.7 L7.9,12 L12.2,16.3"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(MyImportsIcon);
