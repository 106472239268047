import React from 'react';
import Page from './Page';
import SearchPageTemplate from '../templates/SearchPageTemplate';

export default function SearchPage () {
  return (
    <Page title='Search'>
      <SearchPageTemplate />
    </Page>
  );
}
