import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { State } from '../redux/reducers';
import Dialog from '../molecules/Dialog';
import Loader from '../atoms/Loader';
import Button from '../atoms/Button';
import gql from '../services/gql';
import Toast, { ToastType } from '../atoms/Toast';


interface StagingTableProps {
  className?: string;
}

const useStyles = makeStyles(theme => ({
  staging: {
    padding: theme.spacing(3, 2),
  }

}), { name: StagingTable.name });

function StagingTable(props: StagingTableProps) {
  const {
    className,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const classes = useStyles();

  const onClose = () => {
    setIsOpen(false);
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const onConfirm = () => {
    setLoading(true);
    gql(`
        mutation {
          deleteAllStagedPlayers
        }`
      )
        .then((data:any) => data.deleteAllStagedPlayers as boolean)
        .then((success:boolean) => {
          if (success) {
            setIsOpen(false);
            showToast('Deleted', ToastType.SUCCESS);
            setLoading(false);
          }
        }).catch((err) => {
          setIsOpen(false);
          showToast('Delete failed', ToastType.ERROR);
          setLoading(false);
        });
  }

  return (
    <div className={className}>
      <Loader inProgress={loading} />
      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
      <Dialog title="Clear Staging Area" open={isOpen}
      actions={[
        {
          name: 'Yes',
          primary: true,
          onClick: onConfirm
        },
        {
          name: 'No',
          primary: false,
          onClick: onClose
        },
      ]}
      onClose={onClose}
      >
        <div>
          Are you sure you want to clear the staging area?
        </div>
      </Dialog>
      <div className={classes.staging}>
        <Button primary onClick={() => setIsOpen(true)}> Clear Staging</Button>
      </div>

    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StagingTable);
