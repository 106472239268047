import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Page from './Page';
import MyRecruitingBoardPageTemplate from '../templates/MyRecruitingBoardPageTemplate';

export default function MyRecruitingBoardPage () {
  const location = useLocation();
  const history = useHistory();
  const printed = location.pathname.endsWith('/pdf');

  if (!printed) {
    history.replace('/dashboard?');
  }

  return (
    <Page title='Recruiting Board' printed={printed}>
      <MyRecruitingBoardPageTemplate />
    </Page>
  );
}
