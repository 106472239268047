import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FreshmanIcon from '../icons/FreshmanIcon';
import RanksTooltip from '../molecules/RanksTooltip';
import Tooltip from './Tooltip';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_MIDDLE_GRAY,
  COLOR_ORANGE,
  COLOR_TEXT,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Rank from '../types/Rank';
import Position from '../types/Position';
import { getEventTypeDescription } from '../services/event-names-and-formats';

interface RanksProps {
  className?: string;
  type?: RanksType;
  headerItems?: RanksHeaderItem[];
  playerId?: number;
  teamId?: number;
  position?: Position;
  comparisonEvent?: string;
  combineId?: number | null;
  combineCategory?: 'hs' | 'nfl' | null;
  event?: RanksEvent;
  values?: Rank[];
  combine?: boolean;
  bottomSpace?: boolean;
  noTopBorder?: boolean;
}

export interface RanksHeaderItem {
  title?: string;
  subtitle?: string;
  logo?: string;
}

export interface RanksEvent {
  type?: string;
  name?: string;
  result?: string;
  isCombineEvent?: boolean;
}

export enum RanksType {
  PAI = 'pai',
  COMBINE = 'combine',
  PRO_COMBINE = 'proCombine',
  PPI = 'ppi',
}

const ITEMS_NUMBER = 5;

const useStyles = makeStyles((theme: Theme) => ({
  ranks: {
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomSpace: {
    paddingBottom: theme.spacing(2),
  },
  noTopBorder: {
    '& > *:first-child': {
      borderTop: 0,
    },
  },

  header: {
    display: 'flex',
    minWidth: '400px',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  headerItem: {
    width: `${100 / ITEMS_NUMBER}%`,
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
  },
  headerText: {
    color: COLOR_TEXT,
  },
  headerSubtext: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },
  headerLogo: {
    width: '32px',
    height: 'auto',
  },

  event: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${COLOR_BORDER}`,
    fontFamily: FONT_PROXIMA_NOVA,
  },
  eventResult: {
    padding: theme.spacing(0, 2, 0, 1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.2,
    fontWeight: 'bold',
    textAlign: 'center',
    color: COLOR_ORANGE,
    borderRight: `1px solid ${COLOR_MIDDLE_GRAY}`,
  },
  eventType: {
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,
    fontWeight: 600,
    color: COLOR_TEXT,
  },
  relayEventType: {
    display: 'flex',
  },
  relaysubHeader: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
  },
  freshmanIcon: {
    width: 45,
    height: 40,
    cursor: 'help',
    color: COLOR_ORANGE,
    transform: 'translate(-4px, 1px)',
  },
  eventName: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
    transform: 'translateY(1px)',
  },
  relayEventName: {
    paddingLeft: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
    transform: 'translateY(1px)',
  },
  values: {
    display: 'flex',
    minWidth: '400px',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderBottom: `1px solid ${COLOR_BORDER}`,
    background: COLOR_BACKGROUND_LIGHT,
  },
  valueItem: {
    width: `${100 / ITEMS_NUMBER}%`,
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
  },
  withRightBorder: {
    borderRight: `1px solid ${COLOR_BORDER}`,
  },

  value: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
  },
  interactive: {
    color: COLOR_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  percentile: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: 1,
    color: COLOR_ORANGE,
  },
}), { name: Ranks.name });

export default function Ranks (props: RanksProps) {
  const {
    className,
    type,
    headerItems,
    event,
    values,
    playerId,
    teamId,
    combineId = null,
    combineCategory = null,
    comparisonEvent,
    position,
    bottomSpace,
    noTopBorder,
  } = props;
  const classes = useStyles();
  const isRelayEvent = event && event.type && event.type.includes('Relay') || false;

  return (
    <div className={clsx(
      classes.ranks,
      bottomSpace && classes.bottomSpace,
      noTopBorder && classes.noTopBorder,
      className,
    )}>
      {headerItems?.length === ITEMS_NUMBER && (
        <div className={classes.header}>
          {headerItems.map((item: RanksHeaderItem, index:number) => (
            <div
              key={index}
              className={classes.headerItem}
            >
              {item.logo && (
                <img
                  className={classes.headerLogo}
                  src={item.logo}
                  alt=''
                />
              )}
              {item.title && (
                <span className={classes.headerText}>{item.title}</span>
              )}
              {item.subtitle && (
                <span className={classes.headerSubtext}>{item.subtitle}</span>
              )}
            </div>
          ))}
        </div>
      )}

      {!!event && (
        <div className={classes.event}>
          {!!event.result && <span className={classes.eventResult}>{event.result}</span>}
          {!!event.isCombineEvent && (
            <Tooltip title='Freshman Data'>
              <FreshmanIcon className={classes.freshmanIcon} />
            </Tooltip>
          )}
          {!!event.type && !isRelayEvent && (
            <span className={classes.eventType}>
              {event.type}{event.name && <>&nbsp;-</>}
            </span>
          )}

          {!!event.type && isRelayEvent && (
            <div className={classes.relayEventType}>
              <span className={classes.eventType}>
                {event.type}
              </span>
              <span className={classes.eventType}>
                {event.name && <>&nbsp;-</>}
              </span>
              {/* <span className={classes.relaysubHeader}>
                {getEventTypeDescription(comparisonEvent)}
              </span> */}
            </div>
          )}
          {!!event.name && !isRelayEvent && <span className={classes.eventName}>{event.name}</span>}
          {!!event.name && isRelayEvent && <span className={classes.relayEventName}>{event.name}</span>}
        </div>
      )}

      {values?.length === ITEMS_NUMBER && (
        <div className={classes.values}>
          {values.map((rank: Rank, index) => (
            <div
              key={index}
              className={clsx(classes.valueItem, index === 1 && classes.withRightBorder)}
            >
              <span className={classes.percentile}>
                {rank.percentile}%
              </span>

              {rank.comparisonGroup
                ? <RanksTooltip
                    type={type}
                    playerId={playerId}
                    teamId={teamId}
                    position={position}
                    comparisonGroup={rank.comparisonGroup}
                    comparisonEvent={comparisonEvent}
                    combineId={combineId}
                    combineCategory={combineCategory}
                    rank={rank.value.includes('/')
                      ? Number(rank.value.split('/')[0])
                      : undefined
                    }
                  >
                    <span className={clsx(classes.value, classes.interactive)}>
                      Rank {rank.value}
                    </span>
                  </RanksTooltip>
                : <span className={classes.value}>{rank.value.includes('/') ? 'Rank ' : ''}{rank.value}</span>
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
