const INCHES_PER_FOOT = 12;
const SECONDS_PER_MINUTE = 60;

export function inchesToFeetAndInches (inches:number, withoutRound: boolean = false):string {
  let roundedInches = Math.round(inches);
  let feet = `${Math.floor(roundedInches / INCHES_PER_FOOT)}`;
  let inchesRemainder = `0${Math.round(roundedInches % INCHES_PER_FOOT)}`.slice(-2);
  if (withoutRound) {
    roundedInches = inches;
    feet = `${(roundedInches / INCHES_PER_FOOT)}`;
    let inchesRem = (roundedInches % INCHES_PER_FOOT);
    if (feet?.split(".").length > 1) {
      const firstPart = feet?.split(".")[0];
      inchesRem = Number(inchesRem.toFixed(3));
      const inFeet = `${firstPart}' ${inchesRem}`
      return `${inFeet}"`;
    }
  }
  return `${feet}'${inchesRemainder}"`;
}

export function inchesToFeetInInchesAsSingleNumber (inches:number):number {
  const roundedInches = Math.round(inches);
  const feet = Math.floor(roundedInches / INCHES_PER_FOOT);
  const inchesRemainder = `0${Math.round(roundedInches % INCHES_PER_FOOT)}`.slice(-2);

  return Number(`${feet}${inchesRemainder}`);
}

export function feetAndInchesToInches (feetAndInches:string):number {
  const parts = feetAndInches.split('\'');
  const feet = Number(parts[0]);
  const inches = Number(parts[1].split('"')[0]);

  return (feet * INCHES_PER_FOOT) + inches;
}

export function secondsToMinutesSecondsMs (seconds:number):string {
  if (seconds < SECONDS_PER_MINUTE) return `${Number(seconds).toFixed(2)}`;
  if (seconds === SECONDS_PER_MINUTE) return '1:00.0';

  const minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  const fullSeconds = `0${Math.floor(seconds % SECONDS_PER_MINUTE)}`.slice(-2);
  const ms = seconds
    .toFixed(2)
    .split('.')[1];

  return `${minutes}:${fullSeconds}${ms === '00' ? '' : `.${ms}`}`;
}

export function datetimeToDate (datetime:string):string {
  const date = new Date(datetime);

  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
}

export function displayDateTime (datetime:string, isLocal?: boolean):string {
  const date = new Date(datetime);
  if (isLocal) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}:${seconds}`;

    // Get local month, date, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year} ${timeString}`;
  }
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  const timeString = `${hours}:${minutes}:${seconds}`;
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()} ${timeString} `;
}

export function formatDatetime (
  datetime:string,
  { appendExactTime = false } = {},
):string {
  const dateToFormat = new Date(datetime);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  let text = '';
  const todayDisplayDate = datetimeToDate(today.toString());
  const yesterdayDisplayDate = datetimeToDate(yesterday.toString());
  let dateToDisplay = datetimeToDate(dateToFormat.toUTCString());
  let hours = dateToFormat.getHours();
  let minutes = dateToFormat.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  if (dateToDisplay === yesterdayDisplayDate) {
    text = 'Yesterday';
  } else if (dateToDisplay === todayDisplayDate) {
    text = 'Today';
  } else {
    text = `${dateToDisplay}`;
  }

  if (appendExactTime) {
    text = `${text} at ${hours}:${('0' + minutes).slice(-2)} ${ampm}`;
  }

  return text;
}

export function formatNumbersToCommaSeparatedString(numberToConvert: number):string {
  return numberToConvert.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDateInDays (datetime:string):string {
  let textToDisplay = '';
  if (datetime) {
    const dateTimestamp = new Date(new Date(datetime).setHours(0, 0, 0)).getTime();
    const todaysTimestamp = new Date(new Date().setHours(0, 0, 0)).getTime();
    const diffTime = Math.abs(todaysTimestamp - dateTimestamp);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      textToDisplay = 'Today';
    } else if (diffDays === 1) {
      textToDisplay = 'Yesterday';
    } else {
      textToDisplay = `${diffDays} days ago`;
    }
  }

  return textToDisplay;
}

export function numberWithCommas (number:number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getDateFromDays (days:number):any {
  let startDate = '';
  let endDate = '';
  if (days) {
    const start = new Date();
    start.setDate(start.getDate() - days);
    startDate = start.toISOString().slice(0, 10);

    const end = new Date();
    end.setDate(end.getDate() - 0);
    endDate = end.toISOString().slice(0, 10);
  }
  return {startDate, endDate};
}
