import React from 'react';
import Icon from '../atoms/Icon';

function UserIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.5,2 C10.8055796,2 7,5.80557962 7,10.5 C7,15.1944204 10.8055796,19 15.5,19 C20.1944204,19 24,15.1944204 24,10.5 C24,5.80557962 20.1944204,2 15.5,2 Z M3,32 C3,24.8196667 8.81966667,19 16,19 C23.1803333,19 29,24.8196667 29,32'
          stroke='currentColor'
          strokeWidth='2.46153846'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(UserIcon);
