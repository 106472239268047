import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_DARK_GRAY, COLOR_RED } from '../styles/colors';

interface CloseButtonProps {
  className?: string,
  onClick?: (event: any) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    appearance: 'none',
    background: 'none',
    border: `1px solid transparent`,
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    position: 'relative',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:before': {
      content: '""',
      width: '2px',
      height: '20px',
      background: 'currentColor',
      position: 'absolute',
      top: '1px',
      left: '10px',
      transform: 'rotate(45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '20px',
      background: 'currentColor',
      position: 'absolute',
      top: '1px',
      left: '10px',
      transform: 'rotate(-45deg)',
    },

    '&:hover': {
      color: COLOR_RED,
    },

    '&:focus': {
      outline: 0,
      borderColor: COLOR_DARK_GRAY,
    },
  },
}), { name: CloseButton.name });

export default function CloseButton (props: CloseButtonProps) {
  const {
    className,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.closeButton, className)}
      onClick={onClick}
    />
  );
}
