import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '../atoms/Checkbox';
import Slider from '../atoms/Slider';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface MultiSelectWithSlidersProps {
  className?: string;
  selectorClassName?: string;
  selectorRootClassName?: string;
  valueClassName?: string;
  items: MultiSelectWithSlidersItem[];
  emptyItem?: MultiSelectWithSlidersItem;
  values: string[];
  labelWhenSelectedAll?: any;
  labelWhenSelectedNone?: any;
  sliderRanges?: any;
  selectedSliderRanges?: any;
  onChange: (values: string[]) => void;
  onSliderChange: (value: number | number[], scoreType:string) => void;
}

export interface MultiSelectWithSlidersItem {
  value: string;
  content: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  multiSelectWithSlider: {
    minWidth: '110px',
    outlineColor: COLOR_BLUE,
  },
  multiSelectWithSliderEmpty: {
    '& $selectorRoot': {
      padding: theme.spacing(1.25, 2),
    },
  },

  selector: {
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #efefef',

    '&:hover': {
      borderColor: COLOR_BLUE,
    },

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '& fieldset': {
      border: 0,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    },
  },
  selectorRoot: {
    padding: '8px 32px 8px 10px',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: `${COLOR_WHITE} !important`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    position: 'relative',

    '&:after': {
      content: '""',
      width: '32px',
      height: '100%',
      background: COLOR_WHITE,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  icon: {
    display: 'none',
  },

  menu: {
    padding: 0,
  },
  menuItem: {
    padding: '6px 16px 6px 8px',
    background: `${COLOR_WHITE} !important`,
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:hover': {
      background: `rgba(0, 0, 0, 0.04) !important`,
    },
  },
  itemContent: {
    width: '180px',
  },

  value: {
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },

  slider: {
    padding: theme.spacing(3, 0),
    boxSizing: 'border-box',
  },
}), { name: MultiSelectWithSliders.name });

export default function MultiSelectWithSliders (props:MultiSelectWithSlidersProps) {
  const {
    className,
    selectorClassName,
    selectorRootClassName,
    valueClassName,
    items = [],
    values = [],
    emptyItem,
    labelWhenSelectedAll,
    labelWhenSelectedNone,
    sliderRanges = {},
    selectedSliderRanges = {},
    onChange = () => {},
    onSliderChange = () => {},
  } = props;
  const classes = useStyles();

  const [selectedScores, setSelectedScores] = useState<string[]>(values);

  function onCheckboxChange(checkedItem:string) {
    return (value: boolean) => {
      if (value) {
        setSelectedScores([...selectedScores, checkedItem]);
        onChange([...selectedScores, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedScores];
        const removeIndex = selectedScores.findIndex(score => score === checkedItem);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }
        setSelectedScores(selectedItemsWithoutCheckedItem);
        onChange(selectedItemsWithoutCheckedItem);
      }
    }
  }

  return (
    <FormControl
      className={clsx(
        classes.multiSelectWithSlider,
        className,
        (values.length === 0 && !emptyItem) && classes.multiSelectWithSliderEmpty,
      )}
      variant='outlined'
    >
      <Select
        className={clsx(classes.selector, selectorClassName)}
        classes={{
          root: clsx(classes.selectorRoot, selectorRootClassName),
          icon: classes.icon,
        }}
        multiple
        value={values}
        renderValue={(selectedValues:any) => {
          if (emptyItem && (!selectedValues.length || (selectedValues.length === 1 && selectedValues[0] === emptyItem.value))) {
            return emptyItem.content;
          }
          if (labelWhenSelectedNone && selectedValues.length === 0) {
            return labelWhenSelectedNone;
          }

          if (labelWhenSelectedAll && selectedValues.length === items.length) {
            return labelWhenSelectedAll;
          }

          const allItems = ([emptyItem, ...items] as MultiSelectWithSlidersItem[])
            .filter((item:MultiSelectWithSlidersItem) => item && (selectedValues as string[]).includes(item.value));

          return allItems
            .map((item, index) => <>{item.content}{index === allItems.length - 1 ? '' : ', '}</>);
        }}
        MenuProps={{ classes: { list: classes.menu } }}
        displayEmpty
      >
        {emptyItem && (
          <MenuItem
            key='none'
            value={emptyItem.value}
            classes={{ root: classes.menuItem }}
          >
            <Checkbox checked={values.includes(emptyItem.value)} />
            <span className={clsx(classes.value, valueClassName)}>
              {emptyItem.content}
            </span>
          </MenuItem>
        )}

        {items.map((item:MultiSelectWithSlidersItem, index:number) => (
          <MenuItem
            key={index}
            classes={{ root: classes.menuItem }}
            value={item.value}
          >
            <div className={classes.itemContent}>
              <Checkbox
                checked={values.includes(item.value)}
                onChange={onCheckboxChange(item.value)}
              />
              <span className={clsx(classes.value, valueClassName)}>
                {item.content}
              </span>
            </div>
            <Slider
              disabled={!selectedScores.includes(item.value)}
              className={classes.slider}
              label={item.content}
              showValueLabelComponent={false}
              min={sliderRanges[item.value][0]}
              step={1}
              max={sliderRanges[item.value][1]}
              defaultValue={selectedSliderRanges[item.value] || sliderRanges[item.value]}
              marks={[
                {value: selectedSliderRanges[item.value] ? selectedSliderRanges[item.value][0] : sliderRanges[item.value][0], label: selectedSliderRanges[item.value] ? selectedSliderRanges[item.value][0] : sliderRanges[item.value][0]},
                {value: selectedSliderRanges[item.value] ? selectedSliderRanges[item.value][1] : sliderRanges[item.value][1], label: selectedSliderRanges[item.value] ? selectedSliderRanges[item.value][1] : sliderRanges[item.value][1]},
              ]}
              onChange={(value: number | number[]) => onSliderChange(value, item.value)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
