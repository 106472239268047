export const DEFAULT_SCROLLBAR_WIDTH = 10;

// Taken from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
// Note: returns 0 on MacOS with the default "Only show scrollbars when scrolling" setting (Yosemite and up).
export function getScrollbarWidth ():number {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // @ts-ignore
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  // @ts-ignore
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}
