import React, {FormEvent, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import DemoIcon from '../icons/DemoIcon';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Dialog from './Dialog';
import { logIn, resetPassword, healthCheck } from '../services/user';
import { getQueryParameter } from '../services/query-parameters';
import { retrieveLastPath } from '../services/path';
import { updatePageTitle } from '../services/page-title';
import { getScreenSize, ScreenSize } from '../services/screen-size';
import { setScreenSizeAction } from '../redux/actions/ui';
import { resetReduxStoreAction } from '../redux/actions/root';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_DARK_BLUE, COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import Outage from '../organisms/Outage';

interface LoginFormProps {
  resetReduxStoreAction: () => void;
  setScreenSizeAction: (screenSize:ScreenSize) => void,
}

const useStyles = makeStyles(theme => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
  },
  title: {
    ...theme.typography.h1,
    margin: 0,
  },
  input: {
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  submitButton: {
    width: 'auto',
    padding: theme.spacing(2, 4),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
  },

  forgotPassword: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    appearance: 'none',
    background: 'none',
    border: 0,
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
    outline: 'none',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
    '&:focus': {
      fontWeight: 'bold',
    },
  },

  footer: {
    position: 'absolute',
    bottom: '150px',
    display: 'flex',
    flexDirection: 'column',
  },
  signupWrapper: {
    display: 'flex',
  },
  signupLink: {
    marginLeft: theme.spacing(1),
    color: COLOR_BLUE,
    textDecoration: 'underline',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
    '&:focus': {
      fontWeight: 'bold',
    },
  },

  demoLink: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover $demoIcon': {
      color: COLOR_DARK_BLUE,
    },
    '&:hover $demoText': {
      color: COLOR_DARK_BLUE,
    },
    '&:focus $demoText': {
      fontWeight: 'bold',
    },
  },
  demoIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_BLUE,
  },
  demoText: {
    marginLeft: theme.spacing(1),
    color: COLOR_BLUE,
    textDecoration: 'underline',
  },

  resetPasswordText: {
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLOR_TEXT,
  },
  resetInputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  '@media (max-height: 800px)': {
    footer: {
      position: 'relative',
      bottom: 'auto',
      marginTop: theme.spacing(8),
    },
  },

  [MEDIA.MOBILE]: {
    title: {
      fontSize: theme.typography.pxToRem(20),
      color: COLOR_TEXT,
    },

    footer: {
      position: 'relative',
      bottom: 'auto',
      marginTop: theme.spacing(8),
    },
  },

  '@media (max-width: 400px)': {
    submitButton: {
      padding: theme.spacing(2, 3),
    },
    forgotPassword: {
      padding: 0,
    },
  },
}), { name: LoginForm.name });

function LoginForm (props:LoginFormProps) {
  const {
    resetReduxStoreAction,
    setScreenSizeAction,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);

  const [isAbleToLogin, setIsAbleToLogin] = useState<boolean>(false);
  const [outageMessage, setOutageMessage] = useState<string>('');


  useEffect(() => {
    updatePageTitle('Login');
    onLoad();
  }, []);

  function onLoad () {
    setLoading(true);
    healthCheck()
    .then((res) => {
      if (res && res.loginAllowed) {
        setIsAbleToLogin(true);
      } else if (res && !res.loginAllowed) {
        setOutageMessage('Sorry the website is down temporarily for planned updating and maintenance.  We will be back online shortly. Thank you for your patience.');
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setOutageMessage('Sorry the website is down. We are working hard to resolve the issue and will have the site back online as soon as possible. Thank you for your patience.');
      setLoading(false);
    })
  }

  function onLogIn (event:FormEvent) {
    event.preventDefault();

    if (checkFieldsAreValid(email, password)) {
      setLoading(true);

      logIn(email, password)
        .then(() => {
          resetReduxStoreAction();
          setScreenSizeAction(getScreenSize());

          const redirectPath = getQueryParameter('redirect');
          const lastPath = retrieveLastPath();

          history.push(redirectPath ? redirectPath : (lastPath ? lastPath : `/`));
        })
        .catch(error => {
          console.error(error);
          showToast(error.message, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  function onResetPassword () {
    setLoading(true);

    resetPassword(email)
      .then(() => {
        setForgotPasswordDialogOpen(false);
        showToast('Success. Check you email.', ToastType.SUCCESS);
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function checkFieldsAreValid (email:string, password:string) {
    let valid = true;

    if (email.length < 6 || !email.includes('@')) {
      setEmailError('Email is invalid');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!password.length) {
      valid = false;
      setPasswordError('Please enter password');
    } else {
      setPasswordError('');
    }

    return valid;
  }

  function showToast (message:string, toastType:ToastType = ToastType.SUCCESS) {
    setToastMessage(message)
    setToastVisible(true);
    setToastType(toastType);
  }

  return (
    !isAbleToLogin ? (
      <Outage outageMessage={outageMessage}/>
    )
    :
    <>
      <form
        className={classes.loginForm}
        onSubmit={onLogIn}
      >
        <Loader inProgress={loading} />

        <h1 className={classes.title}>Log in</h1>

        <Input
          className={classes.input}
          label='Email'
          value={email}
          error={emailError}
          onChange={(value:string) => setEmail(value.trim())}
        />

        <Input
          className={classes.input}
          type='password'
          label='Password'
          value={password}
          error={passwordError}
          onChange={(value:string) => setPassword(value.trim())}
        />

        <div className={classes.submitWrapper}>
          <Button
            className={classes.submitButton}
            type='submit'
            primary
          >
            Log In
          </Button>

          <button
            className={classes.forgotPassword}
            onClick={(event) => {
              event.preventDefault();
              setForgotPasswordDialogOpen(true);
            }}
          >
            Forgot password?
          </button>
        </div>
      </form>

      <div className={classes.footer}>
        <a
          className={classes.demoLink}
          href='https://www.trackingfootball.com/demo/'
        >
          <DemoIcon className={classes.demoIcon} />
          <span className={classes.demoText}>Request a Free Demo</span>
        </a>
      </div>

      <Dialog
        open={forgotPasswordDialogOpen}
        title='Forgot password?'
        actions={[
          {
            name: 'No, I remember',
            onClick: () => setForgotPasswordDialogOpen(false),
          },
          {
            name: 'Yes, reset',
            primary: true,
            disabled: !!emailError,
            onClick: onResetPassword,
          },
        ]}
        onClose={() => setForgotPasswordDialogOpen(false)}
      >
        <Loader inProgress={loading} />

        <p className={classes.resetPasswordText}>
          Enter your email address, and we'll send you an email with a special link to reset your password.
        </p>

        <Input
          className={classes.input}
          inputFieldClassName={classes.resetInputField}
          label='Email'
          value={email}
          fontSize={14}
          error={emailError}
          onChange={(value:string) => setEmail(value)}
        />
      </Dialog>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
}

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      resetReduxStoreAction,
      setScreenSizeAction,
    },
    dispatch
  )
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginForm);
