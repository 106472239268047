import React from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '../atoms/Checkbox';
import DragNDropDots from '../atoms/DragNDrop';
import { MultiSelectItem } from './MultiSelect';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BORDER, COLOR_WHITE, COLOR_BACKGROUND_LIGHT } from '../styles/colors';

interface MultiSelectPopoverProps {
  className?: string;
  anchorEl: HTMLElement | null;
  items: MultiSelectItem[];
  values: string[];
  onChange: (values: string[]) => void;
  onReorder?: (values: string[]) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  multiSelectPopover: {
  },
  paper: {
    borderRadius: '12px',
  },

  list: {
    appearance: 'none',
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  listItem: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    padding: '6px 16px 6px 4px',
    background: `${COLOR_WHITE} !important`,
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,

      '& $dragNDropDots': {
        opacity: 1,
      },
    },
  },
  dragNDropDotsContainer: {
    marginRight: theme.spacing(0.4)
  },
  dragNDropDots: {
    opacity: 0,
    transition: 'opacity 0.2s',
  },
  blankDragNDropDots: {
    visibility: 'hidden'
  },

  disabled: {
    cursor: 'no-drop',
  },

  value: {
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  columnDragging: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}), { name: MultiSelectPopover.name });

export default function MultiSelectPopover (props: MultiSelectPopoverProps) {
  const classes = useStyles();
  const {
    className,
    anchorEl,
    items,
    values,
    onChange,
    onReorder,
    onClose,
  } = props;
  const open = Boolean(anchorEl);

  if (!items || !items.length) return null;

  function onClick (newValue:string) {
    return () => {
      const selectedValues = items
        .filter((item:MultiSelectItem) => values.includes(item.value) || item.value === newValue)
        .map((item:MultiSelectItem) => item.value);

      if (values.includes(newValue)) {
        // remove the selected value
        const index = selectedValues.findIndex((value:string) => value === newValue);
        selectedValues.splice(index, 1);
      }

      onChange(selectedValues);
    };
  }

  function onDragEnd (result:any) {
    if (!result.destination) return;

    const fromPosition = result.source.index;
    const toPosition = result.destination.index;
    const reorderedColumns = [...items];
    const draggedColumn = items[fromPosition];

    reorderedColumns.splice(fromPosition, 1); // cut out the dragged player
    reorderedColumns.splice(toPosition, 0, draggedColumn); // insert it to the position

    if (onReorder) {
      onReorder(reorderedColumns.map((item:MultiSelectItem) => item.value));
    }
  }

  return (
    <Popover
      className={clsx(classes.multiSelectPopover, className)}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='add-remove-column'>
          {({ droppableProps, placeholder, innerRef }) => (
            <div
              ref={innerRef}
              {...droppableProps}
            >
              <ul className={classes.list}>
                {items.map((item:MultiSelectItem, index:number) => (
                  <Draggable
                    key={index}
                    index={index}
                    draggableId={`${item.value}_${index}`}
                    isDragDisabled={(item.hasOwnProperty('isDragDisabled') ? item.isDragDisabled : item.isDefault)}
                  >
                    {({ draggableProps, dragHandleProps, innerRef }, snapshot) => (
                      <MenuItem
                        key={index}
                        classes={{ root: clsx(
                          classes.listItem,
                          (item.hasOwnProperty('isDragDisabled') ? item.isDragDisabled : item.isDefault) && classes.disabled,
                          snapshot.isDragging && classes.columnDragging)
                        }}
                        value={item.value}
                        onClick={!item.isDefault ? onClick(item.value) : undefined}
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <div className={classes.dragNDropDotsContainer}>
                          <DragNDropDots
                            className={clsx(
                              classes.dragNDropDots,
                              (item.hasOwnProperty('isDragDisabled') ? item.isDragDisabled : item.isDefault) && classes.blankDragNDropDots
                            )}
                          />
                        </div>
                        <Checkbox
                          checked={values.includes(item.value)}
                          disabled={item.isDefault}
                        />
                        <span className={classes.value}>
                          {item.content}
                        </span>
                      </MenuItem>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </ul>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Popover>
  );
}
