export enum Achievement {
  LOGIN = 'login',
  VIEW_PLAYER = 'view-player',
  SAVE_PREFERENCES = 'save-preferences',
  SAVE_PLAYER = 'save-player',
  SAVE_SEARCH = 'save-search',
  CHECK_MESSAGES = 'check-messages',
  VIEW_ACCOUNT_PAGE = 'view-account-page',
  VIEW_SIDE_BY_SIDE_PAGE = 'view-side-by-side-page',
  VIEW_COMPARE_PAGE = 'view-compare-page',
}
