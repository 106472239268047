import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { COLOR_ORANGE, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface BadgeProps {
  className?: string;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: '26px',
    backgroundColor: COLOR_ORANGE,
    color: COLOR_WHITE,
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '1em',
    cursor: 'inherit',
  },
  badgeLabel: {
    padding: theme.spacing(0, 1),
  },
}), { name: Badge.name });

export default function Badge (props: BadgeProps) {
  const {
    className,
    value,
  } = props;
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.badge, className)}
      classes={{
        label: classes.badgeLabel,
      }}
      label={value}
    />
  );
}
