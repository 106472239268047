import React from 'react';
import Icon from '../atoms/Icon';

function ManageUsersIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(3, 3)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M10,0 C6.1,0 3,3.1 3,7 C3,9.4375 4.209961,11.561523 6.070312,12.8125 C2.5100119,14.34733 0,17.893578 0,22 L2,22 C2,17.6 5.6,14 10,14 C13.9,14 17,10.9 17,7 C17,3.1 13.9,0 10,0 Z M10,2 C12.8,2 15,4.2 15,7 C15,9.8 12.8,12 10,12 C7.2,12 5,9.8 5,7 C5,4.2 7.2,2 10,2 Z M18.099609,13 L18.099609,15.099609 C17.499609,15.199609 16.900391,15.500781 16.400391,15.800781 L14.900391,14.300781 L13.5,15.699219 L15,17.199219 C14.6,17.699219 14.400781,18.3 14.300781,19 L12,19 L12,21 L14.099609,21 C14.199609,21.6 14.500781,22.200781 14.800781,22.800781 L13.300781,24.300781 L14.699219,25.699219 L16.199219,24.199219 C16.699219,24.499219 17.300391,24.800391 17.900391,24.900391 L17.900391,27 L19.900391,27 L19.900391,24.900391 C20.500391,24.800391 21.099609,24.499219 21.599609,24.199219 L23.099609,25.699219 L24.5,24.300781 L23,22.800781 C23.4,22.300781 23.599219,21.7 23.699219,21 L26,21 L26,19 L23.900391,19 C23.800391,18.4 23.499219,17.799219 23.199219,17.199219 L24.699219,15.699219 L23.300781,14.300781 L21.800781,15.800781 C21.300781,15.500781 20.699609,15.199609 20.099609,15.099609 L20.099609,13 L18.099609,13 Z M19,17 C20.7,17 22,18.3 22,20 C22,21.7 20.7,23 19,23 C17.3,23 16,21.7 16,20 C16,18.3 17.3,17 19,17 Z M19,19 C18.875,19 18.75,19.03125 18.632812,19.085938 C18.515625,19.140625 18.40625,19.21875 18.3125,19.3125 C18.21875,19.40625 18.140625,19.515625 18.085938,19.632812 C18.03125,19.75 18,19.875 18,20 C18,20.375 18.28125,20.75 18.632812,20.914062 C18.75,20.96875 18.875,21 19,21 C19.5,21 20,20.5 20,20 C20,19.5 19.5,19 19,19 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(ManageUsersIcon);
