import React from 'react';
import Page from './Page';
import DepthChartsPageTemplate from '../templates/DepthChartsPageTemplate';

export default function DepthChartsPage () {
  return (
    <Page title='Depth Charts'>
      <DepthChartsPageTemplate />
    </Page>
  );
}
