import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContactInfoPopover from '../molecules/ContactInfoPopover';
import { State } from '../redux/reducers';
import { COLOR_BLUE, COLOR_DARK_BLUE, COLOR_DARK_GRAY } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Player from '../types/Player';

interface ContactInfoWithPopoverProps {
  className?: string;
  name?: string;
  player: Player;
  isNFL?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  ContactInfoWithPopover: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    top: '38px'
  },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },

  icon: {
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_BLUE,
    }
  },

  ryzerLogo: {
    height: '18px',
    marginLeft: theme.spacing(0.5),
  },
  ml: {
    marginLeft: theme.spacing(0.5),
  }
}), { name: ContactInfoWithPopover.name });

function ContactInfoWithPopover (props: ContactInfoWithPopoverProps) {
  const classes = useStyles();
  const {
    className,
    player,
    name,
    isNFL,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isContactInfoAvailable = player && (player?.email || player?.cellPhone || player?.address) ? true : false;
  function onOpen (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onClose () {
    setAnchorEl(null);
  }

  return (
    <>
    <div
    className={clsx(classes.ContactInfoWithPopover, className)}
    >
      <p>{isContactInfoAvailable && name}</p>
      {
        player && player?.ryzerId && (
          <img
            className={classes.ryzerLogo}
            src='/images/ryzerLogo.png'
            alt='Ryzer'
          />
        )
      }
      {
        isContactInfoAvailable && (
          <span onClick={onOpen} className={classes.ml}>
            <InfoOutlinedIcon className={classes.icon} />
          </span>
        )
      }

      <ContactInfoPopover
        player={player}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    </div>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    isNFL: state.ui.nflAccess,
  };
};

export default connect(
  mapStateToProps,
)(ContactInfoWithPopover);