import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export function uploadProfilePhoto (file:Blob, name:string):Promise<string> {
  let body = new FormData();
  body.append('photo', file, name);

  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/upload-photo`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body,
  })
    .then(res => {
      if (res.ok) {
        return res.text();
      } else {
        return res.text()
          .then(errorMessage => {
            throw new Error(errorMessage);
          });
      }
    });
}
