import React from 'react';
import Icon from '../atoms/Icon';

function SideBySideIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(3, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M20,0 C17.790861,0 16,1.790861 16,4 C16,6.209139 17.790861,8 20,8 C22.209139,8 24,6.209139 24,4 C24,1.790861 22.209139,0 20,0 Z M14,14 C14,10.686 16.686,8 20,8 C23.314,8 26,10.686 26,14 M6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 C8.209139,8 10,6.209139 10,4 C10,1.790861 8.209139,0 6,0 Z M0,14 C0,10.686 2.686,8 6,8 C9.314,8 12,10.686 12,14 M1,19 L8,19 M10,19 L12,19 M25,19 L18,19 M16,19 L14,19 M4,16 L1,19 L4,22 M22,16 L25,19 L22,22' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SideBySideIcon);
