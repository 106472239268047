import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TeamIcon from '../icons/TeamIcon';
import { COLOR_DARK_GRAY } from '../styles/colors';
import Team from '../types/Team';

interface TeamLogoProps {
  className?: string;
  team?: Team;
}

export function getTeamLogo (team?:Team):string {
  return team?.logo247 || team?.logoESPN || team?.logoAlt || '';
}

const useStyles = makeStyles((theme: Theme) => ({
  teamLogo: {
    width: 'auto',
    height: '24px',
    color: COLOR_DARK_GRAY,
  },
}), { name: TeamLogo.name });

export default function TeamLogo (props: TeamLogoProps) {
  const {
    className,
    team,
  } = props;
  const classes = useStyles();
  const [imagePreloaded, setImagePreloaded] = useState<boolean>(false);

  const logo = getTeamLogo(team);

  useEffect(() => {
    if (logo && !imagePreloaded) {
      preloadImage(logo);
    }
  }, [logo, imagePreloaded]);

  function preloadImage (imageUrl:string) {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImagePreloaded(true);
    };
    image.onerror = () => {
      setImagePreloaded(false);
    };
  }

  return logo && imagePreloaded
    ? <img
        className={clsx(classes.teamLogo, className)}
        src={logo}
        alt={team?.name ? `${team.name} logo` : ''}
      />
    : <TeamIcon className={clsx(classes.teamLogo, className)} />;
}
