import React from 'react';
import Page from './Page';
import SavedSearchesPageTemplate from '../templates/SavedSearchesPageTemplate';

export default function SavedSearchesPage () {
  return (
    <Page title='Saved Searches'>
      <SavedSearchesPageTemplate />
    </Page>
  );
}
