import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../atoms/Loader';
import gql from '../services/gql';
import {
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_BORDER,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface CityAutoCompleteProps {
  className?: string;
  itemClassName?: string;
  label?: string;
  value?: string;
  onSelect: (city:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cityAutoComplete: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    position: 'relative',

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },
  },
  open: {
    '& $label': {
      display: 'none',
    },
  },
  withValue: {
    '& $label': {
      display: 'none',
    },
  },

  endAdornment: {
    display: 'none',
  },
  option: {
    padding: theme.spacing(0.5, 1),
  },
  input: {
    marginTop: '0 !important',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },

    '& > input': {
      padding: theme.spacing(2, 4, 2, 2),
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
  },
  menuItemValue: {
    margin: theme.spacing(0, 0.5),
  },
}), { name: CityAutoComplete.name });

export default function CityAutoComplete (props: CityAutoCompleteProps) {
  const {
    className,
    itemClassName,
    label = 'Combine City',
    value,
    onSelect,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<string[]>([]);

  const throttledFetchCity = useCallback(throttle(fetchCity, 1000), []);

  function fetchCity(searchText:string) {
    if (!!searchText.trim()) {
      setLoading(true);

      gql(`
        autocompleteHSCombineCity(
          text: "${searchText.trim()}",
        )
      `)
        .then((data:any) => data.autocompleteHSCombineCity as string[])
        .then((autocompleteHSCombineCity:string[]) => setCities(autocompleteHSCombineCity.sort()))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }

  function handleKeyUp (event:any) {
    throttledFetchCity.cancel();
    throttledFetchCity(event.target.value || '');
  }

  return (
    <Autocomplete
      className={clsx(
        classes.cityAutoComplete,
        open && classes.open,
        value && classes.withValue,
        className,
      )}
      classes={{ endAdornment: classes.endAdornment, option: classes.option }}
      options={cities || []}
      getOptionLabel={(city:string) => city}
      renderOption={(city:string) => (
        <div className={clsx(classes.menuItem, itemClassName)}>
          <div className={classes.menuItemValue}>{city}</div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
          }}
          placeholder={label}
          variant='standard'
        />
      )}
      loading={loading}
      loadingText={<Loader inProgress/>}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event:any, city: string | null) => onSelect(city || '')}
      onKeyUp={handleKeyUp}
    />
  );
}
