import React from 'react';
import Icon from '../atoms/Icon';

function PositionIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M26.704 4.74v24.89H5.37V4.74h4.741V2.37H3V32h26.074V2.37h-7.111v2.37z'/>
        <path d='M19.39 2.37a3.556 3.556 0 00-6.706 0H8.926v7.111h14.222v-7.11H19.39zm1.388 2.37v2.371h-9.482v-2.37h3.556V3.556a1.185 1.185 0 112.37 0V4.74h3.556zM10.111 24.889a1.185 1.185 0 100 2.37 1.185 1.185 0 000-2.37zm10.667-9.482a1.185 1.185 0 100 2.37 1.185 1.185 0 000-2.37zM9.392 13.05l4.262 4.223-1.666 1.683-4.262-4.223z'/>
        <path d='M9.424 18.968l-1.675-1.676 4.238-4.255 1.675 1.676zM20.063 21.343l4.262 4.223-1.666 1.682-4.262-4.222z'/>
        <path d='M20.095 27.26l-1.675-1.677 4.239-4.254 1.674 1.675zM18.145 17.557l1.513 1.824-6.963 5.778-1.513-1.825 6.963-5.777zm3.818-5.705v2.37h-2.37v-2.37h2.37z'/>
        <path d='M18.407 13.037h4.741l-2.37-2.37z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(PositionIcon);
