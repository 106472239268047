import React from 'react';
import Page from './Page';
import UserPageTemplate from '../templates/UserPageTemplate';

export default function UserPage () {
  return (
    <Page title='User'>
      <UserPageTemplate />
    </Page>
  )
}