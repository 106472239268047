import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
} from '../styles/colors';
import AlertIcon from '../icons/AlertIcon';
import Tooltip from '../atoms/Tooltip';

interface AlertButtonProps {
  className?: string;
  mode: string;
  active: boolean;
  onClick?: (event: any) => void;
}

export const ALERT_MODE = {
  SET_ALERT: 'alert',
  REMOVE: 'remove',
};

const useStyles = makeStyles((theme: Theme) => ({
  alertButton: {
    appearance: 'none',
    border: 0,
    padding: 0,
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  bellIcon: {
    width: '24px',
    height: '24px',
    padding: theme.spacing(0, 1),
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',
    boxSizing: 'content-box',
  },
  active: {
    '& $bellIcon': {
      color: COLOR_GREEN,
    },
  },
  modeSetAlert: {
    '& $bellIcon': {
      '&:hover': {
        color: COLOR_ORANGE,
      },
    },
  },
  modeRemove: {
    '& $bellIcon': {
      '&:hover': {
        color: COLOR_RED,
      },
    },
  },
}), { name: AlertButton.name });

export default function AlertButton (props: AlertButtonProps) {
  const {
    className,
    mode = ALERT_MODE.SET_ALERT,
    active = false,
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(
        classes.alertButton,
        {
          [classes.active]: active,
          [classes.modeSetAlert]: mode === ALERT_MODE.SET_ALERT,
          [classes.modeRemove]: mode === ALERT_MODE.REMOVE,
        },
        className,
      )}
      onClick={onClick}
    >
      <Tooltip title={mode === ALERT_MODE.SET_ALERT ? 'Alert' : 'Remove'}>
        <AlertIcon className={classes.bellIcon} />
      </Tooltip>
    </button>
  );
}
