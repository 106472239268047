export const CONFIGURATIONS_LOADING = 'configurations/CONFIGURATIONS_LOADING';
export const CONFIGURATIONS_LOADED = 'configurations/CONFIGURATIONS_LOADED';
export const CURRENT_RECRUITING_YEAR = 'configurations/CURRENT_RECRUITING_YEAR';
export const NFL_DRAFT_YEAR = 'configurations/NFL_DRAFT_YEAR';
export const NEW_HS_PLAYERS_DAYS = 'configurations/NEW_HS_PLAYERS_DAYS';
export const NEW_COLLEGE_PLAYERS_DAYS = 'configurations/NEW_COLLEGE_PLAYERS_DAYS';
export const DASHBOARD_ROLLOVER_DAY = 'configurations/DASHBOARD_ROLLOVER_DAY';
export const DASHBOARD_ROLLOVER_MONTH = 'configurations/DASHBOARD_ROLLOVER_MONTH';
export const ROLLOVER_DAY = 'configurations/ROLLOVER_DAY';
export const ROLLOVER_MONTH = 'configurations/ROLLOVER_MONTH';
export const TRACK_DATE_PAST_YEAR_LIMIT = 'configurations/TRACK_DATE_PAST_YEAR_LIMIT';
export const ROLLOVER_DATE_TRANSFER_PORTAL = 'configurations/ROLLOVER_DATE_TRANSFER_PORTAL';
export const ROLLOVER_MONTH_TRANSFER_PORTAL = 'configurations/ROLLOVER_MONTH_TRANSFER_PORTAL';

export function setConfigurationsLoadingAction (configurationsLoading:boolean) {
  return {
    type: CONFIGURATIONS_LOADING,
    configurationsLoading,
  };
}

export function setConfigurationsLoadedAction (configurationsLoaded:boolean) {
  return {
    type: CONFIGURATIONS_LOADED,
    configurationsLoaded,
  };
}

export function setCurrentRecruitingYearAction (currentRecruitingYear:number) {
  return {
    type: CURRENT_RECRUITING_YEAR,
    currentRecruitingYear,
  };
}

export function setNFLDraftYearAction (nflDraftYear:number) {
  return {
    type: NFL_DRAFT_YEAR,
    nflDraftYear,
  };
}

export function setNewHSPlayersDaysAction (newHSPlayersDays:number) {
  return {
    type: NEW_HS_PLAYERS_DAYS,
    newHSPlayersDays,
  };
}

export function setNewCollegePlayersDaysAction (newCollegePlayersDays:number) {
  return {
    type: NEW_COLLEGE_PLAYERS_DAYS,
    newCollegePlayersDays,
  };
}

export function setDashboardRolloverDayAction (dashboardRolloverDay:number) {
  return {
    type: DASHBOARD_ROLLOVER_DAY,
    dashboardRolloverDay,
  };
}

export function setDashboardRolloverMonthAction (dashboardRolloverMonth:number) {
  return {
    type: DASHBOARD_ROLLOVER_MONTH,
    dashboardRolloverMonth,
  };
}

export function setRolloverDayAction (rolloverDay:number) {
  return {
    type: ROLLOVER_DAY,
    rolloverDay,
  };
}

export function setRolloverMonthAction (rolloverMonth:number) {
  return {
    type: ROLLOVER_MONTH,
    rolloverMonth,
  };
}

export function setTrackDatePastYearLimitAction (trackDatePastYearLimit:number) {
  return {
    type: TRACK_DATE_PAST_YEAR_LIMIT,
    trackDatePastYearLimit,
  };
}

export function setRolloverDateTransferPortal (rolloverDateTransferPortal:number) {
  return {
    type: ROLLOVER_DATE_TRANSFER_PORTAL,
    rolloverDateTransferPortal,
  };
}

export function setRolloverMonthTransferPortal (rolloverMonthTransferPortal:number) {
  return {
    type: ROLLOVER_MONTH_TRANSFER_PORTAL,
    rolloverMonthTransferPortal,
  };
}