import React from 'react';
import Icon from '../atoms/Icon';

function AcademicsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(5, 5)' fill='currentColor' fillRule='nonzero'>
          <path d='M4,0 C1.8026661,0 0,1.8026661 0,4 L0,5 C0,6.210938 0.5762461,7.264444 1.4355469,8 C0.5762461,8.735556 0,9.789062 0,11 C0,12.210938 0.5762461,13.264444 1.4355469,14 C0.5762461,14.735556 0,15.789062 0,17 L0,18 C0,20.197334 1.8026661,22 4,22 L22.617188,22 L21.894531,20.552734 C21.894531,20.552734 21,18.555556 21,17.5 C21,16.444444 21.894531,14.447266 21.894531,14.447266 L22.617188,13 L19.519531,13 C19.468541,12.92413 19.471001,12.95568 19.394531,12.802734 C19.20416,12.421991 19,11.833333 19,11 C19,10.166667 19.20416,9.578009 19.394531,9.197266 C19.471001,9.044321 19.468541,9.075865 19.519531,9 L22.617188,9 L21.894531,7.552734 C21.894531,7.552734 21,5.555556 21,4.5 C21,3.4444444 21.894531,1.4472656 21.894531,1.4472656 L22.617188,0 L4,0 Z M4,2 L19.511719,2 C19.257527,2.7203112 19,3.6007983 19,4.5 C19,5.399202 19.257527,6.279689 19.511719,7 L4,7 C2.8833339,7 2,6.116666 2,5 L2,4 C2,2.8833339 2.8833339,2 4,2 Z M4,9 L17.449219,9 C17.238855,9.56932 17,10.134902 17,11 C17,11.865098 17.238855,12.43068 17.449219,13 L14,13 L14,15 L19.511719,15 C19.257527,15.720311 19,16.600798 19,17.5 C19,18.399202 19.257527,19.279689 19.511719,20 L4,20 C2.8833339,20 2,19.116666 2,18 L2,17 C2,15.883334 2.8833339,15 4,15 L6,15 L6,13 L4,13 C2.8833339,13 2,12.116666 2,11 C2,9.883334 2.8833339,9 4,9 Z M8,11 L8,18 L10,16 L12,18 L12,11 L8,11 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(AcademicsIcon);
