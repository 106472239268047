import React from 'react';
import Icon from '../atoms/Icon';

function DashboardIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        transform='translate(5,5)'
        fill='none'
        fillRule='evenodd'
      >
        <circle
          stroke='currentColor'
          strokeWidth='2'
          cx='11'
          cy='11'
          r='11'
        />
        <circle
          fill='currentColor'
          fillRule='nonzero'
          cx='11'
          cy='11'
          r='2'
        />
        <path
          d='M11 11l5.478-5.478'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M16.792 9.45a5.963 5.963 0 01-1.549 5.793l1.414 1.414c2.369-2.369 2.934-5.864 1.705-8.776l-1.57 1.569zM14.119 3.638c-2.912-1.229-6.408-.664-8.776 1.705-3.119 3.119-3.119 8.194 0 11.313l1.414-1.414A5.959 5.959 0 015 11c0-1.603.624-3.109 1.757-4.243C8.32 5.194 10.533 4.7 12.531 5.226l1.588-1.588z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(DashboardIcon);
