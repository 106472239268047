import { Dispatch,  } from 'redux';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import { setSelectedCollegeTeamAction, setSelectedNFLTeamAction } from '../actions/ui';
import Team from '../../types/Team';

export function setSelectedCollegeTeam (selectedCollegeTeam:Team | undefined) {
  return (dispatch:Dispatch) => {
    clientStorage.save(StorageKey.SELECTED_COLLEGE_TEAM, selectedCollegeTeam);
    dispatch(setSelectedCollegeTeamAction(selectedCollegeTeam));
  };
}

export function setSelectedNFLTeam (selectedNFLTeam:Team | undefined) {
  return (dispatch:Dispatch) => {
    clientStorage.save(StorageKey.SELECTED_NFL_TEAM, selectedNFLTeam);
    dispatch(setSelectedNFLTeamAction(selectedNFLTeam));
  };
}
