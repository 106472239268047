import React from 'react';
import Page from './Page';
import MyAccountPageTemplate from '../templates/MyAccountPageTemplate';

export default function MyAccountPage () {
  return (
    <Page title='My Account'>
      <MyAccountPageTemplate />
    </Page>
  )
}