import React from 'react';
import Icon from '../atoms/Icon';

function CoachIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M5 18.286c0 1.509.98 2.794 2.353 3.245.979 3.042 2.162 5.74 3.073 6.703 3.306 3.499 8.698 3.499 12.005 0 .91-.962 2.093-3.661 3.072-6.703a3.418 3.418 0 001.211-5.796v-3.164H24.43v4.4l.57.33c.355.205.572.572.572.985 0 .59-.457 1.08-1.052 1.128l-.771.063-.228.74c-.906 2.948-2.109 5.768-2.75 6.447-2.406 2.545-6.278 2.545-8.683 0-.642-.679-1.845-3.499-2.751-6.447l-.228-.74-.771-.063a1.136 1.136 0 01-1.051-1.128c0-.408.22-.777.58-.984l.572-.331-.01-4.831-2.285.004.007 3.591A3.405 3.405 0 005 18.285z'/>
        <path d='M13 17.143a1.143 1.143 0 100 2.286 1.143 1.143 0 000-2.286zm6.857 0a1.143 1.143 0 100 2.286 1.143 1.143 0 000-2.286zM26.714 12.142c0 .823-.844 1.376-1.599 1.048-.113-.05-.344-.143-.678-.265a25.085 25.085 0 00-1.912-.61c-2.002-.554-4.075-.886-6.096-.886-2.022 0-4.095.332-6.097.886-.703.195-1.345.403-1.912.61-.334.122-.565.215-.678.265a1.143 1.143 0 01-1.6-1.048c0-7.282 2.44-11 10.287-11 7.846 0 10.285 3.718 10.285 11zM16.43 3.429c-5.734 0-7.643 2.083-7.953 7.059.393-.129.809-.255 1.246-.376 2.187-.605 4.457-.97 6.707-.97 2.249 0 4.52.365 6.706.97.437.121.854.247 1.246.376-.31-4.976-2.22-7.06-7.952-7.06z'/>
        <path d='M24.429 12.571c0 1.565-.258 2.08-.688 2.198-.503.14-1.025.056-3.27-.472-1.706-.402-2.81-.583-4.042-.583-1.25 0-2.37.195-4.093.628-2.233.562-2.74.65-3.229.514-.416-.116-.678-.657-.678-2.285a1.143 1.143 0 10-2.286 0c0 2.536.726 4.036 2.352 4.487 1.069.297 1.592.206 4.399-.499 1.552-.39 2.521-.559 3.535-.559 1.01 0 1.972.157 3.519.522 2.794.658 3.338.744 4.402.45 1.626-.45 2.364-1.925 2.364-4.4a1.143 1.143 0 10-2.285 0zM16.514 6.85l-.035.007h-.05v.01c-.057.02 0 .061 0-.01V5.714h2.285c0-1.01-.479-1.759-1.255-2.1a2.275 2.275 0 00-1.081-.185h-.002c-1.324.024-2.233.95-2.233 2.285v1.143c0 1.344.922 2.274 2.26 2.286.324.01.694-.042 1.082-.224.754-.353 1.23-1.081 1.23-2.062h-2.213a.1.1 0 01.012-.007zM18.714 22.857v2.286h-1.143v2.286h-2.285v-2.286h-1.143v-2.286zM16.429 0a1.143 1.143 0 100 2.286 1.143 1.143 0 000-2.286z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(CoachIcon);