import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { COLOR_BLUE, COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface DropDownProps {
  className?: string;
  selectorClassName?: string;
  selectorRootClassName?: string;
  emptyItemClassName?: string;
  items: DropDownItem[];
  emptyItem?: DropDownItem;
  value: any;
  error?: string;
  onChange: (value:any) => void;
  onBlur?: () => void;
}

export interface DropDownItem {
  value: any;
  content: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropDown: {
    minWidth: '110px',
    outlineColor: COLOR_BLUE,
  },
  dropDownEmpty: {
  },

  selector: {
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #efefef',
    boxSizing: 'content-box',

    '&:hover': {
      borderColor: COLOR_BLUE,
    },

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '& fieldset': {
      border: 0,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    },
  },
  selectorRoot: {
    padding: '8px 32px 8px 10px',
    display: 'flex',
    alignItems: 'center',
    background: `${COLOR_WHITE} !important`,
    fontFamily: FONT_PROXIMA_NOVA,
    lineHeight: 1,
  },
  icon: {
    display: 'none',
  },
  noneItem: {
    padding: theme.spacing(1, 4),
  },
}), { name: DropDown.name });

export default function DropDown (props: DropDownProps) {
  const {
    items = [],
    value = '',
    emptyItem,
    className,
    selectorClassName,
    selectorRootClassName,
    emptyItemClassName,
    error,
    onChange = () => {},
    onBlur = () => {},
  } = props;
  const classes = useStyles();

  function onValueChange (event:any) {
    onChange(event.target.value);
  }

  return (
    <FormControl
      className={clsx(
        classes.dropDown,
        className,
        value === '' && classes.dropDownEmpty,
      )}
      variant='outlined'
      error={!!error}
    >
      <Select
        className={clsx(classes.selector, selectorClassName)}
        classes={{
          root: clsx(classes.selectorRoot, selectorRootClassName),
          icon: classes.icon,
        }}
        value={value}
        onChange={onValueChange}
        onBlur={onBlur}
        displayEmpty={!!emptyItem}
      >
        {emptyItem && (
          <MenuItem
            key='none'
            value={emptyItem.value}
            classes={{ root: clsx(classes.noneItem, emptyItemClassName) }}
          >
            {emptyItem.content}
          </MenuItem>
        )}

        {items.map((item:DropDownItem, index:number) => (
          <MenuItem
            key={index}
            value={item.value}
          >
            {item.content}
          </MenuItem>
        ))}
      </Select>
      {!!error && (<FormHelperText>{error}</FormHelperText>)}
    </FormControl>
  );
}
