import React from 'react';
import Icon from '../atoms/Icon';

function HuddleVisionIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref} width="405" height="80" viewBox="0 0 405 80"
    >
      <g 
        fill="#000000"
        fillRule='nonzero'
      >
        <path d="M40.0082 0L32.688 7.32016L27.259 12.7491H29.1828V18.5042L40.0136 7.67341L72.3375 39.9973L57.17 55.1648V62.8381L62.599 57.4146L80.0163 39.9973L40.0082 0ZM50.8335 61.4904L40.0027 72.3212L22.8517 55.1702L7.68426 40.0027L22.8517 24.8353V17.151L0 40.0027L40.0027 80L52.7519 67.2509H50.8281V61.4904H50.8335Z" fill="#000000"/>
        <path d="M50.8335 31.8565V37.2855H29.1774V12.7546H22.8571V56.4201H17.4282V7.32015H34.6063V31.8565H50.8335Z" fill="#000000"/>
        <path d="M62.5882 23.5799V72.6799H45.4045V48.1489H29.1774V42.7199H50.8335V67.2454H57.1592V23.5799H62.5882Z" fill="#000000"/>
        <path d="M120.204 30.1284V49.8444H113.53V42.1276H103.672V49.8444H96.9663V30.1284H103.672V37.3399H113.53V30.1284H120.204Z" fill="#000000"/>
        <path d="M144.925 30.1284V41.5896C144.925 49.2249 139.572 50.2629 133.969 50.2629C128.366 50.2629 123.013 49.2195 123.013 41.5896V30.1284H129.687V41.057C129.687 45.0839 131.29 45.7632 133.969 45.7632C136.616 45.7632 138.251 45.0893 138.251 41.057V30.1284H144.925Z" fill="#000000"/>
        <path d="M170.684 39.9864C170.684 48.2956 164.435 49.8444 158.261 49.8444H147.729V30.1284H158.261C164.435 30.1284 170.684 31.7044 170.684 39.9864ZM163.929 39.9864C163.929 34.9161 160.745 34.6933 156.777 34.6933H154.413V45.3122H156.777C160.745 45.3067 163.929 45.0839 163.929 39.9864Z" fill="#000000"/>
        <path d="M195.889 39.9864C195.889 48.2956 189.639 49.8444 183.466 49.8444H172.934V30.1284H183.466C189.634 30.1284 195.889 31.7044 195.889 39.9864ZM189.128 39.9864C189.128 34.9161 185.944 34.6933 181.977 34.6933H179.613V45.3122H181.977C185.944 45.3067 189.128 45.0839 189.128 39.9864Z" fill="#000000"/>
        <path d="M217.594 49.8444H198.133V30.1284H204.807V45.3122H217.594V49.8444Z" fill="#000000"/>
        <path d="M239.56 49.8444H219.844V30.1284H239.277V34.6933H226.517V37.454H238.489V42.0189H226.517V45.3122H239.56V49.8444Z" fill="#000000"/>
        <path d="M266.623 30.1284L257.499 49.8444H249.782L240.685 30.1284H247.614L253.64 44.8883L259.667 30.1284H266.623Z" fill="#000000"/>
        <path d="M274.726 30.1284V49.8444H268.02V30.1284H274.726Z" fill="#000000"/>
        <path d="M300.099 43.5894C300.099 48.3228 296.072 50.2629 288.328 50.2629C279.992 50.2629 276.693 47.5892 276.693 43.4481H283.676C283.676 45.2795 285.932 45.785 288.692 45.785C291.339 45.785 292.915 45.3882 292.915 44.2905C292.915 42.9645 291.002 43.0514 285.339 42.1765C279.285 41.2472 277.144 39.981 277.144 36.0356C277.144 32.0359 280.329 29.7262 287.986 29.7262C296.349 29.7262 298.828 32.7423 298.828 36.1497H292.04C292.04 34.7966 290.464 34.2042 287.899 34.2042C285.198 34.2042 283.785 34.6009 283.785 35.7259C283.785 37.079 286.236 37.1931 289.616 37.5301C295.79 38.1822 300.099 38.6061 300.099 43.5894Z" fill="#000000"/>
        <path d="M308.767 30.1284V49.8444H302.061V30.1284H308.767Z" fill="#000000"/>
        <path d="M336.678 39.9864C336.678 47.5348 330.228 50.2683 323.831 50.2683C317.44 50.2683 311.017 47.5348 311.017 39.9864C311.017 32.4652 317.44 29.7317 323.831 29.7317C330.228 29.7317 336.678 32.4652 336.678 39.9864ZM329.891 39.9864C329.891 36.4106 327.722 34.2423 323.837 34.2423C319.978 34.2423 317.783 36.4106 317.783 39.9864C317.783 43.5894 319.978 45.7632 323.837 45.7632C327.722 45.7578 329.891 43.5894 329.891 39.9864Z" fill="#000000"/>
        <path d="M361.622 30.1284V49.8444H354.552L345.596 38.1279V49.8444H338.923V30.1284H345.993L354.949 41.8722V30.1284H361.622Z" fill="#000000"/>
        <path d="M369.98 47.1979C369.98 48.888 368.6 50.2683 366.91 50.2683C365.22 50.2683 363.867 48.888 363.867 47.1979C363.867 45.535 365.22 44.1546 366.91 44.1546C368.6 44.1546 369.98 45.535 369.98 47.1979Z" fill="#000000"/>
        <path d="M388.539 46.8012H379.018L377.779 49.8444H370.823L379.92 30.1284H387.637L396.761 49.8444H389.805L388.539 46.8012ZM386.708 42.2363L383.778 35.1118L380.876 42.2363H386.708Z" fill="#000000"/>
        <path d="M404.869 30.1284V49.8444H398.163V30.1284H404.869Z" fill="#000000"/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(HuddleVisionIcon);
