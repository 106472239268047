import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PdfPageContainer from './PdfPageContainer';
import {
  COLOR_LIGHT_GRAY,
  COLOR_BACKGROUND_LIGHT,
} from '../styles/colors';
import MEDIA from '../styles/media';

interface PdfPageTemplateProps {
  className?: string;
  headerIconClassName?: string;
  children: any;
  icon?: any;
  headerTitle: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  page: {
    padding: theme.spacing(5, 0),

    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  pdfHeader: {
    height: '200px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
  },
  pdfHeaderTitle: {
    fontSize: theme.typography.pxToRem(25),
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(1),
  },

  logo: {
    width: '125px',
    marginLeft: 'auto',
  },

  tableWrapper: {
    padding: theme.spacing(5),
  },

  titleRow: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 'none',
    padding: theme.spacing(1.5),
  },
  titleText: {
    ...theme.typography.h2,
    margin: theme.spacing(0,0,0,1),
  },

  [MEDIA.PRINT]: {
    page: {
      pageBreakAfter: 'always',
      pageBreakInside: 'avoid',
      size: 'letter',
      margin: 0,
      zoom: '75%',
      printColorAdjust: 'exact',
      filter: 'opacity(1)',

      '&:last-of-type': {
        pageBreakAfter: 'avoid',
      },
    },
  },
}), { name: PdfPageTemplate.name });

export default function PdfPageTemplate (props:PdfPageTemplateProps) {
  const classes = useStyles();
  const {
    headerIconClassName,
    children,
    icon,
    headerTitle,
  } = props;

  const Icon = icon;

  return (
    <PdfPageContainer>
      <div className={classes.page}>
        <div className={classes.pdfHeader}>
          <h1 className={classes.pdfHeaderTitle}>
            {!!icon && (
              <Icon className={clsx(classes.headerIcon, headerIconClassName)} />
            )}
            {headerTitle}
          </h1>

          <img
            className={classes.logo}
            src='/images/tracking-football-logo.svg'
            alt='Tracking Football'
          />
        </div>

        {children}
      </div>
    </PdfPageContainer>
  )
}