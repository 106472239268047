import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SideBySideIcon from '../icons/SideBySideIcon';
import BackIcon from '../icons/BackIcon';
import Loader from '../atoms/Loader';
import SideBySidePlayersTable from '../organisms/SideBySidePlayersTable';
import PageContainer from './PageContainer';
import PdfPageContainer from './PdfPageContainer';
import { getQueryParameter } from '../services/query-parameters';
import gql from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_TEXT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_BACKGROUND_LIGHT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import SideBySidePlayer from '../types/SideBySide';

interface SideBySideTemplateProps {
  printed?: boolean;
}

const useStyles = makeStyles(theme => ({
  sideBySidePageTemplate: {
    minHeight: '100vh',
    position: 'relative',
  },

  sideBySidePageTemplatePdf: {
    backgroundColor: COLOR_WHITE,
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 4, 0),
  },
  action: {
    display: 'flex',
  },
  backBtn: {
    color: COLOR_BLUE,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },

  headerTitle: {
    paddingTop: theme.spacing(3),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_TEXT,
    ...theme.typography.h2,
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  sideBySide: {
    padding: theme.spacing(4),
  },

  pageContainer: {
    overflow: 'visible',
    position: 'relative',
    fontVariantLigatures: 'no-common-ligatures',
  },
  page: {
    pageBreakAfter: 'avoid',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'always',
    padding: theme.spacing(4, 0),

    '&:nth-of-type(1)': {
      padding: 0,
    },
    '&:not(:nth-of-type(1))': {
      paddingBottom: 0,
      paddingTop: '20px',
    },
    '&:not(:nth-of-type(1)) $pdfHeader': {
      display: 'none',
    },

    '&:last-of-type': {
      pageBreakAfter: 'auto',
    },

    [MEDIA.PRINT]: {
      size: 'letter',
      margin: 0,
      printColorAdjust: 'exact',
      filter: 'opacity(1)',
    },
  },
  pdfHeader: {
    height: '200px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 5, 4, 8),
  },
  logo: {
    width: '125px',
    marginLeft: 'auto',
  },

  printedTable: {
    width: 1440,
    margin: '0 auto',
  },

  [MEDIA.MOBILE]: {
    sideBySide: {
      padding: theme.spacing(2),
    },

    header: {
      margin: theme.spacing(2, 2, 0),
    },
  },
}), { name: SideBySideTemplate.name });

export default function SideBySideTemplate (props:SideBySideTemplateProps) {
  const {
    printed = false,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const playersIds = getQueryParameter('playerIds')
    .split(',')
    .map(param => Number(param));

  const [loading, setLoading] = useState<boolean>(false);
  const [players, setPlayers] = useState<SideBySidePlayer[]>([]);

  useEffect(() => {
    if (!!playersIds.length && !players.length) {
      fetchPlayers();
    }
  }, [playersIds, players.length]);

  useEffect(() => {
    if (printed) {
      const style = document.createElement('style');
      style.innerHTML = `@page {size: landscape;}`;
      style.id = 'page-orientation';
      document.head.appendChild(style);
    }
  }, [printed]);

  function fetchPlayers () {
    setLoading(true);

    gql(`
      sideBySidePlayers (playerIds: [${playersIds}]) {
        id
        slug
        firstName
        lastName
        photoUrl
        highSchools
        pai
        combine
        compStar
        _247Star
        bestTrackEvent
        playerColleges {
          id
          isPrimary
          team {
            id
            name
            logo247
            logoESPN
            logoAlt
          }
        }
        playerNFLTeams {
          id
          isPrimary
          team {
            id
            name
            logo247
            logoESPN
            logoAlt
          }
        }
        hsCombines {
          height
          weight
          _10Split
          powerToss
          shuttle
          _40mDash
          verticalJump
          broadJump
          _3Cone
        }
      }
    `)
    .then((data:any) => data.sideBySidePlayers as SideBySidePlayer[])
    .then((players:SideBySidePlayer[]) => {
      if (players && players.length) {
        const orderedPlayers:SideBySidePlayer[] = playersIds.map((playerId:number) => (
          players.filter((player:SideBySidePlayer) => player.id === playerId)[0]
        ));

        setPlayers(orderedPlayers)
      }
    })
    .catch(console.error)
    .finally(() => setLoading(false));
  }

  return (
    <>
      {!printed && (
        <PageContainer className={classes.sideBySidePageTemplate}>
          <Loader inProgress={loading} />

          <Paper className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.action}>
                <span
                  className={classes.backBtn}
                  onClick={() => history.goBack()}
                >
                  <BackIcon className={classes.icon} />Back
                </span>
              </div>
              <h1 className={classes.headerTitle}>
                <SideBySideIcon className={classes.icon} />
                Side-by-Side
              </h1>
            </div>
            <SideBySidePlayersTable
              className={classes.sideBySide}
              playersIds={playersIds}
              players={players}
              setPlayers={setPlayers}
            />
          </Paper>
        </PageContainer>
      )}

      {printed && (
        <PdfPageContainer className={classes.pageContainer}>
          <Loader inProgress={loading} />

          <div className={classes.page}>
            <div className={classes.pdfHeader}>
              <h1 className={classes.headerTitle}>
                <SideBySideIcon className={classes.icon} />
                Side-by-Side
              </h1>

              <img
                className={classes.logo}
                src='/images/tracking-football-logo.svg'
              />
            </div>

            <SideBySidePlayersTable
              className={clsx(classes.sideBySide, classes.printedTable)}
              playersIds={playersIds}
              printed={printed}
              players={players}
              setPlayers={setPlayers}
            />
          </div>
        </PdfPageContainer>
      )}
    </>
  )
}
