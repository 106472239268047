import React from 'react';
import Icon from '../atoms/Icon';

function AllConferenceIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(6, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M0,10.25 C0,17.709 10,21.5 10,21.5 C10,21.5 20,17.766 20,10.25 C20,7.16 20,2.375 20,2.375 C17.533,2.375 10,0.5 10,0.5 C10,0.5 2.464,2.375 0,2.375 C0,2.375 0,7.16 0,10.25 Z' />
          <path d='M20,11 L1.35760968,14.7284781 M0,10 L20,6' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(AllConferenceIcon);
