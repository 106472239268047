import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import { createPassword, resendCreatePasswordEmail, validatePassword } from '../services/user';
import { getQueryParameter } from '../services/query-parameters';
import { updatePageTitle } from '../services/page-title';
import * as clientStorage from '../services/client-storage';
import { resetReduxStoreAction } from '../redux/actions/root';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_TEXT } from '../styles/colors';

const useStyles = makeStyles(theme => ({
  createPasswordForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
  },

  title: {
    ...theme.typography.h1,
    margin: 0,
  },

  message: {
    padding: 0,
    margin: theme.spacing(4, 0, 3),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: COLOR_TEXT,
  },

  link: {
    color: COLOR_BLUE,
  },

  input: {
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    width: 'auto',
    padding: theme.spacing(2, 4),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
  },
}), { name: CreatePasswordForm.name });

function CreatePasswordForm () {
  const classes = useStyles();
  const history = useHistory();

  const token = getQueryParameter('token');

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [failedToVerify, setFailedToVerify] = useState<boolean>(false);
  const [newEmailSuccessfullySent, setNewEmailSuccessfullySent] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    updatePageTitle('Create Password');
  }, []);

  function onSubmit (event:FormEvent) {
    event.preventDefault();

    if (checkFieldsAreValid(password, confirmPassword)) {
      setLoading(true);

      createPassword(token, password)
        .then(() => {
          navigateToLogin();
        })
        .catch(error => {
          console.error(error);
          showToast('Failed to verify the token');
          setFailedToVerify(true);
        })
        .finally(() => setLoading(false));
    }
  }

  function navigateToLogin () {
    clientStorage.clearAll();
    resetReduxStoreAction();
    history.push('/login');
  }

  function onResendEmail (event:FormEvent) {
    event.preventDefault();

    setLoading(true);

    resendCreatePasswordEmail(token)
      .then((success:boolean) => setNewEmailSuccessfullySent(success))
      .catch(() => {
        showToast('Something went wrong. Try refreshing the page or contact the support team.');
      })
      .finally(() => setLoading(false));
  }

  function checkFieldsAreValid (password:string, confirmPassword:string) {
    let { valid, error } = validatePassword(password);

    setPasswordError(error);

    if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords don\'t match.');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    return valid;
  }

  function showToast (message:string) {
    setToastMessage(message);
    setToastVisible(true);
  }

  return (
    <>
      <form
        className={classes.createPasswordForm}
        onSubmit={failedToVerify ? onResendEmail : onSubmit}
      >
        <Loader inProgress={loading} />

        <h1 className={classes.title}>Create Password</h1>

        {(!token && !newEmailSuccessfullySent) && (
          <p className={classes.message}>
            Open this page using the link in the "Welcome" email.
            <br />
            <br />
            If you didn't receive the email please
            contact our <Link className={classes.link} to='/help'>Support</Link> team.
          </p>
        )}

        {(failedToVerify && !newEmailSuccessfullySent) && (
          <p className={classes.message}>
            Your token has expired.
            <br />
            Please click the button below, and we'll send you an email with the new token.
          </p>
        )}

        {newEmailSuccessfullySent && (
          <p className={classes.message}>
            We've sent you a new email, please close this page.
          </p>
        )}

        {(!!token && !failedToVerify && !newEmailSuccessfullySent) && (
          <>
            <Input
              className={classes.input}
              type='password'
              label='Password'
              value={password}
              error={passwordError}
              onChange={(value:string) => setPassword(value.trim())}
            />

            <Input
              className={classes.input}
              type='password'
              label='Repeat Password'
              value={confirmPassword}
              error={confirmPasswordError}
              onChange={(value:string) => setConfirmPassword(value.trim())}
            />
          </>
        )}

        {(!!token && !newEmailSuccessfullySent) && (
          <div className={classes.submitWrapper}>
            <Button
              className={classes.submitButton}
              type='submit'
              primary
              disabled={loading}
            >
              {failedToVerify ? 'Resend Email' : 'Save'}
            </Button>
          </div>
        )}
      </form>

      <Toast
        visible={toastVisible}
        type={ToastType.ERROR}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
}

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      resetReduxStoreAction,
    },
    dispatch
  )
};

export default connect(
  null,
  mapDispatchToProps,
)(CreatePasswordForm);
