import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SpeedIcon from '../icons/SpeedIcon';
import PowerIcon from '../icons/PowerIcon';
import AgilityIcon from '../icons/AgilityIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Tooltip from '../atoms/Tooltip';
import { COLOR_BLACK, COLOR_TEXT } from '../styles/colors';
import EventRating from '../types/EventRating';
import ProCombineRating from '../types/ProCombineRating';
import { addSpaceBetweenNumAndAlpha } from '../services/player';
import CircleBadge from '../atoms/CircleBadge';

interface PlayerProCombineRatingProps {
  className?: string;
  proCombineRatings?: ProCombineRating[];
}

const useStyles = makeStyles((theme:Theme) => ({
  playerProCombineRating: {},

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: 350,
    paddingRight: 0,
    marginLeft: 'auto',
    cursor: 'help',
  },
  progressBar: {
    backgroundColor: COLOR_TEXT,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  badgeWrap: {
    marginRight: 10,
    width: '45px',
  },
  badgeVal: {
    color: COLOR_BLACK,
  },
}), { name: PlayerProCombineRating.name });

const BETTER_THAN_TOOLTIP_TEXT = 'Better than NFL Draft position';

function aggregateAllEventsOfType (eventType:string, proCombineRatings: ProCombineRating[]):EventRating[] {
  return proCombineRatings
    // @ts-ignore
    .flatMap((combine:ProCombineRating) => combine[eventType] as EventRating[] || []);
}

function getTheBestEvent (events:EventRating[]):EventRating {
  const sortedEvents = !!events?.length
    ? events
      .sort((firstEvent:EventRating, secondEvent:EventRating) => {
        if (firstEvent?.percentile > secondEvent?.percentile) return -1;
        if (firstEvent?.percentile < secondEvent?.percentile) return 1;
        return 0;
      })
    : [];

  const firstEvent = sortedEvents[0];
  const anotherEventWithTheSamePercentileIndex = sortedEvents?.length > 1
    ? sortedEvents.slice(1).findIndex((event:EventRating) => {
        return event.percentile === firstEvent.percentile && event.name !== firstEvent.name;
      }) + 1
    : 0;

  // show 2 event names if their results are the same (edge case)
  if (anotherEventWithTheSamePercentileIndex > 0) {
    return {
      name: `${firstEvent?.name}/${sortedEvents[anotherEventWithTheSamePercentileIndex]?.name}`,
      percentile: firstEvent.percentile,
    } as EventRating;
  }

  return firstEvent;
}

export default function PlayerProCombineRating (props:PlayerProCombineRatingProps) {
  const {
    className,
    proCombineRatings,
  } = props;
  const classes = useStyles();

  if (!proCombineRatings?.length) return null;

  const bestSpeedEvent = getTheBestEvent(aggregateAllEventsOfType('speed', proCombineRatings));
  const bestPowerEvent = getTheBestEvent(aggregateAllEventsOfType('power', proCombineRatings));
  const bestAgilityEvent = getTheBestEvent(aggregateAllEventsOfType('agility', proCombineRatings));
  const noEvents = !bestPowerEvent && !bestSpeedEvent && !bestAgilityEvent;

  if (noEvents) return null;

  return (
    <NamedBox
      className={clsx(classes.playerProCombineRating, className)}
      name={
        <div className={classes.header}>
          <CircleBadge
            className={classes}
            value={'COM'}
            height={45} 
            width={45}
            color={COLOR_BLACK}
          />
          <span>{'Pro Combine Rating'}
          </span>
        </div>
      }
    >
      {bestSpeedEvent && (
        <ListItem
          icon={SpeedIcon}
          title='Speed:'
        >
          <b>{ addSpaceBetweenNumAndAlpha(bestSpeedEvent.name)}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={bestSpeedEvent.percentile}
                progressBarClassName={classes.progressBar}
              />
            </div>
          </Tooltip>
        </ListItem>
      )}

      {bestPowerEvent && (
        <ListItem
          icon={PowerIcon}
          title='Power:'
        >
          <b>{bestPowerEvent.name}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={bestPowerEvent.percentile}
                progressBarClassName={classes.progressBar}
              />
            </div>
          </Tooltip>
        </ListItem>
      )}

      {bestAgilityEvent && (
        <ListItem
          icon={AgilityIcon}
          title='Agility:'
        >
          <b>{bestAgilityEvent.name}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={bestAgilityEvent.percentile}
                progressBarClassName={classes.progressBar}
              />
            </div>
          </Tooltip>
        </ListItem>
      )}
    </NamedBox>
  );
}
