import React from 'react';
import Icon from '../atoms/Icon';

function RulerHorizontalIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
        <path
          d='M32 27V5H0v22h32zm-2.783-2.75h-4.174v-9.625h-2.782v9.625h-2.783v-5.5h-2.782v5.5h-2.783v-9.625H11.13v9.625H8.348v-5.5H5.565v5.5H2.783V7.75h26.434v16.5z'
          fill='currentColor'
          fillRule='nonzero'
        />
    </Icon>
  );
}

export default React.forwardRef(RulerHorizontalIcon);
