import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Download from '../atoms/Download';
import Button from '../atoms/Button';
import Toast, { ToastType } from '../atoms/Toast';
import TeamLogo from '../atoms/TeamLogo';
import TeamProfileHeader from '../organisms/TeamProfileHeader';
import TeamDepthChart, { CHART_TYPE } from '../organisms/TeamDepthChart';
import PageContainer from './PageContainer';
import PdfPageContainer from './PdfPageContainer';
import gql, { serializeGQLObject } from '../services/gql';
import { datetimeToDate, feetAndInchesToInches } from '../services/converter';
import exportXLSReport, { XLSExportType } from '../services/export-xls-report';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { sortPlayersByLastName } from '../services/player';
import { State } from '../redux/reducers';
import {
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_LIGHT_GRAY,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import DepthChartPosition, { DepthChartType } from '../types/DepthChartPosition';
import DepthChart from '../types/DepthChart';
import DepthChartPlayer from '../types/DepthChartPlayer';
import User from '../types/User';
import Player from '../types/Player';
import OurladsIcon from '../icons/OurladsIcon';
import { ButtonBase, Typography } from '@material-ui/core';

interface DepthChartPageTemplateProps {
  user?: User | undefined;
  printed?: boolean;
  depthChartType?: string;
}

const useStyles = makeStyles(theme => ({
  depthChartPageTemplate: {
    fontFamily: FONT_PROXIMA_NOVA,
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  headerTitleBlock: {
    padding: 0,
  },

  subHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h4,
    fontSize: '14px', 
    margin: '0 0 0 5px',
  },

  subHeaderIcon: {
    width: '72px',
    height: '72px',
    marginRight: theme.spacing(1),
  },

  content: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
  },

  topContainer: {
    padding: theme.spacing(0, 5),
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: 0,
    color: COLOR_TEXT,
  },

  downloadButtons: {
    display: 'flex',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  actions: {
    display: 'flex',
    marginLeft: 'auto',
  },
  action: {
    width: '120px',
    marginLeft: theme.spacing(2),
    padding: '15px',
    fontSize: theme.typography.pxToRem(14),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  box: {
    marginTop: theme.spacing(4),
  },

  statisticsLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  statisticsDropDown: {
    marginTop: theme.spacing(1),
  },
  statisticsDropDownSelector: {
    fontSize: theme.typography.pxToRem(12),
  },

  divider: {
    margin: theme.spacing(0, 4),
  },

  table: {
    margin: theme.spacing(5, 5, 0, 5),
    // pageBreakAfter: 'always',
    pageBreakInside: 'avoid',
  },

  page: {
    padding: theme.spacing(5, 0),

    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  pdfHeader: {
    height: '60px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
  },
  pdfHeaderTitle: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },

  teamLogo : {
    width: '15%',
    height: 'auto',
    marginRight: theme.spacing(2),
  },
  logo: {
    width: '125px',
    marginLeft: 'auto',
  },

  titleRow: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 'none',
    padding: theme.spacing(1.5),
  },

  '@media (max-width: 1023px)': {
    action: {
      width: '90px',
      padding: '10px',
    },
  },

  [MEDIA.MOBILE]: {
    topContainer: {
      display: 'block',
    },

    downloadButtons: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },

    titleContainer: {
      display: 'block',
    },

    actions: {
      marginTop: theme.spacing(2),
      paddingLeft: 0,
    },
  },

  [MEDIA.MOBILE_AND_TABLET]: {
    topContainer: {
      padding: theme.spacing(0,2),
    },

    title: {
      fontSize: theme.typography.pxToRem(24),
    },

    table: {
      margin: theme.spacing(2, 2, 0, 2),
    },
  },

  [MEDIA.DESKTOP]: {
    action: {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  [MEDIA.PRINT]: {
    page: {
      pageBreakAfter: 'always',
      pageBreakInside: 'avoid',
      size: 'letter',
      margin: 0,
      zoom: '75%',
      printColorAdjust: 'exact',
      filter: 'opacity(1)',

      '&:last-of-type': {
        pageBreakAfter: 'avoid',
      },
    },
  },
  teamTimestampsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',  
    padding: '0 40px',
  },
  teamTimestampsPdfContainer: {
    padding: '40px 40px 0',
  }
}), { name: DepthChartPageTemplate.name });

function DepthChartPageTemplate (props: DepthChartPageTemplateProps) {
  const classes = useStyles();
  const {
    depthChartType,
    user,
    printed = false,
  } = props;

  const { depthChartSlug } = useParams<any>();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [depthChart, setDepthChart] = useState<DepthChart>();
  const [team, setTeam] = useState<Team>();
  const [teamPlayers, setTeamPlayers] = useState<Player[]>([]);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    if (depthChartSlug) {
      fetchDepthChart(depthChartSlug);
    }
  }, [depthChartSlug]);

  useEffect(() => {
    if (depthChart && depthChart.teamId) {
      fetchTeam(depthChart.teamId);
    }
  }, [
    depthChart?.teamId ? depthChart.teamId : depthChart,
  ]);

  useEffect(() => {
    if (team && team.id) {
      fetchCollegePlayers(team.id);
    }
  }, [JSON.stringify(team)]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.depthChartEnabled) {
        history.push(`/dashboard?no-access=${history.location.pathname}`);
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function fetchDepthChart (slug:string, byPassloader?:boolean) {
    if (!byPassloader) {
      setLoading(true);
    }

    gql(`
      depthChart (slug: "${slug}") {
        id
        slug
        name
        teamId
        programId
        updatedAt
        createdAt
        positions {
          id
          order
          label
          type
          players {
            id
            order
            jerseyNumber
            playerId
            playerName
            weight
            height
            class
            note
            paiPercentile
            combinePercentile
            ppiPercentile
            player {
              id
              slug
              firstName
              lastName
              photoUrl
              pai
              ppi
              ppiDiv1Percentile
              multiSport
              isSpeedRecruit
              isPowerRecruit
              combine
              hsCombines {
                id
              }
            }
          }
        }
      }
    `)
      .then((data:any) => data.depthChart as DepthChart)
      .then((depthChart:DepthChart) => {
        setDepthChart(depthChart)
      })
      .then(() => setLoading(false))
      .catch(console.error);
  }

  function fetchTeam (teamId:number) {
    gql(`
      team (id: ${teamId}) {
        id
        name
        shortName
        slug
        logo247
        logoESPN
        logoAlt
        city
        state
        stateAbbr
        color
        division
        conference {
          name
          abbr
          iconUrl
        }
      }
    `)
      .then((data:any) => data.team as Team)
      .then((team:Team) => setTeam(team))
      .then(() => setLoading(false))
      .catch(console.error);
  }

  function fetchCollegePlayers (teamId:number) {
    gql(`
      playersForCollegeTeam (
        teamId: ${teamId},
        isCurrent: true,
        isFormer: false,
        commitsYears: [],
      ) {
        id
        firstName
        lastName
        highSchools
        playerColleges {
          id
          positions
          isPrimary
          height
          weight
          recruitingClass
        }
      }
    `)
      .then((data:any) => data.playersForCollegeTeam as Player[])
      .then((players:Player[]) => {
        if (players?.length > 0) {
          setTeamPlayers(players.sort(sortPlayersByLastName));
        }
      });
  }

  function onUpdatePositions (updatedPositions:DepthChartPosition[]) {
    if (updatedPositions && updatedPositions.length && depthChart) {
      const updatedPositionsType = updatedPositions[0].type;
      const updatedDepthChart = {
        ...depthChart,
        positions: [
          ...(depthChart.positions || []).filter(position => position.type !== updatedPositionsType),
          ...updatedPositions,
        ] as DepthChartPosition[],
      } as DepthChart;

      setDepthChart(updatedDepthChart);
    }
  }

  function onCancel () {
    fetchDepthChart(depthChartSlug);
  }

  function onSave () {
    if (!depthChart) return;

    setLoading(true);

    const depthChartToSave = prepareDepthChartForSaving(depthChart);

    return gql(`
      mutation {
        saveDepthChart (
          depthChart: ${serializeGQLObject(depthChartToSave)}
        )
      }
    `)
    .then((data:any) => data.saveDepthChart as boolean)
    .then((success:boolean) => {
      if (success) {
        showToast('Saved', ToastType.SUCCESS);
      } else {
        showToast('Failed to save. Try again.', ToastType.ERROR);
      }
    })
    .catch((error) => {
      console.error(error);
      showToast('Failed to save. Try again.', ToastType.ERROR);
    })
    .finally(() => setLoading(false));
  }

  function prepareDepthChartForSaving (depthChart:DepthChart) {
    const playerCount = depthChart.positions.reduce((totalCount:number, position:DepthChartPosition) => {
      const playerCountForCurrentPosition = position.players.reduce((count:number, currentPlayer:DepthChartPlayer) => {
        return count + (currentPlayer.playerId ? 1 : 0);
      }, 0);

      return totalCount + playerCountForCurrentPosition;
    }, 0);

    return {
      id: Number(depthChart.id),
      name: depthChart.name,
      programId: depthChart.programId,
      teamId: depthChart.teamId,
      playerCount,
      positions: [
        ...depthChart.positions.map(position => ({
          ...position,
          id: Number(position.id),
          players: [
            ...position.players.map((depthChartPlayer:DepthChartPlayer, index:number) => ({
              ...depthChartPlayer,
              id: Number(depthChartPlayer.id),
              jerseyNumber: depthChartPlayer.jerseyNumber,
              height: typeof depthChartPlayer.height === 'number'
                ? depthChartPlayer.height
                : (depthChartPlayer.height ? feetAndInchesToInches(depthChartPlayer.height as string) : null),
              weight: typeof depthChartPlayer.weight === 'number'
                ? depthChartPlayer.weight
                : (depthChartPlayer.weight ? Number(depthChartPlayer.weight as string) : null),
              class: depthChartPlayer.class ? Number(depthChartPlayer.class) : depthChartPlayer.class,
              playerId: depthChartPlayer.player ? depthChartPlayer.player.id : null,
              paiPercentile: depthChartPlayer.paiPercentile || null,
              combinePercentile: depthChartPlayer.combinePercentile || null,
              note: depthChartPlayer.note || null,
              order: index + 1,
              player: null,
              duplicated: undefined,
            })),
          ],
        })),
      ],
    };
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onXLSDownload () {
    if (depthChart) {
      setLoading(true);

      exportXLSReport(
        XLSExportType.DEPTH_CHART,
        { id: depthChart.id, type: 'all' },
      )
        .catch(error => {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  function generateAndDownloadPDFReport () {
    if (!depthChartSlug) return;

    setLoading(true);

    exportPDFReport(
      PDFExportPage.DEPTH_CHART,
      depthChartSlug,
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  const playerIds:number[] = !!depthChart?.positions?.length
    ? depthChart.positions
        .flatMap(position => position.players.map(positionPlayer => positionPlayer?.player?.id))
        .filter(Boolean)
        .sort() as number[]
    : [];
  const teamPlayersWithMarkedDuplicates = teamPlayers.map((player:Player) => ({
    ...player,
    duplicated: playerIds.filter((id:number) => id === player.id).length > 0,
  }))
  const positionsWithMarkedDuplicatedPlayers:DepthChartPosition[] = !!depthChart?.positions?.length
    ? depthChart.positions.map((position:DepthChartPosition) => ({
        ...position,
        players: position.players.map((positionPlayer:DepthChartPlayer) => ({
          ...positionPlayer,
          duplicated: playerIds.filter((id:number) => id === positionPlayer?.player?.id).length > 1,
        } as DepthChartPlayer)),
      }))
    : [];

  const defensivePositions = !!positionsWithMarkedDuplicatedPlayers?.length
    ? positionsWithMarkedDuplicatedPlayers.filter(position => position.type === DepthChartType.DEFENSIVE)
    : [];
  const offensivePositions = !!positionsWithMarkedDuplicatedPlayers?.length
    ? positionsWithMarkedDuplicatedPlayers.filter(position => position.type === DepthChartType.OFFENSIVE)
    : [];
  const specialPositions = !!positionsWithMarkedDuplicatedPlayers?.length
    ? positionsWithMarkedDuplicatedPlayers.filter(position => position.type === DepthChartType.SPECIAL)
    : [];

  let chartContent:{type:DepthChartType, positions: DepthChartPosition[]}[] = [];
  if (printed) {
    switch (depthChartType) {
      case CHART_TYPE[DepthChartType.DEFENSIVE]:
        chartContent = [{
          type: DepthChartType.DEFENSIVE,
          positions: defensivePositions,
        }];
        break;
      case CHART_TYPE[DepthChartType.OFFENSIVE]:
        chartContent = [{
          type: DepthChartType.OFFENSIVE,
          positions: offensivePositions,
        }];
        break;
      case CHART_TYPE[DepthChartType.SPECIAL]:
        chartContent = [{
          type: DepthChartType.SPECIAL,
          positions: specialPositions,
        }];
        break;
      default:
        chartContent = [
          {
            type: DepthChartType.DEFENSIVE,
            positions: defensivePositions,
          },
          {
            type: DepthChartType.OFFENSIVE,
            positions: offensivePositions,
          },
          {
            type: DepthChartType.SPECIAL,
            positions: specialPositions,
          }
        ];
        break;
    }
  }

  const toOurlads = () => {
    window.open('https://www.ourlads.com/');
  }

  return (
    <>
      {!printed && (
        <>
          <PageContainer className={classes.depthChartPageTemplate}>
            <Paper className={classes.paper}>
              <TeamProfileHeader
                loading={loading}
                team={team}
              />

              <div className={classes.content}>
                {depthChart && (
                  <div className={classes.topContainer}>
                    <div className={classes.titleContainer}>
                      <div className={classes.headerTitleBlock}>
                        <h1 className={classes.title}>
                          {depthChart.name} Depth Chart
                        </h1>
                        <h1 className={classes.subHeaderTitle}>
                          Powered By: 
                          <ButtonBase onClick={toOurlads}><OurladsIcon className={classes.subHeaderIcon}/></ButtonBase>
                        </h1>
                      </div>

                      <div className={classes.downloadButtons}>
                        {(user.accessLevel.exportAllowanceType !== 'none') && (
                          <Download
                            label='XLS'
                            onClick={onXLSDownload}
                          />
                        )}

                        <Download
                          label='PDF'
                          onClick={generateAndDownloadPDFReport}
                        />
                      </div>
                    </div>

                    <div className={classes.actions}>
                      <Button
                        className={classes.action}
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>

                      <Button
                        className={classes.action}
                        primary
                        onClick={onSave}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}

                <div className={classes.teamTimestampsContainer}>
                  {depthChart?.createdAt ? <span>Created Date: {datetimeToDate(depthChart.createdAt)}</span> : ''}
                  {depthChart?.updatedAt ? <span>Last Updated: {datetimeToDate(depthChart.updatedAt)}</span> : ''}
                </div>

                <TeamDepthChart
                  className={classes.table}
                  type={DepthChartType.DEFENSIVE}
                  loading={loading}
                  team={team}
                  teamPlayers={teamPlayersWithMarkedDuplicates}
                  depthChartId={depthChart?.id}
                  depthChartSlug={depthChartSlug}
                  positions={defensivePositions}
                  user={user}
                  onUpdatePositions={onUpdatePositions}
                  setLoading={setLoading}
                />

                <TeamDepthChart
                  className={classes.table}
                  type={DepthChartType.OFFENSIVE}
                  loading={loading}
                  team={team}
                  teamPlayers={teamPlayersWithMarkedDuplicates}
                  depthChartId={depthChart?.id}
                  depthChartSlug={depthChartSlug}
                  positions={offensivePositions}
                  user={user}
                  onUpdatePositions={onUpdatePositions}
                  setLoading={setLoading}
                />

                <TeamDepthChart
                  className={classes.table}
                  type={DepthChartType.SPECIAL}
                  loading={loading}
                  team={team}
                  teamPlayers={teamPlayersWithMarkedDuplicates}
                  depthChartId={depthChart?.id}
                  depthChartSlug={depthChartSlug}
                  positions={specialPositions}
                  user={user}
                  onUpdatePositions={onUpdatePositions}
                  setLoading={setLoading}
                />
              </div>
            </Paper>
          </PageContainer>

          <Toast
            visible={toastVisible}
            type={toastType}
            onHide={() => setToastVisible(false)}
          >
            {toastMessage}
          </Toast>
        </>
      )}

      {printed && (
        <PdfPageContainer>
          <div className={classes.page}>
            <div className={classes.pdfHeader}>
              <h1 className={clsx(classes.headerTitle, classes.pdfHeaderTitle)}>
                <TeamLogo
                  className={classes.teamLogo}
                  team={team}
                />
                {depthChart && depthChart.name} Depth Chart
              </h1>

              <img
                className={classes.logo}
                src='/images/tracking-football-logo.svg'
                alt='Tracking Football'
              />
            </div>

            {chartContent.map(printedComponent => (
              <TeamDepthChart
                key={printedComponent.type}
                className={classes.table}
                type={printedComponent.type}
                loading={loading}
                team={team}
                teamPlayers={teamPlayersWithMarkedDuplicates}
                depthChartId={depthChart?.id}
                depthChartSlug={depthChartSlug}
                positions={printedComponent.positions}
                user={user}
                onUpdatePositions={onUpdatePositions}
                setLoading={setLoading}
                updatedAt={depthChart?.updatedAt}
                printed
              />
            ))}
          </div>
        </PdfPageContainer>
      )}
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(DepthChartPageTemplate);
