import React, { useEffect } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import FilePicker from '../atoms/FilePicker';
import Action from '../atoms/Action';
import clsx from 'clsx';
import { COLOR_BLUE, COLOR_DARK_BLUE, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import Loader from '../atoms/Loader';
import MEDIA from '../styles/media';
import Toast, { ToastType } from '../atoms/Toast';
import { ProductionFileUploadAuditLog, ProductionFileUploads } from '../types/HSProduction';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import HSProductionDataTable from '../organisms/HSProductionDataTable';
import ImportHSProductionFileTable from '../organisms/ImportHSProductionFileTable';
import gql from '../services/gql';
import { uploadAndPostProductionFileXLS } from '../services/export-xls-report';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1.5),
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    fontSize: theme.typography.pxToRem(14),

    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
      color: COLOR_WHITE,
      textDecoration: 'none',
    }
  },
  filePicker: {
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    cursor: 'pointer',
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),

    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
    }
  },
  fileInputLabelClass: {
    height: '100%',
    width: '100%',
    lineHeight: '50px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  gridContainerColumn: {
    padding: '0 10px',
  },
  '@media (max-width: 1800px)': {
    gridContainer: {
      width: 'calc(100% + 20px)',
      margin: '0 -10px',
    },
    gridContainerColumn: {
      padding: '0 10px',
    },
  },

  [MEDIA.MOBILE_AND_TABLET]: {
    gridContainer: {
      flexDirection: 'column',
    },
    gridContainerColumn: {
      flexBasis: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(5),

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },
  wrapContainer: {
    padding: theme.spacing(3, 3),
  },
}))

export default function ImportHSProductionPage() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingProductionData, setIsLoadingProductionData] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [toastVisible, setToastVisible] = React.useState<boolean>(false);
  const [toastType, setToastType] = React.useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = React.useState<string>('');
  const [uploadedFiles, setUploadedFiles] = React.useState<ProductionFileUploads[]>([]);
  const [selectedfileContent, setSelectedFileContent] = React.useState<ProductionFileUploadAuditLog[]>([]);
  const [currentDataPage, setCurrentDataPage] = React.useState<number>((clientStorage.get(StorageKey.IMPORT_HS_PRODUCTION_TABLE) || {}).currentDataPage || 1);
  const [currentFilePage, setCurrentFilePage] = React.useState<number>((clientStorage.get(StorageKey.IMPORT_HS_PRODUCTION_TABLE) || {}).currentFilePage || 1);
  const [limit, setLimit] = React.useState<number>((clientStorage.get(clientStorage.StorageKey.IMPORT_HS_PRODUCTION_TABLE) || {}).limit || 15);
  const [fileNameAndDate, setFileNameAndDate] = React.useState({
    id: 0,
    fileName: '',
    createdAt: ''
  });

  function showToast(message: string, type: ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  };

  useEffect(() => {
    fetchFileUploads();
  }, []);

  function handleRowClick(data: any) {
    fetchAuditLogs(data);
  }

  // import and upload file
  function onFileSelect(file: any) {
    setUploadedFiles([]);
    if (file) {
      setIsLoading(true);

      uploadAndPostProductionFileXLS(file)
        .then((success:boolean) => {
          if (success) {
            showToast('Uploaded', ToastType.SUCCESS);
            fetchFileUploads();
          } else {
            showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        })
        // .finally(() => setIsLoading(false));
    }
  };

  // fetch all files
  function fetchFileUploads () {
    setIsLoading(true);
    gql(`
      productionFileUploads {
        id,
        createdBy,
        fileUrl,
        fileName,
        status,
        createdAt,
        updatedAt,
        importCount,
      }
    `)
      .then((data: any) => data.productionFileUploads)
      .then((productionFileUploads: ProductionFileUploads[]) => {
        setUploadedFiles(productionFileUploads);
        if (productionFileUploads && productionFileUploads.length && productionFileUploads[0] && productionFileUploads[0].id) {
          fetchAuditLogs(productionFileUploads[0]);
        }
        setIsLoading(false)
        setRefresh(false);
      })
      .catch(() => {
        setIsLoading(false);
        showToast('Failed to fetch files.', ToastType.ERROR);
      })
  }

  // fetch selected file data
  function fetchAuditLogs(data: any) {
    const id = data && data.id;
    setFileNameAndDate({
      id: data && data.id,
      fileName: data && data.fileName,
      createdAt: data && data.createdAt
    });
    setIsLoadingProductionData(true);
    gql(`
      productionFileUploadAuditLogs (productionFileUploadId: ${id}) {
        id,
        productionFileUploadId,
        slug,
        source,
        year,
        message,
      }
    `)
      .then((data: any) => data.productionFileUploadAuditLogs as ProductionFileUploadAuditLog[])
      .then((productionFileUploadAuditLogs: ProductionFileUploadAuditLog[]) => {
        setSelectedFileContent(productionFileUploadAuditLogs);
        setIsLoadingProductionData(false);
      })
      .catch(() => {
        setIsLoadingProductionData(false);
        showToast("Failed to fetch file's data.", ToastType.ERROR);
      })
  }

  React.useEffect(() => {
    if (refresh) {
      setCurrentFilePage(1);
      setCurrentDataPage(1);
      fetchFileUploads();
    }
  }, [refresh]);

  useEffect(() => {
    clientStorage.save(StorageKey.IMPORT_HS_PRODUCTION_TABLE, {limit, currentFilePage});
  }, [limit, currentFilePage]);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            HS Production
          </h1>
          <div className={classes.actions}>
            <FilePicker
              className={clsx(classes.action, classes.filePicker)}
              fileInputLabelClass={classes.fileInputLabelClass}
              name='xls-import'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={(file: any) => onFileSelect(file)}
            >
              Import
            </FilePicker>

            <Action
              className={classes.action}
              onClick={() => {
                setRefresh(true);
              }}
              icon={RefreshIcon}
              iconClassName={classes.icon}
            >
              Refresh
            </Action>
          </div>
        </div>
        <div className={classes.wrapContainer}>
          {isLoading ?
            <Loader inProgress={isLoading} />
            :
            <Grid
              className={classes.gridContainer}
              container
            >
              <Grid
                className={classes.gridContainerColumn}
                item
                lg={5}
                sm={6}
              >
                <ImportHSProductionFileTable
                  uploadedFiles={uploadedFiles}
                  currentFilePage={currentFilePage}
                  setCurrentFilePage={setCurrentFilePage}
                  handleRowClick={handleRowClick}
                  fileNameAndDate={fileNameAndDate}
                  limit={limit}
                />
              </Grid>
              <Grid
                className={classes.gridContainerColumn}
                item
                lg={7}
                sm={6}
              >
                <HSProductionDataTable
                  isLoadingProductionData={isLoadingProductionData}
                  selectedfileContent={selectedfileContent}
                  fileNameAndDate={fileNameAndDate}
                  currentDataPage={currentDataPage}
                  setCurrentDataPage={setCurrentDataPage}
                  limit={limit}
                />
              </Grid>
            </Grid>
          }
        </div>
      </Paper>
      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
};
