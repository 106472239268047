import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import ManageTable from '../organisms/ManageTable';
import ManageUsersIcon from '../icons/ManageUsersIcon';
import PlusIcon from '../icons/PlusIcon';
import { State } from '../redux/reducers';
import User from '../types/User';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import MEDIA from '../styles/media';

enum TabName {
  USERS = 'users',
  PROGRAMS = 'programs',
  COMBINES = 'combines',
  STAGING = 'staging',
}

interface ManagePageTemplateProps {
  user?: User;
}

const useStyles = makeStyles(theme => ({
  managePageTemplate: {
    minHeight: '100vh',
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    margin: theme.spacing(3.5, 5, 0),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  headerActions: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    right: 0,
    display: 'flex',
    minHeight: '50px',
  },

  addButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    backgroundColor: COLOR_BLUE,
    borderRadius: theme.typography.pxToRem(5),
    color: COLOR_WHITE,
    textDecoration: 'none',
    padding: theme.spacing(1.25),

    '&:hover': {
      borderColor: COLOR_DARK_BLUE,
      background: COLOR_DARK_BLUE,
    },
  },
  addIcon: {
    flexShrink: 0,
    width: '12px',
    height: '12px',
    lineHeight: '12px',
    marginRight: theme.spacing(1),
  },
  addText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
  },

  manageUserTable: {
    margin: theme.spacing(2,5,0),
  },

  [MEDIA.MOBILE]: {
    header: {
      display: 'block',
      minHeight: 0,
      margin: theme.spacing(2, 2, 0),
    },

    headerActions: {
      position: 'relative',
      top: 0,
      flexWrap: 'wrap',
      minHeight: 0,
      margin: theme.spacing(2,0),
    },

    addButton: {
      marginLeft: 0,
    },

    manageUserTable: {
      margin: theme.spacing(0, 2),
    },
  },

  [MEDIA.DESKTOP]: {
    addButton: {
      width: '170px',
    },
  },
}), { name: ManagePageTemplate.name });

interface QueryParams {
  tab: TabName;
}

function ManagePageTemplate (props:ManagePageTemplateProps) {
  const { user } = props;
  const classes = useStyles();
  const history = useHistory();
  const { tab = TabName.USERS } = useParams<QueryParams>();

  useEffect(() => {
    if (user && user.accessLevel) {
      if (!user.accessLevel.canManageUsers) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function returnTabName(tab: string): string {
    let tabHeader = 'Users';
    if (tab === TabName.PROGRAMS) tabHeader = 'Programs';
    if (tab === TabName.COMBINES) tabHeader = 'Combines';
    if (tab === TabName.STAGING) tabHeader = 'Staging';
    return tabHeader;
  }
  return (
    <PageContainer className={classes.managePageTemplate}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <ManageUsersIcon  className={classes.headerIcon} />
            Manage {returnTabName(tab)}
          </h1>

          {user.accessLevel.canManageUsers && (
            <div className={classes.headerActions}>
              {(tab === TabName.USERS) && (
                <Link
                  className={classes.addButton}
                  to='/manage/user/add'
                >
                  <PlusIcon className={classes.addIcon} />
                  <span className={classes.addText}>Add User</span>
                </Link>
              )}

              {(tab === TabName.PROGRAMS) && (
                <Link
                  className={classes.addButton}
                  to='/manage/program/add'
                >
                  <PlusIcon className={classes.addIcon} />
                  <span className={classes.addText}>Add Program</span>
                </Link>
              )}

              {(tab === TabName.COMBINES) && (
                <Link
                  className={classes.addButton}
                  to='/manage/combines/add'
                >
                  <PlusIcon className={classes.addIcon} />
                  <span className={classes.addText}>Add Combines</span>
                </Link>
              )}
            </div>
          )}
        </div>

        <ManageTable
          className={classes.manageUserTable}
          user={user}
          tab={tab}
        />
      </Paper>
    </PageContainer>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(ManagePageTemplate);
