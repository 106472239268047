import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MultiSelectPopover from '../molecules/MultiSelectPopover';
import { MultiSelectItem } from '../molecules/MultiSelect';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_ORANGE } from '../styles/colors';
import MEDIA from '../styles/media';

interface AddRemoveColumnsProps {
  className?: string;
  columns: MultiSelectItem[];
  selectedColumns: string[];
  onSelect: (values:string[]) => void;
  onReorder?: (values:string[]) => void;
  onResetColumnsClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  addRemoveColumns: {
    appearance: 'none',
    background: 'none',
    border: 0,
    padding:  theme.spacing(0, 1),
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_BLUE,
    cursor: 'pointer',
    outlineColor: COLOR_ORANGE,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  addRemoveColumnsText: {
    display: 'none',
  },

  [MEDIA.MOBILE]: {
    addRemoveColumns: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  '@media (min-width: 1601px)': {
    addRemoveColumnsText: {
      display: 'inline',
    },
  },
}), { name: AddRemoveColumns.name });

export default function AddRemoveColumns (props: AddRemoveColumnsProps) {
  const {
    className,
    columns,
    selectedColumns,
    onSelect,
    onReorder,
    onResetColumnsClick
  } = props;
  const classes = useStyles();
  const [addRemoveColumnsAnchorEl, setAddRemoveColumnsAnchorEl] = useState<HTMLElement | null>(null);

  function onAddRemoveColumnsClick (event:any) {
    setAddRemoveColumnsAnchorEl(event.currentTarget);
  }

  return (
    <>
      <button
        className={clsx(classes.addRemoveColumns, className)}
        onClick={onAddRemoveColumnsClick}
      >
        <span className={classes.addRemoveColumnsText}>Add / Remove</span> Columns
      </button>

      <MultiSelectPopover
        anchorEl={addRemoveColumnsAnchorEl}
        items={columns}
        values={selectedColumns}
        onChange={(selectedColumns:string[]) => {
          if (selectedColumns.length > 0) {
            onSelect(selectedColumns);
          }
        }}
        onReorder={onReorder}
        onClose={() => setAddRemoveColumnsAnchorEl(null)}
      />

      { onResetColumnsClick &&    
      <button
        className={clsx(classes.addRemoveColumns, className)}
        onClick={onResetColumnsClick}
      >
        Reset Columns
      </button>
      }
    </>
  );
}
