import React from 'react';
import Icon from '../atoms/Icon';

function FolderIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(3, 6) scale(1.5)' stroke='currentColor' strokeWidth='2'>
          <polygon points='18 2 18 14 0 14 0 4 7 4 9 2' />
          <polyline points='0 4 0 0 7 0 9 2' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(FolderIcon);
