import React from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import Pagination from '../atoms/Pagination';
import { COLOR_BACKGROUND_LIGHT, COLOR_BLACK, COLOR_BORDER, COLOR_DARK_GRAY, COLOR_LIGHT_GRAY, COLOR_SHADOW, COLOR_WHITE } from '../styles/colors';
import clsx from 'clsx';
import { datetimeToDate } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface HSProductionFileProps {
  uploadedFiles?: any[];
  handleRowClick: (data: any) => void;
  fileNameAndDate: any;
  currentFilePage: number;
  setCurrentFilePage: (value: number) => void;
  limit: number;
};

interface dataProps {
  'id': number;
  'fileName': string;
  'updatedAt': string;
  'status': string;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    cursor: 'default',
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,
    cursor: 'pointer',

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 0,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1, 0, 1, 1),
    textTransform: 'capitalize',

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },

    '&:last-of-type': {
      width: '20%',
    },
  },
  ellipsisCell: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  selectedTableCell: {
    color: COLOR_BLACK
  },
  paginationWrapper: {
    padding: '0 20px',
  },
  paginationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
  noDataTextWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: FONT_PROXIMA_NOVA,
    padding: theme.spacing(4),
  },
  noDataText: {
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    marginTop: theme.spacing(2),
  },
}))

export default function ImportHSProductionFileTable(props: HSProductionFileProps) {
  const {
    uploadedFiles,
    currentFilePage,
    setCurrentFilePage,
    handleRowClick,
    fileNameAndDate,
    limit
  } = props;
  const classes = useStyles();

  const totalPages = uploadedFiles && Math.ceil(uploadedFiles.length / limit) || 0;
  const itemsFrom = (currentFilePage - 1) * limit;
  const itemsTo = uploadedFiles && uploadedFiles.length < limit ? uploadedFiles.length : (limit * currentFilePage);

  function selectedRowIsTrue(id: number) {
    const selectedRowId = fileNameAndDate && fileNameAndDate.id;
    return id === selectedRowId;
  }

  function renderTableRow(data: dataProps, index: number) {
    const {
      id,
      fileName,
      updatedAt,
      status
    } = data;
    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
        onClick={() => handleRowClick(data)}
      >
        <TableCell className={clsx(classes.tableCell, classes.ellipsisCell, (selectedRowIsTrue(id) ? classes.selectedTableCell : ''))} align='center'>{fileName}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{datetimeToDate(updatedAt)}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{status}</TableCell>
      </TableRow>
    )
  };

  function renderTable() {
    return (
      <>
        <div className={classes.tableWrap}>
          {uploadedFiles && uploadedFiles.length ?
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={clsx(classes.tableCell, classes.ellipsisCell)} align='center'>File</TableCell>
                    <TableCell className={classes.tableHeadCell} align='center'>Updated At</TableCell>
                    <TableCell className={classes.tableHeadCell} align='center'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.slice(itemsFrom, itemsTo).map(renderTableRow)}
                </TableBody>
              </Table>
              <div className={classes.paginationWrapper}>
                <div className={classes.paginationContainer}>
                  <span className={classes.paginationText}>
                    Showing {itemsFrom + 1} to {itemsTo} of {uploadedFiles?.length}
                  </span>

                  {totalPages > 1 && (
                    <Pagination
                      className={classes.pagination}
                      currentPage={currentFilePage}
                      totalPages={totalPages}
                      onChange={page => setCurrentFilePage(page)}
                    />
                  )}
                </div>
              </div>
            </>
            :
            <div className={classes.noDataTextWrapper}>
              <div className={classes.noDataText}>No Data Found</div>
            </div>
          }
        </div>
      </>
    );
  }

  return (
    <Paper>
      {renderTable()}
    </Paper>
  )
};
