import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
} from '../styles/colors';
import PinIcon from '../icons/PinIcon';
import Tooltip from '../atoms/Tooltip';

interface PinButtonProps {
  className?: string;
  mode: string;
  active: boolean;
  onClick?: (event: any) => void;
}

export const PIN_MODE = {
  PIN: 'pin',
  REMOVE: 'remove',
};

const useStyles = makeStyles((theme: Theme) => ({
  pinButton: {
    appearance: 'none',
    border: 0,
    padding: 0,
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  pinIconContainer: {
    padding: theme.spacing(0, 1),
    boxSizing: 'content-box',
    position: 'relative',
  },
  pinIcon: {
    width: '20px',
    height: '20px',
    transition: 'color 0.3s',
    color: COLOR_DARK_GRAY,
  },

  active: {
    '& $pinIcon': {
      color: COLOR_GREEN,
    },
  },
  modePin: {
    '& $pinIcon': {
      '&:hover': {
        color: COLOR_ORANGE,
      },
    },
  },
  modeRemove: {
    '& $pinIcon': {
      '&:hover': {
        color: COLOR_RED,
      },
    },
  },
}), { name: PinButton.name });

export default function PinButton (props: PinButtonProps) {
  const {
    className,
    mode = PIN_MODE.PIN,
    active = false,
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(
        classes.pinButton,
        {
          [classes.active]: active,
          [classes.modePin]: mode === PIN_MODE.PIN,
          [classes.modeRemove]: mode === PIN_MODE.REMOVE,
        },
        className,
      )}
      onClick={onClick}
    >
      <Tooltip title={mode === PIN_MODE.PIN ? 'Pin' : 'Remove'}>
        <div className={classes.pinIconContainer}>
          <PinIcon
            className={classes.pinIcon}
          />
        </div>
      </Tooltip>
    </button>
  );
}
