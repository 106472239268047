import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NamedBox from '../atoms/NamedBox';
import Score from '../atoms/Score';
import { CONFERENCE_NAME } from '../templates/TeamPageTemplate';
import { COLOR_BORDER } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Team from '../types/Team';
import MEDIA from '../styles/media';

interface TeamProfileStatisticsProps {
  className?: string;
  title: any;
  team: Team;
  isNFL?: boolean;
  rows: {
    scoreType: string;
    label: any;
    values: {
      team: number;
      level: number;
      conference: number;
    };
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  teamProfileStatistics: {},

  row: {
    padding: theme.spacing(2.5),
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      borderTop: 0,
    },
  },

  label: {
    maxWidth: '300px',
    marginBottom: theme.spacing(3),
    ...theme.typography.h2,
  },

  scores: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  scoreWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    minWidth: '80px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    textAlign: 'center',
  },
  score: {
    width: '60px',
    height: '60px',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(24),
  },

  [MEDIA.MOBILE]: {
    label: {
      marginBottom: 0,
    },
    scores: {
      flexWrap: 'wrap',
    },
    scoreWrapper: {
      maxWidth: '90px',
    },
    score: {
      width: '50px',
      height: '50px',
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(20),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    row: {
      display: 'flex',
      alignItems: 'center',
    },

    label: {
      width: '130px',
      marginBottom: 0,
    },

    scores: {
      maxWidth: '480px',
      marginLeft: 'auto',
      paddingLeft: theme.spacing(2),
    },
    scoreWrapper: {
      marginLeft: theme.spacing(2),
    },
  },

  '@media (min-width: 1681px)': {
    row: {
      padding: theme.spacing(3,5),
    },

    score: {
      width: '70px',
      height: '70px',
      fontSize: theme.typography.pxToRem(28),
    },
  },
}), { name: TeamProfileStatistics.name });

export default function TeamProfileStatistics (props: TeamProfileStatisticsProps) {
  const {
    className,
    title,
    team,
    isNFL,
    rows,
  } = props;
  const classes = useStyles();

  return (
    <NamedBox
      className={clsx(classes.teamProfileStatistics, className)}
      name={title}
      white
    >
      {rows.map((row, index) => (
        <div
          key={index}
          className={classes.row}
        >
          <div className={classes.label}>{row.label}</div>

          <div className={classes.scores}>
            <div className={classes.scoreWrapper}>
              <Score
                className={classes.score}
                type={row.scoreType}
                value={row.values.team}
              />
              {team.shortName}{!isNFL && `, ${team.stateAbbr}`}
            </div>

            <div className={classes.scoreWrapper}>
              <Score
                className={classes.score}
                type={row.scoreType}
                value={row.values.conference}
              />
              {team.conference.abbr}
            </div>

            <div className={classes.scoreWrapper}>
              <Score
                className={classes.score}
                type={row.scoreType}
                value={row.values.level}
              />
              {isNFL ? 'NFL' : CONFERENCE_NAME[team.conference.mainLevel]}
            </div>
          </div>
        </div>
      ))}
    </NamedBox>
  );
}
