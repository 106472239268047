import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_TEXT } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface ChartLabelProps {
  position: number; // 0 - 100
  label: any;
  long?: boolean;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  chartLabel: {
    position: 'absolute',
    top: 'calc(50% + 2px)',
    transform: 'translate(-50%, -50%)',

    '&:before': {
      content: '""',
      width: '1px',
      height: '16px',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'currentColor',
    },
  },
  long: {
    '&:before': {
      height: '54px',
    },
    '& $label': {
      top: '56px',
    },
  },
  label: {
    position: 'absolute',
    top: '18px',
    left: '-50%',
    transform: 'translateX(-50%)',
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.2,
    textAlign: 'center',
    color: COLOR_TEXT,
  }
}), { name: ChartLabel.name });

export default function ChartLabel (props: ChartLabelProps) {
  const {
    position,
    label = '',
    long = false,
    color,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.chartLabel,
        long && classes.long,
      )}
      style={{
        left: `${position}%`,
        color,
      }}
    >
      <div className={classes.label}>{label}</div>
    </div>
  );
}
