import { AchievementsVisibility } from '../reducers/achievements';
import { Achievement } from '../../types/Achievement';

export const SET_ACHIEVEMENTS_VISIBILITY = 'achievements/SET_ACHIEVEMENTS_VISIBILITY';
export const SET_ACHIEVEMENTS = 'achievements/SET_ACHIEVEMENTS';

export function setAchievements(achievements:Achievement[]) {
  return {
    type: SET_ACHIEVEMENTS,
    achievements,
  };
}

export function setAchievementsVisibilityAction(achievementsVisibility:AchievementsVisibility) {
  return {
    type: SET_ACHIEVEMENTS_VISIBILITY,
    achievementsVisibility,
  };
}
