import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from './Tooltip';
import { COLOR_LIGHT_GRAY, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface ChartImageMarkerProps {
  position: number; // 0 - 100
  image: string;
  tooltipText: string;
  iconFallback?: any;
  avatar?: boolean;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  chartImageMarker: {
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 56px)',
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: FONT_PROXIMA_NOVA,
    cursor: 'pointer',

    '&:before': {
      content: '""',
      width: '1px',
      height: '39px',
      position: 'absolute',
      bottom: '-40px',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'currentColor',
    },
  },
  avatar: {
    top: 'calc(50% - 80px)',

    '& $image': {
      width: '100%',
      height: '100%',
    },
    '&:before': {
      content: '""',
      width: '1px',
      height: '63px',
      position: 'absolute',
      bottom: '-64px',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'currentColor',
    },
  },

  image: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    objectFit: 'cover',
  },

  iconFallback: {
    width: '16px',
    height: '16px',
    color: COLOR_TEXT,
  },
}), { name: ChartImageMarker.name });

export default function ChartImageMarker (props: ChartImageMarkerProps) {
  const {
    position,
    image = '',
    tooltipText,
    iconFallback,
    avatar = false,
    color,
  } = props;
  const classes = useStyles();
  const Icon = iconFallback;

  return (
    <div
      className={clsx(
        classes.chartImageMarker,
        avatar && classes.avatar,
      )}
      style={{
        left: `${position}%`,
        color,
      }}
    >
      <Tooltip title={tooltipText}>
        {image
          ? (
            <img
              className={classes.image}
              src={image}
              alt={tooltipText}
            />
          )
        : <Icon className={classes.iconFallback} />
        }
      </Tooltip>
    </div>
  );
}
