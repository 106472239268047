import Team from '../../types/Team';
import { ScreenSize } from '../../services/screen-size';

export const SET_SIDE_DRAWER_OPEN = 'ui/SET_SIDE_DRAWER_OPEN';
export const SET_SCREEN_SIZE = 'ui/SET_SCREEN_SIZE';
export const SET_COLLEGE_ACCESS = 'ui/SET_COLLEGE_ACCESS';
export const SET_NFL_ACCESS = 'ui/SET_NFL_ACCESS';
export const SET_ADMIN_ACCESS = 'ui/SET_ADMIN_ACCESS';
export const SET_SELECTED_COLLEGE_TEAM = 'ui/SET_SELECTED_COLLEGE_TEAM';
export const SET_SELECTED_NFL_TEAM = 'ui/SET_SELECTED_NFL_TEAM';

export function setSideDrawerOpenAction (sideDrawerOpen:boolean) {
  return {
    type: SET_SIDE_DRAWER_OPEN,
    sideDrawerOpen,
  };
}

export function setScreenSizeAction (screenSize:ScreenSize) {
  return {
    type: SET_SCREEN_SIZE,
    screenSize,
  };
}

export function setCollegeAccessAction (collegeAccess:boolean) {
  return {
    type: SET_COLLEGE_ACCESS,
    collegeAccess,
  };
}

export function setNFLAccessAction (nflAccess:boolean) {
  return {
    type: SET_NFL_ACCESS,
    nflAccess,
  };
}

export function setAdminAccessAction (adminAccess:boolean) {
  return {
    type: SET_ADMIN_ACCESS,
    adminAccess,
  };
}

export function setSelectedCollegeTeamAction (selectedCollegeTeam:Team | undefined) {
  return {
    type: SET_SELECTED_COLLEGE_TEAM,
    selectedCollegeTeam,
  };
}

export function setSelectedNFLTeamAction (selectedNFLTeam:Team | undefined) {
  return {
    type: SET_SELECTED_NFL_TEAM,
    selectedNFLTeam,
  };
}
