import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '../icons/BackIcon';
import RemoveIcon from '../icons/RemoveIcon';
import Action from '../atoms/Action';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import NewMessageForm from './NewMessageForm';
import gql from '../services/gql';
import { formatDatetime } from '../services/converter';
import Message from '../types/Message';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_BACKGROUND_WARM,
  COLOR_DARK_GRAY,
  COLOR_BACKGROUND_LIGHT,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_SHADOW,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';

interface MessageContentProps {
  messageId: string;
  isSent: boolean;
  isDraft: boolean;
}

const REDIRECT_DELAY = 2000;

const useStyles = makeStyles(theme => ({
  messageContent: {
    padding: theme.spacing(4),
  },
  contentWrapper: {
    border: `1px solid ${COLOR_BACKGROUND_WARM}`,
  },
  backBtn: {
    color: COLOR_BLUE,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },
  icon: {
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(0.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    margin: theme.spacing(3, 0),
    position: 'relative',
  },
  time: {
    position: 'absolute',
    right: 0,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
  },
  contentRow: {
    borderTop: `1px solid ${COLOR_BACKGROUND_WARM}`,
    minHeight: '50px',
    padding: theme.spacing(1.5, 2.5),

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:last-of-type': {
      borderBottom: `1px solid ${COLOR_BACKGROUND_WARM}`,
    },
  },
  actions: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },

    '&:first-of-type': {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },
  label: {
    color: COLOR_DARK_GRAY,
  },
  info: {
    backgroundColor: COLOR_BACKGROUND_LIGHT,
  },
  attachments: {
    color: COLOR_BLUE,
  },
  noData: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    color: COLOR_DARK_GRAY,
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    textAlign: 'center',
  },

  [MEDIA.MOBILE]: {
    header: {
      flexWrap: 'wrap',
    },

    time: {
      position: 'relative',
      width: '100%',
      right: 'auto',
      marginTop: theme.spacing(1),
    },

    actions: {
      padding: theme.spacing(1.5),
    },

    messageContent: {
      padding: theme.spacing(2),
    },
  },
}), { name: MessageContent.name });

export default function MessageContent (props: MessageContentProps) {
  const {
    messageId,
    isSent = false,
    isDraft = false,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message>();
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    if (isSent) {
      fetchSentMessage(messageId);
    } else if (isDraft) {
      fetchDraftMessage(messageId);
    } else {
      fetchMessage(messageId);
    }
  }, [isDraft, isSent, messageId]);

  function fetchDraftMessage (id:string) {
    setLoading(true);

    gql(`
      draftMessage (id: ${id}) {
        id
        subject
        text
        to
        isDraft
        createdBy
        emailStatus
        createdAt
        updatedAt
      }`)
      .then((data: any) => data.draftMessage as Message)
      .then((message:Message) => message && setMessage(message))
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchSentMessage (id:string) {
    setLoading(true);

    gql(`
      sentMessage (id: ${id}) {
        id
        subject
        text
        to
        isDraft
        createdBy
        emailStatus
        updatedAt
      }`)
      .then((data: any) => data.sentMessage as Message)
      .then((message:Message) => message && setMessage(message))
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchMessage (id:string) {
    setLoading(true);

    gql(`
      mutation {
        readMessage (id: ${id}) {
          id
          userId
          subject
          text
          readDate
          isRead
          updatedAt
        }
      }`)
      .then((data: any) => data.readMessage as Message)
      .then((message:Message) => message && setMessage(message))
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function deleteMessages () {
    setLoading(true);

    const query = isDraft ? 'deleteDraftMessages' : 'deleteMessages';

    gql(`
      mutation {
        ${query} (ids: [${messageId}])
      }
    `)
        .then((data: any) => data[query] as boolean)
        .then((success: boolean) => {
          if (success) {
            showToast('Deleted. You\'re redirected to the inbox.', ToastType.SUCCESS);

            setTimeout(() => {
              history.push('/messages');
            }, REDIRECT_DELAY);
          } else {
            showToast('Failed to Delete Message. Try again.', ToastType.ERROR);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast('Failed to Delete Message. Try again.', ToastType.ERROR);
        })
        .finally(() => setLoading(false));
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const withAttachments = false;

  return (
    <div className={classes.messageContent}>
      {loading && <Loader inProgress={loading} />}

      <span
        className={classes.backBtn}
        onClick={() => history.goBack()}
      >
        <BackIcon className={classes.icon} />Back
      </span>

      {(message && isDraft) && (
        <NewMessageForm messageContent={message} />
      )}

      {(message && !isDraft) && (
        <>
          <div className={classes.header}>
            {message.subject}

            <span className={classes.time}>
              {formatDatetime(message.updatedAt, { appendExactTime: true })}
            </span>
          </div>

          <div className={classes.contentWrapper}>
            {!isSent && (
              <div className={clsx(classes.contentRow, classes.actions)}>
                <Action
                  className={classes.action}
                  icon={RemoveIcon}
                  iconClassName={classes.actionIcon}
                  onClick={deleteMessages}
                >
                  <span className={classes.actionText}>Delete</span>
                </Action>
              </div>
            )}

            <div className={clsx(classes.contentRow, classes.label)}>
              Subject
            </div>

            <div className={clsx(classes.contentRow, classes.info)}>
              {message.text}
            </div>

            {withAttachments && (
              <>
                <div className={clsx(classes.contentRow, classes.label)}>
                  Attachments
                </div>

                <div className={clsx(classes.contentRow, classes.info, classes.attachments)}>
                  Depth Charts Demo
                </div>
              </>
            )}
          </div>
        </>
      )}

      {(!message && !loading) && (
        <div className={classes.noData}>
          The message is not found
        </div>
      )}

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}
