import React from 'react';
import Icon from '../atoms/Icon';

function SpeedIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M16.08 3.84c7.776 0 14.08 6.304 14.08 14.08S23.856 32 16.08 32 2 25.696 2 17.92 8.304 3.84 16.08 3.84zm0 2.56c-6.362 0-11.52 5.158-11.52 11.52 0 6.362 5.158 11.52 11.52 11.52 6.362 0 11.52-5.158 11.52-11.52 0-6.362-5.158-11.52-11.52-11.52zm1.28 2.56v12.8H14.8V8.96h2.56z'/>
        <path d='M16.08 15.36a2.56 2.56 0 100 5.12 2.56 2.56 0 000-5.12zM26.695 4.215l1.81 1.81-3.2 3.2-1.81-1.81 3.2-3.2zM19.92 0v2.56h-7.68V0h7.68z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SpeedIcon);
