import React from 'react';
import Page from './Page';
import NewMessagePageTemplate from '../templates/NewMessagePageTemplate';

export default function NewMessagePage () {
  return (
    <Page title='New Message'>
      <NewMessagePageTemplate />
    </Page>
  );
}
