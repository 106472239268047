import React from 'react';
import Icon from '../atoms/Icon';

function ThreeX(props: any, ref: React.Ref<any>) {
  return (
    <Icon {...props} ref={ref} viewBox='0 0 100 100'>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="social-tracking-football-triple-verification-huddlevision-2" transform="translate(-236.000000, -806.000000)">
          <g id="Group" transform="translate(236.000000, 806.000000)">
            <circle id="Oval" fill="#F47321" cx="50" cy="50" r="50"></circle>
            <g id="text-copy-4" transform="translate(17.000000, 33.000000)" fill="#FFFFFF" fill-rule="nonzero">
              <path d="M14.4,34.45 C23.05,34.45 28.9,30.4 28.9,24.45 C28.9,19.85 24.7,17.05 21,16.65 C25.25,15.95 28.35,12.8 28.35,9.25 C28.35,3.75 23.65,0 14.45,0 C7.95,0 3.4,2.25 0.45,5.5 L5.2,11.8 C7.5,9.9 10.5,8.9 13.35,8.9 C16.25,8.9 18.3,9.5 18.3,11.05 C18.3,12.1 16.9,12.7 13.7,12.7 C12.5,12.7 9.5,12.7 8.85,12.65 L8.85,21.6 C9.35,21.55 12.25,21.5 13.7,21.5 C18.15,21.5 18.85,22.15 18.85,23.3 C18.85,24.5 17.3,25.55 14,25.55 C11.45,25.55 7.55,24.6 4.95,22.35 L0,29.25 C2.8,32.25 7.8,34.45 14.4,34.45 Z" id="Path"></path>
              <polygon id="Path" points="65.35 33.85 54.2 16.7 64.6 0.5 52.65 0.5 47.35 10 41.95 0.5 30.1 0.5 40.5 16.75 29.35 33.85 41.25 33.85 47.35 23.65 53.45 33.85"></polygon>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(ThreeX);