import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FolderIcon from '../icons/FolderIcon';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import Loader from '../atoms/Loader';
import Action from '../atoms/Action';
import CancelButton from '../atoms/CancelButton';
import DoneButton from '../atoms/DoneButton';
import Tooltip from '../atoms/Tooltip';
import { ToastType } from '../atoms/Toast';
import Dialog from './Dialog';
import gql from '../services/gql';
import { COLOR_BLUE, } from '../styles/colors';
import SearchesFolder from '../types/SearchesFolder';

interface SaveSearchModalProps {
  className?: string;
  dialogOpen: boolean;
  loading: boolean;
  savedSearchName?: string;
  savedSearchFolder?: SearchesFolder;
  saveSearch: (selectedFolder:SearchesFolder | undefined, searchName:string) => void;
  setDialogOpen: (isModalOpen:boolean) => void;
  setLoading: (loading:boolean) => void;
  showToast: (message:any, type:ToastType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  saveSearchModal: {
    position: 'relative',
  },
  fieldContainer: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
   flexGrow: 1,
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '30px',
  },
  foldersDropdown: {
    width: '100%',
  },

  createFolderAction: {
    margin: theme.spacing(1, 0, 0),
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_BLUE,
    textDecoration: 'underline',
  },

  folderIcon: {
    width: '22px',
    height: '22px',
    marginRight: theme.spacing(1),
    color: COLOR_BLUE,
  },
  folderSelectorRoot: {
    fontSize: theme.typography.pxToRem(16),
    height: '50px',
    lineHeight: '30px',
  },

  actions: {
    margin: theme.spacing(0, 1),
    height: '32px',
    minWidth: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  action: {
    marginLeft: theme.spacing(0.5),
  },
  text: {},
}), { name: SaveSearchModal.name });

export default function SaveSearchModal (props:SaveSearchModalProps) {
  const classes = useStyles();
  const {
    className,
    dialogOpen,
    loading,
    savedSearchName,
    savedSearchFolder,
    setDialogOpen,
    saveSearch,
    setLoading,
    showToast,
  } = props;

  const [selectedFolder, setSelectedFolder] = useState<SearchesFolder>();
  const [searchName, setSearchName] = useState<string>('');
  const [searchNameError, setSearchNameError] = useState<string>('');
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [createFolder, setCreateFolder] = useState<boolean>(false);

  const [folders, setFolders] = useState<SearchesFolder[]>([]);
  const [newFolder, setNewFolder] = useState<SearchesFolder>();

  useEffect(() => {
    fetchSavedSearchFolders();
  }, []);

  useEffect(() => {
    if (!!searchName) {
      setSearchNameError('');
    }
  }, [searchName]);

  useEffect(() => {
    if (!dialogOpen) {
      clearDialogData();
    }
  }, [dialogOpen, savedSearchName, savedSearchFolder]);

  useEffect(() => {
    if (!!savedSearchName) {
      setSearchName(savedSearchName);
    }
  }, [savedSearchName]);

  useEffect(() => {
    if (!!savedSearchFolder || !!newFolder) {
      setSelectedFolder(savedSearchFolder || newFolder);
    }
  }, [savedSearchFolder, newFolder]);

  useEffect(() => {
    if (!!newFolder) {
      setCreateFolder(false);
      setNewFolderName('');
    }
  }, [newFolder]);

  function fetchSavedSearchFolders () {
    setLoading(true);

    gql(`
      savedSearchesFolders {
        id
        name
      }
    `)
      .then((data:any) => data.savedSearchesFolders as SearchesFolder[])
      .then((folders:SearchesFolder[]) => {
        if (folders && folders.length) setFolders(folders);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function onCreateNewFolder (folderName:string) {
    setLoading(true);

    gql(`
      mutation {
        createSavedSearchesFolder (name: "${folderName}")
      }
    `)
      .then((data:any) => data.createSavedSearchesFolder as number)
      .then((newFolderId:number) => {
        if (newFolderId) {
          const dateNow = new Date();
          const lastViewedDate = `${dateNow.getMonth()}/${dateNow.getDay()}/${dateNow.getFullYear()}`;
          const newFolter = {
            id: newFolderId,
            name: folderName,
            createdAt: lastViewedDate,
            updatedAt: lastViewedDate,
            searches: [],
          };

          setFolders([
            ...folders,
            newFolter,
          ]);

          setNewFolder(newFolter);
        } else {
          showToast('Failed to create folder.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to create folder.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function clearDialogData () {
    setSearchName(savedSearchName || '');
    setSelectedFolder(savedSearchFolder || newFolder || undefined);
    setCreateFolder(false);
    setNewFolderName('');
  }

  function onSelectFolder (folderId:string) {
    const folder = folders.find(folder => folder.id === Number(folderId));

    setSelectedFolder(folder);
  }

  function saveSearchData() {
    if (!!searchName) {
      saveSearch(selectedFolder, searchName);
    } else {
      setSearchNameError('Please enter search name');
    }
  }

  return(
    <Dialog
      className={clsx(classes.saveSearchModal, className)}
      open={dialogOpen}
      title='Save Search'
      actions={[
        {
          name: 'Cancel',
          disabled: loading,
          onClick: () => setDialogOpen(false),
        },
        {
          name: 'Save Search',
          primary: true,
          disabled: loading,
          onClick: () => saveSearchData(),
        },
      ]}
      onClose={() => setDialogOpen(false)}
    >
      <Loader inProgress={loading} />

      <Input
        label='Search Name'
        value={searchName}
        error={searchNameError}
        autoFocus
        onChange={(value: string) => setSearchName(value)}
      />

      <div className={classes.fieldContainer}>
        <div className={classes.label}>Select a folder</div>
        <DropDown
          className={classes.foldersDropdown}
          selectorClassName={classes.folderSelectorRoot}
          emptyItem={{
            value: '',
            content: 'Main folder'
          }}
          items={folders.map(folder => ({
            content: folder.name,
            value: folder.id,
          }))}
          value={selectedFolder && selectedFolder.id}
          onChange={onSelectFolder}
        />
      </div>

      <div className={classes.fieldContainer}>
        {!createFolder && (
          <Action
            className={classes.createFolderAction}
            icon={FolderIcon}
            iconClassName={classes.folderIcon}
            onClick={() => setCreateFolder(true)}
          >
            <span className={classes.text}>Create a folder</span>
          </Action>
        )}

        {createFolder && (
          <div className={classes.inputContainer}>
            <Input
              className={classes.input}
              label='Folder Name'
              value={newFolderName}
              autoFocus
              onChange={(value: string) => setNewFolderName(value)}
            />

            <div className={classes.actions}>
              <Tooltip title='Save'>
                <div>
                  <DoneButton
                    className={classes.action}
                    disabled={!newFolderName}
                    onClick={() => onCreateNewFolder(newFolderName)}
                  />
                </div>
              </Tooltip>

              <Tooltip title='Cancel'>
                <div>
                  <CancelButton
                    className={classes.action}
                    onClick={() => setCreateFolder(false)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
