import React from 'react';
import Icon from '../atoms/Icon';

function TransferPortalProfileIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref} viewBox='0 0 22 22'>
       <g
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M17 12H7M17 12L13 16M17 12L13 8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#000000"
            strokeWidth={2.0}
            strokeLinejoin="round"
          />
        </g>
    </Icon>
  );
}
export default React.forwardRef(TransferPortalProfileIcon);
