import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_LIGHT_GRAY,
  COLOR_DARK_GRAY
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { StagedPlayerTrackEvent } from '../types/StagedPlayer';
import DatePicker from '../atoms/DatePicker';
import DropDown from '../atoms/DropDown';
import { formatDate } from '../services/player';
import { connect } from 'react-redux';
import { State } from '../redux/reducers';

interface EditTrackEventPopoverProps {
  className?: string;
  trackEvent: StagedPlayerTrackEvent|null;
  trackDatePastYearLimit: number;
  onUpdate: (trackEvent:StagedPlayerTrackEvent|null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  editTrackEventPopover: {
    minWidth: 130,
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    color: COLOR_TEXT,
    background: COLOR_WHITE,
    outline: 'none',
    position: 'relative',
    cursor: 'text',

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: 'currentColor',
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: 'currentColor',
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '&:focus': {
      color: COLOR_BLUE,
      borderColor: COLOR_BLUE,
      boxShadow: 'none',
    },
  },
  open: {
    color: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    boxShadow: 'none',
  },

  selectedValue: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
  },

  popover: {},
  paper: {
    borderRadius: 0,
  },
  popoverContent: {
    width: '440px',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    overflowY: 'scroll',
    boxSizing: 'border-box',
  },

  inputRow: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,

    '&:first-of-type': {
      marginTop: 0,
    },
  },
  input: {
    width: '100%',
  },
  halfInput: {
    marginLeft: theme.spacing(1),
    width: '50%',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-evenly',
  },
  action: {
    marginLeft: theme.spacing(1),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  date: {
    width: '50%',
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },
  dropDown: {
    width: '100%',
    maxWidth: '400px',
    marginBottom: theme.spacing(2),
    padding: 0,
    borderBottom: `2px solid ${COLOR_DARK_GRAY}`,
    background: COLOR_LIGHT_GRAY,
    minWidth: '75px',
  },
  dropDownSelect: {
    height: '47px',
    borderBottom: `1px solid ${COLOR_BORDER}`,
  },
  selectorRootClassName: {
    background: `${COLOR_LIGHT_GRAY} !important`,
  },
  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
  },
  dropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
}), { name: EditTrackEventPopover.name });

function EditTrackEventPopover (props:EditTrackEventPopoverProps) {
  const {
    className,
    trackEvent,
    trackDatePastYearLimit,
    onUpdate,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [meet, setMeet] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [performance, setPerformance] = useState<string | null>(null);
  const [dropdownSelected, setDropdownSelected] = useState<string>('year');
  const [date, setDate] = useState<Date | null>(null);

  const maxDate = formatDate(new Date());
  const today = new Date();
  const minDate = formatDate(new Date(today.setFullYear(today.getFullYear() - trackDatePastYearLimit)));

  useEffect(() => {
    setMeet(trackEvent?.meet || null);
    setYear(trackEvent?.year || null);
    setPerformance(trackEvent?.performance || null);
    setDate(trackEvent?.date || null);
    if (trackEvent?.date) {
      setDropdownSelected('date');
    } else if (trackEvent?.year) {
      setDropdownSelected('year');
    }
  }, [trackEvent]);

  const open = !!anchorEl;

  function onOpen (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onClose () {
    setAnchorEl(null);
  }

  function onDropdownSelect (value:string) {
    switch (value) {
      case 'year':
        setDate(null);
        break;
      case 'date':
        setYear(null);
        break;
    }
    setDropdownSelected(value);
  }

  function onSubmit (event:any) {
    event.preventDefault();

    if (dropdownSelected) {
      if (dropdownSelected && ((dropdownSelected == 'year' && !year) || (dropdownSelected == 'date' && !date) )) {
        return;
      }
    }

    onUpdate({
      meet,
      year,
      performance,
      date
    } as StagedPlayerTrackEvent);

    onClose();
  }

  return (
    <div
      className={clsx(
        classes.editTrackEventPopover,
        open && classes.open,
        className,
      )}
      onClick={!open ? onOpen : undefined}
      tabIndex={0}
    >
      <span className={classes.selectedValue}>
        {trackEvent?.performance || 'Click to Edit'}
      </span>

      <Popover
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContent}>
          <form onSubmit={onSubmit}>
            <div className={classes.inputRow}>
              <Input
                className={classes.input}
                label='Meet'
                value={meet || ''}
                onChange={(value:string) => setMeet(value || null)}
              />
            </div>

            <div className={classes.inputRow}>
              <div className={classes.dropdowns}>
                <div className={classes.dropdownWrapper}>
                  <DropDown
                    className={classes.dropDown}
                    selectorClassName={classes.dropDownSelect}
                    selectorRootClassName={classes.selectorRootClassName}
                    items={[
                      { content: 'Year', value: 'year' },
                      { content: 'Date', value: 'date' }
                    ]}
                    value={dropdownSelected}
                    onChange={(value:string) => onDropdownSelect(value)}
                  />
                </div>
              </div>
              {
                dropdownSelected === 'year' ?
                <Input
                  className={classes.halfInput}
                  label='Year'
                  value={year ? String(year) : ''}
                  onChange={(value:string) => setYear(Number(value))}
                /> :
                <DatePicker
                  className={clsx(classes.halfInput, classes.input, classes.date)}
                  label='Date'
                  value={date}
                  onChange={(date:Date | null) => setDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              }

              <Input
                className={classes.halfInput}
                label='Performance'
                autoFocus
                value={performance ? String(performance) : ''}
                onChange={(value:string) => setPerformance(value)}
              />
            </div>

            <div className={classes.actions}>
              <Button
                className={classes.action}
                onClick={() => {
                  onUpdate(null);
                  onClose();
                }}
              >
                Delete
              </Button>

              <Button
                className={classes.action}
                type='submit'
                primary
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </Popover>
    </div>
  );
}


const mapStateToProps = (state:State) => {
  return {
    trackDatePastYearLimit: state.configurations.trackDatePastYearLimit,
  };
};

export default connect(
  mapStateToProps,
)(EditTrackEventPopover);
