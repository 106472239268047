import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import SettingsIcon from '../icons/SettingsIcon';
import Avatar from '../atoms/Avatar';
import Halo from '../atoms/Halo';
import UserRankButtonWithPopover from '../organisms/UserRankButtonWithPopover';
import { State } from '../redux/reducers';
import { COLLEGE_ACHIEVEMENTS, NFL_ACHIEVEMENTS } from '../redux/reducers/achievements';
import {
  COLOR_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
} from '../styles/colors';
import User from '../types/User';
import { Achievement } from '../types/Achievement';

interface UserIdProps {
  className?: string;
  collapsed?: boolean;
  nflAccess?: boolean;
  user?: User;
  achievements?: Achievement[];
}

const useStyles = makeStyles((theme:Theme) => ({
  userId: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  collapsed: {
    height: '88px',
    marginBottom: theme.spacing(4),

    '& $halo': {
      visibility: 'hidden',
    },

    '& $avatar': {
      width: '70px',
      height: '70px',
      fontSize: theme.typography.pxToRem(32),
    },

    '& $smallBadgeRoot': {
      top: 0,
      transform: 'none',
    },

    '& $smallBadge': {
      top: 'auto',
      bottom: '-18px',
      right: '50%',
      transform: 'translateX(50%)',
    },

    '& $textInfo': {
      visibility: 'hidden',
    },
  },

  halo: {
    display: 'block',
  },

  avatar: {
    width: '122px',
    height: '122px',
  },

  smallBadgeRoot: {
    top: '6px',
    position: 'absolute',
    transform: 'translateY(-18px) scale(0.39)',
    transition: 'transform 0.3s',
  },

  smallBadge: {
    right: '0',
    bottom: '0',
    transform: 'scale(1.8) translate(-13px, 55px)',
    transformOrigin: '100% 100%',
    width: '34px',
    height: '34px',
    padding: 0,
    transition: 'transform 0.3s',
  },

  settingsLink: {
    width: '34px',
    height: '34px',
    appearance: 'none',
    padding: '4px',
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: '50%',
    background: COLOR_WHITE,
    cursor: 'pointer',
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.05)',
    transition: 'box-shadow 0.3s',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      '& $settingsIcon': {
        color: COLOR_BLUE,
      },
    },
  },
  settingsIcon: {
    width: '16px',
    height: '16px',
    margin: '0 auto',
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  textInfo: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    ...theme.typography.h2,
  },
  title: {
    marginTop: '4px',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    fontWeight: 'bold',
    color: COLOR_DARK_GRAY,
  },
  email: {
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
}), { name: UserId.name });

function UserId (props:UserIdProps) {
  const {
    className,
    user,
    achievements = [],
    nflAccess = false,
    collapsed,
  } = props;
  const classes = useStyles(props);
  let myAccountPageUrl = '/my-account';

  if (!user) return null;

  const achievementsCount = (nflAccess ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS)
    .reduce((count:number, achievementInOrder:Achievement) => {
      const completed = !!(achievements && achievements.find((achievement:Achievement) => achievement === achievementInOrder && achievementInOrder));

      return completed ? count + 1 : count;
    }, 0);

  const settingsButton = (
    <Link
      className={classes.settingsLink}
      to={myAccountPageUrl}
    >
      <SettingsIcon className={classes.settingsIcon} />
    </Link>
  );

  const avatar = (
    <Avatar
      className={classes.avatar}
      src={user.photoUrl}
      alt={`Photo of ${user.firstName} ${user.lastName}`}
      initials={`${(user.firstName || ' ')[0]}${(user.lastName || ' ')[0]}`.trim()}
    />
  );

  return (
    <div className={clsx(classes.userId, collapsed && classes.collapsed, className)}>
      <Badge
        overlap='circle'
        classes={{
          root: classes.smallBadgeRoot,
          badge: classes.smallBadge,
        }}
        badgeContent={settingsButton}
      >
        {avatar}
      </Badge>

      <Halo
        className={classes.halo}
        highlighted={achievementsCount}
      >
        <Badge
          overlap='circle'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          badgeContent={<UserRankButtonWithPopover user={user} />}
        >
          <Badge
            overlap='circle'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={settingsButton}
          >
            {avatar}
          </Badge>
        </Badge>
      </Halo>

      <div className={classes.textInfo}>
        <div className={classes.name}>{user.firstName} {user.lastName}</div>
        <div className={classes.title}>{user.designation}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    achievements: state.achievements.achievements,
  };
};

export default connect(
  mapStateToProps,
  null,
)(UserId);
