import React from 'react';
import Icon from '../atoms/Icon';

function SendIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M20.364 32L32 0 0 11.636 20.364 32zm7.438-27.803l-8.45 23.239-14.79-14.789 23.24-8.45z'/>
        <path d='M20.225 10l1.776 1.775-10.044 10.043-1.775-1.775z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SendIcon);