import { Dispatch,  } from 'redux';
import { setUserAction, setAndUpdateUserPreferencesAction } from '../actions/user';
import {
  setCollegeAccessAction,
  setNFLAccessAction,
  setAdminAccessAction,
} from '../actions/ui';
import { setAchievementsVisibilityAction } from '../actions/achievements';
import gql from '../../services/gql';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import { cache, CacheExpiration, CacheKey, retrieve } from '../../services/cache';
import { AchievementsVisibility } from '../reducers/achievements';
import User from '../../types/User';

export function fetchUser (userId:number) {
  return (dispatch:Dispatch, getState:any) => {
    const cachedUser = retrieve(CacheKey.USER);
    const cacheIsValid = !!cachedUser?.id;

    if (cacheIsValid) {
      processUser(cachedUser, dispatch);
      return;
    }

    return gql(`
      user (id: ${userId}) {
        id
        firstName
        lastName
        email
        photoUrl
        designation
        totalLogins
        lastLogin
        last30DaysLoginCount
        rank
        showAchievements
        maximizeAchievements
        isEmailDisabled
        isSMSOptedOut
        isMaster
        percVSUsers
        canClearStagingPlayers
        alertEnabledForSavedPlayers
        accessLevel {
          id
          name
          type
          staffRecords
          hasCollegeAccess
          hasNFLAccess
          hasArchiveAccess
          hasEvalDotAndNotes
          hasAPI
          exportAllowanceType
          exportAllowance
          canComparePlayers
          comparePlayerLimit
          depthChartEnabled
          canManageUsers
          canSendMessages
          canAccessHelp
          canImport
          sideBySideExportType
          sideBysideExportCount
          teamSelectCollegeType
          canTeamSelectNFL
          canTeamSelectCollege
          accessCollegeTeamPage
          accessNFLTeamPage
          recruitingEvaluationBoard
          accounts
          dashboardTeamLogos
          exportSearchResultsAllowanceType
          exportSearchResultsLimit
          pinPlayersAllowanceType
          pinPlayersLimit
          teamPageExport
          transferPortal
          draftProjector
          hsYearsMarch1
          hsYearsAugust1
          canAccessDashboard
          hasJucoAccess
        }
        college {
          recruitingClass
        }
        collegeTeam {
          id
          type
          name
          shortName
          slug
          logo247
          logoESPN
          logoAlt
        }
        nflTeam {
          id
          type
          name
          shortName
          slug
          logo247
          logoESPN
          logoAlt
        }
        company {
          id
          name
          logoUrl
        }
        program {
          id
          name
          accessLevelId
          hasTransferPortal
          hasDraftProjector
          autoDepthChartEnabled
          startDate
          expirationDate
          invoice
          apiKey
          createdAt
          nflTeamId
          collegeId
        }
        preferences
        startDate
        expirationDate
        createdAt
      }
    `)
      .then((data:any) => data && data.user as User)
      .then((user:User) => {
        processUser(user, dispatch);
        cache(CacheKey.USER, user, CacheExpiration.SHORT);
      })
      .catch(console.error);
  };
}

function processUser (user:User, dispatch:Dispatch) {
  if (user) {
    dispatch(setUserAction(user));

    let achievementsVisibility = AchievementsVisibility.CLOSED;
    if (user.showAchievements && user.maximizeAchievements) {
      achievementsVisibility = AchievementsVisibility.OPENED;
    } else if (user.showAchievements && !user.maximizeAchievements) {
      achievementsVisibility = AchievementsVisibility.MINIMIZED;
    }

    dispatch(setAchievementsVisibilityAction(achievementsVisibility));

    const viewMode = clientStorage.get(StorageKey.VIEW_MODE);

    const collegeAccess = user.accessLevel.hasCollegeAccess;
    const nflAccess = (user.accessLevel.hasNFLAccess && viewMode === 'nfl') || user.accessLevel.type === 'nfl';
    const adminAccess = user.accessLevel.type === 'admin';

    dispatch(setCollegeAccessAction(collegeAccess));
    dispatch(setNFLAccessAction(nflAccess));
    dispatch(setAdminAccessAction(adminAccess));
  }
}

export function updateUserPreferences (preferences:any) {
  return (dispatch:Dispatch, getState:any) => {
    const cachedUser = retrieve(CacheKey.USER);
    const cacheIsValid = !!cachedUser?.id;

    if (cacheIsValid) {
      cache(CacheKey.USER, {...cachedUser, preferences}, CacheExpiration.SHORT);
    }
    dispatch(setAndUpdateUserPreferencesAction(preferences));
  }
}
