import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_DARK_GRAY } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface NotAvailableProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  notAvailable: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },
}), { name: NotAvailable.name });

export default function NotAvailable (props: NotAvailableProps) {
  const { className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.notAvailable, className)}>N/A</div>
  );
}
