import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DropDown from '../atoms/DropDown';

interface PageLimitDropdownProps {
  className?: string;
  selectorRootClassName?: string;
  value: any;
  onChange: (value:any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {}
}), { name: PageLimitDropdown.name });

const PAGE_LIMITS = [
  { content: '10', value: '10' },
  { content: '25', value: '25' },
  { content: '50', value: '50' },
  { content: '100', value: '100' },
];

export default function PageLimitDropdown (props: PageLimitDropdownProps) {
  const {
    value = '',
    className,
    selectorRootClassName,
    onChange = () => {},
  } = props;
  const classes = useStyles();

  return (
    <DropDown
      className={clsx(classes.dropdown, className)}
      selectorRootClassName={selectorRootClassName}
      items={PAGE_LIMITS}
      value={String(value)}
      onChange={(limit:string) => onChange(Number(limit))}
    />
  );
}
