import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import Action from '../atoms/Action';
import MyImportsIcon from '../icons/MyImportsIcon';
import MyImportsTable from '../molecules/MyImportsTable';
import { State } from '../redux/reducers';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import User from '../types/User';

interface MyImportPageProps {
  user?: User;
}

const useStyles = makeStyles(theme => ({
  importPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  import: {
    padding: theme.spacing(4),
  },
  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1.5),
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    fontSize: theme.typography.pxToRem(14),

    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
      color: COLOR_WHITE,
      textDecoration: 'none',
    }
  },
  filePicker: {
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    cursor: 'pointer',
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),

    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
    }
  },
  fileInputLabelClass: {
    height: '100%',
    width: '100%',
    lineHeight: '50px',
    cursor: 'pointer',
    textAlign: 'center',
  }
}), { name: MyImportPage.name });

function MyImportPage (props:MyImportPageProps) {
  const classes = useStyles();
  const { user } = props;

  const history = useHistory();
  const [refreshStagedPlayers, setRefreshStagedPlayers] = useState<boolean>(false);


  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canImport) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <MyImportsIcon  className={classes.icon} />
            Import Files
          </h1>

          <div className={classes.actions}>
            <Action
              className={classes.action}
              onClick={() => {
                setRefreshStagedPlayers(true);
              }}
              icon={RefreshIcon}
              iconClassName={classes.icon}
            >
              Refresh
            </Action>
          </div>
        </div>

        <MyImportsTable refreshStagedPlayers={refreshStagedPlayers} setRefreshStagedPlayers={setRefreshStagedPlayers} user={user} />
        
      </Paper>
    </>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(MyImportPage);
