import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TeamProfileStatistics from './TeamProfileStatistics';
import { SCORE_TYPE } from '../atoms/Score';
import { COLOR_DARK_GRAY } from '../styles/colors';
import Team from '../types/Team';
import PlayerStats from '../types/PlayerStats';

interface TeamCurrentPlayerStatsProps {
  className?: string;
  team?: Team;
  nflAccess?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamCurrentPlayerStats: {},

  statisticsLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  statisticsDropDown: {
    marginTop: theme.spacing(1),
  },
  statisticsDropDownSelector: {
    fontSize: theme.typography.pxToRem(12),
  },
}), { name: TeamCurrentPlayerStats.name });

export default function TeamCurrentPlayerStats (props: TeamCurrentPlayerStatsProps) {
  const {
    className,
    team,
    nflAccess,
  } = props;
  const classes = useStyles();
  const [playerStats, setPlayerStats] = useState<PlayerStats>();

  useEffect(() => {
    if (team?.playerStats) {
      setPlayerStats(team.playerStats);
    }
  }, [team, nflAccess]);

  if (!playerStats || !team) return null;

  return (
    <TeamProfileStatistics
      className={clsx(classes.teamCurrentPlayerStats, className)}
      title={nflAccess
        ? <>Drafted & UDFA Statistics <span style={{ fontWeight: 400, color: COLOR_DARK_GRAY }}>(2010 – Present)</span></>
        : 'Current Player Statistics'
      }
      team={team}
      isNFL={nflAccess}
      rows={[
        {
          scoreType: SCORE_TYPE.PAI,
          label: 'PAI:',
          values: playerStats.pai,
        },
        {
          scoreType: SCORE_TYPE.COMBINE,
          label: nflAccess ? 'HS Combine:' : 'Combine:',
          values: playerStats.combine,
        }
      ]}
    />
  );
}
