import React from 'react';
import Icon from '../atoms/Icon';

function TeamIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(4, 5)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <polyline points='7 18 7 22 21 22 21 10 24 10 24 6 20 4.857' />
          <path d='M13,0 C13,0 12.023,1 10,1 C7.977,1 7,0 7,0 L0,2 L0,6 L3,6 L3,18 L17,18 L17,6 L20,6 L20,2 L13,0 Z' />
          <path d='M8,13 L10.5,13 C11.328,13 12,12.328 12,11.5 L12,11.5 C12,10.672 11.328,10 10.5,10 L10,10 M9,10 L10.5,10 C11.328,10 12,9.328 12,8.5 L12,8.5 C12,7.672 11.328,7 10.5,7 L8,7' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TeamIcon);
