import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import Button from '../atoms/Button';
import SearchPlayers from '../organisms/SearchPlayers';
import FilterIcon from '../icons/FilterIcon';
import SearchPlayersIcon from '../icons/SearchPlayersIcon';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';

const useStyles = makeStyles(theme => ({
  searchPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  searchPlayer: {
    padding: theme.spacing(2,4,4,4),
  },

  filterBtn: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterIcon: {
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(1),
  },

  filterDrawer: {
    width: '90%',
    maxWidth: '440px',
  },
  drawerHeader: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  drawerContent: {
    padding: theme.spacing(4, 0),
  },
  closeButton: {
    marginLeft: 'auto',
  },

  filterWrapper: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(0, 4),

    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  filterContent: {
    padding: theme.spacing(4, 0),
  },
  filterTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  dropdown:{
    height: '50px',
    width: '100%',
  },

  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  action: {
    height: '50px',
    width: '170px',
    marginRight: theme.spacing(2),
  },

  [MEDIA.MOBILE]: {
    header: {
      margin: theme.spacing(2),
    },

    searchPlayer: {
      padding: theme.spacing(2),
    },
  },
}), { name: SearchPageTemplate.name });

export default function SearchPageTemplate () {
  const classes = useStyles();
  const { searchId } = useParams<any>();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <PageContainer className={classes.searchPageTemplate}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <SearchPlayersIcon  className={classes.headerIcon} />
            Search Players
          </h1>

          <Button
            className={classes.filterBtn}
            primary
            onClick={() => setIsDrawerOpen(true)}
          >
            <FilterIcon className={classes.filterIcon}/>
            Filter
          </Button>
        </div>

        <SearchPlayers
          className={classes.searchPlayer}
          searchId={Number(searchId)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={(value: boolean) => setIsDrawerOpen(value)}
        />
      </Paper>
    </PageContainer>
  );
}
