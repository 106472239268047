import React from 'react';
import Icon from '../atoms/Icon';

function FreshmanIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(-860, -484)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <g transform='translate(410, 189)'>
            <g transform='translate(1, 286)'>
              <g transform='translate(459, 19)'>
                <path d='M1,0 L1,12 M1,0 L1,5.29411765 L9,5.29411765 L9,0 L1,0 Z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(FreshmanIcon);
