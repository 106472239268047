import React, { useRef }  from 'react';
import clsx from 'clsx';
import Cropper from 'react-cropper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import 'cropperjs/dist/cropper.css';

interface ImageCropperProps {
  className?: string;
  src: string;
  mimeType?: string;
  onCrop: (imageFile:Blob) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageCropper: {
    width: '100%',
    height: '100%',
  },
}), { name: ImageCropper.name });

export default function ImageCropper (props: ImageCropperProps) {
  const {
    className,
    src,
    mimeType = 'image/png',
    onCrop = () => {},
  } = props;
  const classes = useStyles();

  const cropperRef = useRef<HTMLImageElement>(null);

  function onUpdateCrop () {
    const imageElement:any = cropperRef?.current;
    const cropper:any = imageElement?.cropper;

    cropper
      .getCroppedCanvas()
      .toBlob(onCrop, mimeType);
  }

  return (
    <Cropper
      className={clsx(classes.imageCropper, className)}
      ref={cropperRef}
      src={src}
      dragMode='crop'
      aspectRatio={1}
      viewMode={2}
      minCropBoxHeight={256}
      minCropBoxWidth={256}
      background={false}
      rotatable={false}
      checkOrientation={false}
      ready={onUpdateCrop}
      zoom={onUpdateCrop}
      cropend={onUpdateCrop}
    />
  );
}
