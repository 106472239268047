import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHeadCell from '../atoms/TableHeadCell';
import Action from '../atoms/Action';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import NotAvailable from '../atoms/NotAvailable';
import { downloadAndSaveXLSReport } from '../services/export-xls-report';
import gql from '../services/gql';
import { datetimeToDate, displayDateTime } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_BACKGROUND_WARM,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_BACKGROUND_LIGHT
} from '../styles/colors';
import MEDIA from '../styles/media';
import PlayerImport from '../types/Import';
import User from '../types/User';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import Pagination from '../atoms/Pagination';

interface MyImportsProps {
  className?: string;
  user?: User;
  refreshStagedPlayers?: boolean;
  setRefreshStagedPlayers: (value:boolean) => void;
}

const COLUMN = {
  NAME: 'name',
  PLAYERS: 'players',
  IMPORT_DATE: 'imports',
  UPDATED_DATE: 'last_updated'
};

const COLUMNS = [
  COLUMN.NAME,
  COLUMN.PLAYERS,
  COLUMN.IMPORT_DATE,
  COLUMN.UPDATED_DATE

];

const COLUMN_TITLE = {
  [COLUMN.NAME]: 'Name',
  [COLUMN.PLAYERS]: 'Players',
  [COLUMN.IMPORT_DATE]: 'Import Date',
  [COLUMN.UPDATED_DATE]: 'last Updated',
};

const useStyles = makeStyles(theme => ({
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0, 4),
    fontFamily: FONT_PROXIMA_NOVA,
    position: 'relative',
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(0.5),
  },
  importLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: 'auto',
  },
  importsTableWrap: {
    overflow: 'auto'
  },
  importsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: 'none',
    background: COLOR_WHITE,
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
  playersTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  empty: {
    alignItems: 'center',
    borderColor: COLOR_BACKGROUND_WARM,
    borderStyle: 'solid',
    borderWidth: `${theme.typography.pxToRem(1)} 0`,
    color: COLOR_DARK_GRAY,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    padding: theme.spacing(2, 0),
  },
  fileLink: {
    cursor: 'pointer',
    color: COLOR_BLUE,
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(16),
    padding: 0,

    '&:focus': {
      color: COLOR_DARK_BLUE,
      outline: 'none',
    }
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: '20px',
      top: '50%',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
    },

    pagination: {
      maxWidth: '60%',
    },
  },

  [MEDIA.MOBILE]: {
    headerTitle: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0, 2),
    },
  },
}), { name: MyImportsTable.name });

export default function MyImportsTable (props:MyImportsProps) {
  const classes = useStyles();
  const {
    className,
    user,
    refreshStagedPlayers,
    setRefreshStagedPlayers
  } = props;

  const [playersImports, setPlayersImports] = useState<PlayerImport[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchPlayersImports()
  }, []);


  useEffect(() => {
    if (refreshStagedPlayers) {
      fetchPlayersImports()
        .finally(() => {
          setLoading(false);
          setRefreshStagedPlayers(false);
        });
    }
  }, [refreshStagedPlayers]);

  function fetchPlayersImports() {
    setLoading(true);

    return gql(`
      importRequests {
        id
        fileName
        fileUrl
        status
        playerCount
        completedDate
        updatedAt
      }
    `)
      .then((data:any) => data.importRequests as PlayerImport[])
      .then((importRequests:PlayerImport[]) => setPlayersImports(importRequests || []))
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch imported players. Try refreshing the page.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const tableColumns = COLUMNS.map((column: string) => {
    switch (column) {
      case COLUMN.NAME:
        return {
          value: COLUMN.NAME,
          title: COLUMN_TITLE[COLUMN.NAME],
          renderContent: (playerImport:PlayerImport) => (
            <Action
              className={classes.fileLink}
              onClick={() => downloadAndSaveXLSReport(playerImport.fileName, undefined, playerImport.fileUrl)}
            >
              {playerImport.fileName}
            </Action>
          ),
        };
      case COLUMN.PLAYERS:
        return {
          value: COLUMN.PLAYERS,
          title: COLUMN_TITLE[COLUMN.PLAYERS],
          renderContent: (playerImport:PlayerImport) => playerImport.playerCount || 0,
        };
      case COLUMN.IMPORT_DATE:
        return {
          value: COLUMN.IMPORT_DATE,
          title: COLUMN_TITLE[COLUMN.IMPORT_DATE],
          renderContent: (playerImport:PlayerImport) => playerImport.completedDate
            ? datetimeToDate(playerImport.completedDate)
            : <NotAvailable />,
        };
      case COLUMN.UPDATED_DATE:
        return {
          value: COLUMN.UPDATED_DATE,
          title: COLUMN_TITLE[COLUMN.UPDATED_DATE],
          renderContent: (playerImport:PlayerImport) => playerImport.updatedAt
            ? displayDateTime(playerImport.updatedAt, true)
            : <NotAvailable />,
        };
    }
  });

  function renderImportRow (exportRowData: PlayerImport) {
    return (
      <TableRow
        key={exportRowData.id}
        className={classes.tableRow}
      >
        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === COLUMN.NAME && classes.nameCell,
              tableColumn.value === COLUMN.PLAYERS && classes.playerCountCell,
            )}
          >
            {tableColumn.renderContent(exportRowData)}
          </TableCell>
        ))}
      </TableRow>
    )
  }
  const limit = 100;
  const totalPages = Math.ceil(playersImports.length / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = playersImports.length < limit ? playersImports.length : (limit * currentPage);

  return (
    <>
      <TableWithDoubleScrollBars tableClassName={clsx(classes.playersTable, className)}>
      <Loader inProgress={loading} />
        <TableHead className={classes.tableHead}>
          <TableRow>
            {tableColumns.map((tableColumn:any) => (
              <TableHeadCell
                key={tableColumn.value}
                name={tableColumn.sortable ? tableColumn.value : undefined}
              >
                {tableColumn.title}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!playersImports.length && (
            <TableRow
              key='empty'
              className={classes.tableRow}
            >
              <TableCell
                className={clsx(classes.tableCell, classes.emptyCell)}
                colSpan={tableColumns.length + 1}
              >
                <div className={classes.empty}>
                  No Files
                </div>
              </TableCell>
            </TableRow>
          )}

          {playersImports
            .slice(itemsFrom, itemsTo)
            .map((item:PlayerImport) =>
              renderImportRow(item as PlayerImport))
          }
        </TableBody>
      </TableWithDoubleScrollBars>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {playersImports.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>
      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
