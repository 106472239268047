import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import PlusIcon from '../icons/PlusIcon';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import Toast, { ToastType } from '../atoms/Toast';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import DatePicker from '../atoms/DatePicker';
import Tooltip from '../atoms/Tooltip';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import gql, { serializeGQLObject } from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import User from '../types/User';
import AccessLevel, { AccessLevelType } from '../types/AccessLevel';
import Company from '../types/Company';
import Team from '../types/Team';
import Program from '../types/Program';

interface AddEditUserFormProps {
  className?: string;
  userStatusEnabled: boolean;
  isEditMode: boolean;
  user?: User;
  loading: boolean;
  setLoading: (pageLoading:boolean) => void;
  collegeTeams: Team[];
  nflTeams: Team[];
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
}

const ACCESS_LEVELS_VISIBILITY: {[key:string]: any} = {
  college: [AccessLevelType.COLLEGE],
  nfl: [AccessLevelType.COLLEGE, AccessLevelType.NFL],
  admin: [AccessLevelType.COLLEGE, AccessLevelType.NFL, AccessLevelType.ADMIN, AccessLevelType.LIMITED],
  limited: [AccessLevelType.COLLEGE, AccessLevelType.NFL, AccessLevelType.ADMIN, AccessLevelType.LIMITED],
};

const useStyles = makeStyles(theme => ({
  addEditUserForm: {
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  input: {
    width: '100%',
    maxWidth: '400px',
    padding: 0,
    marginTop: theme.spacing(4),

    '&:first-of-type': {
      marginTop: 0,
    },
  },
  dropdownWrapper: {
    marginTop: theme.spacing(4),
    maxWidth: '400px',
  },
  dropDown: {
    width: '100%',
    maxWidth: '400px',
    padding: 0,
  },
  dropdownLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
  },
  dropDownSelect: {
    height: '50px',
  },

  teamSelect: {
    maxWidth: '400px',

    '& input': {
      boxSizing: 'border-box',
      height: '50px',
    },
  },

  programWrapper: {
    maxWidth: '100%',
  },
  programRow: {
    display: 'flex',
    alignItems: 'center',
  },
  programAccounts: {
    height: 52,
    paddingTop: 4,
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    cursor: 'help',
  },
  programAccountsExceedsLimit: {
    alignItems: 'flex-start',
    color: '#f44336',
  },
  programAccountsCheckboxWrapper: {
    height: 52,
    marginLeft: 32,
  },
  accountsLimitChecked: {
    height: 'auto',
  },

  companyName: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  companyLogo: {
    height: 24,
    marginLeft: theme.spacing(2),
  },

  plusIcon: {
    height: '12px',
    width: '12px',
    marginRight: theme.spacing(1),
  },
  calendarIcon: {
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },

  checkbox: {
    marginRight: theme.spacing(1),
  },

  dateWrapper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  date: {
    marginTop: 0,
    marginRight: theme.spacing(4),
    marginBottom: 0,
  },
  dateCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },

  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  action: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  '@media (max-width: 1023px)': {
    dateWrapper: {
      maxWidth: '400px',
    },
    date: {
      maxWidth: 'none',
      flexShrink: 0,
    },
    dateCheckboxWrapper: {
      marginTop: theme.spacing(2),
    },
  },
}), { name: AddEditUserForm.name });

function AddEditUserForm (props:AddEditUserFormProps) {
  const {
    className,
    userStatusEnabled = true,
    isEditMode = false,
    user,
    loading,
    setLoading,
    collegeTeams,
    nflTeams,
    fetchCollegeTeams,
    fetchNFLTeams,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [accessLevels, setAccessLevels] = useState<AccessLevel[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [programAccessLevelType, setProgramAccessLevelType] = useState<AccessLevelType | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [visibleAccessLevels, setVisibleAccessLevels] = useState<AccessLevel[]>([]);
  const [selectedNFLTeam, setSelectedNFLTeam] = useState<Team | null>();
  const [selectedCollegeTeam, setSelectedCollegeTeam] = useState<Team | null>();
  const [selectedAccessLevelId, setSelectedAccessLevelId] = useState<number | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(null);

  const [subscriptionStartDate, setSubscriptionStartDate] = useState<Date | null>(null);
  const [subscriptionStartsWhenProgram, setSubscriptionStartsWhenProgram] = useState<boolean>(true);

  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [expiresWhenProgram, setExpiresWhenProgram] = useState<boolean>(true);

  const [allowedToExceedAccountsLimit, setAllowedToExceedAccountsLimit] = useState<boolean>(false);
  const [isMaster, setIsMaster] = useState<boolean>(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
  const [optedOut, setOptedOut] = useState<boolean>(false);
  const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const selectedProgram:Program|undefined = (selectedProgramId && programs?.length)
    ? programs.find((program:Program) => program.id === selectedProgramId)
    : undefined;
  const selectedProgramAccessLevel:AccessLevel|undefined = (selectedProgram?.accessLevelId && accessLevels?.length)
    ? accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === selectedProgram.accessLevelId)
    : undefined;
  const selectedProgramUsersCount:number = selectedProgram?.usersCount || 0;
  const programAccountsLimit:number = typeof selectedProgramAccessLevel?.accounts === 'number'
    ? selectedProgramAccessLevel.accounts
    : Infinity;
  const accountLimitExceeded = selectedProgramUsersCount >= programAccountsLimit;

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      fetchPrograms(),
      fetchAccessLevels(),
      fetchCompanies(),
    ])
    .finally(() => setLoading(false));

    fetchCollegeTeams();
    fetchNFLTeams();
  }, []);

  useEffect(() => {
    if (
      ((isEditMode && user) || !isEditMode)
      && programs && programs.length
      && accessLevels && accessLevels.length
      && collegeTeams && collegeTeams.length
      && nflTeams && nflTeams.length
      && companies && companies.length
    ) {
      setLoading(false);
    }
  }, [user, isEditMode, programs, accessLevels, collegeTeams, nflTeams, companies]);

  useEffect(() => {
    if (isEditMode && user && user.programId) {
      setSelectedProgramId(user.programId);
    }
  }, [isEditMode, user]);

  useEffect(() => {
    if (selectedProgramId
      && !selectedAccessLevelId
      && isEditMode
      && user
      && programs && programs.length
      && accessLevels && accessLevels.length
      && collegeTeams && collegeTeams.length
      && nflTeams && nflTeams.length
    ) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);

      const selectedProgram = programs.find(program => program.id === selectedProgramId);

      if (!user.accessLevelId && selectedProgram && selectedProgram.accessLevelId) {
        setSelectedAccessLevelId(selectedProgram.accessLevelId);
      } else if (user.accessLevelId) {
        setSelectedAccessLevelId(user.accessLevelId);
      }

      if (!user.collegeId && selectedProgram && selectedProgram.collegeId) {
        const collegeTeam = collegeTeams.find((team:Team) => team.id === selectedProgram.collegeId);
        if (collegeTeam) setSelectedCollegeTeam(collegeTeam);
      } else if (user.collegeId) {
        const collegeTeam = collegeTeams.find((team: Team) => team.id === user.collegeId);
        if (collegeTeam) setSelectedCollegeTeam(collegeTeam);
      }

      if (!user.nflTeamId && selectedProgram && selectedProgram.nflTeamId) {
        const nflTeam = nflTeams.find((team: Team) => team.id === selectedProgram.nflTeamId);
        if (nflTeam) setSelectedNFLTeam(nflTeam);
      } else if (user.nflTeamId) {
        const nflTeam = nflTeams.find((team: Team) => team.id === user.nflTeamId);
        if (nflTeam) setSelectedNFLTeam(nflTeam);
      }

      if (!user.companyId && selectedProgram && selectedProgram.companyId) {
        setSelectedCompanyId(selectedProgram.companyId);
      } else if (user.companyId) {
        setSelectedCompanyId(user.companyId);
      }

      if (user.startDate) {
        setSubscriptionStartDate(new Date(user.startDate));
        setSubscriptionStartsWhenProgram(false);
      } else {
        setSubscriptionStartsWhenProgram(true);
      }

      if (user.expirationDate) {
        setExpirationDate(new Date(user.expirationDate));
        setExpiresWhenProgram(false);
      } else {
        setExpiresWhenProgram(true);
      }

      if (user.isMaster) {
        setIsMaster(true);
      }

      if (user.isEmailDisabled) {
        setIsEmailDisabled(true);
      }

      if (user.isSMSOptedOut) {
        setOptedOut(true);
      }

      if (user.designation) {
        setJobTitle(user.designation);
      }
    }
  }, [
    isEditMode,
    user,
    programs,
    accessLevels,
    collegeTeams,
    nflTeams,
    selectedProgramId,
    selectedAccessLevelId,
  ]);

  // initialize visibleAccessLevels first time
  useEffect(() => {
    if (
      (!visibleAccessLevels || !visibleAccessLevels.length)
      && selectedProgramId && programs && programs.length
      && selectedAccessLevelId && accessLevels && accessLevels.length
    ) {
      const selectedProgram = programs.find(program => program.id === selectedProgramId);

      if (selectedProgram) {
        const selectedProgramAccessLevel = accessLevels.find(
          (accessLevel:AccessLevel) => accessLevel.id === selectedAccessLevelId
        );

        if (selectedProgramAccessLevel) {
          setProgramAccessLevelType(selectedProgramAccessLevel.type);
          updateAccessLevels(selectedProgramAccessLevel.type, selectedProgramAccessLevel.id);
        }
      }
    }
  }, [
    visibleAccessLevels,
    programs,
    selectedProgramId,
    accessLevels,
    selectedAccessLevelId,
  ]);

  useEffect(() => {
    setAllRequiredFieldsFilled(!!firstName.length
      && !!lastName.length
      && !!email.length
      && ((!subscriptionStartsWhenProgram && subscriptionStartDate) || subscriptionStartsWhenProgram)
      && ((!expiresWhenProgram && expirationDate) || expiresWhenProgram)
      && !!selectedProgramId
    );
  }, [
    firstName,
    lastName,
    email,
    subscriptionStartDate,
    subscriptionStartsWhenProgram,
    expirationDate,
    expiresWhenProgram,
    selectedProgramId,
  ]);

  function fetchPrograms () {
    return gql(`
      programs {
        id
        name
        accessLevelId
        collegeId
        companyId
        nflTeamId
        expirationDate
        apiKey
        isEnabled
        isEmailDisabled
        usersCount
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.programs as Program[])
      .then((programs:Program[]) => {
        if (programs?.length) {
          setPrograms(programs.sort((program1:Program, program2:Program) => {
            if (program1.name > program2.name) return 1;
            if (program1.name < program2.name) return -1;
            return 0;
          }));
        } else {
          showToast('Failed to fetch programs.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch programs.', ToastType.ERROR);
      });
  }

  function fetchCompanies () {
    return gql(`
      companies {
        id
        name
        logoUrl
      }
    `)
      .then((data: any) => data.companies as Company[])
      .then((companies:Company[]) => {
        if (companies?.length) {
          setCompanies(companies.sort((company1:Company, company2:Company) => {
            if (company1.name > company2.name) return 1;
            if (company1.name < company2.name) return -1;
            return 0;
          }));
        } else {
          showToast('Failed to fetch Companies.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch Companies.', ToastType.ERROR);
      });
  }

  function fetchAccessLevels () {
    return gql(`
      accessLevels {
        id
        name
        type
        accounts
      }
    `)
      .then((data: any) => data.accessLevels as AccessLevel[])
      .then((accessLevels:AccessLevel[]) => {
        if (accessLevels && accessLevels.length) {
          setAccessLevels(accessLevels);
        } else {
          showToast('Failed to fetch Access Levels.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch Access Levels.', ToastType.ERROR);
      });
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onProgramSelect (selectedProgramId:number | null) {
    setSelectedProgramId(selectedProgramId);

    const selectedProgram = programs.find((program:Program) => program.id === selectedProgramId) || null;

    if (selectedProgram) {
      const selectedProgramCollege = selectedProgram.collegeId
        ? collegeTeams.find((team:Team) => team.id === selectedProgram.collegeId) || null
        : null;
      setSelectedCollegeTeam(selectedProgramCollege);

      const selectedProgramNFLTeam = selectedProgram.nflTeamId
        ? nflTeams.find((team:Team) => team.id === selectedProgram.nflTeamId) || null
        : null;
      setSelectedNFLTeam(selectedProgramNFLTeam);

      const selectedProgramCompany = selectedProgram.companyId
        ? (companies.find((company:Company) => company.id === selectedProgram.companyId) || {} as Team).id || null
        : null;
      setSelectedCompanyId(selectedProgramCompany);

      const selectedProgramAccessLevel = selectedProgram.accessLevelId
        ? accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === selectedProgram.accessLevelId) || null
        : null;
      setSelectedAccessLevelId((selectedProgramAccessLevel && selectedProgramAccessLevel.id)
        ? selectedProgramAccessLevel.id
        : null
      );

      if (selectedProgramAccessLevel && selectedProgramAccessLevel.type) {
        setProgramAccessLevelType(selectedProgramAccessLevel.type);
        updateAccessLevels(selectedProgramAccessLevel.type, selectedProgramAccessLevel.id);
      }
    }
  }

  function onAccessLevelSelect (selectedAccessLevel:number | null) {
    setSelectedAccessLevelId(selectedAccessLevel);

    const selectedProgramAccessLevel = accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === selectedAccessLevel);
    if (selectedProgramAccessLevel && selectedProgramAccessLevel.type) {
      setProgramAccessLevelType(selectedProgramAccessLevel.type);
      updateAccessLevels(selectedProgramAccessLevel.type, selectedAccessLevel);
    }
  }

  function updateAccessLevels (accessLevelType:AccessLevelType, selectedAccessLevel:number | null) {
    const allowedAccessLevels = (accessLevels || [])
      .filter((accessLevel:AccessLevel) => ACCESS_LEVELS_VISIBILITY[accessLevelType].includes(accessLevel.type))
      .sort((al1:AccessLevel, al2:AccessLevel) => {
        if (al1.name > al2.name) return 1;
        if (al1.name < al2.name) return -1;
        return 0;
      });

    setVisibleAccessLevels(allowedAccessLevels);

    const currentSelectAccessLevelIsAllowed = selectedAccessLevel
      ? !!allowedAccessLevels.find(accessLevel => accessLevel.id === selectedAccessLevel)
      : false;

    if (!currentSelectAccessLevelIsAllowed) {
      setSelectedAccessLevelId(allowedAccessLevels[0].id);
    }
  }

  function onSaveUser () {
    setLoading(true);

    const userPayload = {
      firstName,
      lastName,
      email,
      designation: jobTitle,
      programId: selectedProgramId,
      accessLevelId: selectedAccessLevelId,
      collegeId: selectedCollegeTeam ? selectedCollegeTeam.id : null,
      nflTeamId: selectedNFLTeam ? selectedNFLTeam.id : null,
      companyId: selectedCompanyId ? selectedCompanyId : null,
      startDate: subscriptionStartsWhenProgram ? null : subscriptionStartDate,
      expirationDate: expiresWhenProgram ? null : expirationDate,
      isDisabled: !userStatusEnabled,
      isMaster,
      isEmailDisabled,
      isSMSOptedOut: optedOut,
    } as any;

    if (isEditMode && user && user.id) {
      userPayload.id = user.id;
    }

    gql(`
      mutation {
        saveUser (
          user: ${serializeGQLObject(userPayload)}
        )
      }
    `)
      .then((data:any) => data.saveUser as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast(`${isEditMode ? 'Updated' : 'User added'}`, ToastType.SUCCESS);

          if (!isEditMode) {
            resetData();
          }
        }
        else {
          showToast(`Failed to ${isEditMode ? 'update' : 'add'} the user.`, ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast(`Failed to ${isEditMode ? 'update' : 'add'} the user.`, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function resetData () {
    setLoading(true);

    fetchPrograms()
      .finally(() => setLoading(false));

    setFirstName('');
    setLastName('');
    setEmail('');
    setJobTitle('');
    setSubscriptionStartDate(null);
    setSubscriptionStartsWhenProgram(true);
    setExpirationDate(null);
    setExpiresWhenProgram(true);
    setIsEmailDisabled(false);
    setSelectedAccessLevelId(null);
    setSelectedCompanyId(null);
    setSelectedProgramId(null);
    setSelectedCollegeTeam(null);
    setSelectedNFLTeam(null);
    setAllowedToExceedAccountsLimit(false);
  }


  function onSubscriptionStartsNowClick (subscriptionStartsNow:boolean) {
    if (subscriptionStartsNow) {
      setSubscriptionStartDate(null);
      setSubscriptionStartsWhenProgram(true);
    } else {
      if (user?.startDate) {
        setSubscriptionStartDate(new Date(user.startDate));
      } else if (selectedProgramId) {
        const selectedProgram = programs.find(program => program.id === selectedProgramId);

        if (selectedProgram && selectedProgram.startDate) {
          setSubscriptionStartDate(new Date(selectedProgram.startDate));
        }
      }

      setSubscriptionStartsWhenProgram(false);
    }
  }

  function onNoExpirationDateClick (noExpirationDate:boolean) {
    if (noExpirationDate) {
      setExpirationDate(null);
      setExpiresWhenProgram(true);
    } else {
      if (user && user.expirationDate) {
        setExpirationDate(new Date(user.expirationDate));
      } else if (selectedProgramId) {
        const selectedProgram = programs.find(program => program.id === selectedProgramId);

        if (selectedProgram && selectedProgram.expirationDate) {
          setExpirationDate(new Date(selectedProgram.expirationDate));
        }
      }

      setExpiresWhenProgram(false);
    }
  }

  function checkIsEmailUnique() {
    if (isEditMode && email === user?.email) return;

    gql(`
      isEmailUnique (email: "${email}")
    `)
      .then((data:any) => data.isEmailUnique as boolean)
      .then((success:boolean) => {
        if (success) {
          setEmailError('');
        } else {
          setEmailError('User with this email address already exists');
        }
      })
      .catch(() => console.error);
  }

  return (
    <div className={clsx(classes.addEditUserForm, className)}>
      <Input
        className={classes.input}
        label='First Name'
        value={firstName}
        onChange={(value:string) => setFirstName(value)}
      />
      <Input
        className={classes.input}
        label='Last Name'
        value={lastName}
        onChange={(value:string) => setLastName(value)}
      />
      <Input
        className={classes.input}
        label='User Email'
        value={email}
        error={emailError}
        onBlur={checkIsEmailUnique}
        onChange={(value:string) => setEmail(value)}
      />

      <div className={clsx(classes.dropdownWrapper, classes.programWrapper)}>
        <div className={classes.dropdownLabel}>Program Name:</div>

        <div className={classes.programRow}>
          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            emptyItem={{
              value: '',
              content: 'Select a Program'
            }}
            items={programs.map((program: Program) => ({
              content: program.name,
              value: program.id
            }))}
            error={(accountLimitExceeded && !allowedToExceedAccountsLimit) ? 'You\'ve reached the maximum number of accounts for this program' : undefined}
            value={selectedProgramId?.toString() || ''}
            onChange={(programId:number | string) => onProgramSelect(Number(programId) || null)}
          />

          {!!selectedProgramId && (
            <Tooltip title='Users / Limit'>
              <div
                className={clsx(
                  classes.programAccounts,
                  (accountLimitExceeded && !allowedToExceedAccountsLimit) && classes.programAccountsExceedsLimit
                )}
              >
                {selectedProgramUsersCount}&nbsp;/&nbsp;{Number.isFinite(programAccountsLimit) ? programAccountsLimit : 'Unlimited'}
              </div>
            </Tooltip>
          )}

          {accountLimitExceeded && (
            <div className={clsx(classes.programAccountsCheckboxWrapper, allowedToExceedAccountsLimit && classes.accountsLimitChecked)}>
              <Checkbox
                className={classes.checkbox}
                checked={allowedToExceedAccountsLimit}
                onChange={setAllowedToExceedAccountsLimit}
              />
              Check here to allow exceeding the limit
            </div>
          )}
        </div>
      </div>

      {!!selectedProgramId && (
        <div className={classes.dropdownWrapper}>
          <div className={classes.dropdownLabel}>Access Level:</div>

          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            items={visibleAccessLevels.map((accessLevel: AccessLevel) => ({
              content: accessLevel.name,
              value: accessLevel.id,
            }))}
            emptyItem={{
              value: '',
              content: 'Select Access Level'
            }}
            value={selectedAccessLevelId?.toString() || ''}
            onChange={(accessLevelId:number | string) => onAccessLevelSelect(Number(accessLevelId) || null)}
          />
        </div>
      )}

      {!!selectedProgramId && (
        <>
          <div className={classes.dropdownWrapper}>
            <div className={classes.dropdownLabel}>College Team:</div>
            <TeamAutoComplete
              className={classes.teamSelect}
              label='College Team'
              teams={collegeTeams}
              selectedTeam={selectedCollegeTeam}
              onSelect={(team:Team | null) => team && setSelectedCollegeTeam(team)}
            />
          </div>

          {[AccessLevelType.NFL, AccessLevelType.LIMITED, AccessLevelType.ADMIN].includes(programAccessLevelType as any) && (
            <div className={classes.dropdownWrapper}>
              <div className={classes.dropdownLabel}>NFL Team:</div>
              <TeamAutoComplete
                className={classes.teamSelect}
                label='NFL Team'
                teams={nflTeams}
                selectedTeam={selectedNFLTeam}
                onSelect={(team:Team | null) => team && setSelectedNFLTeam(team)}
              />
            </div>
          )}

          <div className={classes.dropdownWrapper}>
            <div className={classes.dropdownLabel}>Company:</div>

            <DropDown
              className={classes.dropDown}
              selectorClassName={classes.dropDownSelect}
              emptyItem={{
                value: '',
                content: 'Select Company'
              }}
              items={companies.map((company:Company) => ({
                content: (
                  <div className={classes.companyName}>
                    {company.name}
                    {company?.logoUrl && (
                      <img
                        className={classes.companyLogo}
                        src={company.logoUrl}
                        alt={`${company.name} logo`}
                      />
                    )}
                  </div>
                ),
                value: company.id,
              }))}
              value={selectedCompanyId?.toString() || ''}
              onChange={(companyId:number | string) => setSelectedCompanyId(Number(companyId) || null)}
            />
          </div>
        </>
      )}

      <Input
        className={classes.input}
        label='Job Title'
        value={jobTitle}
        onChange={(value:string) => setJobTitle(value)}
      />

      <div className={classes.dateWrapper}>
        <DatePicker
          className={clsx(classes.input, classes.date)}
          label='Subscription Start Date'
          value={subscriptionStartsWhenProgram
            ? (user?.program?.startDate || user?.program?.createdAt || null)
            : subscriptionStartDate}
          disabled={subscriptionStartsWhenProgram}
          onChange={(subscriptionStartDate:Date | null) => setSubscriptionStartDate(subscriptionStartDate)}
        />

        <div className={classes.dateCheckboxWrapper}>
          <Checkbox
            className={classes.checkbox}
            checked={subscriptionStartsWhenProgram}
            onChange={onSubscriptionStartsNowClick}
          />
          Same as program
        </div>
      </div>

      <div className={classes.dateWrapper}>
        <DatePicker
          className={clsx(classes.input, classes.date)}
          label='Expiration Date'
          value={expiresWhenProgram
            ? (user?.program?.expirationDate || null)
            : expirationDate}
          disabled={expiresWhenProgram}
          onChange={(expirationDate:Date | null) => setExpirationDate(expirationDate)}
        />

        <div className={classes.dateCheckboxWrapper}>
          <Checkbox
            className={classes.checkbox}
            checked={expiresWhenProgram}
            onChange={onNoExpirationDateClick}
          />
          Same as program {!user?.program?.expirationDate ? '(Unlimited)' : ''}
        </div>
      </div>

      <div className={classes.checkboxWrapper}>
        <Checkbox
          className={classes.checkbox}
          checked={isMaster}
          onChange={() => setIsMaster(!isMaster)}
        />
        Master
      </div>

      <div className={classes.checkboxWrapper}>
        <Checkbox
          className={classes.checkbox}
          checked={isEmailDisabled}
          onChange={() => setIsEmailDisabled(!isEmailDisabled)}
        />
        Unsubscribe from emails
      </div>

      <div className={classes.checkboxWrapper}>
        <Checkbox
          className={classes.checkbox}
          checked={optedOut}
          onChange={() => setOptedOut(!optedOut)}
        />
        Opt out
      </div>

      <div className={classes.actions}>
        <Button
          className={classes.action}
          primary
          disabled={
            loading
            || !allRequiredFieldsFilled
            || !!emailError.length
            || (accountLimitExceeded && !allowedToExceedAccountsLimit)
          }
          onClick={onSaveUser}
        >
          {!isEditMode && <PlusIcon className={classes.plusIcon} />}
          {isEditMode ? 'Update User' : 'Add New User'}
        </Button>

        <Button
          className={classes.action}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    nflTeams: state.teams.nflTeams,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditUserForm);
