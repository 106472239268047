import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '../icons/DashboardIcon';
import SearchPlayersIcon from '../icons/SearchPlayersIcon';
import ManageUsersIcon from '../icons/ManageUsersIcon';
import ComparePlayersIcon from '../icons/CompareIcon';
import TeamsIcon from '../icons/TeamsIcon';
import MessageIcon from '../icons/MessageIcon';
import ExitToAppIcon from '../icons/LogoutIcon';
import DepthChartIcon from '../icons/DepthChartIcon';
import WatchListIcon from '../icons/WatchListIcon';
import TransferPortalIcon from '../icons/TransferPortalIcon';
import Action from '../atoms/Action';
import Toggle from '../atoms/Toggle';
import MenuButton from '../atoms/MenuButton';
import TeamLogo from '../atoms/TeamLogo';
import CompanyLogo from '../atoms/CompanyLogo';
import UserId from '../molecules/UserId';
import { setSideDrawerOpenAction, setNFLAccessAction } from '../redux/actions/ui';
import { State } from '../redux/reducers';
import { isMobile, ScreenSize } from '../services/screen-size';
import { saveCurrentPath } from '../services/path';
import { resetReduxStoreAction } from '../redux/actions/root';
import gql from '../services/gql';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import {
  COLOR_ORANGE,
  COLOR_LIGHT_ORANGE,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_WHITE
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import User from '../types/User';
import { DraftProjectorType, TransferPortalType } from '../types/AccessLevel';

interface SideDrawerProps {
  open: boolean;
  screenSize: ScreenSize;
  user?: User;
  nflAccess?: boolean;
  adminAccess?: boolean;
  printed?: boolean;
  setSideDrawerOpenAction: (open:boolean) => void;
  setNFLAccessAction: (open:boolean) => void;
  resetReduxStoreAction: () => void;
}

export const SIDE_DRAWER_WIDTH = 330;
export const SIDE_DRAWER_WIDTH_SMALL = 240;
export const SIDE_DRAWER_CLOSED_WIDTH = 110;

const ADMIN_TOGGLE_VALUE = {
  COLLEGE: 'college',
  NFL: 'nfl',
};

enum MenuLinkType {
  DASHBOARD = 'dashboard',
  MY_ACCOUNT = 'myAccount',
  MANAGE_USERS = 'manageUsers',
  SEARCH_PLAYERS = 'searchPlayers',
  COMPARE_PLAYERS = 'comparePlayers',
  WATCH_LIST_PLAYERS = 'watchListPlayers',
  TEAMS = 'teams',
  DEPTH_CHARTS = 'depthCharts',
  TRANSFER_PORTAL = 'transferPortal',
  DRAFT_PROJECTOR = 'draftProjector',
}

const MENU_CONTENT:{[key: string]: {
  title: string,
  icon: any,
  href: string,
  notActiveWhenQueryParameters?: string[]
}} = {
  [MenuLinkType.DASHBOARD]: {
    title: 'Dashboard',
    icon: DashboardIcon,
    href: '/dashboard?',
  },
  [MenuLinkType.MANAGE_USERS]: {
    title: 'Manage',
    icon: ManageUsersIcon,
    href: '/manage/users?',
  },
  [MenuLinkType.MY_ACCOUNT]: {
    title: 'My Account',
    icon: ManageUsersIcon,
    href: '/my-account',
  },
  [MenuLinkType.SEARCH_PLAYERS]: {
    title: 'Search Players',
    icon: SearchPlayersIcon,
    href: '/search?reset-filters=true',
    notActiveWhenQueryParameters: ['watch-list'],
  },
  [MenuLinkType.COMPARE_PLAYERS]: {
    title: 'Compare Players',
    icon: ComparePlayersIcon,
    href: '/compare?',
  },
  [MenuLinkType.WATCH_LIST_PLAYERS]: {
    title: 'Watch List Players',
    icon: WatchListIcon,
    href: '/search?watch-list=true',
  },
  [MenuLinkType.TEAMS]: {
    title: 'Teams',
    icon: TeamsIcon,
    href: '/teams?',
  },
  [MenuLinkType.DEPTH_CHARTS]: {
    title: 'D-1 Depth Charts',
    icon: DepthChartIcon,
    href: '/depth-charts?',
  },
  [MenuLinkType.TRANSFER_PORTAL]: {
    title: 'Transfer Portal',
    icon: TransferPortalIcon,
    href: 'http://coachesbythenumbers.com/cbtn-membership-required/?wlfrom=%2Ftransfer-portal%2F',
  },
  [MenuLinkType.DRAFT_PROJECTOR]: {
    title: 'Draft Projector',
    icon: TransferPortalIcon,
    href: 'http://coachesbythenumbers.com/player-production-analytics',
  },
};

const useStyles = makeStyles((theme:Theme) => ({
  sideDrawer: {
    height: '100vh',
    position: 'fixed',
    zIndex: 1101,
    top: 0,
    left: 0,
    boxShadow: '0 0 40px rgb(0,0,0,.5)',

    '@media print': {
      display: 'none',
    }
  },
  collapsed: {
    boxShadow: 'none',

    '& $header': {
      padding: '10px 20px',
      justifyContent: 'center',
    },

    '& $userId': {
      marginTop: theme.spacing(4),
    },

    '& $toggleContainer': {
      display: 'none',
    },

    '& $companyLogo': {
      visibility: 'hidden',
    },
    '& $teamLogo': {
      visibility: 'hidden',
    },
    '& $textTeamLogo': {
      visibility: 'hidden',
    },

    '& $menuActionIcon': {
      margin: '0 auto',
    },
    '& $menuActionText': {
      display: 'none',
    },

    '& $logoutAction': {
      margin: '0 auto',
    },
    '& $logoutActionIcon': {
      margin: '0 auto',
    },
  },
  withTextTeamLogo: {
    '& $toggleContainer': {
      flexDirection: 'column',
    },
  },
  withAdminAccess: {
    '& $companyLogo': {
      marginRight: theme.spacing(3),
    },
    '& $teamLogo': {
      marginRight: theme.spacing(3),
    },
  },

  drawerPaper: {
    display: 'block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: SIDE_DRAWER_WIDTH,
    maxWidth: '100%',
    border: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 25px 0 25px',
    userSelect: 'none',
  },

  logo: {
    width: '90px',
  },

  headerButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
  },

  messagesContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  messagesBadge: {
    width: 22,
    height: 22,
    padding: '2px',
    top: '8px',
    right: '8px',
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1,
    background: COLOR_LIGHT_ORANGE,
    color: COLOR_WHITE,
    border: `4px solid ${COLOR_WHITE}`,
    borderRadius: '50%',
  },
  messagesIcon: {
    width: '32px',
    height: '32px',
  },

  userId: {
    marginTop: theme.spacing(3),
  },

  toggleContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggle: {
    alignSelf: 'center',
  },

  companyLogo : {
    height: '40px',
  },
  teamLogo: {
    height: '40px',
  },
  textTeamLogo: {
    padding: theme.spacing(0, 0, 3),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    lineHeight: 1,
    color: COLOR_TEXT,
  },

  menu: {
    width: '100%',
    padding: 0,
    margin: theme.spacing(4, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
  },
  menuAction: {
    whiteSpace: 'normal',
    padding: theme.spacing(2, 2.5),
    position: 'relative',
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_DARK_GRAY,

    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuActionText: {},
  menuActionSelected: {
    color: COLOR_TEXT,

    '&:before': {
      content: '""',
      width: '4px',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      background: COLOR_ORANGE,
    },

    '& $menuActionIcon': {
      color: COLOR_ORANGE,
    },
  },
  menuActionIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2.5),
    flexShrink: 0,
  },

  logout: {
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 2.5),
  },
  logoutAction: {
    padding: 0,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(16),

    '&:hover': {
      textDecoration: 'none',
    },
  },
  logoutActionIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(3),
    flexShrink: 0,
  },

  [MEDIA.DESKTOP_LARGE]: {
    sideDrawer: {
      width: SIDE_DRAWER_WIDTH_SMALL,
      boxShadow: 'none',
    },
    collapsed: {
      width: 'auto',
    },

    toggleContainer: {
      flexDirection: 'column',
    },
    toggle: {
      marginTop: theme.spacing(2),
    },
    textTeamLogo: {
      paddingBottom: theme.spacing(1),
    },

    companyLogo : {
      margin: '0 !important',
    },
    teamLogo: {
      margin: '0 !important',
    },

    menuAction: {
      fontSize: theme.typography.pxToRem(18),
    },
  },

  '@media (min-width:1801px)': {
    sideDrawer: {
      width: SIDE_DRAWER_WIDTH,
    },
    collapsed: {
      width: 'auto',
    },

    drawerPaper: {
      width: SIDE_DRAWER_WIDTH,
    },
    drawerPaperClose: {
      width: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
    },

    menuAction: {
      padding: theme.spacing(2, 5),
      fontSize: theme.typography.pxToRem(20),
    },

    logout: {
      padding: theme.spacing(2, 5),
    },
  },
}), { name: SideDrawer.name });

function SideDrawer (props:SideDrawerProps) {
  const {
    open = false,
    screenSize = ScreenSize.MOBILE,
    user,
    nflAccess,
    adminAccess,
    printed = false,
    setSideDrawerOpenAction = () => {},
    setNFLAccessAction = () => {},
    resetReduxStoreAction = () => {},
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [sideDrawerMenuLinks, setSideDrawerMenuLinks] = useState<MenuLinkType[]>([]);
  const [selectedToggleValue, setSelectedToggleValue] = useState<string>(
    clientStorage.get(StorageKey.VIEW_MODE)
    || (nflAccess ? ADMIN_TOGGLE_VALUE.NFL : ADMIN_TOGGLE_VALUE.COLLEGE)
  );
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>();

  useEffect(() => {
    if (!printed) fetchUnreadMessageCount();
  }, [printed]);

  useEffect(() => {
    const viewMode = clientStorage.get(StorageKey.VIEW_MODE);

    setSelectedToggleValue(!!viewMode ? viewMode : (nflAccess
        ? ADMIN_TOGGLE_VALUE.NFL
        : ADMIN_TOGGLE_VALUE.COLLEGE
      )
    );
  }, [nflAccess, adminAccess]);

  useEffect(() => {
    if (user && user.accessLevel) {
      const {
        canManageUsers,
        canComparePlayers,
        canAccessDashboard,
        accessCollegeTeamPage,
        accessNFLTeamPage,
        depthChartEnabled,
        transferPortal,
        draftProjector,
      } = user.accessLevel;

      const menuLinks = [];

      if (canAccessDashboard) {
        menuLinks.push(MenuLinkType.DASHBOARD);
      }

      if (!adminAccess) {
        menuLinks.push(MenuLinkType.MY_ACCOUNT);
      }

      if (canManageUsers) {
        menuLinks.push(MenuLinkType.MANAGE_USERS);
      }

      menuLinks.push(MenuLinkType.SEARCH_PLAYERS);

      if (canComparePlayers) {
        menuLinks.push(MenuLinkType.COMPARE_PLAYERS);
      }

      if (nflAccess) {
        menuLinks.push(MenuLinkType.WATCH_LIST_PLAYERS);
      }

      if (accessCollegeTeamPage || accessNFLTeamPage) {
        menuLinks.push(MenuLinkType.TEAMS);
      }

      if (depthChartEnabled) {
        menuLinks.push(MenuLinkType.DEPTH_CHARTS);
      }

      if (transferPortal === TransferPortalType.YES
        || (transferPortal === TransferPortalType.YES_IF_ACTIVATED && user.program.hasTransferPortal)
      ) {
        menuLinks.push(MenuLinkType.TRANSFER_PORTAL);
      }

      if (nflAccess && (
        draftProjector === DraftProjectorType.YES
        || (draftProjector === DraftProjectorType.YES_IF_ACTIVATED && user.program.hasDraftProjector)
      )) {
        menuLinks.push(MenuLinkType.DRAFT_PROJECTOR);
      }

      setSideDrawerMenuLinks(menuLinks);
    }
  }, [user, nflAccess, adminAccess]);

  if (printed) return null;

  function fetchUnreadMessageCount() {
    gql(`countUnreadMessages`)
      .then((data:any) => data.countUnreadMessages as number)
      .then((unreadMessageCount:number) => setUnreadMessageCount(unreadMessageCount))
      .catch(console.error);
  }

  function onLogOut () {
    saveCurrentPath();
    clientStorage.clearAll();
    resetReduxStoreAction();
    history.push('/login');
  }

  function checkIfLinkIsActive(match:any, location:any, menuLink: MenuLinkType) {
    const href = MENU_CONTENT[menuLink].href;
    const notActiveWhenQueryParameters = MENU_CONTENT[menuLink].notActiveWhenQueryParameters || [];
    return (
      match &&
      (href === `${location.pathname}${location.search || '?'}` ||
      !!notActiveWhenQueryParameters.filter(param => !location.search.includes(param)).length)
    );
  }

  const onOpen = () => setSideDrawerOpenAction(true);
  const onClose = () => setSideDrawerOpenAction(false);
  const onMenuButtonClick = open ? onClose : onOpen;

  const messagesButton = (
    <Link to='/messages'>
      <IconButton>
        <Badge
          classes={{ badge: classes.messagesBadge }}
          badgeContent={unreadMessageCount}
        >
          <MessageIcon className={classes.messagesIcon} />
        </Badge>
      </IconButton>
    </Link>
  );

  const drawerContent = (
    <>
      <div className={classes.header}>
        <div className={classes.headerButtonContainer}>
          <MenuButton onClick={onMenuButtonClick} />
        </div>

        {open && (
          <img
            className={classes.logo}
            src='/images/tracking-football-logo.svg'
            alt='Tracking Football'
          />
        )}

        {open && (
          <div className={classes.headerButtonContainer}>
            {messagesButton}
          </div>
        )}
      </div>

      {!open && (
        <div className={classes.messagesContainer}>
          {messagesButton}
        </div>
      )}

      <UserId
        className={classes.userId}
        collapsed={!open}
        nflAccess={nflAccess}
      />


      <div className={classes.toggleContainer}>
        {(user?.accessLevel?.dashboardTeamLogos && !user?.company?.logoUrl) && (
          <TeamLogo
            className={classes.teamLogo}
            team={nflAccess ? user.nflTeam : user.collegeTeam}
          />
        )}

        {(user?.accessLevel?.dashboardTeamLogos && user?.company?.logoUrl) && (
          <CompanyLogo
            className={classes.companyLogo}
            company={user.company}
          />
        )}

        {!user?.accessLevel?.dashboardTeamLogos && (
          <div className={classes.textTeamLogo}>
            {nflAccess ? user?.nflTeam?.name : user?.collegeTeam?.name}
          </div>
        )}

        {adminAccess && (
          <Toggle
            className={classes.toggle}
            items={[
              { value: ADMIN_TOGGLE_VALUE.COLLEGE, content: 'College'},
              { value: ADMIN_TOGGLE_VALUE.NFL, content: 'NFL'},
            ]}
            value={selectedToggleValue}
            onSelect={(value:string) => {
              const isNFL = value === ADMIN_TOGGLE_VALUE.NFL;
              setSelectedToggleValue(value);
              clientStorage.save(StorageKey.VIEW_MODE, value);
              setNFLAccessAction(isNFL);
            }}
          />
        )}
      </div>

      <ul className={classes.menu}>
        {sideDrawerMenuLinks.map((menuLink:MenuLinkType) => (
          <li key={menuLink}>
            <Action
              className={classes.menuAction}
              selectedClassName={classes.menuActionSelected}
              icon={MENU_CONTENT[menuLink].icon}
              iconClassName={classes.menuActionIcon}
              href={MENU_CONTENT[menuLink].href}
              isActive={(match:any, location:any) => checkIfLinkIsActive(match, location, menuLink)}
              target={(menuLink === MenuLinkType.TRANSFER_PORTAL || menuLink === MenuLinkType.DRAFT_PROJECTOR) ? '_blank' : undefined}
              externalLink={menuLink === MenuLinkType.TRANSFER_PORTAL || menuLink === MenuLinkType.DRAFT_PROJECTOR}
            >
              <span className={classes.menuActionText}>
                {MENU_CONTENT[menuLink].title}
              </span>
            </Action>
          </li>
        ))}
      </ul>

      <div className={classes.logout}>
        <Action
          className={classes.logoutAction}
          selectedClassName={classes.menuActionSelected}
          icon={ExitToAppIcon}
          iconClassName={classes.logoutActionIcon}
          onClick={onLogOut}
        >
          <span className={classes.menuActionText}>Logout</span>
        </Action>
      </div>
    </>
  );

  const drawerClassName = clsx(
    classes.sideDrawer,
    !open && classes.collapsed,
    !user?.accessLevel?.dashboardTeamLogos && classes.withTextTeamLogo,
    adminAccess && classes.withAdminAccess,
  );

  return isMobile(screenSize)
    ? (
      <SwipeableDrawer
        className={drawerClassName}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      >
        {drawerContent}
      </SwipeableDrawer>
    )
    : (
      <Drawer
        variant='permanent'
        className={drawerClassName}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        {drawerContent}
      </Drawer>
    );
}

const mapStateToProps = (state:State) => {
  return {
    open: state.ui.sideDrawerOpen,
    screenSize: state.ui.screenSize,
    nflAccess: state.ui.nflAccess,
    adminAccess: state.ui.adminAccess,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      setSideDrawerOpenAction,
      setNFLAccessAction,
      resetReduxStoreAction,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawer);
