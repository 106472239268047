import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_DARK_GRAY } from '../styles/colors';
import IconButton from '@material-ui/core/IconButton';

interface MenuButtonProps {
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuIcon: {
    width: '22px',
    height: '22px',
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
    position: 'relative',

    '&:before': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      top: '5px',
      left: 0,
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      bottom: '5px',
      left: 0,
    },
  },
}), { name: MenuButton.name });

export default function MenuButton (props: MenuButtonProps) {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <IconButton onClick={onClick}>
      <div className={classes.menuIcon} />
    </IconButton>
  );
}
