import React from 'react';
import Icon from '../atoms/Icon';

function NewMessageIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(2, 3)'
        >
          <polyline
            stroke='currentColor'
            strokeWidth='2'
            points='24 14 24 22 10 22 5 26 5 22 0 22 0 6 13 6'
          />
          <path
            d='M20,16 C15.6,16 12,12.4 12,8 C12,3.6 15.6,0 20,0 C24.4,0 28,3.6 28,8 C28,12.4 24.4,16 20,16 Z M20,2 C16.7,2 14,4.7 14,8 C14,11.3 16.7,14 20,14 C23.3,14 26,11.3 26,8 C26,4.7 23.3,2 20,2 Z M24,7 L21,7 L21,4 L19,4 L19,7 L16,7 L16,9 L19,9 L19,12 L21,12 L21,9 L24,9 L24,7 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(NewMessageIcon);
