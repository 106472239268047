import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TeamLogo from '../atoms/TeamLogo';
import Loader from '../atoms/Loader';
import Tooltip from '../atoms/Tooltip';
import { State } from '../redux/reducers';
import gql from '../services/gql';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Conference from '../types/Conference';
import PAIChartTeam from '../types/PAIChartTeam';

interface ConferencePAIRatingsChartProps {
  className?: string;
  sideDrawerOpen?: boolean;
  teamId: number;
  dashboardRolloverDay?: number;
  dashboardRolloverMonth?: number;
}

const CHART_INITIAL_WIDTH = 500;
const CHART_HEIGHT = 300;
const CHART_MARGIN_LEFT = 56;
const CHART_MARGIN_RIGHT = 48;
const CHART_HORIZONTAL_LINES_NUMBER = 6;
const CHART_VERTICAL_LINES_NUMBER = 6;
const CHART_VERTICAL_LINES_STEP = 20; // %
const TEAM_LOGO_SIZE = 56;

const useStyles = makeStyles((theme: Theme) => ({
  conferencePAIRatingsChart: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },

  conferenceLogo: {
    height: '24px',
  },

  chartWrap: {
    overflow: 'visible',
  },

  chart: {
    width: `calc(100% - ${CHART_MARGIN_LEFT}px - ${CHART_MARGIN_RIGHT}px)`,
    minWidth: '500px',
    height: `${CHART_HEIGHT}px`,
    margin: theme.spacing(2, 0, 9, 9),
    boxSizing: 'content-box',
    position: 'relative',
  },

  verticalCaption: {
    position: 'absolute',
    left: '-88px',
    top: 'calc(50% - 7px)',
    transform: 'translateY(-50%) rotate(-90deg)',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
  },
  horizontalCaption: {
    position: 'absolute',
    left: 'calc(50% - 8px)',
    bottom: '-52px',
    transform: 'translateX(-50%)',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
  },

  horizontalLines: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: COLOR_BORDER,

    '&:before': {
      content: 'attr(data-axis-name)',
      position: 'absolute',
      left: '-18px',
      top: '-7px',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1,
      color: COLOR_TEXT,
    },

    '&:nth-of-type(1)': {
      '&:before': {
        content: '"0"',
        top: '6px',
      },
    },
  },

  verticalLines: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  verticalLine: {
    width: '1px',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: COLOR_BORDER,

    '&:before': {
      content: 'attr(data-axis-name)',
      position: 'absolute',
      left: '0',
      bottom: '-22px',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1,
      color: COLOR_TEXT,
      transform: 'translateX(-50%)',
    },
  },

  team: {
    width: `${TEAM_LOGO_SIZE}px`,
    height: `${TEAM_LOGO_SIZE}px`,
    position: 'absolute',
    cursor: 'default',
    border: `1px solid rgba(15, 137, 230, 0)`,
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    transition: 'border 0.3s, background 0.3s',

    '&:hover': {
      borderColor: COLOR_BLUE,
      backgroundColor: COLOR_WHITE,
      zIndex: 1,

      '& $teamLogo': {
        opacity: '1',
      },
    },
  },
  teamSelected: {
    borderColor: COLOR_BLUE,
    backgroundColor: COLOR_WHITE,
    zIndex: 1,

    '& $teamLogo': {
      opacity: '1',
    },
  },
  teamLogo: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    opacity: '0.5',
    transition: 'opacity 0.3s',
  },

  [MEDIA.MOBILE]: {
    chartWrap: {
      overflow: 'auto',
    },

    chart: {
      minWidth: '500px',
    },
  },
}), { name: ConferencePAIRatingsChart.name });

function ConferencePAIRatingsChart (props: ConferencePAIRatingsChartProps) {
  const {
    className,
    teamId,
    sideDrawerOpen,
    dashboardRolloverDay,
    dashboardRolloverMonth,
  } = props;
  const classes = useStyles();

  const [conferenceLoading, setConferenceLoading] = useState<boolean>(true);
  const [conference, setConference] = useState<Conference>();

  const [chartTeamsLoading, setChartTeamsLoading] = useState<boolean>(true);
  const [chartTeams, setChartTeams] = useState<PAIChartTeam[]>([]);

  const [width, setWidth] = useState<number>(CHART_INITIAL_WIDTH);

  const chartEl = useRef(null);

  const throttledUpdateChartParameters = useCallback(throttle(updateChartParameters, 300), []);

  useEffect(() => {
    window.addEventListener('resize', throttledUpdateChartParameters);

    return () => onUnmount();
  }, []);

  useEffect(() => {
    fetchConference(teamId);
    fetchChartTeams(teamId);
  }, [teamId]);

  useEffect(() => {
    updateChartParameters();
  }, [chartTeams?.length]);

  useEffect(() => {
    window.setTimeout(updateChartParameters, 300);
  }, [sideDrawerOpen]);

  function fetchChartTeams (teamId:number) {
    if (!chartTeamsLoading) setChartTeamsLoading(true);

    gql(`
      chart(teamId: ${teamId}) {
        team {
          id
          name
          logo247
          logoESPN
          logoAlt
        }
        averagePAI
        percentWithPAI
        countWithPAI
      }
    `)
      .then((data:any) => data?.chart as PAIChartTeam[])
      .then((chartTeams:PAIChartTeam[]) => setChartTeams(chartTeams || []))
      .catch(console.error)
      .finally(() => setChartTeamsLoading(false));
  }

  function fetchConference (teamId:number) {
    if (!conferenceLoading) setConferenceLoading(true);

    gql(`
      team(id: ${teamId}) {
        conference {
          name
          abbr
          iconUrl
        }
      }
    `)
      .then((data:any) => data?.team?.conference as Conference)
      .then((conference:Conference) => conference && setConference(conference))
      .catch(console.error)
      .finally(() => setConferenceLoading(false));
  }

  function updateChartParameters () {
    const chartWidth = ((chartEl || {}).current || { offsetWidth: CHART_INITIAL_WIDTH }).offsetWidth;
    setWidth(chartWidth);
  }

  function onUnmount () {
    window.removeEventListener('resize', throttledUpdateChartParameters);
  }

  function renderHorizontalLine (index:number) {
    let bottomOffset = index * Math.round(CHART_HEIGHT / (CHART_HORIZONTAL_LINES_NUMBER - 1));
    if (index === CHART_HORIZONTAL_LINES_NUMBER - 1) {
      bottomOffset = CHART_HEIGHT;
    }

    return (
      <div
        key={index}
        className={classes.horizontalLine}
        style={{ bottom: `${bottomOffset}px` }}
        data-axis-name={index}
      />
    );
  }

  function renderVerticalLine (index:number) {
    let leftOffset = index * Math.round(width / (CHART_VERTICAL_LINES_NUMBER - 1));
    if (index === CHART_VERTICAL_LINES_NUMBER - 1) {
      leftOffset = width;
    }

    return (
      <div
        key={index}
        className={classes.verticalLine}
        style={{ left: `${leftOffset}px` }}
        data-axis-name={`${(index * CHART_VERTICAL_LINES_STEP) || ''}`}
      />
    );
  }

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const chartYear = dashboardRolloverMonth && dashboardRolloverDay
    && (currentMonth > dashboardRolloverMonth || (currentMonth === dashboardRolloverMonth && currentDay >= dashboardRolloverDay))
    ? currentYear + 1
    : currentYear;

  const loading = conferenceLoading || chartTeamsLoading;

  return (
    <Paper className={clsx(classes.conferencePAIRatingsChart, className)}>
      <div className={classes.header}>
        {conference?.iconUrl && (
          <img
            className={classes.conferenceLogo}
            src={conference.iconUrl}
            alt={`${conference.name} conference logo`}
          />
        )}

        <h2 className={classes.headerTitle}>
          Conference Recruiting PAI Ratings {chartYear}
        </h2>
      </div>

      <div className={classes.chartWrap}>
        <div
          className={classes.chart}
          ref={chartEl}
        >
          <Loader inProgress={loading} />

          <div className={classes.horizontalCaption}>Total HS Commit % with PAI</div>
          <div className={classes.horizontalLines}>
            {Array
              .from(new Array(CHART_HORIZONTAL_LINES_NUMBER))
              .map((_, index) => renderHorizontalLine(index))
            }
          </div>

          <div className={classes.verticalCaption}>PAI Score Avg</div>
          <div className={classes.verticalLines}>
            {Array
              .from(new Array(CHART_VERTICAL_LINES_NUMBER))
              .map((_, index) => renderVerticalLine(index))
            }
          </div>

          {chartTeams.map((chartTeam:PAIChartTeam) => (
            <Tooltip
              key={chartTeam.team.id}
              title={(
                <>
                  <b>{chartTeam.team.name}</b> <br />
                  PAI: {Number(chartTeam.averagePAI || 0).toFixed(2)} <br />
                  Total Players: {Number(chartTeam.countWithPAI || 0)} <br />
                  Total % with PAI: {Math.round(chartTeam.percentWithPAI || 0)}%
                </>
              )}
            >
              <div
                className={clsx(
                  classes.team,
                  chartTeam.team.id === teamId && classes.teamSelected,
                )}
                style={{
                  left: `${Math.round((chartTeam.percentWithPAI * width / 100) - TEAM_LOGO_SIZE / 2)}px`,
                  bottom: `${Math.round((chartTeam.averagePAI * CHART_HEIGHT / 5) - TEAM_LOGO_SIZE / 2)}px`,
                }}
              >
                <TeamLogo
                  className={classes.teamLogo}
                  team={chartTeam.team}
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </Paper>
  );
}

const mapStateToProps = (state:State) => {
  return {
    sideDrawerOpen: state.ui.sideDrawerOpen,
    dashboardRolloverDay: state.configurations.dashboardRolloverDay,
    dashboardRolloverMonth: state.configurations.dashboardRolloverMonth,
  };
};

export default connect(
  mapStateToProps,
  null
)(ConferencePAIRatingsChart);
