import { Dispatch } from 'redux';
import { CacheKey, CacheExpiration, cache, retrieve } from '../../services/cache';
import gql from '../../services/gql';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import { State } from '../reducers';
import {
  setFBSConferences,
  setFBSConferencesLoading,
  setFCSConferences,
  setFCSConferencesLoading,
  setDivIIConferences,
  setDivIIConferencesLoading,
  setDivIIIConferences,
  setDivIIIConferencesLoading,
} from '../actions/conferences';
import Conference from '../../types/Conference';

export const CONFERENCE_LEVEL = {
  FBS: 'Div I-A',
  FCS: 'Div I-AA',
  DIVII: 'Div II',
  DIVIII: 'Div III',
};

const CONFERENCE_BODY = `
  id
  name
  abbr
  iconUrl
  teams {
    id
    name
  }
`;

export function fetchFBSConferences () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { conferences: { fbsConferences, fbsConferencesLoading } } = getState();

    if (fbsConferencesLoading || fbsConferences.length > 0) return;

    const cachedFBSConferences = retrieve(CacheKey.CONFERENCES_FBS);
    const cacheIsValid = !!cachedFBSConferences?.length;

    if (cacheIsValid) {
      dispatch(setFBSConferences(cachedFBSConferences));
      return;
    }

    dispatch(setFBSConferencesLoading(true));

    return gql(`
      collegeConferences(levelName: "${CONFERENCE_LEVEL.FBS}") {
        ${CONFERENCE_BODY}
      }
    `)
      .then((data:any) => data && data.collegeConferences as Conference[])
      .then(sortConferences)
      .then((fbsConferences:Conference[]) => {
        clientStorage.save(StorageKey.CONFERENCES_FBS, fbsConferences);
        cache(CacheKey.CONFERENCES_FBS, fbsConferences, CacheExpiration.LONG);
        dispatch(setFBSConferences(fbsConferences));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setFBSConferencesLoading(false));
      });
  };
}

export function fetchFCSConferences () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { conferences: { fcsConferences, fcsConferencesLoading } } = getState();

    if (fcsConferencesLoading || fcsConferences.length > 0) return;

    const cachedFCSConferences = retrieve(CacheKey.CONFERENCES_FCS);
    const cacheIsValid = !!cachedFCSConferences?.length;

    if (cacheIsValid) {
      dispatch(setFCSConferences(cachedFCSConferences));
      return;
    }

    dispatch(setFCSConferencesLoading(true));

    return gql(`
      collegeConferences(levelName: "${CONFERENCE_LEVEL.FCS}") {
        ${CONFERENCE_BODY}
      }
    `)
      .then((data:any) => data && data.collegeConferences as Conference[])
      .then(sortConferences)
      .then((fcsConferences:Conference[]) => {
        clientStorage.save(StorageKey.CONFERENCES_FCS, fcsConferences);
        cache(CacheKey.CONFERENCES_FCS, fcsConferences, CacheExpiration.LONG);
        dispatch(setFCSConferences(fcsConferences));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setFCSConferencesLoading(false));
      });
  };
}

export function fetchDivIIConferences () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { conferences: { divIIConferences, divIIConferencesLoading } } = getState();

    if (divIIConferencesLoading || divIIConferences.length > 0) return;

    const cachedConferences = retrieve(CacheKey.CONFERENCES_DIV_II);
    const cacheIsValid = !!cachedConferences?.length;

    if (cacheIsValid) {
      dispatch(setDivIIConferences(cachedConferences));
      return;
    }

    dispatch(setDivIIConferencesLoading(true));

    return gql(`
      collegeConferences(levelName: "${CONFERENCE_LEVEL.DIVII}") {
        ${CONFERENCE_BODY}
      }
    `)
      .then((data:any) => data && data.collegeConferences as Conference[])
      .then(sortConferences)
      .then((fbsConferences:Conference[]) => {
        clientStorage.save(StorageKey.CONFERENCES_DIV_II, fbsConferences);
        cache(CacheKey.CONFERENCES_DIV_II, fbsConferences, CacheExpiration.LONG);
        dispatch(setDivIIConferences(fbsConferences));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDivIIConferencesLoading(false));
      });
  };
}


export function fetchDivIIIConferences () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { conferences: { divIIIConferences, divIIIConferencesLoading } } = getState();

    if (divIIIConferencesLoading || divIIIConferences.length > 0) return;

    const cachedConferences = retrieve(CacheKey.CONFERENCES_DIV_III);
    const cacheIsValid = !!cachedConferences?.length;

    if (cacheIsValid) {
      dispatch(setDivIIIConferences(cachedConferences));
      return;
    }

    dispatch(setDivIIIConferencesLoading(true));

    return gql(`
      collegeConferences(levelName: "${CONFERENCE_LEVEL.DIVIII}") {
        ${CONFERENCE_BODY}
      }
    `)
      .then((data:any) => data && data.collegeConferences as Conference[])
      .then(sortConferences)
      .then((fcsConferences:Conference[]) => {
        clientStorage.save(StorageKey.CONFERENCES_DIV_III, fcsConferences);
        cache(CacheKey.CONFERENCES_DIV_III, fcsConferences, CacheExpiration.LONG);
        dispatch(setDivIIIConferences(fcsConferences));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDivIIIConferencesLoading(false));
      });
  };
}

function sortConferences (conferences:Conference[]):Conference[] {
  return (conferences || []).sort((conference1:Conference, conference2:Conference) => {
    if (conference1.name < conference2.name) return -1;
    if (conference1.name > conference2.name) return 1;

    return 0;
  });
}
