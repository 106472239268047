import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { interpolateHexColors } from '../services/colors-interpolator';
import UserIcon from '../icons/UserIcon';
import TeamIcon from '../icons/TeamIcon';
import ChartMarker from '../atoms/ChartMarker';
import { getTeamLogo } from '../atoms/TeamLogo';
import ChartImageMarker from '../atoms/ChartImageMarker';
import { getScoreValue } from '../atoms/Score';
import Team from '../types/Team';
import Player from '../types/Player';
import Position from '../types/Position';
import ChartLabel from '../atoms/ChartLabel';

interface PPIChartProps {
  className?: string;
  team?: Team;
  player?: Player;
  position?: Position;
}

interface PAIChartLabel {
  range: string;
  name: any;
  value: number;
}

const START_COLOR = '#fdd344';
const MID_COLOR = '#69bd45';
const END_COLOR = '#395b5e';

const useStyles = makeStyles((theme: Theme) => ({
  ppiChart: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
  chart: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  axis: {
    width: '100%',
    height: '4px',
    background: `linear-gradient(to right, ${START_COLOR} 30%, ${MID_COLOR} 50%,${MID_COLOR} 70%, ${END_COLOR} 20%)`,
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
  },
}), { name: PPIChart.name });

export default function PPIChart (props:PPIChartProps) {
  const {
    className,
    team,
    player,
    position,
  } = props;
  const classes = useStyles();

  const ppiInformations: Array<PAIChartLabel> = [
    {
      range: '0.0 - 1.9',
      name: <b>Underperformers &&nbsp; Low Utiization</b>,
      value: 1.0,
    },
    {
      range: '2.0 - 3.5',
      name: <b>FCS&nbsp;&&nbsp;FBS&nbsp; Starters</b>,
      value: 3.0,
    },
    {
      range: '3.6 - 5.0',
      name: <b>All&nbsp;Conference&nbsp;/&nbsp; All-Americans</b>,
      value: 4.5,
    },
  ];

  let teamMarker = null;
  if (!!team?.ppi?.length) {
    const teamPPI = getScoreValue(team.ppi, { position }) || 0;
    const teamPosition = Math.round(teamPPI * 100 / 5);

    teamMarker = (
      <ChartImageMarker
        position={teamPosition}
        image={getTeamLogo(team)}
        iconFallback={TeamIcon}
        tooltipText={`Team Avg. PPI: ${teamPPI.toFixed(2)}`}
        color={interpolateHexColors(START_COLOR, END_COLOR, teamPosition)}
      />
    );
  }

  let playerMarker = null;
  if (!!player?.ppi) {
    const ppi = player.ppi.toFixed(2);
    const playerPosition = Math.round(parseFloat(ppi) * 100 / 5);

    playerMarker = (
      <ChartImageMarker
        position={playerPosition}
        image={player.photoUrl}
        iconFallback={UserIcon}
        tooltipText={`${player.firstName} ${player.lastName} PPI: ${ppi}`}
        color={interpolateHexColors(START_COLOR, END_COLOR, playerPosition)}
        avatar
      />
    );
  }

  return (
    <div className={clsx(classes.ppiChart, className)}>
      <div className={classes.chart}>
        <div className={classes.axis} />

        <ChartMarker
          position={0}
          label='0'
          color={START_COLOR}
        />
        <ChartMarker
          position={20}
          label='1.0'
          color={interpolateHexColors(START_COLOR, END_COLOR, 0)}
        />
        <ChartMarker
          position={40}
          label='2.0'
          color={interpolateHexColors(MID_COLOR, END_COLOR, 0)}
        />
        <ChartMarker
          position={50}
          label='2.5'
          color={interpolateHexColors(MID_COLOR, END_COLOR, 0)}
        />
        <ChartMarker
          position={60}
          label='3.0'
          color={interpolateHexColors(MID_COLOR, END_COLOR, 0)}
        />
        {/* <ChartMarker
          position={70}
          label='3.5'
          color={interpolateHexColors(MID_COLOR, END_COLOR, 0)}
        /> */}
        <ChartMarker
          position={80}
          label='4.0'
          color={interpolateHexColors(MID_COLOR, END_COLOR, 100)}
        />
        <ChartMarker
          position={100}
          label='5.0'
          color={END_COLOR}
        />

        {ppiInformations.map((info: PAIChartLabel, index) => {
          const position = Math.round(info.value * 100 / 5);

          return (
            <ChartLabel
              key={index}
              position={position}
              label={(<>{info.range}<br />{info.name}<br /></>)}
              color={interpolateHexColors(START_COLOR, END_COLOR, position)}
            />
          );
        })}
        {teamMarker}
        {playerMarker}
      </div>
    </div>
  );
}
