import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { COLOR_BACKGROUND_LIGHT, COLOR_BLUE, COLOR_LIGHT_GRAY, COLOR_TEXT } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface AccordionProps {
  className?: string;
  summaryClassName?: string;
  summaryContentClassName?: string;
  expandButtonClassName?: string;
  summary: any;
  children: any;
  expanded?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: '0 !important',

    '& $summary': {
      minHeight: 'auto',
    },
    '& $summaryContent': {
      minHeight: 'auto',
    },
  },
  summary: {
    minHeight: 'auto !important',
    padding: '6px 16px',
    background: COLOR_BACKGROUND_LIGHT,
    borderTop: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
  },
  summaryContent: {
    margin: '0  !important',
    paddingRight: theme.spacing(5),
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },

  expandButton: {
    appearance: 'none',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '50%',
    right: '4px',
    padding: 0,
    border: 0,
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(-50%)',
    color: COLOR_TEXT,
    outline: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_BLUE,
    },
  },
  expandIcon: {
    width: '16px',
    height: '16px',
    color: 'inherit',
  },

  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}), { name: Accordion.name });

export default function Accordion (props: AccordionProps) {
  const {
    className,
    summaryClassName,
    summaryContentClassName,
    expandButtonClassName,
    summary,
    children,
    expanded = false,
  } = props;
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState<boolean>(expanded);

  const ExpandIcon = isExpanded ? RemoveIcon : AddIcon;

  return (
    <MaterialAccordion
      className={clsx(
        classes.accordion,
        isExpanded && classes.expanded,
        className,
      )}
      expanded={isExpanded}
      onChange={(event:any, isExpanded) => setExpanded(isExpanded)}
      square
    >
      <MaterialAccordionSummary
        className={clsx(classes.summary, summaryClassName)}
        classes={{ content: clsx(classes.summaryContent, summaryContentClassName) }}
      >
        {summary}

        <button className={clsx(classes.expandButton, expandButtonClassName)}>
          <ExpandIcon className={classes.expandIcon} />
        </button>
      </MaterialAccordionSummary>

      <MaterialAccordionDetails className={classes.content}>
        {children}
      </MaterialAccordionDetails>
    </MaterialAccordion>
  );
}
