import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHeadCell from '../atoms/TableHeadCell';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import Toast, { ToastType } from '../atoms/Toast';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_BLUE,
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import Company from '../types/Company';
import { Order } from '../types/Order';
import HsCombineType from '../types/HsCombineType';
import { TEAM_CAMP_STRING } from '../types/ValidationRule';

interface HsCombineTypeTableProps {
  className?: string;
  hsCombineType: HsCombineType[];
  setHsCombineType: (hsCombineType:HsCombineType[]) => void;
  currentPage?: number;
  limit?: number;
  selectedItems: HsCombineType[];
  selectedColumns: string[];
  setCurrentPage: (page: number) => void;
  setSelectedItems: (items: any) => void;
}

export enum ManagedHsCombineTypeColumn {
  TYPE = 'type',
  TEAM_CAMP = 'teamCamp',
  IS_ACCESS_CONTROLLED = 'isAccessControlled',
}

export const HS_COMBINE_TYPE_ALL_COLUMNS = [
  ManagedHsCombineTypeColumn.TYPE,
  ManagedHsCombineTypeColumn.TEAM_CAMP,
  ManagedHsCombineTypeColumn.IS_ACCESS_CONTROLLED,
];

export const HS_COMBINE_TYPE_COLUMN_TITLE = {
  [ManagedHsCombineTypeColumn.TYPE]: 'Type',
  [ManagedHsCombineTypeColumn.TEAM_CAMP]: <>Team&nbsp;Camp</>,
  [ManagedHsCombineTypeColumn.IS_ACCESS_CONTROLLED]: <>Access&nbsp;Controlled</>,
};

const useStyles = makeStyles(theme => ({
  header: {
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    position: 'relative',
    margin: 0,
    padding: theme.spacing(3, 2),
    lineHeight: 1,
    ...theme.typography.h2,
  },

  programsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },
  tableHeadCell: {
    borderLeft: 'none',
    color: COLOR_DARK_GRAY,
    padding: theme.spacing(1.25),

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  tableRow: {
    height: '50px',
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.25),
    whiteSpace: 'nowrap',
  },
  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },
  nameCell: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  rankCell: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },

  paginationWrapper: {
    minHeight: '24px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 0),
    position: 'relative',
    boxSizing: 'content-box',
  },
  paginationText: {
    position: 'absolute',
    left: theme.spacing(0),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },
}), { name: HsCombineTypeTable.name });

function HsCombineTypeTable(props: HsCombineTypeTableProps) {
  const {
    className,
    hsCombineType,
    setHsCombineType,
    currentPage = 1,
    limit = 10,
    selectedItems,
    selectedColumns,
    setSelectedItems,
    setCurrentPage,
  } = props;

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order | undefined>();
  const [companies, setCompanies] = useState<Company[]>([]);

  const [sortedByColumn, setSortedByColumn] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const classes = useStyles();

  useEffect(() => {
    // fetchCollegeTeams();
    // fetchNFLTeams();
    // fetchCompanies();
  }, []);

  function fetchCompanies () {
    setPageLoading(true);

    gql(`
      companies {
        id
        name
      }
    `)
      .then((data: any) => data.companies as Company[])
      .then((companies: Company[]) => {
        if (companies && companies.length) {
          setCompanies(companies);
        } else {
          showToast('Failed to fetch Companies.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Failed to fetch Companies.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function onSortByColumn (sort:(hsCombineType: HsCombineType[], order:Order) => HsCombineType[]) {
    return (columnName: string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function onSort (columnName:string, newOrder:Order, sort:(hsCombineType: HsCombineType[], order: Order) => HsCombineType[]) {
    setSortedByColumn(columnName);
    setHsCombineType(sort(hsCombineType, newOrder));
  }

  function onCheckBoxChange (checkedItem: HsCombineType) {
    return (value: boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }
        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function renderRow (item: HsCombineType) {
    const checked = (!!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id) && item?.type !== TEAM_CAMP_STRING);

    return (
      <TableRow
        key={item.id}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={checked}
            disabled={item?.type === TEAM_CAMP_STRING}
            onChange={onCheckBoxChange(item)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          (tableColumn && <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.className,
              tableColumn.value === ManagedHsCombineTypeColumn.TYPE && classes.nameCell,
            )}
          >
            {tableColumn.renderContent(item)}
          </TableCell>)
        ))}
      </TableRow>
    );
  }

  function sortPrograms (
    hsCombineType: HsCombineType[],
    order: Order,
    getValue: (hsCombineType:HsCombineType) => string | number,
  ) {
    return hsCombineType.sort((first:HsCombineType, second:HsCombineType) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const tableColumns = selectedColumns.map((column: string) => {
    switch (column) {
      case ManagedHsCombineTypeColumn.TYPE:
        return {
          value: ManagedHsCombineTypeColumn.TYPE,
          title: HS_COMBINE_TYPE_COLUMN_TITLE[ManagedHsCombineTypeColumn.TYPE],
          sortable: true,
          renderContent: (hsCombineType: HsCombineType) => (
            <>
              {
              hsCombineType.type !== TEAM_CAMP_STRING ?
              <Link
                className={classes.link}
                to={`/manage/combines/edit/${hsCombineType.id}`}
              >
                {`${hsCombineType.type}`}
              </Link>
            : hsCombineType.type
              }
            </>
          ),
          sort: (hsCombineType: HsCombineType[], order:Order) => {
            return sortPrograms(
              hsCombineType,
              order,
              (hsCombineType: HsCombineType) => `${hsCombineType.type}`
            );
          },
        };
      case ManagedHsCombineTypeColumn.TEAM_CAMP:
        return {
          value: ManagedHsCombineTypeColumn.TEAM_CAMP,
          title: HS_COMBINE_TYPE_COLUMN_TITLE[ManagedHsCombineTypeColumn.TEAM_CAMP],
          sortable: true,
          renderContent: (hsCombineType:HsCombineType) => hsCombineType?.teamCamp,
          sort: (hsCombineType: HsCombineType[], order:Order) => {
            return sortPrograms(
              hsCombineType,
              order,
              (hsCombineType: HsCombineType) => `${hsCombineType?.teamCamp}`
            );
          },
        };
      case ManagedHsCombineTypeColumn.IS_ACCESS_CONTROLLED:
        return {
          value: ManagedHsCombineTypeColumn.IS_ACCESS_CONTROLLED,
          title: HS_COMBINE_TYPE_COLUMN_TITLE[ManagedHsCombineTypeColumn.IS_ACCESS_CONTROLLED],
          sortable: true,
          renderContent: (hsCombineType:HsCombineType) => hsCombineType?.isAccessControlled ? 'Enabled' : 'Disabled',
          sort: (hsCombineType:HsCombineType[], order:Order) => {
            return sortPrograms(
              hsCombineType,
              order,
              (hsCombineType:HsCombineType) => hsCombineType?.isAccessControlled ? 'Disabled' : 'Enabled',
            );
          },
        };
    }
  });
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = hsCombineType?.length < limit ? hsCombineType.length : (limit * currentPage);
  const totalPages = Math.ceil(hsCombineType?.length / limit);

  const loading = pageLoading;
  return (
    <div className={className}>

      {/* <Loader inProgress={loading} /> */}
      <TableWithDoubleScrollBars tableClassName={classes.programsTable}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableHeadCell
              key='check-box-cell'
              className={classes.checkBoxCell}
            >
              <Checkbox
                checked={selectedItems?.length === hsCombineType?.length}
                onChange={() => selectedItems?.length === hsCombineType?.length
                  ? setSelectedItems([])
                  : setSelectedItems([...hsCombineType])
                }
              />
            </TableHeadCell>

            {tableColumns.map((tableColumn: any) => (
              (tableColumn && (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable ? onSortByColumn(tableColumn.sort || ((hsCombineType: HsCombineType[]) => hsCombineType)) : undefined}
                  className={clsx(tableColumn.className, classes.tableHeadCell)}
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {!hsCombineType?.length && (
          <TableRow
            key='empty'
            className={classes.tableRow}
          >
            <TableCell
              className={clsx(classes.tableCell, classes.emptyCell)}
              colSpan={tableColumns.length + 1}
            >
              <div className={classes.empty}>
                No Hs Combine Type
              </div>
            </TableCell>
          </TableRow>
        )}
          {hsCombineType?.slice(itemsFrom, itemsTo)
            .map(renderRow)
          }
        </TableBody>
      </TableWithDoubleScrollBars>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {hsCombineType?.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HsCombineTypeTable);
