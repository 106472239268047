import React from 'react';
import Page from './Page';
import TeamsPageTemplate from '../templates/TeamsPageTemplate';

export default function TeamsPage () {
  return (
    <Page title='Teams'>
      <TeamsPageTemplate />
    </Page>
  );
}
