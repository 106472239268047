import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Input from '../atoms/Input';
import Loader from '../atoms/Loader';
import { ToastType } from '../atoms/Toast';
import DropDown from '../atoms/DropDown';
import Dialog from './Dialog';
import gql, { serializeGQLObject } from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT } from '../styles/colors';
import StagedPlayer, { StagedPlayerStatus } from '../types/StagedPlayer';
import StateType from '../types/State';

interface CreateStagedPlayerModalProps {
  open: boolean;
  states: StateType[];
  onClose: () => void;
  onCreate: () => void;
  showToast: (message:any, toastType:ToastType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  createStagedPlayerModal: {},

  text: {
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLOR_TEXT,
  },

  input: {
    marginTop: theme.spacing(3),
  },
  inputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  dropdown: {
    marginTop: theme.spacing(3),
  },
  dropdownSelect: {
    height: '40px',
  },
}), { name: CreateStagedPlayerModal.name });

export default function CreateStagedPlayerModal (props:CreateStagedPlayerModalProps) {
  const {
    open = false,
    states = [],
    onCreate = () => {},
    onClose = () => {},
    showToast,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [hsGraduationYear, setHsGraduationYear] = useState<string>('');
  const [hsState, setHsState] = useState<StateType>();

  function createPlayer () {
    setLoading(true);

    const newStagedPlayer:StagedPlayer = {
      id: Math.round(Math.random() * 1000000000), // generate temporary id
      slug: null,
      status: StagedPlayerStatus.new,
      data: {
        slug: null,
        key247: null,
        uniqueId: null,
        pffId: null,
        ppiDate: '',
        ssaId: null,
        ryzerId: null,
        pai: null,
        combine: null,
        ppi: null,
        hsClass: Number(hsGraduationYear) || null,
        lastName: lastName || null,
        firstName: firstName || null,
        hsState: hsState || null,
        hsName: null,
        hsCity: null,
        hsCounty: null,
        hsZipcode: null,
        hsPrimaryPosition: null,
        hsPositions: null,
        hsHeight: null,
        hsWeight: null,
        dob: null,
        gpa: null,
        sat: null,
        act: null,
        comp247: null,
        compStar: null,
        star247: null,
        offersCount: null,
        offers: null,
        committedTo: null,
        linkTrackingFootball: null,
        photoUrl: null,
        link247: null,
        linkRivals: null,
        linkOn3: null,
        linkHudl: null,
        linkMaxpreps: null,
        linkTwitter: null,
        linkInstagram: null,
        linkAthleticNet: null,
        linkMilesplit: null,
        linkDirectAthletics: null,
        hsSportBaseball: null,
        hsSportBasketball: null,
        hsSportGolf: null,
        hsSportHockey: null,
        hsSportLacrosse: null,
        hsSportPowerLifting: null,
        hsSportRugby: null,
        hsSportSoccer: null,
        hsSportSwimming: null,
        hsSportTennis: null,
        hsSportTrack: null,
        hsSportVolleyball: null,
        hsSportWrestling: null,
        track55m: null,
        track60m: null,
        track55hh: null,
        track60hh: null,
        track100m: null,
        track110hh: null,
        track200m: null,
        track300m: null,
        track300ih: null,
        track400m: null,
        track400h: null,
        track800m: null,
        track400r: null,
        track800r: null,
        track1600r: null,
        track3200r: null,
        trackShotput: null,
        trackDiscus: null,
        trackJavelin: null,
        trackHighJump: null,
        trackLongJump: null,
        trackTripleJump: null,
        trackPoleVault: null,
        aTier: null,
        bTier: null,
        cTier: null,
        hsCombineType: null,
        hsCombineCity: null,
        hsCombineDate: null,
        hsCombineHeight: null,
        hsCombineWeight: null,
        hsCombineWingspan: null,
        hsCombineHand: null,
        hsCombineArm: null,
        hsCombineMPH: null,
        hsCombine10Split: null,
        hsCombine40mDash: null,
        hsCombineShuttle: null,
        hsCombine3Cone: null,
        hsCombineVerticalJump: null,
        hsCombineBroadJump: null,
        hsCombinePowerToss: null,
        hsCombineRating: null,
        about: null,
        transferStatus: null,
        transferYear: null,
        ncaaId: null,
        collegeScholarship: null,
        collegeActive: null,
        collegeUrl: null,
        linkPff: null,
        linkSsa: null,
        collegeName: null,
        collegeRecruitingClass: null,
        collegePrimaryPosition: null,
        collegePositions: null,
        collegeHeight: null,
        collegeWeight: null,
        collegeJerseyNumber: null,
        collegeAllConference: null,
        collegeAllAmerican: null,
        watchlistEvent: null,
        watchlistYear: null,
        nflCombineType: null,
        nflCombineDate: null,
        nflCombineScore: null,
        nflPrimaryPosition: null,
        nflPositions: null,
        nflCombineHeight: null,
        nflCombineWeight: null,
        nflCombineHand: null,
        nflCombineWingspan: null,
        nflCombineArm: null,
        nflCombine40mDash: null,
        nflCombine10Split: null,
        nflCombine20Split: null,
        nflCombineMPH: null,
        nflCombineShuttle: null,
        nflCombine3Cone: null,
        nflCombineVerticalJump: null,
        nflCombineBroadJump: null,
        nflCombineBench: null,
        nflTeamName: null,
        nflDraftYear: null,
        nflDraftRound: null,
        nflOverallPick: null,
        nflProBowl: null,
        nflAllPro: null,
      },
    } as StagedPlayer;

    gql(`
      mutation {
        createStagedPlayer(
          stagedPlayer: ${serializeGQLObject(newStagedPlayer)}
        )
      }
    `)
      .then((data:any) => data.createStagedPlayer as boolean)
      .then((success:boolean) => {
        if (success) {
          onCreate();
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  const someFieldsAreInvalid = !firstName || !lastName || !hsGraduationYear || !hsState;

  return (
    <Dialog
      open={open}
      title='Add New Player'
      actions={[
        {
          name: 'Cancel',
          onClick: onClose,
        },
        {
          name: 'Create',
          primary: true,
          disabled: someFieldsAreInvalid,
          onClick: createPlayer,
        },
      ]}
      onClose={onClose}
    >
      <Loader inProgress={loading} />

      <p className={classes.text}>
        Specify the required player's details:
      </p>

      <Input
        className={classes.input}
        inputFieldClassName={classes.inputField}
        label='First Name'
        value={firstName}
        fontSize={14}
        onChange={setFirstName}
      />

      <Input
        className={classes.input}
        inputFieldClassName={classes.inputField}
        label='Last Name'
        value={lastName}
        fontSize={14}
        onChange={setLastName}
      />

      <Input
        className={classes.input}
        inputFieldClassName={classes.inputField}
        label='HS Graduation Year'
        type='number'
        value={hsGraduationYear}
        fontSize={14}
        onChange={setHsGraduationYear}
      />

      <DropDown
        className={classes.dropdown}
        selectorClassName={classes.dropdownSelect}
        emptyItem={{
          value: '',
          content: 'HS State'
        }}
        items={states
          .map((state:StateType) => ({
            content: `${state.code} - ${state.name}`,
            value: state.code,
          }))
          .concat([{
            content: 'International',
            value: 'International',
          }])}
        value={hsState || ''}
        onChange={setHsState}
      />
    </Dialog>
  );
}
