import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FreshmanIcon from '../icons/FreshmanIcon';
import Tooltip from '../atoms/Tooltip';
import Loader from '../atoms/Loader';
import Score, { SCORE_TYPE, getScoreValue } from '../atoms/Score';
import Avatar from '../atoms/Avatar';
import CFCLogo from '../atoms/CFCLogo';
import MultiSportList from '../molecules/MultiSportList';
import LastUpdated from '../molecules/LastUpdated';
import PlayerProfileHighSchoolOverview from '../organisms/PlayerProfileHighSchoolOverview';
import PlayerProfileCollegeOverview from '../organisms/PlayerProfileCollegeOverview';
import PlayerProfileNFLOverview from '../organisms/PlayerProfileNFLOverview';
import PlayerTrackAndFieldRating from '../organisms/PlayerTrackAndFieldRating';
import PlayerCombineRating from '../organisms/PlayerCombineRating';
import PlayerProfileAbout from '../organisms/PlayerProfileAbout';
import PlayerProfileNotes from '../organisms/PlayerProfileNotes';
import PlayerProfileTopPlayers from '../organisms/PlayerProfileTopPlayers';
import PlayerTrackAndFieldData from '../organisms/PlayerTrackAndFieldData';
import PlayerCombineData from '../organisms/PlayerCombineData';
import PlayerMultiSportData from '../organisms/PlayerMultiSportData';
import PlayerCharts from '../organisms/PlayerCharts';
import { TEAM_TYPE } from '../templates/PlayerPageTemplate';
import { TAB } from './PlayerProfileStats';
import gql from '../services/gql';
import { getPrimaryHighSchool } from '../services/player';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_WHITE,
  COLOR_ORANGE,
  COLOR_GREEN,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import Team from '../types/Team';
import Position from '../types/Position';
import User from '../types/User';
import PlayerPAIStats from '../types/PlayerPAIStats';
import PlayerNFLPAIStats from '../types/PlayerNFLPAIStats';
import PlayerCombineStats from '../types/PlayerCombineStats';
import PlayerCombineEvent from '../types/PlayerCombineEvent';
import PlayerProCombineData from './PlayerProCombineData';
import PlayerProCombineRating from './PlayerProCombineRating';
import PlayerNFLCombineEvent from '../types/PlayerNFLCombineEvent';
import CombineRating from '../types/CombineRating';
import ProCombineRating from '../types/ProCombineRating';
import PlayerPPIStats from '../types/PlayerPPIStats';
import PlayerNFLPPIStats from '../types/PlayerNFLPPIStats';
import PlayerProCombineStats from '../types/PlayerProCombineStats';
import PlayerInGameRating from './PlayerInGameRating';

interface PlayerProfilePrintableProps {
  player?: Player;
  selectedCollegeTeam?: Team;
  selectedNFLTeam?: Team;
  selectedPosition?: Position;
  selectedTeamType?: string;
  user?: User;
  trackFieldRating: any;
  combineRatings?: CombineRating[];
  proCombineRatings?: ProCombineRating[];
  isNFL: boolean;
  hsInGameRating?:any;
  inGameRating?:any;
}

const COLLEGE_PAI_STATS_QUERY = `
  performance
  event
  meet
  year
  date
  hsClassStats {
    percentile
    playerRank
    playerCount
  }
  div1Stats {
    percentile
    playerRank
    playerCount
  }
  teamStats {
    percentile
    playerRank
    playerCount
  }
  conferenceStats {
    percentile
    playerRank
    playerCount
  }
  nflDraftStats{
    percentile
    playerRank
    playerCount
  }
`;
const NFL_PAI_STATS_QUERY = `
  performance
  event
  meet
  year
  date
  collegeTeamStats {
    percentile
    playerRank
    playerCount
  }
  collegeConferenceStats {
    percentile
    playerRank
    playerCount
  }
  nflTeamStats {
    percentile
    playerRank
    playerCount
  }
  nflUDFAStats {
    percentile
    playerRank
    playerCount
  }
  nflDraftStats {
    percentile
    playerRank
    playerCount
  }
`;

const useStyles = makeStyles(theme => ({
  playerProfilePrintable: {
    minHeight: '100vh',
    position: 'relative',
    fontFamily: FONT_PROXIMA_NOVA,
    backgroundColor: COLOR_WHITE,
  },
  page: {
    padding: theme.spacing(5, 0),

    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  paper: {
    width: '100%',
  },

  content: {
    position: 'relative',
  },

  playerProfileHeader: {
    height: '200px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    display: 'flex',
    padding: theme.spacing(4, 5, 4, 8),
  },

  gridContainer: {
    paddingRight: theme.spacing(5),
  },
  gridContainerColumn: {
    paddingLeft: theme.spacing(5),
  },
  pageBreak: {
    pageBreakAfter: 'always'
  },

  panel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },

  avatar: {
    width: '128px',
    height: '128px',
    marginRight: theme.spacing(5),
  },

  playerIntro: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  playerName: {
    margin: theme.spacing(0, 2, 1, 0),
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(30),
    color: COLOR_TEXT,
  },

  logoAndLastUpdated: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logo: {
    width: '125px',
    marginBottom: theme.spacing(1.5),
  },
  lastUpdatedText: {
    color: '#424242',
  },

  cfcLogo: {
    width: '30px',
    marginLedt: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },

  playerStats: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  playerScoreWrapper: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '150px',
    marginLeft: theme.spacing(8),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  playerScoreLabel: {
    color: COLOR_TEXT,
  },
  playerScoreRow: {
    display: 'flex',
    alignItems: 'center',
  },
  playerScore: {
    width: 70,
    height: 70,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  checkIconClass: {
    width: 24,
    height: 24,
  },

  freshmanIcon: {
    width: 48,
    height: 48,
    cursor: 'help',
  },
  paiFreshmanIcon: {
    color: COLOR_ORANGE,
  },
  combineFreshmanIcon: {
    color: COLOR_GREEN,
  },

  multiSport: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
    marginTop: theme.spacing(1),
  },
  multiSportIcon: {
    width: '32px',
    height: '32px',
    margin: theme.spacing(.5,1),

    '&:first-of-type': {
      marginLeft: theme.spacing(1),
    },
  },

  box: {
    marginTop: theme.spacing(4),
  },

  topPlayers: {
    margin: theme.spacing(4, 0, 0, 5),
  },

  [MEDIA.PRINT]: {
    page: {
      pageBreakAfter: 'always',
      pageBreakInside: 'avoid',
      size: 'letter',
      margin: 0,
      zoom: '50%',
      printColorAdjust: 'exact',
      filter: 'opacity(1)',

      '&:last-of-type': {
        pageBreakAfter: 'avoid',
      },
    },
  },
  noScoreText: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
  },
  leftAlign: {
    alignItem: 'baseline',
    display: 'flex',
    alignItems: 'flex-start'
  },
}), { name: PlayerProfilePrintable.name });

export default function PlayerProfilePrintable (props:PlayerProfilePrintableProps) {
  const {
    player,
    user,
    selectedPosition,
    selectedTeamType,
    selectedCollegeTeam,
    selectedNFLTeam,
    trackFieldRating,
    combineRatings,
    proCombineRatings,
    isNFL,
    hsInGameRating,
    inGameRating,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);

  const [paiStats, setPAIStats] = useState<PlayerPAIStats | PlayerNFLPAIStats>();
  const [sprints, setSprints] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [relays, setRelays] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [hurdles, setHurdles] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [jumps, setJumps] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [throws, setThrows] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);

  const [combineStats, setCombineStats] = useState<PlayerCombineStats>();
  const [proCombineStats, setProCombineStats] = useState<PlayerProCombineStats>();
  const [combineEvents, setCombineEvents] = useState<Array<PlayerCombineEvent | PlayerNFLCombineEvent>>([]);
  const [nflCombineEvents, setNFLCombineEvents] = useState<Array<PlayerCombineEvent | PlayerNFLCombineEvent>>([]);

  const [ppiStats, setPPIStats] = useState<PlayerPPIStats | PlayerNFLPPIStats>();

  const hasPAIScore = !!player?.pai && typeof getScoreValue(player.pai, { position: selectedPosition }) === 'number';
  const hasCombineScore = !!player?.combine && typeof getScoreValue(player.combine, { position: selectedPosition }) === 'number';
  const hasHSCombineData = !!player?.hsCombines?.length;
  const hasProCombineData = !!player?.nflCombines?.length;
  const hasPPIScore = !!player?.ppi;

  const primaryHighSchool = getPrimaryHighSchool(player);

  const playerHasColleges = player && player.playerColleges && player.playerColleges.length > 0;
  const playerHasNFLTeams = player && player.playerNFLTeams && player.playerNFLTeams.length > 0;
  const playerHasHighSchools = player && player.highSchools && player.highSchools.length > 0;
  const playerIsHS = playerHasHighSchools && !playerHasColleges && !playerHasNFLTeams;

  useEffect(() => {
    if (player?.id && selectedPosition) {
      if (isNFL) {
        if (hasPAIScore) {
          fetchNFLPAIStats();
        }

        if (hasPPIScore) {
          fetchNFLPPIStats();
        }

        if (hasCombineScore || hasHSCombineData || hasProCombineData) {
          fetchNFLCombineStats();
        }
      } else if (!isNFL) {
        if (hasPAIScore) {
          fetchPAIStats();
        }

        if (hasPPIScore) {
          fetchPPIStats();
        }

        if (hasCombineScore || hasHSCombineData || hasProCombineData) {
          fetchCombineStats();
        }
      }
    }
  }, [player?.id, selectedCollegeTeam?.id, selectedNFLTeam?.id, selectedPosition, isNFL]);

  function fetchPAIStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerPAIStats (
        id: ${player.id},
        compareToTeamId: ${selectedCollegeTeam.id},
        positionCode: "${selectedPosition}"
      ) {
        paiStats {
          performance
          event
          meet
          year
          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }
        sprints {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        relays {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        hurdles {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        jumps {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        throws {
          ${COLLEGE_PAI_STATS_QUERY}
        }
      }
    `)
      .then((data:any) => ({
        paiStats: get(data, 'playerPAIStats.paiStats') as PlayerPAIStats,
        sprints: get(data, 'playerPAIStats.sprints', []) as PlayerPAIStats[],
        relays: get(data, 'playerPAIStats.relays', []) as PlayerPAIStats[],
        hurdles: get(data, 'playerPAIStats.hurdles', []) as PlayerPAIStats[],
        jumps: get(data, 'playerPAIStats.jumps', []) as PlayerPAIStats[],
        throws: get(data, 'playerPAIStats.throws', []) as PlayerPAIStats[],
      }))
      .then(({ paiStats, sprints, relays, hurdles, jumps, throws }:{ paiStats:PlayerPAIStats, sprints:PlayerPAIStats[], relays:PlayerPAIStats[], hurdles:PlayerPAIStats[], jumps:PlayerPAIStats[], throws:PlayerPAIStats[] }) => {
        setPAIStats(paiStats);
        setSprints(sprints.filter(sprint => !!sprint.performance));
        setRelays(relays.filter(relay => !!relay.performance));
        setHurdles(hurdles.filter(hurdle => !!hurdle.performance));
        setJumps(jumps.filter(jump => !!jump.performance));
        setThrows(throws.filter(throwEvent => !!throwEvent.performance));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLPAIStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedNFLTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerPAIStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${selectedCollegeTeam.id},
        compareToNFLTeamId: ${selectedNFLTeam.id},
        positionCode: "${selectedPosition}"
      ) {
        paiStats {
          performance
          event
          meet
          year
          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }
        sprints {
          ${NFL_PAI_STATS_QUERY}
        }
        relays {
          ${NFL_PAI_STATS_QUERY}
        }
        hurdles {
          ${NFL_PAI_STATS_QUERY}
        }
        jumps {
          ${NFL_PAI_STATS_QUERY}
        }
        throws {
          ${NFL_PAI_STATS_QUERY}
        }
      }
    `)
      .then((data:any) => ({
        paiStats: get(data, 'playerPAIStatsNFL.paiStats') as PlayerNFLPAIStats,
        sprints: get(data, 'playerPAIStatsNFL.sprints', []) as PlayerNFLPAIStats[],
        relays: get(data, 'playerPAIStatsNFL.relays', []) as PlayerNFLPAIStats[],
        hurdles: get(data, 'playerPAIStatsNFL.hurdles', []) as PlayerNFLPAIStats[],
        jumps: get(data, 'playerPAIStatsNFL.jumps', []) as PlayerNFLPAIStats[],
        throws: get(data, 'playerPAIStatsNFL.throws', []) as PlayerNFLPAIStats[],
      }))
      .then(({ paiStats, sprints, relays, hurdles, jumps, throws }:{ paiStats:PlayerNFLPAIStats, sprints:PlayerNFLPAIStats[], relays:PlayerNFLPAIStats[], hurdles:PlayerNFLPAIStats[], jumps:PlayerNFLPAIStats[], throws:PlayerNFLPAIStats[] }) => {
        setPAIStats(paiStats);
        setSprints(sprints.filter(sprint => !!sprint.performance));
        setRelays(relays.filter(relay => !!relay.performance));
        setHurdles(hurdles.filter(hurdle => !!hurdle.performance));
        setJumps(jumps.filter(jump => !!jump.performance));
        setThrows(throws.filter(throwEvent => !!throwEvent.performance));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchCombineStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerCombineStats (
        id: ${player.id},
        compareToTeamId: ${selectedCollegeTeam?.id},
        positionCode: "${selectedPosition}"
      ) {
        combineStats {
          performance
          event
          meet
          year

          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }

        proCombineStats {
          performance
          event
          meet
          year

          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }

        combines {
          id
          type
          city
          combineDate
          height
          weight
          category
          combineHasFreshmanData
          combine

          eventStats {
            performance
            event
            meet
            year
            hsClassStats {
              percentile
              playerRank
              playerCount
            }
            div1Stats {
              percentile
              playerRank
              playerCount
            }
            teamStats {
              percentile
              playerRank
              playerCount
            }
            conferenceStats {
              percentile
              playerRank
              playerCount
            }
            nflDraftStats {
              percentile
              playerRank
              playerCount
            }
          }
        }
      }
    `)
      .then((data:any) => ({
        combineStats: get(data, 'playerCombineStats.combineStats') as PlayerCombineStats,
        combineEvents: get(data, 'playerCombineStats.combines') as PlayerCombineEvent[],
        proCombineStats: get(data, 'playerCombineStats.proCombineStats') as PlayerCombineStats,
      }))
      .then(({ combineStats, proCombineStats, combineEvents }:{ combineStats:PlayerCombineStats, proCombineStats:PlayerCombineStats, combineEvents:PlayerCombineEvent[] }) => {
        setCombineStats(combineStats)
        setProCombineStats(proCombineStats)
        setCombineEvents(combineEvents.filter(event => event.category !== 'nfl'));
        setNFLCombineEvents(combineEvents.filter(event => event.category === 'nfl'));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLCombineStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedNFLTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerCombineStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${selectedCollegeTeam.id},
        compareToNFLTeamId: ${selectedNFLTeam.id},
        positionCode: "${selectedPosition}"
      ) {
        combineStats {
          performance
          event
          meet
          year

          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }

        combines {
          id
          type
          city
          combineDate
          height
          weight
          category

          eventStats {
            performance
            event
            meet
            year
            collegeTeamStats {
              percentile
              playerRank
              playerCount
            }
            collegeConferenceStats {
              percentile
              playerRank
              playerCount
            }
            nflTeamStats {
              percentile
              playerRank
              playerCount
            }
            nflUDFAStats {
              percentile
              playerRank
              playerCount
            }
            nflDraftStats {
              percentile
              playerRank
              playerCount
            }
          }
        }
      }
    `)
      .then((data:any) => ({
        combineStats: get(data, 'playerCombineStatsNFL.combineStats') as PlayerCombineStats,
        combineEvents: get(data, 'playerCombineStatsNFL.combines') as PlayerNFLCombineEvent[],
      }))
      .then(({ combineStats, combineEvents }:{ combineStats:PlayerCombineStats, combineEvents:PlayerNFLCombineEvent[] }) => {
        setCombineStats(combineStats);
        setCombineEvents(combineEvents.filter(event => event.category !== 'nfl'));
        setNFLCombineEvents(combineEvents.filter(event => event.category === 'nfl'));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchPPIStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerPPIStats (
        id: ${player.id},
        compareToTeamId: ${selectedCollegeTeam.id},
        positionCode: "${selectedPosition}"
      ) {
        hsClassStats {
          percentile
          playerRank
          playerCount
        }
        div1Stats {
          percentile
          playerRank
          playerCount
        }
        teamStats {
          percentile
          playerRank
          playerCount
        }
        conferenceStats {
          percentile
          playerRank
          playerCount
        }
        nflDraftStats{
          percentile
          playerRank
          playerCount
        }
      }
    `)
      .then((data:any) => data?.playerPPIStats as PlayerPPIStats)
      .then((ppiStats:PlayerPPIStats) => {
        setPPIStats(ppiStats);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLPPIStats () {
    if (!player?.id || !selectedCollegeTeam?.id || !selectedNFLTeam?.id || !selectedPosition) return;
    if (!loading) setLoading(true);

    gql(`
      playerPPIStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${selectedCollegeTeam.id},
        compareToNFLTeamId: ${selectedNFLTeam.id},
        positionCode: "${selectedPosition}"
      ) {
        collegeTeamStats {
          percentile
          playerRank
          playerCount
        }
        collegeConferenceStats {
          percentile
          playerRank
          playerCount
        }
        nflTeamStats {
          percentile
          playerRank
          playerCount
        }
        nflUDFAStats {
          percentile
          playerRank
          playerCount
        }
        nflDraftStats {
          percentile
          playerRank
          playerCount
        }
      }
    `)
      .then((data:any) => data?.playerPPIStatsNFL as PlayerNFLPPIStats)
      .then((ppiStats:PlayerNFLPPIStats) => {
        setPPIStats(ppiStats);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  const canRender = player && selectedPosition && selectedTeamType;

  return (
    <div className={classes.playerProfilePrintable}>
      <Loader inProgress={loading} />

      {canRender && (
        <div className={classes.page}>
          <div className={classes.content}>
            <div className={classes.playerProfileHeader}>
              <div className={classes.playerIntro}>
                <Avatar
                  className={classes.avatar}
                  src={player?.photoUrl || null}
                  initials={`${(player?.firstName || ' ')[0]}${(player?.lastName || ' ')[0]}`.trim()}
                  alt='Profile picture'
                />

                <h1 className={classes.playerName}>
                  {player?.firstName} {player?.lastName}
                </h1>

                {primaryHighSchool?.state && (
                  <CFCLogo
                    className={classes.cfcLogo}
                    state={primaryHighSchool.state}
                  />
                )}
              </div>

              <div className={classes.playerStats}>
                <div className={classes.playerScoreWrapper}>
                  <span className={classes.playerScoreLabel}>PAI</span>

                  <div className={classes.playerScoreRow}>
                    <Score
                      className={classes.playerScore}
                      noScoreTextClass={classes.noScoreText}
                      type={SCORE_TYPE.PAI}
                      scoreList={player?.pai}
                      position={selectedPosition}
                    />

                    {!!player?.paiHasFreshmanData && (
                      <Tooltip title='Freshman Data'>
                        <FreshmanIcon className={clsx(classes.freshmanIcon, classes.paiFreshmanIcon)} />
                      </Tooltip>
                    )}
                  </div>
                </div>

                <div className={clsx(classes.playerScoreWrapper, !!player?.combineHasFreshmanData ? classes.leftAlign : '')}>
                  <span className={classes.playerScoreLabel}>HS Com</span>

                  <div className={classes.playerScoreRow}>
                    <Score
                      className={classes.playerScore}
                      noScoreTextClass={classes.noScoreText}
                      checkIconClass={classes.checkIconClass}
                      type={SCORE_TYPE.COMBINE}
                      scoreList={player?.combine}
                      position={selectedPosition}
                      combines={player?.hsCombines}
                    />

                    {!!player?.combineHasFreshmanData && (
                      <Tooltip title='Freshman Data'>
                        <FreshmanIcon className={clsx(classes.freshmanIcon, classes.combineFreshmanIcon)} />
                      </Tooltip>
                    )}
                  </div>
                </div>

                {player?.bestHighSchoolMPH?.mph && (user?.accessLevel?.hasCollegeAccess || user?.accessLevel?.hasNFLAccess) && (
                  <div className={clsx(classes.playerScoreWrapper)}>
                    <span className={classes.playerScoreLabel}>{selectedTeamType !== TEAM_TYPE.HIGH_SCHOOL ? 'MPH' : 'HS MPH'}</span>

                    <div className={classes.playerScoreRow}>
                      <Score
                        className={classes.playerScore}
                        noScoreTextClass={classes.noScoreText}
                        checkIconClass={classes.checkIconClass}
                        type={SCORE_TYPE.MPH}
                        value={(selectedTeamType === TEAM_TYPE.HIGH_SCHOOL ? player?.bestHighSchoolMPH?.mph : player?.bestCollegeMPH?.mph) || undefined}
                        totalValue={(selectedTeamType === TEAM_TYPE.HIGH_SCHOOL ? player?.bestHighSchoolMPH?.mph : player?.bestCollegeMPH?.mph) || undefined}
                      />
                    </div>
                  </div>
                )}

                {player?.ppi && (user?.accessLevel?.hasCollegeAccess || user?.accessLevel?.hasNFLAccess) && (
                  <div className={clsx(classes.playerScoreWrapper, classes.proCombineWrapper)}>
                    <span className={classes.playerScoreLabel}>PPI</span>

                    <div className={classes.playerScoreRow}>
                      <Score
                        className={classes.playerScore}
                        noScoreTextClass={classes.noScoreText}
                        checkIconClass={classes.checkIconClass}
                        type={SCORE_TYPE.PPI}
                        value={player.ppi || undefined}
                        position={selectedPosition}
                      />
                    </div>
                  </div>
                )}

                {(player?.nflCombineScore || !!player?.nflCombines?.length) && (
                  <div className={clsx(classes.playerScoreWrapper, classes.proCombineWrapper)}>
                    <span className={classes.playerScoreLabel}>Pro Com</span>

                    <div className={classes.playerScoreRow}>
                      <Score
                        className={classes.playerScore}
                        noScoreTextClass={classes.noScoreText}
                        checkIconClass={classes.checkIconClass}
                        type={SCORE_TYPE.PRO_COMBINE}
                        value={player.nflCombineScore || undefined}
                        position={selectedPosition}
                        combines={player.nflCombines}
                      />
                    </div>
                  </div>
                )}

                {!!player?.multiSport?.length && (
                  <div className={classes.playerScoreWrapper}>
                    <span className={classes.playerScoreLabel}>Multi-sport</span>

                    <MultiSportList
                      className={classes.multiSport}
                      iconClassName={classes.multiSportIcon}
                      list={player.multiSport}
                    />
                  </div>
                )}
              </div>

              <div className={classes.logoAndLastUpdated}>
                <img
                  className={classes.logo}
                  src='/images/tracking-football-logo.svg'
                />

                {(player?.id && player?.lastPublishedDate) && (
                  <LastUpdated
                    className={classes.lastUpdatedText}
                    playerId={player.id}
                    datetime={player.lastPublishedDate}
                    hideTooltip
                  />
                )}
              </div>
            </div>

            <Grid
              className={classes.gridContainer}
              container
            >
              <Grid
                className={classes.gridContainerColumn}
                item
                xs={6}
              >
                {selectedTeamType === TEAM_TYPE.HIGH_SCHOOL && (
                  <PlayerProfileHighSchoolOverview
                    className={classes.box}
                    player={player}
                    selectedPosition={selectedPosition}
                  />
                )}

                {selectedTeamType === TEAM_TYPE.COLLEGE && (
                  <PlayerProfileCollegeOverview
                    className={classes.box}
                    player={player}
                    selectedPosition={selectedPosition}
                    user={user}
                  />
                )}

                {selectedTeamType === TEAM_TYPE.NFL && (
                  <PlayerProfileNFLOverview
                    className={classes.box}
                    player={player}
                    selectedPosition={selectedPosition}
                    user={user}
                  />
                )}

                {player?.about && (
                  <PlayerProfileAbout
                    className={classes.box}
                    about={player.about}
                  />
                )}

                {!!player?.notes?.length && (
                  <PlayerProfileNotes
                    className={classes.box}
                    playerId={player?.id}
                    notes={player.notes}
                    canEdit={false}
                  />
                )}
              </Grid>

              <Grid
                className={classes.gridContainerColumn}
                item
                xs={6}
              >
                <PlayerTrackAndFieldRating
                  className={classes.box}
                  trackFieldRating={trackFieldRating}
                  isNFL={isNFL}
                />

                <PlayerCombineRating
                  className={classes.box}
                  combineRatings={combineRatings}
                  isNFL={isNFL}
                />

                <PlayerProCombineRating
                  className={classes.box}
                  proCombineRatings={proCombineRatings}
                />
                {playerIsHS && (
                  <PlayerInGameRating
                    className={classes.box}
                    inGameRating={hsInGameRating}
                    label={"HS In-Game Rating"}
                  />
                )}
                {
                  !playerIsHS && (
                    <PlayerInGameRating
                      className={classes.box}
                      inGameRating={inGameRating}
                      label={"College In-Game Rating"}
                    />
                  )
                }
              </Grid>

              {player && (
                <PlayerProfileTopPlayers
                  className={clsx(classes.topPlayers, classes.box)}
                  user={user}
                  currentPlayer={player}
                  hideCombinePlayersLink
                  printed
                />
              )}
            </Grid>
          </div>
        </div>
      )}

      <div className={classes.page}>
        <div className={classes.content}>
          <Grid
            className={classes.gridContainerColumn}
            item
            xs={6}
          >
            <PlayerCharts
              className={classes.box}
              loading={loading}
              selectedTab={TAB.MULTISPORT}
              player={player}
              collegeTeam={selectedCollegeTeam}
              nflTeam={selectedNFLTeam}
              position={selectedPosition}
              paiStats={paiStats}
              combineStats={combineStats}
              combineEvents={combineEvents}
              isNFL={isNFL}
              hideTabs
              onTabChange={() => {}}
              printed
            />

            <PlayerMultiSportData
              player={player}
              collegeTeam={selectedCollegeTeam}
              nflTeam={selectedNFLTeam}
              position={selectedPosition}
              isNFL={isNFL}
            />
          </Grid>
        </div>
      </div>

      {canRender && (
        <div className={classes.page}>
          <div className={classes.content}>
            <Grid
              className={classes.gridContainer}
              container
            >
              {hasPAIScore && (
                <Grid
                  className={classes.gridContainerColumn}
                  item
                  xs={6}
                >
                  <PlayerCharts
                    className={classes.box}
                    loading={loading}
                    selectedTab={TAB.PAI}
                    player={player}
                    collegeTeam={selectedCollegeTeam}
                    nflTeam={selectedNFLTeam}
                    position={selectedPosition}
                    paiStats={paiStats}
                    combineStats={combineStats}
                    combineEvents={combineEvents}
                    isNFL={isNFL}
                    hasPaiData={hasPAIScore}
                    hideTabs
                    onTabChange={() => {}}
                    printed
                  />

                  <div className={classes.box}>
                    <PlayerTrackAndFieldData
                      loading={loading}
                      player={player}
                      collegeTeam={selectedCollegeTeam}
                      nflTeam={selectedNFLTeam}
                      position={selectedPosition}
                      sprints={sprints}
                      relays={relays}
                      hurdles={hurdles}
                      jumps={jumps}
                      throws={throws}
                      isNFL={isNFL}
                    />
                  </div>
                </Grid>
              )}

              {(hasCombineScore || hasHSCombineData) && (
                <Grid
                  className={classes.gridContainerColumn}
                  item
                  xs={6}
                >
                  {
                    hasCombineScore && (
                      <PlayerCharts
                        className={classes.box}
                        loading={loading}
                        selectedTab={TAB.COMBINE}
                        player={player}
                        collegeTeam={selectedCollegeTeam}
                        nflTeam={selectedNFLTeam}
                        position={selectedPosition}
                        paiStats={paiStats}
                        combineStats={combineStats}
                        combineEvents={combineEvents}
                        isNFL={isNFL}
                        hideTabs
                        onTabChange={() => {}}
                        hasCombineScore={hasCombineScore}
                        hasHSCombineData={hasHSCombineData}
                        printed
                      />
                    )
                  }

                  {
                    hasHSCombineData && (
                      <div className={classes.box}>
                        <PlayerCombineData
                          loading={loading}
                          player={player}
                          collegeTeam={selectedCollegeTeam}
                          nflTeam={selectedNFLTeam}
                          position={selectedPosition}
                          combineEvents={combineEvents}
                          isNFL={isNFL}
                        />
                      </div>
                    )
                  }
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      )}

      {hasPPIScore && (
        <div className={classes.page}>
          <div className={classes.content}>
            <Grid
              className={classes.gridContainerColumn}
              item
              xs={6}
            >
              <PlayerCharts
                className={classes.box}
                loading={loading}
                selectedTab={TAB.PPI}
                player={player}
                collegeTeam={selectedCollegeTeam}
                nflTeam={selectedNFLTeam}
                position={selectedPosition}
                paiStats={paiStats}
                combineStats={combineStats}
                combineEvents={combineEvents}
                ppiStats={ppiStats}
                hasPPIScore
                isNFL={isNFL}
                hideTabs
                onTabChange={() => {}}
                printed
              />
            </Grid>
            </div>
        </div>
      )}

      {(canRender && hasProCombineData) && (
        <div className={classes.page}>
          <div className={classes.content}>
            <Grid
              className={classes.gridContainer}
              container
            >
              {(hasProCombineData) && (
                <Grid
                  className={classes.gridContainerColumn}
                  item
                  xs={6}
                >
                   {
                    proCombineStats && proCombineStats.div1Stats && (
                      <PlayerCharts
                        className={classes.box}
                        loading={loading}
                        selectedTab={TAB.PRO_COMBINE}
                        player={player}
                        collegeTeam={selectedCollegeTeam}
                        nflTeam={selectedNFLTeam}
                        position={selectedPosition}
                        paiStats={paiStats}
                        combineStats={proCombineStats}
                        hasProCombineData={hasProCombineData}
                        combineEvents={combineEvents}
                        isNFL={isNFL}
                        hasPaiData={hasPAIScore}
                        hideTabs
                        onTabChange={() => {}}
                        printed
                      />
                    )
                   }
                  <div className={classes.box}>
                    <PlayerProCombineData
                      loading={loading}
                      player={player}
                      collegeTeam={selectedCollegeTeam}
                      nflTeam={selectedNFLTeam}
                      position={selectedPosition}
                      nflCombineEvents={nflCombineEvents}
                      isNFL={isNFL}
                    />
                  </div>
                </Grid>
              )}

            </Grid>
          </div>
        </div>
      )}
    </div>
  );
}
