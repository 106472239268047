import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_GREEN, COLOR_MIDDLE_GRAY, COLOR_WHITE } from '../styles/colors';

interface HaloProps {
  className?: string,
  highlighted: number,
  children: React.ReactNode,
}

const GAPS_PER_180_DEG = 6;
const SECTIONS_TOTAL = 8;

const useStyles = makeStyles((theme: Theme) => ({
  halo: {
    display: 'flex',
    alignSelf: 'flex-start',
    margin: '0 auto',
    position: 'relative',
  },

  haloElements: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
    borderRadius: '50%',

    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      background: COLOR_GREEN,
      borderRadius: '50%',
    },
  },

  gap: {
    width: '4px',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: COLOR_WHITE,
  },

  circleCover: {
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    position: 'absolute',
    top: '4px',
    left: '4px',
    background: COLOR_WHITE,
    borderRadius: '50%',
  },

  bottomCover: {
    width: '100%',
    height: 'calc(25% + 2px)',
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: COLOR_WHITE,
  },

  inactiveSection: {
    width: 'calc(50% + 1px)',
    height: 'calc(25% + 2px)',
    position: 'absolute',
    left: '50%',
    bottom: '50%',
    background: COLOR_MIDDLE_GRAY,
    transformOrigin: 'left center',
  },

  children: {
    padding: theme.spacing(2),
  },
}), { name: Halo.name });

export default function Halo (props: HaloProps) {
  const {
    className = '',
    highlighted = SECTIONS_TOTAL,
    children,
  } = props;
  const classes = useStyles();

  const numberToArray = (number:number) => Array.from(new Array(number));
  const gaps = numberToArray(GAPS_PER_180_DEG);
  const inactiveSections = numberToArray(SECTIONS_TOTAL - Math.min(highlighted, SECTIONS_TOTAL));

  return (
    <div className={clsx(classes.halo, className)}>
      <div className={classes.haloElements}>
        {inactiveSections.map((section, index) => (
          <div
            key={index}
            className={classes.inactiveSection}
            style={{
              transform: `translate(0, 50%) rotate(${15 - (index * 30)}deg)`
            }}
          />
        ))}

        {gaps.map((gap, index) => (
          <div
            key={index}
            className={classes.gap}
            style={{
              transform: `translateX(-50%) rotate(${-90 + (index * 30)}deg)`
            }}
          />
        ))}

        <div className={classes.circleCover} />
        <div className={classes.bottomCover} />
      </div>

      <div className={classes.children}>
        {children}
      </div>
    </div>
  );
}
