import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Toast, { ToastType } from '../atoms/Toast';
import MouseIcon from '../icons/MouseIcon';
import AddUserIcon from '../icons/AddUserIcon';
import ManageUsersIcon from '../icons/ManageUsersIcon';
import UserCircleIcon from '../icons/UserCircleIcon';
import TrainingIcon from '../icons/TrainingIcon';
import HelpIcon from '../icons/HelpIcon';
import OtherIcon from '../icons/OtherIcon';
import TechHelpModal from '../molecules/TechHelpModal';
import LoginSubscriptionModal from '../molecules/LoginSubscriptionModal';
import PlayerAdditionChangeModal from '../molecules/PlayerAdditionChangeModal';
import AddRemoveAccountModal from '../molecules/AddRemoveAccountModal';
import ScheduleTrainingModal from '../molecules/ScheduleTrainingModal';
import OtherHelpModal from '../molecules/OtherHelpModal';
import ScoreDescriptionsModal from '../molecules/ScoreDescriptionsModal';
import ApiDocumentationModal from '../molecules/ApiDocumentationModal';
import { State } from '../redux/reducers';
import User from '../types/User';
import {
  COLOR_TEXT,
  COLOR_BORDER,
  COLOR_SHADOW,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';

enum HelpTopicType {
  TECH_HELP = 'tech-help',
  LOGIN_SUBSCRIPTION = 'login-subscription',
  ADD_REMOVE_ACCOUNTS = 'add-remove-accounts',
  PLAYER_ADDITIONS_CHANGES = 'player-additions-changes',
  SCHEDULE_TRAINING = 'schedule-training',
  SCORE_DESCRIPTION = 'score-descriptions',
  API_DOCUMENTATION = 'api-documentation',
  OTHER = 'other',
}

interface HelpTopic {
  type: HelpTopicType;
  text: string;
  icon: any;
  iconSize?: number;
  modal: any;
}

const HELP_TOPICS:HelpTopic[] = [
  {
    type: HelpTopicType.TECH_HELP,
    text: 'Tech Help',
    icon: MouseIcon,
    modal: TechHelpModal
  },
  {
    type: HelpTopicType.LOGIN_SUBSCRIPTION,
    text: 'Login / Subscription',
    icon: UserCircleIcon,
    modal: LoginSubscriptionModal,
  },
  {
    type: HelpTopicType.PLAYER_ADDITIONS_CHANGES,
    text: 'Player Additions / Changes',
    icon: AddUserIcon,
    modal: PlayerAdditionChangeModal,
  },
  {
    type: HelpTopicType.ADD_REMOVE_ACCOUNTS,
    text: 'Add / Remove Accounts',
    icon: ManageUsersIcon,
    modal: AddRemoveAccountModal,
  },
  {
    type: HelpTopicType.SCHEDULE_TRAINING,
    text: 'Schedule Training',
    icon: TrainingIcon,
    iconSize: 36,
    modal: ScheduleTrainingModal,
  },
  {
    type: HelpTopicType.SCORE_DESCRIPTION,
    text: 'Score Descriptions',
    icon: HelpIcon,
    iconSize: 24,
    modal: ScoreDescriptionsModal,
  },
  {
    type: HelpTopicType.API_DOCUMENTATION,
    text: 'API Documentation',
    icon: MouseIcon,
    modal: ApiDocumentationModal,
  },
  {
    type: HelpTopicType.OTHER,
    text: 'Other',
    icon: OtherIcon,
    modal: OtherHelpModal,
  }
];

interface HelpPageCardsProps {
  user?: User;
}

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    padding: theme.spacing(0, 5, 5, 5),
    width: '100%',
  },
  cardsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '0 -10px',
  },

  heading: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(0,0,2),
    fontSize: theme.typography.pxToRem(16),
  },

  button: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(33.3333% - 20px)',
    height: '200px',
    margin: '20px 10px 0 10px',
    padding: theme.spacing(2),
    backgroundColor: `${COLOR_WHITE}`,
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    ...theme.typography.h2,

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: '#0F89E6',
      color: 'inherit',

      '& $icon': {
        color: '#0F89E6'
      }
    },
  },

  dialog : {
    overflow: 'auto',
  },

  dropDown: {
    marginBottom: theme.spacing(5),
    padding: 0,
  },
  dropDownSelect: {
    height: '50px',
  },

  icon: {
    width: '32px',
    height: '32px',
    color: COLOR_TEXT,
    marginBottom: '10px',
  },

  input: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  inputHalf: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 0,
    margin: theme.spacing(0, 0, 2, 1),

    '&:nth-of-type(1)': {
      marginLeft: 0,
    }
  },
  inputField: {
    padding: theme.spacing(.5),
  },

  name: {
    display: 'flex',
  },

  multiSelectClassName: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },

  contentClassName: {
    position: 'relative'
  },

  [MEDIA.MOBILE]: {
    container: {
      padding: theme.spacing(0,2,2,2),
    },

    cardsContainer: {
      margin: '0 -5px',
    },

    button: {
      width: 'calc(50% - 10px)',
      height: '150px',
      margin: '10px 5px 0 5px',
    },
  },

  [MEDIA.DESKTOP]: {
    button: {
      height: '300px',
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    cardsContainer: {
      margin: '0 -20px',
    },

    button: {
      width: 'calc(33.3333% - 40px)',
      margin: '40px 20px 0 20px',
    },
  },
}), { name: HelpPageCards.name });

function HelpPageCards (props:HelpPageCardsProps) {
  const classes = useStyles();
  const { user } = props;

  const history = useHistory();

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [selectedTopicType, setSelectedTopicType] = useState<string>('');
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canAccessHelp) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  function handleClick (topicType:string) {
    setIsPopupVisible(true);
    setSelectedTopicType(topicType);
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const selectedTopic = HELP_TOPICS.find((topic:HelpTopic) => topic.type === selectedTopicType);
  const Modal = selectedTopic && selectedTopic.modal;

  return (
    <div className={classes.container}>
      <div className={classes.cardsContainer}>
        {HELP_TOPICS.map((topic:HelpTopic, index:number) => (
          <button
            key={index}
            className={classes.button}
            onClick={() => handleClick(topic.type)}
          >
            <topic.icon
              className={classes.icon}
              style={topic.iconSize ? { width: topic.iconSize, height: topic.iconSize } : undefined}
            />
            {topic.text}
          </button>
        ))}
      </div>

      {(isPopupVisible && Modal) && (
        <Modal
          isPopupVisible={isPopupVisible}
          onClose={() => {setIsPopupVisible(false)}}
          className={classes.dialog}
          headingClassName={classes.heading}
          inputFieldClassName={classes.inputField}
          inputClassName={classes.input}
          inputHalfClassName={classes.inputHalf}
          dropDownClassName={classes.dropDown}
          dropDownSelectClassName={classes.dropDownSelect}
          fullNameClassName={classes.name}
          multiSelectClassName={classes.multiSelectClassName}
          contentClassName={classes.contentClassName}
          onError={() => {
            showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
          }}
          onSuccess={() => {
            showToast('Submitted', ToastType.SUCCESS);
          }}
        />
      )}

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(HelpPageCards);
