import React from 'react';
import Icon from '../atoms/Icon';

function CloseIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          fill='currentColor'
          fillRule='nonzero'
          points='22.335175 24 24 22.335175 17.664825 16 24 9.66482505 22.335175 8 16 14.335175 9.66482505 8 8 9.66482505 14.335175 16 8 22.335175 9.66482505 24 16 17.664825'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(CloseIcon);
