import React from 'react';
import Icon from '../atoms/Icon';

function CopyIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(6, 6)' fill='currentColor' fillRule='nonzero'>
          <path d='M18,4 L6,4 C4.895,4 4,4.895 4,6 L4,18 C4,19.105 4.895,20 6,20 L18,20 C19.105,20 20,19.105 20,18 L20,6 C20,4.895 19.105,4 18,4 Z M18,18 L6,18 L6,6 L18,6 L18,18 Z' />
          <path d='M2,2 L16,2 L16,0 L2,0 C0.895,0 0,0.895 0,2 L0,16 L2,16 L2,2 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(CopyIcon);
