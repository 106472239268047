import React from 'react';
import Icon from '../atoms/Icon';

function ScaleIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M0 3h32v26.667H0V3zm2.667 2.667V27h26.666V5.667H2.667z'/>
        <path d='M16 8.333c2.517 0 4.93.537 7.162 1.429 1.343.537 2.339 1.08 2.91 1.462l1.059.705-5.043 8.404H9.912L4.869 11.93l1.058-.705c.572-.381 1.568-.925 2.911-1.462C11.07 8.87 13.483 8.333 16 8.333zM16 11c-2.15 0-4.236.464-6.171 1.238-.473.189-.911.387-1.312.587l2.905 4.842h3.753l2.3-4.597 2.384 1.193-1.702 3.404h2.421l2.905-4.842c-.4-.2-.84-.398-1.312-.587C20.236 11.464 18.15 11 16 11z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(ScaleIcon);
