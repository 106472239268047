import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { State } from '../redux/reducers';
import InfoIcon from '../icons/InfoIcon';
import Tooltip from '../atoms/Tooltip';
import TeamLogo from '../atoms/TeamLogo';
import Loader from '../atoms/Loader';
import gql from '../services/gql';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Team from '../types/Team';
import PAIChartTeam from '../types/PAIChartTeam';

interface NFLPAIDraftRatingsChartProps {
  className?: string;
  sideDrawerOpen?: boolean;
  team?: Team;
  nflDraftYear?: number;
}

const CHART_INITIAL_WIDTH = 500;
const CHART_HEIGHT = 500;
const CHART_MARGIN_LEFT = 56;
const CHART_MARGIN_RIGHT = 44;
const CHART_HORIZONTAL_LINES_NUMBER = 6;
const CHART_VERTICAL_LINES_NUMBER = 6;
const CHART_VERTICAL_LINES_STEP = 20;
const TEAM_LOGO_SIZE = 48;

const useStyles = makeStyles((theme: Theme) => ({
  nflPAIDraftRatingsChart: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },

  infoIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: theme.spacing(-6),
    right: '-5px',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_BLUE,
    },
  },

  chart: {
    width: `calc(100% - ${CHART_MARGIN_LEFT}px - ${CHART_MARGIN_RIGHT}px)`,
    minWidth: '500px',
    height: `${CHART_HEIGHT}px`,
    margin: theme.spacing(2, 0, 9, 9),
    boxSizing: 'content-box',
    position: 'relative',
  },

  verticalCaption: {
    position: 'absolute',
    left: '-88px',
    top: 'calc(50% - 7px)',
    transform: 'translateY(-50%) rotate(-90deg)',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
  },
  horizontalCaption: {
    position: 'absolute',
    left: 'calc(50% - 8px)',
    bottom: '-52px',
    transform: 'translateX(-50%)',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontWeight: 600,
    color: COLOR_DARK_GRAY,
  },

  horizontalLines: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: COLOR_BORDER,

    '&:before': {
      content: 'attr(data-axis-name)',
      position: 'absolute',
      left: '-18px',
      top: '-7px',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1,
      color: COLOR_TEXT,
    },

    '&:nth-of-type(1)': {
      '&:before': {
        content: '"0"',
        top: '6px',
      },
    },
  },

  verticalLines: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  verticalLine: {
    width: '1px',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: COLOR_BORDER,

    '&:before': {
      content: 'attr(data-axis-name)',
      position: 'absolute',
      left: '0',
      bottom: '-22px',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1,
      color: COLOR_TEXT,
      transform: 'translateX(-50%)',
    },

    '&:nth-of-type(1)': {
      '&:before': {
        display: 'none',
      },
    },
  },

  team: {
    width: `${TEAM_LOGO_SIZE}px`,
    height: `${TEAM_LOGO_SIZE}px`,
    position: 'absolute',
    cursor: 'default',
    border: `1px solid rgba(15, 137, 230, 0)`,
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    transition: 'border 0.3s, background 0.3s',

    '&:hover': {
      borderColor: COLOR_BLUE,
      backgroundColor: COLOR_WHITE,
      zIndex: 1,

      '& $teamLogo': {
        opacity: '1',
      },
    },
  },
  teamSelected: {
    borderColor: COLOR_BLUE,
    backgroundColor: COLOR_WHITE,
    zIndex: 1,

    '& $teamLogo': {
      opacity: '1',
    },
  },
  teamLogo: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    opacity: '0.5',
    transition: 'opacity 0.3s',
  },

  infoTableWrapper: {
    padding: theme.spacing(0.5),
  },
  infoTable: {
    color: COLOR_WHITE,
    borderCollapse: 'collapse',
  },
  infoTableHeadCell: {
    padding: theme.spacing(0.5, 1),
    borderLeft: `1px solid ${COLOR_DARK_GRAY}`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 'bold',

    '&:first-of-type': {
      borderLeft: 0,
    },
  },
  infoTableCell: {
    padding: theme.spacing(0.5, 1),
    borderTop: `1px solid ${COLOR_DARK_GRAY}`,
    borderLeft: `1px solid ${COLOR_DARK_GRAY}`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1.2,
    textAlign: 'center',

    '&:first-of-type': {
      borderLeft: 0,
    },
  },
  teamName: {
    width: '112px',
  },

  [MEDIA.MOBILE]: {
    chartWrap: {
      overflow: 'auto',
    },

    chart: {
      minWidth: '500px',
    },
  },
}), { name: NFLPAIDraftRatingsChart.name });

function NFLPAIDraftRatingsChart (props: NFLPAIDraftRatingsChartProps) {
  const {
    className,
    team,
    sideDrawerOpen,
    nflDraftYear,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [chartTeams, setChartTeams] = useState<PAIChartTeam[]>([]);
  const [width, setWidth] = useState<number>(CHART_INITIAL_WIDTH);

  const chartEl = useRef(null);

  const throttledUpdateChartParameters = useCallback(throttle(updateChartParameters, 300), []);

  useEffect(() => {
    window.addEventListener('resize', throttledUpdateChartParameters);

    fetchChartTeams();

    return () => onUnmount();
  }, []);

  useEffect(() => {
    updateChartParameters();
  }, [chartTeams?.length]);

  useEffect(() => {
    window.setTimeout(updateChartParameters, 300);
  }, [sideDrawerOpen]);

  function fetchChartTeams () {
    if (!loading) setLoading(true);

    gql(`
      chartNFL {
        team {
          id
          name
          logo247
          logoESPN
          logoAlt
        }
        averagePAI
        percentWithPAI
        countWithPAI
      }
    `)
      .then((data:any) => data?.chartNFL || [] as PAIChartTeam[])
      .then((chartTeams:PAIChartTeam[]) => {
        setChartTeams((chartTeams.sort((team1:PAIChartTeam, team2:PAIChartTeam) => {
          const name1 = team1?.team?.name || '';
          const name2 = team2?.team?.name || '';

          if (name1 > name2) return 1;
          if (name1 < name2) return -1;
          return 0;
        })));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function updateChartParameters () {
    const chartWidth = ((chartEl || {}).current || { offsetWidth: CHART_INITIAL_WIDTH }).offsetWidth;
    setWidth(chartWidth);
  }

  function onUnmount () {
    window.removeEventListener('resize', throttledUpdateChartParameters);
  }

  function renderHorizontalLine (index:number) {
    let bottomOffset = index * Math.round(CHART_HEIGHT / (CHART_HORIZONTAL_LINES_NUMBER - 1));
    if (index === CHART_HORIZONTAL_LINES_NUMBER - 1) {
      bottomOffset = CHART_HEIGHT;
    }

    return (
      <div
        key={index}
        className={classes.horizontalLine}
        style={{ bottom: `${bottomOffset}px` }}
        data-axis-name={index}
      />
    );
  }

  function renderVerticalLine (index:number) {
    let leftOffset = index * Math.round(width / (CHART_VERTICAL_LINES_NUMBER - 1));
    if (index === CHART_VERTICAL_LINES_NUMBER - 1) {
      leftOffset = width;
    }

    return (
      <div
        key={index}
        className={classes.verticalLine}
        style={{ left: `${leftOffset}px` }}
        data-axis-name={`${(index * CHART_VERTICAL_LINES_STEP) || ''}`}
      />
    );
  }

  function roundToHundredth (value:number | string):string {
    return Number(value || 0).toFixed(2);
  }

  return (
    <Paper className={clsx(classes.nflPAIDraftRatingsChart, className)}>
      <div className={classes.header}>
        {nflDraftYear && (
          <h2 className={classes.headerTitle}>NFL PAI Draft Ratings {nflDraftYear}</h2>
        )}
      </div>

      <div
        className={classes.chart}
        ref={chartEl}
      >
        <Loader inProgress={loading} />

        <Tooltip
          placement='bottom'
          title={
            <div className={classes.infoTableWrapper}>
              <table className={classes.infoTable}>
                <thead>
                  <tr>
                    <td className={classes.infoTableHeadCell}>Team</td>
                    <td className={classes.infoTableHeadCell}>PAI Avg</td>
                    <td className={classes.infoTableHeadCell}># of Players</td>
                  </tr>
                </thead>

                <tbody>
                  {(chartTeams || []).map((chartTeam:PAIChartTeam, index:number) => (
                    <tr key={index}>
                      <td className={clsx(classes.infoTableCell, classes.teamName)}>{chartTeam.team.name}</td>
                      <td className={classes.infoTableCell}>{roundToHundredth(chartTeam.averagePAI)}</td>
                      <td className={classes.infoTableCell}>{chartTeam.countWithPAI}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        >
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>

        <div className={classes.horizontalCaption}>Total Draft Pick % with PAI</div>
        <div className={classes.horizontalLines}>
          {Array
            .from(new Array(CHART_HORIZONTAL_LINES_NUMBER))
            .map((_, index) => renderHorizontalLine(index))
          }
        </div>

        <div className={classes.verticalCaption}>PAI Score Avg</div>
        <div className={classes.verticalLines}>
          {Array
            .from(new Array(CHART_VERTICAL_LINES_NUMBER))
            .map((_, index) => renderVerticalLine(index))
          }
        </div>

        {chartTeams.map((chartTeam:PAIChartTeam) => (
          <Tooltip
            key={chartTeam.team.id}
            title={(
              <>
                <b>{chartTeam.team.name}</b> <br />
                PAI: {roundToHundredth(chartTeam.averagePAI)} <br />
                Total % with PAI: {Math.round(chartTeam.percentWithPAI || 0)}%
              </>
            )}
          >
            <div
              className={clsx(
                classes.team,
                team?.id && chartTeam.team.id === team.id && classes.teamSelected,
              )}
              style={{
                left: `${Math.round((chartTeam.percentWithPAI * width / 100) - TEAM_LOGO_SIZE / 2)}px`,
                bottom: `${Math.round((chartTeam.averagePAI * CHART_HEIGHT / (CHART_HORIZONTAL_LINES_NUMBER - 1)) - TEAM_LOGO_SIZE / 2)}px`,
              }}
            >
              <TeamLogo
                className={classes.teamLogo}
                team={chartTeam.team}
              />
            </div>
          </Tooltip>
        ))}
      </div>
    </Paper>
  );
}

const mapStateToProps = (state:State) => {
  return {
    sideDrawerOpen: state.ui.sideDrawerOpen,
    nflDraftYear: state.configurations.nflDraftYear,
  };
};

export default connect(
  mapStateToProps,
)(NFLPAIDraftRatingsChart);
