import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ClassIcon from '../icons/ClassIcon';
import SchoolIcon from '../icons/SchoolIcon';
import PositionIcon from '../icons/PositionIcon';
import LocationIcon from '../icons/LocationIcon';
import RulerVerticalIcon from '../icons/RulerVerticalIcon';
import ScaleIcon from '../icons/ScaleIcon';
import AllConferenceIcon from '../icons/AllConferenceIcon';
import AllAmericanIcon from '../icons/AllAmericanIcon';
import WatchListIcon from '../icons/WatchListIcon';
import LinkIcon from '../icons/LinkIcon';
import PffLinkIcon from '../icons/PffLinkIcon';
import TeamsIcon from '../icons/TeamsIcon';
import TransferPortalProfileIcon from '../icons/TransferPortalProfileIcon';
import UserIcon from '../icons/UserIcon';
import TeamLogo from '../atoms/TeamLogo';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import Links from '../molecules/Links';
import ShowMoreDots from '../molecules/ShowMoreDots';
import WatchList from '../molecules/WatchList';
import { inchesToFeetAndInches, datetimeToDate } from '../services/converter';
import { isValidDOB, capitalizeFirstLetter, prepareLinks } from '../services/player';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_DARK_GREEN,
  COLOR_MIDDLE_GRAY,
} from '../styles/colors';
import Player from '../types/Player';
import Position from '../types/Position';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import User from '../types/User';
import { LinkType } from '../types/Link';

interface PlayerProfileCollegeOverviewProps {
  className?: string;
  player?: Player;
  selectedPosition?: Position;
  user: User | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileCollegeOverview: {},

  divider: {
    width: '1px',
    height: '30px',
    margin: theme.spacing(0, 2.5),
    background: COLOR_MIDDLE_GRAY,
  },

  links: {
    padding: theme.spacing(1, 0),
    height: 'auto',
    alignItems: 'center',
    overflow: 'visible',
  },

  teamLogo: {
    height: '32px',
    marginRight: theme.spacing(2),
  },
  link: {
    color: COLOR_BLUE,
    textDecoration: 'underline',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  collegeStatus: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: 1,
  },
  active: {
    color: COLOR_DARK_GREEN,
  },
  former: {
    color: COLOR_DARK_GRAY,
  },

  more: {
    marginLeft: theme.spacing(2),
  },

  formerColleges: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
  },

  formerCollege: {},

  sportSourceLogo: {
    width: 26,
    height: 'auto',
    marginTop: 6,
  },

  userIcon: {
    height: 22,
  },
  transferYear: {
    marginLeft: '5px'
  }
}), { name: PlayerProfileCollegeOverview.name });

export default function PlayerProfileCollegeOverview (props: PlayerProfileCollegeOverviewProps) {
  const {
    className,
    player,
    selectedPosition,
    user,
  } = props;
  const classes = useStyles();

  const primaryCollege = (player?.playerColleges || [])
    .find((college:College) => !!college?.isPrimary);
  const formerColleges = (player?.playerColleges || [])
    .filter((college:College) => !!college?.isFormer);
  const highSchool = (player?.highSchools || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  const team = primaryCollege?.team;

  const rosterHeight = primaryCollege?.height || highSchool?.height || null;
  const rosterWeight = primaryCollege?.weight || highSchool?.weight || null;
  const dob = !!player?.dob && datetimeToDate(player.dob);
  const jerseyNumber = primaryCollege?.jerseyNumber;
  const transferStatus = player?.transferStatus;
  const links = prepareLinks(player);
  const ssaLink = (links?.find(link => link.key === LinkType.ssa) || {})?.value;

  return (
    <NamedBox
      className={clsx(classes.playerProfileCollegeOverview, className)}
      name='Overview'
    >
      <ListItem
        icon={ClassIcon}
        title='Recruiting Class:'
      >
        <div><b>{primaryCollege?.recruitingClass || highSchool?.graduatingClass || 0}</b></div>

        {dob && isValidDOB(dob) && (
          <>
            <div className={classes.divider} />
            <div>DOB: <b>{dob}</b></div>
          </>
        )}
      </ListItem>

      {selectedPosition && (
        <ListItem
          icon={PositionIcon}
          title='Position:'
        >
          <div><b>{selectedPosition}</b></div>
        </ListItem>
      )}

      {(player && team?.name) && (
        <ListItem
          icon={SchoolIcon}
          title='College:'
        >
          <TeamLogo
            className={classes.teamLogo}
            team={team}
          />

          {user?.accessLevel?.accessCollegeTeamPage
            ? <Link
                to={`/team/${team.slug}`}
                className={classes.link}
              >
                <b>{team.name}</b>
              </Link>
            : <b>{team.name}</b>
          }

          {(user?.accessLevel?.accessCollegeTeamPage && primaryCollege) && (
            <span
              className={clsx(
                classes.collegeStatus,
                primaryCollege?.isFormer ? classes.former : classes.active,
              )}
            >
              {primaryCollege?.isFormer ? '(Former)' : '(Active)'}
            </span>
          )}

          {!!formerColleges.length && (
            <ShowMoreDots
              className={classes.more}
              tooltipContent={(
                <ul className={classes.formerColleges}>
                  {formerColleges.map(college => (
                    <li
                      key={college.id}
                      className={classes.formerCollege}
                    >
                      {college?.team?.name}
                    </li>
                  ))}
                </ul>
              )}
            />
          )}
        </ListItem>
      )}

      {(team?.city || team?.state) && (
        <ListItem
          icon={LocationIcon}
          title='College Location:'
        >
          <b>{team?.city || ''}{(team?.city && team?.state) ? `, ${team.state}` : (!team?.city && team?.state) ? team?.state : ''}</b>
        </ListItem>
      )}

      {!!rosterHeight && (
        <ListItem
          icon={RulerVerticalIcon}
          title='Roster Height:'
        >
          <b>{inchesToFeetAndInches(rosterHeight)}</b>
        </ListItem>
      )}

      {!!rosterWeight && (
        <ListItem
          icon={ScaleIcon}
          title='Roster Weight:'
        >
          <b>{rosterWeight} lbs.</b>
        </ListItem>
      )}

      {!!jerseyNumber && (
        <ListItem
          icon={TeamsIcon}
          title='Jersey Number:'
        >
          <b>{jerseyNumber}</b>
        </ListItem>
      )}

        <ListItem
          icon={TransferPortalProfileIcon}
          title='Transfer Portal:'
        >
          <b className={transferStatus == 'active' ? classes.active : ''}>{capitalizeFirstLetter(transferStatus || '') || "N/A"}</b>
          <b className={classes.transferYear}>{player?.transferYear}</b>
        </ListItem>

      {primaryCollege?.url && (
        <ListItem
          icon={UserIcon}
          iconClassName={classes.userIcon}
          title='College Roster:'
        >
          <b>
            <a
              className={classes.link}
              href={primaryCollege?.url}
              target='_blank'
            >
              Roster Bio
            </a>
          </b>
        </ListItem>
      )}

      {!!ssaLink && (
        <ListItem
          icon={() => <img className={classes.sportSourceLogo} src='/images/websites/ssa.png' />}
          title='SportSource:'
        >
          <b>
            <a
              className={classes.link}
              href={ssaLink}
              target='_blank'
            >
              SSA Player Link
            </a>
          </b>
        </ListItem>
      )}

      {!!links?.length && (links.find(link => link.key === LinkType.pff) || {}).value && (
        <ListItem
          contentClassName={classes.links}
          className={classes.links}
          icon={PffLinkIcon}
          title='Pro Football Focus:'
        >
          <a
            className={classes.link}
            href={`${(links.find(link => link.key === LinkType.pff) || {}).value}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <b>PFF Ultimate Link</b>
          </a>
        </ListItem>
      )}

      {!!player?.allConference?.length && (
        <ListItem
          icon={AllConferenceIcon}
          title='All-Conference:'
        >
          <b>{player.allConference.join(', ')}</b>
        </ListItem>
      )}

      {!!player?.allAmerican?.length && (
        <ListItem
          icon={AllAmericanIcon}
          title='All-American:'
        >
          <b>{player.allAmerican.join(', ')}</b>
        </ListItem>
      )}

      {!!player?.watchList?.length && (
        <ListItem
          icon={WatchListIcon}
          title='Watch List:'
        >
          <WatchList list={player.watchList} />
        </ListItem>
      )}

      {!!links?.length && (
        <ListItem
          contentClassName={classes.links}
          icon={LinkIcon}
          title='Links:'
        >
          <Links links={links} />
        </ListItem>
      )}
    </NamedBox>
  );
}
