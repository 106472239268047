import React from 'react';
import Icon from '../atoms/Icon';

function RemoveIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(6, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M16,22 L4,22 C2.9,22 2,21.1 2,20 L2,3 L18,3 L18,20 C18,21.1 17.1,22 16,22 Z M20,3 L0,3 M7,3 L7,1 C7,0.4 7.4,0 8,0 L12,0 C12.6,0 13,0.4 13,1 L13,3 M10,18 L10,7 M14,18 L14,7 M6,18 L6,7' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(RemoveIcon);
