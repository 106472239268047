import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '../atoms/CloseButton';
import QuickProgramSearchAutoComplete from '../molecules/QuickProgramSearchAutoComplete';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BLUE, COLOR_BORDER, COLOR_DARK_BLUE, COLOR_TEXT } from '../styles/colors';
import Program from '../types/Program';

export interface SearchByProgramName {
  name: string;
  id?: number;
}

interface SearchProgramsProps {
  className?: string;
  searchByProgramName: SearchByProgramName[];
  setSearchByProgramName: (searchByProgramName:SearchByProgramName[]) => void;
}

const COLLAPSED_LIMIT = 8;

const useStyles = makeStyles(theme => ({
  searchPlayerNames: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  autoComplete: {
    width: '100%',
  },

  addButton: {
    width: 52,
    height: 52,
    marginLeft: theme.spacing(1),
    color: COLOR_BLUE,
    background: 'none',
    border: 'none',

    '&:hover': {
      color: COLOR_DARK_BLUE,
      background: 'none',
      border: 'none',
    },
  },

  names: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  name: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 0.25, 0, 1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,
    border: `1px solid ${COLOR_BORDER}`,
    borderRadius: 6,

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  playerLink: {
    color: COLOR_BLUE,
    textDecoration: 'none',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  removeButton: {
    marginLeft: theme.spacing(0.5),
    transform: 'scale(0.78)',
  },

  collapseButton: {
    marginTop: theme.spacing(2),
    appearance: 'none',
    border: 0,
    padding: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_BLUE,
    background: 'none',
    outline: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },
  collapseText: {
    textDecoration: 'underline',
  },
  collapseArrow: {
    marginLeft: theme.spacing(1),
  },
}), { name: SearchPrograms.name });

export default function SearchPrograms (props:SearchProgramsProps) {
  const {
    className,
    searchByProgramName,
    setSearchByProgramName,
  } = props;
  const classes = useStyles();

  const [collapsed, setCollapsed] = useState<boolean>(true);

  function onEnter (searchText:string) {
    if (searchText.length > 2) {
      setSearchByProgramName([
        ...searchByProgramName,
        { name: searchText } as SearchByProgramName,
      ]);
    }
  }

  function onSelect (program:Program|null) {
    if (program) {
      setSearchByProgramName([
        ...searchByProgramName,
        {
          name: `${program.name}`,
          id: program.id,
        } as SearchByProgramName,
      ]);
    }
  }

  function onRemove (index:number) {
    return () => {
      const mutatedPlayers = [...searchByProgramName];
      mutatedPlayers.splice(index, 1);

      setSearchByProgramName(mutatedPlayers);
    };
  }

  const programToShow:SearchByProgramName[] = collapsed
    ? searchByProgramName.slice(0, COLLAPSED_LIMIT)
    : searchByProgramName;

  return (
    <div className={clsx(classes.searchPlayerNames, className)}>
      <QuickProgramSearchAutoComplete
        className={classes.autoComplete}
        label='Search by Program Name'
        onEnter={onEnter}
        onSelect={onSelect}
        clearOnAction
      />

      {!!searchByProgramName?.length && (
        <ul className={classes.names}>
          {programToShow.map((porgram:SearchByProgramName, index:number) => (
            <li
              key={index}
              className={classes.name}
            >
              {porgram?.name}

              <CloseButton
                className={classes.removeButton}
                onClick={onRemove(index)}
              />
            </li>
          ))}
        </ul>
      )}

      {searchByProgramName?.length > COLLAPSED_LIMIT && (
        <button
          className={classes.collapseButton}
          onClick={() => setCollapsed(!collapsed)}
        >
          <span className={classes.collapseText}>
            Show {collapsed ? 'All' : 'Less'}
          </span>

          <span className={classes.collapseArrow}>
            {collapsed ? <>&#9660;</> : <>&#9650;</>}
          </span>
        </button>
      )}
    </div>
  );
}
