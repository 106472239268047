import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SelectPopover, { SelectPopoverItem } from '../molecules/SelectPopover';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_DARK_GRAY, COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';

interface SelectPageProps {
  className?: string;
  pages: number[];
  currentPage: number;
  onSelect: (page:string|number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectPage: {
    appearance: 'none',
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1, 0.25),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    border: `1px solid`,
    borderRadius: '50%',
    borderColor: 'transparent',
    outlineColor: '#87c4f3',
    background: 'none',
    userSelect: 'none',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:hover': {
      borderColor: COLOR_DARK_GRAY,
      color: COLOR_TEXT,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    },
    '&:focus': {
      borderColor: COLOR_DARK_GRAY,
      color: COLOR_TEXT,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    },
  },

  popoverItem: {
    padding: `${theme.spacing(1.5, 1.5)} !important`,
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s, border-color 0.3s, background 0.3s',

    '&:hover': {
      color: COLOR_TEXT,
    },
    '&:focus': {
      color: COLOR_TEXT,
      fontWeight: 'bold',
      outline: 'none',
    },
  },

  currentPopoverItem: {
    color: COLOR_WHITE,
    background: `${COLOR_DARK_GRAY} !important`,
    borderColor: COLOR_DARK_GRAY,
    outline: 'none',
    cursor: 'default',
  },
}), { name: SelectPage.name });

export default function SelectPage (props:SelectPageProps) {
  const {
    className,
    pages,
    currentPage,
    onSelect,
  } = props;
  const classes = useStyles();
  const [selectPageAnchorEl, setSelectPageAnchorEl] = useState<HTMLElement|null>(null);

  function onEllipsisClick (event:any) {
    setSelectPageAnchorEl(event.currentTarget);
  }

  function onSelectPage (page:string) {
    onSelect(Number(page));
    onClose();
  }

  function onClose () {
    setSelectPageAnchorEl(null)
  }

  return (
    <>
      <button
        className={clsx(classes.selectPage, className)}
        onClick={onEllipsisClick}
      >
        …
      </button>

      <SelectPopover
        itemClassName={classes.popoverItem}
        currentItemClassName={classes.currentPopoverItem}
        anchorEl={selectPageAnchorEl}
        items={pages.map((page:number) => ({
          content: String(page),
          value: String(page),
        } as SelectPopoverItem))}
        value={String(currentPage)}
        onSelect={onSelectPage}
        onClose={onClose}
      />
    </>
  );
}
