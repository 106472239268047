import { getUIInitialState } from './reducers/ui';
import { getUserInitialState } from './reducers/user';
import { getConfigurationsInitialState } from './reducers/configurations';
import { getAchievementsInitialState } from './reducers/achievements';
import { getTeamsInitialState } from './reducers/teams';
import { getStatesInitialState } from './reducers/states';
import { getConferencesInitialState } from './reducers/conferences';
import { getUserSettingsInitialState } from './reducers/user-settings';

export const initialState = {
  ui: getUIInitialState(),
  user: getUserInitialState(),
  configurations: getConfigurationsInitialState(),
  achievements: getAchievementsInitialState(),
  teams: getTeamsInitialState(),
  states: getStatesInitialState(),
  conferences: getConferencesInitialState(),
  userSettings: getUserSettingsInitialState(),
};
