import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import DropDown from '../atoms/DropDown';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface StagingQuickFilterProps {
  className?: string;
  value: QuickFilter;
  onSelect: (quickFilter:QuickFilter) => void;
}

export enum QuickFilter {
  ALL = 'all',
  WITH_ERRORS = 'with-errors',
  WITH_DUPLICATE_SLUGS = 'with-duplicate-slugs',
}

const useStyles = makeStyles((theme: Theme) => ({
  stagingQuickFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },
  dropdown: {
    marginLeft: theme.spacing(1),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
}), { name: StagingQuickFilter.name });

export default function StagingQuickFilter (props:StagingQuickFilterProps) {
  const {
    className,
    value,
    onSelect,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.stagingQuickFilter, className)}>
      <div className={classes.title}>Show</div>

      <DropDown
        className={classes.dropdown}
        selectorRootClassName={classes.dropdownSelector}
        items={[
          { content: 'All Players', value: QuickFilter.ALL },
          { content: 'With Errors', value: QuickFilter.WITH_ERRORS },
          { content: 'With Duplicate Slugs', value: QuickFilter.WITH_DUPLICATE_SLUGS },
        ]}
        value={value}
        onChange={onSelect}
      />
    </div>
  );
}
