import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import AlertIcon from '../icons/AlertIcon';
import AlertsTable from '../organisms/AlertsTable';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';

const useStyles = makeStyles(theme => ({
  alertsPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(5, 5, 0),
    color: COLOR_TEXT,
  },
  headerTitle: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  [MEDIA.MOBILE]: {
    header: {
      margin: theme.spacing(2, 2, 0),
    },
  },
}), { name: AlertsPageTemplate.name });

export default function AlertsPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.alertsPageTemplate}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            <AlertIcon className={classes.headerIcon} />
            Alert List
          </h1>
        </div>

        <AlertsTable className={classes.alertsTable} />
      </Paper>
    </PageContainer>
  );
}
