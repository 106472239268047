import React from 'react';
import Icon from '../atoms/Icon';

function EditIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(5, 5)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path d='M5.5,21.9 L0,23 L1.1,17.5 L16.7,1.9 C18,0.6 19.8,0.6 21.1,1.9 L21.1,1.9 C22.4,3.2 22.4,5 21.1,6.3 L5.5,21.9 Z M15.4,3.4 L19.7,7.7 M5.8,21.6 C5.3,19.4 3.6,17.7 1.4,17.2' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(EditIcon);
