import React from 'react';
import Icon from '../atoms/Icon';

function GolfIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(7, 4)'>
          <path
            d='M8.99999987,0 C5.78460966,0 2.81346644,1.71539025 1.20577125,4.5 C-0.40192393,7.28460967 -0.40192393,10.7153903 1.20577125,13.5 C2.81346644,16.2846097 5.78460966,18 8.99999987,18 C12.2153904,18 15.1865336,16.2846098 16.7942288,13.5 C18.401924,10.7153903 18.401924,7.28460966 16.7942288,4.5 C15.1865336,1.71539021 12.2153904,0 8.99999987,0 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M13,10 C12.4477153,10 12,10.4477153 12,11 C12,11.5522847 12.4477153,12 13,12 C13.5522847,12 14,11.5522847 14,11 C14,10.4477153 13.5522847,10 13,10 Z M12,7 C11.4477153,7 11,7.44771525 11,8 C11,8.55228475 11.4477153,9 12,9 C12.5522847,9 13,8.55228475 13,8 C13,7.44771525 12.5522847,7 12,7 Z M11,12 C10.4477153,12 10,12.4477153 10,13 C10,13.5522847 10.4477153,14 11,14 C11.5522847,14 12,13.5522847 12,13 C12,12.4477153 11.5522847,12 11,12 Z M8,11 C7.44771525,11 7,11.4477153 7,12 C7,12.5522847 7.44771525,13 8,13 C8.55228475,13 9,12.5522847 9,12 C9,11.4477153 8.55228475,11 8,11 Z M10,9 C9.44771525,9 9,9.44771525 9,10 C9,10.5522847 9.44771525,11 10,11 C10.5522847,11 11,10.5522847 11,10 C11,9.44771525 10.5522847,9 10,9 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <line
            x1='0'
            y1='24'
            x2='18'
            y2='24'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M4,18 L14,18 M4,18 C4,18 9,18.084 9,24 M14,18 C14,18 9,18.084 9,24'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(GolfIcon);
