import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BORDER, COLOR_LIGHT_ORANGE, COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';

interface ToggleProps {
  className?: string;
  itemClassName?: string;
  items: {
    value: string;
    content: any;
  }[];
  value: string;
  onSelect: (value:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    borderRadius: '20px',
  },
  item: {
    appearance: 'none',
    padding: '5px 15px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    background: 'none',
    border: 0,
    color: COLOR_TEXT,
    cursor: 'pointer',
    outline: 'none',
    zIndex: 1,
    transition: 'color 0.3s',

    '&:hover': {
      color: COLOR_LIGHT_ORANGE,
    },
  },
  selected: {
    color: COLOR_WHITE,

    '&:hover': {
      color: COLOR_WHITE,
    },
  },

  highlighter: {
    position: 'absolute',
    background: COLOR_LIGHT_ORANGE,
    transition: 'all 0.2s ease-out',
  },

  [MEDIA.MOBILE]: {
    item: {
      padding: '5px 10px',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}), { name: Toggle.name });

export default function Toggle (props: ToggleProps) {
  const {
    className,
    itemClassName,
    items,
    value,
    onSelect,
  } = props;
  const classes = useStyles();
  const toggleEl = useRef(null);
  const [selectedItemWidth, setSelectedItemWidth] = useState<number>(0);
  const [selectedItemHeight, setSelectedItemHeight] = useState<number>(22);
  const [selectedItemOffsetTop, setSelectedItemOffsetTop] = useState<number>(0);
  const [selectedItemOffsetLeft, setSelectedItemOffsetLeft] = useState<number>(0);

  useEffect(() => {
    if (toggleEl?.current) {
      const buttons = ((toggleEl || {}).current || { querySelectorAll: (selector:string) => [] }).querySelectorAll('button');
      const selectedItem:any = Array.from(buttons)
        .find((button:any) => button.getAttribute('data-value') === value);

      if (selectedItem) {
        setSelectedItemWidth(selectedItem.offsetWidth);
        setSelectedItemHeight(selectedItem.offsetHeight);
        setSelectedItemOffsetTop(selectedItem.offsetTop);
        setSelectedItemOffsetLeft(selectedItem.offsetLeft);
      }
    }
  }, [items, value]);

  return (
    <div
      className={clsx(classes.toggle, className)}
      ref={toggleEl}
    >
      <div
        key='highlighter'
        className={classes.highlighter}
        style={{
          width: `${selectedItemWidth}px`,
          height: `${selectedItemHeight}px`,
          top: `${selectedItemOffsetTop}px`,
          left: `${selectedItemOffsetLeft}px`,
          borderRadius: `${selectedItemHeight / 2}px`,
        }}
      />

      {items.map((item, index) => (
        <button
          key={index}
          className={clsx(
            classes.item,
            itemClassName,
            value === item.value && classes.selected,
          )}
          onClick={() => onSelect(item.value)}
          data-value={item.value}
        >
          {item.content}
        </button>
      ))}
    </div>
  );
}
