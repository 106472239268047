import React from 'react';
import Icon from '../atoms/Icon';

function BasketballIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentcolor'
        fillRule='nonzero'
      >
        <path d='M16 0a16 16 0 100 32 16 16 0 000-32zm0 2.462a13.538 13.538 0 110 27.076 13.538 13.538 0 010-27.076z'/>
        <path d='M17.23 1.23v13.54h3.74a15.946 15.946 0 014.62-10.062l1.743 1.737a13.485 13.485 0 00-3.893 8.325h7.33v2.46l-7.33.001a13.478 13.478 0 003.597 8.017l.296.307-1.743 1.737a15.946 15.946 0 01-4.62-10.06l-3.74-.001v13.538h-2.46V17.231h-3.74a15.94 15.94 0 01-4.307 9.738l-.313.323-1.743-1.737A13.485 13.485 0 008.56 17.23H1.23v-2.462l7.33.001a13.478 13.478 0 00-3.597-8.018l-.296-.307L6.41 4.708a15.946 15.946 0 014.62 10.061h3.74V1.231h2.46z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(BasketballIcon);
