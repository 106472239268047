import { ScreenSize, BREAKPOINT } from '../services/screen-size';

const MEDIA = {
  MOBILE: `@media (max-width: ${BREAKPOINT[ScreenSize.TABLET] - 1}px)`,
  MOBILE_AND_TABLET: `@media (max-width: ${BREAKPOINT[ScreenSize.DESKTOP_SMALL] - 1}px)`,
  TABLET: `@media (min-width: ${BREAKPOINT[ScreenSize.TABLET]}px) and (max-width: ${BREAKPOINT[ScreenSize.DESKTOP_SMALL] - 1}px)`,
  DESKTOP_SMALL: `@media (min-width: ${BREAKPOINT[ScreenSize.DESKTOP_SMALL]}px) and (max-width: ${BREAKPOINT[ScreenSize.DESKTOP_LARGE] - 1}px)`,
  DESKTOP_LARGE: `@media (min-width: ${BREAKPOINT[ScreenSize.DESKTOP_LARGE]}px)`,
  DESKTOP: `@media (min-width: ${BREAKPOINT[ScreenSize.DESKTOP_SMALL]}px)`,
  PRINT: '@media print',
};

export default MEDIA;
