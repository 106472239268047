import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '../atoms/Checkbox';
import { COLOR_BLUE, COLOR_BORDER, COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface MultiSelectProps {
  className?: string;
  selectorClassName?: string;
  selectorRootClassName?: string;
  valueClassName?: string;
  items: MultiSelectItem[];
  emptyItem?: MultiSelectItem;
  values: string[];
  labelWhenSelectedAll?: any;
  labelWhenSelectedNone?: any;
  onChange: (values: string[]) => void;
  onBlur?: () => void;
}

export interface MultiSelectItem {
  value: any;
  content: any;
  isDefault?: boolean;
  isDragDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  multiSelect: {
    minWidth: '110px',
    outlineColor: COLOR_BLUE,
  },
  multiSelectEmpty: {
    '& $selectorRoot': {
      padding: theme.spacing(1.25, 2),
    },
  },

  selector: {
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #efefef',

    '&:hover': {
      borderColor: COLOR_BLUE,
    },

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '& fieldset': {
      border: 0,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    },
  },
  selectorRoot: {
    padding: '8px 32px 8px 10px',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: `${COLOR_WHITE} !important`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    position: 'relative',

    '&:after': {
      content: '""',
      width: '32px',
      height: '100%',
      background: COLOR_WHITE,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  icon: {
    display: 'none',
  },

  menu: {
    padding: 0,
  },
  menuItem: {
    padding: '6px 16px 6px 8px',
    background: `${COLOR_WHITE} !important`,
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      borderTop: 0,
    },

    '&:hover': {
      background: `rgba(0, 0, 0, 0.04) !important`,
    },
  },

  value: {
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
}), { name: MultiSelect.name });

export default function MultiSelect (props: MultiSelectProps) {
  const {
    className,
    selectorClassName,
    selectorRootClassName,
    valueClassName,
    items = [],
    values = [],
    emptyItem,
    labelWhenSelectedAll,
    labelWhenSelectedNone,
    onChange = () => {},
    onBlur = () => {},
  } = props;
  const classes = useStyles();

  function onValuesChange (event: ChangeEvent<{ value: unknown }>) {
    return onChange(event.target.value as string[]);
  }

  return (
    <FormControl
      className={clsx(
        classes.multiSelect,
        className,
        (values.length === 0 && !emptyItem) && classes.multiSelectEmpty,
      )}
      variant='outlined'
    >
      <Select
        className={clsx(classes.selector, selectorClassName)}
        classes={{
          root: clsx(classes.selectorRoot, selectorRootClassName),
          icon: classes.icon,
        }}
        multiple
        value={values}
        onChange={onValuesChange}
        renderValue={(selectedValues:any) => {
          if (emptyItem && (!selectedValues.length || (selectedValues.length === 1 && selectedValues[0] === emptyItem.value))) {
            return emptyItem.content;
          }
          if (labelWhenSelectedNone && selectedValues.length === 0) {
            return labelWhenSelectedNone;
          }

          if (labelWhenSelectedAll && selectedValues.length === items.length) {
            return labelWhenSelectedAll;
          }

          const allItems = ([emptyItem, ...items] as MultiSelectItem[])
            .filter((item:MultiSelectItem) => item && (selectedValues as string[]).includes(item.value));

          return allItems
            .map((item, index) => <>{item.content}{index === allItems.length - 1 ? '' : ', '}</>);
        }}
        MenuProps={{ classes: { list: classes.menu } }}
        onBlur={onBlur}
        displayEmpty
      >
        {emptyItem && (
          <MenuItem
            key='none'
            value={emptyItem.value}
            classes={{ root: classes.menuItem }}
          >
            <Checkbox checked={values.includes(emptyItem.value)} />
            <span className={clsx(classes.value, valueClassName)}>
              {emptyItem.content}
            </span>
          </MenuItem>
        )}

        {items.map((item:MultiSelectItem, index:number) => (
          <MenuItem
            key={index}
            classes={{ root: classes.menuItem }}
            value={item.value}
          >
            <Checkbox checked={values.includes(item.value)} />
            <span className={clsx(classes.value, valueClassName)}>
              {item.content}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
