import React from 'react';
import Icon from '../atoms/Icon';

function EditProgramIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <g transform='translate(3, 6)'>
          <path
            d='M0,2 L24,2 L24,20 L0,20 L0,2 Z M4,2.5 L4,19.5'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M23,0 C22.25,0 21.500391,0.300391 20.900391,0.900391 L12.099609,9.699219 L11,15 L16.300781,13.900391 L25.099609,5.099609 C25.699609,4.499609 26,3.75 26,3 C26,2.25 25.699609,1.500391 25.099609,0.900391 C24.499609,0.300391 23.75,0 23,0 Z M23,2 C23.25,2 23.499219,2.100781 23.699219,2.300781 C24.099219,2.700781 24.099219,3.299219 23.699219,3.699219 L15.300781,12.099609 L13.599609,12.400391 L13.900391,10.699219 L22.300781,2.300781 C22.500781,2.100781 22.75,2 23,2 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(EditProgramIcon);
