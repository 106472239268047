import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import EditButton from '../atoms/EditButton';
import CancelButton from '../atoms/CancelButton';
import DoneButton from '../atoms/DoneButton';
import Input from '../atoms/Input';
import TableHeadCell from '../atoms/TableHeadCell';
import Tooltip from '../atoms/Tooltip';
import { datetimeToDate } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import DepthChart from '../types/DepthChart';
import { Order } from '../types/Order';

interface DepthChartsTableProps {
  className?: string;
  items: DepthChart[];
  columns: string[];
  selectedItems: DepthChart[];
  setSelectedItems: (items:DepthChart[]) => void;
  editedItems: DepthChart[];
  setEditedItems: (items:DepthChart[]) => void;
  order: Order | undefined;
  setOrder: (order:Order | undefined) => void;
  sortedByColumn: string;
  setSortedByColumn: (sortedByColumn:string) => void;
  onUpdateName: (item:DepthChart, value:string) => void;
  onRenamed: (item:DepthChart) => void;
  onSort: (order:Order, sort:(players:DepthChart[], order:Order) => DepthChart[]) => void;
  limits?: number;
}

export const DEPTH_CHART_COLUMN = {
  NAME: 'name',
  NUMBER_OF_PLAYERS: 'numberOfPlayers',
  DATE_ADDED: 'createdAt',
  LAST_UPDATED: 'updatedAt',
};

export const DEPTH_CHART_ALL_COLUMNS = [
  DEPTH_CHART_COLUMN.NAME,
  DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS,
  DEPTH_CHART_COLUMN.DATE_ADDED,
  DEPTH_CHART_COLUMN.LAST_UPDATED,
];

export const DEPTH_CHART_COLUMN_TITLE = {
  [DEPTH_CHART_COLUMN.NAME]: 'Name',
  [DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS]: <>#&nbsp;of&nbsp;Players</>,
  [DEPTH_CHART_COLUMN.DATE_ADDED]: <>Date&nbsp;Added</>,
  [DEPTH_CHART_COLUMN.LAST_UPDATED]: <>Last&nbsp;Updated</>,
};

const useStyles = makeStyles((theme: Theme) => ({
  depthChartsTableWrap: {
    overflow: 'auto',
  },

  depthChartsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  truncated: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellEdited: {
    padding: 0,
  },

  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },

  nameCell: {
    width: '26%',
    minWidth: '180px',
  },

  numberPlayersCell: {
    width: '160px',
  },

  dateCell: {
    width: '160px',
  },

  actions: {
    minWidth: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginLeft: theme.spacing(0.5),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  editCell: {},
  editButton: {
    width: '24px',
    height: '24px',

    '&:hover': {
      color: COLOR_BLUE,
    },
  },
  editButtonIcon: {
    width: '24px',
    height: '24px',
  },

  depthChartLink: {
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  input: {
    width: 'calc(100% - 4px)',
    padding: theme.spacing(0, 0, 0, 1),
  },
  inputField: {
    padding: theme.spacing(0.5, 0.75, 0.5),
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
    },

    pagination: {
      maxWidth: '60%',
    },
  },
}), { name: DepthChartsTable.name });

export default function DepthChartsTable (props: DepthChartsTableProps) {
  const {
    className,
    items = [],
    columns,
    selectedItems,
    setSelectedItems = () => {},
    editedItems,
    setEditedItems = () => {},
    order,
    setOrder = () => {},
    sortedByColumn,
    setSortedByColumn = () => {},
    onUpdateName = () => {},
    onRenamed = () => {},
    limits,
    onSort = () => {},
  } = props;
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [originalEditedItems, setOriginalEditedItems] = useState<DepthChart[]>([]);

  const limit = limits || 10;
  const totalPages = Math.ceil(items.length / limit);

  useEffect(() => {
    setCurrentPage(1);
  }, [limits]);

  function onCheckBoxChange (checkedItem:DepthChart) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function sortDepthChart (
    depthChart:DepthChart[],
    order:Order,
    getValue: (depthChart:DepthChart) => string | number,
  ) {
    return depthChart.sort((first:DepthChart, second:DepthChart) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function onEdit (editedItem:DepthChart) {
    return () => {
      setEditedItems([...editedItems, editedItem]);
      setOriginalEditedItems([...editedItems, editedItem]);
    };
  }

  function onCancelEdit (editedItem:DepthChart) {
    return () => {
      const originalEditedItemName = (originalEditedItems.find((item:DepthChart) => item.id === editedItem.id) || { name: '' }).name;

      const originalEditedItemsWithoutCancelledItem = [...originalEditedItems];
      const originalRemoveIndex = originalEditedItems.findIndex(item => item.id === editedItem.id);
      if (originalRemoveIndex > -1) {
        originalEditedItemsWithoutCancelledItem.splice(originalRemoveIndex, 1);
      }

      onUpdateName(editedItem, originalEditedItemName);
      setOriginalEditedItems(originalEditedItemsWithoutCancelledItem);
      stopEditing(editedItem);
    };
  }

  function onAcceptEdit (editedItem:DepthChart) {
    return () => {
      stopEditing(editedItem);
      onRenamed(editedItem);
    };
  }

  function stopEditing (editedItem:DepthChart) {
    const editedItemsWithoutCancelledItem = [...editedItems];
    const removeIndex = editedItems.findIndex(item => item.id === editedItem.id);
    if (removeIndex > -1) {
      editedItemsWithoutCancelledItem.splice(removeIndex, 1);
    }

    setEditedItems(editedItemsWithoutCancelledItem);
  }

  function onSortByColumn (sort:(players:DepthChart[], order:Order) => DepthChart[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);
      setSortedByColumn(columnName);
      onSort(newOrder, sort);
    };
  }

  function onInputChange (editedItem:DepthChart) {
    return (value:string) => {
      onUpdateName(editedItem, value);
    };
  }

  function renderRow (item:DepthChart) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id);
    const edited = !!editedItems.find(oneOfEditedItem => oneOfEditedItem.id === item.id);

    return (
      <TableRow
        key={item.id}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(item)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === DEPTH_CHART_COLUMN.NAME && classes.nameCell,
              (edited && tableColumn.value === DEPTH_CHART_COLUMN.NAME) && classes.tableCellEdited
            )}
          >
            {tableColumn.renderContent(item)}
          </TableCell>
        ))}

        <TableCell
          key='edit-cell'
          className={clsx(classes.tableCell, classes.editCell)}
        >
          <div className={classes.actions}>
            {!edited && (
              <Tooltip title='Edit Name'>
                <div className={classes.action}>
                  <EditButton
                    className={classes.editButton}
                    iconClassName={classes.editButtonIcon}
                    onClick={onEdit(item)}
                  />
                </div>
              </Tooltip>
            )}
            {edited && (
              <>
                <Tooltip title='Cancel'>
                  <div className={classes.action}>
                    <CancelButton
                      className={classes.action}
                      onClick={onCancelEdit(item)}
                    />
                  </div>
                </Tooltip>
                <Tooltip title='Save'>
                  <div className={classes.action}>
                    <DoneButton
                      className={classes.action}
                      onClick={onAcceptEdit(item)}
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = items.length < limit ? items.length : (limit * currentPage);

  const tableColumns = columns.map((column:string) => {
    switch (column) {
      case DEPTH_CHART_COLUMN.NAME:
        return {
          value: DEPTH_CHART_COLUMN.NAME,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.NAME],
          sortable: true,
          renderContent: (depthChart:DepthChart) => {
            const edited = !!editedItems.find(oneOfEditedItem => oneOfEditedItem.id === depthChart.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={depthChart.name}
                  onChange={onInputChange(depthChart)}
                />
              )
              : (
                <Link
                  className={classes.depthChartLink}
                  to={`/depth-chart/${depthChart.slug}`}
                >
                  {depthChart.name}
                </Link>
              );
          },
          sort: (depthCharts:DepthChart[], order:Order) => {
            return sortDepthChart(
              depthCharts,
              order,
              (depthChart:DepthChart) =>  depthChart.name || 0,
            );
          },
        };
      case DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS:
        return {
          value: DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS],
          sortable: true,
          renderContent: (depthChart:DepthChart) => depthChart.playerCount,
          sort: (depthCharts:DepthChart[], order:Order) => {
            return sortDepthChart(
              depthCharts,
              order,
              (depthChart:DepthChart) =>  depthChart.playerCount || 0,
            );
          },
        };
      case DEPTH_CHART_COLUMN.DATE_ADDED:
        return {
          value: DEPTH_CHART_COLUMN.DATE_ADDED,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.DATE_ADDED],
          sortable: true,
          renderContent: (depthChart:DepthChart) => depthChart.createdAt ? datetimeToDate(depthChart.createdAt) : '',
          sort: (depthCharts:DepthChart[], order:Order) => {
            return sortDepthChart(
              depthCharts,
              order,
              (depthChart:DepthChart) =>  depthChart.createdAt || 0,
            );
          },
        };
      case DEPTH_CHART_COLUMN.LAST_UPDATED:
        return {
          value: DEPTH_CHART_COLUMN.LAST_UPDATED,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.LAST_UPDATED],
          sortable: true,
          renderContent: (depthChart:DepthChart) => depthChart.updatedAt ? datetimeToDate(depthChart.updatedAt) : '',
          sort: (depthCharts:DepthChart[], order:Order) => {
            return sortDepthChart(
              depthCharts,
              order,
              (depthChart:DepthChart) =>  depthChart.updatedAt || 0,
            );
          },
        };
      default:
        return null;
    }
  });

  return (
    <>
      <div className={classes.depthChartsTableWrap}>
        <Table className={clsx(classes.depthChartsTable, className)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
              >
                <Checkbox
                  checked={!!selectedItems?.length && items.length === selectedItems.length}
                  onChange={() => items.length === selectedItems.length
                    ? setSelectedItems([])
                    : setSelectedItems([...items])
                  }
                />
              </TableHeadCell>

              {tableColumns.map((tableColumn:any) => (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable
                    ? onSortByColumn(tableColumn.sort || ((team:DepthChart[]) => team))
                    : undefined}
                  className={clsx(
                    tableColumn.value === DEPTH_CHART_COLUMN.NAME && classes.nameCell,
                    (tableColumn.value === DEPTH_CHART_COLUMN.DATE_ADDED || tableColumn.value === DEPTH_CHART_COLUMN.LAST_UPDATED) && classes.dateCell,
                    tableColumn.value === DEPTH_CHART_COLUMN.NUMBER_OF_PLAYERS && classes.numberPlayersCell
                  )}
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))}

              <TableHeadCell
                key='edit-cell'
                className={classes.editCell}
              >
                Edit
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!items.length && (
              <TableRow
                key='empty'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.emptyCell)}
                  colSpan={tableColumns.length + 2}
                >
                  <div className={classes.empty}>
                    No Depth Charts
                  </div>
                </TableCell>
              </TableRow>
            )}

            {items
              .slice(itemsFrom, itemsTo)
              .map(renderRow)
            }
          </TableBody>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className={classes.paginationWrapper}>
          <span className={classes.paginationText}>
            Showing {itemsFrom + 1} to {itemsTo} of {items.length}
          </span>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
}
