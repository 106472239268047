import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import PlayerPage from '../pages/PlayerPage';
import SearchPage from '../pages/SearchPage';
import SavedSearchesPage from '../pages/SavedSearchesPage';
import SavedPlayersPage from '../pages/SavedPlayersPage';
import ManagePage from '../pages/ManagePage';
import DepthChartsPage from '../pages/DepthChartsPage';
import DepthChartPage from '../pages/DepthChartPage';
import ExportsPage from '../pages/ExportsPage';
import AlertsPage from '../pages/AlertsPage';
import HelpPage from '../pages/HelpPage';
import ComparePlayersPage from '../pages/ComparePlayersPage';
import TeamsPage from '../pages/TeamsPage';
import TeamPage from '../pages/TeamPage';
import MessagesPage from '../pages/MessagesPage';
import LoginPage from '../pages/LoginPage';
import NewMessagePage from '../pages/NewMessagePage';
import MessagePage from '../pages/MessagePage';
import MyAccountPage from '../pages/MyAccountPage';
import ImportPage from '../pages/ImportPage';
import SideBySidePage from '../pages/SideBySidePage';
import UserPage from '../pages/UserPage';
import ProgramPage from '../pages/ProgramPage';
import CombinePage from '../pages/CombinePage';
import CreatePasswordPage from '../pages/CreatePasswordPage';
import UnsubscribePage from '../pages/UnsubscribePage';
import OptOutPage from '../pages/OptOutPage';
import MyRecruitingBoardPage from '../pages/MyRecruitingBoardPage';
import MyEvaluationBoardPage from '../pages/MyEvaluationBoardPage';
import PrivateRoute from './PrivateRoute';

export default function Router () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login'>
          <LoginPage />
        </Route>

        <Route path='/create-password'>
          <CreatePasswordPage />
        </Route>

        <Route path='/opt-out'>
          <OptOutPage />
        </Route>

        <Route path='/unsubscribe'>
          <UnsubscribePage />
        </Route>

        <PrivateRoute path='/dashboard'>
          <DashboardPage />
        </PrivateRoute>

        <Route path='/player/:slug/pdf'>
          <PlayerPage />
        </Route>
        <PrivateRoute path='/player/:slug'>
          <PlayerPage />
        </PrivateRoute>

        <PrivateRoute path='/search/:searchId'>
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute path='/search'>
          <SearchPage />
        </PrivateRoute>

        <PrivateRoute path='/saved-searches'>
          <SavedSearchesPage />
        </PrivateRoute>

        <PrivateRoute path='/saved-players'>
          <SavedPlayersPage />
        </PrivateRoute>

        <PrivateRoute path='/manage/user/add'>
          <UserPage />
        </PrivateRoute>
        <PrivateRoute path='/manage/user/edit/:userId'>
          <UserPage />
        </PrivateRoute>
        <PrivateRoute path='/manage/user'>
          <Redirect to='/manage/user/add' />
        </PrivateRoute>
        <PrivateRoute path='/manage/program/add'>
          <ProgramPage />
        </PrivateRoute>
        <PrivateRoute path='/manage/program/edit/:programId'>
          <ProgramPage />
        </PrivateRoute>
        <PrivateRoute path='/manage/program'>
          <Redirect to='/manage/program/add' />
        </PrivateRoute>
        {/* Combines */}
        <PrivateRoute path='/manage/combines/add'>
          <CombinePage />
        </PrivateRoute>
        <PrivateRoute path='/manage/combines/edit/:combinesId'>
          <CombinePage />
        </PrivateRoute>
        <PrivateRoute path='/manage/combine'>
          <Redirect to='/manage/combines/add' />
        </PrivateRoute>

        <PrivateRoute path='/manage/:tab'>
          <ManagePage />
        </PrivateRoute>
        <PrivateRoute path='/manage'>
          <Redirect to='/manage/users' />
        </PrivateRoute>

        <PrivateRoute path='/depth-charts'>
          <DepthChartsPage />
        </PrivateRoute>
        <Route path='/depth-chart/:depthChartSlug/pdf'>
          <DepthChartPage />
        </Route>
        <PrivateRoute path='/depth-chart/:depthChartSlug'>
          <DepthChartPage />
        </PrivateRoute>

        <PrivateRoute path='/alerts'>
          <AlertsPage />
        </PrivateRoute>

        <PrivateRoute path='/help'>
          <HelpPage />
        </PrivateRoute>

        <Route path='/compare/:slug/pdf'>
          <ComparePlayersPage />
        </Route>
        <PrivateRoute path='/compare/:slug'>
          <ComparePlayersPage />
        </PrivateRoute>
        <PrivateRoute path='/compare'>
          <ComparePlayersPage />
        </PrivateRoute>

        <PrivateRoute path='/teams'>
          <TeamsPage />
        </PrivateRoute>
        <PrivateRoute path='/team/:teamSlug'>
          <TeamPage />
        </PrivateRoute>

        <PrivateRoute path='/messages/:tab'>
          <MessagesPage />
        </PrivateRoute>
        <PrivateRoute path='/messages'>
          <Redirect to='/messages/inbox' />
        </PrivateRoute>

        <PrivateRoute path='/message/new'>
          <NewMessagePage />
        </PrivateRoute>
        <PrivateRoute path='/message/:type/:messageId'>
          <MessagePage />
        </PrivateRoute>

        <PrivateRoute path='/my-account'>
          <MyAccountPage />
        </PrivateRoute>

        <Route path='/side-by-side/pdf'>
          <SideBySidePage />
        </Route>
        <PrivateRoute path='/side-by-side'>
          <SideBySidePage />
        </PrivateRoute>

        <PrivateRoute path='/import'>
          <ImportPage />
        </PrivateRoute>

        <PrivateRoute path='/exports'>
          <ExportsPage />
        </PrivateRoute>

        <Route path='/recruiting-board/pdf'>
          <MyRecruitingBoardPage />
        </Route>
        <Route path='/evaluation-board/pdf'>
          <MyEvaluationBoardPage />
        </Route>

        <PrivateRoute path='/'>
          <DashboardPage />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
}
