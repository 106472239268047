import React, { useEffect } from 'react';
import clsx from 'clsx';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InternationalIcon from '@material-ui/icons/Public';
import Tooltip from '../atoms/Tooltip';
import { State } from '../redux/reducers';
import { fetchStates } from '../redux/dispatchers/states';
import { COLOR_DARK_GRAY } from '../styles/colors';
import StateType from '../types/State';

interface LocationIconProps {
  className?: string;
  stateCode?: string;
  allStates: StateType[];
  americanStates: StateType[];
  fetchStates: () => void;
}

enum Location {
  CANADA = 'Canada',
  INTERNATIONAL = 'International',
  UNSUPPORTED = 'Unsupported',
}

const LOCATION_ICON_URL = {
  [Location.CANADA]: '/images/country/canada.png',
  [Location.INTERNATIONAL]: '',
  [Location.UNSUPPORTED]: '',
};

const useStyles = makeStyles((theme:Theme) => ({
  locationIcon: {
    width: '16px',
    height: '16px',
    cursor: 'help',
  },

  icon: {
    width: '100%',
    height: '100%',
    color: COLOR_DARK_GRAY,
  },
}), { name: LocationIcon.name });

function LocationIcon (props:LocationIconProps) {
  const {
    className,
    stateCode,
    allStates,
    americanStates,
    fetchStates,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    fetchStates();
  }, []);

  if (!stateCode) return null;

  function getLocation (stateCode:string):Location {
    if (['CAN', 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'].includes(stateCode)) {
      return Location.CANADA;
    } else if (!americanStates.find(usState => usState.code === stateCode)) {
      return Location.INTERNATIONAL;
    } else {
      return Location.UNSUPPORTED;
    }
  }

  const location:Location = getLocation(stateCode);
  const iconUrl:string = LOCATION_ICON_URL[location];
  const isInternational = location === Location.INTERNATIONAL;

  if (!iconUrl && !isInternational) return null;

  const country = isInternational
    ? allStates.find(state => state.code === stateCode)?.name || ''
    : location;

  return (
    <Tooltip title={country}>
      <div className={clsx(classes.locationIcon, className)}>
        {!!iconUrl && (
          <img
            className={classes.icon}
            src={iconUrl}
            alt={location}
          />
        )}

        {isInternational && <InternationalIcon className={classes.icon} />}
      </div>
    </Tooltip>
  );
}

const mapStateToProps = (state:State) => {
  return {
    allStates: state.states.allStates,
    americanStates: state.states.americanStates,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchStates,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationIcon);
