import React from 'react';
import Icon from '../atoms/Icon';

function CombineIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M8.305,19.12 C9.825,19.12 10.665,18.31 11.145,17.48 L9.925,16.89 C9.645,17.44 9.045,17.86 8.305,17.86 C7.145,17.86 6.265,16.93 6.265,15.67 C6.265,14.41 7.145,13.48 8.305,13.48 C9.045,13.48 9.645,13.91 9.925,14.45 L11.145,13.85 C10.675,13.02 9.825,12.22 8.305,12.22 C6.355,12.22 4.805,13.62 4.805,15.67 C4.805,17.72 6.355,19.12 8.305,19.12 Z M15.155,19.12 C17.175,19.12 18.645,17.68 18.645,15.67 C18.645,13.66 17.175,12.22 15.155,12.22 C13.145,12.22 11.675,13.66 11.675,15.67 C11.675,17.68 13.145,19.12 15.155,19.12 Z M15.155,17.86 C13.925,17.86 13.135,16.91 13.135,15.67 C13.135,14.42 13.925,13.48 15.155,13.48 C16.385,13.48 17.185,14.42 17.185,15.67 C17.185,16.91 16.385,17.86 15.155,17.86 Z M21.065,19 L21.065,14.2 L22.945,19 L23.565,19 L25.445,14.2 L25.445,19 L26.875,19 L26.875,12.33 L24.875,12.33 L23.255,16.49 L21.635,12.33 L19.645,12.33 L19.645,19 L21.065,19 Z'
          fill='#2D2D2D'
          fillRule='nonzero'
        />
        <path
          d='M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm0-2.743C8.678 29.257 2.743 23.322 2.743 16S8.678 2.743 16 2.743 29.257 8.678 29.257 16 23.322 29.257 16 29.257z'
          fill='#5EBA6D'
        />
        <path
          d='M0 16c0 8.837 7.163 16 16 16s16-7.163 16-16S24.837 0 16 0a1.371 1.371 0 100 2.743c7.322 0 13.257 5.935 13.257 13.257S23.322 29.257 16 29.257 2.743 23.322 2.743 16A1.371 1.371 0 100 16z'
          fill='#5EBA6D'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(CombineIcon);
