import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import NamedBox from '../atoms/NamedBox';
import Ranks, { RanksType } from '../atoms/Ranks';
import Loader from '../atoms/Loader';
import Accordion from '../atoms/Accordion';
import { RanksComparisonGroup } from '../molecules/RanksTooltip';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import { FormatType, getEventFormatType, getEventName } from '../services/event-names-and-formats';
import Position from '../types/Position';
import Player from '../types/Player';
import Team from '../types/Team';
import PlayerCombineEvent from '../types/PlayerCombineEvent';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import PlayerCombineStats from '../types/PlayerCombineStats';
import PlayerNFLCombineEvent from '../types/PlayerNFLCombineEvent';
import PlayerNFLCombineStats from '../types/PlayerNFLCombineStats';
import { formatDate } from '../services/player';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Tooltip from '../atoms/Tooltip';
import FreshmanIcon from '../icons/FreshmanIcon';
import { COLOR_GREEN } from '../styles/colors';


interface PlayerCombineDataProps {
  className?: string;
  loading?: boolean;
  player?: Player;
  collegeTeam?: Team;
  nflTeam?: Team;
  position?: Position;
  combineEvents: Array<PlayerCombineEvent | PlayerNFLCombineEvent>;
  isNFL?: boolean;
  hideHeader?: boolean;
}

const MIN_EVENT_YEAR = 2010;

const useStyles = makeStyles((theme: Theme) => ({
  playerCombineData: {
    borderBottom: 0,
    position: 'relative',
  },

  header: {
    marginBottom: theme.spacing(2),
  },

  event: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    // minHeight: '60px',
  },
  eventLogo: {
    width: '64px',
    height: '64px',
    marginRight: theme.spacing(2),
  },
  eventInfo: {
    flexGrow: 1,
    border: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    minHeight: '60px',
  },
  eventInfoRow: {},
  eventInfoName: {
    width: '54px',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
  },
  eventInfoValue: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    fontWeight: 'bold',
  },
  hideHeader: {
    display: 'none',
  },
  playerScore: {
    height: '40px',
    width: '40px',
    margin: '0 auto'
  },
  hsScorePosition: {
    display: 'flex',
    position: 'absolute',
    left: '35%',
    top: '60px',
  },
  hsScorePositionCheckMark: {
    display: 'flex',
    position: 'absolute',
    left: '35%',
    top: '60px'
  },
  hsScorePositionCheckMarkShift: {
    display: 'flex',
    position: 'absolute',
    left: '37%',
    top: '60px'
  },
  freshmanIcon: {
    width: 45,
    height: 40,
    cursor: 'help',
    color: COLOR_GREEN,
    transform: 'translate(-4px, 1px)',
  },
}), { name: PlayerCombineData.name });

export default function PlayerCombineData (props: PlayerCombineDataProps) {
  const {
    className,
    loading = false,
    player,
    collegeTeam,
    nflTeam,
    position,
    combineEvents,
    isNFL,
    hideHeader = false
  } = props;
  const classes = useStyles();

  function displayPerformance (performance:number, formatType:FormatType):string {
    if (formatType === FormatType.FEET_AND_INCHES) {
      return inchesToFeetAndInches(performance);
    } else if (formatType === FormatType.INCHES) {
      return `${Number(performance).toFixed(2)}"`;
    } else if (formatType === FormatType.MINUTES_SECONDS_MS) {
      return secondsToMinutesSecondsMs(performance);
    }

    return Number(performance).toFixed(2);
  }

  function getRanksEvent (stats:PlayerCombineStats | PlayerNFLCombineStats) {
    return {
      type: getEventName(stats.event),
      name: stats.meet ? `${stats.meet} ${stats.year ? `(${stats.year})` : ''}` : '',
      result: displayPerformance(stats.performance, getEventFormatType(stats.event)),
    };
  }

  function getEventYear (eventDate:string):number {
    return new Date(eventDate).getUTCFullYear();
  }

  function renderCollegeEvents (
    stats:PlayerCombineStats,
    event:PlayerCombineEvent | PlayerNFLCombineEvent,
    index:number,
  ) {
    return (
      <Ranks
        key={index}
        type={RanksType.COMBINE}
        playerId={player && player.id}
        teamId={collegeTeam && collegeTeam.id}
        position={position}
        comparisonEvent={stats.event}
        combineId={event.id}
        combineCategory={event.category}
        event={getRanksEvent(stats)}
        values={[
          {
            percentile: Math.round(get(stats, 'hsClassStats.percentile', 0)),
            value: `${get(stats, 'hsClassStats.playerRank', 0)}/${get(stats, 'hsClassStats.playerCount', 0)}`,
          },
          {
            percentile: Math.round(get(stats, 'div1Stats.percentile', 0)),
            value: `${get(stats, 'div1Stats.playerRank', 0)}/${get(stats, 'div1Stats.playerCount', 0)}`,
          },
          {
            percentile: Math.round(get(stats, 'teamStats.percentile', 0)),
            value: `${get(stats, 'teamStats.playerRank', 0)}/${get(stats, 'teamStats.playerCount', 0)}`,
            comparisonGroup: RanksComparisonGroup.COLLEGE_TEAM,
          },
          {
            percentile: Math.round(get(stats, 'conferenceStats.percentile', 0)),
            value: `${get(stats, 'conferenceStats.playerRank', 0)}/${get(stats, 'conferenceStats.playerCount', 0)}`,
            comparisonGroup: RanksComparisonGroup.COLLEGE_CONFERENCE,
          },
          {
            percentile: Math.round(get(stats, 'nflDraftStats.percentile', 0)),
            value: `${get(stats, 'nflDraftStats.playerRank', 0)}/${get(stats, 'nflDraftStats.playerCount', 0)}`,
            comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
          },
        ]}
        bottomSpace
      />
    );
  }

  function renderNFLEvents (
    stats:PlayerNFLCombineStats,
    event:PlayerCombineEvent | PlayerNFLCombineEvent,
    index:number,
  ) {
    return (
      <Ranks
        key={index}
        type={RanksType.COMBINE}
        playerId={player && player.id}
        teamId={nflTeam && nflTeam.id}
        position={position}
        comparisonEvent={stats.event}
        combineId={event.id}
        combineCategory={event.category}
        event={getRanksEvent(stats)}
        values={[
          {
            percentile: Math.round(get(stats, 'collegeTeamStats.percentile', 0)),
            value: `${get(stats, 'collegeTeamStats.playerRank', 0)}/${get(stats, 'collegeTeamStats.playerCount', 1)}`,
          },
          {
            percentile: Math.round(get(stats, 'collegeConferenceStats.percentile', 0)),
            value: `${get(stats, 'collegeConferenceStats.playerRank', 0)}/${get(stats, 'collegeConferenceStats.playerCount', 1)}`,
          },
          {
            percentile: Math.round(get(stats, 'nflTeamStats.percentile', 0)),
            value: `${get(stats, 'nflTeamStats.playerRank', 0)}/${get(stats, 'nflTeamStats.playerCount', 1)}`,
            comparisonGroup: RanksComparisonGroup.NFL_TEAM,
          },
          {
            percentile: Math.round(get(stats, 'nflUDFAStats.percentile', 0)),
            value: `${get(stats, 'nflUDFAStats.playerRank', 0)}/${get(stats, 'nflUDFAStats.playerCount', 1)}`,
            comparisonGroup: RanksComparisonGroup.NFL_UDFA,
          },
          {
            percentile: Math.round(get(stats, 'nflDraftStats.percentile', 0)),
            value: `${get(stats, 'nflDraftStats.playerRank', 0)}/${get(stats, 'nflDraftStats.playerCount', 1)}`,
            comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
          },
        ]}
        bottomSpace
      />
    );
  }

  function renderEvent (event:PlayerCombineEvent | PlayerNFLCombineEvent) {
    const eventYear = event?.combineDate ? new Date(event.combineDate).getUTCFullYear() : 0;
    let combines: any = combineEvents?.find(combine => combine?.id == event?.id )?.combine;

    return (
      <div className={classes.event}>
        {(event.type?.toLowerCase().includes('nfl') && event.type?.toLowerCase().includes('combine')) && (
          <img
            className={classes.eventLogo}
            src='/images/nfl-combine.png'
            alt='NFL Scouting Combine'
          />
        )}

        <table className={classes.eventInfo}>
          <tbody>
            {
            !["Under Armour", "ESPN", "Elite 11"].includes(event.type)
            ? <tr className={classes.eventInfoRow}>
                <td className={classes.eventInfoName}>Event</td>
                <td className={classes.eventInfoValue}>{event.type}</td>
              </tr>
             : null
            }

            {!!event.city && (
              <tr className={classes.eventInfoRow}>
                <td className={classes.eventInfoName}>City</td>
                <td className={classes.eventInfoValue}>{event.city}</td>
              </tr>
            )}

            {eventYear >= MIN_EVENT_YEAR && (
              <tr className={classes.eventInfoRow}>
                <td className={classes.eventInfoName}>Year</td>
                <td className={classes.eventInfoValue}>{formatDate(event.combineDate, true)}</td>
              </tr>
            )}

            {!!event.height && (
              <tr className={classes.eventInfoRow}>
                <td className={classes.eventInfoName}>Height</td>
                <td className={classes.eventInfoValue}>{inchesToFeetAndInches(event.height)}</td>
              </tr>
            )}

            {!!event.weight && (
              <tr className={classes.eventInfoRow}>
                <td className={classes.eventInfoName}>Weight</td>
                <td className={classes.eventInfoValue}>{event.weight} lbs.</td>
              </tr>
            )}
          <div className={ clsx(event?.city?.length > 20 && classes.hsScorePositionCheckMarkShift, combines?.length ? classes.hsScorePosition : classes.hsScorePositionCheckMark)}> 
          <Score
              className={classes.playerScore}
              type={SCORE_TYPE.COMBINE}
              position={position}
              scoreList={combines || []}
              showCheckMarkExplict= {!combines?.length || false}
            />

            <div>
               {
                combineEvents?.find(combine => combine?.id == event?.id )?.combineHasFreshmanData && (
                  <Tooltip title='Freshman Data'>
                    <FreshmanIcon className={classes.freshmanIcon} />
                  </Tooltip>
                )
               }
            </div>
          </div>
          </tbody>
        </table>
      </div>
    );
  }
  const college = (player?.playerColleges || [])
    .find((college:College) => college && college.isPrimary);
  const highSchool = (player?.highSchools || [])
    .find((highSchool:HighSchool) => !!highSchool?.isPrimary);
  const recruitingClass = college?.recruitingClass || highSchool?.graduatingClass;

  return (
    <NamedBox
      className={clsx(classes.playerCombineData, className)}
      headerClassName={clsx(classes.header, hideHeader && classes.hideHeader)}
      name={`HS Combine Data - ${position}`}
    >
      <Loader inProgress={loading} />

      {!!combineEvents.length && (
        combineEvents.map((event:PlayerCombineEvent | PlayerNFLCombineEvent, index:number) => (
          <Accordion
            key={index}
            summary={`${["Under Armour", "ESPN", "Elite 11"].includes(event.type) ? 'Combine' : event.type }`}
            expanded={index === 0 || index === 1}
          >
            {renderEvent(event)}

            {!isNFL && (
              <Ranks
                headerItems={[
                  { title: 'HS Class', subtitle: `(${recruitingClass})` },
                  { title: 'Division-I', subtitle: '(Since 2010)' },
                  { logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt },
                  { logo: get(collegeTeam, 'conference.iconUrl', '') },
                  { logo: '/images/nfl-draft.png' },
                ]}
              />
            )}

            {isNFL && (
              <Ranks
                headerItems={[
                  {
                    logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt,
                    subtitle: '(Since 2010)',
                  },
                  {
                    logo: get(collegeTeam, 'conference.iconUrl', ''),
                    subtitle: '(Since 2010)',
                  },
                  { logo: nflTeam?.logo247 || nflTeam?.logoESPN || nflTeam?.logoAlt },
                  { title: 'NFL', subtitle: 'UDFA' },
                  { logo: '/images/nfl-draft.png' },
                ]}
              />
            )}

            {(event.eventStats && !!event.eventStats.length) && (
              (event.eventStats as any[])
                .filter((stats:any) => !!stats.performance)
                .map((stats:any, index:number) => (
                  isNFL
                    ? renderNFLEvents(stats as PlayerNFLCombineStats, event, index)
                    : renderCollegeEvents(stats as PlayerCombineStats, event, index)
                  )
                )
            )}
          </Accordion>
        ))
      )}
    </NamedBox>
  );
}
