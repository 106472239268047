import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Score, { getScoreValue, SCORE_TYPE } from '../atoms/Score';
import Avatar from '../atoms/Avatar';
import Stars from '../atoms/Stars';
import MultiSportList from './MultiSportList';
import { getPrimaryCollege, getPrimaryHighSchool, getPrimaryNFLTeam, getPrimaryPosition, PlayerType } from '../services/player';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import ComparePlayer, { ComparePlayerType } from '../types/ComparePlayer';

interface TopPlayerCardProps {
  className?: string;
  index: number;
  player: ComparePlayer;
  comparePlayerType: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  topPlayerCard: {
    width: 'auto',
    minWidth: '270px',
    borderRadius: 0,
  },

  header: {
    height: '60px',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: COLOR_BACKGROUND_LIGHT,
    position: 'relative',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  rank: {
    lineHeight: 1,
    textAlign: 'center',
  },
  avatar: {
    width: '64px',
    height: '64px',
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  playerLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },
  percentile: {},

  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    background: COLOR_WHITE,
  },

  playerInfo: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textInfo: {
    marginTop: theme.spacing(.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },

  playerStats: {
    display: 'flex',
    maxWidth: '220px',
    margin: '30px auto 0 auto',
    justifyContent: 'space-between',
  },
  centeredScores: {
    '& $playerStats': {
      justifyContent: 'center',
    },
  },
  playerScore: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
  },
  playerScoreNumber: {
    marginLeft: theme.spacing(1),
  },

  stars: {
    height: '32px',
    alignItems: 'center',
  },

  multiSport: {
    height: '32px',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  multiSportIcon: {
    width: '26px',
    height: '26px',
    opacity: '0.5',
  },
}), { name: TopPlayerCard.name });

export default function TopPlayerCard (props:TopPlayerCardProps) {
  const {
    className,
    index,
    player,
    comparePlayerType,
  } = props;
  const classes = useStyles();

  const primaryHS = getPrimaryHighSchool(player);
  const primaryCollege = getPrimaryCollege(player);
  const primaryNFLTeam = getPrimaryNFLTeam(player);

  let city:string = primaryHS?.city || '';
  let state:string = primaryHS?.state || '';
  let classYear = primaryHS?.graduatingClass || '';
  let position = getPrimaryPosition(player, PlayerType.HIGH_SCHOOL) || '';
  let teamName = '';

  if (comparePlayerType === ComparePlayerType.NFL) {
    teamName = primaryNFLTeam?.team?.name || '';
    classYear = primaryNFLTeam?.draftYear || '';
    position = getPrimaryPosition(player, PlayerType.NFL) || '';
  } else if (comparePlayerType === ComparePlayerType.COLLEGE) {
    teamName = primaryCollege?.team?.name || '';
    classYear = primaryCollege?.recruitingClass || '';
    position = getPrimaryPosition(player, PlayerType.COLLEGE) || '';
  }

  const address:string = city && state ? `${city}, ${state}` : (city || state);
  const centeredScores = !player.pai || !player.combine;
  const noPAIScore = !player?.pai?.length || typeof getScoreValue(player.pai) !== 'number';
  const noCombineScore = !player?.hsCombines?.length;
  const noScores = noPAIScore && noCombineScore;
  const displayMultiSport = noScores && player?.multiSport?.length > 0;

  return (
    <Card className={clsx(
      classes.topPlayerCard,
      centeredScores && classes.centeredScores,
      className,
    )}>
      <div className={classes.header}>
        <div className={classes.rank}>RANK<br/><b>#{index + 1}</b></div>

        <Avatar
          className={classes.avatar}
          src={player.photoUrl}
          alt={`Photo of ${player.firstName} ${player.lastName}`}
        />

        <div className={classes.percentile}>
          <b>{Math.round(player.percentMatch * 100)}%</b>
        </div>
      </div>

      <CardContent className={classes.content}>
        <div className={classes.playerInfo}>
          <Link
            className={classes.playerLink}
            to={`/player/${player.slug}?`}
            target='_blank'
          >
            {player.firstName} {player.lastName}
          </Link>

          <div className={classes.textInfo}>
            {position}
            {(position && classYear) ? ` | `: ''}
            {classYear}
            {comparePlayerType === ComparePlayerType.HIGH_SCHOOL && (
              <>
                {((position || classYear) && address) ? ` | `: ''}
                {address}
              </>
            )}
            {comparePlayerType !== ComparePlayerType.HIGH_SCHOOL && (
              <>
                {((position || classYear) && !!teamName) ? ` | `: ''}
                {teamName}
              </>
            )}
          </div>
        </div>

        <div className={classes.playerStats}>
          {!noPAIScore && (
            <div className={classes.playerScore}>
              PAI:
              <Score
                className={classes.playerScoreNumber}
                type={SCORE_TYPE.PAI}
                scoreList={player.pai}
              />
            </div>
          )}

          {!noCombineScore && (
            <div className={classes.playerScore}>
              Combine:
              <Score
                className={classes.playerScoreNumber}
                type={SCORE_TYPE.COMBINE}
                scoreList={player.combine}
                combines={player.hsCombines}
              />
            </div>
          )}

          {(noScores && (typeof player?._247Star === 'number' || typeof player?.compStar === 'number')) && (
            <Stars
              className={classes.stars}
              value={player?._247Star || player?.compStar || 0}
            />
          )}

          {displayMultiSport && (
            <MultiSportList
              className={classes.multiSport}
              iconClassName={classes.multiSportIcon}
              list={player.multiSport}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
