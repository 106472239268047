import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ClassIcon from '../icons/ClassIcon';
import PositionIcon from '../icons/PositionIcon';
import RulerVerticalIcon from '../icons/RulerVerticalIcon';
import ScaleIcon from '../icons/ScaleIcon';
import RulerHorizontalIcon from '../icons/RulerHorizontalIcon';
import NFLTeamIcon from '../icons/NFLTeamIcon';
import LocationIcon from '../icons/LocationIcon';
import HandIcon from '../icons/HandIcon';
import LinkIcon from '../icons/LinkIcon';
import AllConferenceIcon from '../icons/AllConferenceIcon';
import AllAmericanIcon from '../icons/AllAmericanIcon';
import WatchListIcon from '../icons/WatchListIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import TeamLogo from '../atoms/TeamLogo';
import WatchList from '../molecules/WatchList';
import Links from '../molecules/Links';
import { inchesToFeetAndInches, datetimeToDate } from '../services/converter';
import { isValidDOB, getMostRecentDataByProperty, prepareLinks } from '../services/player';
import { COLOR_BLUE, COLOR_DARK_BLUE, COLOR_DARK_GRAY, COLOR_MIDDLE_GRAY } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Player from '../types/Player';
import Position from '../types/Position';
import HighSchoolCombine from '../types/HighSchoolCombine';
import Team from '../types/Team';
import NFLTeam from '../types/NFLTeam';
import College from '../types/College';
import User from '../types/User';
import NFLCombine from '../types/NFLCombine';

interface PlayerProfileNFLOverviewProps {
  className?: string;
  player?: Player;
  team?: Team;
  selectedPosition?: Position;
  user: User | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileNFLOverview: {},

  divider: {
    width: '1px',
    height: '30px',
    margin: theme.spacing(0, 2.5),
    background: COLOR_MIDDLE_GRAY,
  },

  links: {
    padding: theme.spacing(1, 0),
    height: 'auto',
    alignItems: 'center',
    overflow: 'visible',
  },

  committedTo: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2),
  },
  committedToLink: {
    color: COLOR_BLUE,
    textDecoration: 'underline',
    transition: 'color 0.3s',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  draftedBy: {
    marginLeft: theme.spacing(1),
    color: COLOR_DARK_GRAY,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: 1,
  },
  draftValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 700,
    lineHeight: 1,

    '& > span' : {
      borderBottom: `1px solid`,
    }
  },

  progressLine: {
    maxWidth: '350px',
    marginLeft: 'auto',
  },
}), { name: PlayerProfileNFLOverview.name });

export default function PlayerProfileNFLOverview (props: PlayerProfileNFLOverviewProps) {
  const {
    className,
    player,
    selectedPosition,
    user,
  } = props;
  const classes = useStyles();

  const primaryNFLTeam = (player?.playerNFLTeams || [])
    .find((nflTeam:NFLTeam) => !!nflTeam?.isPrimary);
  const nflCombinesWeight = (player?.nflCombines || [])
    .reduce((weight:number, nflCombine:NFLCombine) => {
      return nflCombine?.weight
        ? (nflCombine?.weight > weight ? nflCombine?.weight : weight)
        : weight;
    }, 0);
  const nflCombinesHeight = (player?.nflCombines || [])
    .reduce((height:number, nflCombine:NFLCombine) => {
      return nflCombine?.height
        ? (nflCombine?.height > height ? nflCombine?.height : height)
        : height;
    }, 0);

  const college = ((player && player.playerColleges) || [])
    .find((college:College) => !!college?.isPrimary);

  const highSchoolCombines:HighSchoolCombine[] = (player && player.hsCombines) || [];
  const arm = (highSchoolCombines.find(combine => combine && !!combine.arm) || {}).arm;
  const hand = (highSchoolCombines.find(combine => combine && !!combine.hand) || {}).hand;
  const wingspan = (highSchoolCombines.find(combine => combine && !!combine.wingspan) || {}).wingspan;

  const nflCombines:NFLCombine[] = (player && player.nflCombines) || [];
  const nflWingspan = getMostRecentDataByProperty(nflCombines, 'wingspan');
  const nflArm = getMostRecentDataByProperty(nflCombines, 'arm');
  const nflHand = getMostRecentDataByProperty(nflCombines, 'hand');


  const nflTeamWeight = primaryNFLTeam?.weight;
  const weight = nflTeamWeight || nflCombinesWeight || college?.weight || null;

  const nflTeamHeight = primaryNFLTeam?.height;
  const height = nflTeamHeight || nflCombinesHeight || college?.height || null;

  const dob = !!player?.dob && datetimeToDate(player.dob);
  const links = prepareLinks(player);

  return (
    <NamedBox
      className={clsx(classes.playerProfileNFLOverview, className)}
      name='Overview'
    >
      <ListItem
        icon={ClassIcon}
        title='Draft Year:'
      >
        {primaryNFLTeam?.draftYear && (
          <div><b>{primaryNFLTeam?.draftYear}</b></div>
        )}

        {typeof primaryNFLTeam?.draftRound === 'number' && primaryNFLTeam?.draftRound !== 0 && (
          <>
            <div className={classes.divider} />
            <div className={classes.draftValue}>
              <span>Rd</span>
              <b>{primaryNFLTeam.draftRound}</b>
            </div>
            <div className={classes.divider} />
            <div className={classes.draftValue}>
              <span>Pick</span>
              <b>{primaryNFLTeam.draftPick}</b>
            </div>
          </>
        )}

        {(primaryNFLTeam && primaryNFLTeam.draftRound === 0) && (
          <>
            <div className={classes.divider} />
            <div><b>UDFA</b></div>
          </>
        )}

        {dob && isValidDOB(dob) && (
          <>
            <div className={classes.divider} />
            <div>DOB: <b>{dob}</b></div>
          </>
        )}
      </ListItem>

      {selectedPosition && (
        <ListItem
          icon={PositionIcon}
          title='Position:'
        >
          <div><b>{selectedPosition}</b></div>
        </ListItem>
      )}

      {primaryNFLTeam?.team?.name && (
        <ListItem
          icon={NFLTeamIcon}
          title='NFL Team:'
        >
          <TeamLogo
            className={classes.committedTo}
            team={primaryNFLTeam.team}
          />

          {user?.accessLevel?.accessNFLTeamPage
            ? <Link
                to={`/team/${primaryNFLTeam.team.slug}`}
                className={classes.committedToLink}
              >
                <b>{primaryNFLTeam.team.name}</b>
              </Link>
            : <b>{primaryNFLTeam.team.name}</b>
          }

          <span className={classes.draftedBy}>(Drafted By)</span>
        </ListItem>
      )}

      {(primaryNFLTeam?.team && (primaryNFLTeam.team.city || primaryNFLTeam.team.state)) && (
        <ListItem
          icon={LocationIcon}
          title='Location:'
        >
          <b>{primaryNFLTeam.team.city}, {primaryNFLTeam.team.state}</b>
        </ListItem>
      )}

      {!!height && (
        <ListItem
          icon={RulerVerticalIcon}
          title={nflTeamHeight ? 'Combine Height:' : 'Team Height:'}
        >
          <b>{inchesToFeetAndInches(height)}</b>
        </ListItem>
      )}

      {!!weight && (
        <ListItem
          icon={ScaleIcon}
          title={nflTeamWeight ? 'Combine Weight:' : 'Team Weight:'}
        >
          <b>{`${weight} lbs.`}</b>
        </ListItem>
      )}

      {!!nflArm && (
        <ListItem
          icon={RulerHorizontalIcon}
          title='Combine Arm:'
        >
          <b>{nflArm}</b>
        </ListItem>
      )}

      {!!nflHand && (
        <ListItem
          icon={HandIcon}
          title='Combine Hand:'
        >
          <b>{nflHand}</b>
        </ListItem>
      )}

    {!!nflWingspan && (
        <ListItem
          icon={RulerHorizontalIcon}
          title='Wingspan:'
        >
          <b>{nflWingspan}</b>
        </ListItem>
    )}

      {!!player?.proBowl?.length && (
        <ListItem
          icon={AllConferenceIcon}
          title='Pro Bowl:'
        >
          <b>{player.proBowl.join(', ')}</b>
        </ListItem>
      )}

      {!!player?.allPro?.length && (
        <ListItem
          icon={AllAmericanIcon}
          title='All Pro:'
        >
          <b>{player.allPro.join(', ')}</b>
        </ListItem>
      )}

      {!!player?.watchList?.length && (
        <ListItem
          icon={WatchListIcon}
          title='Watch List:'
        >
          <WatchList list={player.watchList} />
        </ListItem>
      )}

      {!!links?.length && (
        <ListItem
          contentClassName={classes.links}
          icon={LinkIcon}
          title='Links:'
        >
          <Links links={links} />
        </ListItem>
      )}
    </NamedBox>
  );
}
//
