 import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import ExpandIcon from '../icons/ExpandIcon';
import CompareIcon from '../icons/CompareIcon';
import PDFIcon from '../icons/PDFIcon';
import PinIcon from '../icons/PinIcon';
import AlertIcon from '../icons/AlertIcon';
import SaveIcon from '../icons/SaveIcon';
import FreshmanIcon from '../icons/FreshmanIcon';
import Avatar from '../atoms/Avatar';
import Tooltip from '../atoms/Tooltip';
import TeamLogo from '../atoms/TeamLogo';
import CloseButton from '../atoms/CloseButton';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Action from '../atoms/Action';
import NotAvailable from '../atoms/NotAvailable';
import PowerRecruitIcon from '../icons/PowerRecruitIcon';
import SpeedRecruitIcon from '../icons/SpeedRecruitIcon';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import MultiSportList from './MultiSportList';
import gql from '../services/gql';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { inchesToFeetAndInches } from '../services/converter';
import { State } from '../redux/reducers';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_WHITE
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import Player from '../types/Player';
import HighSchool from '../types/HighSchool';
import User from '../types/User';
import College from '../types/College';
import NFLTeam from '../types/NFLTeam';
import { RECRUIT_TOOLTIP } from '../organisms/PlayerTrackAndFieldRating';
import { RECRUIT_TOOLTIP as COMBINE_RECRUIT_TOOLTIP } from '../organisms/PlayerCombineRating';

interface PlayerPopoverProps {
  anchorEl: HTMLElement | null;
  isNFL?: boolean;
  playerId: string | number;
  user: User;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerPopover: {
    position: 'relative'
  },
  paper: {
    width: 'calc(100% - 32px)',
    maxWidth: '556px',
    borderRadius: '12px',
  },

  header: {
    height: '140px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    background: COLOR_BACKGROUND_LIGHT,
    position: 'relative',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },
  offers: {
    position: 'absolute',
    minHeight: '30px',
    left: theme.spacing(6),
    bottom: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  offersNumber: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    background: COLOR_ORANGE,
    color: COLOR_WHITE,
    borderRadius: '35px',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  committed: {
    position: 'absolute',
    minHeight: '30px',
    right: theme.spacing(6),
    bottom: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  committedLogo: {
    width: '32px',
    height: '32px',
    marginLeft: theme.spacing(1),
  },
  teamNotAvailable: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    width: '128px',
    height: '128px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    pointerEvents: 'all',
  },

  contentWrap: {
    padding: theme.spacing(10, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: COLOR_WHITE,
    position: 'relative',
  },

  leftScore: {
    position: 'absolute',
    left: '46px',
    top: theme.spacing(5),
    display: 'grid',
    alignItems: 'center',
  },
  paiScore1: {
    display: 'flex',
    alignItems: 'center',
  },
  recruitIcons1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '46px',
    paddingLeft: '6px'
  },
  ppiScore1: {
    display: 'flex',
    alignItems: 'center',
  },
  rightScore: {
    position: 'absolute',
    right: '32px',
    top: theme.spacing(5),
    display: 'grid',
    alignItems: 'center',
  },
  combineScore1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  proCombineScore1: {
    display: 'flex',
    alignItems: 'center',
  },
  recruitCombineIcons1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: '46px'
  },
  
  scoreWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  paiScoreNumber: {
    marginLeft: theme.spacing(1),
  },
  combineScoreNumber: {
    marginLeft: theme.spacing(1),
  },

  freshmanIcon: {
    width: 32,
    height: 32,
    cursor: 'help',
  },
  paiFreshmanIcon: {
    color: COLOR_ORANGE,
  },
  combineFreshmanIcon: {
    color: COLOR_GREEN,
  },

  nameLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      '& $viewPlayerIcon': {
        transform: 'scale(1.15)',
      },
    },
  },
  name: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.2,
    fontWeight: 600,
    textDecoration: 'underline',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  viewPlayerIcon: {
    flexShrink: 0,
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(1),
    color: COLOR_DARK_GRAY,
    transition: 'transform 0.3s',
  },

  multiSport: {
    height: '24px',
    marginTop: theme.spacing(3),
    maxWidth: '100',
  },

  infoList: {
    width: '100%',
    marginBottom: 0,
    padding: 0,
    marginTop: theme.spacing(3),
    listStyle: 'none',
  },
  infoListItem: {
    display: 'flex',
    marginTop: '5px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,

    '&:first-child': {
      marginTop: 0,
    },
  },
  infoListItemName: {
    width: '50%',
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  infoListItemValue: {
    width: '50%',
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
  },

  actions: {
    height: '48px',
    display: 'flex',
  },
  action: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(0, 1.5),
    borderRight: `1px solid ${COLOR_BORDER}`,
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '&:last-child': {
      paddingRight: theme.spacing(2),
      borderRight: 0,
    },

    '&:hover': {
      background: COLOR_BACKGROUND_LIGHT,
      textDecoration: 'none',

      '& $actionIcon': {
        color: COLOR_DARK_GRAY,
      },
      '& $actionText': {
        color: COLOR_TEXT,
      },
    },
  },
  actionIcon: {
    flexShrink: 0,
    width: '18px',
    height: '18px',
  },
  actionText: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(16),
  },

  [MEDIA.MOBILE]: {
    paper: {
      maxWidth: '400px',
    },
    header: {
      fontSize: theme.typography.pxToRem(14),
    },
    offers: {
      left: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
    },
    committed: {
      right: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
    },
    avatar: {
      width: '80px',
      height: '80px',
    },
    paiScore: {
      left: theme.spacing(2),
    },
    combineScore: {
      right: theme.spacing(2),
    },
    contentWrap: {
      padding: theme.spacing(12,2,2,2),
    },
    actionText: {
      display: 'none',
    },
    actionIcon: {
      marginRight: 0,
    },
  },
}), { name: PlayerPopover.name });

function PlayerPopover (props:PlayerPopoverProps) {
  const classes = useStyles();
  const {
    playerId,
    isNFL,
    anchorEl,
    user,
    onClose,
  } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [player, setPlayer] = useState<Player>();
  const [team, setTeam] = useState<Team>();
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [hasAlert, setHasAlert] = useState<boolean>(false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open && playerId) {
      setLoading(true);

      Promise.allSettled([
        fetchPlayer(),
        checkIsPinned(),
        checkIsSavedPlayer(),
        checkPlayerHasAlert(),
      ])
      .finally(() => setLoading(false));

      setLoading(true);
    }
  }, [open, playerId]);

  useEffect(() => {
    if (open && player && player.committedToTeamId) {
      fetchTeam(player.committedToTeamId);
    }
  }, [open, player]);

  function fetchPlayer () {
    return gql(`
      player (id: ${playerId}) {
        id
        slug
        firstName
        lastName
        photoUrl
        pai
        combine
        highSchools
        aTierCalculated
        bTierCalculated
        combineSpeedTier
        combinePowerTier
        nflCombineScore
        nflCombines {
          id
        }
        ppi
        playerColleges {
          isPrimary
          weight
          height
          positions
          team {
            name
          }
        }
        playerNFLTeams {
          isPrimary
          weight
          height
          positions
          team {
            name
          }
        }
        offers
        committedToTeamId
        multiSport
        hsCombines {
          id
        }
        paiHasFreshmanData
        combineHasFreshmanData
      }
    `)
      .then((data:any) => data.player as Player)
      .then((player:Player) => setPlayer(player))
      .catch(console.error);
  }

  function fetchTeam(teamId:number) {
    setLoading(true);

    gql(`
      team (id: ${teamId}) {
        name
        logo247
        logoESPN
        logoAlt
      }
    `)
      .then((data:any) => data.team as Team)
      .then((team:Team) => setTeam(team))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function checkIsPinned () {
    return gql(`
      isPinned (playerId: ${playerId})
    `)
      .then((data: any) => data && data.isPinned as boolean)
      .then((success: boolean) => setIsPinned(success))
      .catch(console.error);
  }

  function checkIsSavedPlayer () {
    return gql(`
      isSavedPlayer (playerId: ${playerId})
    `)
      .then((data:any) => data.isSavedPlayer as boolean)
      .then((isSaved:boolean) => setIsSaved(isSaved))
      .catch(console.error);
  }

  function checkPlayerHasAlert () {
    return gql(`
      hasAlert (playerId: ${playerId})
    `)
      .then((data:any) => data.hasAlert as boolean)
      .then((hasAlert:boolean) => setHasAlert(hasAlert))
      .catch(console.error);
  }

  function pinPlayer() {
    setLoading(true);

    return gql(`
      mutation {
        pin (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        setIsPinned(success);

        if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function unpinPlayer() {
    setLoading(true);

    return gql(`
      mutation {
        unpin (playerIds: [${playerId}])
      }
    `)
      .then((data: any) => data && data.unpin as boolean)
      .then((success: boolean) => setIsPinned(!success))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function savePlayer() {
    setLoading(true);

    return gql(`
      mutation {
        savePlayers (playerIds: [${playerId}], folderId: null)
      }
    `)
      .then((data:any) => data.savePlayers as boolean)
      .then((isSaved:boolean) => setIsSaved(isSaved))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function unsavePlayer() {
    setLoading(true);

    return gql(`
      mutation {
        unsavePlayers (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.unsavePlayers as boolean)
      .then((isSaved:boolean) => setIsSaved(!isSaved))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function addAlert() {
    setLoading(true);

    return gql(`
      mutation {
        addAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.addAlerts as boolean)
      .then((alertStatus:boolean) => setHasAlert(alertStatus))
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function removeAlert() {
    setLoading(true);

    return gql(`
      mutation {
        removeAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((alertRemoved:boolean) => setHasAlert(!alertRemoved))
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function generateAndDownloadPDFReport () {
    setLoading(true);

    exportPDFReport(
      PDFExportPage.PLAYER,
      player && player.slug,
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function isSpeedRecruitEnable ():boolean {
    if (player && player?.aTierCalculated >= 4) {
      return true;
    }
    return false;
  }

  function isPowerRecruitEnable ():boolean {
    if (player && player?.bTierCalculated >= 4) {
      return true;
    }
    return false;
  }

  function isSpeedRecruitEnableForCombine ():boolean {
    if (player && player?.combineSpeedTier >= 4) {
      return true;
    }
    return false;
  }
  
  function isPowerRecruitEnableForCombine ():boolean {
    if (player && player?.combinePowerTier >= 4) {
      return true;
    }
    return false;
  }

  function renderSpeedPowerIcon (isCombine?: boolean) {
    return (<>
      {
        isCombine
        ? <>
          {isSpeedRecruitEnableForCombine() && (
              <Tooltip title={COMBINE_RECRUIT_TOOLTIP.SPEED}>
                <SpeedRecruitIcon padding="4px" className={'green'}/>
              </Tooltip>
            )}

            {isPowerRecruitEnableForCombine() && (
              <Tooltip title={COMBINE_RECRUIT_TOOLTIP.POWER}>
                <PowerRecruitIcon padding="4px" className={'green'} />
              </Tooltip>
            )}
        </>
        : <>
          {isSpeedRecruitEnable() && (
            <Tooltip title={RECRUIT_TOOLTIP.SPEED}>
              <SpeedRecruitIcon padding="4px"/>
            </Tooltip>
          )}

          {isPowerRecruitEnable() && (
            <Tooltip title={RECRUIT_TOOLTIP.POWER}>
              <PowerRecruitIcon padding="4px" />
            </Tooltip>
          )}
        </>
      }
    </>
    )
  }

  const primarySchool = (player?.highSchools || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  const primaryHSPosition = ((primarySchool?.positions || [])
    .find((position:any) => position && position.isPrimary) || {}).code || undefined;
  const hsWeight = primarySchool?.weight;
  const hsHeight = primarySchool?.height || 0;

  const primaryCollege = (player?.playerColleges || [])
    .find((college:College) => college && college.isPrimary);
  const primaryCollegePosition = ((primaryCollege?.positions || [])
    .find((position:any) => position && position.isPrimary) || {}).code || undefined;
  const collegeWeight = primaryCollege?.weight;
  const collegeHeight = primaryCollege?.height || 0;

  const primaryNFLTeam = (player?.playerNFLTeams || [])
    .find((nflTeam:NFLTeam) => nflTeam && nflTeam.isPrimary);
  const primaryNFLPosition = ((primaryNFLTeam?.positions || [])
    .find((position:any) => position && position.isPrimary) || {}).code || undefined;
  const nflWeight = primaryNFLTeam?.weight;
  const nflHeight = primaryNFLTeam?.height || 0;

  const primaryPosition = primaryNFLPosition || primaryCollegePosition || primaryHSPosition;
  const isCollegeNflPlayer = (primaryCollege || primaryNFLTeam) ? true : false;
  const playerHasColleges = player && player.playerColleges && player.playerColleges.length > 0;
  const playerHasNFLTeams = player && player.playerNFLTeams && player.playerNFLTeams.length > 0;

  return (
    <>
      <Popover
        className={classes.playerPopover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <div className={classes.offers}>
            Offers:
            <span className={classes.offersNumber}>
            {((player && player.offers) || []).length}
          </span>
          </div>

          <div className={classes.committed}>
            Committed:
            {team
              ? <TeamLogo
                  className={classes.committedLogo}
                  team={team}
                />
              : <NotAvailable className={classes.teamNotAvailable} />
            }
          </div>

          <CloseButton
            className={classes.closeButton}
            onClick={onClose}
          />
        </div>

        {player && (
          <Avatar
            className={classes.avatar}
            src={player.photoUrl}
            initials={`${(player.firstName || ' ')[0]}${(player.lastName || ' ')[0]}`.trim()}
            alt='Profile picture'
          />
        )}

        <div className={classes.contentWrap}>
          <div className={classes.leftScore}>
              <div className={classes.paiScore1}>
                  PAI:
                  {player && (
                    <div className={classes.scoreWrapper}>
                      <Score
                        className={classes.paiScoreNumber}
                        type={SCORE_TYPE.PAI}
                        scoreList={player.pai}
                        position={primaryPosition}
                      />

                      <div style={{width: '20px'}}>
                          {player.paiHasFreshmanData && (
                            <Tooltip title='Freshman Data'>
                              <FreshmanIcon className={clsx(classes.freshmanIcon, classes.paiFreshmanIcon)} />
                            </Tooltip>
                          )}
                      </div>
                    </div>
                  )}
                </div>

                <div className={classes.recruitIcons1}>
                  {player && (
                    <div className={classes.scoreWrapper}>
                        {renderSpeedPowerIcon()}
                    </div>
                  )}
                </div>

                {
                  isCollegeNflPlayer && (
                    <>
                      <div className={classes.ppiScore1}>
                      PPI:
                      {player && (
                        <div className={classes.scoreWrapper}>
                          <Score
                            className={classes.paiScoreNumber}
                            type={SCORE_TYPE.PPI}
                            value={player?.ppi || undefined}
                          />
                        </div>
                      )}
                    </div>
                    </>
                  )
                }

          </div>

          <div className={classes.rightScore}>
            <div className={classes.combineScore1}>
              <span>
                {isCollegeNflPlayer && (<>HS</>)} Combine:
              </span>
              {player && (
                <div className={classes.scoreWrapper}>
                  <Score
                    className={classes.combineScoreNumber}
                    type={SCORE_TYPE.COMBINE}
                    scoreList={player.combine}
                    combines={player.hsCombines}
                    position={primaryPosition}
                  />

                  <div style={{width: '20px'}}>
                    {player.combineHasFreshmanData && (
                      <Tooltip title='Freshman Data'>
                        <FreshmanIcon className={clsx(classes.freshmanIcon, classes.combineFreshmanIcon)} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className={classes.recruitCombineIcons1} style={{paddingRight: isSpeedRecruitEnable() && isPowerRecruitEnable() ? '6px' : '20px'}}>
              {player && (
                <div className={classes.scoreWrapper}>
                    {renderSpeedPowerIcon(true)}
                </div>
              )}
            </div>
            {
              isCollegeNflPlayer && (
                <>
                  <div className={classes.proCombineScore1}>
                    Pro Combine:
                      {player && (
                        <div className={classes.scoreWrapper}>
                          <Score
                            className={classes.paiScoreNumber}
                            type={SCORE_TYPE.PRO_COMBINE}
                            value={player?.nflCombineScore || undefined}
                            combines={player.nflCombines}
                          />
                        </div>
                      )}

                      <div style={{width: '20px'}}></div>
                    </div>
                </>
              )
            }

          </div>

          {player && (
            <Link
              className={classes.nameLink}
              to={`/player/${player.slug}?`}
              target='_blank'
            >
              <span className={classes.name}>
                {player.firstName} {player.lastName}
              </span>

              <ExpandIcon className={classes.viewPlayerIcon} />
            </Link>
          )}

          {!!player?.multiSport?.length && (
            <MultiSportList
              className={classes.multiSport}
              list={player.multiSport}
            />
          )}

          <ul className={classes.infoList}>
            {(primarySchool && primarySchool.graduatingClass) && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>Class:</div>
                <div className={classes.infoListItemValue}>{primarySchool.graduatingClass}</div>
              </li>
            )}

            {primaryPosition && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>Position:</div>
                <div className={classes.infoListItemValue}>{primaryPosition}</div>
              </li>
            )}

            {(nflHeight || collegeHeight || hsHeight) && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>{nflHeight ? 'NFL' : (collegeHeight ? 'College' : (hsHeight ? 'HS' : ''))} Height:</div>
                {/* <div className={classes.infoListItemValue}>{(nflHeight || collegeHeight || hsHeight)}</div> */}
                <div className={classes.infoListItemValue}>{inchesToFeetAndInches((nflHeight || collegeHeight || hsHeight))}</div>
              </li>
            )}

            {(nflWeight || collegeWeight || hsWeight) && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>{nflWeight ? 'NFL' : (collegeWeight ? 'College' : (hsWeight ? 'HS' : ''))} Weight:</div>
                <div className={classes.infoListItemValue}>{nflWeight || collegeWeight || hsWeight}</div>
              </li>
            )}

            {(primaryNFLTeam?.team?.name || primaryCollege?.team?.name || primarySchool?.name) && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>{primaryNFLTeam?.team?.name ? 'NFL Team' : (primaryCollege?.team?.name ? 'College' : (primarySchool?.name ? 'School' : ''))}:</div>
                <div className={classes.infoListItemValue}>{primaryNFLTeam?.team?.name || primaryCollege?.team?.name || primarySchool?.name}</div>
              </li>
            )}

            {(primarySchool && (primarySchool.city || primarySchool.state)) && (
              <li className={classes.infoListItem}>
                <div className={classes.infoListItemName}>Hometown:</div>
                <div className={classes.infoListItemValue}>
                  {primarySchool.city}, {primarySchool.state}
                </div>
              </li>
            )}
          </ul>
        </div>

        <Divider variant='fullWidth' light />

        <div className={classes.actions}>
          <Action
            className={classes.action}
            icon={PinIcon}
            iconClassName={classes.actionIcon}
            disabled={loading}
            onClick={isPinned ? unpinPlayer : pinPlayer}
          >
            <span className={classes.actionText}>{isPinned ? 'Unpin' : 'Pin'}</span>
          </Action>

          <Action
            className={classes.action}
            icon={PDFIcon}
            iconClassName={classes.actionIcon}
            disabled={loading}
            onClick={generateAndDownloadPDFReport}
          >
            <span className={classes.actionText}>Report</span>
          </Action>

          {user?.accessLevel?.canComparePlayers && (
            <Action
              className={classes.action}
              icon={CompareIcon}
              iconClassName={classes.actionIcon}
              disabled={loading}
              href={player?.slug ? `/compare/${player.slug}` : '/compare'}
            >
              <span className={classes.actionText}>Compare</span>
            </Action>
          )}

          <Action
            className={classes.action}
            icon={SaveIcon}
            iconClassName={classes.actionIcon}
            disabled={loading}
            onClick={isSaved ? unsavePlayer : savePlayer}
          >
            <span className={classes.actionText}>{isSaved ? 'Unsave' : 'Save'}</span>
          </Action>

          {!isNFL && !(playerHasColleges || playerHasNFLTeams) && (
            <Action
              className={classes.action}
              icon={AlertIcon}
              iconClassName={classes.actionIcon}
              disabled={loading}
              onClick={hasAlert ? removeAlert : addAlert}
            >
              <span className={classes.actionText}>{hasAlert ? 'Remove Alert' : 'Alert'}</span>
            </Action>
          )}
        </div>
      </Popover>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(PlayerPopover);
