import React, { useEffect, useState, createRef } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '../icons/SaveIcon';
import AddUserIcon from '../icons/AddUserIcon';
import RemoveIcon from '../icons/RemoveIcon';
import SearchIcon from '../icons/SearchIcon';
import CheckIcon from '../icons/CheckIcon';
import Checkbox from '../atoms/Checkbox';
import TableHeadCell from '../atoms/TableHeadCell';
import Action from '../atoms/Action';
import Pagination from '../atoms/Pagination';
import Loader from '../atoms/Loader';
import { ToastType } from '../atoms/Toast';
import StagedPlayerTableRow, {
  TABLE_COLUMNS,
  TRACK_EVENT_COLUMNS,
  StagedPlayerColumn,
  ColumnConfiguration,
} from '../molecules/StagedPlayerTableRow';
import CreateStagedPlayerModal from '../molecules/CreateStagedPlayerModal';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import StagingQuickFilter, { QuickFilter } from '../molecules/StagingQuickFilter';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import ImportFiltersDrawer from './ImportFiltersDrawer';
import gql, { GQL_ERROR, serializeGQLObject } from '../services/gql';
import { ScreenSize, isDesktop } from '../services/screen-size';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { allPositionsValid } from '../services/positions';
import { State } from '../redux/reducers';
import { fetchDivITeams, fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchStates } from '../redux/dispatchers/states';
import { fetchFBSConferences, fetchFCSConferences } from '../redux/dispatchers/conferences';
import {
  COLOR_TEXT,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_LIGHT_GRAY,
  COLOR_BORDER,
  COLOR_DARK_GRAY, COLOR_BLUE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import StagedPlayer from '../types/StagedPlayer';
import ValidationRule, { ValidationRuleType, TEAM_CAMP_STRING } from '../types/ValidationRule';
import StateType from '../types/State';
import Team from '../types/Team';
import Conference from '../types/Conference';
import { Order } from '../types/Order';
import HsCombineType from '../types/HsCombineType';

interface ImportPageTableProps {
  className?: string;
  isDrawerOpen: boolean;
  currentRecruitingYear: number;
  nflDraftYear: number;
  screenSize: ScreenSize;
  setIsDrawerOpen: (value: boolean) => void;
  showToast: (message:any, toastType:ToastType) => void;
  divITeams: Team[];
  divITeamsLoading: boolean;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  states: StateType[];
  statesLoading: boolean;
  refreshStagedPlayers: boolean;
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  fetchDivITeams: () => void;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  fetchStates: () => void;
  setRefreshStagedPlayers: (value:boolean) => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
}

const PUBLISH_PLAYERS_TIMEOUT = 57 * 1000;

const useStyles = makeStyles(theme => ({
  importPageTable: {
    position: 'relative',
  },

  tablesContainer: {
    overflow: 'hidden',
    position: 'relative',
  },

  fixedTableWrapper: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  stickyTableWrapper: {
    width: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: '0 10px 10px 0 rgb(0 0 0 / 5%)',
    overflow: 'hidden',
  },

  table: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },

  titleRow: {
    borderBottom: `1px solid ${COLOR_BORDER}`,
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },

  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(16),
    border: 0,
  },

  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  actions: {
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  action: {
    padding: 0,
    marginLeft: theme.spacing(2),
    fontSize: '18px',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  disabledAction: {
    outlineColor: 'transparent',
  },
  addNewPlayerAction: {
    marginLeft: 'auto',
  },

  selectedText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(0, 3),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_BLUE,
  },
  checkIcon: {
    width: 16,
    marginRight: theme.spacing(1),
  },

  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  saveIcon: {
    width: '18px',
    height: '18px',
  },

  noData: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  paginationWrapper: {
    minHeight: '24px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 0),
    position: 'relative',
    boxSizing: 'content-box',
  },
  paginationText: {
    position: 'absolute',
    left: theme.spacing(0),
    top: '50%',
    transform: 'translateY(-50%)',
  },

  dropdowns: {
    marginLeft: theme.spacing(2),
    display: 'flex',
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(1),
  },
  dropdownSelector: {
    minHeight: '24px',
  },

  quickFilter: {},

  searchWrapper: {
    width: '300px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: '300px',
    backgroundColor: COLOR_BORDER,
    border: 'none',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_PROXIMA_NOVA,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(4),
    position: 'relative',

    '&:focus': {
      border: 'none',
      outline: 'none',
    }
  },
  searchIcon: {
    height: '16px',
    width: '16px',
    color: COLOR_TEXT,
    position: 'absolute',
    left: '28px',
    marginRight: theme.spacing(1),
  },
}), { name: ImportPageTable.name });

function ImportPageTable (props:ImportPageTableProps) {
  const {
    className,
    isDrawerOpen,
    currentRecruitingYear,
    nflDraftYear,
    screenSize,
    setIsDrawerOpen,
    showToast = () => {},
    divITeams,
    divITeamsLoading,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    states,
    statesLoading,
    refreshStagedPlayers,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    fetchDivITeams,
    fetchCollegeTeams,
    fetchNFLTeams,
    fetchStates,
    setRefreshStagedPlayers,
    fetchFBSConferences,
    fetchFCSConferences,
  } = props;
  const classes = useStyles();

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.IMPORT_TABLE) || {}).currentPage || 1);
  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.IMPORT_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.IMPORT_TABLE) || {}).limit || 10);
  const [sortedByColumn, setSortedByColumn] = useState<string>(StagedPlayerColumn.FIRST_NAME);
  const [order, setOrder] = useState<Order | undefined>();

  const [selectedQuickFilter, setSelectedQuickFilter] = useState<QuickFilter>(QuickFilter.ALL);

  const [editedPlayer, setEditedPlayer] = useState<StagedPlayer>();
  const [editedColumn, setEditedColumn] = useState<StagedPlayerColumn>();
  const [saved, setSaved] = useState<boolean>(true);

  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [stagedPlayers, setStagedPlayers] = useState<StagedPlayer[]>([]);
  const [visibleStagedPlayers, setVisibleStagedPlayers] = useState<StagedPlayer[]>([]);
  const [selectedStagedPlayers, setSelectedStagedPlayers] = useState<StagedPlayer[]>([]);

  const [conferences, setConferences] = useState<Conference[]>([]);

  const [searchText, setSearchText] = useState<string>('');
  const [hsStates, setHsStates] = useState<any[]>([]);

  const [addNewPlayerModalOpen, setAddNewPlayerModalOpen] = useState<boolean>(false);
  const [stickyColumnsTotalWidth, setStickyColumnsTotalWidth] = useState<number>(0);

  const [hsCombineTypes, setHsCombineType] = useState<HsCombineType[]>([]);
  const [teamsOnly, setTeamsOnly] = useState<Team[]>([]);
  
  const stickyRefs:any[] = [
    createRef(), // for the checkbox column
    ...TABLE_COLUMNS.map(column => column.sticky ? createRef() : undefined),
  ];

  const loading = pageLoading || divITeamsLoading || collegeTeamsLoading || nflTeamsLoading
    || statesLoading || fbsConferencesLoading || fcsConferencesLoading;

  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = visibleStagedPlayers.length < limit ? visibleStagedPlayers.length : (limit * currentPage);
  const totalPages = Math.ceil(visibleStagedPlayers.length / limit);

  const playersForCurrentPage:StagedPlayer[] = visibleStagedPlayers.slice(itemsFrom, itemsTo);

  useEffect(() => {
    Promise.allSettled([
      fetchStagedPlayers(),
      fetchValidationRules(),
      fetchHsCombineTypes(),
    ])
      .finally(() => setPageLoading(false));

    fetchStates();
    fetchDivITeams();
    fetchCollegeTeams();
    fetchNFLTeams();
    fetchFBSConferences();
    fetchFCSConferences();
    fetchTeamsOnly();
  }, []);

  useEffect(() => {
    if (refreshStagedPlayers) {
      fetchStagedPlayers()
        .finally(() => {
          setPageLoading(false);
          setRefreshStagedPlayers(false);
        });
    }
  }, [refreshStagedPlayers]);

  /* Hook to reset page # when limit changes */
  useEffect (() => {
    if (limit !== prevLimit) {
      if (limit * currentPage > visibleStagedPlayers.length) {
        setCurrentPage(1);
      }
      setPrevLimit(limit);
    }
  }, [limit, prevLimit, visibleStagedPlayers, currentPage])

  useEffect(() => {
    if (visibleStagedPlayers.length && !hsStates.length) {
      setHsStates(visibleStagedPlayers.map((stagedPlayer:StagedPlayer) => ({
        content: stagedPlayer.data.hsState,
        value: stagedPlayer.data.hsState,
      })));
    }
  }, [visibleStagedPlayers, hsStates]);

  useEffect(() => {
    // calculate the total width of sticky columns to restrict the width of the sticky table
    if (!loading) {
      const stickyColumnsTotalWidth:number = stickyRefs
        .map((ref:any) => ref?.current?.offsetWidth ? ref.current.offsetWidth : 0)
        .reduce((totalWidth, currentWidth) => totalWidth + currentWidth, 0)
      + 1; // include the width of the left border

      setStickyColumnsTotalWidth(stickyColumnsTotalWidth);
    }
  }, [loading]);

  useEffect(() => {
    clientStorage.save(StorageKey.IMPORT_TABLE, { currentPage, limit });
  }, [currentPage, limit]);

  useEffect(() => {
    if (totalPages > 0 && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    setConferences([
      ...(fbsConferences?.length ? fbsConferences : []),
      ...(fcsConferences?.length ? fcsConferences : []),
    ]
      .sort((conference1:Conference, conference2:Conference) => {
        return conference1.name > conference2.name ? 1 : -1;
      })
    );
  }, [fcsConferences, fbsConferences]);

  function fetchStagedPlayers() {
    if (!pageLoading) setPageLoading(true);

    return gql(`
      stagedPlayers {
        id
        slug
        status
        data
        isAlreadyPresent
        isArchived
        hasHighSchool
        hasCollegeTeam
        hasNFLTeam
        validationErrors
        createdAt
        updatedAt
        lastPublishedDate
      }
    `,)
      .then((data:any) => data.stagedPlayers as StagedPlayer[])
      .then((stagedPlayers:StagedPlayer[]) => {
        const sortedByUpdatedDate = (stagedPlayers || [])
          .sort((a:StagedPlayer, b:StagedPlayer) => {
            const aDate = a.updatedAt ? +(new Date(a.updatedAt)) : 0;
            const bDate = b.updatedAt ? +(new Date(b.updatedAt)) : 0;

            if (aDate > bDate) return -1;
            if (aDate < bDate) return 1;
            return 0;
          });
        setStagedPlayers(sortedByUpdatedDate);
        setVisibleStagedPlayers(sortedByUpdatedDate);
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function fetchValidationRules () {
    return gql(`
      validationRules {
        key
        type
        intStart
        intEnd
        floatStart
        floatEnd
        enumValues
        pattern
        nullable
        dependentColumns
      }
    `,)
      .then((data:any) => data.validationRules as ValidationRule[])
      .then((validationRules:ValidationRule[]) => {
        if (validationRules && validationRules.length > 0) {
          setValidationRules(validationRules);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function fetchTeamsOnly() {
    if (!pageLoading) setPageLoading(true);

    return gql(`
      teamsOnly(type: "College", fbsFcsOnly: false) {
        id
        slug
        type
        name
        shortName
        logo247
        logoESPN
        logoAlt
        averagePAI
        totalWithPAI
        playerCount
      }
    `)
      .then((data:any) => data?.teamsOnly as Team[])
      .then((teams:Team[]) => {
        setTeamsOnly(teams);
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function onSelectPlayer (checkedPlayer:StagedPlayer) {
    return (value:boolean) => {
      if (value) {
        setSelectedStagedPlayers([...selectedStagedPlayers, checkedPlayer]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedStagedPlayers];
        const removeIndex = selectedStagedPlayers.findIndex(item => item.id === checkedPlayer.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }
        setSelectedStagedPlayers(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onEditColumn (
    editedPlayer:StagedPlayer | undefined,
    columnKey:StagedPlayerColumn | undefined,
    newValue?:any | null,
  ) {
    if (saved) setSaved(false);

    if (columnKey == StagedPlayerColumn.HS_COMBINE_TEAM_CAMP) {
      if (editedPlayer?.data.hsCombineType != TEAM_CAMP_STRING) {
          return ;
      }
      editedPlayer.data.hsCombineTeamCampId = divITeams.find((team:Team) => team.shortName === newValue)?.id || null; 
    }

  if (columnKey == StagedPlayerColumn.HS_COMBINE_EVENT) {
      if (editedPlayer && newValue && newValue != TEAM_CAMP_STRING) {
          editedPlayer.data.hsCombineTeamCamp = '';
      }
    }

    setEditedPlayer(editedPlayer);
    setEditedColumn(columnKey);

    const selectedPlayersIds = selectedStagedPlayers.map(player => player.id);
    if (editedPlayer && editedPlayer.id && !selectedPlayersIds.includes(editedPlayer.id)) {
      setSelectedStagedPlayers([...selectedStagedPlayers, editedPlayer]);
    }

    const isTrackEvent = columnKey && TRACK_EVENT_COLUMNS.includes(columnKey);

    function handleNegativeValue (newValue: string) {
      if (newValue == '-') return newValue; //to handle negative value
      if (newValue?.endsWith('-')) return '-'; //to clear the data
      return newValue ? Number(newValue) : newValue; //to handle unfinished float number like "42.
    }

    function updatePlayer (player:StagedPlayer) {
      const validationRule = validationRules.find((rule:ValidationRule) => rule.key === columnKey);
      const isNumber = validationRule && (
        validationRule.type === ValidationRuleType.INT
        || validationRule.type === ValidationRuleType.FLOAT
        || validationRule.type === ValidationRuleType.INCHES
      );
      const isFloat = validationRule && validationRule.type === ValidationRuleType.FLOAT;
      const isSeconds = validationRule && validationRule.type === ValidationRuleType.SECONDS;

      let coercedValue:any = newValue;
      if (isTrackEvent) {
        coercedValue = newValue;
      } else if (isNumber && !isFloat) {
        if (validationRule && validationRule.nullable && newValue == "-") {
          coercedValue = handleNegativeValue(newValue);
        } else {
          coercedValue = newValue !== '' ? parseInt(newValue, 10) : null;
        }
      } else if (isFloat) {
        const isDecimalString = newValue?.includes('.');
        const isUnfinishedNumber = newValue?.endsWith('.');
        const decimalPlaces = isDecimalString ? newValue?.split('.')[1].length : 2;
        coercedValue = !isDecimalString
          ? handleNegativeValue(newValue) // to handle unfinished float number like "42. and negative value"
          : (isUnfinishedNumber ? newValue : Number(newValue).toFixed(decimalPlaces));
      } else if (isSeconds) {
        const isFormattedString = newValue?.includes(':');
        const isUnfinishedNumber = newValue?.endsWith('.');
        const isDecimalString = newValue?.includes('.');
        const decimalPlaces = isDecimalString ? (newValue?.split('.')[1].length === 1 ? 1 : 2) : 2;

        coercedValue = (isFormattedString || isUnfinishedNumber)
          ? newValue
          : (isDecimalString ? Number(newValue).toFixed(decimalPlaces) : Number(newValue));
      }

      return columnKey
        ? {
            ...player,
            data: {
              ...player.data,
              [columnKey]: coercedValue,
            }
          }
        : player;
    }

    const isValidNewValue = typeof newValue === 'string' || newValue === null || typeof newValue === 'object' || typeof newValue === 'number';

    if (editedPlayer && columnKey && isValidNewValue) {
      const updatedStagedPlayers = stagedPlayers.map(player => {
        const needToUpdatePlayer = player.id === editedPlayer.id;

        return needToUpdatePlayer
          ? updatePlayer(player)
          : player;
      });

      const updatedVisibleStagedPlayers = visibleStagedPlayers.map(player => {
        const needToUpdatePlayer = player.id === editedPlayer.id;

        return needToUpdatePlayer
          ? updatePlayer(player)
          : player;
      });

      setStagedPlayers(updatedStagedPlayers);
      setVisibleStagedPlayers(updatedVisibleStagedPlayers);
    }
  }

  function onDelete () {
    if (selectedStagedPlayers.length) {
      setPageLoading(true);

      const idsToDelete = selectedStagedPlayers.map((stagedPlayer:StagedPlayer) => stagedPlayer.id);

      gql(`
        mutation {
          deleteStagedPlayers(ids: [${idsToDelete}])
        }
      `)
        .then((data:any) => data.deleteStagedPlayers as boolean)
        .then((success:boolean) => {
          if (success) {
            setStagedPlayers(
              stagedPlayers.filter((player:StagedPlayer) => !idsToDelete.includes(player.id))
            );
            setVisibleStagedPlayers(
              visibleStagedPlayers.filter((player:StagedPlayer) => !idsToDelete.includes(player.id))
            );
          }
        })
        .catch((error) => {
          console.error(error);
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        })
        .finally(() => setPageLoading(false));
    }

    setSelectedStagedPlayers([]);
  }

  function savePlayers (players:StagedPlayer[]):Promise<boolean> {
    setPageLoading(true);

    // the players we receive here aren't necessarily have the updates we need to save, but we can use their ids
    const playerIds = players.map((player:StagedPlayer) => player.id);
    const playersToSave = stagedPlayers.filter((player:StagedPlayer) => playerIds.includes(player.id));

    return Promise.all(
      playersToSave.map(player => updatePlayer(player as StagedPlayer))
    )
      .then((results:boolean[]) => {
        if (results.every(Boolean)) {
          setSaved(true);
          showToast('Changes Saved', ToastType.SUCCESS);
          return true;
        } else {
          showToast('Failed to save some of the updated players', ToastType.ERROR);
          return false;
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  }

  function updatePlayer (player:StagedPlayer):Promise<boolean> {
    return gql(`
      mutation {
        updateStagedPlayer(
          stagedPlayer: ${serializeGQLObject(player)}
        )
      }
    `)
      .then((data:any) => data.updateStagedPlayer as boolean);
  }

  function findPlayersWithInvalidPositions (players:StagedPlayer[]) {
    return players.filter((player:StagedPlayer) => {
      return !allPositionsValid((player.data.hsPrimaryPosition || '').split('/').filter(Boolean))
        || !allPositionsValid((player.data.hsPositions || '').split('/').filter(Boolean))
        || !allPositionsValid((player.data.collegePrimaryPosition || '').split('/').filter(Boolean))
        || !allPositionsValid((player.data.collegePositions || '').split('/').filter(Boolean))
        || !allPositionsValid((player.data.nflPrimaryPosition || '').split('/').filter(Boolean))
        || !allPositionsValid((player.data.nflPositions || '').split('/').filter(Boolean));
    });
  }

  function onPublish () {
    if (!selectedStagedPlayers.length) return;

    const selectedPlayersIds:number[] = selectedStagedPlayers.map((stagedPlayer:StagedPlayer) => stagedPlayer.id);
    const playersToValidate:StagedPlayer[] = stagedPlayers.filter((player:StagedPlayer) => selectedPlayersIds.includes(player.id));
    const playersWithInvalidPositions:StagedPlayer[] = findPlayersWithInvalidPositions(playersToValidate);
    const playersWithInvalidPositionsIds:number[] = playersWithInvalidPositions.map((player:StagedPlayer) => player.id);

    if (playersWithInvalidPositions?.length) {
      showToast('Some selected players have invalid positions and will be skipped.', ToastType.INFO);
    }

    const playerIdsToPublish = selectedPlayersIds.filter((id:number) => !playersWithInvalidPositionsIds.includes(id));

    if (!playerIdsToPublish?.length) return;

    setPageLoading(true);

    function publishStagedPlayers (ids:number[]):Promise<{ totalPublished:number; errorMessage:string; }> {
      return Promise.any([
        gql(`
          mutation {
            publishStagedPlayers(ids: [${ids}])
          }
        `)
          .then((data:any) => ({
            totalPublished: data.publishStagedPlayers as number || 0,
            errorMessage: data.errorMessage as string || '',
          })),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              totalPublished: 0,
              errorMessage: GQL_ERROR.TIMEOUT,
            });
          }, PUBLISH_PLAYERS_TIMEOUT);
        }),
      ]) as Promise<{ totalPublished:number; errorMessage:string; }>;
    }

    (saved
      ? publishStagedPlayers(playerIdsToPublish)
      : savePlayers(playersForCurrentPage)
          .then(() => publishStagedPlayers(playerIdsToPublish))
    )
      .then(({ totalPublished, errorMessage }:{ totalPublished:number; errorMessage:string; }) => {
        if (errorMessage === GQL_ERROR.TIMEOUT) {
          showToast(
            <>
              Your updates are still being processed.<br />
              Try waiting for at least a minute or more before taking actions.
            </>,
            ToastType.INFO
          );
        } else if (totalPublished && totalPublished > 0) {
          showToast(`Published ${totalPublished} players.`, ToastType.SUCCESS);

          setTimeout(() => setRefreshStagedPlayers(true), 2000);
        } else {
          showToast('Failed to publish', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => {
        setPageLoading(false);
        setSelectedStagedPlayers(playersWithInvalidPositions);
      });
  }

  function onCreateNewPlayer () {
    fetchStagedPlayers()
      .finally(() => setPageLoading(false));

    setAddNewPlayerModalOpen(false);
  }

  function onSortByColumn (sort:(players:StagedPlayer[], order:Order) => StagedPlayer[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function onSort (
    columnName:string,
    newOrder:Order,
    sort:(players:StagedPlayer[], order:Order) => StagedPlayer[],
  ) {
    setSortedByColumn(columnName);
    setStagedPlayers(sort(stagedPlayers, newOrder));
  }

  function handleSearch(event:any) {
    const searchTextValue = event.target.value;
    setSearchText(searchTextValue);

    if (searchTextValue !== '') {
      const filteredStagedPlayers:StagedPlayer[] = [...stagedPlayers]
        .filter((item:StagedPlayer) => {
          return `${item.data.firstName} ${item.data.lastName}`.toLowerCase().includes(searchTextValue.toLowerCase())
            || `${item.data.lastName} ${item.data.firstName}`.toLowerCase().includes(searchTextValue.toLowerCase());
        });

      setVisibleStagedPlayers(filteredStagedPlayers);
    } else {
      setVisibleStagedPlayers(stagedPlayers);
    }
  }

  function onChangePage (page:number) {
    if (!saved) {
      savePlayers(playersForCurrentPage)
        .then((success:boolean) => {
          if (success) {
            setCurrentPage(page);
          }
        });
    }

    setCurrentPage(page);
  }

  function fetchHsCombineTypes () {
    gql(`
      hsCombineTypes {
        id
        type
        teamCamp
        isAccessControlled
      }
    `)
      .then((data: any) => data.hsCombineTypes as HsCombineType[])
      .then((hsCombineType: HsCombineType[]) => {
        setHsCombineType(hsCombineType);
      })
      .catch(() => {
        showToast('Failed to fetch hsCombineType.', ToastType.ERROR);
      })
  }

  const actionsDisabled = !selectedStagedPlayers.length;

  function renderTable ({ sticky = false }:{ sticky:boolean }) {
    return (
      <>
        {!!visibleStagedPlayers?.length && (
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
                ref={sticky ? undefined : stickyRefs[0]}
              >
                <Checkbox
                  checked={selectedStagedPlayers.length === visibleStagedPlayers.length}
                  onChange={() => selectedStagedPlayers.length === visibleStagedPlayers.length
                    ? setSelectedStagedPlayers([])
                    : setSelectedStagedPlayers([...visibleStagedPlayers])
                  }
                />
              </TableHeadCell>

              {TABLE_COLUMNS.map((tableColumn:ColumnConfiguration, index:number) => {
                return (
                <TableHeadCell
                  key={tableColumn.key}
                  ref={sticky ? undefined : stickyRefs[index + 1]}
                  className={clsx(tableColumn.className, classes.truncated)}
                  name={tableColumn.sortable ? tableColumn.key : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable
                    ? onSortByColumn(tableColumn.sort)
                    : undefined
                  }
                >
                  {tableColumn.title}
                </TableHeadCell>
              )})}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {(!visibleStagedPlayers || visibleStagedPlayers.length === 0) && (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <div className={classes.noData}>
                  No staged players
                </div>
              </TableCell>
            </TableRow>
          )}

          {playersForCurrentPage.map((player:StagedPlayer) => (
            <StagedPlayerTableRow
              key={player.id}
              player={player}
              validationRules={validationRules}
              states={states}
              hsCombineTypes={hsCombineTypes}
              divITeams={divITeams}
              collegeTeams={collegeTeams}
              nflTeams={nflTeams}
              teamsOnly={teamsOnly}
              editedPlayer={sticky ? undefined : editedPlayer}
              editedColumn={sticky ? undefined : editedColumn}
              checked={!!selectedStagedPlayers.find(oneOfSelectedItem => oneOfSelectedItem.id === player.id)}
              onSelectPlayer={onSelectPlayer}
              onEditColumn={onEditColumn}
            />
          ))}
        </TableBody>
      </>
    );
  }

  const isDesktopScreenSize = isDesktop(screenSize);

  return (
    <>
      <div className={clsx(classes.importPageTable, className)}>
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <div className={classes.titleRow}>
            <div className={classes.searchWrapper}>
              <input
                className={classes.searchInput}
                placeholder='Search Player'
                type='text'
                value={searchText}
                onChange={handleSearch}
              />

              <SearchIcon className={classes.searchIcon} />
            </div>
          </div>

          <div className={classes.actions}>
            <Action
              className={clsx(classes.action, actionsDisabled && classes.disabledAction)}
              icon={RemoveIcon}
              iconClassName={classes.actionIcon}
              disabled={actionsDisabled}
              onClick={onDelete}
            >
              <span className={classes.actionText}>Delete</span>
            </Action>

            <Action
              className={clsx(classes.action, actionsDisabled && classes.disabledAction)}
              icon={SaveIcon}
              iconClassName={classes.saveIcon}
              disabled={saved}
              onClick={() => {
                selectedStagedPlayers.length > 0
                  ? savePlayers(selectedStagedPlayers)
                  : savePlayers(playersForCurrentPage);
              }}
            >
              <span className={classes.actionText}>Save</span>
            </Action>

            <Action
              className={clsx(classes.action, actionsDisabled && classes.disabledAction)}
              icon={PublishIcon}
              iconClassName={classes.actionIcon}
              disabled={actionsDisabled}
              onClick={onPublish}
            >
              <span className={classes.actionText}>Publish</span>
            </Action>

            {selectedStagedPlayers.length > 0 && (
              <div className={classes.selectedText}>
                <CheckIcon className={classes.checkIcon} />
                Selected {selectedStagedPlayers.length}/{visibleStagedPlayers.length}
              </div>
            )}

            <Action
              className={clsx(classes.action, classes.addNewPlayerAction)}
              icon={AddUserIcon}
              iconClassName={classes.actionIcon}
              onClick={() => setAddNewPlayerModalOpen(true)}
            >
              <span className={classes.actionText}>Add New Player</span>
            </Action>

            <div className={classes.dropdowns}>
              <StagingQuickFilter
                className={classes.quickFilter}
                value={selectedQuickFilter}
                onSelect={setSelectedQuickFilter}
              />

              <div className={classes.dropdownWrapper}>
                Per Page
                <PageLimitDropdown
                  className={classes.dropdown}
                  selectorRootClassName={classes.dropdownSelector}
                  value={String(limit)}
                  onChange={(limit:string) => setLimit(Number(limit))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.tablesContainer}>
          <TableWithDoubleScrollBars
            className={classes.fixedTableWrapper}
            tableClassName={classes.table}
          >
            {renderTable({ sticky: false })}
          </TableWithDoubleScrollBars>

          {isDesktopScreenSize && (
            <TableWithDoubleScrollBars
              className={classes.stickyTableWrapper}
              tableClassName={classes.table}
              style={{ width: `${stickyColumnsTotalWidth}px` }}
              disableBottomScrollbar
            >
              {renderTable({ sticky: true })}
            </TableWithDoubleScrollBars>
          )}
        </div>

        <div className={classes.paginationWrapper}>
          <span className={classes.paginationText}>
            Showing {itemsFrom + 1} to {itemsTo} of {visibleStagedPlayers.length}
          </span>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={onChangePage}
            />
          )}
        </div>
      </div>

      <ImportFiltersDrawer
        open={isDrawerOpen}
        loading={loading}
        currentRecruitingYear={currentRecruitingYear}
        nflDraftYear={nflDraftYear}
        stagedPlayers={stagedPlayers}
        states={states}
        collegeTeams={collegeTeams}
        nflTeams={nflTeams}
        conferences={conferences}
        selectedQuickFilter={selectedQuickFilter}
        setSelectedQuickFilter={setSelectedQuickFilter}
        onFilter={(filteredStagedPlayers:StagedPlayer[]) => setVisibleStagedPlayers(filteredStagedPlayers)}
        onClose={() => setIsDrawerOpen(false)}
      />

      <CreateStagedPlayerModal
        open={addNewPlayerModalOpen}
        states={states}
        onClose={() => setAddNewPlayerModalOpen(false)}
        onCreate={onCreateNewPlayer}
        showToast={showToast}
      />
    </>
  )
}

const mapStateToProps = (state:State) => {
  return {
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    nflDraftYear: state.configurations.nflDraftYear,
    screenSize: state.ui.screenSize,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    states: state.states.allStates,
    statesLoading: state.states.statesLoading,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
  };
};

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchDivITeams,
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchStates,
      fetchFBSConferences,
      fetchFCSConferences,
    },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportPageTable);
