import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { COLOR_DARK_GRAY } from '../styles/colors';
import clsx from 'clsx';

interface CircleBadgeProps {
  value: string | number;
  color?: string;
  text?: string;
  height?: number;
  width?: number;
  className?: {
    badgeWrap?: string;
    badgeVal?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  badgeValue: {
    alignItems: 'center',
    color: COLOR_DARK_GRAY,
    display: 'flex',
    fontSize: '0.9rem',
    inset: 0,
    justifyContent: 'center',
    position: 'absolute',
  },

  circleBadgeWrap: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: 'fit-content',
  },
  text: {
    marginRight: 4
  },
  badgeWrapper: {
    alignItems: "center" ,
    display: "flex",
    justifyContent: "center",
    minWidth: '40px'
    // width: '100%'
  }
}))

const CircleBadge: React.FC<CircleBadgeProps> = ({ value, color = 'inherit', height, width, text = '', className}) => {
  const classes = useStyles();

  const circleStyle = {
    ...(color && { color }),
    ...(height && { height }),
    ...(width && { width }),
  };

  return (
    <Box className={clsx(classes.badgeWrapper, className?.badgeWrap)}>
      {text ? <Typography variant="body1" className={classes.text}>{text}</Typography> : ''}
      <div className={classes.circleBadgeWrap}>
        <CircularProgress style={circleStyle} variant="determinate" value={100} />
        <Typography
          variant="caption"
          className={clsx(classes.badgeValue, className?.badgeVal)}
        >
          {value}
        </Typography>
      </div>
    </Box>
  )
};

export default CircleBadge;
