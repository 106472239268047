import React from 'react';
import Icon from '../atoms/Icon';

function SpeedRecruitIcon (props:any, ref:React.Ref<any>) {
  const color = props?.className == 'green' ? '#408a06': '#F98B60';
  return (
      <Icon {...props} ref={ref} style={{width: '35px', height: '35px', padding: props.padding ? props.padding : '0px 12px 10px 0px'}}>
        <g id="icon-cross-country-active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon-cross-country" transform="translate(4.000000, 6.000000)">
                <path d="M21.5,0.5 C20.3954305,0.5 19.5,1.3954305 19.5,2.5 C19.5,3.6045695 20.3954305,4.5 21.5,4.5 C22.6045695,4.5 23.5,3.6045695 23.5,2.5 C23.5,1.3954305 22.6045695,0.5 21.5,0.5 Z M8.794,2.193 L12.59,1.116 C12.852,1.042 13.134,1.078 13.369,1.215 L16.34,2.957 C16.469,3.033 16.579,3.136 16.663,3.26 L18.83,6.466 C18.94,6.632 19,6.827 19,7.026 L19,9 C19,9.552 19.448,10 20,10 L24,10" id="Shape" stroke={color} strokeWidth="2"></path>
                <path d="M16.462,3.121 L11.748,7.099 C11.097,7.649 10.846,8.54 11.116,9.348 L12.562,13.684 C12.777,14.331 12.295,15 11.613,15 L7,15" id="Path" stroke={color} strokeWidth="2"></path>
                <path d="M15.675,15.19 L13.58,20 L15.762,20 L17.509,15.989 C17.754,15.426 17.73,14.792 17.441,14.249 L15.476,10.561 L18.671,7.43 L17.271,6.001 L14.077,9.131 C13.447,9.747 13.296,10.721 13.71,11.501 L15.675,15.19 Z M9.616,5 L0,5 L0,7 L8.307,7 C8.572,6.252 9.013,5.567 9.616,5 Z M8.27,10.297 C8.127,9.869 8.055,9.434 8.027,9 L0,9 L0,11 L8.504,11 L8.27,10.297 Z" id="Shape" fill={color} fillRule="nonzero"></path>
            </g>
        </g>
      </Icon>
  );
}

export default React.forwardRef(SpeedRecruitIcon);
