import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Ranks, { RanksType } from '../atoms/Ranks';
import Loader from '../atoms/Loader';
import NamedBox from '../atoms/NamedBox';
import PAIChart from '../molecules/PAIChart';
import CombineChart from '../molecules/CombineChart';
import ProCombineChart from '../molecules/ProCombineChart';
import PPIChart from '../molecules/PPIChart';
import MultiSportChart from '../molecules/MultiSportChart';
import { RanksComparisonGroup } from '../molecules/RanksTooltip';
import { TAB } from './PlayerProfileStats';
import PlayerCombineData from './PlayerCombineData';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import Position from '../types/Position';
import Team from '../types/Team';
import Player from '../types/Player';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import PlayerPAIStats from '../types/PlayerPAIStats';
import PlayerNFLPAIStats from '../types/PlayerNFLPAIStats';
import PlayerCombineStats from '../types/PlayerCombineStats';
import PlayerCombineEvent from '../types/PlayerCombineEvent';
import PlayerNFLCombineEvent from '../types/PlayerNFLCombineEvent';
import PlayerProCombineStats from '../types/PlayerProCombineStats';
import PlayerPPIStats from '../types/PlayerPPIStats';
import PlayerNFLPPIStats from '../types/PlayerNFLPPIStats';
import { TEAM_TYPE } from '../templates/PlayerPageTemplate';
import { formatDate } from '../services/player';

interface PlayerChartsProps {
  className?: string;
  loading: boolean;
  selectedTab: number;
  player?: Player;
  collegeTeam?: Team;
  nflTeam?: Team;
  position?: Position;
  isNFL: boolean;
  hideTabs?: boolean;
  paiStats?: PlayerPAIStats | PlayerNFLPAIStats;
  combineStats?: PlayerCombineStats | PlayerProCombineStats;
  combineEvents: Array<PlayerCombineEvent | PlayerNFLCombineEvent>;
  ppiStats?: PlayerPPIStats | PlayerNFLPPIStats;
  hasPaiData?: boolean;
  hasCombineScore?: boolean;
  hasProCombineData?: boolean;
  hasHSCombineData?: boolean;
  hasPPIScore?: boolean;
  printed?: boolean;
  onTabChange: (tab:number) => void;
  selectedTeamType?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    width: '500px',
  },
  playerCharts: {
    position: 'relative',
  },
  playerChartsHeader: {
    height: 16,
    boxSizing: 'content-box',
  },
  playerChartsHeaderName: {
    position: 'absolute',
    bottom: -40,
    left: 20,
  },

  printed: {
    '& $playerChartsHeaderName': {
      position: 'static',
    },
  },

  tabs: {
    position: 'absolute',
    bottom: 0,
  },
  tabsIndicator: {
    height: '3px',
  },
  tab: {
    minWidth: 'auto',
    minHeight: '40px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: 1.2,
    padding: theme.spacing(2, 1.5),
    textTransform: 'none',
    opacity: 1,
  },

  collapsedTab: {
    width: 0,
    padding: 0,
    visibility: 'hidden',
  },

  chart: {
    width: '100%',
    height: '280px',
  },
  chartContainer: {
    minHeight: '200px',
  },
  hsCombineData: {
    minHeight: '200px',
  },

  '@media (max-width: 1260px)': {
    tabs: {
      position: 'relative',
      marginTop: theme.spacing(1),
    },

    playerChartsHeader: {
      height: 'auto',
      display: 'block',
      padding: theme.spacing(0, 0, 0, 1.25),
    },
  },

  [MEDIA.MOBILE]: {
    tab: {
      minHeight: 0,
      padding: theme.spacing(1),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    tab: {
      // padding: theme.spacing(2),
    },
  },
  selectedTab: {
    fontWeight: 600
  },
}), { name: PlayerCharts.name });

export default function PlayerCharts (props:PlayerChartsProps) {
  const {
    className,
    loading,
    selectedTab = TAB.PAI,
    player,
    collegeTeam,
    nflTeam,
    position,
    isNFL,
    paiStats,
    combineStats,
    combineEvents,
    ppiStats,
    hideTabs = false,
    hasPaiData = false,
    hasHSCombineData = false,
    hasCombineScore = false,
    hasPPIScore = false,
    hasProCombineData = false,
    printed = false,
    onTabChange,
    selectedTeamType
  } = props;
  const classes = useStyles();

  const college = ((player && player.playerColleges) || [])
    .find((college:College) => college && college.isPrimary);
  const highSchool = ((player && player.highSchools) || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  const recruitingClass = (highSchool && highSchool.graduatingClass) || (college && college.recruitingClass);

  const currentStats = (selectedTab === TAB.PAI && paiStats)
    || ((selectedTab === TAB.COMBINE || selectedTab === TAB.PRO_COMBINE) && combineStats)
    || (selectedTab === TAB.PPI && ppiStats);

  const isProCombineTabButNoScore = selectedTab === TAB.PRO_COMBINE && !player?.nflCombineScore;

  let ranksType = selectedTab === TAB.PAI ? RanksType.PAI : RanksType.COMBINE;
  let comparisonEvent = selectedTab === TAB.PAI ? 'pai' : 'combine';
  if (selectedTab === TAB.PRO_COMBINE) {
    ranksType = RanksType.PRO_COMBINE;
    comparisonEvent = 'proCombine';
  } else if (selectedTab === TAB.PPI) {
    ranksType = RanksType.PPI;
    comparisonEvent = 'ppi';
  }

  return (
    <NamedBox
      className={clsx(classes.playerCharts, printed && classes.printed, className)}
      headerClassName={classes.playerChartsHeader}
      headerNameClassName={classes.playerChartsHeaderName}
      name={`
        ${selectedTab === TAB.PAI ? 'Player Athletic Index®' : ''}
        ${selectedTab === TAB.COMBINE ? (hasCombineScore ? 'HS Combine Score' : (hasHSCombineData ? 'HS Combine Data' : '')) : ''}
        ${selectedTab === TAB.PPI ? '(PPI) College Production Score' : ''}
        ${selectedTab === TAB.PRO_COMBINE ? (hasProCombineData ? 'Pro Combine Score' :  '') : ''}
        ${selectedTab === TAB.MULTISPORT ? 'Multi-sport' : ''} - ${position}
      `}
      subHeader={`${selectedTab === TAB.PPI && player?.ppiDate ? `Updated: ${formatDate(player?.ppiDate, true)} `: ''}`}
      printed={printed}
      headerContent={!hideTabs ? (
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes.tabsIndicator }}
          value={selectedTab}
          onChange={(event:any, tab:number) => onTabChange(tab)}
        >
          <Tab
            className={clsx(classes.tab, selectedTab === 0 && classes.selectedTab)}
            label='PAI'
            disableRipple
            disabled={!hasPaiData}
          />
          <Tab
            className={clsx(classes.tab, selectedTab === 1 && classes.selectedTab)}
            label='HS Combine'
            disableRipple
            disabled={!(hasCombineScore || hasHSCombineData)}
          />
          <Tab
            className={clsx(classes.tab, selectedTab === 2 && classes.selectedTab)}
            label='Multi-sport'
            disableRipple
          />
          <Tab
          className={clsx(classes.tab, selectedTab === 3 && classes.selectedTab, (selectedTeamType === TEAM_TYPE.HIGH_SCHOOL) && classes.collapsedTab )}
          label='PPI'
          disableRipple
          disabled={!hasPPIScore}
          />
          <Tab
            className={clsx(classes.tab, selectedTab === 4 && classes.selectedTab, (selectedTeamType === TEAM_TYPE.HIGH_SCHOOL) && classes.collapsedTab )}
            label='Pro Combine'
            disableRipple
            disabled={!hasProCombineData}
            />
        </Tabs>
      ) : null}
    >
      <Loader inProgress={loading} />

      {!isProCombineTabButNoScore && (
        <div className={classes.chartContainer}>
          {selectedTab === TAB.PAI && (
            <PAIChart
              className={classes.chart}
              team={isNFL ? nflTeam : collegeTeam}
              player={player}
              position={position}
            />
          )}

          {(selectedTab === TAB.COMBINE && hasCombineScore) && (
            <CombineChart
              className={classes.chart}
              team={isNFL ? nflTeam : collegeTeam}
              player={player}
              position={position}
            />
          )}

          {(selectedTab === TAB.PPI && hasPPIScore) && (
            <PPIChart
              className={classes.chart}
              team={isNFL ? nflTeam : collegeTeam}
              player={player}
              position={position}
            />
          )}

          {(selectedTab === TAB.PRO_COMBINE && hasProCombineData) && (
            <ProCombineChart
              className={classes.chart}
              team={isNFL ? nflTeam : collegeTeam}
              player={player}
              position={position}
              isNFL={isNFL}
            />
          )}

          {((selectedTab === TAB.COMBINE) && !hasCombineScore && (hasHSCombineData || hasProCombineData)) && (
            <PlayerCombineData
              className={classes.hsCombineData}
              combineEvents={combineEvents}
              player={player}
              collegeTeam={collegeTeam}
              nflTeam={nflTeam}
              position={position}
              isNFL={isNFL}
              hideHeader
            />
          )}

          {selectedTab === TAB.MULTISPORT && (
            <MultiSportChart
              sports={player?.multiSport || []}
            />
          )}
        </div>
      )}

      {((selectedTab !== TAB.COMBINE && selectedTab !== TAB.PRO_COMBINE)
        || (selectedTab === TAB.COMBINE && hasCombineScore)
        || (selectedTab === TAB.PPI && hasPPIScore)
        || (selectedTab === TAB.PRO_COMBINE && hasProCombineData && !isProCombineTabButNoScore)
      ) && (
        <>
          {(currentStats && collegeTeam && !isNFL) && (
            <Ranks
              type={ranksType}
              playerId={player?.id}
              teamId={collegeTeam?.id}
              position={position}
              comparisonEvent={comparisonEvent}
              combineId={null}
              combineCategory={null}
              headerItems={[
                { title: 'HS Class', subtitle: `(${recruitingClass})` },
                { title: 'Division-I', subtitle: '(Since 2010)' },
                { logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt },
                { logo: get(collegeTeam, 'conference.iconUrl', '') },
                { logo: '/images/nfl-draft.png' },
              ]}
              values={[
                {
                  percentile: Math.round(get(currentStats, 'hsClassStats.percentile', 0)),
                  value: `${get(currentStats, 'hsClassStats.playerRank', 0)}/${get(currentStats, 'hsClassStats.playerCount', 1)}`,
                },
                {
                  percentile: Math.round(get(currentStats, 'div1Stats.percentile', 0)),
                  value: `${get(currentStats, 'div1Stats.playerRank', 0)}/${get(currentStats, 'div1Stats.playerCount', 1)}`,
                },
                {
                  percentile: Math.round(get(currentStats, 'teamStats.percentile', 0)),
                  value: `${get(currentStats, 'teamStats.playerRank', 0)}/${get(currentStats, 'teamStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.COLLEGE_TEAM,
                },
                {
                  percentile: Math.round(get(currentStats, 'conferenceStats.percentile', 0)),
                  value: `${get(currentStats, 'conferenceStats.playerRank', 0)}/${get(currentStats, 'conferenceStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.COLLEGE_CONFERENCE,
                },
                {
                  percentile: Math.round(get(currentStats, 'nflDraftStats.percentile', 0)),
                  value: `${get(currentStats, 'nflDraftStats.playerRank', 0)}/${get(currentStats, 'nflDraftStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
                },
              ]}
              bottomSpace={selectedTab !== TAB.PPI}
            />
          )}

          {(currentStats && collegeTeam && nflTeam && isNFL) && (
            <Ranks
              type={ranksType}
              playerId={player?.id}
              teamId={nflTeam?.id}
              position={position}
              comparisonEvent={comparisonEvent}
              combineId={null}
              combineCategory={null}
              headerItems={[
                {
                  logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt,
                  subtitle: '(Since 2010)',
                },
                {
                  logo: get(collegeTeam, 'conference.iconUrl', ''),
                  subtitle: '(Since 2010)',
                },
                { logo: nflTeam?.logo247 || nflTeam?.logoESPN || nflTeam?.logoAlt, },
                { title: 'NFL', subtitle: 'UDFA' },
                { logo: '/images/nfl-draft.png' },
              ]}
              values={[
                {
                  percentile: Math.round(get(currentStats, 'collegeTeamStats.percentile', 0)),
                  value: `${get(currentStats, 'collegeTeamStats.playerRank', 0)}/${get(currentStats, 'collegeTeamStats.playerCount', 1)}`,
                },
                {
                  percentile: Math.round(get(currentStats, 'collegeConferenceStats.percentile', 0)),
                  value: `${get(currentStats, 'collegeConferenceStats.playerRank', 0)}/${get(currentStats, 'collegeConferenceStats.playerCount', 1)}`,
                },
                {
                  percentile: Math.round(get(currentStats, 'nflTeamStats.percentile', 0)),
                  value: `${get(currentStats, 'nflTeamStats.playerRank', 0)}/${get(currentStats, 'nflTeamStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.NFL_TEAM,
                },
                {
                  percentile: Math.round(get(currentStats, 'nflUDFAStats.percentile', 0)),
                  value: `${get(currentStats, 'nflUDFAStats.playerRank', 0)}/${get(currentStats, 'nflUDFAStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.NFL_UDFA,
                },
                {
                  percentile: Math.round(get(currentStats, 'nflDraftStats.percentile', 0)),
                  value: `${get(currentStats, 'nflDraftStats.playerRank', 0)}/${get(currentStats, 'nflDraftStats.playerCount', 1)}`,
                  comparisonGroup: RanksComparisonGroup.NFL_DRAFT,
                },
              ]}
              bottomSpace={selectedTab !== TAB.PPI}
            />
          )}
        </>
      )}
    </NamedBox>
  );
}
