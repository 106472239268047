import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TeamProfileStatistics from './TeamProfileStatistics';
import { SCORE_TYPE } from '../atoms/Score';
import DropDown from '../atoms/DropDown';
import { COLOR_DARK_GRAY } from '../styles/colors';
import Position from '../types/Position';
import PositionStats from '../types/PositionStats';
import Team from '../types/Team';

interface TeamPositionStatsProps {
  className?: string;
  team?: Team;
  nflAccess?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamPositionStats: {},

  statisticsLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  statisticsDropDown: {
    marginTop: theme.spacing(1),
  },
  statisticsDropDownSelector: {
    boxSizing: 'border-box',
    minHeight: '40px',
    fontSize: theme.typography.pxToRem(16),
  },
}), { name: TeamPositionStats.name });

export default function TeamPositionStats (props: TeamPositionStatsProps) {
  const {
    className,
    team,
    nflAccess,
  } = props;
  const classes = useStyles();
  const [positionStats, setPositionStats] = useState<PositionStats>();
  const [selectedOffensePosition, setSelectedOffensePosition] = useState<Position | 'all'>('all');
  const [selectedDefensePosition, setSelectedDefensePosition] = useState<Position | 'all'>('all');

  useEffect(() => {
    if (team?.positionStats) {
      setPositionStats(team.positionStats);
    }
  }, [team, nflAccess]);

  if (!positionStats || !team) return null;

  const selectedOffensePositionStats:any = selectedOffensePosition === 'all'
    ? (get(positionStats, 'offensive') || [])
    .find((stat:any) => !get(stat, 'position.code')) || {}
    : (get(positionStats, 'offensive') || [])
    .find((stat:any) => get(stat, 'position.code') === selectedOffensePosition) || {};
  const selectedOffensePositionValues = selectedOffensePositionStats && selectedOffensePositionStats.averagePAI
    ? selectedOffensePositionStats.averagePAI
    : { team: 0, level: 0, conference: 0 };

  const selectedDefensePositionStats:any = selectedDefensePosition === 'all'
    ? (get(positionStats, 'defensive') || [])
    .find((stat:any) => !get(stat, 'position.code')) || {}
    : (get(positionStats, 'defensive') || [])
    .find((stat:any) => get(stat, 'position.code') === selectedDefensePosition) || {};
  const selectedDefensePositionValues = selectedDefensePositionStats && selectedDefensePositionStats.averagePAI
    ? selectedDefensePositionStats.averagePAI
    : { team: 0, level: 0, conference: 0 };

  return (
    <TeamProfileStatistics
      className={clsx(classes.teamPositionStats, className)}
      title={nflAccess
        ? <>Drafted & UDFA Position Statistics <span style={{ fontWeight: 400, color: COLOR_DARK_GRAY }}>(2010 – Present)</span></>
        : 'Current Position Statistics'
      }
      team={team}
      isNFL={nflAccess}
      rows={[
        {
          scoreType: SCORE_TYPE.PAI,
          label: (
            <div className={classes.statisticsLabel}>
              Offense:
              <DropDown
                className={classes.statisticsDropDown}
                selectorRootClassName={classes.statisticsDropDownSelector}
                items={[
                  { content: 'All Positions', value: 'all' },
                  ...get(positionStats, 'offensive', [])
                    .filter((stat:any) => !!get(stat, 'position.code'))
                    .map((stat:any) => get(stat, 'position.code') as Position)
                    .map((position:Position) => ({
                      content: position,
                      value: position,
                    }))
                ]}
                value={selectedOffensePosition}
                onChange={(position:Position) => setSelectedOffensePosition(position)}
              />
            </div>
          ),
          values: selectedOffensePositionValues,
        },
        {
          scoreType: SCORE_TYPE.PAI,
          label: (
            <div className={classes.statisticsLabel}>
              Defense:
              <DropDown
                className={classes.statisticsDropDown}
                selectorRootClassName={classes.statisticsDropDownSelector}
                items={[
                  { content: 'All Positions', value: 'all' },
                  ...get(positionStats, 'defensive', [])
                    .filter((stat:any) => !!get(stat, 'position.code'))
                    .map((stat:any) => get(stat, 'position.code') as Position)
                    .map((position:Position) => ({
                      content: position,
                      value: position,
                    }))
                ]}
                value={selectedDefensePosition}
                onChange={(position:Position) => setSelectedDefensePosition(position)}
              />
            </div>
          ),
          values: selectedDefensePositionValues,
        }
      ]}
    />
  );
}
