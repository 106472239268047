import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { interpolateHexColors } from '../services/colors-interpolator';
import UserIcon from '../icons/UserIcon';
import TeamIcon from '../icons/TeamIcon';
import ChartMarker from '../atoms/ChartMarker';
import { getTeamLogo } from '../atoms/TeamLogo';
import ChartImageMarker from '../atoms/ChartImageMarker';
import ChartLabel from '../atoms/ChartLabel';
import { getScoreValue } from '../atoms/Score';
import Team from '../types/Team';
import Player from '../types/Player';
import Position from '../types/Position';

interface ProCombineChartProps {
  className?: string;
  team?: Team;
  player?: Player;
  position?: Position;
  isNFL?: boolean;
}

const START_COLOR = '#ffe084';
const END_COLOR = '#408a06';

const averages = [
  {
    name: 'Marginal',
    value: 0.0,
  },
  {
    name: 'Below Average',
    value: 1.0,
  },
  {
    name: 'Average',
    value: 2.0,
  },
  {
    name: 'Above Average',
    value: 3.0,
  },
  {
    name: 'Outstanding',
    value: 4.0,
  },
  {
    name: 'Exceptional',
    value: 5.0,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  proCombineChart: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(5),
    boxSizing: 'border-box',
  },
  chart: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  axis: {
    width: '100%',
    height: '4px',
    background: `linear-gradient(to right, ${START_COLOR}, ${END_COLOR})`,
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
  },
}), { name: ProCombineChart.name });

export default function ProCombineChart (props: ProCombineChartProps) {
  const {
    className,
    team,
    player,
    position,
    isNFL,
  } = props;
  const classes = useStyles();

  let teamMarker = null;
  if (!!team?.proCombine?.length) {
    const teamProCombine = getScoreValue(team.proCombine, { position }) || 0;
    const teamPosition = Math.round(teamProCombine * 100 / 5);

    teamMarker = (
      <ChartImageMarker
        position={teamPosition}
        image={getTeamLogo(team)}
        iconFallback={TeamIcon}
        tooltipText={`${isNFL ? 'NFL' : '' } Team Avg: ${teamProCombine.toFixed(2)}`}
        color={interpolateHexColors(START_COLOR, END_COLOR, teamPosition)}
      />
    );
  }

  let playerMarker = null;
  if (!!player?.nflCombineScore) {
    const combine = player.nflCombineScore.toFixed(2);
    const playerPosition = Math.round(parseFloat(combine) * 100 / 5);

    playerMarker = (
      <ChartImageMarker
        position={playerPosition}
        image={player.photoUrl}
        iconFallback={UserIcon}
        tooltipText={`${player.firstName} ${player.lastName} NFL Com.: ${combine}`}
        color={interpolateHexColors(START_COLOR, END_COLOR, playerPosition)}
        avatar
      />
    );
  }

  return (
    <div className={clsx(classes.proCombineChart, className)}>
      <div className={classes.chart}>
        <div className={classes.axis} />

        <ChartMarker
          position={0}
          label='0'
          color={START_COLOR}
        />
        <ChartMarker
          position={20}
          label='1.0'
          color={interpolateHexColors(START_COLOR, END_COLOR, 20)}
        />
        <ChartMarker
          position={40}
          label='2.0'
          color={interpolateHexColors(START_COLOR, END_COLOR, 40)}
        />
        <ChartMarker
          position={60}
          label='3.0'
          color={interpolateHexColors(START_COLOR, END_COLOR, 60)}
        />
        <ChartMarker
          position={80}
          label='4.0'
          color={interpolateHexColors(START_COLOR, END_COLOR, 80)}
        />
        <ChartMarker
          position={100}
          label='5.0'
          color={END_COLOR}
        />

        {averages.map((average:any, index) => {
          const position = Math.round(average.value * 100 / 5);
          const name = average.name
            .split('_')
            .map((word:string, index:number) => (
              <span key={index}>{word}&nbsp;</span>
            ));

          return (
            <ChartLabel
              key={index}
              position={position}
              label={name}
              color={interpolateHexColors(START_COLOR, END_COLOR, position)}
              long={(index + 1) % 2 === 0}
            />
          );
        })}

        {teamMarker}
        {playerMarker}
      </div>
    </div>
  );
}
