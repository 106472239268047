import React from 'react';
import Icon from '../atoms/Icon';

function ArrowDownIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M16,21.7555556 L16,11 M20,17.8444444 L16,22 L12,17.8444444'
          stroke='currentColor'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(ArrowDownIcon);
