import React from 'react';
import Icon from '../atoms/Icon';

function DemoIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1341, -897)' fill='currentColor' fillRule='nonzero'>
          <path d='M1346,903 L1346,918 L1344,918 L1344,919 C1344,921.222656 1345.85938,923 1348.0625,923 L1367.9375,923 C1370.14062,923 1372,921.222656 1372,919 L1372,918 L1370,918 L1370,903 L1346,903 Z M1348,905 L1368,905 L1368,918 L1348,918 L1348,905 Z M1356,908 L1356,914 L1357.59375,912.6875 L1358.5,914.90625 L1359.3125,914.5 L1358.3125,912.3125 L1360.40625,912.09375 L1356,908 Z M1346.40625,920 L1369.59375,920 C1369.23828,920.570313 1368.69141,921 1367.9375,921 L1348.0625,921 C1347.30859,921 1346.76172,920.570313 1346.40625,920 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(DemoIcon);
