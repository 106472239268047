import React from 'react';
import Icon from '../atoms/Icon';

function ClassIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M6.842 17C5.714 17.644 5 18.824 5 20.131c0 1.6 1.057 2.963 2.534 3.435C9 27.524 12.347 31 16 31c3.653 0 6.999-3.476 8.466-7.434C25.943 23.094 27 21.732 27 20.131A3.6 3.6 0 0025.167 17l-.419.716h-1.415v1.027l.611.348c.38.217.612.605.612 1.04 0 .624-.49 1.141-1.124 1.193l-.825.066-.244.782c-1.04 3.335-3.826 6.413-6.363 6.413-2.537 0-5.324-3.078-6.363-6.413l-.244-.782-.825-.066a1.207 1.207 0 01-1.124-1.193c0-.43.237-.82.62-1.04l.63-.359-.028-1.047-1.403.036L6.842 17z'/>
        <path d='M12.25 19c-.69 0-1.25.448-1.25 1s.56 1 1.25 1 1.25-.448 1.25-1-.56-1-1.25-1zm7.5 0c-.69 0-1.25.448-1.25 1s.56 1 1.25 1S21 20.552 21 20s-.56-1-1.25-1zM0 6l16-5 16 5-16 5L0 6zm16 2.384L23.63 6 16 3.616 8.37 6 16 8.384z'/>
        <path d='M24.75 5l.002 6.016c.88 1.075 1.218 3.453 1.246 6.556L26 18h-2.5c0-1.464-.102-2.839-.28-3.896l-.049-.267-.276.161c-1.628.9-4.158 1.464-6.895 1.464-2.898 0-5.564-.631-7.173-1.626C8.621 14.922 8.5 16.41 8.5 18H6c0-3.314.329-5.864 1.25-6.986V5L16 7.733 24.75 5zm-2.5 3.436L16 10.388 9.75 8.436v2.961l.055.048c.246.202.648.428 1.163.636 1.296.524 3.048.844 5.032.844s3.736-.32 5.032-.844c.515-.208.917-.434 1.163-.636l.029-.025.026-.023V8.436z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(ClassIcon);
