import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Ranks from '../atoms/Ranks';
import Loader from '../atoms/Loader';
import { COLOR_BORDER, COLOR_SHADOW, COLOR_WHITE } from '../styles/colors';
import gql from '../services/gql';
import Player from '../types/Player';
import Team from '../types/Team';
import Position from '../types/Position';
import PlayerMultiSportStats from '../types/PlayerMultiSportStats';
import College from '../types/College';
import HighSchool from '../types/HighSchool';
import PlayerNFLMultiSportStats from '../types/PlayerNFLMultiSportStats';

interface PlayerMultiSportDataProps {
  player?: Player;
  collegeTeam?: Team;
  nflTeam?: Team;
  position?: Position;
  isNFL?: boolean;
}

const useStyles = makeStyles((theme:Theme) => ({
  playerMultiSportData: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    borderTop: 0,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
}), { name: PlayerMultiSportData.name });

export default function PlayerMultiSportData (props:PlayerMultiSportDataProps) {
  const {
    player,
    collegeTeam,
    nflTeam,
    position,
    isNFL,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [multiSportStats, setMultiSportStats] = useState<Array<PlayerMultiSportStats | PlayerNFLMultiSportStats>>([]);

  useEffect(() => {
    if (player?.id && collegeTeam?.id && position) {
      if (!isNFL) {
        fetchMultiSportStats(player.id, collegeTeam.id, position);
      }
      if (isNFL && nflTeam?.id) {
        fetchNFLMultiSportStats(player.id, collegeTeam.id, nflTeam.id, position);
      }
    }
  }, [player?.id, collegeTeam?.id, nflTeam?.id, position, isNFL]);

  function fetchMultiSportStats (playerId:number, collegeTeamId:number, position:string) {
    if (!loading) setLoading(true);

    gql(`
      playerMultiSportStats(
        id: ${playerId},
        compareToTeamId: ${collegeTeamId},
        positionCode: "${position}"
      ) {
        multiSportStats {
          performance
          event
          meet
          year
          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }
      }
    `)
      .then((data:any) => get(data, 'playerMultiSportStats.multiSportStats') as PlayerMultiSportStats[])
      .then((multiSportStats:PlayerMultiSportStats[]) => setMultiSportStats(multiSportStats))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLMultiSportStats (playerId:number, collegeTeamId:number, nflTeamId:number, position:string) {
    if (!loading) setLoading(true);

    gql(`
      playerMultiSportStatsNFL (
        id: ${playerId},
        compareToCollegeTeamId: ${collegeTeamId},
        compareToNFLTeamId: ${nflTeamId},
        positionCode: "${position}"
      ) {
        multiSportStats {
          performance
          event
          meet
          year
          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }
      }
    `)
      .then((data:any) => get(data, 'playerMultiSportStatsNFL.multiSportStats') as PlayerNFLMultiSportStats[])
      .then((multiSportStats:PlayerNFLMultiSportStats[]) => setMultiSportStats(multiSportStats))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  const college = ((player && player.playerColleges) || [])
    .find((college:College) => college && college.isPrimary);
  const highSchool = ((player && player.highSchools) || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  const recruitingClass = (college && college.recruitingClass) || (highSchool && highSchool.graduatingClass);

  return (
    <div className={classes.playerMultiSportData}>
      <Loader inProgress={loading} />

      {!isNFL && (
        <Ranks
          headerItems={[
            { title: 'HS Class', subtitle: `(${recruitingClass})` },
            { title: 'Division-I', subtitle: '(Since 2010)' },
            { logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt },
            { logo: get(collegeTeam, 'conference.iconUrl', '') },
            { logo: '/images/nfl-draft.png' },
          ]}
          noTopBorder
        />
      )}
      {isNFL && (
        <Ranks
          headerItems={[
            {
              logo: collegeTeam?.logo247 || collegeTeam?.logoESPN || collegeTeam?.logoAlt,
              subtitle: '(Since 2010)',
            },
            {
              logo: get(collegeTeam, 'conference.iconUrl', ''),
              subtitle: '(Since 2010)',
            },
            { logo: nflTeam?.logo247 || nflTeam?.logoESPN || nflTeam?.logoAlt },
            { title: 'NFL', subtitle: 'UDFA' },
            { logo: '/images/nfl-draft.png' },
          ]}
          noTopBorder
        />
      )}

      {(multiSportStats && !!multiSportStats.length) && (
        multiSportStats.map((stat:PlayerMultiSportStats | PlayerNFLMultiSportStats, index) => (
          <Ranks
            key={index}
            event={{
              type: stat.event
                ? `${stat.event.slice(0, 1).toUpperCase()}${stat.event.slice(1).toLowerCase().replace('_', '')}`
                : ''
            }}
            values={isNFL
              ? [
                  {
                    percentile: Math.round(get(stat, 'collegeTeamStats.percentile', 0)),
                    value: 'Participation',
                  },
                  {
                    percentile: Math.round(get(stat, 'collegeConferenceStats.percentile', 0)),
                    value: 'Participation',
                  },
                  {
                    percentile: Math.round(get(stat, 'nflTeamStats.percentile', 0)),
                    value: 'Participation',
                  },
                  {
                    percentile: Math.round(get(stat, 'nflUDFAStats.percentile', 0)),
                    value: 'Participation',
                  },
                  {
                    percentile: Math.round(get(stat, 'nflDraftStats.percentile', 0)),
                    value: 'Participation',
                  },
                ]
              : [
                {
                  percentile: Math.round(get(stat, 'hsClassStats.percentile', 0)),
                  value: 'Participation',
                },
                {
                  percentile: Math.round(get(stat, 'div1Stats.percentile', 0)),
                  value: 'Participation',
                },
                {
                  percentile: Math.round(get(stat, 'teamStats.percentile', 0)),
                  value: 'Participation',
                },
                {
                  percentile: Math.round(get(stat, 'conferenceStats.percentile', 0)),
                  value: 'Participation',
                },
                {
                  percentile: Math.round(get(stat, 'nflDraftStats.percentile', 0)),
                  value: 'Participation',
                },
              ]
            }
            bottomSpace={index + 1 < multiSportStats.length}
          />
        ))
      )}
    </div>
  );
}
