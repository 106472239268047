import React from 'react';
import Icon from '../atoms/Icon';

function HandshakeIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
        <path
          d='M18.228 4.14c.766-.154 1.272-.163 2.752-.112.327.01.532.017.756.021.506.01.984.153 1.698.453a82.752 82.752 0 001.796.765c1.758.69 3.485 1.059 5.627 1.059H32v11.067l-4.133 1.373-4.225 2.104a4.156 4.156 0 01-1.123 1.976l-.153.127-.277.191a403.453 403.453 0 01-2.973 2.027l-.127.086c-1.324.891-2.331 1.551-2.914 1.903-1.751 1.055-4.392 1.202-5.824-.213l-7.527-8.334L0 17.276V6.326h1.143c2.146 0 3.875-.362 5.635-1.038.465-.178 1.86-.775 1.796-.749.712-.292 1.188-.431 1.69-.44.223-.005.427-.011.752-.022 1.483-.051 1.989-.042 2.756.111.746.15 1.451.433 2.189.892.772-.491 1.502-.788 2.267-.94zm-4.906 2.28c-.528-.105-.933-.112-2.227-.068-.336.012-.55.018-.787.023-.136.002-.4.08-.863.27.099-.04-1.333.571-1.844.768C5.914 8.06 4.23 8.46 2.286 8.57v7.298l1.847.92.145.16 7.627 8.448c.501.495 2.033.409 2.987-.166.142-.085.315-.193.516-.321l-1.467-1.964 1.833-1.361 1.544 2.068.391-.263.126-.085 1-.677-1.466-1.96 1.833-1.36 1.518 2.03.069-.047.152-.105.03-.021a1.904 1.904 0 00-.061-2.645l-.083-.091-4.207-5.162-2.302 1.869c-.058.04-.117.08-.177.118-1.887 1.198-3.883.884-5.7-.291a7.264 7.264 0 01-1.269-1.02l-.842-.882.939-.78 6.265-5.187c.164-.162.325-.317.482-.464a3.785 3.785 0 00-.694-.21zm7.58-.117c-1.292-.044-1.697-.037-2.224.069-1.001.2-2.006.83-3.609 2.406l-5.254 4.356c1.097.671 2.14.807 3.098.2.032-.02.063-.042.036-.02l.008-.007 3.993-3.248 5.616 6.89c.42.427.728.922.927 1.45l3.424-1.706.15-.062 2.647-.879V8.57c-1.948-.113-3.633-.522-5.322-1.185-.512-.2-1.943-.826-1.845-.785-.46-.193-.723-.272-.854-.274-.24-.005-.453-.011-.791-.023z'
          fill='currentColor'
          fillRule='nonzero'
        />
    </Icon>
  );
}

export default React.forwardRef(HandshakeIcon);
