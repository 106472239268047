import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Loader from '../atoms/Loader';
import Dialog from './Dialog';
import ImageCropper from './ImageCropper';

interface UpdateProfilePhotoModalProps {
  className?: string;
  open: boolean;
  loading: boolean;
  confirmDisabled: boolean;
  selectedPhotoFileSrc: string;
  selectedPhotoFileMimeType: string;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  onCrop: (imageFile:Blob) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  updateProfilePhotoModal: {},

  profilePhotoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  profilePhoto: {
    height: '300px',
    width: '300px',
  },
}), { name: UpdateProfilePhotoModal.name });

export default function UpdateProfilePhotoModal (props:UpdateProfilePhotoModalProps) {
  const {
    className,
    open = false,
    loading = true,
    confirmDisabled = false,
    selectedPhotoFileSrc,
    selectedPhotoFileMimeType,
    onClose = () => {},
    onCancel = () => {},
    onConfirm = () => {},
    onCrop = () => {},
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      className={clsx(classes.updateProfilePhotoModal, className)}
      open={open}
      title='Upload a New Profile Photo'
      actions={[
        {
          name: 'Cancel',
          onClick: onCancel,
        },
        {
          name: 'Confirm',
          primary: true,
          onClick: onConfirm,
          disabled: confirmDisabled,
        },
      ]}
      onClose={onClose}
    >
      {selectedPhotoFileSrc
        ? <div className={classes.profilePhotoWrapper}>
            <Loader inProgress={loading} />

            <ImageCropper
              className={classes.profilePhoto}
              src={selectedPhotoFileSrc}
              mimeType={selectedPhotoFileMimeType}
              onCrop={onCrop}
            />
          </div>
        : null
      }
    </Dialog>
  );
}
