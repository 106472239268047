import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FolderIcon from '../icons/FolderIcon';
import BackIcon from '../icons/BackIcon';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import EditButton from '../atoms/EditButton';
import CancelButton from '../atoms/CancelButton';
import DoneButton from '../atoms/DoneButton';
import Input from '../atoms/Input';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import { datetimeToDate } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT, COLOR_BLUE, COLOR_DARK_BLUE, COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE
} from '../styles/colors';
import MEDIA from '../styles/media';
import Search from '../types/Search';
import SearchesFolder from '../types/SearchesFolder';
import { Order } from '../types/Order';

interface SearchesTableProps {
  className?: string;
  items: Array<SearchesFolder | Search>;
  searches: Array<Search>;
  folders: Array<SearchesFolder>;
  limit: number;
  columns: string[];
  selectedSearches: Array<Search>;
  setSelectedSearches: (items:Array<Search>) => void;
  selectedFolders: Array<SearchesFolder>;
  setSelectedFolders: (items:Array<SearchesFolder>) => void;
  editedItems: Array<SearchesFolder | Search>;
  setEditedItems: (items:Array<SearchesFolder | Search>) => void;
  order: Order | undefined;
  setOrder: (order:Order | undefined) => void;
  sortedByColumn: string;
  onSort: (sortedByColumn:string, order:Order, sort:(searches:Search[], order:Order) => Search[]) => void;
  onSelectFolder?: (folder:SearchesFolder) => void;
  onUpdateName?: (item:SearchesFolder | Search, value:string) => void;
  onGoBack?: () => void;
  onRename: (editedItem:SearchesFolder | Search) => void;
  currentPage: number;
  setCurrentPage: (value:number) => void,
}

export const SEARCH_COLUMN = {
  NAME: 'name',
  NUMBER_OF_PLAYERS: 'numberOfPlayers',
  NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW: 'numberOfPlayersAddedSinceLastView',
  LAST_VIEWED_DATE: 'updatedAt',
};

export const SEARCH_ALL_COLUMNS = [
  SEARCH_COLUMN.NAME,
  SEARCH_COLUMN.NUMBER_OF_PLAYERS,
  SEARCH_COLUMN.NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW,
  SEARCH_COLUMN.LAST_VIEWED_DATE,
];

export const SEARCH_COLUMN_TITLE = {
  [SEARCH_COLUMN.NAME]: 'Name',
  [SEARCH_COLUMN.NUMBER_OF_PLAYERS]: <># of Players</>,
  [SEARCH_COLUMN.NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW]: <># of Players Added Since Last View</>,
  [SEARCH_COLUMN.LAST_VIEWED_DATE]: <>Last Viewed Date</>,
};

export function isFolder(item:SearchesFolder | Search) {
  return 'searches' in item;
}

const useStyles = makeStyles((theme: Theme) => ({
  searchesTableWrap: {
    overflow: 'auto',
  },

  searchesTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    minWidth: '120px',
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  truncated: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellEdited: {
    padding: 0,
  },

  goBackCell: {
    paddingLeft: theme.spacing(1),
  },

  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },

  nameCell: {
    minWidth: '180px',
  },

  actions: {
    minWidth: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginLeft: theme.spacing(0.5),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  editCell: {
    minWidth: 0,
  },
  editButton: {
    width: '32px',
    height: '32px',
    opacity: 0.3,

    '&:hover': {
      opacity: 1,
      color: COLOR_BLUE,
    },
  },
  editButtonIcon: {
    width: '32px',
    height: '32px',
  },

  searchLink: {
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  goBack: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },
  goBackIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
    color: 'inherit',
  },

  folder: {
    display: 'flex',
    alignItems: 'center',
  },
  folderIcon: {
    width: '22px',
    height: '22px',
    color: COLOR_TEXT,
  },
  folderName: {
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  input: {
    width: 'calc(100% - 4px)',
    padding: theme.spacing(0, 0, 0, 1),
  },
  inputField: {
    padding: theme.spacing(.5),
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
    },

    pagination: {
      maxWidth: '60%',
    },
  },
}), { name: SearchesTable.name });

export default function SearchesTable (props: SearchesTableProps) {
  const {
    className,
    items = [],
    searches,
    folders,
    limit,
    columns,
    selectedSearches,
    setSelectedSearches = () => {},
    editedItems,
    setEditedItems = () => {},
    order,
    setOrder = () => {},
    sortedByColumn,
    onSort = () => {},
    onSelectFolder = () => {},
    onUpdateName = () => {},
    onGoBack,
    onRename = () => {},
    selectedFolders,
    setSelectedFolders = () => {},
    currentPage,
    setCurrentPage = () => {},
  } = props;
  const classes = useStyles();

  const [originalEditedItems, setOriginalEditedItems] = useState<Array<SearchesFolder | Search>>([]);

  const totalPages = Math.ceil(items.length / limit);

  function onCheckBoxChange (checkedItem:Search) {
    return (value:boolean) => {
      if (value) {
        setSelectedSearches([...selectedSearches, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedSearches];
        const removeIndex = selectedSearches.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedSearches(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onFolderCheckboxChange (checkedItem:SearchesFolder) {
    return (value:boolean) => {
      if (value) {
        setSelectedFolders([...selectedFolders, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedFolders];
        const removeIndex = selectedFolders.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedFolders(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onEdit (editedItem:Search | SearchesFolder) {
    return () => {
      setEditedItems([...editedItems, editedItem]);
      setOriginalEditedItems([...editedItems, editedItem]);
    };
  }

  function onCancelEdit (editedItem:Search | SearchesFolder) {
    return () => {
      const originalEditedItemName = (originalEditedItems.find((item:SearchesFolder | Search) => item.id === editedItem.id) || { name: '' }).name;

      const originalEditedItemsWithoutCancelledItem = [...originalEditedItems];
      const originalRemoveIndex = originalEditedItems.findIndex(item => item.id === editedItem.id);
      if (originalRemoveIndex > -1) {
        originalEditedItemsWithoutCancelledItem.splice(originalRemoveIndex, 1);
      }

      onUpdateName(editedItem, originalEditedItemName);
      setOriginalEditedItems(originalEditedItemsWithoutCancelledItem);
      stopEditing(editedItem);
    };
  }

  function onAcceptEdit (editedItem:Search | SearchesFolder) {
    return () => {
      onRename(editedItem);
      stopEditing(editedItem);
    };
  }

  function stopEditing (editedItem:Search | SearchesFolder) {
    const editedItemsWithoutCancelledItem = [...editedItems];
    const removeIndex = editedItems.findIndex(item => item.id === editedItem.id);
    if (removeIndex > -1) {
      editedItemsWithoutCancelledItem.splice(removeIndex, 1);
    }

    setEditedItems(editedItemsWithoutCancelledItem);
  }

  function onSortByColumn (sort:(searches:Search[], order:Order) => Search[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function sortSearches (
    searches:Search[],
    order:Order,
    getValue: (search:Search) => string | number,
  ) {
    return searches.sort((first:Search, second:Search) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function onInputChange (editedItem:Search | SearchesFolder) {
    return (value:string) => {
      onUpdateName(editedItem, value);
    };
  }

  function checkOrUncheckAllItems() {
    if (items.length === [...selectedFolders, ...selectedSearches].length) {
      setSelectedSearches([]);
      setSelectedFolders([]);
    } else {
      setSelectedSearches(searches);
      setSelectedFolders(folders);
    }
  }

  function renderRow (item: Search | SearchesFolder) {
    const checked = isFolder(item) ?
    !!selectedFolders.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id) :
    !!selectedSearches.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id);

    const edited = !!editedItems.find(oneOfEditedItem => oneOfEditedItem.id === item.id);

    return (
      <TableRow
        key={isFolder(item) ? `folder_${item.id}`: `search_${item.id}`}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={checked}
            onChange={isFolder(item) ? onFolderCheckboxChange(item as SearchesFolder) : onCheckBoxChange(item as Search)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === SEARCH_COLUMN.NAME && classes.nameCell,
              (edited && tableColumn.value === SEARCH_COLUMN.NAME) && classes.tableCellEdited
            )}
          >
            {isFolder(item)
              ? tableColumn.renderFolderContent(item)
              : tableColumn.renderContent(item)
            }
          </TableCell>
        ))}

        <TableCell
          key='edit-cell'
          className={clsx(classes.tableCell, classes.editCell)}
        >
          <div className={classes.actions}>
            {!edited && (
              <EditButton
                className={clsx(classes.action, classes.editButton)}
                iconClassName={classes.editButtonIcon}
                onClick={onEdit(item)}
              />
            )}
            {edited && (
              <>
                <CancelButton
                  className={classes.action}
                  onClick={onCancelEdit(item)}
                />
                <DoneButton
                  className={classes.action}
                  onClick={onAcceptEdit(item)}
                />
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = items.length < limit ? items.length : (limit * currentPage);

  const tableColumns = columns.map((column:string) => {
    switch (column) {
      case SEARCH_COLUMN.NAME:
        return {
          value: SEARCH_COLUMN.NAME,
          title: SEARCH_COLUMN_TITLE[SEARCH_COLUMN.NAME],
          sortable: true,
          renderFolderContent: (folder:SearchesFolder) => {
            const edited = !!editedItems.find(oneOfEditedItem => oneOfEditedItem.id === folder.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={folder.name}
                  onChange={onInputChange(folder)}
                />
              )
              : (
                <div className={classes.folder}>
                  <FolderIcon className={classes.folderIcon}/>
                  <span
                    className={classes.folderName}
                    onClick={() => onSelectFolder(folder)}
                  >
                    {folder.name} ({folder.searches.length})
                  </span>
                </div>
              );
          },
          renderContent: (search:Search) => {
            const edited = !!editedItems.find(oneOfEditedItem => oneOfEditedItem.id === search.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={search.name}
                  autoFocus
                  onChange={onInputChange(search)}
                />
              )
              : (
                <Link
                  className={classes.searchLink}
                  to={`/search/${search.id}?`}
                >
                  {search.name}
                </Link>
              );
          },
          sort: (searches:Search[], order:Order) => {
            return sortSearches(
              searches,
              order,
              (search:Search) => search.name,
            );
          },
        };
      case SEARCH_COLUMN.NUMBER_OF_PLAYERS:
        return {
          value: SEARCH_COLUMN.NUMBER_OF_PLAYERS,
          title: SEARCH_COLUMN_TITLE[SEARCH_COLUMN.NUMBER_OF_PLAYERS],
          sortable: true,
          renderFolderContent: (folder: SearchesFolder) => {
            return folder.searches.reduce((totalPlayers:number, search:Search) => {
              return totalPlayers + search.numberOfPlayers;
            }, 0);
          },
          renderContent: (search:Search) => search.numberOfPlayers || <NotAvailable />,
          sort: (searches:Search[], order:Order) => {
            return sortSearches(
              searches,
              order,
              (search:Search) => search.numberOfPlayers || 0,
            );
          },
        };
      case SEARCH_COLUMN.NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW:
        return {
          value: SEARCH_COLUMN.NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW,
          title: SEARCH_COLUMN_TITLE[SEARCH_COLUMN.NUMBER_OF_PLAYERS_ADDED_SINCE_LAST_VIEW],
          sortable: true,
          renderFolderContent: (folder:SearchesFolder) => {
            return folder.searches.reduce((totalPlayers:number, search:Search) => {
              return totalPlayers + ((search.lastViewNumberOfPlayers && search.numberOfPlayers)
                ? (search.lastViewNumberOfPlayers - search.numberOfPlayers)
                : 0
              );
            }, 0);
          },
          renderContent: (search:Search) => (search.lastViewNumberOfPlayers && search.numberOfPlayers) ? (search.lastViewNumberOfPlayers - search.numberOfPlayers) : 0,
          sort: (searches:Search[], order:Order) => {
            return sortSearches(
              searches,
              order,
              (search:Search) => (search.lastViewNumberOfPlayers && search.numberOfPlayers) ? (search.lastViewNumberOfPlayers - search.numberOfPlayers) : 0,
            );
          },
        };
      case SEARCH_COLUMN.LAST_VIEWED_DATE:
        return {
          value: SEARCH_COLUMN.LAST_VIEWED_DATE,
          title: SEARCH_COLUMN_TITLE[SEARCH_COLUMN.LAST_VIEWED_DATE],
          sortable: true,
          renderFolderContent: (folder:SearchesFolder) => datetimeToDate(folder.updatedAt),
          renderContent: (search:Search) => datetimeToDate(search.updatedAt),
          sort: (searches:Search[], order:Order) => {
            return sortSearches(
              searches,
              order,
              (search:Search) => +(new Date(search.updatedAt)),
            );
          },
        };
      default:
        return null;
    }
  });

  return (
    <>
      <div className={classes.searchesTableWrap}>
        <Table className={clsx(classes.searchesTable, className)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
              >
                <Checkbox
                  checked={items.length === [...selectedFolders, ...selectedSearches].length}
                  onChange={checkOrUncheckAllItems}
                />
              </TableHeadCell>

              {tableColumns.map((tableColumn:any) => (
                <TableHeadCell
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable
                    ? onSortByColumn(tableColumn.sort || ((searches:Search[]) => searches))
                    : undefined
                  }
                >
                  {tableColumn.title}
                </TableHeadCell>
              ))}

              <TableHeadCell
                key='edit-cell'
                className={classes.editCell}
              >
                Edit
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!!onGoBack && (
              <TableRow
                key='go-back'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.goBackCell)}
                  colSpan={tableColumns.length + 2}
                >
                  <div
                    className={classes.goBack}
                    onClick={onGoBack}
                  >
                    <BackIcon className={classes.goBackIcon} />
                    Back
                  </div>
                </TableCell>
              </TableRow>
            )}

            {!items.length && (
              <TableRow
                key='empty'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.emptyCell)}
                  colSpan={tableColumns.length + 2}
                >
                  <div className={classes.empty}>
                    No searches
                  </div>
                </TableCell>
              </TableRow>
            )}

            {items
              .slice(itemsFrom, itemsTo)
              .map(renderRow)}
          </TableBody>
        </Table>
      </div>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {items.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>
    </>
  );
}
