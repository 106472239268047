export enum ScreenSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP_SMALL = 'desktop-small',
  DESKTOP_LARGE = 'desktop-large',
}

export const BREAKPOINT = {
  [ScreenSize.MOBILE]: 1,
  [ScreenSize.TABLET]: 768,
  [ScreenSize.DESKTOP_SMALL]: 1025,
  [ScreenSize.DESKTOP_LARGE]: 1440,
};

export function getScreenSize () {
  const windowWidth = window.innerWidth;

  let screenSize = ScreenSize.MOBILE;
  if (windowWidth >= BREAKPOINT[ScreenSize.TABLET] && windowWidth < BREAKPOINT[ScreenSize.DESKTOP_SMALL]) {
    screenSize = ScreenSize.TABLET;
  } else if (windowWidth >= BREAKPOINT[ScreenSize.DESKTOP_SMALL] && windowWidth < BREAKPOINT[ScreenSize.DESKTOP_LARGE]) {
    screenSize = ScreenSize.DESKTOP_SMALL;
  } else if (windowWidth >= BREAKPOINT[ScreenSize.DESKTOP_LARGE]) {
    screenSize = ScreenSize.DESKTOP_LARGE;
  }

  return screenSize;
}

export function isDesktopLarge (screenSize:ScreenSize) {
  return screenSize === ScreenSize.DESKTOP_LARGE;
}

export function isDesktop (screenSize:ScreenSize) {
  return screenSize === ScreenSize.DESKTOP_SMALL || screenSize === ScreenSize.DESKTOP_LARGE;
}

export function isTablet (screenSize:ScreenSize) {
  return screenSize === ScreenSize.TABLET;
}

export function isMobile (screenSize:ScreenSize) {
  return screenSize === ScreenSize.MOBILE;
}
