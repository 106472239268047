import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface CFCLogoProps {
  className?: string;
  state: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  cfcLogo: {
    width: '30px',
  },
}), { name: CFCLogo.name });

export default function CFCLogo (props:CFCLogoProps) {
  const { className, state } = props;
  const classes = useStyles();

  const isCanada = ['CAN', 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'].includes(state);

  if (!isCanada) return null;

  return (
    <img
      className={clsx(classes.cfcLogo, className)}
      src='/images/websites/cfc.png'
      alt='CFC logo'
    />
  );
}
