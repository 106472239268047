import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '../atoms/Tooltip';
import {
  COLOR_ORANGE,
  COLOR_WHITE,
} from '../styles/colors';

interface ShowMoreDotsProps {
  className?: string;
  iconClassName?: string;
  tooltipContent: any;
}

const useStyles = makeStyles((theme:Theme) => ({
  more: {
    display: 'flex',
    background: COLOR_WHITE,
    cursor: 'pointer',
  },
  moreIcon: {
    width: 32,
    height: 32,
    color: COLOR_ORANGE,
  },
}), { name: ShowMoreDots.name });

export default function ShowMoreDots (props:ShowMoreDotsProps) {
  const {
    className,
    iconClassName,
    tooltipContent,
  } = props;
  const classes = useStyles();

  return (
    <Tooltip title={tooltipContent}>
      <div className={clsx(classes.more, className)}>
        <MoreHorizIcon className={clsx(classes.moreIcon, iconClassName)} />
      </div>
    </Tooltip>
  );
}
