import React from 'react';
import Icon from '../atoms/Icon';

function PinIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M21.556 6.641l3.337 3.336-8.091 10.345-5.476-5.476 10.23-8.205m.248-4.517L6.287 14.57 17.11 25.393l12.273-15.69-7.58-7.579z'/>
        <path d='M0 29.149l11.284-11.284 2.358 2.357L2.358 31.507H0z'/>
        <path d='M6.244 10.105L21.4 25.263l-2.381 2.382L3.862 12.487l2.382-2.382zM19.717 0l11.79 11.79-2.382 2.381-11.79-11.79L19.717 0z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(PinIcon);
