const CURRENT_PATH_KEY = 'current-path';

export function saveCurrentPath () {
  const currentPath = window.location.pathname;
  sessionStorage.setItem(CURRENT_PATH_KEY, currentPath);
}

export function retrieveLastPath () {
  return sessionStorage.getItem(CURRENT_PATH_KEY);
}
