import React from 'react';
import { useLocation } from 'react-router-dom';
import DepthChartPageTemplate from '../templates/DepthChartPageTemplate';
import Page from './Page';
import { getQueryParameter } from '../services/query-parameters';

export default function DepthChartsPage () {
  const location = useLocation();
  const printed = location.pathname.endsWith('/pdf');

  const depthChartType = getQueryParameter('depth-chart-type');

  return (
    <Page
      title='Depth Chart'
      printed={printed}
    >
      <DepthChartPageTemplate
        depthChartType={depthChartType}
        printed={printed}
      />
    </Page>
  );
}
