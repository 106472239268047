import State from '../../types/State';

export const SET_ALL_STATES = 'states/SET_ALL_STATES';
export const SET_AMERICAN_STATES = 'states/SET_AMERICAN_STATES';
export const SET_STATES_LOADING = 'states/SET_STATES_LOADING';

export function setAllStates (allStates:State[]) {
  return {
    type: SET_ALL_STATES,
    allStates,
  };
}

export function setAmericanStates (americanStates:State[]) {
  return {
    type: SET_AMERICAN_STATES,
    americanStates,
  };
}

export function setStatesLoading (statesLoading:boolean) {
  return {
    type: SET_STATES_LOADING,
    statesLoading,
  };
}
