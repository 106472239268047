import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '../atoms/Accordion';
import Loader from '../atoms/Loader';
import TeamsList from '../molecules/TeamsList';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT, COLOR_MIDDLE_GRAY, COLOR_BACKGROUND_WARM, COLOR_WHITE } from '../styles/colors';
import Conference from '../types/Conference';
import Team from '../types/Team';

interface ConferencesListProps {
  className?: string;
  divisionName?: string;
  useConferenceAbbr?: boolean;
  conferences: Conference[];
  teams: Team[];
  loading: boolean;
  hasSubDivision?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  conferencesList: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    maxWidth: '620px',
    position: 'relative',
  },

  header: {
    display: 'flex',
    padding: theme.spacing(2),
    background: COLOR_MIDDLE_GRAY,
    fontFamily: FONT_PROXIMA_NOVA,
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  subDivision: {
    fontWeight: 700,
  },
  division: {
  },

  content: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
  },

  accordionSummary: {
    height: '80px',
    padding: theme.spacing(2, 0),
    background: COLOR_BACKGROUND_WARM,
    borderTop: `1px solid ${COLOR_WHITE}`,
    borderBottom: 0,

    '&:hover': {
      background: COLOR_MIDDLE_GRAY,
    },
  },
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2.5),
  },
  accordionExpandButton: {
    right: theme.spacing(2),
  },

  conferenceLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40px',
    marginRight: theme.spacing(2.5),
  },
  conferenceLogo: {
    maxWidth: '40px',
    maxHeight: '40px',
  },
  conferenceName: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 'bold',
    lineHeight: 1,
    color: COLOR_TEXT,
  },

  '@media (max-width: 1023px)': {
    conferencesList: {
      width: '100%',
      maxWidth: 'none',
    },
  },
}), { name: ConferencesList.name });

export default function ConferencesList (props: ConferencesListProps) {
  const {
    className,
    divisionName,
    conferences,
    teams,
    useConferenceAbbr = false,
    loading,
    hasSubDivision = true
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.conferencesList, className)}>
      <Loader inProgress={loading} />

      {divisionName && (
        <div className={classes.header}>
          <span className={classes.subDivision}>{divisionName}</span>
          {hasSubDivision && <span className={classes.division}>&nbsp;(Division I)</span>}
        </div>
      )}

      <div className={classes.content}>
        {conferences.map((conference:Conference, index:number) => {
          const conferenceTeamsIds = (conference.teams && conference.teams.length)
            ? conference.teams.map(team => team.id)
            : conference?.teams?.map(team => team.id);
          const conferenceTeams = teams.filter(team => conferenceTeamsIds?.includes(team.id));

          return (
            <Accordion
              key={index}
              summaryClassName={classes.accordionSummary}
              summaryContentClassName={classes.accordionSummaryContent}
              expandButtonClassName={classes.accordionExpandButton}
              summary={(
                <>
                  <div className={classes.conferenceLogoWrapper}>
                    {conference.iconUrl && (
                      <img
                        className={classes.conferenceLogo}
                        src={conference.iconUrl}
                        alt={`${conference.name} conference logo`}
                      />
                    )}
                  </div>

                  <span className={classes.conferenceName}>
                  {useConferenceAbbr ? conference.abbr : conference.name}
                </span>
                </>
              )}
              expanded={conferences.length === 1}
            >
              <TeamsList teams={conferenceTeams} />
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}
