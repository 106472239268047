import React from 'react';
import Icon from '../atoms/Icon';

function TrackIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        d='M12.156 18.839l.121-.217c.568 1.828.86 3.776.85 5.677a4.902 4.902 0 01-.388-.064c-1.471-.307-2.09-1.282-1.787-2.702.191-.889.729-1.847 1.204-2.694m2.157 5.548a19.933 19.933 0 00-1.214-7.001l6.274.672c.114.192.265.42.447.676-3.089 1.486-4.667 3.993-4.743 5.677a45.97 45.97 0 01-.764-.024m1.947.057c.071-1.12 1.308-3.407 4.282-4.756.552.699 1.23 1.5 1.966 2.317l-.884.541c-.772.473-1.716 1.46-2.116 2.294-.96-.274-2.12-.357-3.248-.396m4.35.832c.265-.504.953-1.318 1.64-1.739l1.065-.652a48.336 48.336 0 002.205 2.213c-.22.142-.42.31-.592.503-.41.46-.632 1.005-.806 1.522-.356-.188-.721-.385-1.074-.576-.856-.462-1.735-.937-2.438-1.27m4.577 2.394c.155-.478.326-.957.631-1.3.17-.191.39-.343.632-.439.259.222.508.428.75.614.463.359 1.84 1.015 2.527 1.447-.044.126-.19.86-1.049.84-1.058-.025-2.97-.903-3.491-1.162M1.03 4.04l.526 1.896a3.64 3.64 0 001.908 2.282l-.033.522a2.858 2.858 0 001.518 2.687c-.1 1.56.873 3.507 2.374 4.023l4.467 1.53c-.151.368-.405.82-.67 1.295-.515.917-1.099 1.957-1.327 3.018-.444 2.08.59 3.644 2.702 4.084.751.155 1.754.18 2.816.207 1.525.038 3.254.081 4.33.537.704.297 1.788.883 2.838 1.449 1.84.993 3.021 1.543 3.653 1.741.777.244 2.333.689 2.546.689 1.826 0 2.14-1.607 2.277-1.94.408-.987-2.093-1.706-3.023-2.431-2.851-2.207-7.145-7.24-7.64-8.351a.591.591 0 00-.478-.345l-6.663-.715c-.027-.014-5.44-1.871-5.44-1.871-.868-.296-1.47-1.563-1.57-2.439l8.146 3.129c.29.114.643-.02.767-.331a.58.58 0 00-.336-.755l-8.961-3.443a1.698 1.698 0 01-1.144-1.695l.01-.152 10 3.645a.592.592 0 00.76-.344.579.579 0 00-.35-.75L4.208 7.267A2.459 2.459 0 012.7 5.627l-.344-1.233L16.24 9.736c1.032.422 2.056 1.828 2.18 3.006.002.017.418 2.185.418 2.185.058.3.347.524.692.465a.584.584 0 00.471-.681l-.405-2.114c-.18-1.595-1.482-3.359-2.914-3.944L2.143 3.06a.835.835 0 00-.86.147.81.81 0 00-.253.833'
        stroke='currentcolor'
        strokeWidth='1.074'
        fill='currentcolor'
        fillRule='evenodd'
      />
    </Icon>
  );
}

export default React.forwardRef(TrackIcon);
