import React from 'react';
import Icon from '../atoms/Icon';

function UpdateIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        transform='translate(0, 1)'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M14.77 0c8.143 0 14.768 6.625 14.768 14.77h-2.461c0-6.787-5.521-12.308-12.308-12.308-6.786 0-12.307 5.52-12.307 12.307 0 6.787 5.52 12.308 12.307 12.308 1.098 0 2.157-.159 3.17-.43l-.289 2.605a14.73 14.73 0 01-2.88.286C6.624 29.538 0 22.913 0 14.77S6.625 0 14.77 0zm0 7.385l6.153 6.153H16v8.616h-2.462v-8.616H8.615l6.154-6.153z'
          fill='currentColor'
          fillRule='nonzero'
        />
        <circle
          fill='#F98B60'
          cx='25.846'
          cy='23.385'
          r='6.154'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(UpdateIcon);