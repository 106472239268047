import {
  SET_FBS_CONFERENCES_LOADING,
  SET_FBS_CONFERENCES,
  SET_FCS_CONFERENCES_LOADING,
  SET_FCS_CONFERENCES,
  SET_DIV_II_CONFERENCES_LOADING,
  SET_DIV_II_CONFERENCES,
  SET_DIV_III_CONFERENCES_LOADING,
  SET_DIV_III_CONFERENCES,
} from '../actions/conferences';
import Conference from '../../types/Conference';

export interface ConferencesState {
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  divIIConferences: Conference[];
  divIIConferencesLoading: boolean;
  divIIIConferences: Conference[];
  divIIIConferencesLoading: boolean;
}

const INITIAL_STATE:ConferencesState = {
  fbsConferences: [],
  fbsConferencesLoading: false,
  fcsConferences: [],
  fcsConferencesLoading: false,
  divIIConferences: [],
  divIIConferencesLoading: false,
  divIIIConferences: [],
  divIIIConferencesLoading: false,
};

export function getConferencesInitialState () {
  return INITIAL_STATE;
}

export default function conferencesReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_FBS_CONFERENCES_LOADING:
      return {
        ...state,
        fbsConferencesLoading: action.fbsConferencesLoading,
      };
    case SET_FBS_CONFERENCES:
      return {
        ...state,
        fbsConferences: action.fbsConferences,
      };
    case SET_FCS_CONFERENCES_LOADING:
      return {
        ...state,
        fcsConferencesLoading: action.fcsConferencesLoading,
      };
    case SET_FCS_CONFERENCES:
      return {
        ...state,
        fcsConferences: action.fcsConferences,
      };
    case SET_DIV_II_CONFERENCES_LOADING:
      return {
        ...state,
        divIIConferencesLoading: action.divIIConferencesLoading,
      };
    case SET_DIV_II_CONFERENCES:
      return {
        ...state,
        divIIConferences: action.divIIConferences,
      };
    case SET_DIV_III_CONFERENCES_LOADING:
      return {
        ...state,
        divIIIConferencesLoading: action.divIIIConferencesLoading,
      };
    case SET_DIV_III_CONFERENCES:
      return {
        ...state,
        divIIIConferences: action.divIIIConferences,
      };
    default:
      return state;
  }
};
