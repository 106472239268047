import { Dispatch } from 'redux';
import {
  setAchievementsVisibilityAction,
  setAchievements,
} from '../actions/achievements';
import gql from '../../services/gql';
import * as clientStorage from '../../services/client-storage';
import { StorageKey } from '../../services/client-storage';
import { cache, CacheExpiration, CacheKey } from '../../services/cache';
import { State } from '../reducers';
import {
  AchievementsVisibility,
  COLLEGE_ACHIEVEMENTS,
  NFL_ACHIEVEMENTS,
} from '../reducers/achievements';
import { Achievement } from '../../types/Achievement';
import User from '../../types/User';

export function fetchAchievements () {
  return (dispatch:Dispatch, getState:() => State) => {
    const {
      ui: { nflAccess },
      achievements: { achievements },
    } = getState();
    const achievementsToBeCompleted = nflAccess ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS;
    const completedAchievements = achievementsToBeCompleted
      .filter((achievementInOrder:Achievement) => achievements.includes(achievementInOrder));

    if (completedAchievements?.length < achievementsToBeCompleted?.length) {
      return gql('achievements')
        .then((data:any) => data && data.achievements as Achievement[])
        .then((achievements:Achievement[]) => {
          const achievementsToStore = achievements?.length === 0 ? [Achievement.LOGIN] : achievements;
          clientStorage.save(StorageKey.ACHIEVEMENTS, achievementsToStore);
          dispatch(setAchievements(achievementsToStore));
        })
        .catch(console.error);
    }
  };
}

export function minimizeAchievements () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { user } = getState();

    dispatch(setAchievementsVisibilityAction(AchievementsVisibility.MINIMIZED));

    const userWithUpdatedAchievementsFlag = {
      ...user,
      maximizeAchievements: false,
    } as User;

    cache(CacheKey.USER, userWithUpdatedAchievementsFlag, CacheExpiration.SHORT);

    return gql(`
      mutation {
        minimizeAchievements
      }
    `)
      .then((data:any) => data && data.minimizeAchievements as boolean)
      .catch(console.error);
  };
}

export function maximizeAchievements () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { user } = getState();

    dispatch(setAchievementsVisibilityAction(AchievementsVisibility.OPENED));

    const userWithUpdatedAchievementsFlag = {
      ...user,
      maximizeAchievements: true,
    } as User;

    cache(CacheKey.USER, userWithUpdatedAchievementsFlag, CacheExpiration.SHORT);

    return gql(`
      mutation {
        maximizeAchievements
      }
    `)
      .then((data:any) => data && data.maximizeAchievements as boolean)
      .catch(console.error);
  };
}

export function showAchievements () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { user } = getState();

    dispatch(setAchievementsVisibilityAction(AchievementsVisibility.OPENED));

    const userWithUpdatedAchievementsFlag = {
      ...user,
      showAchievements: true,
    } as User;

    cache(CacheKey.USER, userWithUpdatedAchievementsFlag, CacheExpiration.SHORT);

    return gql(`
      mutation {
        showAchievements
      }
    `)
      .then((data:any) => data && data.showAchievements as boolean)
      .catch(console.error);
  };
}

export function hideAchievements () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { user } = getState();

    dispatch(setAchievementsVisibilityAction(AchievementsVisibility.CLOSED));

    const userWithUpdatedAchievementsFlag = {
      ...user,
      showAchievements: false,
    } as User;

    cache(CacheKey.USER, userWithUpdatedAchievementsFlag, CacheExpiration.SHORT);

    return gql(`
      mutation {
        hideAchievements
      }
    `)
      .then((data:any) => data && data.hideAchievements as boolean)
      .catch(console.error);
  };
}
