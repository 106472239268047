import {
  CONFIGURATIONS_LOADING,
  CONFIGURATIONS_LOADED,
  CURRENT_RECRUITING_YEAR,
  NFL_DRAFT_YEAR,
  NEW_HS_PLAYERS_DAYS,
  NEW_COLLEGE_PLAYERS_DAYS,
  DASHBOARD_ROLLOVER_DAY,
  DASHBOARD_ROLLOVER_MONTH,
  ROLLOVER_DAY,
  ROLLOVER_MONTH,
  TRACK_DATE_PAST_YEAR_LIMIT,
  ROLLOVER_DATE_TRANSFER_PORTAL,
  ROLLOVER_MONTH_TRANSFER_PORTAL,
} from '../actions/configurations';

export interface ConfigurationsState {
  configurationsLoading: boolean;
  configurationsLoaded: boolean;
  currentRecruitingYear: number;
  nflDraftYear: number;
  newHSPlayersDays: number;
  newCollegePlayersDays: number;
  dashboardRolloverDay: number;
  dashboardRolloverMonth: number;
  rolloverDay: number;
  rolloverMonth: number;
  rolloverDateTransferPortal: number;
  rolloverMonthTransferPortal: number;
  trackDatePastYearLimit: number;
}

const INITIAL_STATE:ConfigurationsState = {
  configurationsLoading: false,
  configurationsLoaded: false,
  currentRecruitingYear: (new Date()).getFullYear(),
  nflDraftYear: (new Date()).getFullYear(),
  newHSPlayersDays: 7,
  newCollegePlayersDays: 14,
  dashboardRolloverDay: 1,
  dashboardRolloverMonth: 3,
  rolloverDay: 1,
  rolloverMonth: 8,
  trackDatePastYearLimit: 15,
  rolloverDateTransferPortal: 1,
  rolloverMonthTransferPortal: 8,
};

export function getConfigurationsInitialState () {
  return INITIAL_STATE;
}

export default function configurationsReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case CONFIGURATIONS_LOADING:
      return {
        ...state,
        configurationsLoading: action.configurationsLoading,
      };
    case CONFIGURATIONS_LOADED:
      return {
        ...state,
        configurationsLoaded: action.configurationsLoaded,
      };
    case CURRENT_RECRUITING_YEAR:
      return {
        ...state,
        currentRecruitingYear: action.currentRecruitingYear,
      };
    case NFL_DRAFT_YEAR:
      return {
        ...state,
        nflDraftYear: action.nflDraftYear,
      };
    case NEW_HS_PLAYERS_DAYS:
      return {
        ...state,
        newHSPlayersDays: action.newHSPlayersDays,
      };
    case NEW_COLLEGE_PLAYERS_DAYS:
      return {
        ...state,
        newCollegePlayersDays: action.newCollegePlayersDays,
      };
    case DASHBOARD_ROLLOVER_DAY:
      return {
        ...state,
        dashboardRolloverDay: action.dashboardRolloverDay,
      };
    case DASHBOARD_ROLLOVER_MONTH:
      return {
        ...state,
        dashboardRolloverMonth: action.dashboardRolloverMonth,
      };
    case ROLLOVER_DAY:
      return {
        ...state,
        rolloverDay: action.rolloverDay,
      };
    case ROLLOVER_MONTH:
      return {
        ...state,
        rolloverMonth: action.rolloverMonth,
      };
    case TRACK_DATE_PAST_YEAR_LIMIT:
      return {
        ...state,
        trackDatePastYearLimit: action.trackDatePastYearLimit,
      };
    case ROLLOVER_DATE_TRANSFER_PORTAL:
      return {
        ...state,
        rolloverDateTransferPortal: action.rolloverDateTransferPortal,
      };
    case ROLLOVER_MONTH_TRANSFER_PORTAL:
      return {
        ...state,
        rolloverMonthTransferPortal: action.rolloverMonthTransferPortal,
      };
    default:
      return state;
  }
};
