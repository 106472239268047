import React from 'react';
import Page from './Page';
import ProgramPageTemplate from '../templates/ProgramPageTemplate';

export default function ProgramPage () {
  return (
    <Page title='Program'>
      <ProgramPageTemplate />
    </Page>
  )
}