import Team from '../../types/Team';

export const SET_DIV_I_TEAMS = 'teams/SET_DIV_I_TEAMS';
export const SET_DIV_I_TEAMS_LOADING = 'teams/SET_DIV_I_TEAMS_LOADING';
export const SET_COLLEGE_TEAMS = 'teams/SET_COLLEGE_TEAMS';
export const SET_COLLEGE_TEAMS_LOADING = 'teams/SET_COLLEGE_TEAMS_LOADING';
export const SET_JUCO_TEAMS = 'teams/SET_JUCO_TEAMS';
export const SET_JUCO_TEAMS_LOADING = 'teams/SET_JUCO_TEAMS_LOADING';
export const SET_NFL_TEAMS = 'teams/SET_NFL_TEAMS';
export const SET_NFL_TEAMS_LOADING = 'teams/SET_NFL_TEAMS_LOADING';

export function setDivITeams (divITeams:Team[]) {
  return {
    type: SET_DIV_I_TEAMS,
    divITeams,
  };
}

export function setDivITeamsLoading (divITeamsLoading:boolean) {
  return {
    type: SET_DIV_I_TEAMS_LOADING,
    divITeamsLoading,
  };
}

export function setCollegeTeams (collegeTeams:Team[]) {
  return {
    type: SET_COLLEGE_TEAMS,
    collegeTeams,
  };
}

export function setCollegeTeamsLoading (collegeTeamsLoading:boolean) {
  return {
    type: SET_COLLEGE_TEAMS_LOADING,
    collegeTeamsLoading,
  };
}

export function setJucoTeams (jucoTeams:Team[]) {
  return {
    type: SET_JUCO_TEAMS,
    jucoTeams,
  };
}

export function setJucoTeamsLoading (jucoTeamsLoading:boolean) {
  return {
    type: SET_JUCO_TEAMS_LOADING,
    jucoTeamsLoading,
  };
}

export function setNFLTeams (nflTeams:Team[]) {
  return {
    type: SET_NFL_TEAMS,
    nflTeams,
  };
}

export function setNFLTeamsLoading (nflTeamsLoading:boolean) {
  return {
    type: SET_NFL_TEAMS_LOADING,
    nflTeamsLoading,
  };
}
