import { Dispatch } from 'redux';
import {
  setDivITeams,
  setDivITeamsLoading,
  setCollegeTeams,
  setCollegeTeamsLoading,
  setNFLTeams,
  setNFLTeamsLoading,
  setJucoTeams,
  setJucoTeamsLoading,
} from '../actions/teams';
import gql from '../../services/gql';
import { CacheKey, CacheExpiration, cache, retrieve } from '../../services/cache';
import { State } from '../reducers';
import Team from '../../types/Team';

export function fetchDivITeams () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { teams: { divITeams, divITeamsLoading } } = getState();

    if (divITeamsLoading || divITeams.length > 0) return;

    const cachedDivITeams = retrieve(CacheKey.DIV_I_TEAMS);
    const cacheIsValid = !!cachedDivITeams?.length;

    if (cacheIsValid) {
      dispatch(setDivITeams(cachedDivITeams));
      return;
    }

    dispatch(setDivITeamsLoading(true));

    return gql(`
      divITeams {
        id
        slug
        type
        name
        shortName
        logo247
        logoESPN
        logoAlt
        averagePAI
        totalWithPAI
        playerCount
        pai
        combine
        proCombine
        ppi
        stateAbbr
        conference {
          iconUrl
        }
      }
    `)
      .then((data:any) => data?.divITeams as Team[])
      .then((divITeams:Team[]) => {
        if (divITeams && divITeams.length > 0) {
          const sortedDivITeams = sortTeams(divITeams);

          dispatch(setDivITeams(sortedDivITeams));
          cache(CacheKey.DIV_I_TEAMS, sortedDivITeams, CacheExpiration.LONG);
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDivITeamsLoading(false));
      });
  };
}

export function fetchCollegeTeams () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { teams: { collegeTeams, collegeTeamsLoading } } = getState();

    if (collegeTeamsLoading || collegeTeams.length > 0) return;

    const cachedCollegeTeams = retrieve(CacheKey.COLLEGE_TEAMS);
    const cacheIsValid = !!cachedCollegeTeams?.length;

    if (cacheIsValid) {
      dispatch(setCollegeTeams(cachedCollegeTeams));
      return;
    }

    dispatch(setCollegeTeamsLoading(true));

    return gql(`
      teams(type: "College") {
        id
        slug
        type
        name
        shortName
        logo247
        logoESPN
        logoAlt
        averagePAI
        totalWithPAI
        playerCount
      }
    `)
      .then((data:any) => data?.teams as Team[])
      .then((collegeTeams:Team[]) => {
        if (collegeTeams && collegeTeams.length > 0) {
          const sortedCollegeTeams = sortTeams(collegeTeams);

          dispatch(setCollegeTeams(sortedCollegeTeams));
          cache(CacheKey.COLLEGE_TEAMS, sortedCollegeTeams, CacheExpiration.LONG);
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setCollegeTeamsLoading(false));
      });
  };
}

export function fetchJucoTeams () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { teams: { jucoTeams, jucoTeamsLoading } } = getState();

    if (jucoTeamsLoading || jucoTeams.length > 0) return;

    const cachedJucoTeams = retrieve(CacheKey.JUCO_TEAMS);
    const cacheIsValid = !!cachedJucoTeams?.length;

    if (cacheIsValid) {
      dispatch(setJucoTeams(cachedJucoTeams));
      return;
    }

    dispatch(setJucoTeamsLoading(true));

    return gql(`
      jucoTeams {
        id
        slug
        type
        name
        shortName
        logo247
        logoESPN
        logoAlt
        averagePAI
        totalWithPAI
        playerCount
      }
    `)
      .then((data:any) => data?.jucoTeams as Team[])
      .then((jucoTeams:Team[]) => {
        if (jucoTeams && jucoTeams.length > 0) {
          const sortedJucoTeams = sortTeams(jucoTeams);
          dispatch(setJucoTeams(sortedJucoTeams));
          cache(CacheKey.JUCO_TEAMS, sortedJucoTeams, CacheExpiration.SHORT);
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setJucoTeamsLoading(false));
      });
  };
}

export function fetchNFLTeams () {
  return (dispatch:Dispatch, getState:() => State) => {
    const { teams: { nflTeams, nflTeamsLoading } } = getState();

    if (nflTeamsLoading || nflTeams.length > 0) return;

    const cachedNFLTeams = retrieve(CacheKey.NFL_TEAMS);
    const cacheIsValid = !!cachedNFLTeams?.length;

    if (cacheIsValid) {
      dispatch(setNFLTeams(cachedNFLTeams));
      return;
    }

    dispatch(setNFLTeamsLoading(true));

    return gql(`
      teams(type: "NFL") {
        id
        slug
        type
        name
        shortName
        logo247
        logoESPN
        logoAlt
        averagePAI
        totalWithPAI
        playerCount
      }
    `)
      .then((data:any) => data?.teams as Team[])
      .then((nflTeams:Team[]) => {
        if (nflTeams && nflTeams.length > 0) {
          const sortedNFLTeams = sortTeams(nflTeams);

          dispatch(setNFLTeams(sortedNFLTeams));
          cache(CacheKey.NFL_TEAMS, sortedNFLTeams, CacheExpiration.LONG);
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setNFLTeamsLoading(false));
      });
  };
}

function sortTeams (teams:Team[]):Team[] {
  return [...teams].sort((nflTeam1:Team, nflTeam2:Team) => {
    if (nflTeam1.name < nflTeam2.name) return -1;
    if (nflTeam1.name > nflTeam2.name) return 1;
    return 0;
  });
}
