import React from 'react';
import Icon from '../atoms/Icon';

function PDFIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M20.475 3.2L26.6 9.325V28.8H7.4V24H4.2v4.8c0 1.765 1.435 3.2 3.2 3.2h19.2c1.765 0 3.2-1.435 3.2-3.2V8l-8-8H7.4a3.203 3.203 0 00-3.2 3.2v9.6h3.2V3.2h13.075z'/>
        <path d='M27.4 9.6h-7.2V2.4l7.2 7.2zM6.6 16h-.8v1.6h.8a.8.8 0 000-1.6zm6.605.037H12.2V20.8h.984c1.011 0 2.216-.306 2.216-1.758v-1.236c0-1.462-1.194-1.77-2.195-1.77z'/>
        <path d='M4.2 11.2A3.2 3.2 0 001 14.4v8a3.2 3.2 0 003.2 3.2h24V11.2h-24zm2.4 8h-.8v3.2H4.2v-8h2.4a2.4 2.4 0 010 4.8zm10.4-.158c0 1.868-1.266 3.358-3.816 3.358H10.6v-7.963h2.605c2.531 0 3.795 1.523 3.795 3.37v1.235zM23.4 16h-3.2v1.6h2.56v1.6H20.2v3.2h-1.6v-8h4.8V16z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(PDFIcon);
