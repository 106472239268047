import React from 'react';
import Icon from '../atoms/Icon';

function UserCircleIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(4, 4)' stroke='currentColor' strokeWidth='2'>
          <path d='M11.9999998,0 C7.71281288,0 3.75128858,2.287187 1.60769501,6 C-0.535898574,9.7128129 -0.535898574,14.2871871 1.60769501,18 C3.75128858,21.712813 7.71281288,24 11.9999998,24 C16.2871871,24 20.2487114,21.712813 22.392305,18 C24.5358986,14.2871871 24.5358986,9.7128129 22.392305,6 C20.2487114,2.287187 16.2871871,0 11.9999998,0 Z' />
          <path d='M12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 Z M6,19 C6,15.686 8.686,13 12,13 C15.314,13 18,15.686 18,19' />
        </g>
    </g>
    </Icon>
  );
}

export default React.forwardRef(UserCircleIcon);
