import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import TeamLogo from '../atoms/TeamLogo';
import Badge from '../atoms/Badge';
import ShowMoreDots from './ShowMoreDots';
import gql from '../services/gql';
import { COLOR_ORANGE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Team from '../types/Team';

interface OffersProps {
  className?: string;
  offers: string[];
  limit: number;
  displayTotalOffersCount?: boolean;
}

const HEIGHT = 32; // px
const MORE_WIDTH = 28; // px

const useStyles = makeStyles((theme: Theme) => ({
  offers: {
    width: '100%',
    display: 'flex',
  },
  badge: {
    height: '24px',
    margin: theme.spacing(.5, 2, 0, 0),
    fontSize: theme.typography.pxToRem(14),
  },

  offersList: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: `0 ${MORE_WIDTH + 12}px 0 0`,
  },
  offer: {
    marginLeft: theme.spacing(2),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  teamLogo: {
    width: `${HEIGHT}px`,
    height: `${HEIGHT}px`,
  },

  more: {
    width: `${MORE_WIDTH}px`,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    background: 'transparent',
  },
  moreIcon: {
    width: `${HEIGHT}px`,
    height: `${HEIGHT}px`,
    color: COLOR_ORANGE,
  },

  names: {
    margin: 0,
    padding: theme.spacing(1, 0),
    listStyle: 'none',
    textAlign: 'center',
  },
  name: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
}), { name: Offers.name });

export default function Offers (props: OffersProps) {
  const {
    className,
    offers = [],
    limit,
    displayTotalOffersCount = false,
  } = props;
  const classes = useStyles();

  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    if (offers?.length) {
      fetchTeams(offers);
    }
  }, [offers?.length]);

  if (!offers || !offers.length) return null;

  function fetchTeams (offers:string[]) {
    gql(`
      teamsByShortNames (shortNames: ["${offers.join('", "')}"]) {
        name
        shortName
        logo247
        logoESPN
        logoAlt
      }
    `)
      .then((data:any) => data.teamsByShortNames as Team[])
      .then((teams:Team[]) => setTeams(teams))
      .catch(console.error);
  }

  function getTeamForOffer (offer:string) {
    return teams.find((team:Team) => team.shortName === offer) || ({} as Team);
  }

  return (
    <div className={clsx(classes.offers, className)}>
      {displayTotalOffersCount && (
        <Badge
          className={classes.badge}
          value={offers.length.toString()}
        />
      )}

      <ul className={classes.offersList}>
        {offers.slice(0, limit).map((offer, index) => (
          <li
            key={index}
            className={classes.offer}
          >
            <TeamLogo
              className={classes.teamLogo}
              team={getTeamForOffer(offer)}
            />
          </li>
        ))}

        <ShowMoreDots
          className={classes.more}
          tooltipContent={(
            <ul className={classes.names}>
              {offers.map(offer => (
                <li
                  key={offer}
                  className={classes.name}
                >
                  {getTeamForOffer(offer).name || ''}
                </li>
              ))}
            </ul>
          )}
        />
      </ul>
    </div>
  );
}
