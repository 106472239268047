import React from 'react';
import Page from './Page';
import DashboardPageTemplate from '../templates/DashboardPageTemplate';

export default function DashboardPage () {
  return (
    <Page title='Dashboard'>
      <DashboardPageTemplate />
    </Page>
  );
}
