import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MyAccountsBoard from '../organisms/MyAccountsBoard';
import PageContainer from './PageContainer';

const useStyles = makeStyles(theme => ({
  accountPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
  },
  myAccountBoard: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
  },

  '@media (max-width: 1023px)': {
    myAccountBoard: {
      display: 'block',
    },
  },
}), { name: MyAccountPageTemplate.name });

export default function MyAccountPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.accountPageTemplate}>
      <Paper className={classes.paper}>
        <MyAccountsBoard className={classes.myAccountBoard} />
      </Paper>
    </PageContainer>
  )
}
