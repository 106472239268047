import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import HelpPopupFooter from '../atoms/HelpPopupFooter';
import Loader from '../atoms/Loader';
import Dialog from './Dialog';
import { State } from '../redux/reducers';
import { fetchStates } from '../redux/dispatchers/states';
import gql from '../services/gql';
import StateType from '../types/State';

interface PlayerAdditionChangeModalProps {
  className?: string;
  headingClassName?: string;
  inputClassName?: string;
  inputFieldClassName?: string;
  inputHalfClassName?: string;
  dropDownClassName?: string;
  dropDownSelectClassName?: string;
  fullNameClassName?: string;
  multiSelectClassName?: string;
  contentClassName?: string;
  isPopupVisible: boolean;
  currentRecruitingYear?: number;
  states: StateType[];
  statesLoading: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
  fetchStates: () => void;
}

const HELP_OPTIONS = {
  PLAYER_ADDITION: 'Add Player',
  PLAYER_CHANGE: 'Change Player',
};

const HELP_QUES = {
  [HELP_OPTIONS.PLAYER_ADDITION] : {
    question: 'Want us to add a missing player?',
    helpText: 'Please provide the following:',
    submitBtnText: 'Request Addition',
  },
  [HELP_OPTIONS.PLAYER_CHANGE] : {
    question: 'Want us to change existing player data?',
    helpText: 'Please provide specific details:',
    submitBtnText: 'Request Changes',
  }
};

const PLAYER_TYPES = [
  { content: 'High School', value: 'High School' },
  { content: 'College', value: 'College' },
  { content: 'NFL', value: 'NFL' },
];

const PLAYER_POSITIONS = [
  { content: 'Defense', value: 'Defense' },
  { content: 'Offense', value: 'Offense' },
  { content: 'Special', value: 'Special' },
];

function PlayerAdditionChangeModal (props:PlayerAdditionChangeModalProps) {
  const {
    className,
    headingClassName,
    inputClassName,
    inputFieldClassName,
    inputHalfClassName,
    dropDownClassName,
    dropDownSelectClassName,
    fullNameClassName,
    contentClassName,
    isPopupVisible = false,
    currentRecruitingYear,
    states,
    statesLoading,
    onClose,
    onError,
    onSuccess,
    fetchStates,
  } = props;
  const currentYear = currentRecruitingYear || (new Date().getFullYear());

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<string>(HELP_OPTIONS.PLAYER_ADDITION);
  const [playerType, setPlayerType] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [otherCountry, setOtherCountry] = useState<string>('');
  const [otherCountryError, setOtherCountryError] = useState<string>('');
  const [highSchool, setHighSchool] = useState<string>('');
  const [college, setCollege] = useState<string>('');
  const [playerPosition, setPlayerPosition] = useState<string>('');
  const [helpText, setHelpText] = useState<string>('');
  const [highSchoolGradYear, setHighSchoolGradYear] = useState<number>(currentYear);

  useEffect(() => {
    fetchStates();
  }, []);

  function handleSubmit() {
    if (selectedState === 'other' && !otherCountry.length) {
      setOtherCountryError('This field s required');
      return;
    }

    submitHelpText()
      .then((success:boolean | void) => {
        if (success) {
          onClose();
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(onError);
  }

  function submitHelpText () {
    setSubmitLoading(true);

    const hsOtherState = otherCountry.length > 0 ? `"${otherCountry}"` : null;

    return gql(`
      mutation {
        submitPlayerAdditionsChangesHelp (playerAdditionsChangesHelp: {
          requestType: "${requestType}",
          playerType: "${playerType}",
          firstName: "${firstName}",
          lastName: "${lastName}",
          hsName: "${highSchool}",
          hsState: ${selectedState !== 'other' ? (Number(selectedState) || null) : null},
          hsOtherState: ${hsOtherState},
          hsGraduationYear: ${highSchoolGradYear},
          collegeName: "${college}",
          position: "${playerPosition}",
          message: "${helpText}"
        })
      }`
    )
      .then((data: any) => data && data.submitPlayerAdditionsChangesHelp as boolean)
      .finally(() => setSubmitLoading(false));
  }

  function getYearOptions () {
    let yearOptions = [];
    for (let index = currentYear; index <= currentYear + 3; index += 1) {
      yearOptions.push({ content: `${index}`, value: index});
    }
    return yearOptions;
  }

  const loading = submitLoading || statesLoading;

  return (
    <Dialog
      className={className}
      contentClassName={contentClassName}
      open={isPopupVisible}
      title='Player Additions / Changes'
      containsLongContent
      actions={[
        {
          name: 'Cancel',
          onClick: onClose
        },
        {
          name: HELP_QUES[requestType].submitBtnText,
          primary: true,
          disabled: loading,
          onClick: handleSubmit
        }
      ]}
      onClose={onClose}
    >
      <Loader inProgress={loading} />

      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        items={[
          { content: 'Player Addition', value: HELP_OPTIONS.PLAYER_ADDITION },
          { content: 'Player Changes', value: HELP_OPTIONS.PLAYER_CHANGE },
        ]}
        value={requestType}
        onChange={(value: string) => setRequestType(value)}
      />

      <div className={headingClassName}>
        <strong>{HELP_QUES[requestType].question}</strong>
        {HELP_QUES[requestType].helpText}
      </div>

      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        emptyItem={{
          value: '',
          content: 'Player Type'
        }}
        items={PLAYER_TYPES}
        value={playerType}
        onChange={(value: string) => setPlayerType(value)}
      />

      <div className={fullNameClassName}>
        <Input
          className={inputHalfClassName}
          label='First Name'
          value={firstName}
          onChange={(value: string) => setFirstName(value)}
        />

        <Input
          className={inputHalfClassName}
          label='Last Name'
          value={lastName}
          onChange={(value: string) => setLastName(value)}
        />
      </div>

      <Input
        className={inputClassName}
        label='High School Name'
        value={highSchool}
        onChange={(value:string) => setHighSchool(value)}
      />

      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        emptyItem={{
          value: '',
          content: 'State'
        }}
        items={[
          ...states.map((state:StateType) => ({
            content: `${state.code} - ${state.name}`,
            value: state.id,
          })),
          {
            content: 'Other',
            value: 'other'
          },
        ]}
        value={selectedState}
        onChange={(value:string) => setSelectedState(value)}
      />

      {(selectedState === 'other') && (
        <Input
          className={inputClassName}
          label='Country or Province'
          value={otherCountry}
          error={otherCountryError}
          onChange={(value: string) => setOtherCountry(value)}
        />
      )}

      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        emptyItem={{
          value: '',
          content: 'High School Grad Year'
        }}
        items={getYearOptions()}
        value={`${highSchoolGradYear}`}
        onChange={(value:number) => setHighSchoolGradYear(value)}
      />

      <Input
        className={inputClassName}
        label='College (if applicable)'
        value={college}
        onChange={(value:string) => setCollege(value)}
      />

      <DropDown
        className={dropDownClassName}
        selectorClassName={dropDownSelectClassName}
        emptyItem={{
          value: '',
          content: 'Position'
        }}
        items={PLAYER_POSITIONS}
        value={playerPosition}
        onChange={(value:string) => setPlayerPosition(value)}
      />

      {(requestType === HELP_OPTIONS.PLAYER_CHANGE) && (
        <Input
          inputFieldClassName={inputFieldClassName}
          label='Please provide details'
          multiLine
          value={helpText}
          onChange={(value: string) => setHelpText(value)}
        />
      )}

      <HelpPopupFooter />
    </Dialog>
  );
}

const mapStateToProps = (state:State) => {
  return {
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    states: state.states.allStates,
    statesLoading: state.states.statesLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchStates,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerAdditionChangeModal);
