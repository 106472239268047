import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import { CONFERENCE_LONG_NAME } from '../templates/TeamPageTemplate';
import Team from '../types/Team';

interface TeamProfileOverviewProps {
  className?: string;
  team: Team;
  isNFL?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamProfileOverview: {},

  conferenceLogo: {
    width: 'auto',
    height: '18px',
    marginRight: theme.spacing(1),
  },

  listItemContent: {
    display: 'flex',
    alignItems: 'center',
  },
}), { name: TeamProfileOverview.name });

export default function TeamProfileOverview (props: TeamProfileOverviewProps) {
  const {
    className,
    team,
    isNFL,
  } = props;
  const classes = useStyles();

  return (
    <NamedBox
      className={clsx(classes.teamProfileOverview, className)}
      name={`About ${team.name}`}
      white
    >
      <ListItem title='Location:'>
        <b>{team.city}, {team.state}</b>
      </ListItem>

      {(!isNFL && team.conference.mainLevel) && (
        <ListItem title='Level:'>
          <b>{team.conference.mainLevel}</b>
        </ListItem>
      )}

      <ListItem
        contentClassName={classes.listItemContent}
        title='Conference:'
      >
        {!!team.conference.iconUrl && (
          <img
            className={classes.conferenceLogo}
            src={team.conference.iconUrl}
            alt={`${team.conference.name} logo`}
          />
        )}
        <b>{team.conference.name}</b>
      </ListItem>

      {(!isNFL && team.conference.mainLevel) && (
        <ListItem title='Division:'>
          <b>{CONFERENCE_LONG_NAME[team.conference.mainLevel]}</b>
        </ListItem>
      )}
    </NamedBox>
  );
}
