import React from 'react';
import Page from './Page';
import MessagePageTemplate from '../templates/MessagePageTemplate';

export default function MessagePage () {
  return (
    <Page title='Message'>
      <MessagePageTemplate />
    </Page>
  );
}
