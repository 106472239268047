import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import HelpIcon from '../icons/HelpIcon';
import HelpPageCards from '../organisms/HelpPageCards';
import MEDIA from '../styles/media';

const useStyles = makeStyles(theme => ({
  helpPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    padding: 0,
    margin: theme.spacing(5,5,0),
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '25px',
    height: '25px',
    marginRight: theme.spacing(1),
  },

  [MEDIA.MOBILE]: {
    header: {
      margin: theme.spacing(2,2,2),
    },
  },
}), { name: HelpPageTemplate.name });

export default function HelpPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.helpPageTemplate}>
      <Paper className={classes.paper}>
        <h1 className={classes.header}>
          <HelpIcon className={classes.headerIcon} />
          Help
        </h1>

        <HelpPageCards />
      </Paper>
    </PageContainer>
  );
}
