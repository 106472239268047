import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export enum CSVExportType {
  USER_LOGIN = 'user_login',
  API_ACCESS = 'api_access',
}

export const ERROR_MESSAGE_CHECK_EMAIL = 'check email';

export default function exportCSVReport (
  exportType:CSVExportType,
  exportParameters:object,
  fileName?:string,
):Promise<any> {
  const body:any = { ...exportParameters };
  let url = `${process.env.REACT_APP_API_HOST}/api/v1/reports/login?dateStart=${body.startDate}&dateEnd=${body.endDate}`;
  switch (exportType) {
    case CSVExportType.USER_LOGIN:
      url = `${process.env.REACT_APP_API_HOST}/api/v1/reports/login?dateStart=${body.startDate}&dateEnd=${body.endDate}`;
      break;
    case CSVExportType.API_ACCESS:
      url = `${process.env.REACT_APP_API_HOST}/api/v1/reports/api?dateStart=${body.startDate}&dateEnd=${body.endDate}`;
      break;
    default:
      break;
  }
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        // const needToCheckEMail = res.headers.get('tf-check-email') === 'true';
        const needToCheckEMail = !res.headers.get('content-type');

        if (needToCheckEMail) {
          throw new Error(ERROR_MESSAGE_CHECK_EMAIL);
        } else {
          return {
            fileName: fileName ? fileName : (res.headers.get('Content-Disposition') || '').split('filename=')[1] || `abc.csv`,
            fileBlob: await res.blob(),
          };
        }
      } else {
        return res.text()
          .then((errorMessage:string) => {
            throw new Error(errorMessage);
          });
      }
    })
    .then(({ fileName, fileBlob }) => downloadAndSaveXLSReport(fileName, fileBlob));
}

export function downloadAndSaveXLSReport (fileName:string, fileContent?:Blob, fileUrl?: string) {
  const url = !fileUrl ? window.URL.createObjectURL(fileContent) : fileUrl;
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
}
