import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import PlayerCharts from './PlayerCharts';
import PlayerTrackAndFieldData from './PlayerTrackAndFieldData';
import PlayerCombineData from './PlayerCombineData';
import PlayerProCombineData from './PlayerProCombineData';
import PlayerMultiSportData from './PlayerMultiSportData';
import { getScoreValue } from '../atoms/Score';
import gql from '../services/gql';
import Position from '../types/Position';
import Team from '../types/Team';
import Player from '../types/Player';
import PlayerPAIStats from '../types/PlayerPAIStats';
import PlayerNFLPAIStats from '../types/PlayerNFLPAIStats';
import PlayerCombineStats from '../types/PlayerCombineStats';
import PlayerProCombineStats from '../types/PlayerProCombineStats';
import PlayerCombineEvent from '../types/PlayerCombineEvent';
import PlayerNFLCombineEvent from '../types/PlayerNFLCombineEvent';
import PlayerPPIStats from '../types/PlayerPPIStats';
import PlayerNFLPPIStats from '../types/PlayerNFLPPIStats';

interface PlayerProfileStatsProps {
  player?: Player;
  collegeTeamId?: number;
  collegeTeam?: Team;
  nflTeamId?: number;
  nflTeam?: Team;
  position?: Position;
  isNFL: boolean;
  selectedTeamType?: string;
}

export const TAB = {
  PAI: 0,
  COMBINE: 1,
  MULTISPORT: 2,
  PPI: 3,
  PRO_COMBINE: 4,
};

const COLLEGE_PAI_STATS_QUERY = `
  performance
  event
  meet
  year
  date
  hsClassStats {
    percentile
    playerRank
    playerCount
  }
  div1Stats {
    percentile
    playerRank
    playerCount
  }
  teamStats {
    percentile
    playerRank
    playerCount
  }
  conferenceStats {
    percentile
    playerRank
    playerCount
  }
  nflDraftStats{
    percentile
    playerRank
    playerCount
  }
`;
const NFL_PAI_STATS_QUERY = `
  performance
  event
  meet
  year
  collegeTeamStats {
    percentile
    playerRank
    playerCount
  }
  collegeConferenceStats {
    percentile
    playerRank
    playerCount
  }
  nflTeamStats {
    percentile
    playerRank
    playerCount
  }
  nflUDFAStats {
    percentile
    playerRank
    playerCount
  }
  nflDraftStats {
    percentile
    playerRank
    playerCount
  }
`;

export default function PlayerProfileStats (props: PlayerProfileStatsProps) {
  const {
    player,
    collegeTeamId,
    collegeTeam,
    nflTeamId,
    nflTeam,
    position,
    isNFL,
    selectedTeamType,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(TAB.PAI);

  const [paiStats, setPAIStats] = useState<PlayerPAIStats | PlayerNFLPAIStats>();
  const [sprints, setSprints] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [relays, setRelays] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [hurdles, setHurdles] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [jumps, setJumps] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);
  const [throws, setThrows] = useState<Array<PlayerPAIStats | PlayerNFLPAIStats>>([]);

  const [combineStats, setCombineStats] = useState<PlayerCombineStats>();
  const [combineEvents, setCombineEvents] = useState<Array<PlayerCombineEvent | PlayerNFLCombineEvent>>([]);

  const [proCombineStats, setProCombineStats] = useState<PlayerProCombineStats>();
  const [nflCombineEvents, setNFLCombineEvents] = useState<Array<PlayerCombineEvent | PlayerNFLCombineEvent>>([]);

  const [ppiStats, setPPIStats] = useState<PlayerPPIStats | PlayerNFLPPIStats>();

  const hasPaiData = !!player?.pai && getScoreValue(player.pai, { position }) !== null;
  const hasCombineScore = !!player?.combine && getScoreValue(player.combine, { position }) !== null;
  const hasProCombineData = !!player?.nflCombines?.length;
  const hasHSCombineData = !!player?.hsCombines?.length;
  const hasPPIScore = !!player?.ppi;

  useEffect(() => {
    if (player?.id && position) {
      let selectedTab = TAB.MULTISPORT;

      if (hasCombineScore || hasHSCombineData) {
        selectedTab = TAB.COMBINE;
      }

      if (hasPaiData) {
        selectedTab = TAB.PAI;
      }

      setSelectedTab(selectedTab);
    }
  }, [player?.id, position, hasPaiData, hasCombineScore, hasHSCombineData]);

  useEffect(() => {
    if (player?.id) {
      if (isNFL) {
        if (selectedTab === TAB.PAI) {
          fetchNFLPAIStats();
        }
        if ((selectedTab === TAB.COMBINE && (hasCombineScore || hasHSCombineData))
          || (selectedTab === TAB.PRO_COMBINE && hasProCombineData)
        ) {
          fetchNFLCombineStats();
        }
        if (selectedTab === TAB.PPI) {
          fetchNFLPPIStats();
        }
      } else if (!isNFL) {
        if (selectedTab === TAB.PAI) {
          fetchPAIStats();
        }
        if (selectedTab === TAB.COMBINE && (hasCombineScore || hasHSCombineData)
        || (selectedTab === TAB.PRO_COMBINE && hasProCombineData)
        ) {
          fetchCombineStats();
        }
        if (selectedTab === TAB.PPI) {
          fetchPPIStats();
        }
      }
    }
  }, [selectedTab, player?.id, collegeTeamId, nflTeamId, position, isNFL]);

  function fetchPAIStats () {
    if (!player?.id || !collegeTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerPAIStats (
        id: ${player.id},
        compareToTeamId: ${collegeTeamId},
        positionCode: "${position}"
      ) {
        paiStats {
          performance
          event
          meet
          year
          date
          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }
        sprints {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        relays {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        hurdles {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        jumps {
          ${COLLEGE_PAI_STATS_QUERY}
        }
        throws {
          ${COLLEGE_PAI_STATS_QUERY}
        }
      }
    `)
      .then((data:any) => ({
        paiStats: get(data, 'playerPAIStats.paiStats') as PlayerPAIStats,
        sprints: get(data, 'playerPAIStats.sprints', []) as PlayerPAIStats[],
        relays: get(data, 'playerPAIStats.relays', []) as PlayerPAIStats[],
        hurdles: get(data, 'playerPAIStats.hurdles', []) as PlayerPAIStats[],
        jumps: get(data, 'playerPAIStats.jumps', []) as PlayerPAIStats[],
        throws: get(data, 'playerPAIStats.throws', []) as PlayerPAIStats[],
      }))
      .then(({ paiStats, sprints, relays, hurdles, jumps, throws }:{ paiStats:PlayerPAIStats, sprints:PlayerPAIStats[], relays:PlayerPAIStats[], hurdles:PlayerPAIStats[], jumps:PlayerPAIStats[], throws:PlayerPAIStats[] }) => {
        setPAIStats(paiStats);
        setSprints(sprints.filter(sprint => !!sprint.performance));
        setRelays(relays.filter(relay => !!relay.performance));
        setHurdles(hurdles.filter(hurdle => !!hurdle.performance));
        setJumps(jumps.filter(jump => !!jump.performance));
        setThrows(throws.filter(throwEvent => !!throwEvent.performance));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLPAIStats () {
    if (!player?.id || !collegeTeamId || !nflTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerPAIStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${collegeTeamId},
        compareToNFLTeamId: ${nflTeamId},
        positionCode: "${position}"
      ) {
        paiStats {
          performance
          event
          meet
          year
          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }
        sprints {
          ${NFL_PAI_STATS_QUERY}
        }
        relays {
          ${NFL_PAI_STATS_QUERY}
        }
        hurdles {
          ${NFL_PAI_STATS_QUERY}
        }
        jumps {
          ${NFL_PAI_STATS_QUERY}
        }
        throws {
          ${NFL_PAI_STATS_QUERY}
        }
      }
    `)
      .then((data:any) => ({
        paiStats: get(data, 'playerPAIStatsNFL.paiStats') as PlayerNFLPAIStats,
        sprints: get(data, 'playerPAIStatsNFL.sprints', []) as PlayerNFLPAIStats[],
        relays: get(data, 'playerPAIStatsNFL.relays', []) as PlayerNFLPAIStats[],
        hurdles: get(data, 'playerPAIStatsNFL.hurdles', []) as PlayerNFLPAIStats[],
        jumps: get(data, 'playerPAIStatsNFL.jumps', []) as PlayerNFLPAIStats[],
        throws: get(data, 'playerPAIStatsNFL.throws', []) as PlayerNFLPAIStats[],
      }))
      .then(({ paiStats, sprints, relays, hurdles, jumps, throws }:{ paiStats:PlayerNFLPAIStats, sprints:PlayerNFLPAIStats[], relays:PlayerNFLPAIStats[], hurdles:PlayerNFLPAIStats[], jumps:PlayerNFLPAIStats[], throws:PlayerNFLPAIStats[] }) => {
        setPAIStats(paiStats);
        setSprints(sprints.filter(sprint => !!sprint.performance));
        setRelays(relays.filter(relay => !!relay.performance));
        setHurdles(hurdles.filter(hurdle => !!hurdle.performance));
        setJumps(jumps.filter(jump => !!jump.performance));
        setThrows(throws.filter(throwEvent => !!throwEvent.performance));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchCombineStats () {
    if (!player?.id || !collegeTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerCombineStats (
        id: ${player.id},
        compareToTeamId: ${collegeTeamId},
        positionCode: "${position}"
      ) {
        combineStats {
          performance
          event
          meet
          year

          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }

        proCombineStats {
          performance
          event
          meet
          year

          hsClassStats {
            percentile
            playerRank
            playerCount
          }
          div1Stats {
            percentile
            playerRank
            playerCount
          }
          teamStats {
            percentile
            playerRank
            playerCount
          }
          conferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats{
            percentile
            playerRank
            playerCount
          }
        }

        combines {
          id
          type
          city
          combineDate
          height
          weight
          category
          combineHasFreshmanData
          combine

          eventStats {
            performance
            event
            meet
            year
            hsClassStats {
              percentile
              playerRank
              playerCount
            }
            div1Stats {
              percentile
              playerRank
              playerCount
            }
            teamStats {
              percentile
              playerRank
              playerCount
            }
            conferenceStats {
              percentile
              playerRank
              playerCount
            }
            nflDraftStats {
              percentile
              playerRank
              playerCount
            }
          }
        }
      }
    `)
      .then((data:any) => ({
        combineStats: get(data, 'playerCombineStats.combineStats') as PlayerCombineStats,
        combineEvents: get(data, 'playerCombineStats.combines') as PlayerCombineEvent[],
        proCombineStats: get(data, 'playerCombineStats.proCombineStats') as PlayerCombineStats,
      }))
      .then(({ combineStats, proCombineStats, combineEvents }:{ combineStats:PlayerCombineStats, proCombineStats:PlayerCombineStats, combineEvents:PlayerCombineEvent[] }) => {
        setCombineStats(combineStats);
        setProCombineStats(proCombineStats);
        setCombineEvents(combineEvents.filter(event => event.category !== 'nfl'));
        setNFLCombineEvents(combineEvents.filter(event => event.category === 'nfl'));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLCombineStats () {
    if (!player?.id || !collegeTeamId || !nflTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerCombineStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${collegeTeamId},
        compareToNFLTeamId: ${nflTeamId},
        positionCode: "${position}"
      ) {
        combineStats {
          performance
          event
          meet
          year

          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }

        proCombineStats {
          performance
          event
          meet
          year

          collegeTeamStats {
            percentile
            playerRank
            playerCount
          }
          collegeConferenceStats {
            percentile
            playerRank
            playerCount
          }
          nflTeamStats {
            percentile
            playerRank
            playerCount
          }
          nflUDFAStats {
            percentile
            playerRank
            playerCount
          }
          nflDraftStats {
            percentile
            playerRank
            playerCount
          }
        }

        combines {
          id
          type
          city
          combineDate
          height
          weight
          category

          eventStats {
            performance
            event
            meet
            year
            collegeTeamStats {
              percentile
              playerRank
              playerCount
            }
            collegeConferenceStats {
              percentile
              playerRank
              playerCount
            }
            nflTeamStats {
              percentile
              playerRank
              playerCount
            }
            nflUDFAStats {
              percentile
              playerRank
              playerCount
            }
            nflDraftStats {
              percentile
              playerRank
              playerCount
            }
          }
        }
      }
    `)
      .then((data:any) => ({
        combineStats: get(data, 'playerCombineStatsNFL.combineStats') as PlayerCombineStats,
        proCombineStats: get(data, 'playerCombineStatsNFL.proCombineStats') as PlayerProCombineStats,
        combineEvents: get(data, 'playerCombineStatsNFL.combines') as PlayerNFLCombineEvent[],
      }))
      .then(({ combineStats, proCombineStats, combineEvents }:{ combineStats:PlayerCombineStats, proCombineStats:PlayerProCombineStats, combineEvents:PlayerNFLCombineEvent[] }) => {
        setCombineStats(combineStats);
        setProCombineStats(proCombineStats);
        setCombineEvents(combineEvents.filter(event => event.category !== 'nfl'));
        setNFLCombineEvents(combineEvents.filter(event => event.category === 'nfl'));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchPPIStats () {
    if (!player?.id || !collegeTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerPPIStats (
        id: ${player.id},
        compareToTeamId: ${collegeTeamId},
        positionCode: "${position}"
      ) {
        hsClassStats {
          percentile
          playerRank
          playerCount
        }
        div1Stats {
          percentile
          playerRank
          playerCount
        }
        teamStats {
          percentile
          playerRank
          playerCount
        }
        conferenceStats {
          percentile
          playerRank
          playerCount
        }
        nflDraftStats{
          percentile
          playerRank
          playerCount
        }
      }
    `)
      .then((data:any) => data?.playerPPIStats as PlayerPPIStats)
      .then((ppiStats:PlayerPPIStats) => {
        setPPIStats(ppiStats);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchNFLPPIStats () {
    if (!player?.id || !collegeTeamId || !nflTeamId || !position) return;
    if (!loading) setLoading(true);

    gql(`
      playerPPIStatsNFL (
        id: ${player.id},
        compareToCollegeTeamId: ${collegeTeamId},
        compareToNFLTeamId: ${nflTeamId},
        positionCode: "${position}"
      ) {
        collegeTeamStats {
          percentile
          playerRank
          playerCount
        }
        collegeConferenceStats {
          percentile
          playerRank
          playerCount
        }
        nflTeamStats {
          percentile
          playerRank
          playerCount
        }
        nflUDFAStats {
          percentile
          playerRank
          playerCount
        }
        nflDraftStats {
          percentile
          playerRank
          playerCount
        }
      }
    `)
      .then((data:any) => data?.playerPPIStatsNFL as PlayerNFLPPIStats)
      .then((ppiStats:PlayerNFLPPIStats) => {
        setPPIStats(ppiStats);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  return (
    <>
      <PlayerCharts
        selectedTab={selectedTab}
        loading={loading}
        player={player}
        collegeTeam={collegeTeam}
        nflTeam={nflTeam}
        position={position}
        paiStats={paiStats}
        combineStats={selectedTab === TAB.PRO_COMBINE ? proCombineStats : combineStats}
        combineEvents={selectedTab === TAB.PRO_COMBINE ? nflCombineEvents : combineEvents}
        ppiStats={ppiStats}
        isNFL={isNFL}
        hasPaiData={hasPaiData}
        hasCombineScore={hasCombineScore}
        hasProCombineData={hasProCombineData}
        hasHSCombineData={hasHSCombineData}
        hasPPIScore={hasPPIScore}
        onTabChange={(tab:number) => setSelectedTab(tab)}
        selectedTeamType={selectedTeamType}
      />

      {selectedTab === TAB.PAI && (
        <PlayerTrackAndFieldData
          loading={loading}
          player={player}
          collegeTeam={collegeTeam}
          nflTeam={nflTeam}
          position={position}
          sprints={sprints}
          relays={relays}
          hurdles={hurdles}
          jumps={jumps}
          throws={throws}
          isNFL={isNFL}
        />
      )}

      {(selectedTab === TAB.COMBINE && hasCombineScore) && (
        <PlayerCombineData
          loading={loading}
          player={player}
          collegeTeam={collegeTeam}
          nflTeam={nflTeam}
          position={position}
          combineEvents={combineEvents}
          isNFL={isNFL}
        />
      )}

      {(selectedTab === TAB.PRO_COMBINE && hasProCombineData) && (
        <PlayerProCombineData
          loading={loading}
          player={player}
          collegeTeam={collegeTeam}
          nflTeam={nflTeam}
          position={position}
          nflCombineEvents={nflCombineEvents}
          isNFL={isNFL}
        />
      )}

      {selectedTab === TAB.MULTISPORT && (
        <PlayerMultiSportData
          player={player}
          collegeTeam={collegeTeam}
          nflTeam={nflTeam}
          position={position}
          isNFL={isNFL}
        />
      )}
    </>
  );
}
