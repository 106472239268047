import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Checkbox from '../atoms/Checkbox';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Slider from '../atoms/Slider';
import Accordion from '../atoms/Accordion';
import Loader from '../atoms/Loader';
import Switch from '../atoms/Switch';
import MultiSelect from '../molecules/MultiSelect';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import ConferenceDropDown from '../molecules/ConferenceDropDown';
import StagingQuickFilter, { QuickFilter } from '../molecules/StagingQuickFilter';
import { StagedPlayerColumn, COLUMN_TITLE, STATUS } from '../molecules/StagedPlayerTableRow';
import StagedPlayer from '../types/StagedPlayer';
import StateType from '../types/State';
import Team from '../types/Team';
import Conference from '../types/Conference';

interface ImportFiltersDrawerProps {
  open: boolean;
  currentRecruitingYear: number;
  nflDraftYear: number;
  stagedPlayers: StagedPlayer[];
  loading: boolean;
  states: StateType[];
  collegeTeams: Team[];
  nflTeams: Team[];
  conferences: Conference[];
  selectedQuickFilter: QuickFilter;
  setSelectedQuickFilter: (quickFilter:QuickFilter) => void;
  onFilter: (players:StagedPlayer[]) => void;
  onClose: () => void;
}

const FILTER_TYPE = {
  HIGH_SCHOOL: 'highSchool',
  COLLEGE: 'college',
  NFL: 'nfl',
};

const FILTERS_LIST = [
  FILTER_TYPE.HIGH_SCHOOL,
  FILTER_TYPE.COLLEGE,
  FILTER_TYPE.NFL,
];

const PLAYER_TYPES_LIST = [
  FILTER_TYPE.HIGH_SCHOOL,
  FILTER_TYPE.COLLEGE,
  FILTER_TYPE.NFL,
];

const FILTERS_TITLE = {
  [FILTER_TYPE.HIGH_SCHOOL]: 'High School',
  [FILTER_TYPE.COLLEGE]: 'College',
  [FILTER_TYPE.NFL]: 'NFL',
};

const PLAYER_TYPES = [
  { content: 'HS', value: 'highSchool'},
  { content: 'College', value: 'college'},
  { content: 'NFL', value: 'nfl'}
];

const DATA_SOURCE_LINKS = [
  StagedPlayerColumn.URL_247,
  StagedPlayerColumn.ATHLETIC_URL,
  StagedPlayerColumn.HUDL_URL,
  StagedPlayerColumn.MAXPREPS_URL,
  StagedPlayerColumn.MILESPLIT_URL,
  StagedPlayerColumn.RIVALS_URL,
  StagedPlayerColumn.TWITTER_URL,
];

const STATUSES = [
  STATUS.NEW,
  STATUS.UPDATED,
];

const STAR_RATING_RANGE = {
  MIN: 0,
  MAX: 5,
};

const DRAFT_ROUND_RANGE = {
  MIN: 1,
  MAX: 12,
};

const OFFERS_RANGE = {
  MIN: 0,
  MAX: 70,
};

const OTHER_SPORTS = [
  StagedPlayerColumn.BASEBALL,
  StagedPlayerColumn.BASKETBALL,
  StagedPlayerColumn.GOLF,
  StagedPlayerColumn.HOCKEY,
  StagedPlayerColumn.LACROSSE,
  StagedPlayerColumn.POWERLIFTING,
  StagedPlayerColumn.RUGBY,
  StagedPlayerColumn.SOCCER,
  StagedPlayerColumn.SWIMMING,
  StagedPlayerColumn.TENNIS,
  StagedPlayerColumn.TRACK,
  StagedPlayerColumn.VOLLEYBALL,
  StagedPlayerColumn.WRESTLING,
];

const useStyles = makeStyles(theme => ({
  importFiltersDrawer: {
    width: '90%',
    maxWidth: '440px',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },

  title: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
    whiteSpace: 'nowrap',
  },

  quickFilter: {
    marginLeft: 'auto',
  },

  closeButton: {
    marginLeft: theme.spacing(1.5),
  },

  filterWrapper: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 4),

    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  filterContent: {
    paddingBottom: theme.spacing(4),
  },

  drawerContent: {
    paddingBottom: theme.spacing(4),
  },
  multiSelectDropdown: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  multiSelectorRoot: {
    fontSize: theme.typography.pxToRem(16),
    height: '30px',
    lineHeight: '30px',
  },
  playerTypeFilter: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(2),
  },
  filterTitle: {
    fontWeight: 700,
  },
  filterLabel: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '30px',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(3),
    },
  },
  playerTypes: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  switch: {
    marginTop: theme.spacing(2),
  },

  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  playerScore: {
    width: '32px',
    height: '32px',
    fontSize: theme.typography.pxToRem(14),
  },
  slider: {
    width: '100%',
    padding: theme.spacing(3, 0),
    boxSizing: 'border-box',
  },

  teamAutoComplete: {
    marginTop: theme.spacing(2),
  },

  conferenceDropDown: {
    width: '100%',
    marginTop: theme.spacing(2),
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  button: {
    height: '50px',
    width: '170px',
    marginRight: theme.spacing(2),
  },

  drawerFooter: {
    position: 'static',
    bottom: 0,
  },
  accordion: {
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    paddingLeft: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(14),
  },
}), { name: ImportFiltersDrawer.name });

export default function ImportFiltersDrawer (props: ImportFiltersDrawerProps) {
  const classes = useStyles();
  const {
    open,
    loading,
    stagedPlayers = [],
    currentRecruitingYear,
    nflDraftYear,
    states = [],
    collegeTeams = [],
    nflTeams = [],
    conferences = [],
    selectedQuickFilter,
    setSelectedQuickFilter,
    onFilter = () => {},
    onClose = () => {},
  } = props;

  const HS_YEAR_RANGE = {
    MIN: currentRecruitingYear - 3,
    MAX: currentRecruitingYear + 3,
  };

  const RECRUITING_YEAR_RANGE = {
    MIN: 1970,
    MAX: currentRecruitingYear,
  };

  const DRAFT_YEAR_RANGE = {
    MIN: 1970,
    MAX: nflDraftYear,
  };

  const [playerTypeFilters, setPlayerTypeFilters] = useState<string[]>(PLAYER_TYPES_LIST);
  const [selectedPlayerStatuses, setSelectedPlayerStatuses] = useState<string[]>(STATUSES);

  const [dataSourceLinksFilter, setDataSourceLinksFilter] = useState<boolean>(false);
  const [dataSourceLinks, setDataSourceLinks] = useState<string[]>([]);
  const [starsFilter, setStarsFilter] = useState<boolean>(false);
  const [starsRange, setStarsRange] = useState<number | number[]>([STAR_RATING_RANGE.MIN, STAR_RATING_RANGE.MAX]);
  const [offersFilter, setOffersFilter] = useState<boolean>(false);
  const [offersCountRange, setOffersCountRange] = useState<number | number[]>([OFFERS_RANGE.MIN, OFFERS_RANGE.MAX]);
  const [offerSchoolFilter, setOfferSchoolFilter] = useState<boolean>(false);
  const [selectedOfferSchool, setSelectedOfferSchool] = useState<Team | null>(null);
  const [multiSportFilter, setMultiSportFilter] = useState<boolean>(false);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);
  const [hsClassYearFilter, setHsClassYearFilter] = useState<boolean>(false);
  const [hsClassYearRange, setHsClassYearRange] = useState<number | number[]>([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
  const [hsStateFilter, setHsStateFilter] = useState<boolean>(false);
  const [selectedHsStates, setSelectedHsStates] = useState<string[]>([]);

  const [collegeTeamFilter, setCollegeTeamFilter] = useState<boolean>(false);
  const [selectedCollegeTeam, setSelectedCollegeTeam] = useState<Team>();
  const [conferenceFilter, setConferenceFilter] = useState<boolean>(false);
  const [selectedConference, setSelectedConference] = useState<Conference | undefined>();
  const [collegeClassYearFilter, setCollegeClassYearFilter] = useState<boolean>(false);
  const [collegeClassYearRangeFilter, setCollegeClassYearRangeFilter] = useState<number | number[]>([RECRUITING_YEAR_RANGE.MIN, RECRUITING_YEAR_RANGE.MAX]);

  const [nflDraftYearFilter, setNFLDraftYearFilter] = useState<boolean>(false);
  const [nflDraftYearRange, setNFLDraftYearRange] = useState<number | number[]>([DRAFT_YEAR_RANGE.MIN, DRAFT_YEAR_RANGE.MAX]);
  const [nflDraftRoundFilter, setNFLDraftRoundFilter] = useState<boolean>(false);
  const [nflDraftRoundRange, setNFLDraftRoundRange] = useState<number | number[]>([DRAFT_ROUND_RANGE.MIN, DRAFT_ROUND_RANGE.MAX]);
  const [nflUDFAFilter, setNFLUDFAFilter] = useState<boolean>(false);
  const [nflTeamFilter, setNFLTeamFilter] = useState<boolean>(false);
  const [selectedNFLTeam, setSelectedNFLTeam] = useState<Team>();
  const [ncaaIdFilter, setNCAAIdFilter] = useState<boolean>(false);

  useEffect(() => {
    applyFilters();
  }, [selectedQuickFilter]);

  function handlePlayerTypeChange (filteredPlayerType:any) {
    return (value:boolean) => {
      if (value) {
        setPlayerTypeFilters([...playerTypeFilters, filteredPlayerType.value]);
      } else {
        setPlayerTypeFilters(playerTypeFilters.filter((playerType:string) => playerType !== filteredPlayerType.value));

        if (filteredPlayerType.value === FILTER_TYPE.HIGH_SCHOOL) {
          resetHSFilters();
        } else if (filteredPlayerType.value === FILTER_TYPE.COLLEGE) {
          resetCollegeFilters();
        } else if (filteredPlayerType.value === FILTER_TYPE.NFL) {
          resetNFLFilters();
        }
      }
    };
  }

  function isDuplicateSlug (slug:string|null):boolean {
    if (!slug) return false;

    const currentSlugWithoutEnding = getSlugWithoutEnding(slug);
    const allSlugsWithoutEndings = stagedPlayers
      .map((stagedPlayer:StagedPlayer) => getSlugWithoutEnding(stagedPlayer.slug || ''))
      .filter(Boolean);

    return allSlugsWithoutEndings.reduce((count:number, slug:string) => {
      return slug === currentSlugWithoutEnding ? count + 1 : count;
    }, 0) > 1;
  }

  function getSlugWithoutEnding (slug:string):string {
    const hasEndingWithNumber = new RegExp('-[0-9]{1,3}$', 'i').test(slug);

    if (hasEndingWithNumber) {
      const slugParts = slug.split('-');

      return slugParts.slice(0, slugParts.length - 1).join('-');
    }

    return slug;
  }

  function applyFiltersAndClose () {
    applyFilters();
    onClose();
  }

  function applyFilters () {
    let filteredPlayers = [...stagedPlayers]
      // status
      .filter((stagedPlayer:StagedPlayer) => selectedPlayerStatuses.includes(
        stagedPlayer.isAlreadyPresent ? STATUS.UPDATED : STATUS.NEW
      ));

    function isHSPlayer (stagedPlayer:StagedPlayer) {
      return stagedPlayer.isAlreadyPresent
        ? stagedPlayer.hasHighSchool || !!stagedPlayer.data.hsName
        : !!stagedPlayer.data.hsName;
    }
    function isCollegePlayer (stagedPlayer:StagedPlayer) {
      return stagedPlayer.isAlreadyPresent
        ? stagedPlayer.hasCollegeTeam || !!stagedPlayer.data.collegeName
        : !!stagedPlayer.data.collegeName;
    }
    function isNFLPlayer (stagedPlayer:StagedPlayer) {
      return stagedPlayer.isAlreadyPresent
        ? stagedPlayer.hasNFLTeam || !!stagedPlayer.data.nflTeamName
        : !!stagedPlayer.data.nflTeamName;
    }

    if (playerTypeFilters.length) {
      const onlyHS = playerTypeFilters.length === 1 && playerTypeFilters[0] === FILTER_TYPE.HIGH_SCHOOL;
      const onlyHSAndCollege = playerTypeFilters.length === 2 && playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL) && playerTypeFilters.includes(FILTER_TYPE.COLLEGE);

      let hsPlayerIds:number[] = [];
      if (playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL)) {
        hsPlayerIds = filteredPlayers
          .filter((stagedPlayer:StagedPlayer) => onlyHS
            ? isHSPlayer(stagedPlayer) && !isCollegePlayer(stagedPlayer) && !isNFLPlayer(stagedPlayer)
            : onlyHSAndCollege
              ? isHSPlayer(stagedPlayer) && !isNFLPlayer(stagedPlayer)
              : isHSPlayer(stagedPlayer)
          )
          .map((stagedPlayer:StagedPlayer) => stagedPlayer.id);
      }

      let collegePlayerIds:number[] = [];
      if (playerTypeFilters.includes(FILTER_TYPE.COLLEGE)) {
        collegePlayerIds = filteredPlayers
          .filter((stagedPlayer:StagedPlayer) => isCollegePlayer(stagedPlayer) && !isNFLPlayer(stagedPlayer))
          .map((stagedPlayer:StagedPlayer) => stagedPlayer.id);
      }

      let nflPlayerIds:number[] = [];
      if (playerTypeFilters.includes(FILTER_TYPE.NFL)) {
        nflPlayerIds = filteredPlayers
          .filter((stagedPlayer:StagedPlayer) => isNFLPlayer(stagedPlayer))
          .map((stagedPlayer:StagedPlayer) => stagedPlayer.id);
      }

      const filteredBySelectedPlayerTypesIds = [
        ...hsPlayerIds,
        ...collegePlayerIds,
        ...nflPlayerIds,
      ];

      filteredPlayers = filteredPlayers
        .filter((stagedPlayer:StagedPlayer) => filteredBySelectedPlayerTypesIds.includes(stagedPlayer.id));
    }

    // NCAA ID
    if (ncaaIdFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        return !!stagedPlayer.data.ncaaId;
      });
    }

    // Data source links
    if (dataSourceLinksFilter && dataSourceLinks && Array.isArray(dataSourceLinks)) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        return dataSourceLinks.every((columnName:string) => {
          // @ts-ignore
          return !!stagedPlayer.data[columnName];
        });
      });
    }

    // Star rating
    if (starsFilter && starsRange && Array.isArray(starsRange)) {
      const [starsMin, starsMax] = starsRange;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerStars = Number(stagedPlayer.data[StagedPlayerColumn.COMP_STAR] || stagedPlayer.data[StagedPlayerColumn.STAR_247]);
        return playerStars && playerStars >= starsMin && playerStars <= starsMax;
      });
    }

    // Offer count
    if (offersFilter && offersCountRange && Array.isArray(offersCountRange)) {
      const [offersMin, offersMax] = offersCountRange;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerOffersCount = Number(stagedPlayer.data[StagedPlayerColumn.OFFER_COUNT]);
        return playerOffersCount && playerOffersCount >= offersMin && playerOffersCount <= offersMax;
      });
    }

    // Offer school
    if (offerSchoolFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerOffers = (stagedPlayer.data[StagedPlayerColumn.OFFER_SCHOOLS] || '')
          .split(',')
          .map((schoolName:string) => schoolName.trim());
        return selectedOfferSchool && playerOffers.includes(selectedOfferSchool.shortName);
      });
    }

    // Multi-sport
    if (multiSportFilter) {
      if (selectedSports && selectedSports.length > 0) {
        filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
          return selectedSports.every((columnName:string) => {
            // @ts-ignore
            return Number(stagedPlayer.data[columnName]) === 1;
          });
        });
      } else if (selectedSports && selectedSports.length === 0) {
        filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
          return OTHER_SPORTS.every((columnName:string) => {
            // @ts-ignore
            return Number(stagedPlayer.data[columnName]) !== 1;
          });
        });
      }
    }

    // HS class year
    if (hsClassYearFilter && hsClassYearRange && Array.isArray(hsClassYearRange)) {
      const [minYear, maxYear] = hsClassYearRange;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerHSClass = Number(stagedPlayer.data[StagedPlayerColumn.HS_CLASS]);
        return playerHSClass
          && playerHSClass >= minYear
          && playerHSClass <= maxYear;
      });
    }

    // HS states
    if (hsStateFilter && selectedHsStates && Array.isArray(selectedHsStates)) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerHSState = stagedPlayer.data[StagedPlayerColumn.HS_STATE];
        return playerHSState && selectedHsStates.includes(playerHSState);
      });
    }

    // College team
    if (collegeTeamFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerCollegeTeamName = stagedPlayer.data[StagedPlayerColumn.COLLEGE_NAME];
        return selectedCollegeTeam && selectedCollegeTeam.name === playerCollegeTeamName;
      });
    }

    // Conference
    if (conferenceFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerCollegeTeamName = stagedPlayer.data[StagedPlayerColumn.COLLEGE_NAME];
        return selectedConference && selectedConference.teams && selectedConference.teams.length > 0
          && playerCollegeTeamName
          && !!selectedConference.teams.find((team:Team) => team.name === playerCollegeTeamName);
      });
    }

    // College class year
    if (collegeClassYearFilter && collegeClassYearRangeFilter && Array.isArray(collegeClassYearRangeFilter)) {
      const [minYear, maxYear] = collegeClassYearRangeFilter;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerCollegeRecruitingClass = Number(stagedPlayer.data[StagedPlayerColumn.COLLEGE_RECRUITING_CLASS]);
        return playerCollegeRecruitingClass
          && playerCollegeRecruitingClass >= minYear
          && playerCollegeRecruitingClass <= maxYear;
      });
    }

    // NFL Draft Year
    if (nflDraftYearFilter && nflDraftYearRange && Array.isArray(nflDraftYearRange)) {
      const [minYear, maxYear] = nflDraftYearRange;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerDraftYear = Number(stagedPlayer.data[StagedPlayerColumn.NFL_DRAFT_YEAR]);
        return playerDraftYear
          && playerDraftYear >= minYear
          && playerDraftYear <= maxYear;
      });
    }

    // NFL Draft Round
    if (nflDraftRoundFilter && nflDraftRoundRange && Array.isArray(nflDraftRoundRange)) {
      const [minRound, maxRound] = nflDraftRoundRange;

      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerDraftRound = Number(stagedPlayer.data[StagedPlayerColumn.NFL_DRAFT_ROUND]);
        return playerDraftRound
          && playerDraftRound >= minRound
          && playerDraftRound <= maxRound;
      });
    }

    // NFL UDFA
    if (nflUDFAFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerDraftRound = Number(stagedPlayer.data[StagedPlayerColumn.NFL_DRAFT_ROUND]);
        return playerDraftRound === 0;
      });
    }

    // NFL team
    if (nflTeamFilter) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => {
        const playerNFLTeamName = stagedPlayer.data[StagedPlayerColumn.NFL_TEAM_NAME];
        return selectedNFLTeam && selectedNFLTeam.name === playerNFLTeamName;
      });
    }

    // Quick filtering
    if (selectedQuickFilter === QuickFilter.WITH_ERRORS) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => !!stagedPlayer?.validationErrors?.length);
    } else if (selectedQuickFilter === QuickFilter.WITH_DUPLICATE_SLUGS) {
      filteredPlayers = filteredPlayers.filter((stagedPlayer:StagedPlayer) => isDuplicateSlug(stagedPlayer.slug));
    }

    onFilter(filteredPlayers);
  }

  function resetAllFilters () {
    setPlayerTypeFilters(PLAYER_TYPES_LIST);
    setSelectedPlayerStatuses(STATUSES);
    setNCAAIdFilter(false);

    resetHSFilters();
    resetCollegeFilters();
    resetNFLFilters();

    onFilter(stagedPlayers);
    onClose();
  }

  function resetHSFilters () {
    setDataSourceLinksFilter(false);
    setDataSourceLinks([]);
    setStarsFilter(false);
    setStarsRange([STAR_RATING_RANGE.MIN, STAR_RATING_RANGE.MAX]);
    setOffersFilter(false);
    setOffersCountRange([OFFERS_RANGE.MIN, OFFERS_RANGE.MAX]);
    setOfferSchoolFilter(false);
    setSelectedOfferSchool(null);
    setMultiSportFilter(false);
    setSelectedSports([]);
    setHsClassYearFilter(false);
    setHsClassYearRange([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
    setHsStateFilter(false);
    setSelectedHsStates([]);
  }

  function resetCollegeFilters () {
    setCollegeTeamFilter(false);
    setSelectedCollegeTeam(undefined);
    setConferenceFilter(false);
    setSelectedConference(undefined);
    setCollegeClassYearFilter(false);
    setCollegeClassYearRangeFilter([RECRUITING_YEAR_RANGE.MIN, RECRUITING_YEAR_RANGE.MAX]);
  }

  function resetNFLFilters () {
    setNFLDraftYearFilter(false);
    setNFLDraftYearRange([DRAFT_YEAR_RANGE.MIN, DRAFT_YEAR_RANGE.MAX]);
    setNFLDraftRoundFilter(false);
    setNFLDraftRoundRange([DRAFT_ROUND_RANGE.MIN, DRAFT_ROUND_RANGE.MAX]);
    setNFLUDFAFilter(false);
    setNFLTeamFilter(false);
    setSelectedNFLTeam(undefined);
  }

  function renderFilters (playerType:string) {
    switch (playerType) {
      case FILTER_TYPE.HIGH_SCHOOL:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={dataSourceLinksFilter}
                  onChange={() => {
                    if (dataSourceLinksFilter) {
                      setDataSourceLinks([]);
                    }
                    setDataSourceLinksFilter(!dataSourceLinksFilter);
                  }}
                />
                Data Source Links
              </div>

              {dataSourceLinksFilter && (
                <MultiSelect
                  className={classes.multiSelectDropdown}
                  selectorRootClassName={classes.multiSelectorRoot}
                  labelWhenSelectedNone='Select one or more'
                  labelWhenSelectedAll='All'
                  items={DATA_SOURCE_LINKS.map((columnName:string) => ({
                    value: columnName,
                    // @ts-ignore
                    content: COLUMN_TITLE[columnName],
                  }))}
                  values={dataSourceLinks}
                  onChange={(selectedDataSourceLinks:string[]) => setDataSourceLinks(selectedDataSourceLinks)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={starsFilter}
                  onChange={() => {
                    if (starsFilter) {
                      setStarsRange([0, 5]);
                    }
                    setStarsFilter(!starsFilter);
                  }}
                />
                Star Rating
              </div>

              <Slider
                className={classes.slider}
                min={STAR_RATING_RANGE.MIN}
                step={1}
                max={STAR_RATING_RANGE.MAX}
                defaultValue={starsRange}
                marks={[
                  { value: STAR_RATING_RANGE.MIN, label: STAR_RATING_RANGE.MIN },
                  { value: STAR_RATING_RANGE.MAX, label: STAR_RATING_RANGE.MAX },
                ]}
                onChange={(value:number | number[]) => setStarsRange(value)}
                disabled={!starsFilter}
              />
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={offersFilter}
                  onChange={() => {
                    if (offersFilter) {
                      setOffersCountRange([OFFERS_RANGE.MIN, OFFERS_RANGE.MAX]);
                    }
                    setOffersFilter(!offersFilter);
                  }}
                />
                Offers
              </div>

              <Slider
                className={classes.slider}
                min={OFFERS_RANGE.MIN}
                step={1}
                max={OFFERS_RANGE.MAX}
                defaultValue={offersCountRange}
                marks={[
                  { value: OFFERS_RANGE.MIN, label: OFFERS_RANGE.MIN },
                  { value: OFFERS_RANGE.MAX, label: OFFERS_RANGE.MAX },
                ]}
                onChange={(value:number | number[]) => setOffersCountRange(value)}
                disabled={!offersFilter}
              />
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={offerSchoolFilter}
                  onChange={() => {
                    if (offerSchoolFilter) {
                      setSelectedOfferSchool(null);
                    }
                    setOfferSchoolFilter(!offerSchoolFilter);
                  }}
                />
                Offer School
              </div>

              {offerSchoolFilter && (
                <TeamAutoComplete
                  className={classes.teamAutoComplete}
                  label='Offer School'
                  teams={collegeTeams}
                  selectedTeam={selectedOfferSchool}
                  onSelect={(team:Team | null) => team && setSelectedOfferSchool(team)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={multiSportFilter}
                  onChange={() => {
                    if (multiSportFilter) {
                      setSelectedSports([]);
                    }
                    setMultiSportFilter(!multiSportFilter);
                  }}
                />
                Multi-sport
              </div>

              {multiSportFilter && (
                <MultiSelect
                  className={classes.multiSelectDropdown}
                  selectorRootClassName={classes.multiSelectorRoot}
                  labelWhenSelectedNone='None'
                  labelWhenSelectedAll='All'
                  items={OTHER_SPORTS.map((columnName:string) => ({
                    value: columnName,
                    // @ts-ignore
                    content: COLUMN_TITLE[columnName],
                  }))}
                  values={selectedSports}
                  onChange={(selectedSports:string[]) => setSelectedSports(selectedSports)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={hsClassYearFilter}
                  onChange={() => {
                    if (hsClassYearFilter) {
                      setHsClassYearRange([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
                    }
                    setHsClassYearFilter(!hsClassYearFilter);
                  }}
                />
                HS Class Year
              </div>

              <Slider
                className={classes.slider}
                label='HS Class'
                min={HS_YEAR_RANGE.MIN}
                step={1}
                max={HS_YEAR_RANGE.MAX}
                defaultValue={hsClassYearRange || [HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]}
                marks={[
                  { value: HS_YEAR_RANGE.MIN, label: HS_YEAR_RANGE.MIN },
                  { value: HS_YEAR_RANGE.MAX, label: HS_YEAR_RANGE.MAX },
                ]}
                onChange={(value:number | number[]) => setHsClassYearRange(value)}
                disabled={!hsClassYearFilter}
              />
            </div>

            {(states && states.length) && (
              <div className={classes.filterWrapper}>
                <div className={classes.filterLabel}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={hsStateFilter}
                    onChange={() => {
                      if (hsStateFilter) {
                        setSelectedHsStates([]);
                      }
                      setHsStateFilter(!hsStateFilter);
                    }}
                  />
                  State
                </div>

                {hsStateFilter && (
                  <MultiSelect
                    className={classes.multiSelectDropdown}
                    selectorRootClassName={classes.multiSelectorRoot}
                    labelWhenSelectedNone='Select one or more'
                    items={states.map((state:StateType) => ({
                      value: state.code,
                      content: `${state.code} - ${state.name}`,
                    }))}
                    values={selectedHsStates}
                    onChange={(selectedStates:string[]) => setSelectedHsStates(selectedStates)}
                  />
                )}
              </div>
            )}
          </div>
        );
      case FILTER_TYPE.COLLEGE:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={collegeTeamFilter}
                  onChange={() => {
                    if (collegeTeamFilter) setSelectedCollegeTeam(undefined);
                    setCollegeTeamFilter(!collegeTeamFilter);
                  }}
                />
                College Team
              </div>

              {collegeTeamFilter && (
                <TeamAutoComplete
                  className={classes.teamAutoComplete}
                  label='College Team'
                  teams={collegeTeams}
                  selectedTeam={selectedCollegeTeam}
                  onSelect={(team:Team | null) => team && setSelectedCollegeTeam(team)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={conferenceFilter}
                  onChange={() => {
                    if (conferenceFilter) setSelectedConference(undefined);
                    setConferenceFilter(!conferenceFilter);
                  }}
                />
                Conference
              </div>

              {conferenceFilter && (
                <ConferenceDropDown
                  className={classes.conferenceDropDown}
                  conferences={conferences}
                  selectedConference={selectedConference}
                  onChange={(conference:Conference | undefined) => setSelectedConference(conference)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={collegeClassYearFilter}
                  onChange={() => {
                    if (collegeClassYearFilter) {
                      setCollegeClassYearRangeFilter([RECRUITING_YEAR_RANGE.MIN, RECRUITING_YEAR_RANGE.MAX]);
                    }
                    setCollegeClassYearFilter(!collegeClassYearFilter)
                  }}
                />
                College Recruiting Class
              </div>

              <Slider
                className={classes.slider}
                label='College Class'
                min={RECRUITING_YEAR_RANGE.MIN}
                step={1}
                max={RECRUITING_YEAR_RANGE.MAX}
                defaultValue={collegeClassYearRangeFilter || [RECRUITING_YEAR_RANGE.MIN, RECRUITING_YEAR_RANGE.MAX]}
                marks={[
                  {value: RECRUITING_YEAR_RANGE.MIN, label: RECRUITING_YEAR_RANGE.MIN},
                  {value: RECRUITING_YEAR_RANGE.MAX, label: RECRUITING_YEAR_RANGE.MAX},
                ]}
                onChange={(value:number | number[]) => setCollegeClassYearRangeFilter(value)}
                disabled={!collegeClassYearFilter}
              />
            </div>
          </div>
        );
      case FILTER_TYPE.NFL:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={nflDraftYearFilter}
                  onChange={() => {
                    if (nflDraftYearFilter) {
                      setNFLDraftYearRange([DRAFT_YEAR_RANGE.MIN, DRAFT_YEAR_RANGE.MAX]);
                    }
                    setNFLDraftYearFilter(!nflDraftYearFilter);
                  }}
                />
                NFL Draft Year
              </div>

              <Slider
                disabled={!nflDraftYearFilter}
                className={classes.slider}
                min={DRAFT_YEAR_RANGE.MIN}
                step={1}
                max={DRAFT_YEAR_RANGE.MAX}
                defaultValue={nflDraftYearRange || [DRAFT_YEAR_RANGE.MIN, DRAFT_YEAR_RANGE.MAX]}
                marks={[
                  {value: DRAFT_YEAR_RANGE.MIN, label: DRAFT_YEAR_RANGE.MIN},
                  {value: DRAFT_YEAR_RANGE.MAX, label: DRAFT_YEAR_RANGE.MAX}
                ]}
                onChange={(value:number | number[]) => setNFLDraftYearRange(value)}
              />
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={nflDraftRoundFilter}
                  onChange={() => {
                    if (nflDraftRoundFilter) {
                      setNFLDraftRoundRange([DRAFT_ROUND_RANGE.MIN, DRAFT_ROUND_RANGE.MAX]);
                    }
                    setNFLDraftRoundFilter(!nflDraftRoundFilter);
                  }}
                />
                Draft Round
              </div>

              <Slider
                className={classes.slider}
                min={DRAFT_ROUND_RANGE.MIN}
                step={1}
                max={DRAFT_ROUND_RANGE.MAX}
                defaultValue={nflDraftRoundRange}
                marks={[
                  { value: DRAFT_ROUND_RANGE.MIN, label: DRAFT_ROUND_RANGE.MIN },
                  { value: DRAFT_ROUND_RANGE.MAX, label: DRAFT_ROUND_RANGE.MAX },
                ]}
                onChange={(value:number | number[]) => setNFLDraftRoundRange(value)}
                disabled={!nflDraftRoundFilter}
              />
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={nflUDFAFilter}
                  onChange={() => {
                    setNFLUDFAFilter(!nflUDFAFilter);
                  }}
                />
                UDFA
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={nflTeamFilter}
                  onChange={() => {
                    if (nflTeamFilter) setSelectedNFLTeam(undefined);
                    setNFLTeamFilter(!nflTeamFilter);
                  }}
                />
                NFL Team
              </div>

              {nflTeamFilter && (
                <TeamAutoComplete
                  className={classes.teamAutoComplete}
                  label='NFL Team'
                  teams={nflTeams}
                  selectedTeam={selectedNFLTeam}
                  onSelect={(team:Team | null) => team && setSelectedNFLTeam(team)}
                />
              )}
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <Drawer
      classes={{ paper: classes.importFiltersDrawer }}
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <h2 className={classes.title}>Apply Filters</h2>

        <StagingQuickFilter
          className={classes.quickFilter}
          value={selectedQuickFilter}
          onSelect={setSelectedQuickFilter}
        />

        <CloseButton
          className={classes.closeButton}
          onClick={onClose}
        />
      </div>

      <Divider variant='fullWidth' light />

      <div className={classes.drawerContent}>
        <div className={classes.filterWrapper}>
          <div className={classes.filterTitle}>Filter Type</div>
          <div className={classes.playerTypes}>
            {PLAYER_TYPES.map((playerType:{ content:string; value:string }) => (
              <span
                key={playerType.value}
                className={classes.checkboxWrap}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={!!playerTypeFilters.find(selectedFilter => selectedFilter === playerType.value)}
                  onChange={handlePlayerTypeChange(playerType)}
                />
                {playerType.content}
              </span>
            ))}
          </div>
        </div>

        <div className={classes.filterWrapper}>
          <div className={classes.filterTitle}>Status</div>

          <div className={classes.playerTypes}>
            <span className={classes.checkboxWrap}>
              <Checkbox
                className={classes.checkbox}
                checked={!!selectedPlayerStatuses.find(status => status === STATUS.NEW)}
                onChange={() => {
                  const isNew = !!selectedPlayerStatuses.find(status => status === STATUS.NEW);
                  setSelectedPlayerStatuses(isNew
                    ? selectedPlayerStatuses.filter(status => status !== STATUS.NEW)
                    : [...selectedPlayerStatuses, STATUS.NEW]
                  );
                }}
              />
              New Players
            </span>

            <span className={classes.checkboxWrap}>
              <Checkbox
                className={classes.checkbox}
                checked={!!selectedPlayerStatuses.find(status => status === STATUS.UPDATED)}
                onChange={() => {
                  const isUpdated = !!selectedPlayerStatuses.find(status => status === STATUS.UPDATED);
                  setSelectedPlayerStatuses(isUpdated
                    ? selectedPlayerStatuses.filter(status => status !== STATUS.UPDATED)
                    : [...selectedPlayerStatuses, STATUS.UPDATED]
                  );
                }}
              />
              Updated Players
            </span>
          </div>
        </div>

        <div className={classes.filterWrapper}>
          <div className={classes.filterTitle}>NCAA ID</div>

          <Switch
            className={classes.switch}
            checked={ncaaIdFilter}
            onChange={() => setNCAAIdFilter(!ncaaIdFilter)}
          />
        </div>

        {FILTERS_LIST
          .filter((filterType:string) => (PLAYER_TYPES_LIST.indexOf(filterType) === -1 || playerTypeFilters.indexOf(filterType) > -1))
          .map((filterType:string, index:number) => (
            <Accordion
              key={index}
              className={classes.accordion}
              summaryClassName={classes.accordionSummary}
              summary={FILTERS_TITLE[filterType]}
              expanded={index === 0}
            >
              {renderFilters(filterType)}
            </Accordion>
          ))}

        <div className={clsx(classes.buttons, classes.filterWrapper)}>
          <Button
            className={classes.button}
            primary
            onClick={applyFiltersAndClose}
          >
            Apply Filters
          </Button>

          <Button
            className={classes.button}
            onClick={resetAllFilters}
          >
            Reset Filters
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
