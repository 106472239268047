import React, { useCallback, useState, FormEvent } from 'react';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '../icons/SearchIcon';
import Loader from '../atoms/Loader';
import gql from '../services/gql';
import {
  getPrimaryCollege,
  getPrimaryHighSchool,
  getPrimaryNFLTeam,
} from '../services/player';
import { State } from '../redux/reducers';
import {
  COLOR_LIGHT_GRAY,
  COLOR_TEXT,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import QuickSearchPlayer from '../types/QuickSearchPlayer';
import User from '../types/User';
import HighSchool from '../types/HighSchool';
import College from '../types/College';
import NFLTeam from '../types/NFLTeam';
import Team from '../types/Team';

interface QuickSearchAutoCompleteProps {
  className?: string;
  itemClassName?: string;
  label?: string;
  playerTypes?: string[];
  value?: QuickSearchPlayer|null;
  user?: User;
  clearOnAction?: boolean;
  onSelect: (searchedPlayer:QuickSearchPlayer|null) => void;
  onEnter?: (searchText:string) => void;
}

const MIN_SEARCH_CHARACTERS = 2;

const useStyles = makeStyles((theme: Theme) => ({
  quickSearchAutoComplete: {
    background: COLOR_LIGHT_GRAY,
    position: 'relative',
    borderRadius: '4px',
  },
  open: {
    '& $label': {
      display: 'none',
    },
  },
  withValue: {
    '& $label': {
      display: 'none',
    },
  },

  endAdornment: {
    display: 'none',
  },
  option: {
    padding: theme.spacing(0.5, 1),
  },
  input: {
    minHeight: '50px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },

    '& > input': {
      padding: theme.spacing(1.5),
    },
  },

  label: {
    transform: 'none',
    top: theme.spacing(1.75),
    left: theme.spacing(1.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  labelWrapper: {
    display: 'flex',
  },

  inputAdornment: {
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(0),
  },
  searchIcon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
    color: COLOR_TEXT,
  },

  playerItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
  },
  cell: {
    margin: theme.spacing(0, 0.5),
  },
  photo: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2),
    borderRadius: '50%',
  },

  [MEDIA.DESKTOP_LARGE]: {
    input: {
      fontSize: theme.typography.pxToRem(16),
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: QuickSearchAutoComplete.name });

function QuickSearchAutoComplete (props: QuickSearchAutoCompleteProps) {
  const {
    className,
    itemClassName,
    label = 'Search Players',
    playerTypes = [],
    value,
    user,
    clearOnAction = false,
    onSelect,
    onEnter,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [quickSearchPlayers, setQuickSearchPlayers] = useState<QuickSearchPlayer[]>();
  const [quickSearchValue, setQuickSearchValue] = useState<string>('');

  const throttledFetchPlayers = useCallback(
    throttle(fetchPlayers, 1500),
    [playerTypes]
  );

  function fetchPlayers(searchText:string) {
    if (searchText.trim().length < MIN_SEARCH_CHARACTERS) return;

    setLoading(true);

    gql(`
      quickSearch(
        name: "${searchText}",
        includeArchive: ${user?.accessLevel?.hasArchiveAccess || false},
        playerTypes: ${playerTypes?.length
          ? `[${playerTypes.map(type => `"${type}"`).join(',')}]`
          : 'null'
        }
      ) {
        id
        slug
        firstName
        lastName
        highSchools
        playerColleges {
          isPrimary
          recruitingClass
          team {
            id
            name
          }
        }
        playerNFLTeams {
          isPrimary
          draftYear
          team {
            id
            name
          }
        }
      }
    `)
      .then((data:any) => data.quickSearch as QuickSearchPlayer[])
      .then((quickSearchPlayers:QuickSearchPlayer[]) => setQuickSearchPlayers(quickSearchPlayers))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function handleKeyUp (event:any) {
    throttledFetchPlayers.cancel();
    throttledFetchPlayers(event.target.value || '');
  }

  function onSubmit (event:FormEvent) {
    event.preventDefault();

    const searchText = event.currentTarget.querySelector('input')?.value || ''

    if (typeof onEnter === 'function' && searchText) {
      if (clearOnAction) setQuickSearchValue('');
      onEnter(searchText);
    }
  }

  function renderQuickSearchResults (player:QuickSearchPlayer) {
    const primaryHighSchool:HighSchool|undefined = getPrimaryHighSchool(player);
    const graduatingClass = primaryHighSchool?.graduatingClass;

    const primaryCollege:College|undefined = getPrimaryCollege(player);
    const primaryCollegeTeam:Team|undefined = primaryCollege?.team;
    const recruitingClass = primaryCollege?.recruitingClass;

    const primaryNFL:NFLTeam|undefined = getPrimaryNFLTeam(player);
    const primaryNFLTeam:Team|undefined = primaryNFL?.team;
    const nflDraftYear = primaryNFL?.draftYear;

    const teamName = primaryNFLTeam?.name || primaryCollegeTeam?.name || primaryHighSchool?.name || '';
    const classYear = nflDraftYear || recruitingClass || graduatingClass || '';
    const hsState = (primaryNFLTeam || primaryCollegeTeam) ? '' : primaryHighSchool?.state || '';

    return (
      <>
        <div className={classes.cell}>
          {player.firstName} {player.lastName}
        </div>

        {teamName && (
          <>
            <Divider orientation='vertical' light flexItem />
            <div className={classes.cell}>{teamName}</div>
          </>
        )}

        {hsState && (
          <>
            <Divider orientation='vertical' light flexItem />
            <div className={classes.cell}>{hsState}</div>
          </>
        )}

        {classYear && (
          <>
            <Divider orientation='vertical' light flexItem />
            <div className={classes.cell}>{classYear}</div>
          </>
        )}
      </>
    );
  }

  return (
    <Autocomplete
      className={clsx(
        classes.quickSearchAutoComplete,
        open && classes.open,
        value && classes.withValue,
        className,
      )}
      classes={{ endAdornment: classes.endAdornment, option: classes.option }}
      options={quickSearchPlayers || []}
      getOptionLabel={(player:QuickSearchPlayer) => `${player.firstName} ${player.lastName}`}
      renderOption={(player:QuickSearchPlayer) => (
        <div className={clsx(classes.playerItem, itemClassName)}>
          {renderQuickSearchResults(player)}
        </div>
      )}
      renderInput={(params) => (
        <form onSubmit={onSubmit}>
          <TextField
            {...params}
            autoFocus
            InputLabelProps={{ className: classes.label }}
            InputProps={{
              ...params.InputProps,
              className: classes.input,
              startAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  position='start'
                >
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
            placeholder={label}
            variant='standard'
          />
        </form>
      )}
      loading={loading}
      loadingText={<Loader inProgress />}
      value={clearOnAction ? null : value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onKeyUp={handleKeyUp}
      onChange={(event:any, player:QuickSearchPlayer|null) => {
        onSelect(player);
        if (clearOnAction) setQuickSearchValue('');
      }}
      inputValue={quickSearchValue}
      onInputChange={(event:any, quickSearchValue:string) => setQuickSearchValue(quickSearchValue)}
    />
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(QuickSearchAutoComplete);
