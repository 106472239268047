import React from 'react';
import Page from './Page';
import TeamPageTemplate from '../templates/TeamPageTemplate';

export default function TeamPage () {
  return (
    <Page title='Team'>
      <TeamPageTemplate />
    </Page>
  );
}
