import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NamedBox from '../atoms/NamedBox';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface PlayerProfileAboutProps {
  className?: string;
  about: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileAbout: {},
  text: {
    padding: theme.spacing(2),
    margin: 0,
    fontFamily: FONT_PROXIMA_NOVA,
  }
}), { name: PlayerProfileAbout.name });

export default function PlayerProfileAbout (props: PlayerProfileAboutProps) {
  const {
    className,
    about,
  } = props;
  const classes = useStyles();

  return (
    <NamedBox
      className={clsx(classes.playerProfileAbout, className)}
      name='About'
    >
      <p className={classes.text}>
        {about}
      </p>
    </NamedBox>
  );
}
