import { combineReducers } from 'redux';
import uiReducer, { UIState } from './ui';
import userReducer, { UserState } from './user';
import configurationsReducer, { ConfigurationsState } from './configurations';
import achievementsReducer, { AchievementsState } from './achievements';
import teamsReducer, { TeamsState } from './teams';
import statesReducer, { StatesState } from './states';
import conferencesReducer, { ConferencesState } from './conferences';
import userSettingsReducer , { UserSettingsState } from './user-settings';
import { RESET_STORE } from '../actions/root';
import { initialState } from '../initial-state';

export interface State {
  ui: UIState;
  user: UserState;
  configurations: ConfigurationsState;
  achievements: AchievementsState;
  teams: TeamsState;
  states: StatesState;
  conferences: ConferencesState;
  userSettings: UserSettingsState;
}

const appReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  configurations: configurationsReducer,
  achievements: achievementsReducer,
  teams: teamsReducer,
  states: statesReducer,
  conferences: conferencesReducer,
  userSettings: userSettingsReducer,
});

export default function rootReducer (state:State | undefined, action?:any) {
  return appReducer(
    action?.type === RESET_STORE ? initialState : state,
    action,
  );
}
