import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NotAvailable from '../atoms/NotAvailable';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import WatchListItem, { WatchListName } from '../types/WatchListItem';

interface WatchListProps {
  className?: string;
  list: WatchListItem[];
}

const useStyles = makeStyles((theme: Theme) => ({
  watchList: {
    width: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
  },

  watchListItem: {
    height: 40,
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    transition: 'filter 0.3s',

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  logo: {
    width: 'auto',
    height: 32,
  },
  withoutLogo: {
    '& $name': {
      marginLeft: 0,
    },
  },

  name: {
    marginLeft: theme.spacing(1.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 700,
  },

  year: {
    marginRight: theme.spacing(1),
  }
}), { name: WatchList.name });

export default function WatchList (props: WatchListProps) {
  const {
    className,
    list = [],
  } = props;
  const classes = useStyles();

  if (!list || !list.length) return <NotAvailable />;

  const seniorBowl = list.find(item => item.name.trim() === WatchListName.SeniorBowl);
  const cgcShowCase = list.find(item => item.name.trim() === WatchListName.CGSShowcase);
  const nflpaCase = list.find(item => item.name.trim() === WatchListName.NFLPA);
  const eastWestShrineCase = list.find(item => item.name.trim() === WatchListName.EastWestShrine);
  const watchListYear = list.find(item => item?.year)?.year || null;

  const watchList = [
    {
      logo: '/images/watch-list/senior-bowl.png',
      name: seniorBowl?.name,
      year: seniorBowl?.year,
    },
    {
      logo: '/images/watch-list/cgs.png',
      name: cgcShowCase?.name,
      year: cgcShowCase?.year,
    },
    {
      logo: '/images/watch-list/nflpa.png',
      name: nflpaCase?.name,
      year: nflpaCase?.year,
    },
    {
      logo: '/images/watch-list/east-west-shrine.png',
      name: eastWestShrineCase?.name,
      year: eastWestShrineCase?.year,
    },
  ].filter((item:any) => !!item.name);

  return (
    <ul className={clsx(classes.watchList, className)}>
      {watchList.map((item:any) => (
        <li
          key={item.name}
          className={clsx(classes.watchListItem, !item.logo && classes.withoutLogo)}
        >
            {
              item?.year && (
                <span className={classes.year}>
                  <strong>({item?.year})</strong>
                </span>
              )
            }
          {item.logo && (
            <img
              className={classes.logo}
              src={item.logo}
              alt={item.name}
            />
          )}

          <span className={classes.name}>
            {item.name}
          </span>
        </li>
      ))}
    </ul>
  );
}
