import React, { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { State } from '../redux/reducers';
import { getScrollbarWidth, DEFAULT_SCROLLBAR_WIDTH } from '../services/scrollbar';
import { isDesktop, ScreenSize } from '../services/screen-size';
import MEDIA from '../styles/media';

interface TableWithDoubleScrollBarsProps {
  className?: string;
  tableClassName: string;
  style?: any;
  screenSize: ScreenSize;
  children: any;
  disableBottomScrollbar?: boolean;
}

const useStyles = makeStyles(() => ({
  tableWrap: {
    overflow: 'scroll',
  },
  table: {},

  bottomScrollbarWrapper: {
    visibility: 'hidden',
    width: '100%',
    overflowX: 'scroll',
    zIndex: 1,
  },
  bottomScrollbar: {
    width: '100%',
    height: 1,
  },

  [MEDIA.DESKTOP]: {
    tableWrap: {
      transform: 'rotateX(180deg)',
    },

    table: {
      transform: 'rotateX(180deg)',
    },

    bottomScrollbarWrapper: {
      visibility: 'visible',
    },
  },
}), { name: TableWithDoubleScrollBars.name });

function TableWithDoubleScrollBars (props:TableWithDoubleScrollBarsProps) {
  const {
    className,
    tableClassName,
    style = {},
    screenSize,
    children,
    disableBottomScrollbar,
  } = props;

  const tableEl = useRef(null);
  const topScrollbarEl = useRef(null);
  const bottomScrollbarEl = useRef(null);

  const [scrollbarWidth, setScrollbarWidth] = useState<number>(DEFAULT_SCROLLBAR_WIDTH);
  const [isAutoHidingScrollbar, setIsAutoHidingScrollbar] = useState<boolean>(false);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const classes = useStyles({ scrollbarWidth });

  useLayoutEffect(() => {
    const currentScrollbarWidth = getScrollbarWidth();

    if (currentScrollbarWidth === 0) {
      setIsAutoHidingScrollbar(true);
    }

    setScrollbarWidth(currentScrollbarWidth || DEFAULT_SCROLLBAR_WIDTH);

    if (topScrollbarEl?.current && !disableBottomScrollbar) {
      (topScrollbarEl.current as any).addEventListener('scroll', () => {
        (bottomScrollbarEl.current as any).scrollLeft = (topScrollbarEl.current as any).scrollLeft;
      });
    }

    if (bottomScrollbarEl?.current && !disableBottomScrollbar) {
      (bottomScrollbarEl.current as any).addEventListener('scroll', () => {
        (topScrollbarEl.current as any).scrollLeft = (bottomScrollbarEl.current as any).scrollLeft;
      });
    }

    const resizeObserver = new window.ResizeObserver(entries => {
      const entry = (entries || [])[0] || {};

      if (entry?.target) {
        const tableOffsetWidth = (entry.target as any)?.offsetWidth;

        if (tableOffsetWidth) {
          setTableWidth(tableOffsetWidth);
        }
      }
    });

    if (!disableBottomScrollbar) {
      // @ts-ignore
      resizeObserver.observe(tableEl?.current);
    }

    return () => {
      if (!disableBottomScrollbar) {
        resizeObserver.disconnect();
      }
    }
  }, []);

  const isDesktopScreenSize = isDesktop(screenSize);

  const tableInlineStyles = isDesktopScreenSize ? { ...style } : undefined;
  if (isDesktopScreenSize && scrollbarWidth && (isAutoHidingScrollbar || disableBottomScrollbar)) {
    tableInlineStyles.paddingBottom = `${scrollbarWidth}px`;
  }

  return (
    <>
      <div
        className={clsx(classes.tableWrap, className)}
        ref={topScrollbarEl}
        style={tableInlineStyles}
      >
        <Table
          className={clsx(classes.table, tableClassName)}
          ref={tableEl}
        >
          {children}
        </Table>
      </div>

      {!disableBottomScrollbar && (
        <div
          className={classes.bottomScrollbarWrapper}
          ref={bottomScrollbarEl}
        >
          <div
            className={classes.bottomScrollbar}
            style={{
              width: `${tableWidth}px`,
              height: (isDesktopScreenSize && isAutoHidingScrollbar) ? `${scrollbarWidth}px` : '1px',
            }}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    screenSize: state.ui.screenSize,
  };
};

export default connect(
  mapStateToProps,
)(TableWithDoubleScrollBars);
