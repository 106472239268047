import React from 'react';
import Icon from '../atoms/Icon';

function PffLinkIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref} 
    style={{width: '42px', height: '35px'}}
    >
      <svg
      viewBox="0 0 22 22"
      >
      <path d="M14.03 14.27h2.4l.62-1.08h2.42l.77-1.32h-2.42l.31-.53h2.95l.93-1.6h-5.35l-2.62 4.54Zm-5.86 0h2.4l.62-1.08h2.42l.77-1.32h-2.42l.31-.53h2.95l.93-1.6H10.8l-2.62 4.54ZM4.63 9.73l-.93 1.6h3.56l-.31.53H3.39L2 14.26h2.4l.62-1.08h3.22l2-3.46H4.62Z"/>
      </svg>
    </Icon>
  );
}

export default React.forwardRef(PffLinkIcon);
