import React from 'react';
import Page from './Page';
import MessagesPageTemplate from '../templates/MessagesPageTemplate';

export default function MessagesPage () {
  return (
    <Page title='Messages'>
      <MessagesPageTemplate />
    </Page>
  );
}
