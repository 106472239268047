import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PageContainer from './PageContainer';
import TeamsIcon from '../icons/TeamsIcon';
import { COLOR_TEXT } from '../styles/colors';
import Toggle from '../atoms/Toggle';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import ConferencesList from '../organisms/ConferencesList';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchFBSConferences, fetchFCSConferences, 
  fetchDivIIConferences,fetchDivIIIConferences, 
} from '../redux/dispatchers/conferences';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import Conference from '../types/Conference';
import User from '../types/User';

const prepareConferences = (conferences: any) => {
  const mid = Math.ceil(conferences.length / 2);
  const left = conferences.slice(0, mid);
  const right = conferences.slice(mid);
  return { leftConferences: left, rightConferences: right };
};

function prepareToggleOptions(hasCollegeAccess: boolean, isNFLAccess: boolean) {
  const toggleOptions: { value: string; content: string }[] = [];
  if (hasCollegeAccess) {
    toggleOptions.push(
      { value: CATEGORY.DIVI, content: 'Div I' },
      { value: CATEGORY.DIVII, content: 'Div II' },
      { value: CATEGORY.DIVIII, content: 'Div III' },
    )
  };
  if (isNFLAccess) {
    toggleOptions.push({ value: CATEGORY.NFL, content: 'NFL' });
  }
  return toggleOptions; 
};

interface TeamsPageTemplateProps {
  user?: User;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  nflAccess: boolean;
  fbsConferences: Conference[];
  fcsConferences: Conference[];
  divIIConferences: Conference[];
  divIIConferencesLoading: boolean;
  divIIIConferences: Conference[];
  divIIIConferencesLoading: boolean;
  fbsConferencesLoading: boolean;
  fcsConferencesLoading: boolean;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
  fetchDivIIConferences: () => void;
  fetchDivIIIConferences: () => void;
}

const CATEGORY = {
  DIVI: 'DivI',
  DIVII: 'DivII',
  DIVIII: 'DivIII',
  NFL: 'nfl',
};

const useStyles = makeStyles(theme => ({
  teamPageTemplate: {
    minHeight: '100vh',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(3,3,0),
  },

  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
  },

  headerIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_TEXT,
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },

  headerToggle: {
    marginLeft: theme.spacing(4),
  },

  headerDropDownContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
  },
  teamDropDown: {
    width: '100%',
    maxWidth: 'none',
  },

  contentWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1260px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },

  conferencesList: {
    marginLeft: theme.spacing(2),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  '@media (max-width: 1023px)': {
    contentWrap: {
      display: 'block',
    },

    conferencesList: {
      marginLeft: 0,
      marginTop: theme.spacing(1),

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },

  [MEDIA.MOBILE]: {
    header: {
      flexWrap: 'wrap',
    },

    headerTitleContainer: {
      flexWrap: 'wrap',
    },

    headerIcon: {
      width: '28px',
      height: '28px',
      marginBottom: theme.spacing(2),
    },
    headerTitle: {
      margin: theme.spacing(0,2,2,1),
    },

    headerToggle: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
    },

    headerDropDownContainer: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },

  [MEDIA.DESKTOP]: {
    header: {
      padding: theme.spacing(5,5,0),
    },

    teamDropDown: {
      maxWidth: '300px',
    },

    contentWrap: {
      padding: theme.spacing(4,5,5,5),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    headerTitleContainer: {
      justifyContent: 'center',
      position: 'absolute',
      left: theme.spacing(5),
      top: theme.spacing(5),
    },

    teamDropDown: {
      margin: '0 auto',
      marginRight: '22%'
    },

    conferencesList: {
      marginLeft: theme.spacing(4),
    },
  },

  '@media (min-width: 1680px)': {
    teamDropDown: {
      maxWidth: '520px',
    },

    conferencesList: {
      marginLeft: theme.spacing(8),
    },
  },

  '@media (min-width: 1801px)': {
    conferencesList: {
      marginLeft: theme.spacing(12.5),
    },
  },
}), { name: TeamsPageTemplate.name });

function TeamsPageTemplate (props:TeamsPageTemplateProps) {
  const {
    user,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    nflAccess,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    fetchCollegeTeams,
    divIIConferences,
    divIIConferencesLoading,
    divIIIConferences,
    divIIIConferencesLoading,
    fetchNFLTeams,
    fetchFBSConferences,
    fetchFCSConferences,
    fetchDivIIConferences, 
    fetchDivIIIConferences, 
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [conferencesLoading, setConferencesLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    nflAccess ? CATEGORY.NFL : CATEGORY.DIVI
  );
  const [nflConferences, setNFLConferences] = useState<Conference[]>([]);
  const [conferenceDivision, setConferenceDivision] = React.useState({
    leftConferences: [],
    rightConferences: [],
  });

  useEffect(() => {
    setSelectedCategory(nflAccess ? CATEGORY.NFL : CATEGORY.DIVI);
  }, [nflAccess]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.accessCollegeTeamPage && !user.accessLevel.accessNFLTeamPage) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  useEffect(() => {
    if (selectedCategory === CATEGORY.DIVI) {
      if (!fbsConferences.length || !fcsConferences.length) {
        fetchCollegeConferencesAndTeams();
      }
    } else if (selectedCategory === CATEGORY.NFL) {
      if (!nflConferences.length) {
        fetchNFLConferencesAndTeams();
      }
    } else if (selectedCategory === CATEGORY.DIVII) {
      if (!divIIConferences.length) {
        fetchDivIIConferecesAndTeams();
      };
      divIIConferences?.length ? setConferenceDivision(prepareConferences(divIIConferences)) : setConferenceDivision({
        leftConferences: [],
        rightConferences: [], 
      });
    } else if (selectedCategory === CATEGORY.DIVIII) {
      if (!divIIIConferences.length) {
        fetchDivIIIConferecesAndTeams();
      };
      divIIIConferences?.length ? setConferenceDivision(prepareConferences(divIIIConferences)) : setConferenceDivision({
        leftConferences: [],
        rightConferences: [], 
      })
    };
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory === CATEGORY.DIVII && divIIConferences) {
      divIIConferences?.length ? setConferenceDivision(prepareConferences(divIIConferences)) : setConferenceDivision({
        leftConferences: [],
        rightConferences: [], 
      });
    } else if (selectedCategory === CATEGORY.DIVIII && divIIConferences) {
      divIIIConferences?.length ? setConferenceDivision(prepareConferences(divIIIConferences)) : setConferenceDivision({
        leftConferences: [],
        rightConferences: [], 
      });
    };
  }, [selectedCategory, divIIConferences, divIIIConferences]);

  const accessLevel = user?.accessLevel;

  function fetchCollegeConferencesAndTeams () {
    fetchFBSConferences();
    fetchFCSConferences();
    !collegeTeams.length && fetchCollegeTeams();
  }

  function fetchDivIIConferecesAndTeams() {
    !collegeTeams.length && fetchCollegeTeams();
    fetchDivIIConferences();
  }

  function fetchDivIIIConferecesAndTeams() {
    !collegeTeams.length && fetchCollegeTeams();
    fetchDivIIIConferences();
  }

  function fetchNFLConferencesAndTeams () {
    setConferencesLoading(true); 

    fetchNFLConferences()
      .finally(() => setConferencesLoading(false));

    fetchNFLTeams();
  }

  function fetchNFLConferences () {
    return gql(`
      nflConferences {
        abbr
        name
        iconUrl
        teams {
          id
        }
      }
    `)
      .then((data:any) => data.nflConferences as Conference[])
      .then((nflConferences:Conference[]) => {
        setNFLConferences(nflConferences || []);
      })
      .catch(console.error);
  }

  const teamsLoading = collegeTeamsLoading || nflTeamsLoading;
  const loading = conferencesLoading || teamsLoading || fbsConferencesLoading || fcsConferencesLoading || divIIConferencesLoading || divIIIConferencesLoading;
  const hasCollegeTeamPageAccess = accessLevel?.accessCollegeTeamPage || false;
  const hasNFLTeamPageAccess = accessLevel?.accessNFLTeamPage || false;
  const preparedToggleOptions = prepareToggleOptions(hasCollegeTeamPageAccess, hasNFLTeamPageAccess)

  return (
    <PageContainer>
      <Paper className={classes.teamPageTemplate}>
        <div className={classes.header}>
          <div className={classes.headerTitleContainer}>
            <TeamsIcon className={classes.headerIcon} />
            <h2 className={classes.headerTitle}>Teams</h2>

            {(preparedToggleOptions?.length) && (
              <Toggle
                className={classes.headerToggle}
                items={preparedToggleOptions}
                value={selectedCategory}
                onSelect={(category:string) => setSelectedCategory(category)}
              />
            )}
          </div>

          <div className={classes.headerDropDownContainer}>
            <TeamAutoComplete
              className={classes.teamDropDown}
              loading={teamsLoading}
              teams={
                selectedCategory === CATEGORY.NFL
                  ? nflTeams
                  : collegeTeams
              }
              onSelect={(team:Team | null) => team && history.push(`/team/${team.slug}`)}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>

        <div className={classes.contentWrap}>
          {accessLevel?.accessCollegeTeamPage && selectedCategory === CATEGORY.DIVI && (
            <>
              <ConferencesList
                className={classes.conferencesList}
                divisionName='FBS'
                conferences={fbsConferences}
                teams={collegeTeams}
                loading={loading}
              />

              <ConferencesList
                className={classes.conferencesList}
                divisionName='FCS'
                conferences={fcsConferences}
                teams={collegeTeams}
                loading={loading}
              />
            </>
          )}

          {accessLevel?.accessCollegeTeamPage && (selectedCategory === CATEGORY.DIVII || selectedCategory === CATEGORY.DIVIII) && conferenceDivision.leftConferences && (
            <>
              <ConferencesList
                className={classes.conferencesList}
                divisionName={selectedCategory === CATEGORY.DIVII ? 'DIVISION II' : 'DIVISION III'}
                conferences={conferenceDivision?.leftConferences}
                teams={collegeTeams}
                loading={loading}
                hasSubDivision={false}
              />

              <ConferencesList
                className={classes.conferencesList}
                divisionName={selectedCategory === CATEGORY.DIVII ? 'DIVISION II' : 'DIVISION III'}
                conferences={conferenceDivision?.rightConferences?.length ? conferenceDivision?.rightConferences : []}
                teams={collegeTeams}
                loading={loading}
                hasSubDivision={false}
              />
            </>
          )}

          {accessLevel?.accessNFLTeamPage && selectedCategory === CATEGORY.NFL && (
            <>
              <ConferencesList
                className={classes.conferencesList}
                conferences={nflConferences.length ? [nflConferences[0]] : []}
                teams={nflTeams}
                useConferenceAbbr
                loading={loading}
              />

              <ConferencesList
                className={classes.conferencesList}
                conferences={nflConferences.length ? [nflConferences[1]] : []}
                teams={nflTeams}
                useConferenceAbbr
                loading={loading}
              />
            </>
          )}
        </div>
      </Paper>
    </PageContainer>
  );
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    nflAccess: state.ui.nflAccess,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
    user: state.user,
    divIIConferences: state.conferences.divIIConferences,
    divIIConferencesLoading: state.conferences.divIIConferencesLoading,
    divIIIConferences: state.conferences.divIIIConferences,
    divIIIConferencesLoading: state.conferences.divIIIConferencesLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchFBSConferences,
      fetchFCSConferences,
      fetchDivIIConferences,
      fetchDivIIIConferences, 
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamsPageTemplate);
