import React, { useState } from 'react';
import { ToastType } from '../atoms/Toast';
import Button from '../atoms/Button';
import ChangePasswordModal from '../molecules/ChangePasswordModal';

interface ChangePasswordButtonProps {
  className?: string;
  showToast: (message:any, toastType:ToastType) => void;
}

export default function ChangePasswordButton (props:ChangePasswordButtonProps) {
  const {
    className,
    showToast,
  } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        className={className}
        onClick={() => setDialogIsOpen(true)}
      >
        Change Password
      </Button>

      <ChangePasswordModal
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        showToast={showToast}
      />
    </>
  );
}
