import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import { FormatType } from '../services/event-names-and-formats';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_WHITE,
} from '../styles/colors';

interface SliderProps {
  className?: string;
  defaultValue: number | number[];
  marks?: any;
  min?: number;
  max?: number;
  label?: string;
  step?: number;
  disabled?: boolean;
  showValueLabelComponent?: boolean;
  labelDisplay?: string;
  onChange: (value: number | number[], event:any) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 8,
    color: COLOR_BLUE,
  },
  thumb: {
    height: 17,
    width: 17,
    backgroundColor: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    marginTop: -8,
    marginLeft: -5,

    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },

    '&$disabled': {
      height: 17,
      width: 17,
      marginTop: -8,
      marginLeft: -5,
    },
  },
  disabled: {
    '& .thumb': {
      height: 17,
      width: 17,
    }
  },
  marked: {
    marginBottom: 0,
  },
  track: {
    height: 3,
  },
  markLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(1),
  }
}), { name: Slider.name });

export default function Slider (props: SliderProps) {
  const {
    className,
    defaultValue = 0,
    marks,
    min = 0,
    max= 0,
    label = 'Slider',
    step = 1,
    disabled = false,
    showValueLabelComponent = false,
    labelDisplay,
    onChange = () => {},
  } = props;
  const classes = useStyles();

  const [sliderMarks, setSliderMarks] = useState<any>(marks);

  useEffect(() => {
    if (isArray(defaultValue)) {
      const allMarks = [
        ...marks.map((mark:any) => ({
          value: mark.value,
          label: formatLabel(mark.label),
        })),
        ...defaultValue.map((sliderValue:number) => {
          if (!marks.filter((mark:any) => mark.value === sliderValue).length) {
            return {
              value: sliderValue,
              label: formatLabel(sliderValue),
            }
          }
      })];

      setSliderMarks(allMarks.filter((mark:any) => !!mark));
    }
  }, [defaultValue]);

  function formatLabel (label:number):string|number {
    switch (labelDisplay) {
      case FormatType.SECONDS:
        return Number(label).toFixed(2);
      case FormatType.INCHES:
        return `${label}"`;
      case FormatType.WEIGHT:
        return `${label} lbs`;
      case FormatType.FEET_AND_INCHES:
        return inchesToFeetAndInches(label);
      case FormatType.MINUTES_SECONDS_MS:
        return secondsToMinutesSecondsMs(label);
      default:
        return label;
    }
  }

  return (
    <MaterialSlider
      key={`slider-${label}`}
      className={className}
      classes={{
        root: classes.root,
        thumb: classes.thumb,
        track: classes.track,
        markLabel: classes.markLabel,
        marked: classes.marked,
        disabled: classes.disabled,
      }}
      ValueLabelComponent={showValueLabelComponent
        ? ({children, open, value}) => (
          <Tooltip
            open={open}
            enterTouchDelay={0}
            placement='top'
            title={value}
          >
            {children}
          </Tooltip>
        )
        : undefined
      }
      disabled={disabled}
      getAriaLabel={() => label}
      min={min}
      step={step}
      max={max}
      value={defaultValue}
      marks={sliderMarks}
      onChange={(event:any, value:number | number[]) => onChange(value, event)}
    />
  )
}
