import User from '../../types/User';

export const SET_USER = 'user/SET_USER';
export const UPDATE_PREFERENCES = 'user/UPDATE_PREFERENCES';

export function setUserAction(user:User) {
  return {
    type: SET_USER,
    user,
  };
}

export function setAndUpdateUserPreferencesAction(preferences:any) {
  return {
    type: UPDATE_PREFERENCES,
    preferences,
  };
}

