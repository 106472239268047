import React from 'react';
import Icon from '../atoms/Icon';

function AgilityIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M23.5,23 C21.0147186,23 19,25.0147186 19,27.5 C19,29.9852814 21.0147186,32 23.5,32 C25.9852814,32 28,29.9852814 28,27.5 C28,25.0147186 25.9852814,23 23.5,23 Z M23.5,26 C24.3284271,26 25,26.6715729 25,27.5 C25,28.3284271 24.3284271,29 23.5,29 C22.6715729,29 22,28.3284271 22,27.5 C22,26.6715729 22.6715729,26 23.5,26 Z'/>
        <path d='M14,2.75027423 C13.7594025,2.88602911 13.2960296,3.1861957 12.6847014,3.6503041 C11.6490047,4.43658473 10.6105087,5.38694591 9.64259452,6.49913737 C8.22447006,8.12864746 7.08482659,9.96249213 6.3205267,12 L3.51329119,10.932804 C4.41194713,8.53712327 5.74169641,6.39737193 7.38555662,4.50847791 C8.49679929,3.23159342 9.68597131,2.14334375 10.8779427,1.23842245 C11.6032153,0.687809703 12.1794208,0.31455171 12.5328681,0.115122379 L14,2.75027423 Z M8.57456877,2.68716734 C8.37585021,2.78990823 7.98641338,3.0214435 7.47071341,3.38135098 C6.59584246,3.9919242 5.71872362,4.73099153 4.90255385,5.59586098 C4.3781179,6.15158928 3.89618464,6.7421921 3.46551585,7.36714058 L1,5.64524105 C1.52083878,4.88944584 2.09992309,4.1797857 2.72704394,3.51524544 C4.38616746,1.75712439 6.04699407,0.598032004 7.20369089,0 L8.57456877,2.68716734 Z'/>
        <path d='M20.7793656,6.77999992 L18.2487046,5 C17.1042217,6.55930439 16.2569322,8.09632187 15.8423369,9.57976251 L15.7665895,9.8507898 L15.7944743,10.1304487 C15.9761821,11.952812 17.5642417,17.3930909 18.7227697,20.052467 C19.0543221,20.8140863 18.8635446,21.7051427 18.2420735,22.2872993 C17.5478422,22.9381904 16.4883519,23.0347012 15.6832621,22.5205896 L10.9997099,19.5295801 C10.0029993,18.8923161 8.72167595,18.8675708 7.69917373,19.4657073 L1,23.3807133 L2.59730058,26 L9.29705032,22.0846572 C9.29749849,22.0843952 9.29691108,22.0843838 9.29640827,22.0840624 L13.9806989,25.0755436 C15.9989154,26.3643297 18.657004,26.1222 20.3969755,24.4908525 C21.9511602,23.0349866 22.4316015,20.791009 21.5893181,18.8561742 C20.6036801,16.5936633 19.2068994,11.8697699 18.9321068,10.10958 C19.2652976,9.1019328 19.9122104,7.96145826 20.7793656,6.77999992 Z'/>
        <path d='M28.4293499,8.2262926 L29,5.30595672 L17.2249827,3 L16.6769237,3.47764981 C16.070254,4.00638068 15.2076796,4.92245945 14.3476043,6.16357058 C13.0004501,8.10754923 12.1372494,10.2205312 12.0134002,12.458132 C11.9497687,13.6080781 12.1152907,15.1820662 12.4559359,17.1161494 C12.5803115,17.8223174 12.7259289,18.5619333 12.888523,19.3237865 C13.079228,20.2173569 13.2832711,21.0956943 13.4873767,21.9224646 C13.6101771,22.4198923 13.7061799,22.7913555 13.7620682,23 L16.6330621,22.2292764 C16.5819965,22.0386361 16.49083,21.685886 16.3729842,21.2085279 C16.1760126,20.4106553 15.9789786,19.5624897 15.795345,18.702053 C15.639819,17.9733183 15.5009977,17.268221 15.3832078,16.5994445 C15.0793734,14.8743624 14.9334608,13.4868443 14.9812754,12.6227399 C15.0709033,11.0034192 15.7308969,9.38785697 16.7898622,7.85974232 C17.2009995,7.26646047 17.6428716,6.7298388 18.0829991,6.26241081 C18.0998569,6.24450733 18.116437,6.2269885 18.1327303,6.20985743 L28.4293499,8.2262926 Z'/>
        <path
          d='M27.7984956,4 L31,3.68378378 L30.6340229,1 L20.8322002,1.96621622 L20.4375814,2.30540541 C20.4200781,2.32027027 20.256184,2.46351351 20,2.70405405 L27.7984956,4 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(AgilityIcon);
