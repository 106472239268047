import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import TableHeadCell from '../atoms/TableHeadCell';
import Avatar from '../atoms/Avatar';
import Download from '../atoms/Download';
import NotAvailable from '../atoms/NotAvailable';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Pagination from '../atoms/Pagination';
import StaffRecordTooltip from './StaffRecordTooltip';
import PageLimitDropdown from './PageLimitDropdown';
import gql from '../services/gql';
import exportXLSReport, { XLSExportType } from '../services/export-xls-report';
import { formatDatetime } from '../services/converter';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { COLLEGE_ACHIEVEMENTS, NFL_ACHIEVEMENTS } from '../redux/reducers/achievements';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_LIGHT_GRAY,
  COLOR_LIGHT_RED,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_BORDER,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { Achievement } from '../types/Achievement';
import User from '../types/User';

interface StaffRecordsTableProps {
  className?: string;
  user: User;
  adminAccess: boolean;
}

const STAFF_RECORD_COLUMN = {
  NAME: 'name',
  RANK: 'rank',
  ACHIEVEMENTS: 'achievements',
  LAST_LOGIN_DATE: 'lastLoginDate',
  LOGINS_IN_LAST_30_DAYS: 'loginsInLast30Days',
  TOTAL_LOGINS: 'logins',
};

const STAFF_RECORD_COLUMNS = [
  STAFF_RECORD_COLUMN.NAME,
  STAFF_RECORD_COLUMN.RANK,
  STAFF_RECORD_COLUMN.ACHIEVEMENTS,
  STAFF_RECORD_COLUMN.LAST_LOGIN_DATE,
  STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS,
  STAFF_RECORD_COLUMN.TOTAL_LOGINS,
];

const STAFF_RECORD_COLUMN_TITLE = {
  [STAFF_RECORD_COLUMN.NAME]: 'Name',
  [STAFF_RECORD_COLUMN.RANK]: 'Rank',
  [STAFF_RECORD_COLUMN.ACHIEVEMENTS]: 'Achiev.',
  [STAFF_RECORD_COLUMN.LAST_LOGIN_DATE]: 'Last Login',
  [STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS]: 'Logins Last 30 Days',
  [STAFF_RECORD_COLUMN.TOTAL_LOGINS]: 'Logins',
};

const useStyles = makeStyles(theme => ({
  staffRecordsTable: {
    position: 'relative',
  },
  staffRecordsTableWrapper: {
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },

  titleRow: {
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  headerTitle: {
    color: COLOR_TEXT,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1,
    ...theme.typography.h2,
    margin: 0,
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
  },

  dropdowns: {
    display: 'flex',
  },
  dropdownWrapper: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    marginLeft: theme.spacing(2),
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(1),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  exportReport: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    marginRight: theme.spacing(1),

    '&:first-of-type': {
      marginLeft: 'auto',
    }
  },
  downloadIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
  },
  exportsTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: 'none',
    background: COLOR_WHITE,
  },
  tableHead: {
    border: 'none',
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderTop: `1px solid ${COLOR_LIGHT_GRAY}`,
  },
  tableHeadCell: {
    borderLeft: 'none',
    color: COLOR_DARK_GRAY,
    padding: theme.spacing(1.25),
    textAlign: 'center',

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  tableRow: {
    height: '50px',
  },
  highlighted: {
    backgroundColor: COLOR_LIGHT_RED,
    fontWeight: 700,

    '& $tableCell': {
      fontWeight: 700,
    }
  },

  tableCell: {
    padding: theme.spacing(1.25),
    fontSize: theme.typography.pxToRem(16),
    border: 'none',
    textAlign: 'center',
  },
  nameCell: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
  },
  rankCell: {
    color: COLOR_ORANGE,
  },
  achievementsCell: {
    color: COLOR_GREEN,
  },
  loginsCell: {
    minWidth: '80px',
  },
  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },
  playerName: {
    alignItems: 'center',
    display: 'flex',
  },
  avatar: {
    height: '32px',
    width: '32px',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  [MEDIA.MOBILE]: {
    titleRow: {
      flexWrap: 'wrap',
      padding: theme.spacing(2),
    },

    headerTitle: {
      width: '100%',
    },

    exportReport: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: 0,

      '&:first-of-type': {
        marginLeft: 0,
      }
    },

    divider: {
      display: 'none',
    },

    dropdowns: {
      width: '100%',
    },

    dropdownWrapper: {
      marginLeft: 0,
    },
  },
}), { name: StaffRecordsTable.name });

export default function StaffRecordsTable(props: StaffRecordsTableProps) {
  const classes = useStyles();
  const {
    className,
    user,
    adminAccess,
  } = props;

  const [staffRecords, setStaffRecords] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.STAFF_RECORD_TABLE) || {}).currentPage || 1);
  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.STAFF_RECORD_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.STAFF_RECORD_TABLE) || {}).limit || 10);

  const [loading, setLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    fetchStaffRecords();
  }, []);

  useEffect(() => {
    if (prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [limit]);

  useEffect(() => {
    clientStorage.save(StorageKey.STAFF_RECORD_TABLE, {currentPage, limit});
  }, [currentPage, limit]);

  function fetchStaffRecords () {
    setLoading(true);

    gql(`
      staffRecords {
        id
        firstName
        lastName
        rank
        photoUrl
        nflTeamId
        lastLogin
        last30DaysLoginCount
        totalLogins
      }
    `)
      .then((data:any) => data.staffRecords as User[])
      .then((staffRecords:User[]) => {
        // sort by rank
        return staffRecords.sort((a:User, b:User) => {
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        });
      })
      .then(fetchAchievements)
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchAchievements (staffRecords:User[] = []) {
    return gql(`
      achievementsForUsers (userIds: [${staffRecords.map(user => user.id).toString()}])
    `)
      .then((data:any) => data.achievementsForUsers as Array<Achievement[]>)
      .then((achievements:Array<Achievement[]>) => {
        if (!achievements || !achievements.length) {
          showToast('Failed to fetch achievements', ToastType.ERROR);
        }

        const usersWithAchievements = staffRecords.map((user:User, index:number) => {
          const achievementsForUser = user.nflTeamId ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS;

          return {
            ...user,
            achievements: achievements && achievements[index] && achievements[index].length
              ? achievements[index].filter((achievement:Achievement) => achievementsForUser.includes(achievement))
              : [],
          };
        });

        setStaffRecords(usersWithAchievements);
      })
      .catch(error => {
        console.error(error);
        showToast('Failed to fetch achievements', ToastType.ERROR);
      });
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function renderTableRow(staffRecord:User) {
    return (
      <TableRow
        key={staffRecord.id}
        className={clsx(classes.tableRow, staffRecord.id === user.id && classes.highlighted)}
      >
        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === STAFF_RECORD_COLUMN.NAME && classes.nameCell,
              tableColumn.value === STAFF_RECORD_COLUMN.RANK && classes.rankCell,
              tableColumn.value === STAFF_RECORD_COLUMN.ACHIEVEMENTS && classes.achievementsCell,
              tableColumn.value === STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS && classes.loginsCell,
            )}
          >
            {tableColumn.renderContent(staffRecord)}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  function onXLSDownload () {
    if (staffRecords && staffRecords.length) {
      setLoading(true);

      exportXLSReport(
        XLSExportType.USERS,
        { ids: staffRecords.map(staffRecord => staffRecord.id) },
      )
        .catch(error => {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        })
        .finally(() => setLoading(false));
    }
  }

  const tableColumns = STAFF_RECORD_COLUMNS.map((column: string) => {
    switch (column) {
      case STAFF_RECORD_COLUMN.NAME:
        return {
          value: STAFF_RECORD_COLUMN.NAME,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.NAME],
          renderContent: (user:User) => (
            <StaffRecordTooltip userId={user.id}>
              <div className={classes.playerName}>
                <Avatar
                  className={classes.avatar}
                  src={user.photoUrl}
                  alt={`Photo of ${user.firstName} ${user.lastName}`}
                  initials={`${(user.firstName || ' ')[0]}${(user.lastName || ' ')[0]}`.trim()}
                />
                <div className={classes.link}>
                  {`${user.firstName} ${user.lastName}`}
                </div>
              </div>
            </StaffRecordTooltip>
          ),
        };
      case STAFF_RECORD_COLUMN.RANK:
        return {
          value: STAFF_RECORD_COLUMN.RANK,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.RANK],
          renderContent: (user:User) => user.rank,
        };
      case STAFF_RECORD_COLUMN.ACHIEVEMENTS:
        return {
          value: STAFF_RECORD_COLUMN.ACHIEVEMENTS,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.ACHIEVEMENTS],
          renderContent: (user:User) => {
            const achievementsNumber = user.achievements && user.achievements.length ? user.achievements.length : 0;
            const achievementsToDisplay = user.nflTeamId ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS;

            return `${achievementsNumber}/${achievementsToDisplay.length}`;
          },
        };
      case STAFF_RECORD_COLUMN.LAST_LOGIN_DATE:
        return {
          value: STAFF_RECORD_COLUMN.LAST_LOGIN_DATE,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.LAST_LOGIN_DATE],
          renderContent: (user:User) => formatDatetime(user.lastLogin) || <NotAvailable />,
        };
      case STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS:
        return {
          value: STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.LOGINS_IN_LAST_30_DAYS],
          renderContent: (user:User) => user.last30DaysLoginCount || <NotAvailable />,
        };
      case STAFF_RECORD_COLUMN.TOTAL_LOGINS:
        return {
          value: STAFF_RECORD_COLUMN.TOTAL_LOGINS,
          title: STAFF_RECORD_COLUMN_TITLE[STAFF_RECORD_COLUMN.TOTAL_LOGINS],
          renderContent: (user:User) => user.totalLogins || <NotAvailable />,
        };
    }
  });

  const totalPages = Math.ceil(staffRecords.length / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = staffRecords.length < limit ? staffRecords.length : (limit * currentPage);

  return (
    <>
      <div className={clsx(classes.staffRecordsTable, className)}>
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <div className={classes.titleRow}>
            <h2 className={classes.headerTitle}>
              Staff Records
            </h2>

            <div className={classes.actions}>
              {(user.accessLevel.exportAllowanceType !== 'none' && adminAccess) && (
                <>
                  <Download
                    className={classes.exportReport}
                    label='Export Report'
                    onClick={onXLSDownload}
                  />
                  <Divider orientation='vertical' light flexItem className={classes.divider} />
                </>
              )}

              <div className={classes.dropdowns}>
                <div className={classes.dropdownWrapper}>
                  Show
                  <PageLimitDropdown
                    className={clsx(classes.dropdown, classes.limitDropDown)}
                    selectorRootClassName={classes.dropdownSelector}
                    value={String(limit)}
                    onChange={(limit:string) => setLimit(Number(limit))}
                  />
                  Records
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.staffRecordsTableWrapper}>
          <Table className={classes.exportsTable}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {tableColumns.map((tableColumn:any) => (
                  <TableHeadCell
                    key={tableColumn.value}
                    className={clsx(
                      classes.tableHeadCell,
                      tableColumn.value === STAFF_RECORD_COLUMN.NAME && classes.nameCell,
                    )}
                  >
                    {tableColumn.title}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {!!(staffRecords && staffRecords.length) && (
                staffRecords
                .slice(itemsFrom, itemsTo)
                .map((user:User) => renderTableRow(user))
              )}
            </TableBody>
          </Table>
        </div>

        <div className={classes.paginationWrapper}>
          <span className={classes.paginationText}>
            Showing {itemsFrom + 1} to {itemsTo} of {staffRecords.length}
          </span>

          {totalPages > 1 && (
            <Pagination
              className={classes.pagination}
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={page => setCurrentPage(page)}
            />
          )}
        </div>
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
