import React from 'react';
import Icon from '../atoms/Icon';

function OffersIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M9.927 16c-.998 0-2.139.14-3.402.396-.989.201-2.03.468-3.097.786A48.725 48.725 0 000 18.347l.983 2.48c.165-.066.477-.184.907-.337.72-.256 1.498-.513 2.301-.752a33.524 33.524 0 012.866-.728c1.102-.224 2.074-.343 2.87-.343 2.397 0 7.895 2.804 8.424 3.605l.476.722 8.818-1.435a1.443 1.443 0 011.608.962 1.433 1.433 0 01-.78 1.77c-4.576 2.012-12.656 5.042-13.507 5.042-.323 0-1.254-.501-3.288-1.792l-.079-.05c-2.57-1.63-3.573-2.158-4.71-2.158-.932 0-1.895.158-2.85.42-.567.155-1.014.313-1.287.425l1.02 2.465c.178-.073.522-.195.974-.319.742-.203 1.48-.324 2.142-.324.364 0 1.35.518 3.278 1.742l.08.05c2.759 1.75 3.607 2.208 4.72 2.208 1.419 0 9.461-3.016 14.584-5.27a4.098 4.098 0 002.238-5.051 4.113 4.113 0 00-4.578-2.752l-7.29 1.187C17.938 18.444 12.774 16 9.928 16z'/>
        <path d='M19.18 19.962l.399.28a3.689 3.689 0 01.923 5.18 3.773 3.773 0 01-4.31 1.369l-.134-.055L10 23.882l1.172-2.438 5.978 2.816c.418.12.87-.036 1.121-.39a1.008 1.008 0 00-.261-1.419l-.409-.287 1.579-2.202zM20.5 0a7.513 7.513 0 016.495 3.713 7.362 7.362 0 010 7.425 7.513 7.513 0 01-6.494 3.712 7.513 7.513 0 01-6.494-3.712 7.362 7.362 0 010-7.425A7.513 7.513 0 0120.5 0zm0 2.7c-1.704 0-3.28.9-4.132 2.363a4.685 4.685 0 000 4.725 4.781 4.781 0 004.133 2.362c1.705 0 3.28-.9 4.133-2.362a4.685 4.685 0 000-4.725A4.781 4.781 0 0020.5 2.7z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(OffersIcon);
