import React from 'react';
import Icon from '../atoms/Icon';

function WrestlingIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(2, 6)'>
          <path
            d='M18.5,2 C17.1192881,2 16,3.11928813 16,4.5 C16,5.88071187 17.1192881,7 18.5,7 C19.8807119,7 21,5.88071187 21,4.5 C21,3.11928813 19.8807119,2 18.5,2 L18.5,2 Z M10.5,0 C9.11928813,0 8,1.11928813 8,2.5 C8,3.88071187 9.11928813,5 10.5,5 C11.8807119,5 13,3.88071187 13,2.5 C13,1.11928813 11.8807119,0 10.5,0 Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M15.234,15.756 L18.697,13.605 C19.01,13.411 19.24,13.109 19.344,12.758 L19.809,11.194 L21.102,12.804 L18.776,16.099 L17.83,21 L19.867,21 L20.659,16.9 L23.606,12.725 L20.691,9.095 L21.583,8.058 L21.751,8.053 L24.478,10.845 C25.011,11.39 25.149,12.22 24.823,12.909 L23.253,16.219 C23.043,16.664 23.063,17.184 23.309,17.612 L25.265,21 L27.575,21 L25.172,16.839 L26.629,13.766 C27.313,12.325 27.024,10.589 25.908,9.448 L23.182,6.657 C22.769,6.234 22.192,6.006 21.603,6.02 C21.012,6.039 20.452,6.306 20.066,6.755 L18.503,8.573 L17.482,12.006 L14.179,14.057 L15.234,15.756 Z M3.153,15.521 L0.506,21 L2.727,21 L5.041,16.212 C5.207,15.867 5.235,15.476 5.121,15.112 L4.126,11.938 C3.919,11.276 3.978,10.572 4.293,9.956 L5.762,7.073 C6.1,6.408 6.867,6.112 7.565,6.351 L5.129,11.984 L7.832,15.744 L8.976,21 L11.023,21 L9.764,15.216 C9.72,15.017 9.638,14.832 9.516,14.661 L7.414,11.737 L8.976,8.126 L9.868,10.114 C10.109,10.652 10.646,11 11.237,11 L15.094,11 L15.094,9 L11.56,9 L9.794,5.062 L8.359,4.5 C6.68,3.843 4.798,4.558 3.979,6.164 L2.51,9.047 C1.957,10.133 1.853,11.372 2.218,12.536 L3.153,15.521 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(WrestlingIcon);
