import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BACKGROUND_LIGHT , COLOR_TEXT } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';

interface ListItemProps {
  className?: string;
  iconClassName?: string;
  contentClassName?: string;
  icon?: React.ReactNode;
  title: string;
  children: any;
  extendWith?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    padding: theme.spacing(.5, 2),
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
    whiteSpace: 'nowrap',

    '&:nth-of-type(2n)': {
      background: COLOR_BACKGROUND_LIGHT,
    }
  },
  withIcon: {
    '& $titleCell': {
      marginLeft: theme.spacing(1.5),
    },
    '& $titleCellExtWidth': {
      marginLeft: theme.spacing(1.5),
    },
  },

  iconCell: {
    flexShrink: 0,
  },
  icon: {
    width: '26px',
    height: '26px',
  },

  titleCell: {
    width: 120,
    flexShrink: 0,
    whiteSpace: 'break-spaces',
  },

  titleCellExtWidth: {
    width: 200,
    flexShrink: 0,
    whiteSpace: 'break-spaces',
  },

  contentCell: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden',
  },

  [MEDIA.MOBILE]: {
    listItem: {
      padding: theme.spacing(.5, 1),
    },

    withIcon: {
      '& $titleCell': {
        marginLeft: 0,
      },
      '& $titleCellExtWidth': {
        marginLeft: 0,
      },
    },

    icon: {
      display: 'none',
    },

    titleCell: {
      width: 90,
    },
  },

  '@media (max-width: 480px)': {
    listItem: {
      flexWrap: 'wrap',
      padding: theme.spacing(1),
    },

    contentCell: {
      flexWrap: 'wrap',
      margin: theme.spacing(1, 0),
    },
  },
}), { name: ListItem.name });

export default function ListItem (props: ListItemProps) {
  const {
    className,
    contentClassName,
    iconClassName,
    icon,
    title,
    children,
    extendWith = false
  } = props;
  const classes = useStyles();
  const Icon:any = icon;

  return (
    <div
      className={clsx(
        classes.listItem,
        icon && classes.withIcon,
        className,
      )}
    >
      {icon && (
        <div className={classes.iconCell}>
          <Icon className={clsx(classes.icon, iconClassName)} />
        </div>
      )}
      { extendWith &&
        <div className={classes.titleCellExtWidth }>{title}</div>
      }
      { !extendWith &&
        <div className={classes.titleCell }>{title}</div>
      }
      <div className={clsx(classes.contentCell, contentClassName)}>
        {children}
      </div>
    </div>
  );
}
