import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClassIcon from '../icons/ClassIcon';
import SchoolIcon from '../icons/SchoolIcon';
import AcademicsIcon from '../icons/AcademicsIcon';
import PositionIcon from '../icons/PositionIcon';
import HomeIcon from '../icons/HomeIcon';
import RulerVerticalIcon from '../icons/RulerVerticalIcon';
import ScaleIcon from '../icons/ScaleIcon';
import RulerHorizontalIcon from '../icons/RulerHorizontalIcon';
import HandIcon from '../icons/HandIcon';
import OffersIcon from '../icons/OffersIcon';
import HandshakeIcon from '../icons/HandshakeIcon';
import StarIcon from '../icons/StarIcon';
import LinkIcon from '../icons/LinkIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Stars from '../atoms/Stars';
import TeamLogo from '../atoms/TeamLogo';
import NotAvailable from '../atoms/NotAvailable';
import Tooltip from '../atoms/Tooltip';
import Loader from '../atoms/Loader';
import Offers from '../molecules/Offers';
import Links from '../molecules/Links';
import { inchesToFeetAndInches, datetimeToDate } from '../services/converter';
import { isValidDOB, objectWithMaxCombine, getMostRecentDataByProperty, prepareLinks } from '../services/player';
import gql from '../services/gql';
import { COLOR_MIDDLE_GRAY, COLOR_TEXT, COLOR_BLUE, COLOR_DARK_BLUE } from '../styles/colors';
import Player from '../types/Player';
import Position from '../types/Position';
import HighSchoolCombine from '../types/HighSchoolCombine';
import HighSchool from '../types/HighSchool';
import Team from '../types/Team';
import TeamsIcon from '../icons/TeamsIcon';
import { LinkType } from '../types/Link';
interface PlayerProfileHighSchoolOverviewProps {
  className?: string;
  player?: Player;
  selectedPosition?: Position;
}

const BETTER_THAN_TOOLTIP_TEXT = 'Better than D-I position';

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileHighSchoolOverview: {
    position: 'relative',
  },

  divider: {
    width: '1px',
    height: '30px',
    margin: theme.spacing(0, 2.5),
    background: COLOR_MIDDLE_GRAY,
  },

  offersContent: {
    height: '32px',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },

  links: {
    padding: theme.spacing(1, 0),
    height: 'auto',
    alignItems: 'center',
    overflow: 'visible',
  },

  committedTo: {
    width: '32px',
    height: '32px',
  },

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: '350px',
    marginLeft: 'auto',
    cursor: 'help',
  },

  headerIcon: {
    width: '24px',
    height: '24px',
    color: COLOR_TEXT,
    marginRight: theme.spacing(1),
  },

  link: {
    color: COLOR_BLUE,
    textDecoration: 'underline',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  sportSourceLogo: {
    width: 26,
    height: 'auto',
    marginTop: 6,
  },
}), { name: PlayerProfileHighSchoolOverview.name });

export default function PlayerProfileHighSchoolOverview (props: PlayerProfileHighSchoolOverviewProps) {
  const {
    className,
    player,
    selectedPosition,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [committedToTeam, setCommittedToTeam] = useState<Team>();

  const primarySchool = ((player && player.highSchools) || [])
    .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);

  const heightPercentile:any = primarySchool && primarySchool.heightPerc && (primarySchool.heightPerc
    .find((heightPerc:any) => heightPerc && heightPerc.code === selectedPosition) || {} as any).percentile;
  const weightPercentile:any = primarySchool && primarySchool.weightPerc && (primarySchool.weightPerc
    .find((weightPerc:any) => weightPerc && weightPerc.code === selectedPosition) || {} as any).percentile;

  const highSchoolCombines:HighSchoolCombine[] = (player && player.hsCombines) || [];
  const wingspan = getMostRecentDataByProperty(highSchoolCombines, 'wingspan');
  const arm = getMostRecentDataByProperty(highSchoolCombines, 'arm');
  const hand = getMostRecentDataByProperty(highSchoolCombines, 'hand');

  const stars = (typeof player?._247Star === 'number' || typeof player?.compStar === 'number')
    ? player?._247Star || player?.compStar || 0
    : null;
  const dob = !!player?.dob && datetimeToDate(player.dob);
  const links = prepareLinks(player);
  const ssaLink = (links?.find(link => link.key === LinkType.ssa) || {})?.value;

  useEffect(() => {
    if (player?.committedToTeamId) {
      fetchCommittedToTeam(player.committedToTeamId);
    }
  }, [player?.committedToTeamId]);

  function fetchCommittedToTeam (teamId:number) {
    setLoading(true);

    gql(`
      team (id: ${teamId}) {
        name
        logo247
        logoESPN
        logoAlt
      }
    `)
      .then((data:any) => data.team as Team)
      .then((team:Team) => setCommittedToTeam(team))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  return (
    <NamedBox
      className={clsx(classes.playerProfileHighSchoolOverview, className)}
      name='Overview'
    >
      <Loader inProgress={loading} />

      <ListItem
        icon={ClassIcon}
        title='Class:'
      >
        <div><b>{(primarySchool && primarySchool.graduatingClass) || 0}</b></div>
        {dob && isValidDOB(dob) && (
          <>
            <div className={classes.divider} />
            <div>DOB: <b>{dob}</b></div>
          </>
        )}
      </ListItem>

      {selectedPosition && (
        <ListItem
          icon={PositionIcon}
          title='Position:'
        >
          <div><b>{selectedPosition}</b></div>
        </ListItem>
      )}

      {(primarySchool && primarySchool.name) && (
        <ListItem
          icon={SchoolIcon}
          title='School:'
        >
          <div><b>{primarySchool.name}</b></div>
        </ListItem>
      )}

      {(primarySchool && primarySchool.isPrepSchool) && (
        <ListItem
          icon={SchoolIcon}
          title='Prep:'
        >
          <div><b>{primarySchool.prepSchoolName}</b></div>
        </ListItem>
      )}

      {player && (player.gpa || player.sat || player.act) && (
        <ListItem
          icon={AcademicsIcon}
          title='Academics:'
        >
          {(player && player.gpa) && (
            <>
              <div>GPA: <b>{Number(player.gpa).toFixed(2)}</b></div>
              {(player.sat || player.act) && <div className={classes.divider} />}
            </>
          )}

          {(player && player.sat) && (
            <>
              <div>SAT: <b>{player.sat}</b></div>
              {player.act && <div className={classes.divider} />}
            </>
          )}

          {(player && player.act) && (
            <div>ACT: <b>{player.act}</b></div>
          )}
        </ListItem>
      )}

      {(primarySchool && (primarySchool.city || primarySchool.state)) && (
        <ListItem
          icon={HomeIcon}
          title='Hometown:'
        >
          <b>{primarySchool.city || ''}{primarySchool.city && primarySchool.state ? `, ${primarySchool.state}` : primarySchool.state}</b>
        </ListItem>
      )}

      {(!!primarySchool?.height) && (
        <ListItem
          icon={RulerVerticalIcon}
          title='Height:'
        >
          <b>{inchesToFeetAndInches(primarySchool.height)}</b>

          {(typeof heightPercentile === 'number') && (
            <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
              <div className={classes.progressLineWrapper}>
                <ProgressLine
                  className={classes.progressLine}
                  value={heightPercentile}
                />
              </div>
            </Tooltip>
          )}
        </ListItem>
      )}

      {(!!primarySchool?.weight) && (
        <ListItem
          icon={ScaleIcon}
          title='Weight:'
        >
          <b>{primarySchool.weight} lbs.</b>

          {(typeof weightPercentile === 'number') && (
            <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
              <div className={classes.progressLineWrapper}>
                <ProgressLine
                  className={classes.progressLine}
                  value={weightPercentile}
                />
              </div>
            </Tooltip>
          )}
        </ListItem>
      )}

      {!!wingspan && (
        <ListItem
          icon={RulerHorizontalIcon}
          title='Wingspan:'
        >
          <b>{wingspan}</b>
        </ListItem>
      )}

      {!!arm && (
        <ListItem
          icon={RulerHorizontalIcon}
          title='Arm:'
        >
          <b>{arm}</b>
        </ListItem>
      )}

      {!!hand && (
        <ListItem
          icon={HandIcon}
          title='Hand:'
        >
          <b>{hand}</b>
        </ListItem>
      )}

      {player && (
        <ListItem
          icon={StarIcon}
          title='Star Rating:'
        >
          {(typeof stars === 'number')
            ? <Stars value={stars} />
            : <NotAvailable />
          }
        </ListItem>
      )}

      {(typeof player?._247Comp === 'number') && (
        <ListItem
          icon={StarIcon}
          title='Composite Rating:'
        >
          <b>{player._247Comp.toFixed(4)}</b>
        </ListItem>
      )}

      {(player && !!player.offers && !!player.offers.length) && (
        <ListItem
          contentClassName={classes.offersContent}
          icon={OffersIcon}
          title='Offers:'
        >
          <Offers
            offers={player.offers}
            displayTotalOffersCount
            limit={6}
          />
        </ListItem>
      )}

        <ListItem
          icon={HandshakeIcon}
          title='Committed:'
        >
          {
            committedToTeam
            ? (<TeamLogo
                className={classes.committedTo}
                team={committedToTeam}
              />)
            : <><TeamsIcon className={classes.headerIcon} /><span className='ml-2'>Uncommitted</span></>
          }
        </ListItem>

        {!!ssaLink && (
        <ListItem
          icon={() => <img className={classes.sportSourceLogo} src='/images/websites/ssa.png' />}
          title='SportSource:'
        >
          <b>
            <a
              className={classes.link}
              href={ssaLink}
              target='_blank'
            >
              SSA Player Link
            </a>
          </b>
        </ListItem>
      )}

      {(!!links?.length) && (
        <ListItem
          contentClassName={classes.links}
          icon={LinkIcon}
          title='Links:'
        >
          <Links links={links} />
        </ListItem>
      )}
    </NamedBox>
  );
}
