import React from 'react';
import Icon from '../atoms/Icon';

function PowerLiftingIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(7, 4)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path d='M3,9.971 L2.038,3.72 C1.737,1.764 3.251,0 5.23,0 L12.771,0 C14.75,0 16.263,1.764 15.962,3.72 L15,9.971' />
          <path d='M3.349,24 L14.651,24 C16.692,22.35 18,19.829 18,17 C18,12.029 13.971,8 9,8 C4.029,8 0,12.029 0,17 C0,19.829 1.308,22.35 3.349,24 Z' />
          <path d='M9,12 C6.239,12 4,14.239 4,17' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(PowerLiftingIcon);
