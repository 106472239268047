import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '../atoms/Avatar';
import NamedBox from '../atoms/NamedBox';
import Accordion from '../atoms/Accordion';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import { FormatType, getEventFormatType, getEventName } from '../services/event-names-and-formats';
import {
  COLOR_ORANGE,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import Top5TrackFieldPerformers from '../types/Top5TrackFieldPerformers';
import TrackFieldEvent from '../types/TrackFieldEvent';
import EventPosition from '../types/EventPosition';
import MEDIA from '../styles/media';

interface TeamProfileTopPerformersProps {
  className?: string;
  events: Top5TrackFieldPerformers;
  isNFL?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamProfileTopPerformers: {},

  header: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
  },

  headerCell: {
    paddingRight: theme.spacing(.5),
  },

  rank: {
    boxSizing: 'content-box',
    width: '12%',
    paddingLeft: theme.spacing(2),
  },
  name: {
    width: '32%',
  },
  position: {
    width: '15%',
  },
  performance: {
    width: '26%',
  },
  year: {
    boxSizing: 'border-box',
    width: '15%',
    paddingRight: theme.spacing(2),
  },

  accordion: {
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    paddingLeft: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(14),
  },

  players: {
    margin: 0,
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },

  cell: {
    paddingRight: theme.spacing(.5),
    fontSize: theme.typography.pxToRem(14),

    '&$performance': {
      color: COLOR_ORANGE,
      fontWeight: 600,
    },
  },

  playerNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
  },
  playerName: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
  },
  playerLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(1.5),

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },

  [MEDIA.MOBILE]: {
    header: {
      minWidth: '500px',
    },
    accordion: {
      minWidth: '500px',
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    header: {
      fontSize: theme.typography.pxToRem(16),
    },
    rank: {
      width: '10%',
    },
    name: {
      width: '40%',
    },
    performance: {
      width: '20%',
    },

    cell: {
      fontSize: theme.typography.pxToRem(16),
    },

    playerAvatar: {
      width: '32px',
      height: '32px',
    },
    playerName: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: TeamProfileTopPerformers.name });

export default function TeamProfileTopPerformers (props: TeamProfileTopPerformersProps) {
  const {
    className,
    events,
    isNFL,
  } = props;
  const classes = useStyles();
  const eventKeys = Object.keys(events);

  function displayPerformance (performance:number, formatType:FormatType):string {
    if (formatType === FormatType.FEET_AND_INCHES) {
      return inchesToFeetAndInches(performance);
    } else if (formatType === FormatType.INCHES) {
      return `${performance}"`;
    } else if (formatType === FormatType.MINUTES_SECONDS_MS) {
      return secondsToMinutesSecondsMs(performance);
    }

    return Number(performance || 0).toFixed(2);
  }

  function renderResults (trackFieldEvents:TrackFieldEvent[], formatType:FormatType) {
    return (
      <ul className={classes.players}>
        {trackFieldEvents
          .map((trackFieldEvent:TrackFieldEvent, index) => (
            <li
              key={trackFieldEvent.id}
              className={classes.player}
            >
              <div className={clsx(classes.cell, classes.rank)}>#{index + 1}</div>

              <div className={clsx(classes.cell, classes.name)}>
                <div className={classes.playerNameContainer}>
                  <Avatar
                    className={classes.playerAvatar}
                    src={trackFieldEvent.photoUrl}
                    alt={`${trackFieldEvent.firstName} ${trackFieldEvent.lastName} profile picture`}
                  />

                  <Link
                    className={classes.playerLink}
                    to={`/player/${trackFieldEvent.slug}?`}
                    target='_blank'
                  >
                    {trackFieldEvent.firstName} {trackFieldEvent.lastName}
                  </Link>
                </div>
              </div>

              <div className={clsx(classes.cell, classes.position)}>
                {trackFieldEvent.position
                  .map((position:EventPosition) => position.code)
                  .join('/')
                }
              </div>

              <div className={clsx(classes.cell, classes.performance)}>
                {displayPerformance(trackFieldEvent.performance, formatType)}
              </div>

              <div className={clsx(classes.cell, classes.year)}>
                {trackFieldEvent.year}
              </div>
            </li>
          ))
        }
      </ul>
    );
  }

  return (
    <NamedBox
      className={clsx(classes.teamProfileTopPerformers, className)}
      name={`Top 5 ${isNFL ? 'High School' : 'All-Time'} Track & Field Performers`}
      white
    >
      <div className={classes.header}>
        <div className={clsx(classes.headerCell, classes.rank)}>Rank</div>
        <div className={clsx(classes.headerCell, classes.name)}>Name</div>
        <div className={clsx(classes.headerCell, classes.position)}>Pos.</div>
        <div className={clsx(classes.headerCell, classes.performance)}>Performance</div>
        <div className={clsx(classes.headerCell, classes.year)}>Year</div>
      </div>

      {(eventKeys && !!eventKeys.length) && (
        <>
          {eventKeys
            .filter((eventKey:string) => {
              return (events as any)[eventKey] && !!(events as any)[eventKey].length;
            })
            .map((eventKey:string, index) => {
              const results:TrackFieldEvent[] = (events as any)[eventKey];

              return (
                <Accordion
                  key={index}
                  className={classes.accordion}
                  summaryClassName={classes.accordionSummary}
                  summary={getEventName(eventKey)}
                  expanded={index === 0}
                >
                  {renderResults(results, getEventFormatType(eventKey))}
                </Accordion>
              );
            })
          }
        </>
      )}
    </NamedBox>
  );
}
