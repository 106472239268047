import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Popover from '@material-ui/core/Popover';
import SearchIcon from '../icons/SearchIcon';
import CloseIcon from '../icons/CloseIcon';
import Input from '../atoms/Input';
import Tooltip from '../atoms/Tooltip';
import Loader from '../atoms/Loader';
import ColorCode from '../atoms/ColorCode';
import { sortPlayersByLastName } from '../services/player';
import {
  COLOR_BACKGROUND_WARM,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_RED,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Player from '../types/Player';
import { Color } from '../types/Color';

interface SelectPlayerProps {
  className?: string;
  players: Player[];
  selectedPlayer: Player | null;
  customName?: string;
  onUpdateCustomName: (customName:string) => void;
  onSelect?: (player:Player) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectPlayer: {
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    color: COLOR_TEXT,
    background: COLOR_WHITE,
    outline: 'none',
    position: 'relative',
    cursor: 'text',

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: 'currentColor',
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: 'currentColor',
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '&:focus': {
      color: COLOR_BLUE,
      borderColor: COLOR_BLUE,
      boxShadow: 'none',
    },
  },
  open: {
    color: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    boxShadow: 'none',
  },
  withCustomNameInput: {
    padding: '0 !important',
    margin: theme.spacing(0.5, 0),
    border: 0,
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    }
  },

  name: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
  },

  popover: {},
  paper: {
    borderRadius: 0,
  },
  popoverContent: {
    width: '300px',
    height: '240px',
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    overflowY: 'scroll',
  },

  searchWrapper: {
    padding: theme.spacing(0.75),
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  searchIconContainer: {
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  searchIcon: {
    color: COLOR_TEXT,
    width: '18px',
    height: '18px',
  },
  searchInputRoot: {
    width: '100%',
    fontFamily: FONT_PROXIMA_NOVA,
  },
  searchInputField: {
    width: '100%',
    height: '36px',
    padding: theme.spacing(1, 1, 1, 4.75),
    transition: theme.transitions.create('width'),
    background: COLOR_BACKGROUND_WARM,
    color: COLOR_TEXT,
    boxSizing: 'border-box',
    borderRadius: '4px',
  },

  customName: {
    padding: theme.spacing(0, 2),
    flexShrink: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  customNameInputWrapper: {
    position: 'relative',
  },
  customNameInput: {
    width: '100%',
  },
  customNameInputField: {
    height: '100%',
    padding: theme.spacing(1, 4, 0.75, 1),
    boxSizing: 'border-box',
  },

  closeButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    border: 0,
    appearance: 'none',
    background: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '5px',
    right: '4px',
    outline: 'none',

    '&:focus': {
      border: `1px solid ${COLOR_DARK_GRAY}`,
      borderRadius: '50%',
    },

    '&:hover': {
      '& $closeIcon': {
        color: COLOR_RED,
      }
    },
  },
  closeIcon: {
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',
  },

  players: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    position: 'relative',
    flexGrow: 1,
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
    borderTop: `1px solid ${COLOR_BORDER}`,
    background: COLOR_WHITE,
    cursor: 'pointer',
    transition: 'background 0.3s',

    '&:hover': {
      background: COLOR_BACKGROUND_WARM,
    }
  },

  duplicatePlayer: {
    marginRight: theme.spacing(1.5),
  },
}), { name: SelectPlayer.name });

export default function SelectPlayer (props: SelectPlayerProps) {
  const {
    className,
    players,
    selectedPlayer,
    onSelect = () => {},
    customName,
    onUpdateCustomName = () => {},
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayCustomNameInput, setDisplayCustomNameInput] = useState<boolean>(!!customName);
  const open = !!anchorEl && !displayCustomNameInput;

  function onOpen (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onClose () {
    setAnchorEl(null);
  }

  function onSelectPlayer (player:Player) {
    return () => {
      onSelect(player);
      onClose();
    };
  }

  function onRemoveCustomName () {
    onUpdateCustomName('');
    setDisplayCustomNameInput(false);
    onClose();
  }

  const filteredPlayers = players
    .filter((player:Player) => {
      return `${player.firstName} ${player.lastName}`.toLowerCase().includes(searchValue.toLowerCase())
        || `${player.lastName} ${player.firstName}`.toLowerCase().includes(searchValue.toLowerCase());
    })
    .sort(sortPlayersByLastName);

  return (
    <div
      className={clsx(
        classes.selectPlayer,
        open && classes.open,
        displayCustomNameInput && classes.withCustomNameInput,
        className,
      )}
      onClick={(!open && !displayCustomNameInput) ? onOpen : undefined}
      tabIndex={0}
    >
      {displayCustomNameInput && (
        <div className={classes.customNameInputWrapper}>
          <Input
            className={classes.customNameInput}
            inputFieldClassName={classes.customNameInputField}
            fontSize={16}
            value={customName || ''}
            onChange={onUpdateCustomName}
          />

          <Tooltip title='Remove Custom Name'>
            <button
              className={classes.closeButton}
              onClick={onRemoveCustomName}
            >
              <CloseIcon className={classes.closeIcon} />
            </button>
          </Tooltip>
        </div>
      )}

      {!displayCustomNameInput && (
        <span className={classes.name}>
        {(selectedPlayer && selectedPlayer.firstName)
          ? `${selectedPlayer.firstName} ${selectedPlayer.lastName}`
          : 'Select a player'
        }
      </span>
      )}

      <Popover
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContent}>
          <div className={classes.searchWrapper}>
            <div className={classes.search}>
              <div className={classes.searchIconContainer}>
                <SearchIcon className={classes.searchIcon} />
              </div>

              <InputBase
                classes={{
                  root: classes.searchInputRoot,
                  input: classes.searchInputField,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue}
                placeholder='Search Players'
                onChange={(event:any) => setSearchValue(event.target.value)}
              />
            </div>

            {!!selectedPlayer && (
              <div
                className={classes.customName}
                onClick={() => setDisplayCustomNameInput(true)}
              >
                Custom Name
              </div>
            )}
          </div>

          <ul className={classes.players}>
            <Loader inProgress={!players || !players.length} />

            {filteredPlayers.map((player:Player, index) => (
              <li
                key={index}
                className={classes.player}
                onClick={onSelectPlayer(player)}
              >
                {player.duplicated && (
                  <ColorCode
                    className={classes.duplicatePlayer}
                    color={Color.YELLOW}
                    tooltipText='Already Added'
                  />
                )}

                {player.firstName} {player.lastName}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
}
