import React from 'react';
import Icon from '../atoms/Icon';

function SettingsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
          <path d='M16,12 C18.2,12 20,13.8 20,16 C20,18.2 18.2,20 16,20 C13.8,20 12,18.2 12,16 C12,13.8 13.8,12 16,12 Z M27.8,18 C27.4,19.8 26.8,21.4 25.8,23 L28.8,26 L26,28.8 L23,25.8 C21.6,26.8 19.8,27.6 18,27.8 L18,32 L14,32 L14,27.8 C12.2,27.4 10.6,26.8 9,25.8 L6,28.8 L3.2,26 L6.2,23 C5.2,21.6 4.4,19.8 4.2,18 L0,18 L0,14 L4.2,14 C4.6,12.2 5.2,10.6 6.2,9 L3.2,6.2 L6,3.4 L9,6.4 C10.4,5.4 12.2,4.6 14,4.4 L14,0 L18,0 L18,4.2 C19.8,4.6 21.4,5.2 23,6.2 L26,3.2 L28.8,6 L25.8,9 C26.8,10.4 27.6,12.2 27.8,14 L32,14 L32,18 L27.8,18 Z M24,16 C24,11.6 20.4,8 16,8 C11.6,8 8,11.6 8,16 C8,20.4 11.6,24 16,24 C20.4,24 24,20.4 24,16 Z' />
      </g>
    </Icon>
  );
}

export default React.forwardRef(SettingsIcon);
