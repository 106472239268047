import React from 'react';
import Icon from '../atoms/Icon';

function PlusIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M19.2,0 L19.2,12.8 L32,12.8 L32,19.2 L19.2,19.2 L19.2,32 L12.8,32 L12.8,19.2 L0,19.2 L0,12.8 L12.8,12.8 L12.8,0 L19.2,0 Z'
          fill='currentColor'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(PlusIcon);
