import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  SIDE_DRAWER_WIDTH,
  SIDE_DRAWER_WIDTH_SMALL,
  SIDE_DRAWER_CLOSED_WIDTH,
} from './SideDrawer';
import Action from '../atoms/Action';
import ImportsIcon from '../icons/MyImportsIcon';
import ExportsIcon from '../icons/ExportsIcon';
import AlertIcon from '../icons/AlertIcon';
import SaveIcon from '../icons/SaveIcon';
import HelpIcon from '../icons/HelpIcon';
import MenuButton from '../atoms/MenuButton';
import QuickSearchAutoComplete from '../molecules/QuickSearchAutoComplete';
import { State } from '../redux/reducers';
import { setSideDrawerOpenAction } from '../redux/actions/ui';
import { isMobile, isDesktop, isTablet, ScreenSize } from '../services/screen-size';
import {
  COLOR_BACKGROUND_COLD,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_BLUE,
  COLOR_SHADOW,
} from '../styles/colors';
import MEDIA from '../styles/media';
import QuickSearchPlayer from '../types/QuickSearchPlayer';
import User from '../types/User';

interface NavBarProps {
  sideDrawerOpen: boolean;
  screenSize: ScreenSize;
  nflAccess?: boolean;
  adminAccess?: boolean;
  user?: User;
  setSideDrawerOpenAction: (open: boolean) => void;
}

export const NAVBAR_HEIGHT = 90;
export const NAVBAR_MOBILE_HEIGHT = 168;

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    '@media print': {
      display: 'none'
    }
  },

  drawerControlContainer: {
    width: '71px',
    height: '64px',
    background: COLOR_WHITE,
  },

  appBar: {
    left: 0,
    right: 'auto',
    width: `calc(100% - ${SIDE_DRAWER_CLOSED_WIDTH}px)`,
    marginLeft: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
    background: COLOR_BACKGROUND_COLD,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },

  toolbar: {
    minHeight: `${NAVBAR_HEIGHT}px`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  search: {
    width: '400px',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  quickSearchAutoComplete: {
    width: '350px',
    background: COLOR_SHADOW,
  },

  actions: {
    display: 'flex',
    marginLeft: 'auto'
  },
  action: {
    overflow: 'visible',
    position: 'relative',
    marginLeft: theme.spacing(4),
    transition: 'color 0.3s',

    '&:first-of-type': {
      marginLeft: '0',
    },

    '&:before': {
      content: '""',
      display: 'none',
      width: '100%',
      height: '5px',
      position: 'absolute',
      bottom: '-34px',
      left: 0,
      background: COLOR_ORANGE,
    },

    '&:hover': {
      textDecoration: 'none',
    },
  },
  actionSelected: {
    '&:before': {
      display: 'block',
    },

    '& $actionIcon': {
      color: COLOR_ORANGE,
    },
  },
  actionIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
    marginRight: 0,
    color: COLOR_TEXT,
    transition: 'color 0.3s',

    'a:hover &': {
      color: COLOR_BLUE,
    },
  },
  actionText: {
    display: 'none',
  },

  importIcon: {
    transform: 'scale(1.4)',
  },
  alertIcon: {
    transform: 'scale(1.2)',
  },

  savedSearches: {
    height: '24px',
    lineHeight: '24px',
    textDecoration: 'underline',
  },
  savedSearchesSelected: {
    color: COLOR_ORANGE,

    '&:before': {
      left: 0,
    },
  },

  [MEDIA.MOBILE]: {
    appBar: {
      width: '100%',
      marginLeft: 0,
      left: 0,
    },
    toolbar: {
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    searchWrapper: {
      width: '100%',
      marginTop: theme.spacing(1),
      flexDirection: 'column',

      '& $action': {
        marginLeft: 'auto',
        marginTop: theme.spacing(1),
      }
    },
    search: {
      width: '100%',
      marginRight: 0,
    },

    savedSearchesSelected: {
      '&:before': {
        display: 'none',
      },
    },
  },

  [MEDIA.TABLET]: {
    searchWrap: {
      width: '60%',
    },
  },

  [MEDIA.DESKTOP]: {
    searchWrap: {
      width: '40%',
    },
    search: {
      flexGrow: '1',
      maxWidth: '400px',
    },

    actionIcon: {
      marginRight: theme.spacing(2),
    },
    actionText: {
      display: 'block',
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    appBarShift: {
      width: `calc(100% - ${SIDE_DRAWER_WIDTH_SMALL}px)`,
      marginLeft: `${SIDE_DRAWER_WIDTH_SMALL}px`,
    },
  },

  '@media (min-width: 1801px)': {
    appBarShift: {
      width: `calc(100% - ${SIDE_DRAWER_WIDTH}px)`,
      marginLeft: `${SIDE_DRAWER_WIDTH}px`,
    },

    toolbar: {
      fontSize: theme.typography.pxToRem(16),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },

    searchWrap: {
      width: '50%',
    },

    action: {
      marginLeft: theme.spacing(7),
    },
  },
}), { name: NavBar.name });

function NavBar (props:NavBarProps) {
  const {
    sideDrawerOpen = true,
    screenSize = ScreenSize.MOBILE,
    nflAccess,
    adminAccess,
    user,
    setSideDrawerOpenAction,
  } = props;
  const classes = useStyles();

  if (!user || !Object.keys(user).length) {
    return null;
  }

  const displayMenuButton = !isDesktop(screenSize) && !isTablet(screenSize) && !sideDrawerOpen;
  const onMenuButtonClick = sideDrawerOpen
    ? () => setSideDrawerOpenAction(false)
    : () => setSideDrawerOpenAction(true);

  const quickSearch = (
    <div className={classes.searchWrapper}>
      <QuickSearchAutoComplete
        className={clsx(classes.quickSearchAutoComplete, classes.search)}
        label='Search Players'
        onSelect={(searchedPlayer:QuickSearchPlayer | null) => {
          if (searchedPlayer && searchedPlayer.id) {
            window.open(`/player/${searchedPlayer.slug}?`, '_blank');
          }
        }}
      />

      <Action
        className={clsx(classes.action, classes.savedSearches)}
        selectedClassName={clsx(classes.actionSelected, classes.savedSearchesSelected)}
        href='/saved-searches?'
      >
        Saved Searches
      </Action>
    </div>
  )
  return (
    <nav className={clsx(classes.navBar, sideDrawerOpen && classes.sideDrawerOpen)}>
      <AppBar
        position='absolute'
        className={clsx(classes.appBar, sideDrawerOpen && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            {displayMenuButton && (
              <MenuButton onClick={onMenuButtonClick} />
            )}
            {!isMobile(screenSize) && (quickSearch)}
            <div className={classes.actions}>
              <div className={classes.grow} />

              {adminAccess && (
                <Action
                  className={classes.action}
                  selectedClassName={classes.actionSelected}
                  iconClassName={clsx(classes.actionIcon, classes.importIcon)}
                  icon={ImportsIcon}
                  href='/import?'
                >
                  <span className={classes.actionText}>Import</span>
                </Action>
              )}

              {(!nflAccess && user?.accessLevel?.exportAllowanceType !== 'none') && (
                <Action
                  className={classes.action}
                  selectedClassName={classes.actionSelected}
                  iconClassName={classes.actionIcon}
                  icon={ExportsIcon}
                  href='/exports?'
                >
                  <span className={classes.actionText}>Exports</span>
                </Action>
              )}

              {!nflAccess && (
                <Action
                  className={classes.action}
                  selectedClassName={classes.actionSelected}
                  iconClassName={clsx(classes.actionIcon, classes.alertIcon)}
                  icon={AlertIcon}
                  href='/alerts?'
                >
                  <span className={classes.actionText}>Alert List</span>
                </Action>
              )}

              <Action
                className={classes.action}
                selectedClassName={classes.actionSelected}
                iconClassName={classes.actionIcon}
                icon={SaveIcon}
                href='/saved-players?'
              >
                <span className={classes.actionText}>Saved Players</span>
              </Action>

              <Action
                className={classes.action}
                selectedClassName={classes.actionSelected}
                iconClassName={classes.actionIcon}
                icon={HelpIcon}
                href='/help?'
              >
                <span className={classes.actionText}>Help</span>
              </Action>
            </div>
          </div>

          {isMobile(screenSize) && (quickSearch)}
        </Toolbar>
      </AppBar>
    </nav>
  );
}

const mapStateToProps = (state:State) => {
  return {
    sideDrawerOpen: state.ui.sideDrawerOpen,
    screenSize: state.ui.screenSize,
    nflAccess: state.ui.nflAccess,
    adminAccess: state.ui.adminAccess,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    { setSideDrawerOpenAction },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);
