import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BackIcon from '../icons/BackIcon';
import Action from '../atoms/Action';
import { trackPageView } from '../services/google-analytics';
import { COLOR_BLUE } from '../styles/colors';
import MEDIA from '../styles/media';

interface ModalPageTemplateProps {
  children:any;
}

const useStyles = makeStyles(theme => ({
  modalPageTemplate: {
    position: 'relative',
    width: '100%',
    margin: theme.spacing(10, 'auto'),
    padding: theme.spacing(0, 2),
  },
  paper: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '780px',
    margin: theme.spacing(0, 'auto'),
  },
  header: {
    padding: theme.spacing(4, 4, 2),
  },
  action: {
    color: COLOR_BLUE,
  },
  backIcon: {
    height: '32px',
    width: '32px',
  },
  logoContainer: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '-32px',
  },
  logo: {
    width: '150px',
    position: 'absolute',
    left: '50%',
    top: '-48px',
    transform: 'translateX(-50%)',
  },

  [MEDIA.MOBILE]: {
    modalPageTemplate: {
      marginTop: '130px',
    },

    logo: {
      top: '-110px',
    },
  },
}), { name: ModalPageTemplate.name });

export default function ModalPageTemplate (props:ModalPageTemplateProps) {
  const classes = useStyles();
  const { children } = props;

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <main className={classes.modalPageTemplate}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <img
            className={classes.logo}
            src='/images/tracking-football-logo.svg'
          />
          <Action
            className={classes.action}
            icon={BackIcon}
            iconClassName={classes.backIcon}
            href='/?'
          >
            Back To Tracking Football
          </Action>
        </div>
        {children}
      </Paper>
    </main>
  )
}
