export enum AccessLevelType {
  COLLEGE = 'college',
  NFL = 'nfl',
  ADMIN = 'admin',
  LIMITED = 'limited',
}

export enum StaffRecords {
  TEAM = 'team',
  ACCOUNT_ONLY = 'account_only',
  ALL = 'all',
}

export enum TeamSelectCollegeType {
  ALL = 'all',
  DEFAULT_TEAM_ONLY = 'default_team_only',
}

export enum SideBySideExportType {
  MONTHLY = 'monthly',
  UNLIMITED = 'unlimited',
  NONE = 'none',
}

export enum ExportAllowanceType {
  LIMITED = 'limited',
  NONE = 'none',
}

export enum ExportSearchResultsAllowanceType {
  LIMITED_HS = 'limited_hs',
  LIMITED_ALL = 'limited_all',
  UNLIMITED = 'unlimited',
  NONE = 'none',
}

export enum PinPlayersAllowanceType {
  LIMITED = 'limited',
  UNLIMITED = 'unlimited',
}

export enum TransferPortalType {
  YES = 'yes',
  YES_IF_ACTIVATED = 'if_activated',
  NO = 'no',
}

export enum DraftProjectorType {
  YES = 'yes',
  YES_IF_ACTIVATED = 'if_activated',
  NO = 'no',
}

export enum HSYearsAccessType {
  FRESHMAN = 'freshman',
  SOPHOMORE = 'sophomore',
  JUNIOR = 'junior',
  SENIOR = 'senior',
}
export default interface AccessLevel {
  id: number;
  name: string;
  type: AccessLevelType;
  accounts: number;
  staffRecords: StaffRecords;
  hasCollegeAccess: boolean;
  hasNFLAccess: boolean;
  hasArchiveAccess: boolean;
  hasEvalDotAndNotes: boolean;
  hasAPI: boolean;
  exportAllowanceType: ExportAllowanceType;
  exportAllowance: number;
  canComparePlayers: boolean;
  comparePlayerLimit: number;
  depthChartEnabled: boolean;
  canManageUsers: boolean;
  canSendMessages: boolean;
  canAccessHelp: boolean;
  canImport: boolean;
  sideBySideExportType: SideBySideExportType;
  sideBysideExportCount: number;
  teamSelectCollegeType: TeamSelectCollegeType;
  canTeamSelectNFL: boolean;
  canTeamSelectCollege: boolean;
  accessCollegeTeamPage: boolean;
  accessNFLTeamPage: boolean;
  recruitingEvaluationBoard: boolean;
  dashboardTeamLogos: boolean;
  exportSearchResultsAllowanceType: ExportSearchResultsAllowanceType;
  exportSearchResultsLimit: number;
  pinPlayersAllowanceType: PinPlayersAllowanceType;
  pinPlayersLimit: number;
  teamPageExport: boolean;
  transferPortal: TransferPortalType;
  draftProjector: DraftProjectorType;
  hsYearsAugust1: HSYearsAccessType[];
  hsYearsMarch1: HSYearsAccessType[];
  canAccessDashboard: boolean;
  hasJucoAccess?: boolean;
}
