import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export enum PDFExportPage {
  PLAYER = 'players',
  COMPARE_PLAYER = 'compare-players',
  DEPTH_CHART = 'depth-charts',
  RECRUITING_BOARD = 'recruiting-board',
}

export default function exportPDFReport (
  exportPage:PDFExportPage,
  slug?:string,
  exportParameters?:any,
):Promise<any> {
  const query = typeof exportParameters === 'object'
    ? '?' + Object.entries(exportParameters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    : '';

  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${exportPage}${slug ? `/${slug}` : ''}/pdf${query}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return {
          fileName: (res.headers.get('Content-Disposition') || '').split('filename=')[1] || getPDFFileName(exportPage, slug, exportParameters),
          fileBlob: await res.blob(),
        };
      } else {
        return res.text()
          .then((errorMessage:string) => {
            throw new Error(errorMessage);
          });
      }
    })
    .then(({ fileName, fileBlob }) => downloadAndSavePDFReport(fileName, fileBlob));
}

export function downloadAndSavePDFReport (fileName:string, fileContent?:Blob, fileUrl?: string) {
  const url = !fileUrl ? window.URL.createObjectURL(fileContent) : fileUrl;
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
}

function getPDFFileName (exportPage:PDFExportPage, slug?:string, exportParameters?:any) {
  if (exportPage === PDFExportPage.DEPTH_CHART && slug) {
    return `${slug[0].toUpperCase()}${slug.slice(1)} - Depth Chart${exportParameters?.['depth-chart-type'] ? ` (${exportParameters['depth-chart-type']})`: ''}.pdf`;
  }

  return `${slug || exportPage}.pdf`;
}
