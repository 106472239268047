import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PinIcon from '../icons/PinIcon';
import MyRecruitingBoard from '../organisms/MyRecruitingBoard';
import PdfPageTemplate from './PdfPageTemplate';
import { getQueryParameter } from '../services/query-parameters';
import { State } from '../redux/reducers';
import { COLOR_LIGHT_GRAY } from '../styles/colors';
import User from '../types/User';
import Position from '../types/Position';

interface MyRecruitingBoardPageTemplateProps {
  user?: User;
}

const useStyles = makeStyles<Theme, MyRecruitingBoardPageTemplateProps>(theme => ({
  headerIcon: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(1),
  },

  tableWrapper: {
    padding: theme.spacing(5),
  },

  titleRow: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 'none',
    padding: theme.spacing(1.5),
  },
  titleText: {
    ...theme.typography.h2,
    margin: theme.spacing(0,0,0,1),
  },
}), { name: MyRecruitingBoardPageTemplate.name });

function MyRecruitingBoardPageTemplate (props: MyRecruitingBoardPageTemplateProps) {
  const { user } = props;
  const classes = useStyles(props);

  const position = getQueryParameter('position') as Position;

  return (
    <PdfPageTemplate
      headerTitle={`My Recruiting Board${position ? ` - ${position.toUpperCase()}` : ''}`}
      headerIconClassName={classes.headerIcon}
      icon={PinIcon}
    >
      <div className={classes.tableWrapper}>
        <div className={classes.titleRow}>
          <div className={classes.titleText}>My Recruiting Board</div>
        </div>

        {(user && !!Object.keys(user).length) && (
          <MyRecruitingBoard
            user={user}
            position={position}
            printed
          />
        )}
      </div>
    </PdfPageTemplate>
  );
}


const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(MyRecruitingBoardPageTemplate);
