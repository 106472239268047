import React, { useEffect, useState } from 'react';
import Dialog from './Dialog';
import MultiSelect from '../molecules/MultiSelect';
import HelpPopupFooter from '../atoms/HelpPopupFooter';
import Loader from '../atoms/Loader';
import gql from '../services/gql';

interface ScheduleTrainingModalProps {
  className?: string;
  headingClassName?: string;
  inputClassName?: string;
  inputHalfClassName?: string;
  dropDownClassName?: string;
  dropDownSelectClassName?: string;
  fullNameClassName?: string;
  multiSelectClassName?: string;
  contentClassName?: string;
  isPopupVisible: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

const MONTH = {
  'JANUARY': 'January',
  'FEBRUARY': 'February',
  'MARCH': 'March',
  'APRIL': 'April',
  'MAY': 'May',
  'JUNE': 'June',
  'JULY': 'July',
  'AUGUST': 'August',
  'SEPTEMBER': 'September',
  'OCTOBER': 'October',
  'NOVEMBER': 'November',
  'DECEMBER':'December',
};

const DAY = {
  'MONDAY': 'Monday',
  'TUESDAY':'Tuesday',
  'WEDNESDAY': 'Wednesday',
  'THURSDAY': 'Thursday',
  'FRIDAY': 'Friday',
};

const MONTHS = [
  { content: MONTH.JANUARY, value: '1' },
  { content: MONTH.FEBRUARY, value: '2' },
  { content: MONTH.MARCH, value: '3' },
  { content: MONTH.APRIL, value: '4' },
  { content: MONTH.MAY, value: '5' },
  { content: MONTH.JUNE, value: '6' },
  { content: MONTH.JULY, value: '7' },
  { content: MONTH.AUGUST, value: '8' },
  { content: MONTH.SEPTEMBER, value: '9' },
  { content: MONTH.OCTOBER, value: '10' },
  { content: MONTH.NOVEMBER, value: '11' },
  { content: MONTH.DECEMBER, value: '12' },
];

const DAYS = [
  { content: DAY.MONDAY, value: 'monday' },
  { content: DAY.TUESDAY, value: 'tuesday' },
  { content: DAY.WEDNESDAY, value: 'wednesday' },
  { content: DAY.THURSDAY, value: 'thursday' },
  { content: DAY.FRIDAY, value: 'friday' },
];

const BEST_TIME = [
  { content: 'Morning', value: 'morning' },
  { content: 'Mid-Day', value: 'mid-day' },
  { content: 'Afternoon', value: 'afternoon' },
  { content: 'Evening', value: 'evening' },
];

export default function ScheduleTrainingModal (props:ScheduleTrainingModalProps) {
  const {
    className,
    headingClassName,
    dropDownClassName,
    multiSelectClassName,
    contentClassName,
    isPopupVisible = false,
    onClose,
    onError,
    onSuccess,
  } = props;

  const [selectedMonths, setSelectedMonths] = useState<string[]>([`${new Date().getMonth() + 1}`]);
  const [prevSelectedMonths, setPrevSelectedMonths] = useState<string[]>([`${new Date().getMonth() + 1}`]);
  const [months, setMonths] = useState<number[]>([]);
  const [days, setDays] = useState<string[]>([]);
  const [bestTime, setBestTime] = useState<string[]>(['morning']);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedMonths.length !== prevSelectedMonths.length) {
      let selectedMonthsNumeric: number[] = [];
      selectedMonths.forEach(month => {
        selectedMonthsNumeric.push(Number(month));
      });
      setPrevSelectedMonths(selectedMonths);
      setMonths(selectedMonthsNumeric);
    }
  }, [selectedMonths, prevSelectedMonths])

  function handleSubmit() {
    submitHelpText()
      .then((success:boolean | void) => {
        if (success) {
          onClose();
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(onError);
  }

  function submitHelpText () {
    setIsLoading(true);

    return gql(`
      mutation {
        submitScheduleTrainingHelp (scheduleTrainingHelp: {
          months: ${JSON.stringify(months)},
          days: ${JSON.stringify(days)},
          time: ${JSON.stringify(bestTime)}
        })
      }`
    )
      .then((data: any) => data && data.submitScheduleTrainingHelp as boolean)
      .finally(() => setIsLoading(false));
  }

  return(
    <Dialog
      className={className}
      open={isPopupVisible}
      title='Schedule Training'
      contentClassName={contentClassName}
      actions={[
        {
          name: 'Cancel',
          onClick: onClose
        },
        {
          name: 'Request Training',
          primary: true,
          disabled: isLoading,
          onClick: handleSubmit
        }
      ]}
      onClose={onClose}
    >
      <Loader inProgress={isLoading} />
      <div className={headingClassName}>
        <strong>Want to schedule additional training?</strong>
        Please provide the following:
      </div>
      <MultiSelect
        className={dropDownClassName}
        selectorRootClassName={multiSelectClassName}
        labelWhenSelectedNone='Months'
        items={MONTHS}
        values={selectedMonths}
        onChange={(months: string[]) => setSelectedMonths(months)}
      />
      <MultiSelect
        className={dropDownClassName}
        selectorRootClassName={multiSelectClassName}
        labelWhenSelectedNone='Days'
        items={DAYS}
        values={days}
        onChange={(days: string[]) => setDays(days)}
      />
      <MultiSelect
        className={dropDownClassName}
        selectorRootClassName={multiSelectClassName}
        labelWhenSelectedNone='Best Time'
        items={BEST_TIME}
        values={bestTime}
        onChange={(times: string[]) => setBestTime(times)}
      />
      <HelpPopupFooter />
    </Dialog>
  );
}
