export enum WatchListName {
  SeniorBowl = 'Senior Bowl',
  CGSShowcase = 'CGS Showcase',
  NFLPA = 'NFLPA',
  EastWestShrine = 'East West Shrine',
}

export default interface WatchListItem {
  name: string;
  year: number;
}
