import get from 'lodash/get';
import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';
import { getQueryParameter } from './query-parameters';

const GQL_URL = `${process.env.REACT_APP_API_HOST}/graphql`;

export const GQL_ERROR = {
  UNAUTHORIZED: 'Unauthorized',
  TIMEOUT: 'Timeout',
};

const TIMEOUT_RESPONSE_HEADER = 'x-azure-externalerror';

/**
 * Serializes data payload passed as an argument to a GraphQL mutation
 * @param object {Object}
 */
export function serializeGQLObject (object:object) {
  return JSON.stringify(object)
    .replace(/"(\w+)":/ig, '$1:');
}

export default function gql (query:string, data?:Object | Array<any>):Promise<any> {
  if (data) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 300);
    });
  }

  const isMutation = query.trim().startsWith('mutation');
  const token = getQueryParameter('token');

  return fetch(GQL_URL, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token || clientStorage.get(StorageKey.TOKEN)}`,
    },
    body: JSON.stringify({ query: isMutation ? ` ${query} ` : `{ ${query} }` }),
  })
    .then(res => {
      const headers:string[] = [];
      res.headers.forEach(header => headers.push(header));

      const isTimeoutError = !!res.headers
        .get(TIMEOUT_RESPONSE_HEADER)
        ?.toLowerCase()
        ?.includes('timeout');

      if (res.ok) {
        return res.json();
      } else if (isTimeoutError) {
        return {
          data: {},
          errors: [{ message: GQL_ERROR.TIMEOUT }],
        };
      }

      throw new Error('Failed to get GQL response');
    })
    .then((data:any) => {
      const result = data.data;
      const errorMessage = get(data, 'errors[0].message', '');

      if (errorMessage === GQL_ERROR.UNAUTHORIZED) {
        window.location.href = '/login';
      }

      return {
        ...result,
        errorMessage,
      };
    })
    .catch(console.error);
}
