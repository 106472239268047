import { SET_USER, UPDATE_PREFERENCES } from '../actions/user';
import User from '../../types/User';

export interface UserState extends User {}

const INITIAL_STATE:UserState = {} as UserState;

export function getUserInitialState () {
  return INITIAL_STATE;
}

export default function userReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: action.preferences
      };
    default:
      return state;
  }
};
