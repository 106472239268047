import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddUserIcon from '../icons/AddUserIcon';
import EditUserIcon from '../icons/EditUserIcon';
import Switch from '../atoms/Switch';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import AddEditUserForm from '../organisms/AddEditUserForm';
import PageContainer from './PageContainer';
import gql from '../services/gql';
import User from '../types/User';
import { State } from '../redux/reducers';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';

interface AddUserPageTemplateProps {
  loggedInUser?: User;
  collegeTeamsLoading: boolean;
  nflTeamsLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  userPageTemplate: {
    minHeight: '100vh',
    position: 'relative',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(5, 5, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  switchWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(12),
    marginRight: 'auto',
  },
  switchValue: {
    color: COLOR_TEXT,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
  },

  addEditUserForm: {
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(5),
  },

  [MEDIA.MOBILE]: {
    header: {
      display: 'block',
      margin: theme.spacing(2, 2, 0),
    },

    switchWrapper: {
      margin: theme.spacing(2, 0, 0),
    },

    addEditUserForm: {
      padding: theme.spacing(2),
    }
  },
}), { name: AddUserPageTemplate.name });

function AddUserPageTemplate (props:AddUserPageTemplateProps) {
  const classes = useStyles();
  const {
    loggedInUser,
    collegeTeamsLoading,
    nflTeamsLoading,
  } = props;

  const history = useHistory();
  const { userId } = useParams<any>();

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [userStatusEnabled, setUserStatusEnabled] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (loggedInUser && loggedInUser.accessLevel) {
      if (!loggedInUser.accessLevel.canManageUsers) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (history.location.pathname.startsWith('/manage/user/edit')) {
      setIsEditMode(true);
      fetchUserById();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (isEditMode && user) {
      setUserStatusEnabled(!user.isDisabled);
    }
  }, [isEditMode, user]);

  if (!loggedInUser || !Object.keys(loggedInUser).length) {
    return null;
  }

  function fetchUserById () {
    setPageLoading(true);

    gql(`
      user (id: ${userId}) {
        id
        email
        firstName
        lastName
        designation
        messageCount
        rank
        lastLogin
        photoUrl
        last30DaysLoginCount
        totalLogins
        preferences
        divisionLevel
        programId
        accessLevelId
        collegeId
        startDate
        expirationDate
        nflTeamId
        companyId
        isEmailDisabled
        isSMSOptedOut
        isMaster
        isDisabled
        canClearStagingPlayers
        accessLevel {
          name
          accounts
        }
        program {
          id
          name
          accessLevelId
          startDate
          expirationDate
          createdAt
        }
      }
    `)
      .then((data:any) => data.user as User)
      .then((user:User) => {
        if (user) {
          setUser(user);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const loading = pageLoading || collegeTeamsLoading || nflTeamsLoading;

  return (
    <PageContainer className={classes.userPageTemplate}>
      <Loader inProgress={loading} />

      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            {isEditMode ?
              <EditUserIcon className={classes.headerIcon} />
            :
              <AddUserIcon className={classes.headerIcon} />
            }
            {isEditMode ? 'Edit User' : 'Add User'}
          </h1>

          <div className={classes.switchWrapper}>
            <Switch
              checked={userStatusEnabled}
              onChange={() => {setUserStatusEnabled(!userStatusEnabled)}}
            />

            <span className={classes.switchValue}>
              User Status
              <strong>{userStatusEnabled ? 'Enabled' : 'Disabled'}</strong>
            </span>
          </div>
        </div>

        <AddEditUserForm
          className={classes.addEditUserForm}
          userStatusEnabled={userStatusEnabled}
          isEditMode={isEditMode}
          user={user}
          loading={loading}
          setLoading={setPageLoading}
        />
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </PageContainer>
  )
}

const mapStateToProps = (state:State) => {
  return {
    loggedInUser: state.user,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

export default connect(
  mapStateToProps,
)(AddUserPageTemplate);
