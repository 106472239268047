import { CompareResultType } from '../../organisms/ComparePlayersTable';
import {
  USER_SETTINGS_LOADING,
  USER_SETTINGS_LOADED,
  SET_SEARCH_COLUMNS,
  SET_SAVED_PLAYERS_COLUMNS,
  SET_TEAM_PLAYERS_COLUMNS,
  SET_COMPARE_PLAYERS_COLUMNS,
  SET_MANAGE_USERS_COLUMNS,
  SET_MANAGE_PROGRAMS_COLUMNS,
  SET_COMPARE_PLAYERS_RESULT_TYPE,
} from '../actions/user-settings';
import Column from '../../types/Column';

export interface UserSettingsState {
  userSettingsLoading: boolean;
  userSettingsLoaded: boolean;
  searchColumns: Column[] | null;
  savedPlayersColumns: Column[] | null;
  teamPlayersColumns: Column[] | null;
  comparePlayersColumns: Column[] | null;
  comparePlayersResultType: CompareResultType | null;
  manageUsersColumns: Column[] | null;
  manageProgramsColumns: Column[] | null;
}

const INITIAL_STATE:UserSettingsState = {
  userSettingsLoading: false,
  userSettingsLoaded: false,
  searchColumns: null,
  savedPlayersColumns: null,
  teamPlayersColumns: null,
  comparePlayersColumns: null,
  comparePlayersResultType: null,
  manageUsersColumns: null,
  manageProgramsColumns: null,
};

export function getUserSettingsInitialState () {
  return INITIAL_STATE;
}

export default function userSettingsReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case USER_SETTINGS_LOADING:
      return {
        ...state,
        userSettingsLoading: action.userSettingsLoading,
      };
    case USER_SETTINGS_LOADED:
      return {
        ...state,
        userSettingsLoaded: action.userSettingsLoaded,
      };
    case SET_SEARCH_COLUMNS:
      return {
        ...state,
        searchColumns: action.searchColumns,
      };
    case SET_SAVED_PLAYERS_COLUMNS:
      return {
        ...state,
        savedPlayersColumns: action.savedPlayersColumns,
      };
    case SET_TEAM_PLAYERS_COLUMNS:
      return {
        ...state,
        teamPlayersColumns: action.teamPlayersColumns,
      };
    case SET_COMPARE_PLAYERS_COLUMNS:
      return {
        ...state,
        comparePlayersColumns: action.comparePlayersColumns,
      };
    case SET_MANAGE_USERS_COLUMNS:
      return {
        ...state,
        manageUsersColumns: action.manageUsersColumns,
      };
    case SET_MANAGE_PROGRAMS_COLUMNS:
      return {
        ...state,
        manageProgramsColumns: action.manageProgramsColumns,
      };
    case SET_COMPARE_PLAYERS_RESULT_TYPE:
      return {
        ...state,
        comparePlayersResultType: action.comparePlayersResultType,
      };
    default:
      return state;
  }
};
