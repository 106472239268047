import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MyLoginInfo from '../molecules/MyLoginInfo';
import StaffRecordsTable from '../molecules/StaffRecordsTable';
import MyPreferences from '../molecules/MyPreferences';
import MyExportsTable from '../molecules/MyExportsTable';
import MyImportsTable from '../molecules/MyImportsTable';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import UserInfo from './UserInfo';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchStates } from '../redux/dispatchers/states';
import { fetchFBSConferences, fetchFCSConferences } from '../redux/dispatchers/conferences';
import {
  COLOR_MIDDLE_GRAY,
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
} from '../styles/colors';
import MEDIA from '../styles/media';
import User from '../types/User';
import Team from '../types/Team';
import StateType from '../types/State';
import Conference from '../types/Conference';

interface MyAccountsBoardProps {
  className?: string;
  nflAccess?: boolean;
  adminAccess?: boolean;
  user?: User;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  states: StateType[];
  statesLoading: boolean;
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  fetchStates: () => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  myAccountsBoard: {
    position: 'relative',
  },
  separator: {
    width: '100%',
    borderTop: `1px solid ${COLOR_MIDDLE_GRAY}`,
    margin: theme.spacing(5, 0),
  },
  leftSection: {
    width: '27%',
    borderRight: `1px solid ${COLOR_MIDDLE_GRAY}`,
    boxSizing: 'border-box',
    padding: theme.spacing(5, 0),
    flexGrow: 1,
  },
  rightSection: {
    width: '73%',
    boxSizing: 'border-box',
    padding: theme.spacing(5),
    flexGrow: 1,
  },
  loginInfo: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    marginBottom: theme.spacing(5),
    padding: theme.spacing(5,7),
  },
  staffRecordsTable: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    marginBottom: theme.spacing(5),
  },
  myPreferences: {
    marginBottom: theme.spacing(2),
  },

  '@media (max-width: 1600px)': {
    loginInfo: {
      padding: theme.spacing(5),
    },
  },

  '@media (max-width: 1260px)': {
    leftSection: {
      width: '35%',
    },
    rightSection: {
      width: '65%',
    },
    loginInfo: {
      display: 'block',
    },
  },

  '@media (max-width: 1023px)': {
    leftSection: {
      width: '100%',
    },
    rightSection: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },

  [MEDIA.MOBILE]: {
    leftSection: {
      paddingTop: theme.spacing(2),
    },

    loginInfo: {
      padding: theme.spacing(2),
    },
  },
}), { name: MyAccountsBoard.name });

function MyAccountsBoard (props: MyAccountsBoardProps) {
  const {
    className,
    nflAccess = false,
    adminAccess = false,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    user,
    states,
    statesLoading,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    fetchCollegeTeams,
    fetchNFLTeams,
    fetchStates,
    fetchFBSConferences,
    fetchFCSConferences,
  } = props;
  const classes = useStyles();

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    fetchCollegeTeams();
    fetchNFLTeams();
    fetchStates();
  }, []);

  useEffect(() => {
    if (!nflAccess) {
      fetchFBSConferences();
      fetchFCSConferences();
    }
  }, [nflAccess]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const loading = collegeTeamsLoading || nflTeamsLoading || statesLoading || fbsConferencesLoading || fcsConferencesLoading;

  return (
    <div className={clsx(className, classes.myAccountsBoard)}>
      <Loader inProgress={loading} />

      <div className={classes.leftSection}>
        {!!(user && Object.keys(user).length) && (
          <UserInfo
            user={user}
            nflAccess={nflAccess}
            adminAccess={adminAccess}
          />
        )}

        {!nflAccess && (
          <>
            <div className={classes.separator} />
            <MyExportsTable />
          </>
        )}

        {/* {!!user?.accessLevel?.canImport && (
          <>
            <div className={classes.separator} />
            <MyImportsTable user={user} />
          </>
        )} */}
      </div>

      <div className={classes.rightSection}>
        <MyLoginInfo
          className={classes.loginInfo}
          user={user}
          nflAccess={nflAccess}
          nflTeams={nflTeams}
          collegeTeams={collegeTeams}
          showToast={showToast}
        />

        <StaffRecordsTable
          className={classes.staffRecordsTable}
          user={user}
          adminAccess={adminAccess}
        />

        {!nflAccess && (
          <MyPreferences
            className={classes.myPreferences}
            user={user}
            states={states}
            fbsConferences={fbsConferences}
            fcsConferences={fcsConferences}
          />
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    nflAccess: state.ui.nflAccess,
    adminAccess: state.ui.adminAccess,
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    states: state.states.allStates,
    statesLoading: state.states.statesLoading,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchStates,
      fetchFBSConferences,
      fetchFCSConferences,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccountsBoard);
