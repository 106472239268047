import React from 'react';
import Icon from '../atoms/Icon';

function SwimmingIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
         strokeWidth='1'
         fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(1, 10)'>
          <path
            d='M25,3.499 C25,4.882 23.88,6 22.5,6 C21.118,6 20,4.882 20,3.499 C20,2.119 21.118,1 22.5,1 C23.88,1 25,2.119 25,3.499 Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinejoin='round'
          />
          <path
            d='M8.998,11 C9.674,11 10.328,10.925 10.973,10.808 L17.163,5.545 L15.868,4.022 L7.731,10.94 C8.137,10.975 8.555,11 8.998,11 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M12.842,2.016 L18.436,8.493 C19.173,8.301 19.933,8.157 20.718,8.075 L14.356,0.709 C13.673,-0.083 12.518,-0.215 11.672,0.395 L6.415,4.19 L7.585,5.811 L12.842,2.016 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M30,13 C30,13 26.421,10 22,10 C17.577,10 13.877,13 8.998,13 C3.158,13 0,10 0,10'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(SwimmingIcon);
