import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '../icons/CancelIcon';
import { COLOR_DARK_GRAY, COLOR_RED } from '../styles/colors';

interface CancelButtonProps {
  className?: string,
  onClick?: (event: any) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    appearance: 'none',
    border: 0,
    background: 'none',
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    position: 'relative',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:hover': {
      color: COLOR_RED,
    },

    '&:focus': {
      outline: 0,
      color: COLOR_RED,
    },
  },

  cancelIcon: {
    width: '100%',
    height: '100%',
  },
}), { name: CancelButton.name });

function CancelButton (props: CancelButtonProps, ref: React.Ref<any>) {
  const {
    className,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.cancelButton, className)}
      onClick={onClick}
      ref={ref}
    >
      <CancelIcon className={classes.cancelIcon} />
    </button>
  );
}

export default React.forwardRef(CancelButton);
