import React from 'react';
import Icon from '../atoms/Icon';

function AlertIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        d='M24.615 28.378H7.385l3.296-16.666a2.47 2.47 0 012.414-2h5.81c1.174 0 2.184.837 2.414 2l3.296 16.666zm-20.923 0h24.616M16 1v4.978M16 1v4.978m16 11.2h-4.923m-22.154 0H0m32 0h-4.923m-22.154 0H0m8.167-7.92l-3.48-3.52m3.48 3.52l-3.48-3.52m19.146 3.52l3.48-3.52m-3.48 3.52l3.48-3.52'
        stroke='currentColor'
        strokeWidth='2.462'
        fill='none'
        fillRule='evenodd'
      />
    </Icon>
  );
}

export default React.forwardRef(AlertIcon);
