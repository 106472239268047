export enum StagedPlayerStatus {
  matched = 'matched',
  unmatched = 'unmatched',
  created = 'created',
  new = 'new',
}

export interface StagedPlayerValidationError {
  key: string;
  value: any;
  message: string;
}

export interface StagedPlayerTrackEvent {
  meet: string | null;
  date: Date | null;
  year: number | null;
  performance: string;
}

export default interface StagedPlayer {
  id: number;
  slug: string | null;
  status: StagedPlayerStatus;
  validationErrors: StagedPlayerValidationError[];
  isAlreadyPresent?: boolean;
  hasHighSchool?: boolean;
  hasCollegeTeam?: boolean;
  hasNFLTeam?: boolean;
  isArchived?: boolean;
  createdAt?: string;
  updatedAt?: string;
  lastPublishedDate?: string;
  data: {
    slug: string | null;
    key247: string | null;
    uniqueId: string | null;
    pffId: string | null;
    ssaId: string | null;
    ryzerId: string | null;
    pai: number | null;
    combine: number | null;
    ppi: number | null;
    ppiDate?: string | null;
    hsClass: number | null;
    lastName: string | null;
    firstName: string | null;
    hsState: string | null;
    hsName: string | null;
    hsCity: string | null;
    hsCounty: string | null;
    hsZipcode: number | null;
    hsPrimaryPosition: string | null;
    hsPositions: string | null;
    hsHeight: number | null;
    hsWeight: number | null;
    dob: number | null;
    gpa: number | null;
    sat: number | null;
    act: number | null;
    comp247: number | null;
    compStar: number | null;
    star247: number | null;
    offersCount: number | null;
    offers: string | null;
    committedTo: string | null;
    linkTrackingFootball: string | null;
    photoUrl: string | null;
    link247: string | null;
    linkRivals: string | null;
    linkOn3: string | null;
    linkHudl: string | null;
    linkMaxpreps: string | null;
    linkTwitter: string | null;
    linkInstagram: string | null;
    linkAthleticNet: string | null;
    linkMilesplit: string | null;
    linkDirectAthletics: string | null;
    hsSportBaseball: 1 | 0 | null;
    hsSportBasketball: 1 | 0 | null;
    hsSportGolf: 1 | 0 | null;
    hsSportHockey: 1 | 0 | null;
    hsSportLacrosse: 1 | 0 | null;
    hsSportPowerLifting: 1 | 0 | null;
    hsSportRugby: 1 | 0 | null;
    hsSportSoccer: 1 | 0 | null;
    hsSportSwimming: 1 | 0 | null;
    hsSportTennis: 1 | 0 | null;
    hsSportTrack: 1 | 0 | null;
    hsSportVolleyball: 1 | 0 | null;
    hsSportWrestling: 1 | 0 | null;
    track55m: StagedPlayerTrackEvent | null;
    track60m: StagedPlayerTrackEvent | null;
    track55hh: StagedPlayerTrackEvent | null;
    track60hh: StagedPlayerTrackEvent | null;
    track100m: StagedPlayerTrackEvent | null;
    track110hh: StagedPlayerTrackEvent | null;
    track200m: StagedPlayerTrackEvent | null;
    track300m:  StagedPlayerTrackEvent | null;
    track300ih:  StagedPlayerTrackEvent | null;
    track400m:  StagedPlayerTrackEvent | null;
    track400h:  StagedPlayerTrackEvent | null;
    track800m:  StagedPlayerTrackEvent | null;
    track400r:  StagedPlayerTrackEvent | null;
    track800r:  StagedPlayerTrackEvent | null;
    track1600r:  StagedPlayerTrackEvent | null;
    track3200r:  StagedPlayerTrackEvent | null;
    trackShotput:  StagedPlayerTrackEvent | null;
    trackDiscus:  StagedPlayerTrackEvent | null;
    trackJavelin:  StagedPlayerTrackEvent | null;
    trackHighJump:  StagedPlayerTrackEvent | null;
    trackLongJump:  StagedPlayerTrackEvent | null;
    trackTripleJump:  StagedPlayerTrackEvent | null;
    trackPoleVault:  StagedPlayerTrackEvent | null;
    aTier: number | null;
    bTier: number | null;
    cTier: number | null;
    speedTag: number | null;
    powerTag: number | null;
    hsCombineType: string | null;
    hsCombineTeamCamp?: string | null;
    hsCombineCity: string | null;
    hsCombineDate: string | null;
    hsCombineHeight: number | null;
    hsCombineWeight: number | null;
    hsCombineWingspan: number | null;
    hsCombineHand: number | null;
    hsCombineArm: number | null;
    hsCombineMPH: number | null;
    hsCombine10Split: number | null;
    hsCombine40mDash: number | null;
    hsCombineShuttle: number | null;
    hsCombine3Cone: number | null;
    hsCombineVerticalJump: number | null;
    hsCombineBroadJump: number | null;
    hsCombinePowerToss: number | null;
    hsCombineRating: number | null;
    about: string | null;
    transferStatus: string | null;
    transferYear: number | null;
    ncaaId: number | null;
    collegeScholarship: 1 | 0 | null;
    collegeActive: 1 | 0 | null;
    collegeUrl: string | null;
    linkPff: string | null;
    linkSsa: string | null;
    collegeName: string | null;
    collegeRecruitingClass: number | null;
    collegePrimaryPosition: string | null;
    collegePositions: string | null;
    collegeHeight: number | null;
    collegeWeight: number | null;
    collegeJerseyNumber: string | null;
    collegeAllConference: string | null;
    collegeAllAmerican: string | null;
    watchlistEvent: string | null;
    watchlistYear: number | null;
    nflCombineType: string | null;
    nflCombineDate: number | null;
    nflCombineScore: number | null;
    nflPrimaryPosition: string | null;
    nflPositions: string | null;
    nflCombineHeight: number | null;
    nflCombineWeight: number | null;
    nflCombineHand: number | null;
    nflCombineWingspan: number | null;
    nflCombineArm: number | null;
    nflCombine40mDash: number | null;
    nflCombine10Split: number | null;
    nflCombine20Split: number | null;
    nflCombineMPH: number | null;
    nflCombineShuttle: number | null;
    nflCombine3Cone: number | null;
    nflCombineVerticalJump: number | null;
    nflCombineBroadJump: number | null;
    nflCombineBench: number | null;
    nflTeamName: string | null;
    nflDraftYear: number | null;
    nflDraftRound: number | null;
    nflOverallPick: string | null;
    nflProBowl: string | null;
    nflAllPro: string | null;
    hsCombineTeamCampId: number | null;
  };
}
