import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BLUE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import DownloadIcon from '../icons/DownloadIcon';
import MEDIA from '../styles/media';

interface DownloadProps {
  className?: string;
  label?: string,
  disabled?: boolean,
  onClick?: (event: any) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  download: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    marginLeft: theme.spacing(2),
    background: 'none',
    border: 0,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_BLUE,
    cursor: 'pointer',
    outlineColor: COLOR_BLUE,

    '&:hover': {
      textDecoration: 'underline',
    },

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  label: {
    fontWeight: 600,
  },
  icon: {
    width: '15px',
    height: '15px',
    marginLeft: theme.spacing(1),
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  [MEDIA.MOBILE]: {
    icon: {
      marginLeft: theme.spacing(.5),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    download: {
      marginLeft: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
    },
  },

  '@media (min-width: 1681px': {
    download: {
      marginLeft: theme.spacing(4),
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: Download.name });

export default function Download (props: DownloadProps) {
  const {
    className,
    label = '',
    disabled = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(
        classes.download,
        disabled && classes.disabled,
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={classes.label}>{label}</span>
      <DownloadIcon className={classes.icon} />
    </button>
  );
}
