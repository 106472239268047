import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_DARK_GRAY } from '../styles/colors';
import Company from '../types/Company';

interface CompanyLogoProps {
  className?: string;
  company?: Company;
}

const useStyles = makeStyles((theme:Theme) => ({
  companyLogo: {
    width: 'auto',
    height: '24px',
    color: COLOR_DARK_GRAY,
  },
}), { name: CompanyLogo.name });

export default function CompanyLogo (props: CompanyLogoProps) {
  const {
    className,
    company,
  } = props;
  const classes = useStyles();

  return company?.logoUrl
    ? <img
        className={clsx(classes.companyLogo, className)}
        src={company.logoUrl}
        alt={company?.name}
      />
    : null;
}
