import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '../icons/EditIcon';
import { COLOR_DARK_GRAY, COLOR_ORANGE } from '../styles/colors';

interface EditButtonProps {
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  onClick: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  editButton: {
    appearance: 'none',
    width: '30px',
    height: '30px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: COLOR_DARK_GRAY,
    border: 0,
    background: 'none',
    position: 'relative',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:before': {
      content: '""',
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      top: '-4px',
      left: '-4px',
      position: 'absolute',
      background: 'none',
      borderRadius: '50%',
      border: '1px solid transparent',
      boxSizing: 'border-box',
    },

    '&:hover': {
      color: COLOR_ORANGE,
    },

    '&:focus': {
      outline: 0,

      '&:before': {
        borderColor: COLOR_DARK_GRAY,
      },
    },
  },

  editIcon: {
    width: '30px',
    height: '30px',
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',

    '&:hover': {
      color: COLOR_DARK_GRAY,
    },
  },
}), { name: EditButton.name });

function EditButton (props: EditButtonProps, ref:React.Ref<any>) {
  const {
    className,
    iconClassName,
    disabled = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.editButton, disabled && classes.disabled, className)}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
    >
      <EditIcon className={clsx(classes.editIcon, iconClassName)} />
    </button>
  );
}

export default React.forwardRef(EditButton);
