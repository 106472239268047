import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '../molecules/Dialog';

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  confirmActionText?: string;
  cancelActionText?: string;
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  confirmDialog: {},
  confirmDialogActions: {
    marginTop: 0
  },
}), { name: ConfirmDialog.name });

export default function ConfirmDialog (props:ConfirmDialogProps) {
  const {
    open,
    title = 'Are you sure?',
    confirmActionText = 'Confirm',
    cancelActionText = 'No, Cancel',
    onConfirm,
    onClose,
    onCancel,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      className={classes.confirmDialog}
      actionsClassName={classes.confirmDialogActions}
      open={open}
      title={title}
      actions={[
        {
          name: confirmActionText,
          primary: true,
          onClick: onConfirm,
        },
        {
          name: cancelActionText,
          onClick: onCancel,
        },
      ]}
      onClose={onClose}
    />
  );
}