import React, { useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHeadCell from '../atoms/TableHeadCell';
import Score, { getScoreValue, SCORE_TYPE } from '../atoms/Score';
import NotAvailable from '../atoms/NotAvailable';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Avatar from '../atoms/Avatar';
import TeamLogo from '../atoms/TeamLogo';
import Download from '../atoms/Download';
import MultiSportList from '../molecules/MultiSportList';
import { inchesToFeetAndInches, secondsToMinutesSecondsMs } from '../services/converter';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_WHITE,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_BACKGROUND_WARM,
  COLOR_LIGHTEST_GRAY,
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_GRAY,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import SideBySidePlayer from '../types/SideBySide';
import HighSchool from '../types/HighSchool';
import College from '../types/College';
import NFLTeam from '../types/NFLTeam';
import { Order } from '../types/Order';

interface SideBySidePlayersTableProps {
  className?: string;
  playersIds: number [];
  printed?: boolean;
  players?: SideBySidePlayer[];
  setPlayers: (players: SideBySidePlayer[]) => void;
}

const HEADING = {
  CATEGORY: 'category',
  COMBINE: 'combine',
  THROWING: 'throwing',
  JUMPING: 'jumping',
  SPRINTING: 'sprinting',
  RELAYS: 'relays',
  HURDLES: 'hurdles',
  MIDDLE_DISTANCE: 'md_distance',
};

const HEADINGS_TEXT = {
  [HEADING.CATEGORY]: 'Category',
  [HEADING.COMBINE]: 'Combine',
  [HEADING.THROWING]: 'Throwing',
  [HEADING.JUMPING]: 'Jumping',
  [HEADING.SPRINTING]: 'Sprinting',
  [HEADING.RELAYS]: 'Relays',
  [HEADING.HURDLES]: 'Hurdles',
  [HEADING.MIDDLE_DISTANCE]: 'Middle Distance',
};

export const PLAYER_COLUMN = {
  POSITION: 'position',
  PAI_SCORE: 'pai',
  COMBINE_SCORE: 'combine',
  HS_NAME: 'highSchoolName',
  COLLEGE: 'college',
  NFL: 'nfl',
  HS_CLASS: 'highSchoolClass',
  HS_HEIGHT: 'highSchoolHeight',
  HS_WEIGHT: 'highSchoolWeight',
  STARS: 'compStar',
  HS_SPORTS: 'highSchoolSports',
  RUN_40: 'run_40',
  SHUTTLE: 'shuttle',
  VERTICAL_JUMP: 'verticalJump',
  BROAD_JUMP: 'broadJump',
  CONE_3: '3Cone',
  POWER_TOSS: 'powerToss',
  S_55_METERS: '55Meters',
  S_60_METERS: '60Meters',
  S_100_METERS: '100Meters',
  S_200_METERS: '200Meters',
  S_300_METERS: '300Meters',
  S_400_METERS: '400Meters',
  HH_55_METERS: '55mHighHurdles',
  HH_60_METERS: '60mHighHurdles',
  HH_110_METERS: '110mHighHurdles',
  H_300_INT: '300iHurdles',
  H_400_METERS: '400mHurdles',
  R_400_METERS: '400mRelay',
  R_800_METERS: '800mRelay',
  R_1600_METERS: '1600mRelay',
  R_3200_METERS: '3200mRelay',
  LONG_JUMP: 'longJump',
  TRIPLE_JUMP: 'tripleJump',
  HIGH_JUMP: 'highJump',
  POLE_VAULT_JUMP: 'poleVaultJump',
  SHOTPUT: 'shotput',
  DISCUS: 'discus',
  JAVELIN: 'javelin',
  MD_800_METERS: '800Meters',
};

export const PLAYER_COLUMN_TITLE = {
  [PLAYER_COLUMN.POSITION]: 'Position',
  [PLAYER_COLUMN.PAI_SCORE]: 'PAI',
  [PLAYER_COLUMN.COMBINE_SCORE]: 'Combine',
  [PLAYER_COLUMN.HS_NAME]: 'HS',
  [PLAYER_COLUMN.COLLEGE]: 'College',
  [PLAYER_COLUMN.NFL]: 'NFL',
  [PLAYER_COLUMN.HS_CLASS]: 'Class(HS)',
  [PLAYER_COLUMN.HS_HEIGHT]: 'Height(HS)',
  [PLAYER_COLUMN.HS_WEIGHT]: 'Weight(HS)',
  [PLAYER_COLUMN.HS_SPORTS]: 'Sports(HS)',
  [PLAYER_COLUMN.STARS]: 'Star Rating',
  [PLAYER_COLUMN.RUN_40]: '40',
  [PLAYER_COLUMN.SHUTTLE]: 'Shuttle',
  [PLAYER_COLUMN.VERTICAL_JUMP]: 'Vert. Jump',
  [PLAYER_COLUMN.BROAD_JUMP]: 'Broad Jump',
  [PLAYER_COLUMN.CONE_3]: '3-Cone',
  [PLAYER_COLUMN.POWER_TOSS]: 'Power Toss',
  [PLAYER_COLUMN.SHOTPUT]: 'Shot Put',
  [PLAYER_COLUMN.DISCUS]: 'Discus',
  [PLAYER_COLUMN.HIGH_JUMP]: 'High Jump',
  [PLAYER_COLUMN.S_55_METERS]: '55 Meters',
  [PLAYER_COLUMN.S_60_METERS]: '60 Meters',
  [PLAYER_COLUMN.S_100_METERS]: '100 Meters',
  [PLAYER_COLUMN.S_200_METERS]: '200 Meters',
  [PLAYER_COLUMN.S_300_METERS]: '300 Meters',
  [PLAYER_COLUMN.S_400_METERS]: '400 Meters',
  [PLAYER_COLUMN.HH_55_METERS]: '55 Meters High Hurdles',
  [PLAYER_COLUMN.HH_60_METERS]: '60 Meters High Hurdles',
  [PLAYER_COLUMN.HH_110_METERS]: '110 Meters High Hurdles',
  [PLAYER_COLUMN.H_300_INT]: '300 Int. Hurdles',
  [PLAYER_COLUMN.H_400_METERS]: '400 Meters Hurdles',
  [PLAYER_COLUMN.R_400_METERS]: '400 Meters',
  [PLAYER_COLUMN.R_800_METERS]: '800 Meters',
  [PLAYER_COLUMN.R_1600_METERS]: '1600 Meters',
  [PLAYER_COLUMN.R_3200_METERS]: '3200 Meters',
  [PLAYER_COLUMN.LONG_JUMP]: 'Long Jump',
  [PLAYER_COLUMN.TRIPLE_JUMP]: 'Triple Jump',
  [PLAYER_COLUMN.POLE_VAULT_JUMP]: 'Pole Vault',
  [PLAYER_COLUMN.JAVELIN]: 'Javelin',
  [PLAYER_COLUMN.MD_800_METERS]: '800 Meters',
};

const TOTAL_ROWS_IN_SECTION:{[key:string]: number} = {
  [HEADING.CATEGORY]: 5,
  [HEADING.COMBINE]: 5,
  [HEADING.THROWING]: 3,
  [HEADING.JUMPING]: 4,
  [HEADING.SPRINTING]: 6,
  [HEADING.HURDLES]: 5,
  [HEADING.RELAYS]: 4,
  [HEADING.MIDDLE_DISTANCE]: 1,
};

const useStyles = makeStyles((theme: Theme) => ({
  sideBySide: {
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  titleTextWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 2, 0, 0),
  },

  downloadPDF: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,

    '&:first-of-type': {
      marginLeft: 'auto',
    }
  },

  sideBySidePlayersTableWrap: {
    overflow: 'auto',
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  sideBySideTable: {
    width: '100%',
    background: COLOR_WHITE,
  },

  tableRow: {
    height: '50px',
    background: COLOR_WHITE,

    '& $playerTrackRecordCell': {
      '&:first-of-type': {
        color: COLOR_ORANGE,
      }
    },

    '@media print': {
      pageBreakInside: 'avoid',
      pageBreakAfter: 'auto',
    }
  },
  tableTitleRow: {
    borderTop: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      '& $tableHeadCellHeading': {
        fontWeight: 700,
        verticalAlign: 'middle',
      },
    },
  },

  tableHeadCellHeading: {
    backgroundColor: COLOR_BACKGROUND_WARM,
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(16),
  },
  tableHeadCellSubHeading: {
    width: '150px',
    maxWidth: '150px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderBottom: `1px solid ${COLOR_BORDER}`,
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),

    '&:hover': {
      '& $orderIcon': {
        transform: 'rotate(-90deg)translateX(50%)',
      },
    }
  },
  tableCell: {
    minWidth: '120px',
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,
    textAlign: 'center',
  },
  categoryRow: {
    verticalAlign: 'top',
  },
  selectedRow: {
    backgroundColor: COLOR_LIGHTEST_GRAY,
  },
  hiddenRow: {
    display: 'none',
  },

  orderIcon: {
    transform: 'rotate(-90deg)translateX(50%)',
  },

  avatar: {
    width: '32px',
    height: '32px',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
  },

  nameCell: {
    minHeight: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textAlign: 'center',
  },

  playerScore: {
    width: '32px',
    height: '32px',
    fontSize: theme.typography.pxToRem(14),
    margin: '0 auto',
  },

  teamLogo: {
    width: '25px',
    height: '25px',
  },

  multiSportList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiSportIcon: {
    width: '24px',
    height: '24px',
  },

  playerTrackRecordCell: {
    fontWeight: 700,

    '&:first-of-type': {
      color: COLOR_ORANGE,
    }
  },

  noData: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    backgroundColor: COLOR_LIGHT_GRAY,
    color: COLOR_DARK_GRAY,
  },

  [MEDIA.MOBILE]: {
    tableHeadCellSubHeading: {
      width: '100px',
      paddingLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
  },
}), { name: SideBySidePlayersTable.name });

export default function SideBySidePlayersTable(props:SideBySidePlayersTableProps) {
  const {
    className,
    playersIds,
    printed = false,
    players = [],
    setPlayers,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [sortedByColumn, setSortedByColumn] = useState<string>(PLAYER_COLUMN.PAI_SCORE);
  const [order, setOrder] = useState<Order | undefined>();

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  function getPrimarySchool (player:SideBySidePlayer) {
    return (player.highSchools || [])
      .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
  }

  function getPrimaryPosition (player:SideBySidePlayer) {
    const primaryHS = getPrimarySchool(player);
    const primaryPosition = ((primaryHS || {}).positions || [])
      .find((position:any) => position && position.isPrimary);

    return primaryPosition ? primaryPosition.code : '';
  }

  function getPrimaryCollege (player:SideBySidePlayer) {
    return (player.playerColleges || [])
      .find((college:College) => !!college?.isPrimary);
  }

  function getPrimaryNFLTeam (player:SideBySidePlayer) {
    return (player.playerNFLTeams || [])
      .find((nflTeam:NFLTeam) => !!nflTeam?.isPrimary);
  }

  function sortPlayers (
    players:SideBySidePlayer[],
    order:Order,
    getValue: (players:SideBySidePlayer) => string | number,
  ) {
    return players.sort((first:SideBySidePlayer, second:SideBySidePlayer) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function onSort (columnName:string, newOrder:Order, sort:(players:SideBySidePlayer[], order:Order) => SideBySidePlayer[]) {
    setSortedByColumn(columnName);
    setPlayers(sort(players, newOrder));
  }

  function onSortByColumn (sort:(players:SideBySidePlayer[], order:Order) => SideBySidePlayer[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function checkIsEmptyRow(columnName:string) {
    const totalPlayersCount = players.length;

    switch (columnName) {
      case PLAYER_COLUMN.POSITION: {
        const playerWithNoPrimaryPosition = players.filter((player:SideBySidePlayer) => !getPrimaryPosition(player));
        return playerWithNoPrimaryPosition.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.PAI_SCORE: {
        const playerWithNoPAIScore = players.filter((player:SideBySidePlayer) => !player.pai || typeof getScoreValue(player.pai) !== 'number');
        return playerWithNoPAIScore.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.COMBINE_SCORE: {
        const playerWithNoCombineScore = players.filter((player:SideBySidePlayer) => typeof getScoreValue(player.combine, { combines: player.hsCombines }) !== 'number');
        return playerWithNoCombineScore.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HS_NAME: {
        const playerWithNoHS = players.filter((player:SideBySidePlayer) => !(getPrimarySchool(player)|| {}).name);
        return playerWithNoHS.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HS_CLASS: {
        const playerWithNoHSClass = players.filter((player:SideBySidePlayer) => !(getPrimarySchool(player)|| {}).graduatingClass);
        return playerWithNoHSClass.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.COLLEGE: {
        const playerWithNoCollegeTeam = players.filter((player:SideBySidePlayer) => !getPrimaryCollege(player));
        return playerWithNoCollegeTeam.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.NFL: {
        const playerWithNoNFLTeam = players.filter((player:SideBySidePlayer) => !getPrimaryNFLTeam(player));
        return playerWithNoNFLTeam.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HS_HEIGHT: {
        const playerWithNoHSHeight = players.filter((player:SideBySidePlayer) => !(getPrimarySchool(player) || {}).height);
        return playerWithNoHSHeight.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HS_WEIGHT: {
        const playerWithNoHSWeight = players.filter((player:SideBySidePlayer) => !(getPrimarySchool(player) || {}).weight);
        return playerWithNoHSWeight.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HS_SPORTS: {
        const playerWithNoMultiSports = players.filter((player:SideBySidePlayer) => !(player || {}).multiSport);
        return playerWithNoMultiSports.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.STARS: {
        const playerWithNoStars = players.filter((player:SideBySidePlayer) => {
          return typeof player._247Star !== 'number' && typeof player.compStar !== 'number';
        });
        return playerWithNoStars.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.RUN_40: {
        const playerWithNo40 = players.filter((player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return (!hsCombines || !hsCombines._40mDash)
        });
        return playerWithNo40.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.SHUTTLE: {
        const playerWithNoShuttle = players.filter((player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return (!hsCombines || !hsCombines.shuttle)
        });
        return playerWithNoShuttle.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.VERTICAL_JUMP: {
        const playerWithNoVerticalJump = players.filter((player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return (!hsCombines || !hsCombines.verticalJump)
        });
        return playerWithNoVerticalJump.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.BROAD_JUMP: {
        const playerWithNoBroadJump = players.filter((player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return (!hsCombines || !hsCombines.broadJump)
        });
        return playerWithNoBroadJump.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.POWER_TOSS: {
        const playerWithNoPowerToss = players.filter((player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return (!hsCombines || !hsCombines.powerToss)
        });
        return playerWithNoPowerToss.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.SHOTPUT: {
        const playerWithNoShotputEvent = players.filter((player:SideBySidePlayer) => {
          const shotputEvent = player?.bestTrackEvent?.event_shotput;
          return (!shotputEvent || !shotputEvent.performance)
        });
        return playerWithNoShotputEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.DISCUS: {
        const playerWithNoDiscusEvent = players.filter((player:SideBySidePlayer) => {
          const discusEvent = player?.bestTrackEvent?.event_discus;
          return (!discusEvent || !discusEvent.performance);
        });
        return playerWithNoDiscusEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.JAVELIN: {
        const playerWithNoJavelinEvent = players.filter((player:SideBySidePlayer) => {
          const javelinEvent = player?.bestTrackEvent?.event_javelin;
          return (!javelinEvent || !javelinEvent.performance);
        });
        return playerWithNoJavelinEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HIGH_JUMP: {
        const playerWithNoHighJumpEvent = players.filter((player:SideBySidePlayer) => {
          const highJumpEvent = player?.bestTrackEvent?.event_high_jump;
          return (!highJumpEvent || !highJumpEvent.performance);
        });
        return playerWithNoHighJumpEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.LONG_JUMP: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const jumpEvent = player?.bestTrackEvent?.event_long_jump;
          return (!jumpEvent || !jumpEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.POLE_VAULT_JUMP: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const jumpEvent = player?.bestTrackEvent?.event_pole_vault;
          return (!jumpEvent || !jumpEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.TRIPLE_JUMP: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const jumpEvent = player?.bestTrackEvent?.event_triple_jump;
          return (!jumpEvent || !jumpEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_55_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_55m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_60_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_60m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_100_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_100m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_200_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_200m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_300_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_300m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.S_400_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_400m;
          return (!sprintEvent || !sprintEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.R_400_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_400r;
          return (!relayEvent || !relayEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.R_800_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_800r;
          return (!relayEvent || !relayEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.R_1600_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_1600r;
          return (!relayEvent || !relayEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.R_3200_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_3200r;
          return (!relayEvent || !relayEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HH_55_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_55hh;
          return (!hurdleEvent || !hurdleEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HH_60_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_60hh;
          return (!hurdleEvent || !hurdleEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.HH_110_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_110hh;
          return (!hurdleEvent || !hurdleEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.H_300_INT: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_300ih;
          return (!hurdleEvent || !hurdleEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.H_400_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_400h;
          return (!hurdleEvent || !hurdleEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      case PLAYER_COLUMN.MD_800_METERS: {
        const playerWithNoEvent = players.filter((player:SideBySidePlayer) => {
          const distanceEvent = player?.bestTrackEvent?.event_800m;
          return (!distanceEvent || !distanceEvent.performance);
        });
        return playerWithNoEvent.length === totalPlayersCount;
      }
      default:
        break;
    }
    return false;
  }

  function checkIsCategoryEmpty(category:string) {
    const rowsInSection = tableColumns[category].filter((column:any) => checkIsEmptyRow(column.value));
    return rowsInSection.length === TOTAL_ROWS_IN_SECTION[category];
  }

  function generateAndDownloadPDFReport () {
    if (!playersIds || !playersIds.length) return;

    setLoading(true);

    exportPDFReport(
      PDFExportPage.PLAYER,
      'side-by-side',
      { 'playerIds': playersIds.join(',') },
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function renderCell(player:SideBySidePlayer, column:any) {
    return (
      <TableCell
        key={`cell_${player.slug}`}
        className={clsx(
          classes.tableCell,
          [
            PLAYER_COLUMN.SHOTPUT,
            PLAYER_COLUMN.DISCUS,
            PLAYER_COLUMN.HIGH_JUMP,
            PLAYER_COLUMN.VERTICAL_JUMP,
            PLAYER_COLUMN.BROAD_JUMP,
            PLAYER_COLUMN.POWER_TOSS,
            PLAYER_COLUMN.RUN_40,
            PLAYER_COLUMN.CONE_3,
            PLAYER_COLUMN.SHUTTLE
          ].includes(column.value)
          //  && classes.playerTrackRecordCell
        )}
      >
        {column.renderContent(player)}
      </TableCell>
    );
  }

  const categories = [HEADING.CATEGORY, HEADING.COMBINE, HEADING.THROWING, HEADING.JUMPING, HEADING.SPRINTING, HEADING.RELAYS, HEADING.HURDLES];

  const tableColumns:{[key:string]: any} = {
    [HEADING.CATEGORY]: [
      {
        value: PLAYER_COLUMN.POSITION,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.POSITION],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => getPrimaryPosition(player) || <NotAvailable />,
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => getPrimaryPosition(player)
          );
        },
      },
      {
        value: PLAYER_COLUMN.PAI_SCORE,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.PAI_SCORE],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => (
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.PAI}
            scoreList={player.pai}
          />
        ),
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => player.pai && (getScoreValue(player.pai) || 0)
          );
        },
      },
      {
        value: PLAYER_COLUMN.COMBINE_SCORE,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COMBINE_SCORE],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => (
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.COMBINE}
            scoreList={player.combine}
            combines={player.hsCombines}
          />
        ),
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => getScoreValue(player?.combine, { combines: player?.hsCombines }) || 0,
          );
        },
      },
      {
        value: PLAYER_COLUMN.HS_NAME,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_NAME],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).name || <NotAvailable />),
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).name || '')
          );
        },
      },
      {
        value: PLAYER_COLUMN.COLLEGE,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COLLEGE],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const collegeTeam = (getPrimaryCollege(player) || {}).team;
          return ( collegeTeam ? (
            <TeamLogo
              className={classes.teamLogo}
              team={collegeTeam}
            />
          ) : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const collegeTeam = (getPrimaryCollege(player) || {}).team;
              return collegeTeam ? collegeTeam.name : '';
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.NFL,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.NFL],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const nflTeam = (getPrimaryNFLTeam(player) || {}).team;
          return ( nflTeam ? (
            <TeamLogo
              className={classes.teamLogo}
              team={nflTeam}
            />
          ) : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const nflTeam = (getPrimaryNFLTeam(player) || {}).team;
              return nflTeam ? nflTeam.name : '';
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.HS_CLASS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_CLASS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).graduatingClass || <NotAvailable />),
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).graduatingClass || '')
          );
        },
      },
      {
        value: PLAYER_COLUMN.HS_HEIGHT,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_HEIGHT],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const playerHSHeight = (getPrimarySchool(player) || {}).height;
          return (playerHSHeight ? inchesToFeetAndInches(playerHSHeight) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).height || 0),
          );
        },
      },
      {
        value: PLAYER_COLUMN.HS_WEIGHT,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_WEIGHT],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => (`${(getPrimarySchool(player) || {}).weight}lbs` || <NotAvailable />),
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => ((getPrimarySchool(player) || {}).weight || 0),
          );
        },
      },
      {
        value: PLAYER_COLUMN.HS_SPORTS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_SPORTS],
        sortable: false,
        renderContent: (player:SideBySidePlayer) => {
          return(!!player?.multiSport?.length ? (
            <MultiSportList
              className={classes.multiSportList}
              iconClassName={classes.multiSportIcon}
              list={player.multiSport}
            />
          )
          : <NotAvailable />)
        },
      },
      {
        value: PLAYER_COLUMN.STARS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.STARS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const stars = (typeof player?._247Star === 'number' || typeof player?.compStar === 'number')
            ? player?._247Star || player?.compStar || 0
            : null;
          return typeof stars === 'number'
            ? `${stars}/5`
            : <NotAvailable />;
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => player?._247Star || player?.compStar || 0,
          );
        },
      },
    ],
    [HEADING.COMBINE]: [
      {
        value: PLAYER_COLUMN.RUN_40,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.RUN_40],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return ((hsCombines && hsCombines._40mDash) ? Number(hsCombines._40mDash).toFixed(2) : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hsCombines = get(player, 'hsCombines[0]');
              return ((hsCombines && hsCombines._40mDash) ? hsCombines._40mDash : 0);
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.SHUTTLE,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.SHUTTLE],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return ((hsCombines && hsCombines.shuttle) ? Number(hsCombines.shuttle).toFixed(2) : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hsCombines = get(player, 'hsCombines[0]');
              return ((hsCombines && hsCombines.shuttle) ? hsCombines.shuttle : 0);
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.VERTICAL_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.VERTICAL_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return ((hsCombines && hsCombines.verticalJump) ? hsCombines.verticalJump : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hsCombines = get(player, 'hsCombines[0]');
              return ((hsCombines && hsCombines.verticalJump) ? hsCombines.verticalJump : 0);
            },
          );
        },
      },
      {
        value: PLAYER_COLUMN.BROAD_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.BROAD_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return ((hsCombines && hsCombines.broadJump) ? hsCombines.broadJump : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hsCombines = get(player, 'hsCombines[0]');
              return ((hsCombines && hsCombines.broadJump) ? hsCombines.broadJump : 0);
            },
          );
        },
      },
      {
        value: PLAYER_COLUMN.POWER_TOSS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.POWER_TOSS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hsCombines = get(player, 'hsCombines[0]');
          return ((hsCombines && hsCombines.powerToss) ? hsCombines.powerToss : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hsCombines = get(player, 'hsCombines[0]');
              return ((hsCombines && hsCombines.powerToss) ? hsCombines.powerToss : 0);
            },
          );
        },
      },
    ],
    [HEADING.THROWING]: [
      {
        value: PLAYER_COLUMN.SHOTPUT,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.SHOTPUT],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const shotputEvent = player?.bestTrackEvent?.event_shotput;
          return (shotputEvent ? inchesToFeetAndInches(shotputEvent.performance) : <NotAvailable />);
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const shotputEvent = player?.bestTrackEvent?.event_shotput;
              return (shotputEvent ? shotputEvent.performance : 0);
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.DISCUS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.DISCUS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const discusEvent = player?.bestTrackEvent?.event_discus;
          return (discusEvent ? inchesToFeetAndInches(discusEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const discusEvent = player?.bestTrackEvent?.event_discus;
              return (discusEvent ? discusEvent.performance : 0)
            },
          );
        },
      },
      {
        value: PLAYER_COLUMN.JAVELIN,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.JAVELIN],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const javelinEvent = player?.bestTrackEvent?.event_javelin;
          return (javelinEvent ? inchesToFeetAndInches(javelinEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const javelinEvent = player?.bestTrackEvent?.event_javelin;
              return (javelinEvent ? javelinEvent.performance : 0)
            },
          );
        },
      },
    ],
    [HEADING.JUMPING]: [
      {
        value: PLAYER_COLUMN.HIGH_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HIGH_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const highJumpEvent = player?.bestTrackEvent?.event_high_jump;
          return (highJumpEvent ? inchesToFeetAndInches(highJumpEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const highJumpEvent = player?.bestTrackEvent?.event_high_jump;
              return (highJumpEvent ? highJumpEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.LONG_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.LONG_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const longJumpEvent = player?.bestTrackEvent?.event_long_jump;
          return (longJumpEvent ? inchesToFeetAndInches(longJumpEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const longJumpEvent = player?.bestTrackEvent?.event_long_jump;
              return (longJumpEvent ? longJumpEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.POLE_VAULT_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.POLE_VAULT_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const jumpEvent = player?.bestTrackEvent?.event_pole_vault;
          return (jumpEvent ? inchesToFeetAndInches(jumpEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const jumpEvent = player?.bestTrackEvent?.event_pole_vault;
              return (jumpEvent ? jumpEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.TRIPLE_JUMP,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.TRIPLE_JUMP],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const jumpEvent = player?.bestTrackEvent?.event_triple_jump;
          return (jumpEvent ? inchesToFeetAndInches(jumpEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const jumpEvent = player?.bestTrackEvent?.event_triple_jump;
              return (jumpEvent ? jumpEvent.performance : 0)
            }
          );
        },
      },
    ],
    [HEADING.SPRINTING]: [
      {
        value: PLAYER_COLUMN.S_55_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_55_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_55m;
          return (sprintEvent ? Number(sprintEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_55m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.S_60_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_60_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_60m;
          return (sprintEvent ? Number(sprintEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_60m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.S_100_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_100_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_100m;
          return (sprintEvent ? Number(sprintEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_100m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.S_200_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_200_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_200m;
          return (sprintEvent ? secondsToMinutesSecondsMs(sprintEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_200m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.S_300_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_300_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_300m;
          return (sprintEvent ? secondsToMinutesSecondsMs(sprintEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_300m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.S_400_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.S_400_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const sprintEvent = player?.bestTrackEvent?.event_400m;
          return (sprintEvent ? secondsToMinutesSecondsMs(sprintEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const sprintEvent = player?.bestTrackEvent?.event_400m;
              return (sprintEvent ? sprintEvent.performance : 0)
            }
          );
        },
      },
    ],
    [HEADING.RELAYS]: [
      {
        value: PLAYER_COLUMN.R_400_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.R_400_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_400r;
          return (relayEvent ? secondsToMinutesSecondsMs(relayEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const relayEvent = player?.bestTrackEvent?.event_400r;
              return (relayEvent ? relayEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.R_800_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.R_800_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_800r;
          return (relayEvent ? secondsToMinutesSecondsMs(relayEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const relayEvent = player?.bestTrackEvent?.event_800r;
              return (relayEvent ? relayEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.R_1600_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.R_1600_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_1600r;
          return (relayEvent ? secondsToMinutesSecondsMs(relayEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const relayEvent = player?.bestTrackEvent?.event_1600r;
              return (relayEvent ? relayEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.R_3200_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.R_3200_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const relayEvent = player?.bestTrackEvent?.event_3200r;
          return (relayEvent ? secondsToMinutesSecondsMs(relayEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const relayEvent = player?.bestTrackEvent?.event_3200r;
              return (relayEvent ? relayEvent.performance : 0)
            }
          );
        },
      },
    ],
    [HEADING.HURDLES]: [
      {
        value: PLAYER_COLUMN.HH_55_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HH_55_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_55hh;
          return (hurdleEvent ? Number(hurdleEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hurdleEvent = player?.bestTrackEvent?.event_55hh;
              return (hurdleEvent ? hurdleEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.HH_60_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HH_60_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_60hh;
          return (hurdleEvent ? Number(hurdleEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hurdleEvent = player?.bestTrackEvent?.event_60hh;
              return (hurdleEvent ? hurdleEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.HH_110_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HH_110_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_110hh;
          return (hurdleEvent ? Number(hurdleEvent.performance).toFixed(2) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hurdleEvent = player?.bestTrackEvent?.event_110hh;
              return (hurdleEvent ? hurdleEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.H_300_INT,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.H_300_INT],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_300ih;
          return (hurdleEvent ? secondsToMinutesSecondsMs(hurdleEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hurdleEvent = player?.bestTrackEvent?.event_300ih;
              return (hurdleEvent ? hurdleEvent.performance : 0)
            }
          );
        },
      },
      {
        value: PLAYER_COLUMN.H_400_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.H_400_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const hurdleEvent = player?.bestTrackEvent?.event_400h;
          return (hurdleEvent ? secondsToMinutesSecondsMs(hurdleEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const hurdleEvent = player?.bestTrackEvent?.event_400h;
              return (hurdleEvent ? hurdleEvent.performance : 0)
            }
          );
        },
      },
    ],
    [HEADING.MIDDLE_DISTANCE]: [
      {
        value: PLAYER_COLUMN.MD_800_METERS,
        title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.MD_800_METERS],
        sortable: true,
        renderContent: (player:SideBySidePlayer) => {
          const distanceEvent = player?.bestTrackEvent?.event_800m;
          return (distanceEvent ? secondsToMinutesSecondsMs(distanceEvent.performance) : <NotAvailable />)
        },
        sort: (players:SideBySidePlayer[], order:Order) => {
          return sortPlayers(
            players,
            order,
            (player:SideBySidePlayer) => {
              const distanceEvent = player?.bestTrackEvent?.event_800m;
              return (distanceEvent ? distanceEvent.performance : 0)
            }
          );
        },
      },
    ]
  };

  const playersToRender = players
    .filter((player:SideBySidePlayer) => playersIds.includes(player.id));

  return (
    <div className={clsx(className, classes.sideBySide)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleTextWrap}>
          <h2 className={classes.headerTitle}>
            Your Player Chart
          </h2>

          {!printed && (
            <Download
              className={classes.downloadPDF}
              label='PDF'
              onClick={generateAndDownloadPDFReport}
            />
          )}
        </div>
      </div>

      <div className={classes.sideBySidePlayersTableWrap}>
        <Table className={classes.sideBySideTable}>
          {(playersToRender && !!playersToRender.length) && categories.map((category:string, index:number) => (
            <TableBody key={`${category}_${index}`}>
              <TableRow className={clsx(
                classes.tableRow,
                classes.tableTitleRow,
                HEADINGS_TEXT[category] === HEADINGS_TEXT[HEADING.CATEGORY] && classes.categoryRow,
                  checkIsCategoryEmpty(category) && classes.hiddenRow,
                )}
              >
                <TableCell className={classes.tableHeadCellHeading}>
                  {HEADINGS_TEXT[category]}
                </TableCell>
                {playersToRender
                  .map((player:SideBySidePlayer) => (
                    <TableCell
                      key={player.slug}
                      className={classes.tableCell}
                    >
                      {HEADINGS_TEXT[category] === HEADINGS_TEXT[HEADING.CATEGORY] && (
                        <div className={classes.nameCell}>
                          <Avatar
                            className={classes.avatar}
                            src={player.photoUrl}
                            alt={`Photo of ${player.firstName} ${player.lastName}`}
                            initials={`${(player.firstName || ' ')[0]}${(player.lastName || ' ')[0]}`.trim()}
                          />
                          {`${player.firstName} ${player.lastName}`}
                        </div>
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
              {tableColumns[category].map((column:any) => (
                <TableRow
                  key={column.value}
                  className={clsx(
                    classes.tableRow,
                    column.value === sortedByColumn && classes.selectedRow,
                    checkIsEmptyRow(column.value) && classes.hiddenRow
                  )}>
                  <TableHeadCell
                    className={clsx(
                      classes.tableHeadCellSubHeading,
                      column.value === sortedByColumn && classes.selectedRow
                    )}
                    orderIconClass={classes.orderIcon}
                    name={column.sortable ? column.value : undefined}
                    sortedByColumn={column.sortable ? sortedByColumn : undefined}
                    order={column.sortable ? order : undefined}
                    onSort={column.sortable ? onSortByColumn(column.sort || ((players:SideBySidePlayer[]) => players)) : undefined}
                  >
                    {column.title}
                  </TableHeadCell>
                  {playersToRender.map((player:SideBySidePlayer) => (
                    renderCell(player, column)
                  ))}
                </TableRow>
              ))}
            </TableBody>
          ))}
        </Table>

        {!players.length && (
          <div className={classes.noData}>
            No Players Data
          </div>
        )}
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}
