import React from 'react';
import Icon from '../atoms/Icon';

function HomeIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g 
        fill='currentColor' 
        fillRule='nonzero'
      >
        <path d='M29.333 18.495l1.886-1.886L15.609 1 0 16.61l1.886 1.885L15.609 4.771z'/>
        <path d='M13.558 18.886h4.103v11.282h10.564v-16h-2.667V27.5h-5.23V16.219h-9.436v11.282H5.66V14.168H2.994v16h10.564z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(HomeIcon);
