import React from 'react';
import Icon from '../atoms/Icon';

function HeightIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        d='M27 0H5v32h22V0zm-2.75 2.783v4.174h-9.625v2.782h9.625v2.783h-5.5v2.782h5.5v2.783h-9.625v2.783h9.625v2.782h-5.5v2.783h5.5v2.782H7.75V2.783h16.5z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </Icon>
  );
}

export default React.forwardRef(HeightIcon);
