import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import WatchListIcon from '../icons/WatchListIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '../atoms/Avatar';
import Download from '../atoms/Download';
import LocationIcon from '../atoms/LocationIcon';
import NotAvailable from '../atoms/NotAvailable';
import CloseButton from '../atoms/CloseButton';
import SeeMoreButton from '../atoms/SeeMoreButton';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import AlertButton, { ALERT_MODE } from '../molecules/AlertButton';
import PinButton, { PIN_MODE } from '../molecules/PinButton';
import PlayerNameWithPopover from './PlayerNameWithPopover';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  COLOR_BORDER,
  COLOR_BLUE,
  COLOR_ORANGE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import User from '../types/User';
import College from '../types/College';
import HighSchool from '../types/HighSchool';

interface DiscoveryPlayersProps {
  className?: string;
  isNFL?: boolean;
  user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
  trendingPlayers: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContentWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  headerIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
    color: COLOR_BLUE,
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 0, 0, 1),
  },
  headerCaption: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontStyle: 'italic',
  },
  headerDownloads: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  drawerHeaderDownloads: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },

  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '8px',
    transform: 'translate(0%, 50%)',
  },

  tableWrap: {
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableRow: {
    backgroundColor: COLOR_WHITE,
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,
    },
  },
  tableCell: {
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableCellWithAlert: {
    width: '50px',
    height: '50px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  tableSectionTitle: {
    background: COLOR_BACKGROUND_LIGHT,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    padding: theme.spacing(1, 2),
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: 0,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: 'rgba(0, 0, 0, 0.04)',
      bottom: 0,
      left: 0,
    },
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  playerHometownOrCollege: {
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  playerUpdate: {
    display: 'flex',
    alignItems: 'center',
  },
  playerUpdateIcon: {
    width: '20px',
    height: '20px',
    color: COLOR_DARK_GRAY,
  },
  playerUpdateText: {
    marginLeft: theme.spacing(1),
  },
  playerScore: {
    margin: '0 auto',
  },

  alertButtonContainer: {
    height: '100%',
    display: 'flex',
  },

  seeMoreContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },

  seeMoreDrawer: {
    width: '90%',
    maxWidth: '640px',
  },

  '@media (min-width: 1025px) and (max-width: 1260px), (max-width: 480px)': {
    header: {
      flexWrap: 'wrap',
      minHeight: 'auto',
    },
    headerTitleWrap: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    headerContentWrap: {
      width: '100%',
      marginLeft: 0,
      justifyContent: 'space-between',
    },
    headerCaption: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    headerDownloads: {
      marginLeft: 0,
    },
    drawerHeaderDownloads: {
      marginRight: 0,
    },
    closeButton: {
      top: '4px',
    }
  },

  [MEDIA.DESKTOP_LARGE]: {
    tableHeadCell: {
      padding: theme.spacing(1, 0, 1, 2),
      fontSize: theme.typography.pxToRem(16),

      '&:first-of-type': {
        paddingLeft: theme.spacing(2.5),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(2.5),
      },
    },
  },
}), { name: DiscoveryPlayers.name });

export default function DiscoveryPlayers (props: DiscoveryPlayersProps) {
  const { className, isNFL, user } = props;
  const classes = useStyles();

  const [discoveryPlayers, setDiscoveryPlayers] = useState<Player[]>([]);
  // const [players, setPlayers] = useState<Player[]>([]);
  const [hasAlerts, setHasAlerts] = useState<{[key:number]: boolean}>({});
  const [arePinned, setArePinned] = useState<{[key:number]: boolean}>({});

  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const limit = drawerOpen ? 50 : 5;

  useEffect(() => {
    fetchDiscoveryPlayers();
  }, [drawerOpen, isNFL]);

  function fetchDiscoveryPlayers () {
    setLoading(true);

    gql(`
    discovery (limit: ${limit}, isNFL: ${isNFL}) {
        id
        firstName
        lastName
        photoUrl
        playerColleges {
          positions
          recruitingClass
          team {
            id
            name
          }
          isPrimary
        }
        highSchools
        pai
        ppi
        combine
        hsCombines {
          id
        }
      }
    `)
      .then((data:any) => data.discovery as Player[])
      .then((players:Player[]) => {
        setDiscoveryPlayers(players || []);
        if (isNFL) {
          checkPlayersArePinned(players);
        } else {
          checkPlayersHasAlert(players);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function checkPlayersHasAlert (players:Player[]) {
    setLoading(true);
    if (!(players && players.length)) {
      return;
    }

    gql(`
      hasAlerts (playerIds: [${players.map(player => player.id)}])
    `)
      .then((data:any) => data.hasAlerts as boolean[])
      .then((hasAlerts:boolean[]) => {
        let playersList: {[key:number]: boolean} = {};
        players.map((player:Player, index:number) => {
          playersList[player.id] = hasAlerts[index];
        });

        setHasAlerts(playersList);
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function checkPlayersArePinned (players:Player[]) {
    setLoading(true);

    gql(`
      arePinned (playerIds: [${players.map(player => player.id)}])
    `)
      .then((data:any) => data.arePinned as boolean[])
      .then((arePinned:boolean[]) => {
        let playersList: {[key:number]: boolean} = {};
        players.map((player:Player, index:number) => {
          playersList[player.id] = arePinned[index];
        });

        setArePinned(playersList);
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onXLSDownload () {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: discoveryPlayers.map(player => player.id) },
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function addAlert (playerId:number) {
    setLoading(true);

    return gql(`
      mutation {
        addAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.addAlerts as boolean)
      .then((alertStatus:boolean) => {
        setHasAlerts({...hasAlerts, [playerId] : alertStatus});
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function removeAlert (playerId:number) {
    setLoading(true);

    return gql(`
      mutation {
        removeAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
        setHasAlerts({...hasAlerts, [playerId] : !success});
      })
        .catch(() => {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        })
      .finally(() => setLoading(false));
  }

  function unpinPlayer(playerId:number | string) {
    setLoading(true);

    gql(`
      mutation {
        unpin (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data && data.unpin as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
        setArePinned({...arePinned, [playerId] : !success});
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function pinPlayer(playerId:number | string) {
    setLoading(true);

    return gql(`
      mutation {
        pin (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => ({
        pinStatus: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ pinStatus, errorMessage }:{ pinStatus:boolean, errorMessage:string }) => {
        setArePinned({...arePinned, [playerId] : pinStatus});

        if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function renderHeader ({ inDrawer = false } = {}) {
    return (
      <div className={classes.header}>
        <div className={classes.headerTitleWrap}>
          <WatchListIcon className={classes.headerIcon} style={{color: isNFL ? COLOR_ORANGE : COLOR_BLUE}}/>
          <h2 className={classes.headerTitle}>Discovery Players</h2>
        </div>

        <div className={classes.headerContentWrap}>
          <div className={classes.headerCaption}>
            {inDrawer
              ? 'Tracking football staff selected players'
              : 'Tracking football staff selected players'
            }
          </div>

          {(user.accessLevel.exportAllowanceType !== 'none') && (
              <div className={clsx(classes.headerDownloads, inDrawer && classes.drawerHeaderDownloads)}>
                <Download
                  label='XLS'
                  onClick={onXLSDownload}
                  disabled={!discoveryPlayers.length}
                />
              </div>
            )
          }
        </div>

        {inDrawer && (
          <CloseButton
            className={classes.closeButton}
            onClick={() => setDrawerOpen(false)}
          />
        )}
      </div>
    );
  }

  function renderTableRow (player: Player, index: number) {
    const {
      id,
      firstName,
      lastName,
      photoUrl,
      pai,
      combine,
      playerColleges,
      highSchools,
    } = player;

    const primaryHighSchool = (highSchools || []).find((highSchool:HighSchool) => !!highSchool?.isPrimary);
    const primaryCollege = (playerColleges || []).find((college:College) => !!college?.isPrimary);

    const hsPosition = ((primaryHighSchool?.positions || [])
      .find((position:any) => position && position.isPrimary) || {}).code || '';
    const collegePosition = ((primaryCollege?.positions || [])
      .find((position:any) => position && position.isPrimary) || {}).code || '';

    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
      >
        <TableCell className={classes.tableCell} align='left'>
          <div className={classes.playerInfoContainer}>
            <Avatar
              className={classes.playerAvatar}
              src={photoUrl}
              alt='Profile picture'
            />

            <div className={classes.playerNameAndHometown}>
              <PlayerNameWithPopover
                playerId={id}
                name={`${firstName} ${lastName}`}
              />

              {(isNFL && primaryCollege) && (
                <div className={classes.playerHometownOrCollege}>
                  {primaryCollege.team ? primaryCollege.team.name : ''}
                </div>
              )}

              {(!isNFL && primaryHighSchool) && (
                <div className={classes.playerHometownOrCollege}>
                  {primaryHighSchool.city ? `${primaryHighSchool.city},`: ''}{primaryHighSchool.state ? `${primaryHighSchool.state}` : ''}
                </div>
              )}
            </div>

            {primaryHighSchool?.state && (
              <LocationIcon
                className={classes.locationIcon}
                stateCode={primaryHighSchool.state}
              />
            )}
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {isNFL && (!!collegePosition ? collegePosition : <NotAvailable />)}

          {!isNFL && (!!hsPosition ? hsPosition : <NotAvailable />)}
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {
            (primaryHighSchool && primaryHighSchool.graduatingClass)
            ? primaryHighSchool.graduatingClass
            : <NotAvailable />
          }
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.PAI}
            scoreList={pai}
          />
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.COMBINE}
            scoreList={combine}
            combines={player.hsCombines}
          />
        </TableCell>

        { isNFL &&
        <TableCell className={classes.tableCell} align='center'>
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.PPI}
            value={player?.ppi || undefined}
          />
        </TableCell>
        }

        <TableCell className={clsx(classes.tableCell, classes.tableCellWithAlert)} align='center'>
          <div className={classes.alertButtonContainer}>
            <Divider orientation='vertical' light flexItem />

            {!isNFL && (
              <AlertButton
                mode={hasAlerts[player.id] ? ALERT_MODE.REMOVE : ALERT_MODE.SET_ALERT}
                active={hasAlerts[player.id]}
                onClick={() => hasAlerts[player.id] ? removeAlert(player.id) : addAlert(player.id)}
              />
            )}
            {isNFL && (
              <PinButton
                mode={arePinned[player.id] ? PIN_MODE.REMOVE : PIN_MODE.PIN}
                active={arePinned[player.id]}
                onClick={() => arePinned[player.id] ? unpinPlayer(player.id) : pinPlayer(player.id)}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  function renderTable () {
    return (
      <div className={classes.tableWrap}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell} align='left'>Name | {isNFL ? 'College' : 'Hometown'}</TableCell>
              <TableCell className={classes.tableHeadCell} align='center'>Pos.</TableCell>
              <TableCell className={classes.tableHeadCell} align='center'>Class</TableCell>
              <TableCell className={classes.tableHeadCell} align='center'>PAI</TableCell>
              <TableCell className={classes.tableHeadCell} align='center'>HS Com.</TableCell>
              { isNFL && <TableCell className={classes.tableHeadCell} align='center'>PPI</TableCell>}
              <TableCell className={classes.tableHeadCell}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {discoveryPlayers.map(renderTableRow)}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <>
      <Paper className={clsx(classes.trendingPlayers, className)}>
        <Loader inProgress={loading} />

        {renderHeader()}

        <Divider variant='fullWidth' light />

        {renderTable()}

        <Divider variant='fullWidth' light />

        <div className={classes.seeMoreContainer}>
          <SeeMoreButton onClick={() => setDrawerOpen(true)} />
        </div>

        <Drawer
          classes={{ paper: classes.seeMoreDrawer }}
          anchor='right'
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Loader inProgress={loading} />

          {drawerOpen && [
            renderHeader({ inDrawer: true }),
            <Divider variant='fullWidth' light />,
            renderTable(),
          ]}
        </Drawer>
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
