import React from 'react';
import Icon from '../atoms/Icon';

function AllAmericanIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round'>
        <g transform='translate(3, 5)' stroke='currentColor' strokeWidth='2'>
          <polygon points='20 11 21.632 15.063 26 15.36 22.64 18.167 23.708 22.413 20 20.085 16.292 22.413 17.36 18.167 14 15.36 18.369 15.063' />
          <polygon points='6 11 7.632 15.063 12 15.36 8.64 18.167 9.708 22.413 6 20.085 2.292 22.413 3.36 18.167 0 15.36 4.369 15.063' />
          <polygon points='13 0 14.632 4.063 19 4.36 15.64 7.167 16.708 11.413 13 9.085 9.292 11.413 10.36 7.167 7 4.36 11.369 4.063' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(AllAmericanIcon);
