import React from 'react';
import Icon from '../atoms/Icon';

function TransferPortalIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(16, 16) scale(-1, 1) translate(-16, -16) translate(3, 5)'>
          <path
            d='M2,2 L4,2 L4,4 L2,4 L2,2 Z M5,2 L7,2 L7,4 L5,4 L5,2 Z M8,2 L20,2 L20,4 L8,4 L8,2 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <polyline
            stroke='currentColor'
            strokeWidth='2'
            points='14.71 22 0 22 0 0 22 0 22 12.157'
          />
          <line
            x1='0'
            y1='6'
            x2='22'
            y2='6'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M25.717,18.5 C25.717,18.5 22.829,22 20.5,22 C18.567,22 17,20.433 17,18.5 C17,16.567 18.567,15 20.5,15 C22.904,15 25.717,18.5 25.717,18.5 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TransferPortalIcon);
