import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Dialog from './Dialog';
import { COLOR_DARK_BLUE, COLOR_TEXT, COLOR_BLUE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';

interface ApiDocumentationModalProps {
  className?: string;
  headingClassName?: string;
  contentClassName?: string;
  isPopupVisible: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme:Theme) => ({
  apiDocumentationModal: {},

  linksList: {
    margin: 0,
    listStyle: 'none',
  },
  linkListItem: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
  },

  icon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
  },

  pdfLink: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR_BLUE,
    textDecoration: 'underline',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },
}), { name: ApiDocumentationModal.name });

export default function ApiDocumentationModal (props:ApiDocumentationModalProps) {
  const {
    className,
    headingClassName,
    contentClassName,
    isPopupVisible,
    onClose,
  } = props;
  const classes = useStyles(props);

  return(
    <Dialog
      className={clsx(classes.apiDocumentationModal, className)}
      contentClassName={contentClassName}
      open={isPopupVisible}
      title='API Documentation'
      actions={[
        {
          name: 'Cancel',
          primary: false,
          onClick: onClose
        },
      ]}
      onClose={onClose}
    >
      <ul className={classes.linksList}>
        <li className={classes.linkListItem}>
          <Link
            className={classes.pdfLink}
            to='/docs/api-json-response.pdf'
            target='_blank'
          >
            API documentation
          </Link>
            Provides example API responses and response values for fields.
        </li>

        <li className={classes.linkListItem}>
          <Link
            className={classes.pdfLink}
            to='/docs/using-api-v2.pdf'
            target='_blank'
          >
            Using the API
          </Link>
            Guide to using the Tracking Football API to access player data.
        </li>
      </ul>
    </Dialog>
  );
}
