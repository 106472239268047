import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Loader from '../atoms/Loader';
import { COLOR_BACKGROUND_LIGHT, COLOR_BORDER, COLOR_DARK_GRAY, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import clsx from 'clsx';
import Pagination from '../atoms/Pagination';
import { displayDateTime } from '../services/converter';

interface HSProductionProps {
  selectedfileContent: any[];
  isLoadingProductionData: boolean;
  fileNameAndDate: any;
  setCurrentDataPage: (value: number) => void;
  limit: number;
  currentDataPage: number;
}

interface dataProps {
  slug: string;
  source: string;
  year: number;
  message: string;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    cursor: 'default',
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 0,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1, 0, 1, 1),
    textTransform: 'capitalize',

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableInfo: {
    cursor: 'default', 
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 20px',
    '& > span': {
      display: 'inline-block',
    },
  },
  ellipsisCell: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  paginationWrapper: {
    padding: '0 20px',
  },
  paginationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },
  noDataTextWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: FONT_PROXIMA_NOVA,
    padding: theme.spacing(4),
  },
  noDataText: {
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    marginTop: theme.spacing(2),
  },
}))

export default function HSProductionDataTable(props: HSProductionProps) {
  const {
    isLoadingProductionData,
    selectedfileContent,
    fileNameAndDate,
    limit,
    currentDataPage,
    setCurrentDataPage
  } = props;
  const classes = useStyles();
  const totalPages = selectedfileContent && Math.ceil(selectedfileContent.length / limit);
  const itemsFrom = (currentDataPage - 1) * limit;
  const itemsTo = selectedfileContent && selectedfileContent.length < limit ? selectedfileContent.length : (limit * currentDataPage);

  function renderTableRow(data: dataProps, index: number) {
    const {
      slug,
      source,
      year,
      message
    } = data;
    return (
      <TableRow
        key={`${slug}_${index}`}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.ellipsisCell)} align='left'>{slug}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{source}</TableCell>
        <TableCell className={classes.tableCell} align='center'>{year}</TableCell>
        <TableCell className={classes.tableCell} align='left'>{message}</TableCell>
      </TableRow>
    )
  };

  function renderTable() {
    return (
      <div className={classes.tableWrap}>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.tableHeadCell, classes.ellipsisCell)} align='center'>Slug</TableCell>
                <TableCell className={classes.tableHeadCell} align='center'>Source</TableCell>
                <TableCell className={classes.tableHeadCell} align='center'>Year</TableCell>
                <TableCell className={classes.tableHeadCell} align='center'>Message</TableCell>
              </TableRow>
            </TableHead>
            {selectedfileContent && selectedfileContent.length ?
              <TableBody>
                {selectedfileContent.slice(itemsFrom, itemsTo).map(renderTableRow)}
              </TableBody>
              :
              <div className={classes.noDataTextWrapper}>
                <div className={classes.noDataText}>No Data Found</div>
              </div>
            }
          </Table>
        </>

      </div>
    );
  }

  return (
    <>
      <div className={classes.tableInfo}>
        <span>{fileNameAndDate && fileNameAndDate.fileName ? `File: ${fileNameAndDate.fileName}` : ''}</span>
        <span>{fileNameAndDate && fileNameAndDate.createdAt ? `Date: ${displayDateTime(fileNameAndDate.createdAt, true)}` : ''}</span>
      </div>
      <Paper>
        {
          isLoadingProductionData ?
            <Loader inProgress={isLoadingProductionData} />
            : renderTable()
        }
        <div className={classes.paginationWrapper}>
          <div className={classes.paginationContainer}>
            <span className={classes.paginationText}>
              Showing {itemsFrom + 1} to {itemsTo} of {selectedfileContent.length}
            </span>

            {totalPages > 1 && (
              <Pagination
                className={classes.pagination}
                currentPage={currentDataPage}
                totalPages={totalPages}
                onChange={page => setCurrentDataPage(page)}
              />
            )}
          </div>
        </div>
      </Paper>
    </>
  )
}
