import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_MIDDLE_GRAY, COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import { Order } from '../types/Order';

interface TableHeadCellProps {
  className?: string;
  orderIconClass?: string;
  children?: any;
  name?: string;
  sortedByColumn?: string;
  order?: Order | undefined;
  onSort?: (name:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHeadCell: {
    padding: theme.spacing(1.25, 2.5),
    borderBottom: 0,
    borderLeft: `1px solid ${COLOR_LIGHT_GRAY}`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
    background: COLOR_WHITE,
    position: 'relative',

    '&:last-of-type': {
      borderRight: `1px solid ${COLOR_LIGHT_GRAY}`,
    },
  },
  sortable: {
    paddingRight: '22px',

    '&:hover': {
      background: COLOR_LIGHT_GRAY,
      cursor: 'pointer',

      '& $orderIcon': {
        color: COLOR_DARK_GRAY,
        transform: 'translateY(-50%) scale(1.2)',
      },
    },
  },
  sorted: {
    color: COLOR_TEXT,

    '& $orderIcon': {
      color: COLOR_BLUE,
    },

    '&:hover': {
      '& $orderIcon': {
        color: COLOR_BLUE,
      },
    },
  },

  orderIcon: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: '50%',
    right: -5,
    transform: 'translateY(-50%) scale(1)',
    transition: 'transform 0.4s',
    color: COLOR_MIDDLE_GRAY,
  },

  [MEDIA.DESKTOP]: {
    tableHeadCell: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: TableHeadCell.name });

function TableHeadCell (props:TableHeadCellProps, ref:React.Ref<any>) {
  const {
    className,
    orderIconClass,
    children = '',
    name = '',
    sortedByColumn,
    order,
    onSort = () => {},
  } = props;
  const classes = useStyles();
  const sortable = !!name;
  const sorted = sortable && sortedByColumn === name && typeof order === 'string';

  return (
    <TableCell
      className={clsx(
        classes.tableHeadCell,
        name && classes.sortable,
        sorted && order && classes.sorted,
        className,
      )}
      ref={ref}
      sortDirection={sorted ? order : undefined}
      onClick={name ? () => onSort(name) : undefined}
    >
      {children}

      {sorted && order === Order.asc && (
        <ArrowUpIcon className={clsx(classes.orderIcon, orderIconClass)} />
      )}
      {((sorted && order === Order.desc) || (sortable && !sorted)) && (
        <ArrowDownIcon className={clsx(classes.orderIcon, orderIconClass)} />
      )}
    </TableCell>
  );
}

export default React.forwardRef(TableHeadCell);
