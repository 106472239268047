import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import { COLOR_BLUE, COLOR_MIDDLE_GRAY, COLOR_WHITE } from '../styles/colors';

interface CheckboxProps {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (value:boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    padding: 0,
  },
  checked: {
    '& $checkIconWrapper': {
      background: COLOR_BLUE,
      borderColor: COLOR_BLUE,
    },
  },
  disabled: {
    cursor: 'not-allowed !important' as 'not-allowed',
    pointerEvents: 'visible !important' as 'visible',

    '& $checkIconWrapper': {
      background: COLOR_MIDDLE_GRAY,
      borderColor: COLOR_MIDDLE_GRAY,
    },
  },
  disabledAndUnchecked: {
    '& $checkIconWrapper': {
      background: COLOR_WHITE,
    },
  },

  checkIconWrapper: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLOR_WHITE,
    borderRadius: '5px',
    border: '2px solid #979797',
    transition: 'background 0.3s, border 0.3s',
  },
  checkIcon: {
    width: '16px',
    height: '16px',
    color: COLOR_WHITE,
  },
}), { name: Checkbox.name });

export default function Checkbox (props: CheckboxProps) {
  const {
    className,
    checked,
    disabled,
    onChange = () => {},
  } = props;
  const classes = useStyles();

  return (
    <MaterialCheckbox
      className={clsx(
        classes.checkbox,
        checked && classes.checked,
        disabled && classes.disabled,
        disabled && !checked && classes.disabledAndUnchecked,
        className,
      )}
      checked={checked}
      disabled={disabled}
      checkedIcon={(
        <div className={classes.checkIconWrapper}>
          <CheckIcon className={classes.checkIcon}/>
        </div>
      )}
      icon={<div className={classes.checkIconWrapper} />}
      onChange={(event:any) => onChange(event.target.checked as boolean)}
      disableRipple
    />
  );
}
