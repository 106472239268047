import Position from './Position';
import DepthChartPlayer from './DepthChartPlayer';

export enum DepthChartType {
  DEFENSIVE = 'Defensive',
  OFFENSIVE = 'Offensive',
  SPECIAL = 'Special Teams',
}

export default interface DepthChartPosition {
  id: number;
  position?: Position | string;
  label: string;
  type: DepthChartType;
  order: number;
  players: DepthChartPlayer[];
  createdAt?: string;
  updatedAt?: string;
}
