import React from 'react';
import Icon from '../atoms/Icon';

function PAIIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fillRule='nonzero'
        fill='none'
      >
        <path
          d='M8.531 20.571H6.714v-8.537h3.993c1.856 0 2.867 1.254 2.867 2.752 0 1.485-1.024 2.739-2.867 2.739H8.531v3.046zm1.92-4.646c.717 0 1.267-.435 1.267-1.14 0-.716-.55-1.151-1.267-1.151h-1.92v2.291h1.92zm12.224 4.646h-2.06l-.538-1.446h-3.661l-.538 1.446h-2.06l3.29-8.537h2.278l3.29 8.537zm-3.097-3.046l-1.332-3.674-1.33 3.674h2.662zm5.708 3.046H23.47v-8.537h1.817v8.537z'
          fill='#2D2D2D'
        />
        <path
          d='M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm0-2.743C8.678 29.257 2.743 23.322 2.743 16S8.678 2.743 16 2.743 29.257 8.678 29.257 16 23.322 29.257 16 29.257z'
          fill='#F47321'
        />
        <path
          d='M0 16c0 8.837 7.163 16 16 16s16-7.163 16-16S24.837 0 16 0a1.371 1.371 0 100 2.743c7.322 0 13.257 5.935 13.257 13.257S23.322 29.257 16 29.257 2.743 23.322 2.743 16A1.371 1.371 0 100 16z'
          fill='#F47321'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(PAIIcon);
