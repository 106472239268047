export enum PlayerColumn {
  NAME = 'name',
  LINKS = 'links',
  HS_POSITION = 'HSPosition',
  PAI_SCORE = 'pai',
  COMBINE_SCORE = 'combine',
  HS_MPH = 'hsMPH',
  PRO_COMBINE_SCORE = 'nflCombineScore',
  PPI_SCORE = 'ppi',
  STARS = 'compStar',
  HS_NAME = 'highSchoolName',
  HS_STATE = 'highSchoolState',
  HS_CLASS = 'highSchoolClass',
  HS_SPORTS = 'highSchoolSports',
  WEIGHT = 'weight',
  HEIGHT = 'height',
  OFFER = 'offer',
  HS_CITY = 'city',
  OFFERED_BY = 'offeredBy',
  COLLEGE_NAME = 'collegeName',
  ROSTER_HEIGHT = 'rosterHeight',
  ROSTER_WEIGHT = 'rosterWeight',
  RECRUITING_CLASS = 'recruitingClass',
  COLLEGE_POSITION = 'collegePosition',
  NFL_POSITION = 'nflPosition',
  NFL_TEAM = 'nflTeam',
  DRAFT_YEAR = 'draftYear',
  DRAFT_RD = 'draftRound',
  TEAM_HEIGHT = 'teamHeight',
  TEAM_WEIGHT = 'teamWeight',
  LAST_EDITED = 'lastPublishedDate',
  COMMITTED_TO = 'committedTo',
  TRACK_55M = 'event_55m',
  TRACK_60M = 'event_60m',
  TRACK_100M = 'event_100m',
  TRACK_200M = 'event_200m',
  TRACK_300M = 'event_300m',
  TRACK_400M = 'event_400m',
  TRACK_800M = 'event_800m',
  TRACK_55HH = 'event_55hh',
  TRACK_60HH = 'event_60hh',
  TRACK_110HH = 'event_110hh',
  TRACK_300IH = 'event_300ih',
  TRACK_400H = 'event_400h',
  TRACK_400R = 'event_400r',
  TRACK_800R = 'event_800r',
  TRACK_1600R = 'event_1600r',
  TRACK_3200R = 'event_3200r',
  TRACK_SHOTPUT = 'event_shotput',
  TRACK_DISCUS = 'event_discus',
  TRACK_JAVELIN = 'event_javelin',
  TRACK_HIGH_JUMP = 'event_high_jump',
  TRACK_LONG_JUMP = 'event_long_jump',
  TRACK_TRIPLE_JUMP = 'event_triple_jump',
  TRACK_POLE_VAULT = 'event_pole_vault',
  COMBINE_ARM = 'arm',
  COMBINE_BROAD_JUMP = 'broadJump',
  COMBINE_HAND = 'hand',
  COMBINE_MPH = 'mph',
  COMBINE_POWER_TOSS = 'powerToss',
  COMBINE_SHUTTLE = 'shuttle',
  COMBINE_VERTICAL_JUMP = 'verticalJump',
  COMBINE_WINGSPAN = 'wingspan',
  COMBINE_3_CONE = '_3Cone',
  COMBINE_10_SPLIT = '_10Split',
  COMBINE_40M_DASH = '_40mDash',
  WATCH_LIST = 'watchlist',
  WATCH_LIST_YEAR = 'watchlistYear',
}
