import React from 'react';
import Icon from '../atoms/Icon';

function FilterIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='scale(-1, 1) translate(-27, 6)'>
          <path
            d='M0,0 L22,0 L22,20 L0,20 L0,0 Z M9,20 L9,0 M7,7 L2,7'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M3,3 C2.44771525,3 2,3.44771525 2,4 C2,4.55228475 2.44771525,5 3,5 C3.55228475,5 4,4.55228475 4,4 C4,3.44771525 3.55228475,3 3,3 Z M6,3 C5.44771525,3 5,3.44771525 5,4 C5,4.55228475 5.44771525,5 6,5 C6.55228475,5 7,4.55228475 7,4 C7,3.44771525 6.55228475,3 6,3 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(FilterIcon);
