import React from 'react';
import Icon from '../atoms/Icon';

function LacrosseIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(2, 3)'>
          <path
            d='M10.371,16.628 C11.917,18.174 14.343,18.325 15.245,17.296 C19.147,13.373 22.454,15.001 25.228,12.226 C26.912,10.548 25.794,7.009 22.892,4.107 L22.892,4.107 C19.99,1.205 16.422,0.061 14.744,1.745 C11.969,4.52 13.597,7.826 9.674,11.728 C8.645,12.63 8.825,15.083 10.371,16.628 L10.371,16.628 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M8.251,18.75 C8.014,18.513 7.8,18.255 7.597,17.989 L0.979,24.607 L2.393,26.021 L8.957,19.457 L8.251,18.75 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path
            d='M10.625,10.667 C9.596,11.569 9.796,14.142 11.342,15.688 L11.342,15.688 C12.888,17.234 15.224,17.447 16.125,16.418'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M23.355,8 C23.016,7.368 22.552,6.713 22,6.083 L22,7 L20,7 L20,5 L20.922,5 C20.291,4.443 19.635,3.979 19,3.636 L19,4 L17,4 L17,2.978 C16.943,2.974 16.882,2.962 16.827,2.962 L16.824,2.962 C16.513,2.962 16.29,3.028 16.161,3.157 C16.102,3.216 16.053,3.276 16,3.335 L16,4 L15.513,4 C15.332,4.31 15.19,4.637 15.07,5 L16,5 L16,7 L14.528,7 C14.443,7.32 14.345,7.657 14.236,8 L16,8 L16,10 L14,10 L14,8.679 C13.725,9.417 13.355,10.196 12.833,11 L13,11 L13,13 L12.069,13 C12.157,13.358 12.32,13.704 12.539,14 L13,14 L13,14.468 C13.307,14.705 13.658,14.889 14,14.977 L14,14 L16,14 L16,14.124 C16.765,13.629 17.506,13.269 18.207,13 L17,13 L17,11 L19,11 L19,12.725 C19.344,12.616 19.679,12.52 20,12.435 L20,11 L22,11 L22,11.892 C22.363,11.77 22.69,11.626 23,11.442 L23,11 L23.606,11 C23.676,10.939 23.746,10.88 23.814,10.812 C23.945,10.681 24.011,10.458 24.011,10.147 C24.011,10.1 24.001,10.048 23.998,10.001 L23,10.001 L23,8.001 L23.355,8 L23.355,8 Z M16,13 L14,13 L14,11 L16,11 L16,13 Z M19,10 L17,10 L17,8 L19,8 L19,10 Z M19,7 L17,7 L17,5 L19,5 L19,7 Z M22,10 L20,10 L20,8 L22,8 L22,10 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(LacrosseIcon);
