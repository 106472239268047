import React from 'react';
import Icon from '../atoms/Icon';

function RugbyIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinejoin='round'
      >
        <g
          transform='translate(5, 5)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path
            d='M5.544,22 C9.001,22 13.619,20.543 17.078,17.084 C22.457,11.704 23.01,3.533 20.738,1.262 C19.927,0.451 18.364,0 16.444,0 C12.987,0 8.374,1.463 4.916,4.922 C-0.466,10.302 -1,18.488 1.255,20.744 C2.061,21.55 3.623,22 5.544,22 L5.544,22 Z'
            strokeLinejoin='round'
          />
          <path d='M21,1 L16.695,5.304 M5.305,16.696 L1,21 M15.5,6.5 L6.5,15.5 M13,13 L9,9 M15.5,10.5 L11.5,6.5 M6.5,11.5 L10.5,15.5' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(RugbyIcon);
