import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '../icons/DoneIcon';
import { COLOR_DARK_GRAY, COLOR_GREEN } from '../styles/colors';

interface DoneButtonProps {
  className?: string,
  disabled?: boolean,
  onClick?: (event: any) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  doneButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    appearance: 'none',
    border: 0,
    background: 'none',
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    position: 'relative',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:hover': {
      color: COLOR_GREEN,
    },

    '&:focus': {
      outline: 0,
      color: COLOR_GREEN,
    },
  },

  doneIcon: {
    width: '100%',
    height: '100%',
  },

  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',
    color: COLOR_DARK_GRAY,

    '&:hover': {
      color: COLOR_DARK_GRAY,
    },
  },
}), { name: DoneButton.name });

export default function DoneButton (props: DoneButtonProps) {
  const {
    className,
    disabled = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.doneButton, className, disabled && classes.disabled)}
      disabled={disabled}
      onClick={onClick}
    >
      <DoneIcon className={classes.doneIcon} />
    </button>
  );
}
