import React from 'react';
import Icon from '../atoms/Icon';

function MoveIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(3, 7)'>
          <line x1='11' y1='9' x2='24.605' y2='9' stroke='currentColor' strokeWidth='2' />
          <path
            d='M15.315,12 C14.189,14.361 11.785,16 9,16 C5.141,16 2,12.86 2,9 C2,5.14 5.141,2 9,2 C11.785,2 14.188,3.639 15.315,6 L17.475,6 C16.236,2.51 12.91,0 9,0 C4.037,0 0,4.038 0,9 C0,13.962 4.037,18 9,18 C12.91,18 16.236,15.49 17.475,12 L15.315,12 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <polyline
            stroke='currentColor'
            strokeWidth='2'
            points='21 13 25 9 21 5'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(MoveIcon);
