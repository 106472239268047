import React from 'react';
import Icon from '../atoms/Icon';

function TrophyIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        fill='currentColor'
        fillRule='nonzero'
      >
        <path d='M27.077 1C29.679 1 32 3.033 32 5.923c0 1.954-.885 3.226-2.967 5.118l-.516.466c-1.987 1.794-2.67 2.78-2.67 4.262v.034c.013.242.16.59.408.964.181.272.363.486.466.59l-1.75 1.73a6.595 6.595 0 01-.765-.956c-.47-.706-.775-1.432-.817-2.193-.004-.084-.004-.084-.004-.169 0-2.4 1.01-3.857 3.482-6.09-.036.034.397-.357.511-.46 1.599-1.452 2.16-2.26 2.16-3.296 0-1.452-1.151-2.461-2.461-2.461-.548 0-.993.15-1.36.4-.208.142-.332.271-.364.315l-2.002-1.431c.188-.264.51-.596.978-.916A4.797 4.797 0 0127.077 1zM4.923 1c1.066 0 1.988.311 2.748.83.468.32.79.652.978.915L6.647 4.178a1.697 1.697 0 00-.364-.315 2.349 2.349 0 00-1.36-.401c-1.31 0-2.461 1.009-2.461 2.461 0 1.036.561 1.844 2.16 3.296l.51.46c2.472 2.233 3.483 3.69 3.483 6.09 0 .085 0 .085-.004.17-.042.76-.347 1.486-.817 2.192-.28.421-.56.75-.765.957l-1.75-1.731c.103-.104.285-.318.466-.59.248-.373.395-.722.408-.964v-.034c0-1.482-.683-2.468-2.67-4.262l-.516-.466C.885 9.149 0 7.877 0 5.923 0 3.033 2.32 1 4.923 1z'/>
        <path d='M25.846 2.23v1.232c0 7.557-3.342 17.498-7.19 20.928a4.92 4.92 0 014.723 4.67l.006.248v1.23H8.615v-1.23a4.923 4.923 0 014.678-4.917l.018-.001-.228-.213c-3.662-3.54-6.799-12.926-6.925-20.26l-.004-.455V2.23h19.692zm-7.384 24.616h-4.924c-.84 0-1.583.422-2.027 1.066l-.105.165h9.188a2.46 2.46 0 00-2.132-1.23zM13.31 24.39l.227-.005-.227.005zM23.35 4.692H8.649c.418 7.917 4.648 18.462 7.33 18.462 2.695 0 6.952-10.53 7.372-18.462zm-6.12 3.693v6.153h-2.462V8.385h2.462z'/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TrophyIcon);