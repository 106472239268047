import get from 'lodash/get';
import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';
import { AccessLevelType } from '../types/AccessLevel';
import User from '../types/User';
import { HealthCheck } from '../types/User';

export function logIn (email:string, password:string):Promise<User> {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/login`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: email,
      password,
    }),
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('Login attempt failed. Please check your email and password, and try again.')
    })
    .then(({ token, user }: { token:string, user:User }) => {
      clientStorage.clearAll();
      clientStorage.save(StorageKey.TOKEN, token);

      return user;
    });
}

export function verifyToken (token:string):Promise<User>  {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/verify`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('The token is invalid or expired.')
    });
}

export function createPassword (token:string, password:string):Promise<User> {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/create-password`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      password
    }),
  })
    .then(res => {
      if (res.ok) return res.json();

      throw new Error('An error occurred, please try again.')
    })
    .then(({ token, user }: { token:string, user:User }) => {
      clientStorage.save(StorageKey.TOKEN, token);

      return user;
    });
}

export function resendCreatePasswordEmail (token:string):Promise<boolean> {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/resend-create-password`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  })
    .then(res => res.ok);
}

export function resetPassword (email:string):Promise<boolean> {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/reset-password`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
    }),
  })
    .then(res => res.ok);
}

export function isLoggedIn () {
  return !!clientStorage.get(StorageKey.TOKEN);
}

export function validatePassword (password:string):{ valid:boolean, error:string } {
  let valid = true;
  let error = '';

  if (password.length < 6) {
    valid = false;
    error = 'The password must be at least 6 characters long.';
  }

  if (!(/[A-Z]/.test(password))) {
    valid = false;
    error = 'The password must contain at least one capital character.';
  }

  if (!(/[0-9]/.test(password))) {
    valid = false;
    error = 'The password must contain at least one digit.';
  }

  return { valid, error };
}

export function getHighSchoolAccessYears (user?:User):string[] {
  let highSchoolAccessYears = get(user, 'accessLevel.hsYearsAugust1', []);

  // if currentMonth is in between March and July then we need to go for hsYearsMarch1 otherwise hsYearsAugust1
  if (isDuringRollover()) {
    highSchoolAccessYears = get(user, 'accessLevel.hsYearsMarch1', []);
  }

  return highSchoolAccessYears;
}

export function getMaxHighSchoolClassYear (user:User|undefined, currentRecruitingYear:number):number {
  if (!user) return currentRecruitingYear;

  const highSchoolAccessYears = getHighSchoolAccessYears(user);
  const isAdmin = user?.accessLevel?.type === AccessLevelType.ADMIN;

  const accessYearMapping:any = {
    'senior': currentRecruitingYear,
    'junior': currentRecruitingYear + Math.max(1, highSchoolAccessYears.length - 1),
    'sophomore': currentRecruitingYear + Math.max(2, highSchoolAccessYears.length - 1),
    'freshman': currentRecruitingYear + Math.max(3, highSchoolAccessYears.length - 1),
  };

  return (accessYearMapping[highSchoolAccessYears[0]] || currentRecruitingYear) + (isAdmin ? 1 : 0);
}

export function isDuringRollover (rolloverDay?: number, rolloverMonth?: number) {
  const currentMonth:number = new Date().getMonth() + 1;
  if (rolloverDay && rolloverMonth) {
    const march1 = new Date(new Date().getFullYear(), 2, 1);
    const rolloverDateCurrentYear = new Date(new Date().getFullYear(), rolloverMonth-1, rolloverDay);

    return march1 <= new Date() && new Date() < rolloverDateCurrentYear;
  }
  return currentMonth >= 3 && currentMonth <= 7;
}

export function healthCheck ():Promise<HealthCheck> {
  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/healthCheck`, {
    method: 'GET',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(res => res.json());
}
