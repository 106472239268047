import React from 'react';
import Icon from '../atoms/Icon';

function TrainingIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(5, 8)' stroke='currentColor' strokeWidth='2'>
          <path d='M2,6.499 L2,0 L20,0 L20,13 L7,13 M7,13 L23,13' />
          <path d='M3.99999994,6 C2.92820322,6 1.93782213,6.57179674 1.40192374,7.5 C0.866025337,8.42820322 0.866025337,9.57179678 1.40192374,10.5 C1.93782213,11.4282033 2.92820322,12 3.99999994,12 C5.07179678,12 6.06217787,11.4282033 6.59807626,10.5 C7.13397466,9.57179678 7.13397466,8.42820322 6.59807626,7.5 C6.06217787,6.57179674 5.07179678,6 3.99999994,6 L3.99999994,6 Z' />
          <path d='M0,16 C0,13.791 1.791,12 4,12 C6.209,12 8,13.791 8,16' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(TrainingIcon);
