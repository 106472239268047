import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NavBar, { NAVBAR_HEIGHT, NAVBAR_MOBILE_HEIGHT } from '../organisms/NavBar';
import {
  SIDE_DRAWER_WIDTH,
  SIDE_DRAWER_WIDTH_SMALL,
  SIDE_DRAWER_CLOSED_WIDTH,
} from '../organisms/SideDrawer';
import { State } from '../redux/reducers';
import MEDIA from '../styles/media';

interface PageContainerProps {
  className?: string;
  sideDrawerOpen?: boolean;
  children: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    paddingTop: `${NAVBAR_HEIGHT}px`,
  },
  container: {
    width: `calc(100vw - ${SIDE_DRAWER_CLOSED_WIDTH}px)`,
    marginLeft: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxSizing: 'border-box',
    transition: 'margin 0.3s, width 0.3s',
  },
  withOpenDrawer: {
  },

  [MEDIA.MOBILE]: {
    pageContainer: {
      paddingTop: `${NAVBAR_MOBILE_HEIGHT}px`,
    },
    container: {
      width: 'auto',
      marginLeft: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [MEDIA.TABLET]: {
    withOpenDrawer: {
      width: `calc(100vw - ${SIDE_DRAWER_CLOSED_WIDTH}px)`,
      marginLeft: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    withOpenDrawer: {
      width: `calc(100vw - ${SIDE_DRAWER_WIDTH_SMALL}px)`,
      marginLeft: `${SIDE_DRAWER_WIDTH_SMALL}px`,
    },
  },

  '@media (min-width: 1801px)': {
    container: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },

    withOpenDrawer: {
      width: `calc(100vw - ${SIDE_DRAWER_WIDTH}px)`,
      marginLeft: `${SIDE_DRAWER_WIDTH}px`,
    },
  },
}), { name: PageContainer.name });

function PageContainer (props:PageContainerProps) {
  const {
    className,
    children,
    sideDrawerOpen,
  } = props;
  const classes = useStyles();

  return (
    <main className={classes.pageContainer}>
      <NavBar />

      <Container
        className={clsx(classes.container, sideDrawerOpen && classes.withOpenDrawer, className)}
        maxWidth={false}
      >
        {children}
      </Container>
    </main>
  );
}

const mapStateToProps = (state:State) => {
  return {
    sideDrawerOpen: state.ui.sideDrawerOpen,
  };
};

export default connect(
  mapStateToProps,
  null
)(PageContainer);
