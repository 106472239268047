import React from 'react';
import Icon from '../atoms/Icon';

function ExpandIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(7, 7)'>
          <polygon fill='currentColor' fillRule='nonzero' points='6 18 0 18 0 12' />
          <line x1='6.6' y1='11.4' x2='1.356' y2='16.644' stroke='currentColor' strokeWidth='2' />
          <polygon fill='currentColor' fillRule='nonzero' points='12 0 18 0 18 6' />
          <line x1='11.4' y1='6.6' x2='16.644' y2='1.356' stroke='currentColor' strokeWidth='2' />
          <polygon fill='currentColor' fillRule='nonzero' points='0 6 0 0 6 0' />
          <line x1='6.6' y1='6.6' x2='1.356' y2='1.356' stroke='currentColor' strokeWidth='2' />
          <polygon fill='currentColor' fillRule='nonzero' points='18 12 18 18 12 18' />
          <line x1='11.4' y1='11.4' x2='16.644' y2='16.644' stroke='currentColor' strokeWidth='2' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(ExpandIcon);
