import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_MIDDLE_GRAY } from '../styles/colors';

interface DragNDropDotsProps {
  className?: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  dragNDropDots: {
    width: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5),
    cursor: 'move',
  },
  dragNDropDot: {
    width: '3px',
    height: '3px',
    marginTop: '3px',
    marginLeft: '2px',
    borderRadius: '50%',
    backgroundColor: COLOR_MIDDLE_GRAY,

    '&:nth-of-type(2n - 1)': {
      marginLeft: 0,
    },
  },
}), { name: DragNDropDots.name });

export default function DragNDropDots (props: DragNDropDotsProps) {
  const {
    className,
  } = props;
  const classes = useStyles();


  return (
    <div className={clsx(classes.dragNDropDots, className)}>
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
      <div className={classes.dragNDropDot} />
    </div>
  );
}
