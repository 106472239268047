/**
 * Converts HEX color to RGB format
 * @param hexColor - e.g. '#ffffff' -> 'rgb(255, 255, 255)'
 */
function hexToRGB (hexColor:string):string {
  const hex = hexColor.replace('#', '');

  let red = 0;
  let green = 0;
  let blue = 0;

  if (hex.length === 3) {
    red = Number('0x' + hex[0] + hex[0]);
    green = Number('0x' + hex[1] + hex[1]);
    blue =  Number('0x' + hex[2] + hex[2]);
  } else if (hex.length === 6) {
    red =  Number('0x' + hex[0] + hex[1]);
    green =  Number('0x' + hex[2] + hex[3]);
    blue =  Number('0x' + hex[4] + hex[5]);
  }

  return `rgb(${red}, ${green}, ${blue})`;
}

/**
 * Converts RGB color to HEX format
 * @param rgbColor - e.g. 'rgb(255, 255, 255)' -> '#ffffff'
 */
function rgbToHex (rgbColor:string):string {
  const rgb:Array<number> = rgbStringToArray(rgbColor);

  let red = rgb[0].toString(16);
  let green = rgb[1].toString(16);
  let blue = rgb[2].toString(16);

  if (red.length === 1) red = '0' + red;
  if (green.length === 1) green = '0' + green;
  if (blue.length === 1) blue = '0' + blue;

  return '#' + red + green + blue;
}

function rgbStringToArray (rgbColor:string): Array<number> {
  return rgbColor
    .replace('rgb(', '')
    .replace(')', '')
    .replace(/ /g, '')
    .split(',')
    .map(value => Number(value));
}

function rgbArrayToString (rgbColor:Array<number>): string {
  return `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`;
}

export function interpolateRGBColors (
  startColor:string, // 'rgb(255, 255, 255)'
  endColor:string, // 'rgb(255, 255, 255)'
  percent:number, // 76
): Array<number> {
  const startColorArray = rgbStringToArray(startColor);
  const endColorArray = rgbStringToArray(endColor);
  const startFactor = 1 - (percent / 100);
  const endFactor = 1 - startFactor;

  return [
    Math.round(startColorArray[0] * startFactor + endColorArray[0] * endFactor),
    Math.round(startColorArray[1] * startFactor + endColorArray[1] * endFactor),
    Math.round(startColorArray[2] * startFactor + endColorArray[2] * endFactor)
  ];
}

export function interpolateHexColors (
  startColor:string, // '#ab4567'
  endColor:string, // '#ffffff'
  percent:number, // 76
) {
  const startRgbColor = hexToRGB(startColor);
  const endRgbColor = hexToRGB(endColor);
  const rgbResult = interpolateRGBColors(startRgbColor, endRgbColor, percent);

  return rgbToHex(rgbArrayToString(rgbResult));
}
