import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_BACKGROUND_WARM,
  COLOR_BORDER,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';

interface NamedBoxProps {
  className?: string;
  headerClassName?: string;
  headerNameClassName?: string;
  name: any;
  headerContent?: any;
  children: any;
  white?: boolean;
  subHeader?: string;
  printed?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  namedBox: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  white: {
    '& $header': {
      background: COLOR_WHITE,
      borderBottom: `1px solid ${COLOR_BORDER}`,
    },
  },

  header: {
    padding: theme.spacing(2.5),
    display: 'flex',
    position: 'relative',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
    background: COLOR_BACKGROUND_WARM,
  },
  headerName: {
    ...theme.typography.h2,
  },
  subHeader: {
    ...theme.typography.h2,
    position: 'absolute',
    bottom: '-40px',
    right: '4%',
    fontWeight: 400,
    fontSize: '18px'
  },
  printedSubHeader: {
    bottom: '12px',
  },
  boxContent: {
    background: COLOR_WHITE,
  },

  [MEDIA.MOBILE]: {
    header: {
      padding: theme.spacing(1.5),
    },

    boxContent: {
      overflow: 'auto',
    },
  },
}), { name: NamedBox.name });

export default function NamedBox (props: NamedBoxProps) {
  const {
    className,
    headerClassName,
    headerNameClassName,
    name,
    headerContent,
    children,
    white = false,
    subHeader,
    printed = false
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.namedBox,
        white && classes.white,
        className,
      )}
    >
      <div className={clsx(classes.header, headerClassName)}>
        <div className={clsx(classes.headerName, headerNameClassName)}>{name}</div>
        {subHeader && (<div className={clsx(classes.subHeader, printed && classes.printedSubHeader)}>{subHeader}</div>)}

        {headerContent}
      </div>

      <div className={classes.boxContent}>{children}</div>
    </div>
  );
}
