import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TrophyIcon from '../icons/TrophyIcon';
import CheckIcon from '../icons/CheckIcon';
import CloseButton from '../atoms/CloseButton';
import Loader from '../atoms/Loader';
import MinimizeButton from '../atoms/MinimizeButton';
import Tooltip from '../atoms/Tooltip';
import ProgressLine from '../atoms/ProgressLine';
import Dialog from '../molecules/Dialog';
import { State } from '../redux/reducers';
import {
  AchievementsVisibility,
  COLLEGE_ACHIEVEMENTS,
  NFL_ACHIEVEMENTS,
} from '../redux/reducers/achievements';
import { hideAchievements, minimizeAchievements, maximizeAchievements } from '../redux/dispatchers/achievements';
import {
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_MIDDLE_GRAY,
  COLOR_ORANGE,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { Achievement } from '../types/Achievement';

interface AchievementsProps {
  className?: string;
  achievements?: Achievement[];
  achievementsVisibility: AchievementsVisibility;
  isNFL: boolean;
  hideAchievements: () => void;
  minimizeAchievements: () => void;
  maximizeAchievements: () => void;
}

const ACHIEVEMENT_CONFIG = {
  [Achievement.LOGIN]: {
    name: 'Initial Login',
    link: '/login',
  },
  [Achievement.VIEW_PLAYER]: {
    name: 'View Player Profile',
    link: '/search?',
  },
  [Achievement.SAVE_PREFERENCES]: {
    name: 'Set Preferences',
    link: '/my-account#preferences',
  },
  [Achievement.VIEW_COMPARE_PAGE]: {
    name: 'Compare Players',
    link: '/compare?',
  },
  [Achievement.SAVE_PLAYER]: {
    name: 'Save a Player',
    link: '/search?',
  },
  [Achievement.SAVE_SEARCH]: {
    name: 'Save a Search',
    link: '/search?',
  },
  [Achievement.VIEW_ACCOUNT_PAGE]: {
    name: 'Visit Account Page',
    link: '/my-account?',
  },
  [Achievement.CHECK_MESSAGES]: {
    name: 'Check Messages',
    link: '/messages?',
  },
  [Achievement.VIEW_SIDE_BY_SIDE_PAGE]: {
    name: 'Side-by-side Chart',
    link: '/saved-players?',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  achievements: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    paddingRight: theme.spacing(2),
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  headerIcon: {
    flexShrink: 0,
    color: COLOR_TEXT,
    width: '24px',
    height: '24px',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },
  headerProgress: {
    width: '100%',
    maxWidth: '320px',
    paddingLeft: 0,
  },
  headerProgressValue: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    color: COLOR_GREEN,
  },

  minimizeButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  closeButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },

  achievement: {
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
  achievementLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  achievementNumber: {
    border: `2px solid ${COLOR_MIDDLE_GRAY}`,
    borderRadius: '50%',
    width: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    fontWeight: 400,
    color: COLOR_TEXT,
  },
  achievementDoneIcon: {
    color: COLOR_WHITE,
    width: '14px',
    height: '14px',
  },
  achievementText: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
  },
  achievementLinkIcon: {
    color: COLOR_TEXT,
    transform: 'scale(0.56)',
  },

  completed: {
    '& $achievementLink': {
      cursor: 'default',
    },

    '& $achievementNumber': {
      borderColor: COLOR_GREEN,
      background: COLOR_GREEN,
      position: 'relative',
    },
    '& $achievementText': {
      color: COLOR_DARK_GRAY,
    },
  },

  highlighted: {
    '& $achievementNumber': {
      borderColor: COLOR_ORANGE,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    headerProgress: {
      width: 'auto',
      paddingLeft: theme.spacing(2),
    },

    achievement: {
      fontSize: theme.typography.pxToRem(20),
    },
    achievementText: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
}), { name: Achievements.name });

function Achievements (props:AchievementsProps) {
  const {
    className,
    achievements = [],
    achievementsVisibility,
    hideAchievements,
    minimizeAchievements,
    maximizeAchievements,
    isNFL,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  const achievementConfigs = (isNFL ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS)
    .map((achievementInOrder:Achievement) => {
      const completed = !!achievements.find((achievement:Achievement) => achievement === achievementInOrder);

      return {
        ...ACHIEVEMENT_CONFIG[achievementInOrder],
        completed,
      };
    });

  useEffect(() => {
    if (achievements && achievements.length) {
      setLoading(false);
    }
  }, [achievements]);

  function getProgressValue () {
    let progressValue = 0;

    if (!!achievements.length) {
      const completedNumber = achievementConfigs.reduce((sum, config:any) => {
        return config.completed ? sum + 1 : sum;
      }, 0);

      progressValue = Math.round(completedNumber * 100 / achievementConfigs.length);
    }

    return progressValue;
  }

  if (achievementsVisibility === AchievementsVisibility.CLOSED) {
    return null;
  }

  const highlightedAchievementIndex = achievementConfigs.findIndex((achievement, index) => {
    return !achievement.completed && achievementConfigs[index - 1] && achievementConfigs[index - 1].completed;
  });

  return (
    <>
      <Paper className={clsx(classes.achievements, className)}>
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <div className={classes.headerInfo}>
            <TrophyIcon className={classes.headerIcon} />

            <h2 className={classes.headerTitle}>Achievements</h2>

            <ProgressLine
              className={classes.headerProgress}
              valueClassName={classes.headerProgressValue}
              value={getProgressValue()}
            />
          </div>

          <div className={classes.headerActions}>
            <Tooltip title={achievementsVisibility === AchievementsVisibility.MINIMIZED ? 'Expand Achievements' : 'Minimize Achievements'}>
              <div className={classes.minimizeButtonWrapper}>
                <MinimizeButton
                  minimized={achievementsVisibility === AchievementsVisibility.MINIMIZED}
                  onMinimize={minimizeAchievements}
                  onMaximize={maximizeAchievements}
                />
              </div>
            </Tooltip>

            <Tooltip title='Remove Achievements'>
              <div className={classes.closeButtonWrapper}>
                <CloseButton onClick={() => setDialogOpen(true)} />
              </div>
            </Tooltip>
          </div>
        </div>

        <Divider variant='fullWidth' light />

        {(!!achievements.length && achievementsVisibility === AchievementsVisibility.OPENED) && (
          <List disablePadding>
            {achievementConfigs.map((achievement:any, index) => (
              <ListItem
                key={index}
                className={clsx(
                  classes.achievement,
                  achievement.completed && classes.completed,
                  highlightedAchievementIndex === index && classes.highlighted,
                )}
                divider={index !== achievementConfigs.length - 1}
              >
                <a
                  className={classes.achievementLink}
                  href={achievement.link}
                >
                  <div className={classes.achievementNumber}>
                    {achievement.completed
                      ? <CheckIcon className={classes.achievementDoneIcon} />
                      : index + 1
                    }
                  </div>

                  <ListItemText
                    classes={{ primary: classes.achievementText }}
                    primary={achievement.name}
                  />

                  {!achievement.completed && <ArrowForwardIosIcon className={classes.achievementLinkIcon} />}
                </a>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>

      <Dialog
        open={dialogOpen}
        title='Are you sure?'
        text='Your Achievements will be removed from your dashboard. You can bring back it in your account settings. Are you sure you want to remove this from your dashboard?'
        actions={[
          {
            name: 'Yes, Remove',
            primary: true,
            onClick: hideAchievements,
          },
          {
            name: 'No, Cancel',
            onClick: () => setDialogOpen(false),
          },
        ]}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    achievements: state.achievements.achievements,
    achievementsVisibility: state.achievements.achievementsVisibility,
    isNFL: state.ui.nflAccess,
  };
};
const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      hideAchievements,
      minimizeAchievements,
      maximizeAchievements,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Achievements);
