import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHeadCell from '../atoms/TableHeadCell';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import NotAvailable from '../atoms/NotAvailable';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import { datetimeToDate } from '../services/converter';
import { COLLEGE_ACHIEVEMENTS, NFL_ACHIEVEMENTS } from '../redux/reducers/achievements';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_SHADOW,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_BLUE,
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_BLUE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import ManagedUser from '../types/ManagedUser';
import { Order } from '../types/Order';
import { Achievement } from '../types/Achievement';

interface UsersTableProps {
  className?: string;
  managedUsers: ManagedUser[];
  currentPage?: number;
  limit?: number;
  selectedItems: ManagedUser[];
  selectedColumns: string[],
  setCurrentPage: (page: number) => void;
  setSelectedItems: (items: any) => void;
  setManagedUsers: (managedUsers:ManagedUser[]) => void;
}

export interface ColumnConfiguration {
  key: ManagedUserColumn;
  title: any;
  className?: string;
  sortable?: boolean;
  truncated?: boolean;
  align?: 'left' | 'center' | 'right';
  renderContent: (managedUser:ManagedUser) => any;
  sort: (users:ManagedUser[], order:Order) => ManagedUser[];
}

export enum ManagedUserColumn {
  NAME = 'name',
  EMAIL = 'email',
  ACCESS_LEVEL = 'accessLevel',
  SUBSCRIPTION_DATE = 'startDate',
  EXPIRATION_DATE = 'expirationDate',
  RANK = 'rank',
  ACHIEVEMENTS = 'achievements',
  LAST_LOGIN_DATE = 'lastLogin',
  LOGINS_LAST_30_DAYS = 'last30DaysLoginCount',
  TOTAL_LOGINS = 'totalLogins',
  SAVED_PLAYERS = 'savedPlayers',
  ALERTS = 'alerts',
  PINNED_PLAYERS = 'pinnedPlayers',
  EXPORTED_PLAYERS = 'exportedPlayers',
  OPT_OUT = 'isSMSOptedOut',
  INVOICE = 'invoice',
  COLLEGE = 'collegeTeam',
  NFL_TEAM = 'nflTeam',
  COMPANY = 'company',
  PROGRAM_NAME = 'programName',
  LAST_LOGIN_IP = 'lastLoginIp',
  IP_ORGANIZATION = 'IpOrganization',
  IP_CITY_STATE = 'IpCityState',
}

export const USER_ALL_COLUMNS = [
  ManagedUserColumn.NAME,
  ManagedUserColumn.EMAIL,
  ManagedUserColumn.ACCESS_LEVEL,
  ManagedUserColumn.SUBSCRIPTION_DATE,
  ManagedUserColumn.EXPIRATION_DATE,
  ManagedUserColumn.RANK,
  ManagedUserColumn.ACHIEVEMENTS,
  ManagedUserColumn.LAST_LOGIN_DATE,
  ManagedUserColumn.LOGINS_LAST_30_DAYS,
  ManagedUserColumn.TOTAL_LOGINS,
  ManagedUserColumn.SAVED_PLAYERS,
  ManagedUserColumn.ALERTS,
  ManagedUserColumn.PINNED_PLAYERS,
  ManagedUserColumn.EXPORTED_PLAYERS,
  ManagedUserColumn.OPT_OUT,
  ManagedUserColumn.INVOICE,
  ManagedUserColumn.COLLEGE,
  ManagedUserColumn.NFL_TEAM,
  ManagedUserColumn.COMPANY,
  ManagedUserColumn.PROGRAM_NAME,
  ManagedUserColumn.LAST_LOGIN_IP,
  ManagedUserColumn.IP_ORGANIZATION,
  ManagedUserColumn.IP_CITY_STATE,
];

export const USER_COLUMN_TITLE = {
  [ManagedUserColumn.NAME]: <>Name&nbsp;(Last&nbsp;First)</>,
  [ManagedUserColumn.EMAIL]: 'Email',
  [ManagedUserColumn.ACCESS_LEVEL]: <>Access&nbsp;Level</>,
  [ManagedUserColumn.SUBSCRIPTION_DATE]: <>Subscription&nbsp;Date</>,
  [ManagedUserColumn.EXPIRATION_DATE]: <>Expiration&nbsp;Date</>,
  [ManagedUserColumn.RANK]: 'Rank',
  [ManagedUserColumn.ACHIEVEMENTS]: 'Achievements',
  [ManagedUserColumn.LAST_LOGIN_DATE]: <>Last&nbsp;Login</>,
  [ManagedUserColumn.LOGINS_LAST_30_DAYS]: <>Logins&nbsp;Last&nbsp;30&nbsp;days</>,
  [ManagedUserColumn.TOTAL_LOGINS]: <>Total&nbsp;Logins</>,
  [ManagedUserColumn.SAVED_PLAYERS]: <>Saved&nbsp;Players</>,
  [ManagedUserColumn.ALERTS]: <>Alert&nbsp;List</>,
  [ManagedUserColumn.PINNED_PLAYERS]: 'Pinned',
  [ManagedUserColumn.EXPORTED_PLAYERS]: 'Exports',
  [ManagedUserColumn.OPT_OUT]: <>Opt&nbsp;Out</>,
  [ManagedUserColumn.INVOICE]: <>Invoice&nbsp;#</>,
  [ManagedUserColumn.COLLEGE]: 'College',
  [ManagedUserColumn.NFL_TEAM]: <>NFL&nbsp;Team</>,
  [ManagedUserColumn.COMPANY]: 'Company',
  [ManagedUserColumn.PROGRAM_NAME]: <>Program&nbsp;Name</>,
  [ManagedUserColumn.LAST_LOGIN_IP]: <>Last&nbsp;Login&nbsp;IP</>,
  [ManagedUserColumn.IP_ORGANIZATION]: <>IP&nbsp;Organization</>,
  [ManagedUserColumn.IP_CITY_STATE]: <>IP&nbsp;City&nbsp;State</>,
};

const useStyles = makeStyles(theme => ({
  usersTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  header: {
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    position: 'relative',
    margin: 0,
    padding: theme.spacing(3, 2),
    lineHeight: 1,
    ...theme.typography.h2,
  },
  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: '40px',
    },
  },
  tableHeadCell: {
    borderLeft: 'none',

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  tableRow: {
    height: '50px',
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.25),
    whiteSpace: 'nowrap',
  },
  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },
  nameCell: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },

  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0, 5),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    width: '100%',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },

  [MEDIA.DESKTOP]: {
    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: 'auto',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
      textAlign: 'left',
    },

    pagination: {
      maxWidth: '60%',
    },
  },
}), { name: UsersTable.name });

export default function UsersTable (props:UsersTableProps) {
  const {
    className,
    managedUsers,
    setManagedUsers,
    currentPage = 1,
    limit = 10,
    selectedItems,
    selectedColumns,
    setCurrentPage,
    setSelectedItems,
  } = props;
  const classes = useStyles();

  const [order, setOrder] = useState<Order | undefined>();
  const [sortedByColumn, setSortedByColumn] = useState<string>('');

  function onSortByColumn (sort:(users:ManagedUser[], order:Order) => ManagedUser[]) {
    return (columnName: string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function onSort (columnName:string, newOrder:Order, sort:(users:ManagedUser[], order: Order) => ManagedUser[]) {
    setSortedByColumn(columnName);
    setManagedUsers(sort(managedUsers, newOrder));
  }

  function onCheckBoxChange (checkedItem:ManagedUser) {
    return (value: boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }
        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function renderRow (managedUser:ManagedUser) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === managedUser.id);

    return (
      <TableRow
        key={managedUser.id}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(managedUser)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:ColumnConfiguration) => (
          tableColumn && (
            <TableCell
              key={tableColumn.key}
              className={clsx(
                classes.tableCell,
                tableColumn.className,
                tableColumn.key === ManagedUserColumn.NAME && classes.nameCell,
                [ManagedUserColumn.RANK, ManagedUserColumn.ACHIEVEMENTS].includes(tableColumn.key) && classes.textCenter,
              )}
              align={tableColumn.align}
            >
              {tableColumn.renderContent(managedUser)}
            </TableCell>
          )))}
      </TableRow>
    );
  }

  function sortManagedUsers (
    users: ManagedUser[],
    order: Order,
    getValue: (user:ManagedUser) => string | number,
  ) {
    return users.sort((first:ManagedUser, second:ManagedUser) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  const tableColumns:ColumnConfiguration[] = selectedColumns.map((column:string) => {
    switch (column as ManagedUserColumn) {
      case ManagedUserColumn.NAME:
        return {
          key: ManagedUserColumn.NAME,
          title: USER_COLUMN_TITLE[ManagedUserColumn.NAME],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => (
            <Link
              className={classes.link}
              to={`/manage/user/edit/${managedUser.id}`}
            >
              {`${managedUser.lastName} ${managedUser.firstName}`}
            </Link>
          ),
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => `${managedUser.lastName} ${managedUser.firstName}`
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.EMAIL:
        return {
          key: ManagedUserColumn.EMAIL,
          title: USER_COLUMN_TITLE[ManagedUserColumn.EMAIL],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.email ? `${managedUser.email}` : <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.email || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.ACCESS_LEVEL:
        return {
          key: ManagedUserColumn.ACCESS_LEVEL,
          title: USER_COLUMN_TITLE[ManagedUserColumn.ACCESS_LEVEL],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser?.accessLevel?.name || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser?.accessLevel?.name || '',
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.SUBSCRIPTION_DATE:
        return {
          key: ManagedUserColumn.SUBSCRIPTION_DATE,
          title: USER_COLUMN_TITLE[ManagedUserColumn.SUBSCRIPTION_DATE],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => (managedUser.startDate || managedUser.program?.startDate || managedUser.program?.createdAt
            ? `${datetimeToDate(managedUser.startDate || managedUser.program?.startDate || managedUser.program?.createdAt)}`
            : <NotAvailable className={classes.textCenter} />
          ),
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => (managedUser.startDate || managedUser.program?.startDate || managedUser.program?.createdAt
                ? (managedUser.startDate || managedUser.program?.startDate || managedUser.program?.createdAt) : '')
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.EXPIRATION_DATE:
        return {
          key: ManagedUserColumn.EXPIRATION_DATE,
          title: USER_COLUMN_TITLE[ManagedUserColumn.EXPIRATION_DATE],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.expirationDate || managedUser.program?.expirationDate ? `${datetimeToDate(managedUser.expirationDate || managedUser.program?.expirationDate)}` : <NotAvailable className={classes.textCenter} />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.expirationDate || managedUser.program?.expirationDate ? (managedUser.expirationDate || managedUser.program?.expirationDate) : ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.RANK:
        return {
          key: ManagedUserColumn.RANK,
          title: USER_COLUMN_TITLE[ManagedUserColumn.RANK],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => {
            return managedUser.rank || <NotAvailable className={classes.textCenter} />;
          },
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.rank || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.ACHIEVEMENTS:
        return {
          key: ManagedUserColumn.ACHIEVEMENTS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.ACHIEVEMENTS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => {
            const allAchievements = managedUser.nflTeam ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS;
            const completedAchievements = (managedUser.achievements || [])
              .filter((eventName:Achievement) => allAchievements.includes(eventName))
              .length || 0;

            return `${completedAchievements || 0}/${allAchievements.length}`;
          },
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => {
                const allAchievements = managedUser.nflTeam ? NFL_ACHIEVEMENTS : COLLEGE_ACHIEVEMENTS;

                return (managedUser.achievements || [])
                  .filter((eventName:Achievement) => allAchievements.includes(eventName))
                  .length || 0;
              }
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.LAST_LOGIN_DATE:
        return {
          key: ManagedUserColumn.LAST_LOGIN_DATE,
          title: USER_COLUMN_TITLE[ManagedUserColumn.LAST_LOGIN_DATE],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.lastLogin ? `${datetimeToDate(managedUser.lastLogin)}` : <NotAvailable className={classes.textCenter} />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.lastLogin || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.LOGINS_LAST_30_DAYS:
        return {
          key: ManagedUserColumn.LOGINS_LAST_30_DAYS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.LOGINS_LAST_30_DAYS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.last30DaysLoginCount || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.last30DaysLoginCount || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.TOTAL_LOGINS:
        return {
          key: ManagedUserColumn.TOTAL_LOGINS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.TOTAL_LOGINS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.totalLogins || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.totalLogins || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.SAVED_PLAYERS:
        return {
          key: ManagedUserColumn.SAVED_PLAYERS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.SAVED_PLAYERS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.savedPlayers || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.savedPlayers || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.ALERTS:
        return {
          key: ManagedUserColumn.ALERTS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.ALERTS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.alerts || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.alerts || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.PINNED_PLAYERS:
        return {
          key: ManagedUserColumn.PINNED_PLAYERS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.PINNED_PLAYERS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.pinnedPlayers || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.pinnedPlayers || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.EXPORTED_PLAYERS:
        return {
          key: ManagedUserColumn.EXPORTED_PLAYERS,
          title: USER_COLUMN_TITLE[ManagedUserColumn.EXPORTED_PLAYERS],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.exportedPlayers || 0,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.exportedPlayers || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.OPT_OUT:
        return {
          key: ManagedUserColumn.OPT_OUT,
          title: USER_COLUMN_TITLE[ManagedUserColumn.OPT_OUT],
          sortable: true,
          align: 'center',
          renderContent: (managedUser:ManagedUser) => managedUser.isSMSOptedOut ? 'Yes' : 'No',
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.isSMSOptedOut ? 'Yes' : 'No'
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.INVOICE:
        return {
          key: ManagedUserColumn.INVOICE,
          title: USER_COLUMN_TITLE[ManagedUserColumn.INVOICE],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.invoice || <NotAvailable className={classes.textCenter} />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.invoice || 0
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.COLLEGE:
        return {
          key: ManagedUserColumn.COLLEGE,
          title: USER_COLUMN_TITLE[ManagedUserColumn.COLLEGE],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.collegeTeam?.name || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.collegeTeam?.name || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.NFL_TEAM:
        return {
          key: ManagedUserColumn.NFL_TEAM,
          title: USER_COLUMN_TITLE[ManagedUserColumn.NFL_TEAM],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.nflTeam?.name || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.nflTeam?.name || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.COMPANY:
        return {
          key: ManagedUserColumn.COMPANY,
          title: USER_COLUMN_TITLE[ManagedUserColumn.COMPANY],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.company?.name || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.company?.name || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.PROGRAM_NAME:
        return {
          key: ManagedUserColumn.PROGRAM_NAME,
          title: USER_COLUMN_TITLE[ManagedUserColumn.PROGRAM_NAME],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.program?.name || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.program?.name || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.LAST_LOGIN_IP:
        return {
          key: ManagedUserColumn.LAST_LOGIN_IP,
          title: USER_COLUMN_TITLE[ManagedUserColumn.LAST_LOGIN_IP],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.lastLoginData?.ipAddress || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.lastLoginData?.ipAddress || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.IP_ORGANIZATION:
        return {
          key: ManagedUserColumn.IP_ORGANIZATION,
          title: USER_COLUMN_TITLE[ManagedUserColumn.IP_ORGANIZATION],
          sortable: true,
          renderContent: (managedUser:ManagedUser) => managedUser.lastLoginData?.locationDetails?.organization || <NotAvailable />,
          sort: (users:ManagedUser[], order:Order) => {
            return sortManagedUsers(
              users,
              order,
              (managedUser:ManagedUser) => managedUser.lastLoginData?.locationDetails?.organization || ''
            );
          },
        } as ColumnConfiguration;
      case ManagedUserColumn.IP_CITY_STATE:
      return {
        key: ManagedUserColumn.IP_CITY_STATE,
        title: USER_COLUMN_TITLE[ManagedUserColumn.IP_CITY_STATE],
        sortable: true,
        renderContent: (managedUser:ManagedUser) => `${managedUser.lastLoginData?.locationDetails?.city || ''}, ${managedUser.lastLoginData?.locationDetails?.state || ''}` || <NotAvailable />,
        sort: (users:ManagedUser[], order:Order) => {
          return sortManagedUsers(
            users,
            order,
            (managedUser:ManagedUser) => `${managedUser.lastLoginData?.locationDetails?.city}, ${managedUser.lastLoginData?.locationDetails?.state}` || ''
          );
        },
      } as ColumnConfiguration;
      default:
        return null;
    }
  }).filter(Boolean) as ColumnConfiguration[];

  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = managedUsers.length < limit ? managedUsers.length : (limit * currentPage);
  const totalPages = Math.ceil(managedUsers.length / limit);

  return (
    <div className={className}>
      <TableWithDoubleScrollBars tableClassName={classes.usersTable}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableHeadCell
              key='check-box-cell'
              className={classes.checkBoxCell}
            >
              <Checkbox
                checked={selectedItems.length === managedUsers.length}
                onChange={() => selectedItems.length === managedUsers.length
                  ? setSelectedItems([])
                  : setSelectedItems([...managedUsers])
                }
              />
            </TableHeadCell>

            {tableColumns.map((tableColumn:ColumnConfiguration) => (
              (tableColumn && (<TableHeadCell
                key={tableColumn.key}
                name={tableColumn.sortable ? tableColumn.key : undefined}
                sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                order={tableColumn.sortable ? order : undefined}
                onSort={tableColumn.sortable ? onSortByColumn(tableColumn.sort || ((users:ManagedUser[]) => users)) : undefined}
                className={clsx(tableColumn.className, classes.tableHeadCell)}
              >
                {tableColumn.title}
              </TableHeadCell>))
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {managedUsers
            .slice(itemsFrom, itemsTo)
            .map(renderRow)
          }
        </TableBody>
      </TableWithDoubleScrollBars>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {managedUsers.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  )
}
