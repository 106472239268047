import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  optOut: {
    padding: theme.spacing(2, 4, 4),
    position: 'relative',
  },
  heading: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
  },
}), { name: Outage.name });

interface OutageProps {
  outageMessage: string;
}

export default function Outage (props: OutageProps) {
  const classes = useStyles();
  const {
    outageMessage
  } = props;

  return (
    <>
       <div className={classes.optOut}>
        <div className={classes.heading}>
          {outageMessage}
        </div>
        </div>
    </>
  );
}
