import React from 'react';
import Page from './Page';
import AlertsPageTemplate from '../templates/AlertsPageTemplate';

export default function AlertsPage () {
  return (
    <Page title='Alerts'>
      <AlertsPageTemplate />
    </Page>
  );
}
