import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '../atoms/Tooltip';
import Loader from '../atoms/Loader';
import { datetimeToDate } from '../services/converter';
import gql from '../services/gql';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import PlayerUpdate, { PlayerUpdateType } from '../types/PlayerUpdate';

interface LastUpdatedProps {
  className?: string;
  playerId: number;
  datetime: string;
  hideTooltip?: boolean;
}

const UPDATE_TYPE_NAME = {
  [PlayerUpdateType.WEIGHT]: 'Weight Updated',
  [PlayerUpdateType.HEIGHT]: 'Height Updated',
  [PlayerUpdateType.TRACK_DATA]: 'Track Data Updated',
  [PlayerUpdateType.PAI_SCORE]: 'PAI Score Updated',
  [PlayerUpdateType.COMBINE_SCORE]: 'Combine Score Updated',
  [PlayerUpdateType.STAR_RATING]: 'Star Rating Changed',
  [PlayerUpdateType.OFFERS]: 'New Offers',
  [PlayerUpdateType.COMMITTED]: 'Committed',
  [PlayerUpdateType.COMBINE_DATA]: 'New Combine Data',
  [PlayerUpdateType.PPI]: 'PPI Score Updated',
  [PlayerUpdateType.WATCH_LIST]: 'Watch list Updated',
  [PlayerUpdateType.ALL_AMERICAN]: 'All American Updated',
  [PlayerUpdateType.ALL_CONFERENCE]: 'All Conference Updated',
};

const useStyles = makeStyles((theme: Theme) => ({
  lastUpdated: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
  },
  text: {
    paddingRight: '8px',
  },
  icon: {
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s',
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_BLUE,
    }
  },

  tooltipContent: {
    minWidth: 100,
    minHeight: 50,
    position: 'relative',
  },

  loader: {
    background: COLOR_TEXT,
  },

  noUpdates: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },

  table: {
    marginTop: '6px',
    marginBottom: '6px',
    borderCollapse: 'collapse',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  tableRow: {},
  tableCell: {
    padding: theme.spacing(0.5),
    color: COLOR_WHITE,

    '&:first-of-type': {
      textAlign: 'left',
      paddingRight: theme.spacing(1.5),
    },

    '&:last-of-type': {
      textAlign: 'right',
      paddingLeft: theme.spacing(1.5),
    },
  },
}), { name: LastUpdated.name });

export default function LastUpdated (props:LastUpdatedProps) {
  const {
    className,
    playerId,
    hideTooltip = false,
    datetime,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [playerUpdates, setPlayerUpdates] = useState<PlayerUpdate[]>([]);

  function fetchPlayerUpdates () {
    setLoading(true);

    gql(`
      playerUpdatesTooltip(playerId:${playerId}, limit:10) {
        id
        type
        createdAt
      }
    `)
      .then((data:any) => data.playerUpdatesTooltip as PlayerUpdate[])
      .then((playerUpdates:PlayerUpdate[]) => {
        if (playerUpdates && playerUpdates.length) {
          setPlayerUpdates(playerUpdates);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function onOpen () {
    setOpen(true);
    fetchPlayerUpdates();
  }

  return (
    <div
      className={clsx(classes.lastUpdated, className)}
      onClick={hideTooltip ? undefined : onOpen}
    >
      <span className={classes.text}>
        Last Updated: {datetimeToDate(datetime)}
      </span>

      {!hideTooltip && (
        <Tooltip
          open={open}
          interactive={true}
          title={(
            <div className={classes.tooltipContent}>
              <Loader
                className={classes.loader}
                inProgress={loading}
              />

              {(!loading && !playerUpdates?.length) && (
                <div className={classes.noUpdates}>No Updates</div>
              )}

              {(!loading && !!playerUpdates?.length) && (
                <table className={classes.table}>
                  {playerUpdates.map((playerUpdate:PlayerUpdate) => (
                    <tr
                      key={playerUpdate.id}
                      className={classes.tableRow}
                    >
                      <td className={classes.tableCell}>{UPDATE_TYPE_NAME[playerUpdate.type]}</td>
                      <td className={classes.tableCell}>{datetimeToDate(playerUpdate.createdAt)}</td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          )}
          onClose={() => setOpen(false)}
          placement='bottom'
        >
          <InfoOutlinedIcon className={classes.icon} />
        </Tooltip>
      )}
    </div>
  );
}
