import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AttachmentIcon from '../icons/AttachmentIcon';
import SendIcon from '../icons/SendIcon';
import Loader from '../atoms/Loader';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Switch from '../atoms/Switch';
import FilePicker from '../atoms/FilePicker';
import Toast, { ToastType } from '../atoms/Toast';
import DropDown from '../atoms/DropDown';
import MultiSelect from '../molecules/MultiSelect';
import AddUsers from '../molecules/AddUsers';
import gql, { serializeGQLObject } from '../services/gql';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchFBSConferences, fetchFCSConferences } from '../redux/dispatchers/conferences';
import { State } from '../redux/reducers';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_LIGHT_GRAY,
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_GREEN,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Message from '../types/Message';
import AccessLevel from '../types/AccessLevel';
import Conference from '../types/Conference';
import User from '../types/User';
import Team from '../types/Team';

interface NewMessageFormProps {
  messageContent?: Message;
  user?: User;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
}

enum MessageGroup {
  ACCESS_LEVELS = 'accessLevels',
  DIVISIONS = 'divisions',
  COLLEGE_CONFERENCES = 'collegeConferences',
  COLLEGE_TEAMS = 'collegeTeams',
  NFL_TEAMS = 'nflTeams',
  INDIVIDUALS = 'individuals',
}

const GROUPS = [
  {
    content: 'Access Levels',
    value: MessageGroup.ACCESS_LEVELS,
  },
  {
    content: 'Divisions',
    value: MessageGroup.DIVISIONS,
  },
  {
    content: 'College Conferences',
    value: MessageGroup.COLLEGE_CONFERENCES,
  },
  {
    content: 'College Teams',
    value: MessageGroup.COLLEGE_TEAMS,
  },
  {
    content: 'NFL Teams',
    value: MessageGroup.NFL_TEAMS,
  },
  {
    content: 'Individuals',
    value: MessageGroup.INDIVIDUALS,
  },
];

const DIVISION = {
  FBS: 'Div I-A',
  FCS: 'Div I-AA',
};

const useStyles = makeStyles((theme: Theme) => ({
  newMessageForm: {
    position: 'relative',
    padding: theme.spacing(5),
  },

  subject: {
    display: 'flex',
    maxWidth: '400px',
  },
  subjectInputField: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  message: {
    display: 'flex',
    maxWidth: '750px',
    marginTop: theme.spacing(4),
  },
  messageInputField: {
    padding: theme.spacing(0, 0.5),
    lineHeight: 1.4,
  },
  labelClassName: {
    left: theme.spacing(1),
  },
  labelShrinkClassName: {
    left: theme.spacing(0.5),
  },

  filePicker: {
    display: 'flex',
    maxWidth: '400px',
    marginTop: theme.spacing(4),
  },
  filePickerInput: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  fileInputLabel: {
    height: 51,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLOR_LIGHT_GRAY,
    borderBottom: `2px solid ${COLOR_DARK_GRAY}`,
    padding: theme.spacing(2),

    '&:hover': {
      borderColor: COLOR_TEXT,
    },

    '&:active, &:focus': {
      borderColor: COLOR_BLUE
    }
  },
  fileContainer: {
    height: 51,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: FONT_PROXIMA_NOVA,
    position: 'relative',
  },
  attachmentIcon: {
    height: 32,
    width: 32,
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1),
    color: COLOR_DARK_GRAY,
  },

  dropDownWrapper: {
    marginTop: theme.spacing(4),
  },
  dropdown: {
    width: '100%',
    maxWidth: '400px',
    height: 50,
  },
  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },
  dropdownLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },

  switchWrapper: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  switchValue: {
    color: COLOR_TEXT,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
  },

  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  action: {
    width: 'auto',
    minWidth: '160px',
    minHeight: '50px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),

    '&:last-child': {
      marginRight: 0,
    },
  },
  actionCancel: {
    minWidth: '120px',
  },
  actionSend: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLOR_GREEN,
    borderColor: COLOR_GREEN,
  },
  sendIcon: {
    width: '22px',
    height: '22px',
    color: COLOR_WHITE,
    marginRight: theme.spacing(1),
  },

  listOfUsers: {
    marginTop: theme.spacing(4),
    marginBottom: 0,
  },

  [MEDIA.MOBILE]: {
    newMessageForm: {
      padding: theme.spacing(2),
    },
  },
}), { name: NewMessageForm.name });

function NewMessageForm (props: NewMessageFormProps) {
  const {
    messageContent,
    user,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    fetchCollegeTeams,
    fetchNFLTeams,
    fetchFBSConferences,
    fetchFCSConferences,
  } = props;
  const classes = useStyles();

  const history = useHistory();

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [isPlainText, setIsPlainText] = useState<boolean>(false);

  const [selectedGroup, setSelectedGroup] = useState<MessageGroup>();

  const [accessLevels, setAccessLevels] = useState<AccessLevel[]>([]);
  const [selectedAccessLevelIds, setSelectedAccessLevelIds] = useState<number[]>([]);

  const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);

  const [collegeConferences, setCollegeConferences] = useState<Conference[]>([]);
  const [selectedCollegeConferencesIds, setSelectedCollegeConferencesIds] = useState<number[]>([]);

  const [selectedCollegeTeamsIds, setSelectedCollegeTeamsIds] = useState<number[]>([]);
  const [selectedNflTeamsIds, setSelectedNflTeamsIds] = useState<number[]>([]);

  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [userIds, setUserIds] = useState<number[]>([]);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const uniqueUserIds = Array.from(new Set(userIds));
  const HIDE_ATTACHEMENT_FIELD = true;

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canSendMessages) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  useEffect(() => {
    if (selectedGroup && selectedGroup === MessageGroup.INDIVIDUALS) {
      fetchUsers();
    }

    if (selectedGroup && selectedGroup === MessageGroup.ACCESS_LEVELS) {
      fetchAccessLevels();
    }

    if (selectedGroup && selectedGroup === MessageGroup.COLLEGE_CONFERENCES && !collegeConferences.length) {
      fetchFBSConferences();
      fetchFCSConferences();
    }

    if (selectedGroup && selectedGroup === MessageGroup.COLLEGE_TEAMS && !collegeTeams.length) {
      fetchCollegeTeams();
    }

    if (selectedGroup && selectedGroup === MessageGroup.NFL_TEAMS && !nflTeams.length) {
      fetchNFLTeams();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedAccessLevelIds && selectedAccessLevelIds.length) {
      fetchUserIDsForAccessLevels(selectedAccessLevelIds);
    }
  }, [selectedAccessLevelIds]);

  useEffect(() => {
    if (selectedDivisions && selectedDivisions.length) {
      fetchUserIDsForDivisions(selectedDivisions);
    }
  }, [selectedDivisions]);

  useEffect(() => {
    if (selectedCollegeConferencesIds && selectedCollegeConferencesIds.length) {
      fetchUserIdsForConferences(selectedCollegeConferencesIds);
    }
  }, [selectedCollegeConferencesIds]);

  useEffect(() => {
    if (selectedCollegeTeamsIds && selectedCollegeTeamsIds.length) {
      fetchUserIdsForCollegeTeams(selectedCollegeTeamsIds);
    }
  }, [selectedCollegeTeamsIds]);

  useEffect(() => {
    if (selectedNflTeamsIds && selectedNflTeamsIds.length) {
      fetchUserIdsForNflTeams(selectedNflTeamsIds);
    }
  }, [selectedNflTeamsIds]);

  useEffect(() => {
    if (messageContent) {
      const {
        subject,
        text,
        emailStatus,
      } = messageContent;

      if (subject) setSubject(subject);
      if (text) setMessage(text);

      setIsPlainText(emailStatus);
    }
  }, [messageContent]);

  useEffect(() => {
    if (!!fbsConferences?.length) {
      setCollegeConferences(
        [...fbsConferences, ...collegeConferences]
          .sort(sortConferences)
      );
    }
  }, [fbsConferences]);

  useEffect(() => {
    if (!!fcsConferences?.length) {
      setCollegeConferences(
        [...fcsConferences, ...collegeConferences]
          .sort(sortConferences)
      );
    }
  }, [fcsConferences]);

  function fetchAccessLevels () {
    setPageLoading(true);

    gql(`
      accessLevels {
        id
        name
        type
      }
    `)
      .then((data:any) => data.accessLevels as AccessLevel[])
      .then((accessLevels:AccessLevel[]) => {
        if (accessLevels?.length) {
          setAccessLevels(accessLevels.sort((accessLevel1:AccessLevel, accessLevel2:AccessLevel) => {
            const name1 = accessLevel1?.name || '';
            const name2 = accessLevel2?.name || '';

            if (name1 > name2) return 1;
            if (name1 < name2) return -1;
            return 0;
          }));
        }
      })
      .catch(() => {
        showToast('Failed to fetch Access Levels.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUsers () {
    setPageLoading(true);

    gql(`
      users {
        id
        firstName
        lastName
      }
    `)
      .then((data:any) => data.users as User[])
      .then((users:User[]) => {
        if (users?.length) {
          setUsers(users.sort((user1:User, user2:User) => {
            const name1 = `${user1?.firstName} ${user1?.lastName}`;
            const name2 = `${user2?.firstName} ${user2?.lastName}`;

            if (name1 > name2) return 1;
            if (name1 < name2) return -1;
            return 0;
          }));
        }
      })
      .catch(() => {
        showToast('Failed to fetch users.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUserIDsForAccessLevels (accessLevelIds: number[]) {
    setPageLoading(true);

    gql(`
      getUserIds (accessLevels: [${accessLevelIds}])
    `)
      .then((data:any) => data.getUserIds as number[])
      .then((ids:number[]) => setUserIds([...(ids || [])]))
      .catch(() => {
        showToast('Failed to fetch Users Ids.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUserIDsForDivisions (divisions:string[]) {
    setPageLoading(true);

    gql(`
      getUserIds (divisions: [${divisions.map(id => `"${id}"`)}])
    `)
      .then((data:any) => data.getUserIds as number[])
      .then((ids:number[]) => setUserIds([...(ids || [])]))
      .catch(() => {
        showToast('Failed to fetch Users Ids.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUserIdsForConferences (conferences:number[]) {
    setPageLoading(true);

    gql(`
      getUserIds (conferences: [${conferences}])
    `)
      .then((data:any) => data.getUserIds as number[])
      .then((ids:number[]) => setUserIds([...(ids || [])]))
      .catch(() => {
        showToast('Failed to fetch Users Ids.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUserIdsForCollegeTeams (collegeTeamsIds:number[]) {
    setPageLoading(true);

    gql(`
      getUserIds (collegeTeams: [${collegeTeamsIds}])
    `)
      .then((data:any) => data.getUserIds as number[])
      .then((ids:number[]) => setUserIds([...(ids || [])]))
      .catch(() => {
        showToast('Failed to fetch Users Ids.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchUserIdsForNflTeams (nflTeams: number[]) {
    setPageLoading(true);

    gql(`
      getUserIds (nflTeams: [${nflTeams}])
    `)
      .then((data:any) => data.getUserIds as number[])
      .then((ids:number[]) => setUserIds([...(ids || [])]))
      .catch(() => {
        showToast('Failed to fetch Users Ids.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function sortConferences (conference1:Conference, conference2:Conference):number {
    const name1 = conference1?.name || '';
    const name2 = conference2?.name || '';

    if (name1 > name2) return 1;
    if (name1 < name2) return -1;
    return 0;
  }

  function sendMessage () {
    setPageLoading(true);

    const messagePayload = {
      subject,
      text: message,
      to: uniqueUserIds,
      isDraft: false
    } as any;

    if (messageContent && messageContent.id) {
      messagePayload.id = messageContent.id;
    }

    return gql(`
      mutation {
        sendMessage (
          messageToBeSent: ${serializeGQLObject(messagePayload)}
        )
      }
    `)
    .then((data:any) => data.sendMessage as boolean)
    .then((success:boolean) => {
      if (success) {
        showToast('Sent', ToastType.SUCCESS);
        resetMessage();
      } else {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      }
    })
    .catch((error) => {
      console.error(error);
      showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
    })
    .finally(() => setPageLoading(false));
  }

  function saveDraft() {
    setPageLoading(true);

    const draftPayload = {
      subject,
      text: message,
      to: uniqueUserIds,
      isDraft: true
    } as any;

    if (messageContent && messageContent.id) {
      draftPayload.id = messageContent.id;
    }

    return gql(`
      mutation {
        sendMessage (
          messageToBeSent: ${serializeGQLObject(draftPayload)}
        )
      }
    `)
      .then((data:any) => data.sendMessage as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Saved', ToastType.SUCCESS);
          resetMessage();
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function resetMessage () {
    setSubject('');
    setMessage('');
    setIsPlainText(false);
    setSelectedNflTeamsIds([]);
    setSelectedCollegeTeamsIds([]);
    setSelectedCollegeConferencesIds([]);
    setSelectedDivisions([]);
    setSelectedAccessLevelIds([]);
    setSelectedGroup(undefined);
  }

  function onAddUsers () {
    if (selectedUsers && selectedUsers.length) {
      setUserIds([
        ...uniqueUserIds,
        ...selectedUsers.map((user:User) => user.id)
      ]);
    }
  }

  const loading = pageLoading || collegeTeamsLoading || nflTeamsLoading || fbsConferencesLoading || fcsConferencesLoading;

  return (
    <div className={classes.newMessageForm}>
      <Loader inProgress={loading} />

      <Input
        className={classes.subject}
        inputFieldClassName={classes.subjectInputField}
        labelClassName={classes.labelClassName}
        labelShrinkClassName={classes.labelShrinkClassName}
        label='Message Subject'
        value={subject}
        onChange={(subject:string) => setSubject(subject)}
      />

      <Input
        className={classes.message}
        inputFieldClassName={classes.messageInputField}
        labelClassName={classes.labelClassName}
        labelShrinkClassName={classes.labelShrinkClassName}
        label='Message'
        multiLine
        rows={14}
        value={message}
        onChange={(message:string) => setMessage(message)}
      />

      {!HIDE_ATTACHEMENT_FIELD && (
        <FilePicker
          className={classes.filePicker}
          inputClass={classes.filePickerInput}
          fileInputLabelClass={classes.fileInputLabel}
          fileContainerClass={classes.fileContainer}
          name='attachment'
          fileName={fileName}
          onChange={(file:any) => setFileName(file.name)}
        >
          <AttachmentIcon className={classes.attachmentIcon} />
          {fileName === '' ? 'Attachment' : fileName}
        </FilePicker>
      )}

      <div className={classes.dropDownWrapper}>
        <div className={classes.dropdownLabel}>
          Send to everyone in these groups:
        </div>

        <DropDown
          className={classes.dropdown}
          selectorRootClassName={classes.dropdownSelector}
          emptyItem={{
            content: 'Select Group',
            value: '',
          }}
          items={GROUPS}
          value={selectedGroup}
          onChange={(group:MessageGroup) => setSelectedGroup(group)}
        />
      </div>

      {selectedGroup && (
        <>
          {(selectedGroup === MessageGroup.ACCESS_LEVELS) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to everyone with these Access Levels:
              </div>

              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='Select Access Levels'
                items={accessLevels.map((accessLevel:AccessLevel) => (
                  {
                    content: accessLevel.name,
                    value: accessLevel.id.toString(),
                  }
                ))}
                values={selectedAccessLevelIds.map((id:number) => id.toString())}
                onChange={(ids:string[]) => {
                  setSelectedAccessLevelIds(ids.map((id:string) => parseInt(id, 10)));
                }}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.DIVISIONS) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to everyone in these divisions:
              </div>

              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='Select Divisions'
                items={[
                  {content: DIVISION.FBS, value: DIVISION.FBS},
                  {content: DIVISION.FCS, value: DIVISION.FCS},
                ]}
                values={selectedDivisions}
                onChange={(ids:string[]) => setSelectedDivisions(ids)}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.COLLEGE_CONFERENCES) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to everyone in these college conferences:
              </div>

              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='Select College Conferences'
                items={collegeConferences.map((conference: Conference) => ({
                  content: conference.name,
                  value: conference.id.toString(),
                }))}
                values={selectedCollegeConferencesIds.map(id => id.toString())}
                onChange={(conferences:string[]) => {
                  setSelectedCollegeConferencesIds(conferences.map((conferenceId:string) => parseInt(conferenceId, 10)));
                }}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.COLLEGE_TEAMS) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to everyone in these college teams:
              </div>

              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='Select College Teams'
                items={collegeTeams.map((team:Team) => ({
                  content: team.name,
                  value: team.id.toString(),
                }))}
                values={selectedCollegeTeamsIds.map(id => id.toString())}
                onChange={(teamsIds:string[]) => {
                  setSelectedCollegeTeamsIds(teamsIds.map(id => parseInt(id, 10)));
                }}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.NFL_TEAMS) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to everyone in these NFL teams:
              </div>

              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='Select NFL Teams'
                items={nflTeams.map((team:Team) => ({
                  content: team.name,
                  value: team.id.toString(),
                }))}
                values={selectedNflTeamsIds.map(id => id.toString())}
                onChange={(teamsIds:string[]) => {
                  setSelectedNflTeamsIds(teamsIds.map(id => parseInt(id, 10)));
                }}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.INDIVIDUALS) && (
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropdownLabel}>
                Send to these individuals:
              </div>

              <AddUsers
                className={clsx(classes.dropdown, classes.addUser)}
                users={users}
                selectedUsers={selectedUsers}
                onSelect={setSelectedUsers}
                onAdd={onAddUsers}
              />
            </div>
          )}

          {(selectedGroup === MessageGroup.INDIVIDUALS && !!selectedUsers.length) && (
            <p className={classes.listOfUsers}>
              <strong>Selected Individuals:&nbsp;</strong>
              {selectedUsers.map((user:User) => `${user.firstName} ${user.lastName}`).join(', ')}
            </p>
          )}

          {(selectedGroup && selectedGroup !== MessageGroup.INDIVIDUALS) && (
            <p className={classes.listOfUsers}>
              <strong>Total user accounts:&nbsp;</strong>{uniqueUserIds.length}
            </p>
          )}
        </>
      )}

      <div className={classes.switchWrapper}>
        <Switch
          checked={isPlainText}
          onChange={() => {setIsPlainText(!isPlainText)}}
        />

        <span className={classes.switchValue}>
          Plain Text Email
          <strong>{isPlainText ? 'On' : 'Off'}</strong>
        </span>
      </div>

      <div className={classes.actions}>
        <Button
          className={clsx(classes.action, classes.actionCancel)}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>

        <Button
          className={classes.action}
          primary
          disabled={loading || !subject || !message}
          onClick={saveDraft}
        >
          Save as Draft
        </Button>

        <Button
          className={clsx(classes.action, classes.actionSend)}
          primary
          disabled={loading || !subject || !message || uniqueUserIds.length === 0}
          onClick={sendMessage}
        >
          <SendIcon className={classes.sendIcon} />
          Send Message
        </Button>
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchFBSConferences,
      fetchFCSConferences,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMessageForm);
