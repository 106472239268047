import { PlayerColumn } from './PlayerColumn';
import { Order } from './Order';

export enum HSFilter {
  HS_CLASS_YEAR = 'hsClassYear',
  NEW_PLAYER = 'newPlayer',
  ARCHIVES = 'archives',
  PREP = 'prep',
  HS_STATE = 'hsState',
  INTERNATIONAL = 'international',
  PLAYER_NAMES = 'playerNames',
  HS_HEIGHT = 'hsHeight',
  HS_WEIGHT = 'hsWeight',
  HS_WINGSPAN = 'hsWingspan',
  HS_HANDSIZE = 'hsHandSize',
  HS_ARM_LENGTH = 'hsArmLength',
  OTHER_SPORTS = 'multiSports',
  NONE = 'None',
  BASEBALL = 'Baseball',
  BASKETBALL = 'Basketball',
  GOLF = 'Golf',
  HOCKEY = 'Hockey',
  LACROSS = 'Lacrosse',
  POWER_LIFTING = 'Powerlifting',
  RUGBY = 'Rugby',
  SOCCER = 'Soccer',
  SWIMMING = 'Swimming',
  TENNIS = 'Tennis',
  TRACK = 'Track',
  VOLLEYBALL = 'Volleyball',
  WRESTLING = 'Wrestling',
  SPEED = 'speed',
  POWER = 'power',
  PAI = 'pai',
  COMBINE = 'combine',
  GPA = 'gpa',
  SAT = 'sat',
  ACT = 'act',
  RATING_247 = 'rating247',
  OFFER_COUNT = 'offerCount',
  OFFER_SCHOOL = 'offerSchool',
  OFFER_CONFERENCE = 'offerConference',
  FBS = 'FBS',
  FCS = 'FCS',
  HIGHEST_LEVEL_OFFER = 'highestLevelOffer',
  HIGHEST_LEVEL_OFFER_P5 = 'highestLevelOfferP5',
  HIGHEST_LEVEL_OFFER_G5 = 'highestLevelOfferG5',
  HIGHEST_LEVEL_OFFER_FCS = 'highestLevelOfferFCS',
  UNCOMMITTED = 'uncommitted',
  COMMITTED_SCHOOL = 'committedSchool',
  TRACK_AND_FIELD_DATA_YEAR = 'trackAndFieldDataYear',
  SPRINTS = 'sprints',
  HURDLES = 'hurdles',
  MIDDLE_DISTANCE = 'middleDistance',
  RELAYS = 'relays',
  THROWING_EVENTS = 'throwingEvents',
  JUMPING_EVENTS = 'jumpingEvents',
  COMBINE_YEAR = 'combineYear',
  COMBINE_EVENT = 'combineEvent',
  COMBINE_CITY = 'combineCity',
  COMBINE_40_M_DASH = 'combine40mDash',
  COMBINE_10_SPLIT = 'combine10Split',
  COMBINE_MPH = 'combineMPH',
  COMBINE_SHUTTLE = 'combineShuttle',
  COMBINE_3_CONE = 'combine3Cone',
  COMBINE_VERTICAL_JUMP = 'combineVerticalJump',
  COMBINE_BROAD_JUMP = 'combineBroadJump',
  COMBINE_POWERTOSS = 'combinePowerToss',
  COMBINE_WINGSPAN = 'combineWingspan',
  SPARQ = 'sparq',
  S_55_METERS = '55Meters',
  S_60_METERS = '60Meters',
  S_100_METERS = '100Meters',
  S_200_METERS = '200Meters',
  S_300_METERS = '300Meters',
  S_400_METERS = '400Meters',
  HH_55_METERS = '55mHighHurdles',
  HH_60_METERS = '60mHighHurdles',
  HH_110_METERS = '110mHighHurdles',
  H_300_INT = '300iHurdles',
  H_400_METERS = '400mHurdles',
  R_400_METERS = '400mRelay',
  R_800_METERS = '800mRelay',
  R_1600_METERS = '1600mRelay',
  R_3200_METERS = '3200mRelay',
  LONG_JUMP = 'longJump',
  TRIPLE_JUMP = 'tripleJump',
  HIGH_JUMP = 'highJump',
  POLE_VAULT_JUMP = 'poleVaultJump',
  SHOTPUT = 'shotput',
  DISCUS = 'discus',
  JAVELIN = 'javelin',
  MD_800_METERS = '800Meters',
  HS_MPH = 'hsMPH',
}

export enum NFLFilter {
  HEIGHT = 'nflHeight',
  WEIGHT = 'nflWeight',
  DRAFT_YEAR = 'draftYear',
  DRAFT_ROUND = 'draftRound',
  AWARDS = 'nflAwards',
  ALL_PRO = 'allPro',
  PRO_BOWL = 'proBowl',
  DRAFT_TEAM = 'draftTeam',
  UDFA = 'UDFA',
  PRO_COMBINE_SCORE = 'nflCombineScore',
  PPI = 'ppi',
}

export enum CollegeFilter {
  HEIGHT = 'collegeHeight',
  WEIGHT = 'collegeWeight',
  RECRUITING_CLASS = 'recruitingClass',
  COLLEGE_NAME = 'collegeName',
  COLLEGE_LEVEL = 'collegeLevel',
  FBS_CONFERENCE = 'fbsConference',
  FCS_CONFERENCE = 'fcsConference',
  TRANSFER_PORTAL = 'transferPortal',
  AWARDS = 'collegeAwards',
  ALL_AMERICAN = 'allAmerican',
  ALL_CONFERENCE = 'allConference',
  WATCH_LIST = 'watchList',
  SENIOR_BOWL = 'seniorBowl',
  CGS_SHOWCASE = 'cgsShowcase',
  EAST_WEST_SHRINE = 'eastWestShrine',
  TRANSFER_YEARS = 'transferYears',
}

export enum PlayerPosition {
  PRIMARY_POSITION = 'primaryPosition',
  SECONDARY_POSITION = 'secondaryPosition',
}

export const SEARCH_COLUMNS_SORTING_RULES = {
  // sprints
  [HSFilter.S_55_METERS]: PlayerColumn.TRACK_55M,
  [HSFilter.S_60_METERS]: PlayerColumn.TRACK_60M,
  [HSFilter.S_100_METERS]: PlayerColumn.TRACK_100M,
  [HSFilter.S_200_METERS]: PlayerColumn.TRACK_200M,
  [HSFilter.S_300_METERS]: PlayerColumn.TRACK_300M,
  [HSFilter.S_400_METERS]: PlayerColumn.TRACK_400M,

  // hurdles
  [HSFilter.HH_55_METERS]: PlayerColumn.TRACK_55HH,
  [HSFilter.HH_60_METERS]: PlayerColumn.TRACK_60HH,
  [HSFilter.HH_110_METERS]: PlayerColumn.TRACK_110HH,
  [HSFilter.H_300_INT]: PlayerColumn.TRACK_300IH,
  [HSFilter.H_400_METERS]: PlayerColumn.TRACK_400H,

  // middle distance
  [HSFilter.MD_800_METERS]: PlayerColumn.TRACK_800M,

  // relays
  [HSFilter.R_400_METERS]: PlayerColumn.TRACK_400R,
  [HSFilter.R_800_METERS]: PlayerColumn.TRACK_800R,
  [HSFilter.R_1600_METERS]: PlayerColumn.TRACK_1600R,
  [HSFilter.R_3200_METERS]: PlayerColumn.TRACK_3200R,

  // throwing events
  [HSFilter.SHOTPUT]: PlayerColumn.TRACK_SHOTPUT,
  [HSFilter.DISCUS]: PlayerColumn.TRACK_DISCUS,
  [HSFilter.JAVELIN]: PlayerColumn.TRACK_JAVELIN,

  // jumping events
  [HSFilter.LONG_JUMP]: PlayerColumn.TRACK_LONG_JUMP,
  [HSFilter.TRIPLE_JUMP]: PlayerColumn.TRACK_TRIPLE_JUMP,
  [HSFilter.HIGH_JUMP]: PlayerColumn.TRACK_HIGH_JUMP,
  [HSFilter.POLE_VAULT_JUMP]: PlayerColumn.TRACK_POLE_VAULT,

  // combine events
  [HSFilter.COMBINE_40_M_DASH]: PlayerColumn.COMBINE_40M_DASH,
  [HSFilter.COMBINE_10_SPLIT]: PlayerColumn.COMBINE_10_SPLIT,
  [HSFilter.COMBINE_MPH]: PlayerColumn.COMBINE_MPH,
  [HSFilter.COMBINE_SHUTTLE]: PlayerColumn.COMBINE_SHUTTLE,
  [HSFilter.COMBINE_3_CONE]: PlayerColumn.COMBINE_3_CONE,
  [HSFilter.COMBINE_VERTICAL_JUMP]: PlayerColumn.COMBINE_VERTICAL_JUMP,
  [HSFilter.COMBINE_BROAD_JUMP]: PlayerColumn.COMBINE_BROAD_JUMP,
  [HSFilter.COMBINE_POWERTOSS]: PlayerColumn.COMBINE_POWER_TOSS,
};

export const SEARCH_COLUMNS_ORDERING_RULES = {
  // sprints
  [HSFilter.S_55_METERS]: Order.asc,
  [HSFilter.S_60_METERS]: Order.asc,
  [HSFilter.S_100_METERS]: Order.asc,
  [HSFilter.S_200_METERS]: Order.asc,
  [HSFilter.S_300_METERS]: Order.asc,
  [HSFilter.S_400_METERS]: Order.asc,

  // hurdles
  [HSFilter.HH_55_METERS]: Order.asc,
  [HSFilter.HH_60_METERS]: Order.asc,
  [HSFilter.HH_110_METERS]: Order.asc,
  [HSFilter.H_300_INT]: Order.asc,
  [HSFilter.H_400_METERS]: Order.asc,

  // middle distance
  [HSFilter.MD_800_METERS]: Order.asc,

  // relays
  [HSFilter.R_400_METERS]: Order.asc,
  [HSFilter.R_800_METERS]: Order.asc,
  [HSFilter.R_1600_METERS]: Order.asc,
  [HSFilter.R_3200_METERS]: Order.asc,

  // throwing events
  [HSFilter.SHOTPUT]: Order.desc,
  [HSFilter.DISCUS]: Order.desc,
  [HSFilter.JAVELIN]: Order.desc,

  // jumping events
  [HSFilter.LONG_JUMP]: Order.desc,
  [HSFilter.TRIPLE_JUMP]: Order.desc,
  [HSFilter.HIGH_JUMP]: Order.desc,
  [HSFilter.POLE_VAULT_JUMP]: Order.desc,

  // combine events
  [HSFilter.COMBINE_40_M_DASH]: Order.asc,
  [HSFilter.COMBINE_10_SPLIT]: Order.asc,
  [HSFilter.COMBINE_MPH]: Order.asc,
  [HSFilter.COMBINE_SHUTTLE]: Order.asc,
  [HSFilter.COMBINE_3_CONE]: Order.asc,
  [HSFilter.COMBINE_VERTICAL_JUMP]: Order.desc,
  [HSFilter.COMBINE_BROAD_JUMP]: Order.desc,
  [HSFilter.COMBINE_POWERTOSS]: Order.desc,
};
