import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import SideDrawer from '../organisms/SideDrawer';
import { fetchUser } from '../redux/dispatchers/user';
import { State } from '../redux/reducers';
import { fetchConfigurations } from '../redux/dispatchers/configurations';
import { fetchAchievements } from '../redux/dispatchers/achievements';
import { setSideDrawerOpenAction } from '../redux/actions/ui';
import { resetReduxStoreAction } from '../redux/actions/root';
import { verifyToken } from '../services/user';
import { saveCurrentPath } from '../services/path';
import { updatePageTitle } from '../services/page-title';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { isDesktopLarge, ScreenSize } from '../services/screen-size';
import { getQueryParameter } from '../services/query-parameters';
import { trackPageView } from '../services/google-analytics';
import { COLOR_BACKGROUND_COLD } from '../styles/colors';
import User from '../types/User';

interface PageProps {
  userId?: number;
  children: any;
  printed?: boolean;
  title?: string;
  screenSize: ScreenSize;
  fetchUser: (userId:number) => void;
  fetchConfigurations: () => void;
  fetchAchievements: () => void;
  setSideDrawerOpenAction: (open:boolean) => void,
  resetReduxStoreAction: () => void;
}

const useStyles = makeStyles(() => ({
  page: {
    width: '100%',
    display: 'flex',
    background: COLOR_BACKGROUND_COLD,
  },
}), { name: Page.name });

function Page (props:PageProps) {
  const {
    children,
    title,
    screenSize,
    fetchUser,
    fetchConfigurations,
    fetchAchievements,
    setSideDrawerOpenAction,
    resetReduxStoreAction,
    printed = false,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const token = getQueryParameter('token');

  useEffect(() => {
    verifyToken(clientStorage.get(StorageKey.TOKEN) || token)
      .then((user:User) => {
        if (user?.id) {
          fetchUser(user.id);
        }
      })
      .catch(onTokenInvalid);

    fetchConfigurations();
    fetchAchievements();
  }, [token]);

  useEffect(() => {
    updatePageTitle(title || '');
    trackPageView();
  }, []);

  useEffect(() => {
    if (!isDesktopLarge(screenSize)) {
      setSideDrawerOpenAction(false);
    }
  }, []);

  function onTokenInvalid () {
    saveCurrentPath();
    clientStorage.clearAll();
    resetReduxStoreAction();
    history.push('/login');
  }

  return (
    <div className={classes.page}>
      <SideDrawer printed={printed} />

      {children}
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    screenSize: state.ui.screenSize,
  };
};

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUser,
      fetchConfigurations,
      fetchAchievements,
      setSideDrawerOpenAction,
      resetReduxStoreAction,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page);
