import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { isArray } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import VisibilitySensor from 'react-visibility-sensor';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '../atoms/Checkbox';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import Slider from '../atoms/Slider';
import Toast, { ToastType } from '../atoms/Toast';
import MultiSelect from '../molecules/MultiSelect';
import MultiSelectWithSliders from '../molecules/MultiSelectWithSliders';
import { updateUserPreferences } from '../redux/dispatchers/user';
import { isDuringRollover } from '../services/user';
import gql from '../services/gql';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_TEXT,
  COLOR_BACKGROUND_LIGHT,
  COLOR_BORDER,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_LIGHT_ORANGE,
} from '../styles/colors';
import StateType from '../types/State';
import { HSYearsAccessType } from '../types/AccessLevel';
import User from '../types/User';
import {
  PositionGroup,
  OffensivePosition,
  SpecialPosition,
  DefensivePosition,
} from '../types/Position';
import Conference from '../types/Conference';
import MEDIA from '../styles/media';

interface MyPreferencesProps {
  className?: string;
  user:User;
  states: StateType[];
  fbsConferences: Conference[];
  fcsConferences: Conference[];
  updateUserPreferences: (preferences:any) => void;
}

enum Score {
  PAI = 'pai',
  COMBINE = 'combine',
  STARS = 'stars',
}

const SCORE_TITLE:{[key: string]: string} = {
  [Score.PAI]: 'PAI',
  [Score.COMBINE]: 'Combine',
  [Score.STARS]: 'Stars',
};

const SCORE_RANGES:{[key:string]: number[]} = {
  [Score.PAI]: [0, 5],
  [Score.COMBINE]: [0, 5],
  [Score.STARS]: [0, 5],
};

const POSITION_GROUP_TITLE:{[key: string]: string} = {
  [PositionGroup.OFFENSE]: 'Offense',
  [PositionGroup.DEFENSE]: 'Defense',
  [PositionGroup.SPECIAL]: 'Specialist',
};

const PLAYER_CLASS_TITLE = {
  [HSYearsAccessType.FRESHMAN]: 'Freshman',
  [HSYearsAccessType.SOPHOMORE]: 'Sophomore',
  [HSYearsAccessType.JUNIOR]: 'Junior',
  [HSYearsAccessType.SENIOR]: 'Senior',
};

const CONFERENCES = {
  FBS: 'FBS',
  FCS: 'FCS',
};

const useStyles = makeStyles(theme => ({
  myPreferences: {
    position: 'relative',
  },
  myPreferencesWrapper: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    marginBottom: theme.spacing(4),
  },

  disablingCover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'not-allowed',
    top: 0,
    left: 0,
    background: 'rgb(255 255 255 / 60%)',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  disablingCoverTitle: {
    padding: theme.spacing(2, 3),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    color: COLOR_WHITE,
    background: COLOR_LIGHT_ORANGE,
    borderRadius: 6,
  },

  myPreferencesContent: {
    padding: theme.spacing(2.5),
  },

  header: {
    color: COLOR_TEXT,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    margin: 0,
    padding: theme.spacing(2.5),
    lineHeight: 1,
    ...theme.typography.h2,
  },
  dropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  dropDowns: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDown: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    position: 'relative',
    marginRight: theme.spacing(2),
  },
  dropDownFull: {
    maxWidth: 'none',
  },
  dropDownSelect: {
    height: '50px',
    lineHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },

  labelText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },
  multiSelect: {
    width: '100%',
    maxWidth: '300px',
  },
  multiSelectSelector: {
    height: '50px',
  },
  multiSelectSelectorRoot: {
    height: '28px',
    lineHeight: '28px',
    fontSize: theme.typography.pxToRem(16),
  },

  checkboxWrapper: {
    flexShrink: 0,
    margin: theme.spacing(1,0,1,4),
  },
  internationalCheckbox: {
    marginRight: theme.spacing(2),
  },
  slider: {
    width: '290px',
    display: 'block',
    padding: theme.spacing(3, 0),
    margin: theme.spacing(0, 2, 0, 1),
    boxSizing: 'border-box',
  },

  advancedPreferenceButton: {
    height: '50px',
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    border: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
  },
  icon: {
    height: '18px',
    width: '24px',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '18px',
    marginLeft: 'auto',
    textAlign: 'right',
  },
  advancedPreferenceWrapper: {
    width: '100%',
    border: `1px solid ${COLOR_BORDER}`,
    padding: theme.spacing(2),
  },
  advancedPreferenceText: {
    marginLeft: 'auto',
  },

  actions: {
    display: 'flex',
  },
  actionButton: {
    width: '120px',
    height: '50px',

    '&:first-of-type': {
      marginRight: theme.spacing(3),
    }
  },

  [MEDIA.MOBILE]: {
    dropDowns: {
      flexWrap: 'wrap',
    },

    dropDown: {
      flexWrap: 'wrap',
      maxWidth: 'none',
      marginRight: 0,
      marginBottom: theme.spacing(2),

      '&:last-of-type': {
        marginTop: 0,
      }
    },

    checkboxWrapper: {
      width: '100%',
      margin: theme.spacing(2,0,0,0),
    },
  },
}), { name: MyPreferences.name });

function MyPreferences (props:MyPreferencesProps) {
  const {
    className,
    user,
    states = [],
    fbsConferences,
    fcsConferences,
    updateUserPreferences,
  } = props;
  const classes = useStyles();

  const [userAccessYears, setUserAccessYears] = useState<HSYearsAccessType[]>([]);
  const [offerConferences, setOfferConferences] = useState<{content:string, value:string}[]>([]);
  const [commitConferences, setCommitConferences] = useState<{content:string, value:string}[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [resetPreferences, setResetPreferences] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const [isInternational, setIsInternational] = useState<boolean>(false);
  const [showAdvancedPreferences, setShowAdvancedPreferences] = useState<boolean>(false);

  const [selectedPositionGroups, setSelectedPositionGroups] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [selectedScoreTypes, setSelectedScoreTypes] = useState<string[]>([]);
  const [selectedScoreRanges, setSelectedScoreRanges] = useState<any>(SCORE_RANGES);
  const [selectedOfferDivisions, setSelectedOfferDivisions] = useState<string[]>([]);
  const [selectedOfferConferences, setSelectedOfferConferences] = useState<string[]>([]);
  const [selectedCommitDivisions, setSelectedCommitDivisions] = useState<string[]>([]);
  const [selectedCommitConferences, setSelectedCommitConferences] = useState<string[]>([]);
  const [offerConferencesRange, setOfferConferencesRange] = useState<number | number[]>([0, 50]);
  const [commitConferencesRange, setCommitConferencesRange] = useState<number | number[]>([0, 50]);

  const [visibilityTracked, setVisibilityTracked] = useState<boolean>(false);

  useEffect(() => {
    let userAccessYears = get(user, 'accessLevel.hsYearsAugust1', []);
    if (isDuringRollover()) {
      userAccessYears = get(user, 'accessLevel.hsYearsMarch1', []);
    }

    setUserAccessYears(userAccessYears);
  }, [user]);

  useEffect(() => {
    if (user) {
      processUserPreferences(user.preferences || {});
    }
  }, [user, resetPreferences]);

  useEffect(() => {
    let allConferences:{content:string, value:string}[] = [];
    const allDivisions = selectedOfferDivisions.length ? selectedOfferDivisions : [CONFERENCES.FBS, CONFERENCES.FCS];

    allDivisions.forEach((division:string) => (
      conferenceMapping[division].forEach((conference:Conference) => {
        const id = `${conference.id}`;
        allConferences.push({content: conference.abbr, value: id.toString()})
      })
    ));
    setOfferConferences(allConferences);
  }, [selectedOfferDivisions, fbsConferences, fcsConferences]);

  useEffect(() => {
    let allConferences:{content:string, value:string}[] = [];
    const allDivisions = selectedCommitDivisions.length ? selectedCommitDivisions : [CONFERENCES.FBS, CONFERENCES.FCS];

    allDivisions.forEach((division:string) => (
      conferenceMapping[division].forEach((conference:Conference) => {
        allConferences.push({content: conference.abbr, value: `${conference.id}`})
      })
    ));
    setCommitConferences(allConferences);
  }, [selectedCommitDivisions, fbsConferences, fcsConferences,]);

  useEffect(() => {
    if (selectedOfferDivisions.length &&
      selectedOfferConferences.length &&
      offerConferences.length
    ) {
      let selectedConferences:string[] = [];
      if (selectedOfferDivisions.includes(CONFERENCES.FBS)) {
        const selectedFBSOfferConferences = fbsConferences
          .filter((conference:Conference) => selectedOfferConferences.includes(`${conference.id}`))
          .map((conference:Conference) => `${conference.id}`);
        selectedConferences = [...selectedConferences, ...selectedFBSOfferConferences];
      }
      if (selectedOfferDivisions.includes(CONFERENCES.FCS)) {
        const selectedFCSOfferConferences = fcsConferences
          .filter((conference:Conference) => selectedOfferConferences.includes(`${conference.id}`))
          .map((conference:Conference) => `${conference.id}`);
        selectedConferences = [...selectedConferences, ...selectedFCSOfferConferences];
      }
      setSelectedOfferConferences(selectedConferences);
    }

    if (!selectedOfferDivisions.length && selectedOfferConferences.length) {
      setSelectedOfferConferences([]);
      setOfferConferencesRange([]);
    }
  }, [selectedOfferDivisions.length, selectedOfferConferences.length]);

  useEffect(() => {
    if (selectedCommitDivisions.length &&
      selectedCommitConferences.length &&
      commitConferences.length
    ) {
      let selectedConferences:string[] = [];
      if (selectedCommitDivisions.includes(CONFERENCES.FBS)) {
        const selectedFBSCommitConferences = fbsConferences
          .filter((conference:Conference) => selectedCommitConferences.includes(`${conference.id}`))
          .map((conference:Conference) => `${conference.id}`);
        selectedConferences = [...selectedConferences, ...selectedFBSCommitConferences];
      }
      if (selectedCommitDivisions.includes(CONFERENCES.FCS)) {
        const selectedFCSCommitConferences = fcsConferences
          .filter((conference:Conference) => selectedCommitConferences.includes(`${conference.id}`))
          .map((conference:Conference) => `${conference.id}`);
        selectedConferences = [...selectedConferences, ...selectedFCSCommitConferences];
      }
      setSelectedCommitConferences(selectedConferences);
    }

    if (!selectedCommitDivisions.length && selectedCommitConferences.length) {
      setSelectedCommitConferences([]);
      setCommitConferencesRange([]);
    }
  }, [selectedCommitDivisions.length, selectedCommitConferences.length]);

  function preparePreferences() {
    const offensivePositions:string[] = Object.values(OffensivePosition);
    const defensivePositions:string[] = Object.values(DefensivePosition);
    const specialPositions:string[] = Object.values(SpecialPosition);

    const groupMapping:{[key:string]: string[]} = {
      'offense': selectedPositions.filter(position => offensivePositions.includes(position)),
      'defense': selectedPositions.filter(position => defensivePositions.includes(position)),
      'special': selectedPositions.filter(position => specialPositions.includes(position)),
    };

    const position = selectedPositionGroups.map((group:string) => ({
        type: group,
        code: groupMapping[group]
      }
    ));

    const scores:{[key:string] : number[]} = {};

    selectedScoreTypes.forEach((scoreKey:string) => {
      scores[scoreKey] = selectedScoreRanges[scoreKey] || SCORE_RANGES[scoreKey];
    });


    return ({
      scores,
      position,
      international: isInternational,
      location: selectedLocations,
      class: selectedClasses,
      offers: {
        divisions: selectedOfferDivisions,
        range: !!selectedOfferDivisions.length ? (isArray(offerConferencesRange) ? [...offerConferencesRange] : [0, 50]) : [],
        conferences: !!selectedOfferDivisions.length ? (selectedOfferConferences.map(conference => conference)) : [],
      },
      commits: {
        divisions: selectedCommitDivisions,
        range: !!selectedCommitDivisions.length ? (isArray(commitConferencesRange) ? [...commitConferencesRange] : [0, 50]) : [],
        conferences: !!selectedCommitDivisions.length ? (selectedCommitConferences.map(conference => conference)) : [],
      }
    });
  }

  function savePreferences () {
    setLoading(true);

    const preferences = preparePreferences();
    const {
      position,
      scores,
      location,
      international,
      class: classYear,
      offers,
      commits,
    } = preferences;

    let score = '';
    Object.keys(scores).map(key => (
      score = `${score}
        ${key}: [${scores[key]}]
      `
    ));

    gql(`
      mutation {
        savePreferences (
          preferences: {
            position: [${position.map((position:any) => (`{
              type: "${position.type}",
              code: [${position['code'].map((position:string) => `"${position}"`)}]
            }`)
            )}],
            location: [${location.map(location => `"${location}"`)}],
            international: ${international},
            class: [${classYear.map(playerClass => `"${playerClass}"`)}],
            scores: {
              ${score}
            },
            offers: {
              divisions: [${offers.divisions.map(division => `"${division}"`)}],
              range: [${offers.range}],
              conferences: [${offers.conferences}],
            },
            commits: {
              divisions: [${commits.divisions.map(division => `"${division}"`)}],
              range: [${commits.range}],
              conferences: [${commits.conferences}],
            }
          }
        )
      }`
    )
      .then((data:any) => data.savePreferences as boolean)
      .then((success:boolean) => {
        if (success) {
          updateUserPreferences(preferences);
          showToast('Saved', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function processUserPreferences (preferences:any) {
    const {
      location,
      class: classYears,
      international,
      position,
      scores,
      offers,
      commits,
    } = preferences;

    setSelectedLocations(location);
    setSelectedClasses(classYears);
    setIsInternational(international);

    extractPlayerPositions(position);

    extractPlayerScores(scores);
    extractOffersAndRanges(offers);
    extractCommitsAndRanges(commits);
  }

  function extractPlayerPositions (playerPositions:{ code:string; type:string; }[]) {
    if (!playerPositions?.length) return;

    let positions:string[] = (playerPositions || []).flatMap(playerPosition => playerPosition?.code);
    const positionGroups:string[] = (playerPositions || []).map(playerPosition => playerPosition?.type);

    if (positionGroups.includes(PositionGroup.OFFENSE)) {
      const offensivePositions:string[] = Object.values(OffensivePosition);
      positions = positions.filter((position:string) => offensivePositions.includes(position));
    }

    if (positionGroups.includes(PositionGroup.DEFENSE)) {
      const defensivePositions:string[] = Object.values(DefensivePosition);
      positions = positions.filter((position:string) => defensivePositions.includes(position));
    }

    if (positionGroups.includes(PositionGroup.SPECIAL)) {
      const specialPositions:string[] = Object.values(SpecialPosition);
      positions = positions.filter((position:string) => specialPositions.includes(position));
    }

    setSelectedPositions(positions);
    setSelectedPositionGroups(positionGroups);
  }

  function extractPlayerScores (scores:any) {
    if (!scores) return;

    setSelectedScoreTypes(Object.keys(scores).map(score => score));

    let scoreRanges = {};
    Object.keys(scores).forEach((scoreType:string) => (
      scoreRanges = {
        ...scoreRanges,
        [scoreType]: scores[scoreType]
      }
    ))
    setSelectedScoreRanges({...scoreRanges});
  }

  function extractOffersAndRanges (offers:any) {
    if (!offers) return;

    setSelectedOfferDivisions(offers.divisions);
    setSelectedOfferConferences([...offers.conferences.map((conference:string) => `${conference}`)]);
    setOfferConferencesRange((offers.range && offers.range.length) ? offers.range : [0, 50]);
  }

  function extractCommitsAndRanges (commits:any) {
    if (!commits) return;

    setSelectedCommitDivisions(commits.divisions);
    setSelectedCommitConferences([...commits.conferences.map((conference:string) => `${conference}`)]);
    setCommitConferencesRange((commits.range && commits.range.length) ? commits.range : [0, 50]);
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function getPositionItems () {
    let allPositions:any = [];

    const sortPositions = (first:any, second:any) => {
      if (first.content < second.content) return -1;
      if (first.content > second.content) return 1;

      return 0;
    };

    if (selectedPositionGroups.includes(PositionGroup.OFFENSE)) {
      allPositions = [...allPositions, ...offensivePositions]
        .sort(sortPositions);
    }
    if (selectedPositionGroups.includes(PositionGroup.DEFENSE)) {
      allPositions = [...allPositions, ...defensivePositions]
        .sort(sortPositions);
    }
    if (selectedPositionGroups.includes(PositionGroup.SPECIAL)) {
      allPositions = [...allPositions, ...specialPositions]
        .sort(sortPositions);
    }

    return allPositions;
  }

  const positionGroups = Object.values(PositionGroup).map((positionGroup:string) => ({
    content: POSITION_GROUP_TITLE[positionGroup],
    value: positionGroup
  }));

  const offensivePositions = Object.values(OffensivePosition).map(position => ({
    content: position,
    value: position
  }));

  const defensivePositions = Object.values(DefensivePosition).map(position => ({
    content: position,
    value: position
  }));

  const specialPositions = Object.values(SpecialPosition).map(position => ({
    content: position,
    value: position
  }));

  const playerClasses = userAccessYears && userAccessYears.map(accessYear => ({
    content: PLAYER_CLASS_TITLE[accessYear],
    value: accessYear,
  }));

  const conferenceMapping:{[key:string]: Conference[]} = {
    FBS: fbsConferences,
    FCS: fcsConferences,
  };

  function onVisible (visible:boolean) {
    if (visible && !visibilityTracked) {
      setVisibilityTracked(true);

      gql(`
        mutation {
          trackPreferencesVisit
        }`
      )
        .then((data:any) => data.trackPreferencesVisit as boolean)
        .then((tracked:boolean) => {
          if (tracked) {
            showToast('The Preferences section is still under development. But we give you a point for visiting it. Thanks.', ToastType.INFO);
          }
        });
    }
  }

  return (
    <>
      <VisibilitySensor
        active={!visibilityTracked}
        partialVisibility
        onChange={onVisible}
      >
        <div
          className={clsx(classes.myPreferences, className)}
          title='This section is under development'
        >
          <div className={classes.disablingCover}>
            <div className={classes.disablingCoverTitle}>Under Development</div>
          </div>

          <Loader inProgress={loading} />

          <div className={classes.myPreferencesWrapper}>
            <h2 className={classes.header}>
              My Preferences
            </h2>

            <div className={classes.myPreferencesContent}>
              <div className={classes.dropdownWrapper}>
                {!!selectedPositionGroups.length && (
                  <div className={classes.labelText}>Position</div>
                )}

                <div className={classes.dropDowns}>
                  <div className={classes.dropDown}>
                    <MultiSelect
                      className={classes.multiSelect}
                      selectorClassName={classes.multiSelectSelector}
                      selectorRootClassName={classes.multiSelectSelectorRoot}
                      labelWhenSelectedNone='Position'
                      labelWhenSelectedAll='All Positions'
                      items={positionGroups}
                      values={selectedPositionGroups}
                      onChange={(positions:string[]) => setSelectedPositionGroups(positions)}
                    />
                  </div>

                  {!!selectedPositionGroups.length && (
                    <div className={classes.dropDown}>
                      <MultiSelect
                        className={classes.multiSelect}
                        selectorClassName={classes.multiSelectSelector}
                        selectorRootClassName={classes.multiSelectSelectorRoot}
                        labelWhenSelectedNone='Positions'
                        items={getPositionItems()}
                        values={selectedPositions}
                        onChange={(positions:string[]) => setSelectedPositions(positions)}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.dropdownWrapper}>
                {!!selectedLocations?.length && (
                  <div className={classes.labelText}>Location</div>
                )}

                <div className={clsx(classes.dropDown, classes.dropDownFull)}>
                  <MultiSelect
                    className={classes.multiSelect}
                    selectorClassName={classes.multiSelectSelector}
                    selectorRootClassName={classes.multiSelectSelectorRoot}
                    labelWhenSelectedNone='Location'
                    items={states.map((state:StateType) => ({
                      content: state.name,
                      value: state.code
                    }))}
                    values={selectedLocations}
                    onChange={(locations:string[]) => setSelectedLocations(locations)}
                  />

                  <span className={classes.checkboxWrapper}>
                    <Checkbox
                      className={classes.internationalCheckbox}
                      checked={isInternational}
                      onChange={() => setIsInternational(!isInternational)}
                    />

                    International
                  </span>
                </div>
              </div>

              <div className={classes.dropdownWrapper}>
                {!!selectedClasses?.length && (
                  <div className={classes.labelText}>Class</div>
                )}

                <div className={classes.dropDown}>
                  <MultiSelect
                    className={classes.multiSelect}
                    selectorClassName={classes.multiSelectSelector}
                    selectorRootClassName={classes.multiSelectSelectorRoot}
                    labelWhenSelectedNone='Class'
                    items={playerClasses || []}
                    values={selectedClasses}
                    onChange={(playerClass:string[]) => setSelectedClasses(playerClass)}
                  />
                </div>
              </div>

              <div
                className={classes.advancedPreferenceButton}
                onClick={() => setShowAdvancedPreferences(!showAdvancedPreferences)}
              >
                <div className={classes.advancedPreferenceText}>Advanced Preferences</div>
                <span className={classes.icon}>{showAdvancedPreferences ? '-' : '+'}</span>
              </div>

              {showAdvancedPreferences && (
                <div className={classes.advancedPreferenceWrapper}>
                  <div className={classes.dropdownWrapper}>
                    {(selectedScoreRanges.hasOwnProperty(Score.PAI) ||
                      selectedScoreRanges.hasOwnProperty(Score.COMBINE) ||
                      selectedScoreRanges.hasOwnProperty(Score.STARS)
                    ) && (
                      <div className={classes.labelText}>Score</div>
                    )}

                    <div className={classes.dropDown}>
                      <MultiSelectWithSliders
                        className={classes.multiSelect}
                        selectorClassName={classes.multiSelectSelector}
                        selectorRootClassName={classes.multiSelectSelectorRoot}
                        labelWhenSelectedNone='Scores'
                        items={[
                          { content: SCORE_TITLE[Score.PAI], value: Score.PAI },
                          { content: SCORE_TITLE[Score.COMBINE], value: Score.COMBINE },
                          { content: SCORE_TITLE[Score.STARS], value: Score.STARS },
                        ]}
                        values={selectedScoreTypes}
                        sliderRanges={SCORE_RANGES}
                        selectedSliderRanges={selectedScoreRanges}
                        onChange={(scores:string[]) => setSelectedScoreTypes(scores)}
                        onSliderChange={(value:number | number[], scoreType:string) => {
                          setSelectedScoreRanges({
                            ...selectedScoreRanges,
                            [scoreType]: value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.dropdownWrapper}>
                    {!!selectedOfferDivisions.length && (
                      <div className={classes.labelText}>Offers</div>
                    )}

                    <div className={classes.dropDowns}>
                      <div className={classes.dropDown}>
                        <MultiSelect
                          className={classes.multiSelect}
                          selectorClassName={classes.multiSelectSelector}
                          selectorRootClassName={classes.multiSelectSelectorRoot}
                          labelWhenSelectedNone='Offers'
                          items={[
                            {content: CONFERENCES.FBS, value: CONFERENCES.FBS},
                            {content: CONFERENCES.FCS, value: CONFERENCES.FCS}
                          ]}
                          values={selectedOfferDivisions}
                          onChange={(offers:string[]) => setSelectedOfferDivisions(offers)}
                        />
                      </div>

                      {!!selectedOfferDivisions.length && (
                        <div className={classes.dropDown}>
                          <MultiSelect
                            className={classes.multiSelect}
                            selectorClassName={classes.multiSelectSelector}
                            selectorRootClassName={classes.multiSelectSelectorRoot}
                            labelWhenSelectedNone='Conferences'
                            items={offerConferences}
                            values={selectedOfferConferences.filter((conferenceId:string) =>
                              offerConferences.find(offerConference => offerConference.value === conferenceId)
                            )}
                            onChange={(conferences:string[]) => setSelectedOfferConferences(conferences)}
                          />
                        </div>
                      )}
                    </div>

                    {!!selectedOfferDivisions.length && (
                      <div className={classes.dropDown}>
                        <Slider
                          className={classes.slider}
                          label='Offer Conference Range'
                          min={0}
                          step={1}
                          max={50}
                          defaultValue={(isArray(offerConferencesRange) && offerConferencesRange.length) ? offerConferencesRange : [0, 50]}
                          marks={[
                            { value: 0, label: 0 },
                            { value: 50, label: 50 }
                          ]}
                          onChange={(value: number | number[]) => setOfferConferencesRange(value)}
                        />
                      </div>
                    )}
                  </div>

                  <div className={classes.dropdownWrapper}>
                    {!!selectedCommitDivisions.length && (
                      <div className={classes.labelText}>Commits</div>
                    )}

                    <div className={classes.dropDowns}>
                      <div className={classes.dropDown}>
                        <MultiSelect
                          className={classes.multiSelect}
                          selectorClassName={classes.multiSelectSelector}
                          selectorRootClassName={classes.multiSelectSelectorRoot}
                          labelWhenSelectedNone='Commits'
                          items={[
                            {content: CONFERENCES.FBS, value: CONFERENCES.FBS},
                            {content: CONFERENCES.FCS, value: CONFERENCES.FCS}
                          ]}
                          values={selectedCommitDivisions}
                          onChange={(commits:string[]) => setSelectedCommitDivisions(commits)}
                        />
                      </div>

                      {!!selectedCommitDivisions.length && (
                        <div className={classes.dropDown}>
                          <MultiSelect
                            className={classes.multiSelect}
                            selectorClassName={classes.multiSelectSelector}
                            selectorRootClassName={classes.multiSelectSelectorRoot}
                            labelWhenSelectedNone='Conferences'
                            items={commitConferences}
                            values={selectedCommitConferences.filter((conferenceId:string) =>
                              commitConferences.find(commitConference => commitConference.value === conferenceId)
                            )}
                            onChange={(commits:string[]) => setSelectedCommitConferences(commits)}
                          />
                        </div>
                      )}
                    </div>

                    {!!selectedCommitDivisions.length && (
                      <div className={classes.dropDown}>
                        <Slider
                          className={classes.slider}
                          label='Commit Conference Range'
                          min={0}
                          step={1}
                          max={50}
                          defaultValue={(isArray(commitConferencesRange) && commitConferencesRange.length) ? commitConferencesRange : [0, 50]}
                          marks={[
                            { value: 0, label: 0 },
                            { value: 50, label: 50 }
                          ]}
                          onChange={(value:number | number[]) => setCommitConferencesRange(value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={classes.actions}>
            <Button
              className={classes.actionButton}
              disabled={loading}
              onClick={() => setResetPreferences(true)}
            >
              Cancel
            </Button>

            <Button
              primary
              className={classes.actionButton}
              disabled={loading}
              onClick={savePreferences}
            >
              Save
            </Button>
          </div>
        </div>
      </VisibilitySensor>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      updateUserPreferences,
    },
    dispatch
  )
};

export default connect(
  null,
  mapDispatchToProps,
)(MyPreferences);
