import React from 'react';
import Icon from '../atoms/Icon';

function LocationIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(10, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M6,0 C2.6862915,0 0,2.6862915 0,6 C0,9.3137085 2.6862915,12 6,12 C9.3137085,12 12,9.3137085 12,6 C12,2.6862915 9.3137085,0 6,0 Z M3,6 C3,4.343 4.343,3 6,3 M6,22 L6,12' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(LocationIcon);
