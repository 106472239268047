import React from 'react';
import Page from './Page';
import ExportsPageTemplate from '../templates/ExportsPageTemplate';

export default function ExportsPage () {
  return (
    <Page title='Exports'>
      <ExportsPageTemplate />
    </Page>
  );
}
