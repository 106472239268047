import React from 'react';
import Icon from '../atoms/Icon';

function AddProgramIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(4, 7)'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path d='M0,0 L24,0 L24,18 L0,18 L0,0 Z M4,0.5 L4,17.5 M14,14 L14,4 M19,9 L9,9' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(AddProgramIcon);
