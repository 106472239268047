import React from 'react';
import clsx from 'clsx';

interface FilePickerProps {
  className?: string;
  name: string;
  inputClass?: string;
  fileInputLabelClass?: string;
  fileContainerClass?: string;
  fileName?: string;
  accept?: string;
  children: any;
  onChange: (value:any) => void;
}

export default function FilePicker (props:FilePickerProps) {
  const {
    className,
    name = 'file-input',
    inputClass,
    fileInputLabelClass,
    fileContainerClass,
    accept,
    children,
    onChange = () => {}
  } = props;

  function clearInput (event:any) {
    event.target.value = null;
  }

  function onSelectFile (event:any) {
    if (event.target.files && !!event.target.files.length) {
      onChange(event.target.files[0]);
    }
  }

  return (
    <div className={className}>
      <input
        className={inputClass}
        type='file'
        id={name}
        name={name}
        accept={accept}
        onClick={clearInput}
        onChange={onSelectFile}
        hidden
      />

      <label
        className={clsx(
          inputClass,
          fileInputLabelClass,
          fileContainerClass,
        )}
        htmlFor={name}
      >
        {children}
      </label>
    </div>
  )
}
