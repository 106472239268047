import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '../atoms/Tooltip';
import Download from '../atoms/Download';
import SelectPopover, { SelectPopoverItem } from '../molecules/SelectPopover';
import { COLOR_DARK_GRAY, COLOR_LIGHT_GRAY, COLOR_TEXT } from '../styles/colors';

interface SelectXLSExportTypeProps {
  className?: string;
  items: SelectPopoverItem[];
  tooltipText?: string;
  disabled?: boolean;
  onSelect: (selectedValue:string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectXLSExportType: {},

  popoverPaper: {
    borderRadius: 4,
  },

  popoverItem: {
    padding: `${theme.spacing(1.5, 1.5)} !important`,
    justifyContent: 'flex-start !important',
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: COLOR_DARK_GRAY,
    transition: 'color 0.3s, border-color 0.3s, background 0.3s',

    '&:hover': {
      color: COLOR_TEXT,
      background: `${COLOR_LIGHT_GRAY} !important`,
    },
    '&:focus': {
      color: COLOR_TEXT,
      fontWeight: 'bold',
      outline: 'none',
    },
  },
}), { name: SelectXLSExportType.name });

export default function SelectXLSExportType (props:SelectXLSExportTypeProps) {
  const {
    className,
    items,
    onSelect,
    tooltipText = '',
    disabled,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);

  function onDownloadClick (event:any) {
    setAnchorEl(event.currentTarget);
  }

  function onSelectExportType (value:string) {
    onSelect(value);
    onClose();
  }

  function onClose () {
    setAnchorEl(null)
  }

  return (
    <div className={clsx(classes.selectXLSExportType, className)}>
      <Tooltip title={tooltipText}>
        <div>
          <Download
            label='XLS'
            disabled={disabled}
            onClick={onDownloadClick}
          />
        </div>
      </Tooltip>

      <SelectPopover
        paperClassName={classes.popoverPaper}
        itemClassName={classes.popoverItem}
        anchorEl={anchorEl}
        items={items}
        onSelect={onSelectExportType}
        onClose={onClose}
      />
    </div>
  );
}
