import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Page from './Page';
import MyEvaluationBoardPageTemplate from '../templates/MyEvaluationBoardPageTemplate';

export default function MyEvaluationBoardPage () {
  const location = useLocation();
  const history = useHistory();
  const printed = location.pathname.endsWith('/pdf');

  if (!printed) {
    history.replace('/dashboard?');
  }

  return (
    <Page title='Evaluation Board' printed={printed}>
      <MyEvaluationBoardPageTemplate />
    </Page>
  );
}
