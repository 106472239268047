import React from 'react';
import Page from './Page';
import ImportPageTemplate from '../templates/ImportPageTemplate';

export default function ImportPage () {
  return (
    <Page title='Import'>
      <ImportPageTemplate />
    </Page>
  );
}
