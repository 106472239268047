import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import StarIcon from '../icons/StarIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import SearchPlayersIcon from '../icons/SearchPlayersIcon';
import NotAvailable from '../atoms/NotAvailable';
import Loader from '../atoms/Loader';
import Download from '../atoms/Download';
import CloseButton from '../atoms/CloseButton';
import SeeMoreButton from '../atoms/SeeMoreButton';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Avatar from '../atoms/Avatar';
import DropDown from '../atoms/DropDown';
import Toast, { ToastType } from '../atoms/Toast';
import LocationIcon from '../atoms/LocationIcon';
import AlertButton, { ALERT_MODE } from '../molecules/AlertButton';
import PinButton, { PIN_MODE } from '../molecules/PinButton';
import PlayerNameWithPopover from './PlayerNameWithPopover';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import { formatDateInDays } from '../services/converter';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  COLOR_BORDER,
  COLOR_ORANGE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import NewPlayer from '../types/NewPlayer';
import Player from '../types/Player';
import User from '../types/User';
import College from '../types/College';
import HighSchool from '../types/HighSchool';

interface NewPlayersProps {
  className?: string;
  isNFL?: boolean;
  user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
  newPlayers: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  headerIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
    color: COLOR_ORANGE,
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0,0,0,1),
  },
  headerDownloads: {
    display: 'flex',
    marginLeft: 'auto',
  },
  drawerHeaderDownloads: {
    marginRight: theme.spacing(3),
  },

  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '8px',
    transform: 'translate(0%, 50%)',
  },
  dropDown: {
    marginLeft: theme.spacing(2),
  },
  dropDownSelect: {
    height: '40px',
  },

  tableWrap: {
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableHeadCellWithTitleSection: {
    borderColor: 'rgba(0, 0, 0, 0.04)',
  },
  tableRow: {
    backgroundColor: COLOR_WHITE,
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,
    },
  },
  tableCell: {
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableCellWithAlert: {
    width: '50px',
    height: '50px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  tableSectionTitle: {
    background: COLOR_BACKGROUND_LIGHT,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    padding: theme.spacing(1, 2),
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: 0,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: 'rgba(0, 0, 0, 0.04)',
      bottom: 0,
      left: 0,
    },
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  playerHometownOrCollege: {
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  playerUpdate: {
    display: 'flex',
    alignItems: 'center',
  },
  playerUpdateIcon: {
    width: '20px',
    height: '20px',
    color: COLOR_DARK_GRAY,
  },
  playerUpdateText: {
    marginLeft: theme.spacing(1),
  },
  playerScore: {
    margin: '0 auto',
  },

  alertButtonContainer: {
    height: '100%',
    display: 'flex',
  },

  noPlayers: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
  },
  noPlayersIcon: {
    width: '40px',
    height: '40px',
    opacity: 0.6,
  },
  noPlayersText: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  seeMoreContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  seeMoreDrawer: {
    width: '90%',
    maxWidth: '640px',
  },

  '@media (max-width: 1600px)': {
    dropDownSelect: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  '@media (min-width: 1025px) and (max-width: 1260px), (max-width: 480px)': {
    headerTitleWrap: {
      width: '100%',
    },
    headerTitle: {
      whiteSpace: 'nowrap',
    },
    headerDownloads: {
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    tableHeadCell: {
      padding: theme.spacing(1, 0, 1, 2),
      fontSize: theme.typography.pxToRem(16),

      '&:first-of-type': {
        paddingLeft: theme.spacing(2.5),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(2.5),
      },
    },
  },
}), { name: NewPlayers.name });

enum FilterByDate {
  LAST_TWO_WEEKS = 'Last two weeks',
  LAST_WEEK = 'Last week',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  TWO_DAYS_AGO = '2 days ago',
  THREE_DAY_AGO = '3 days ago',
  FOUR_DAYS_AGO = '4 days ago',
  FIVE_DAYS_AGO = '5 days ago',
  SIX_DAYS_AGO = '6 days ago',
  SEVEN_DAYS_AGO = '7 days ago',
  MORE_THAN_WEEK = 'More than a week ago',
}

const FILTER_RANGE = {
  [FilterByDate.LAST_TWO_WEEKS]: [0, 13],
  [FilterByDate.LAST_WEEK]: [0, 6],
  [FilterByDate.TODAY]: [0, 0],
  [FilterByDate.YESTERDAY]: [1, 1],
  [FilterByDate.TWO_DAYS_AGO]: [2, 2],
  [FilterByDate.THREE_DAY_AGO]: [3, 3],
  [FilterByDate.FOUR_DAYS_AGO]: [4, 4],
  [FilterByDate.FIVE_DAYS_AGO]: [5, 5],
  [FilterByDate.SIX_DAYS_AGO]: [6, 6],
  [FilterByDate.SEVEN_DAYS_AGO]: [7, 7],
  [FilterByDate.MORE_THAN_WEEK]: [7, 13],
};

const HIGH_SCHOOL_FILTERS = [
  FilterByDate.LAST_WEEK,
  FilterByDate.TODAY,
  FilterByDate.YESTERDAY,
  FilterByDate.TWO_DAYS_AGO,
  FilterByDate.THREE_DAY_AGO,
  FilterByDate.FOUR_DAYS_AGO,
  FilterByDate.FIVE_DAYS_AGO,
  FilterByDate.SIX_DAYS_AGO,
  // FilterByDate.SEVEN_DAYS_AGO,
];

const COLLEGE_FILTERS = [
  FilterByDate.LAST_TWO_WEEKS,
  FilterByDate.LAST_WEEK,
  FilterByDate.TODAY,
  FilterByDate.YESTERDAY,
  FilterByDate.TWO_DAYS_AGO,
  FilterByDate.THREE_DAY_AGO,
  FilterByDate.FOUR_DAYS_AGO,
  FilterByDate.FIVE_DAYS_AGO,
  FilterByDate.SIX_DAYS_AGO,
  // FilterByDate.SEVEN_DAYS_AGO,
  FilterByDate.MORE_THAN_WEEK,
];

const MAX_PLAYERS_PER_VIEW = 5;

export default function NewPlayers (props:NewPlayersProps) {
  const {
    className,
    isNFL,
    user,
  } = props;
  const classes = useStyles();

  const [newPlayers, setNewPlayers] = useState<NewPlayer[]>([]);
  const [filterByDate, setFilterByDate] = useState<FilterByDate>(FilterByDate.LAST_WEEK);
  const [hasAlerts, setHasAlerts] = useState<{[key:number]: boolean}>({});
  const [arePinned, setArePinned] = useState<{[key:number]: boolean}>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [dateStart, setDateStart] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>('');

  const limit = drawerOpen ? 100 : 5;

  const filteredNewPlayers = newPlayers;
  const groupedNewPlayers = drawerOpen
    ? groupBy(newPlayers, (newPlayer:NewPlayer) => formatDateInDays(newPlayer.createdAt))
    : {};

  useEffect(() => {
    fetchNewPlayers();
  }, [limit, isNFL]);

  useEffect(() => {
    if (isNFL) {
      setFilterByDate(FilterByDate.LAST_TWO_WEEKS);
    } else {
      setFilterByDate(FilterByDate.LAST_WEEK);
    }
  }, [isNFL]);

  useEffect(() => {
      setStartEndDate();
  }, [filterByDate]);

  function onChangeFilterByDate (filterByDate: FilterByDate) {
    setFilterByDate(filterByDate);
  }

  function setStartEndDate () {
    const [startRange, endRange] = FILTER_RANGE[filterByDate];
    let finalStartRange = startRange;
    let finalEndRange = endRange;
    if (drawerOpen) {
      if (isNFL) {
        finalStartRange = 0;
        finalEndRange = 14;
      } else {
        finalStartRange = 0;
        finalEndRange = 7;
      }
    }
    const start = new Date();
    start.setDate(start.getDate() - finalEndRange);
    const startParam = start.toISOString().slice(0, 10);
    setDateStart(startParam);

    const end = new Date();
    end.setDate(end.getDate() - finalStartRange);
    const endParam = end.toISOString().slice(0, 10);
    setDateEnd(endParam);
    fetchNewPlayers(startParam, endParam);
  }

  function fetchNewPlayers (startParam?: string, endParam?: string) {
    setLoading(true);
    gql(`
      newPlayers (
        limit: ${limit},
        highSchool: ${!isNFL},
        college: ${isNFL},
        dateStart: "${startParam ? startParam : dateStart}",
        dateEnd: "${endParam ? endParam : dateEnd}"
      ) {
        player {
          id
          firstName
          lastName
          pai
          ppi
          combine
          highSchools
          photoUrl
          playerColleges {
            positions
            recruitingClass
            team {
              id
              name
            }
            isPrimary
          }
          hsCombines {
            id
          }
        }
        createdAt
      }
    `)
      .then((data:any) => data.newPlayers as NewPlayer[])
      .then((newPlayers:NewPlayer[]) => {
        setNewPlayers(newPlayers || []);

        if (!!newPlayers?.length) {
          if (isNFL) {
            checkPlayersArePinned(newPlayers);
          } else {
            checkPlayersHasAlert(newPlayers);
          }
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function checkPlayersHasAlert (newPlayers:NewPlayer[]) {
    setLoading(true);

    const playerIds = newPlayers.map((newPlayer:NewPlayer) => newPlayer.player.id);

    gql(`
      hasAlerts (playerIds: [${playerIds}])
    `)
      .then((data:any) => data.hasAlerts as boolean[])
      .then((hasAlerts:boolean[]) => {
        let playersList: {[key:number]: boolean} = {};
        newPlayers.forEach((newPlayer:NewPlayer, index:number) => {
          playersList[newPlayer.player.id] = hasAlerts[index];
        });

        setHasAlerts(playersList);
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function checkPlayersArePinned (newPlayers:NewPlayer[]) {
    setLoading(true);
    const playerIds = newPlayers.map((newPlayer:NewPlayer) => newPlayer.player.id);

    gql(`
      arePinned (playerIds: [${playerIds}])
    `)
      .then((data:any) => data.arePinned as boolean[])
      .then((arePinned:boolean[]) => {
        let playersList: {[key:number]: boolean} = {};

        newPlayers.forEach((newPlayer:NewPlayer, index:number) => {
          playersList[newPlayer.player.id] = arePinned[index];
        });

        setArePinned(playersList);
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function addAlert (playerId:number) {
    setLoading(true);

    return gql(`
      mutation {
        addAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.addAlerts as boolean)
      .then((success:boolean) => {
        if (success) {
          setHasAlerts({...hasAlerts, [playerId] : success});
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function removeAlert (playerId:number) {
    setLoading(true);

    const player = (newPlayers.find((newPlayer:NewPlayer) => newPlayer.player.id === playerId) || {}).player as Player;
    const playerName = `${player.firstName} ${player.lastName}`;

    return gql(`
      mutation {
        removeAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast(`You unsubscribed from ${playerName}`, ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
        setHasAlerts({...hasAlerts, [playerId] : !success});
      })
        .catch(() => {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        })
      .finally(() => setLoading(false));
  }

  function unpinPlayer (playerId:number | string) {
    setLoading(true);

    gql(`
      mutation {
        unpin (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data && data.unpin as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
        setArePinned({...arePinned, [playerId] : !success});
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function pinPlayer (playerId:number | string) {
    setLoading(true);

    return gql(`
      mutation {
        pin (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => ({
        pinStatus: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ pinStatus, errorMessage }:{ pinStatus:boolean, errorMessage:string }) => {
        setArePinned({...arePinned, [playerId] : pinStatus});

        if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onXLSDownload () {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: filteredNewPlayers.map((newPlayer:NewPlayer) => newPlayer.player.id) },
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onSeeMoreClick () {
    if (isNFL) {
      setFilterByDate(FilterByDate.LAST_TWO_WEEKS);
    } else {
      setFilterByDate(FilterByDate.LAST_WEEK);
    }
    setDrawerOpen(true);
  }

  function onSeeMoreDrawerClose () {
    setDrawerOpen(false);
  }

  function renderHeader ({ inDrawer = false } = {}) {
    return (
      <div className={classes.header}>
        <div className={classes.headerTitleWrap}>
          <StarIcon className={classes.headerIcon} />
          <h2 className={classes.headerTitle}>New Players</h2>
        </div>

        {(user.accessLevel.exportAllowanceType !== 'none') && (
            <div className={clsx(classes.headerDownloads, inDrawer && classes.drawerHeaderDownloads)}>
              <Download
                label='XLS'
                onClick={onXLSDownload}
                disabled={!filteredNewPlayers.length}
              />
            </div>
          )
        }

        {inDrawer && (
          <CloseButton
            className={classes.closeButton}
            onClick={onSeeMoreDrawerClose}
          />
        )}

        {!inDrawer && (
          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            items={(isNFL ? COLLEGE_FILTERS : HIGH_SCHOOL_FILTERS).map((filter:FilterByDate) => ({
              content: filter,
              value: filter,
            }))}
            value={filterByDate}
            onChange={onChangeFilterByDate}
          />
        )}
      </div>
    );
  }

  function renderTableSectionTitle (title:string) {
    return (
      <TableRow key={title}>
        <TableCell
          className={classes.tableSectionTitle}
          colSpan={6}
        >
          {title}
        </TableCell>
      </TableRow>
    );
  }

  function renderTableRow (newPlayer:NewPlayer, index:number) {
    const {
      player: {
        id: playerId,
        firstName,
        lastName,
        photoUrl,
        pai,
        combine,
        playerColleges,
        highSchools,
        hsCombines,
        ppi,
      },
    } = newPlayer;

    const primaryHighSchool = (highSchools || []).find((highSchool:HighSchool) => !!highSchool?.isPrimary);
    const primaryCollege = (playerColleges || []).find((college:College) => !!college?.isPrimary);

    const hsPosition = ((primaryHighSchool?.positions || [])
      .find((position:any) => position && position.isPrimary) || {}).code || '';
    const collegePosition = ((primaryCollege?.positions || [])
      .find((position:any) => position && position.isPrimary) || {}).code || '';

    return (
      <TableRow
        key={`${playerId}_${index}`}
        className={classes.tableRow}
      >
        <TableCell className={classes.tableCell} align='left'>
          <div className={classes.playerInfoContainer}>
            <Avatar
              className={classes.playerAvatar}
              src={photoUrl}
              alt='Profile picture'
            />

            <div className={classes.playerNameAndHometown}>
              <PlayerNameWithPopover
                playerId={playerId}
                name={`${firstName} ${lastName}`}
              />

              {(isNFL && primaryCollege) && (
                <div className={classes.playerHometownOrCollege}>
                  {primaryCollege.team ? primaryCollege.team.name : ''}
                </div>
              )}

              {(!isNFL && primaryHighSchool) && (
                <div className={classes.playerHometownOrCollege}>
                  {primaryHighSchool.city ? `${primaryHighSchool.city}, `: ''}{primaryHighSchool.state || ''}
                </div>
              )}
            </div>

            {primaryHighSchool?.state && (
              <LocationIcon
                className={classes.locationIcon}
                stateCode={primaryHighSchool.state}
              />
            )}
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {(isNFL) && (
            (!!collegePosition)
            ? collegePosition
            : <NotAvailable />
          )}

          {(!isNFL) && (
            (!!hsPosition)
            ? hsPosition
            : <NotAvailable />
          )}
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {
            (primaryHighSchool && primaryHighSchool.graduatingClass)
            ? primaryHighSchool.graduatingClass
            : <NotAvailable />
          }
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.PAI}
            scoreList={pai}
          />
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          <Score
            className={classes.playerScore}
            type={SCORE_TYPE.COMBINE}
            scoreList={combine}
            combines={hsCombines}
          />
        </TableCell>
        { isNFL &&
          <TableCell className={classes.tableCell} align='center'>
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PPI}
              value={ppi || undefined}
            />
          </TableCell>
        }

        <TableCell className={clsx(classes.tableCell, classes.tableCellWithAlert)} align='center'>
          <div className={classes.alertButtonContainer}>
            <Divider orientation='vertical' light flexItem />

            {!isNFL && (
              <AlertButton
                mode={hasAlerts[playerId] ? ALERT_MODE.REMOVE : ALERT_MODE.SET_ALERT}
                active={hasAlerts[playerId]}
                onClick={() => hasAlerts[playerId] ? removeAlert(playerId) : addAlert(playerId)}
              />
            )}
            {isNFL && (
              <PinButton
                mode={arePinned[playerId] ? PIN_MODE.REMOVE : PIN_MODE.PIN}
                active={arePinned[playerId]}
                onClick={() => arePinned[playerId] ? unpinPlayer(playerId) : pinPlayer(playerId)}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  function renderTable ({ inDrawer = false } = {}) {
    const tableHeadCellClass = clsx(
      classes.tableHeadCell,
      inDrawer && classes.tableHeadCellWithTitleSection,
    );

    return (
      <div className={classes.tableWrap}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={tableHeadCellClass} align='left'>Name | {isNFL ? 'College' : 'Hometown'}</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>Pos.</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>Class</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>PAI</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>HS Com.</TableCell>
              { isNFL && <TableCell className={tableHeadCellClass} align='center'>PPI</TableCell>}
              <TableCell className={tableHeadCellClass}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!inDrawer && filteredNewPlayers.map(renderTableRow)}

            {inDrawer && (
              Object.keys(groupedNewPlayers)
                .map((timeSlot:string) => ([
                  renderTableSectionTitle(timeSlot),
                  groupedNewPlayers[timeSlot].map(renderTableRow)
                ]))
            )}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <Paper className={clsx(classes.newPlayers, className)}>
      <Loader inProgress={loading} />

      {renderHeader()}

      <Divider variant='fullWidth' light />

      {filteredNewPlayers.length > 0 && renderTable()}

      {!filteredNewPlayers.length && (
        <div className={classes.noPlayers}>
          <SearchPlayersIcon className={classes.noPlayersIcon} />

          <div className={classes.noPlayersText}>No New Players</div>
        </div>
      )}

      {newPlayers.length > 0 && (
        <>
          <Divider variant='fullWidth' light />

          <div className={classes.seeMoreContainer}>
            <SeeMoreButton onClick={onSeeMoreClick} />
          </div>
        </>
      )}

      <Drawer
        classes={{ paper: classes.seeMoreDrawer }}
        anchor='right'
        open={drawerOpen}
        onClose={onSeeMoreDrawerClose}
      >
        <Loader inProgress={loading} />

        {drawerOpen && [
          renderHeader({ inDrawer: true }),
          <Divider variant='fullWidth' light />,
          renderTable({ inDrawer: true }),
        ]}
      </Drawer>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </Paper>
  );
}
