import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ReduxProvider from './redux/ReduxProvider';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BREAKPOINT, ScreenSize } from './services/screen-size';
import { FONT_PROXIMA_NOVA } from './styles/fonts';
import { COLOR_TEXT, COLOR_SHADOW, COLOR_ORANGE, COLOR_DARK_GRAY } from './styles/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#080a14',
    },
    secondary: {
      main: '#f47321',
    },
  },
  typography: {
    fontFamily: [
      'proxima-nova',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: BREAKPOINT[ScreenSize.MOBILE],
      sm: BREAKPOINT[ScreenSize.TABLET],
      md: BREAKPOINT[ScreenSize.DESKTOP_SMALL],
      lg: BREAKPOINT[ScreenSize.DESKTOP_LARGE],
      xl: 1600,
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
      },
      rounded: {
        borderRadius: '5px',
        border: '1px solid #efefef',
      },
    },
    MuiTab: {
      root: {
        borderBottom: '1px solid transparent',
        '&:hover': {
          opacity: 1,
          color: COLOR_ORANGE,
          borderBottomColor: COLOR_DARK_GRAY,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: FONT_PROXIMA_NOVA,
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: 0,
  color: COLOR_TEXT,
};

theme.typography.h2 = {
  fontFamily: FONT_PROXIMA_NOVA,
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: 0,
  color: COLOR_TEXT,

  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ReduxProvider>
        <App/>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
