import {
  SET_ALL_STATES,
  SET_AMERICAN_STATES,
  SET_STATES_LOADING,
} from '../actions/states';
import State from '../../types/State';

export interface StatesState {
  allStates: State[];
  americanStates: State[];
  statesLoading: boolean;
}

const INITIAL_STATE:StatesState = {
  allStates: [],
  americanStates: [],
  statesLoading: false,
};

export function getStatesInitialState () {
  return INITIAL_STATE;
}

export default function statesReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_ALL_STATES:
      return {
        ...state,
        allStates: action.allStates,
      };
    case SET_AMERICAN_STATES:
      return {
        ...state,
        americanStates: action.americanStates,
      };
    case SET_STATES_LOADING:
      return {
        ...state,
        statesLoading: action.statesLoading,
      };
    default:
      return state;
  }
};
