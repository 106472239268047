import React from 'react';
import Icon from '../atoms/Icon';

function OtherIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(5, 5)' stroke='currentColor' strokeWidth='2'>
          <path d='M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,4.92486775 17.0751322,0 11,0 Z' />
          <path d='M11,10 C10.4477153,10 10,10.4477153 10,11 C10,11.5522847 10.4477153,12 11,12 C11.5522847,12 12,11.5522847 12,11 C12,10.4477153 11.5522847,10 11,10 Z M17,10 C16.4477153,10 16,10.4477153 16,11 C16,11.5522847 16.4477153,12 17,12 C17.5522847,12 18,11.5522847 18,11 C18,10.4477153 17.5522847,10 17,10 Z M5,10 C4.44771525,10 4,10.4477153 4,11 C4,11.5522847 4.44771525,12 5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(OtherIcon);
