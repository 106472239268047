import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { bindActionCreators, Dispatch } from 'redux';
import Divider from '@material-ui/core/Divider';
import SideBySideIcon from '../icons/SideBySideIcon';
import RemoveIcon from '../icons/RemoveIcon';
import AlertIcon from '../icons/AlertIcon';
import MoveIcon from '../icons/MoveIcon';
import Action from '../atoms/Action';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Download from '../atoms/Download';
import AddRemoveColumns from './AddRemoveColumns';
import NewFolder from '../molecules/NewFolder';
import CopyToFolder from '../molecules/CopyToFolder';
import SurfaceToTop, { surfaceToTopPlayers } from '../molecules/SurfaceToTop';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import PlayersTable, {
  SAVED_PLAYER_HS_COLUMNS,
  COLLEGE_DEFAULT_COLUMNS,
  NFL_DEFAULT_COLUMNS,
  PLAYER_COLUMN_TITLE,
} from './PlayersTable';
import { State } from '../redux/reducers';
import { fetchUserSettings, saveSavedPlayersColumns } from '../redux/dispatchers/user-settings';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import PlayersFolder from '../types/PlayersFolder';
import SavedPlayer from '../types/SavedPlayer';
import User from '../types/User';
import Column from '../types/Column';
import { Order } from '../types/Order';
import Checkbox from '../atoms/Checkbox';
import PinIcon from '../icons/PinIcon';

interface SavedPlayersTableProps {
  className?: string;
  user?: User;
  newHSPlayersDays: number;
  newCollegePlayersDays: number;
  fetchUserSettings: (isRefresh?: boolean) => void;
  savedColumns: Column[] | null;
  saveColumns: (savedPlayersColumns:Column[]) => void;
  nflAccess?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  savedPlayersTable: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  titleRow: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  titleTextWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 4, 0, 0),
  },
  headerHint: {
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'italic',
    color: COLOR_TEXT,
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    flexShrink: 0,
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  surfaceToTop: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  actionsPrimary: {
    maxWidth: 560,
    paddingRight: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 0,
  },
  actionsSecondary: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'space-between',
  },
  action: {
    padding: theme.spacing(0, 0, 0, 2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-child': {
      paddingLeft: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  alertIcon: {
    width: '26px',
    height: '26px',
    marginLeft: theme.spacing(1),
  },
  actionIconReport: {
    width: '16px',
    height: '16px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  autoSetAlertCheckbox: {
    margin: theme.spacing(0, 1, 0, 0),
  },

  divider: {},

  newFolder: {
    margin: theme.spacing(0, 1, 0, 0),
    padding: theme.spacing(0, 2, 0, 0),
  },

  addRemoveColumns: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0, 2),
  },

  downloadXLS: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },
  downloadIcon: {
    width: '15px',
    height: '15px',
    marginLeft: theme.spacing(1.5),
    color: COLOR_BLUE,
  },

  '@media (max-width: 1260px)': {
    titleRow: {
      display: 'block',
    },

    dropdowns: {
      marginTop: theme.spacing(2),
    },
    dropdownWrapper: {
      marginLeft: 0,
    },

    actions: {
      display: 'block',
    },
    actionsPrimary: {
      paddingRight: 0,
    },
    actionsSecondary: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },

  [MEDIA.MOBILE]: {
    titleRow: {
      padding: theme.spacing(2),
    },

    dropdownWrapper: {
      fontSize: theme.typography.pxToRem(14),
    },
    dropdown: {
      minWidth: '70px',
    },
    dropdownSelector: {
      minHeight: 0,
    },

    actions: {
      padding: theme.spacing(2),
    },
    actionsPrimary: {
      justifyContent: 'flex-start',
    },
    action: {
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(14),
    },
    actionText: {
      display: 'none',
      fontSize: theme.typography.pxToRem(14),
    },

    downloadXLS: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  '@media (max-width: 580px)': {
    titleRow: {
      display: 'block',
    },

    dropdowns: {
      flexWrap: 'wrap',

      '& $dropdownWrapper': {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginLeft: 0,
      },
    },

    actionsSecondary: {
      flexWrap: 'wrap',
    },
    newFolder: {
      width: '100%',
      margin: theme.spacing(0,0,2,0),
      paddingLeft: 0,
    },
    divider: {
      '&:first-of-type': {
        display: 'none',
      },
    },
    addRemoveColumns: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },

  [MEDIA.DESKTOP]: {
    surfaceToTop: {
      minWidth: '300px',
    },

    actionIcon: {
      width: '32px',
      height: '32px',
    },
    actionIconReport: {
      width: '20px',
      height: '20px',
    },
    removeAction: {
      '&:nth-child(1)': {
        marginRight: 0,
      },
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    dropdownWrapper: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}), { name: SavedPlayersTable.name });

function SavedPlayersTable (props: SavedPlayersTableProps) {
  const {
    className,
    user,
    newHSPlayersDays,
    newCollegePlayersDays,
    fetchUserSettings,
    savedColumns,
    saveColumns,
    nflAccess,
  } = props;
  const classes = useStyles();

  const [savedPlayers, setSavedPlayers] = useState<SavedPlayer[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [folders, setFolders] = useState<PlayersFolder[]>([]);
  const [prevFolders, setPrevFolders] = useState<PlayersFolder[]>([]);
  const [surfaceToTopValues, setSurfaceToTopValues] = useState<string[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Array<Player>>([]);
  const [selectedFolders, setSelectedFolders] = useState<Array<PlayersFolder>>([]);
  const [order, setOrder] = useState<Order | undefined>();
  const [sortedByColumn, setSortedByColumn] = useState<string>('name');
  const [selectedFolder, setSelectedFolder] = useState<PlayersFolder>();
  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.SAVED_PLAYER_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.SAVED_PLAYER_TABLE) || {}).limit || 10);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.SAVED_PLAYER_TABLE) || {}).currentPage || 1);
  const [autoSetAlert, setAutoSetAlert] = useState<boolean>(false);

  const defaultColumns: Column[] =
    Array
    .from(new Set([
      ...SAVED_PLAYER_HS_COLUMNS,
      ...COLLEGE_DEFAULT_COLUMNS,
      ...NFL_DEFAULT_COLUMNS,
    ]))
    .map((columnName:string) => ({
      name: columnName,
      selected: true,
    }));
  const [columns, setColumns] = useState<Column[]>(defaultColumns);

  const [loading, setLoading] = useState<boolean>(true);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    Promise.allSettled([
      fetchSavedPlayers(),
      fetchSavedPlayerFolders(),
      getAlertForSavedPlayers(),
    ])
      .finally(() => setLoading(false));

    fetchUserSettings();
  }, []);

  useEffect(() => {
    clientStorage.save(StorageKey.SAVED_PLAYER_TABLE, { limit, currentPage });
  }, [limit, currentPage]);

  useEffect(() => {
    if (user) {
      getAlertForSavedPlayers();
    }
  }, [user]);

  useEffect(() => {
    if(autoSetAlert && !nflAccess) {
      saveColumns(columns.map((column:Column) => ({
        name: column.name,
        selected: column.selected || ['alert'].includes(column.name),
      } as Column)));
    } else {
      saveColumns(columns.map((column:Column) => ({
        name: column.name,
        selected: !['alert'].includes(column.name),
      } as Column)));
    }
  }, [autoSetAlert, nflAccess]);

  useEffect(() => {
    setColumns(savedColumns?.length ? savedColumns : defaultColumns);
  }, [savedColumns]);

  useEffect(() => {
    if (prevLimit !== limit && !!players.length) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [players.length, limit]);

  useEffect(() => {
    if (savedPlayers && savedPlayers.length && folders && folders.length !== prevFolders.length) {
      extractPlayers();
      setPrevFolders(folders);
    }
  }, [savedPlayers, folders, prevFolders]);

  useEffect(() => {
    setPlayers(
      surfaceToTopPlayers(
        players,
        surfaceToTopValues,
        user?.accessLevel,
        newHSPlayersDays,
        newCollegePlayersDays,
      )
    );
    if (selectedFolder) {
      setSelectedFolder({
        ...selectedFolder,
        players: surfaceToTopPlayers(
          selectedFolder.players,
          surfaceToTopValues,
          user?.accessLevel,
          newHSPlayersDays,
          newCollegePlayersDays,
        ),
      });
    }
  }, [surfaceToTopValues]);

  function getAlertForSavedPlayers () {
    if (!user || !Object.keys(user).length) return;

    return gql(`
      user (id: ${user.id}) {
        alertEnabledForSavedPlayers
      }`
    )
    .then((data:any) => data && data.user as User)
    .then((user:User) => {
      setAutoSetAlert(user.alertEnabledForSavedPlayers)
    })
    .catch(console.error);
  }

  function fetchSavedPlayers () {
    return gql(`
      savedPlayers {
        id
        playerId
        notes {
          colorCode
        }
        player {
          id
          slug
          firstName
          lastName
          photoUrl
          highSchools
          pai
          ppi
          combine
          compStar
          _247Star
          linkMilesplit
          linkMaxpreps
          link247
          linkRivals
          linkAthleticNet
          linkPff
          linkOn3
          linkSSA
          linkInstagram
          linkDirectAthletics
          linkTwitter
          linkHudl
          linkTrackingFootball
          multiSport
          createdAt
          hasHighSchool
          hasCollegeTeam
          hasNFLTeam
          bestHighSchoolMPH {
            mph
          }
          hsCombines {
            id
          }
          playerColleges {
            recruitingClass
            height
            weight
            positions
            isPrimary
            team {
              id
              name
              shortName
              logoESPN
              logo247
              logoAlt
            }
          }
          playerNFLTeams {
            draftYear
            draftRound
            height
            weight
            positions
            isPrimary
            team {
              id
              name
              shortName
              logoESPN
              logo247
              logoAlt
            }
          }
          email
          cellPhone
          address
          igaScore
        }
        userId
        folderId
        folder {
          id
          name
        }
      }
    `)
      .then((data: any) => data.savedPlayers as SavedPlayer[])
      .then((savedPlayers: SavedPlayer[]) => {
        setSavedPlayers(savedPlayers);
        setPlayers(savedPlayers.map((savedPlayer:SavedPlayer) => ({...savedPlayer.player, notes: savedPlayer.notes})));
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function fetchSavedPlayerFolders () {
    return gql(`
      savedPlayersFolders {
        id
        userId
        name
        createdAt
        updatedAt
      }
    `)
      .then((data: any) => data.savedPlayersFolders as PlayersFolder[])
      .then((folders:PlayersFolder[]) => {
        setFolders(folders.map(folder => ({ ...folder, players: [] })));
        extractPlayers();
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      });
  }

  function extractPlayers() {
    if (savedPlayers.length && folders.length) {
      let foldersWithPlayers:PlayersFolder[] = [];
      folders.map(folder => {
        let folderWithPlayers: PlayersFolder = {
          ...folder,
          players: []
        };
        savedPlayers.map(savedPlayer => {
          if (folder.id === savedPlayer.folderId) {
            folderWithPlayers.players.push(savedPlayer.player);
          }
          return folderWithPlayers;
        });

        foldersWithPlayers.push(folderWithPlayers);
      });
      setFolders(foldersWithPlayers);
    }
  }

  function onCreateNewFolder (name:string) {
    setLoading(true);

    gql(`
      mutation {
        createSavedPlayersFolder (name: "${name}")
      }
    `)
      .then((data:any) => data.createSavedPlayersFolder as number)
      .then((newFolderId:number) => {
        setFolders([
          ...folders,
          {
            id: newFolderId,
            name,
            players: [],
          },
        ]);
      })
      .catch(() => {
        showToast('Failed to create new folder. Try refreshing the page.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onSelectFolder (folder:PlayersFolder) {
    setSelectedFolder(folder);
    setSelectedPlayers([]);
    setSelectedFolders([]);
  }

  function onGoBack () {
    setSelectedFolder(undefined);
    setSelectedPlayers([]);
    setSelectedFolders([]);
  }

  function onCopyToFolder (folder:PlayersFolder | any) {
    setLoading(true);

    const playersInFolderFilteredFromSelected = folder.players
      .filter((player: Player) => !selectedPlayers.some(item => item.id === player.id));

    const folderWithCopiedItems = {
      ...folder,
      players: playersInFolderFilteredFromSelected.concat(selectedPlayers as Player[]),
    };

    const updatedFolders = [...folders];
    const updatedFolderIndex = folders.findIndex(item => item.id === folder.id);
    if (updatedFolderIndex > -1) {
      updatedFolders.splice(updatedFolderIndex, 1, folderWithCopiedItems as PlayersFolder);
    }

    setFolders(updatedFolders);

    const selectedPlayersIds = selectedPlayers.map((player:Player) => player.id);
    const savedPlayerIds = savedPlayers
      .filter((savedPlayer:SavedPlayer) => selectedPlayersIds.includes(savedPlayer.playerId))
      .map((savedPlayer:SavedPlayer) => savedPlayer.id);

    gql(`
      mutation {
        copySavedPlayersToFolder (savedPlayerIds: [${savedPlayerIds}], folderId: ${folder.id})
      }
    `)
      .then((data:any) => data.copySavedPlayersToFolder as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedPlayers([]);
    setSelectedFolders([]);
  }

  function onMoveOut () {
    if (selectedFolder) {
      setLoading(true);

      const updatedPlayers = selectedFolder.players
        .filter(player => !selectedPlayers.some(item => item.id === player.id));
      const updatedFolder = {
        ...selectedFolder,
        players: updatedPlayers,
      };
      setSelectedFolder(updatedFolder);

      // update the list of folders
      const updatedFolders = [...folders];
      const updateIndex = folders.findIndex(item => item.id === updatedFolder.id);
      if (updateIndex > -1) {
        updatedFolders.splice(updateIndex, 1, updatedFolder);
      }
      setFolders(updatedFolders);
    }

    const selectedPlayersIds = selectedPlayers.map((player:Player) => player.id);
    const savedPlayerIds = savedPlayers
      .filter((savedPlayer:SavedPlayer) => selectedPlayersIds.includes(savedPlayer.playerId))
      .map((savedPlayer:SavedPlayer) => savedPlayer.id);

    gql(`
      mutation {
        copySavedPlayersToFolder (savedPlayerIds: [${savedPlayerIds}], folderId: null)
      }
    `)
      .then((data:any) => data.copySavedPlayersToFolder as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedPlayers([]);
    setSelectedFolders([]);
  }

  function onDelete () {
    // delete selected folders (only if not inside a folder)
    if (!selectedFolder) {
      const foldersToDelete = [...selectedFolders];
      if (foldersToDelete.length) {
        deleteFolders(foldersToDelete);
      }
    }

    const playersToDelete = [...selectedPlayers];
    if (playersToDelete.length) {
      // delete selected players in the main list
      unsavePlayers(playersToDelete);
    }

    setSelectedPlayers([]);
    setSelectedFolders([]);
  }

  function onSelectAutoSetAlert(checked: any) {
    setAutoSetAlert(checked);
    onSaveAlertsForSavedPlayers(checked);
  }

  function onSaveAlertsForSavedPlayers (alertEnabledForSavedPlayers:boolean | null) {
    if (!loading) setLoading(true);

    return gql(`
      mutation {
        saveAlertsForSavedPlayers (alertEnabledForSavedPlayers: ${alertEnabledForSavedPlayers})
      }
    `)
      .then((data:any) => data.saveAlertsForSavedPlayers as boolean)
      .then((success:boolean) => {
        if (!success) {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function unsavePlayers (playersToUnsave: (PlayersFolder | Player)[]) {
    const playerIdsToUnsave = playersToUnsave.map(player => player.id);

    setLoading(true);

    gql(`
      mutation {
        unsavePlayers (playerIds: [${playerIdsToUnsave}])
      }
    `)
      .then((data:any) => data.unsavePlayers as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Unsaved', ToastType.SUCCESS);
          fetchSavedPlayers();
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function deleteFolders (foldersToDelete: (PlayersFolder | Player)[]) {
    setLoading(true);

    const folderIdsToDelete = foldersToDelete.map(folder => folder.id);

    gql(`
      mutation {
        deleteSavedPlayersFolders (folderIds: [${folderIdsToDelete}])
      }
    `)
      .then((data:any) => data.deleteSavedPlayersFolders as boolean)
      .then((success:boolean) => {
        if (success) {
          setFolders(folders.filter(folder => !folderIdsToDelete.includes(folder.id)));
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onSort (columnName:string, newOrder:Order, sort:(players:Player[], order:Order) => Player[]) {
    setSortedByColumn(columnName);
    setPlayers(sort(selectedFolder ? selectedFolder.players : players, newOrder));
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onXLSDownload () {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: players.map(player => player.id) },
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function onSelectColumns (selectedColumnNames:string[]) {
    saveColumns(columns.map((column:Column) => ({
      name: column.name,
      selected: selectedColumnNames.includes(column.name),
    } as Column)));
  }

  function onReorderColumns (reorderedColumnNames:string[]) {
    saveColumns(reorderedColumnNames
      .map((columnName:string) => columns.find((column:Column) => column.name === columnName))
      .filter(Boolean) as Column[]
    );
  }

  function resetColumns () {
    saveColumns([]);
  }

  function onPin () {
    setLoading(true);
    const selectedPlayerIds = selectedPlayers.length && selectedPlayers.map(selectedItem => selectedItem.id);

    gql(`
      mutation {
        pin (playerIds: [${selectedPlayerIds}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Pinned', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

      setSelectedPlayers([]);
      setSelectedFolders([]);
  }

  if (!user || !Object.keys(user).length) {
    return <Loader inProgress />;
  }

  const withSelectedFolders = selectedFolders.length > 0;
  const sideBySideActionDisabled = (
    withSelectedFolders ||
    !selectedPlayers.length ||
    selectedPlayers.length === 1 ||
    selectedPlayers.length > 10
  );
  const copyActionDisabled = withSelectedFolders || !selectedPlayers.length;
  const moveOutActionDisabled = !selectedPlayers.length;
  const removeActionDisabled = !selectedPlayers.length && !selectedFolders.length;
  const onPinDisabled = !selectedPlayers.length || selectedFolders.length ? true : false;

  return (
    <div className={clsx(classes.savedPlayersTable, className)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleRow}>
          <div className={classes.titleTextWrap}>
            <h2 className={classes.headerTitle}>
              {selectedFolder
                ? `${selectedFolder.name} Folder`
                : 'Your Saved Players'
              }
            </h2>
            <p className={classes.headerHint}>You can compare up to 10 players side-by-side</p>
          </div>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              Surface Players to Top:
              <SurfaceToTop
                className={classes.surfaceToTop}
                displayOnlyNew={!user.accessLevel.hasEvalDotAndNotes}
                values={surfaceToTopValues}
                onSelect={(values:string[]) => setSurfaceToTopValues(values)}
              />
            </div>

            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Players
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <div className={classes.actionsPrimary}>
            <Action
              className={clsx(classes.action, onPinDisabled && classes.disabledAction)}
              icon={PinIcon}
              iconClassName={classes.actionIcon}
              disabled={onPinDisabled}
              onClick={onPin}
            >
              <span className={classes.actionText}>Pin</span>
            </Action>

            <Action
              className={clsx(classes.action, sideBySideActionDisabled && classes.disabledAction)}
              icon={SideBySideIcon}
              iconClassName={classes.actionIcon}
              disabled={sideBySideActionDisabled}
              href={`/side-by-side?playerIds=${selectedPlayers.map((player:Player) => player.id).join(',')}`}
            >
              <span className={classes.actionText}>Side-by-Side</span>
            </Action>

            {(!selectedFolder && !!folders?.length) && (
              <CopyToFolder
                className={clsx(classes.action, copyActionDisabled && classes.disabledAction)}
                folders={folders}
                disabled={copyActionDisabled}
                onCopy={onCopyToFolder}
              />
            )}

            {selectedFolder && (
              <Action
                className={clsx(classes.action, moveOutActionDisabled && classes.disabledAction)}
                icon={MoveIcon}
                iconClassName={classes.actionIcon}
                disabled={moveOutActionDisabled}
                onClick={onMoveOut}
              >
                <span className={classes.actionText}>Move Out</span>
              </Action>
            )}

            <Action
              className={clsx(classes.action, removeActionDisabled && classes.disabledAction, classes.removeAction)}
              icon={RemoveIcon}
              iconClassName={classes.actionIcon}
              disabled={removeActionDisabled}
              onClick={onDelete}
            >
              <span className={classes.actionText}>Remove</span>
            </Action>

            {!nflAccess && <div className={classes.action} style={{paddingLeft: '18px', display: 'flex', alignItems: 'center'}}>
              <Checkbox
                className={classes.autoSetAlertCheckbox}
                checked={autoSetAlert}
                onChange={onSelectAutoSetAlert}
              />
              <span onClick={() => onSelectAutoSetAlert(!autoSetAlert)}> Auto Set Alerts</span><span onClick={() => onSelectAutoSetAlert(!autoSetAlert)} className={classes.alertIcon}><AlertIcon /></span>
            </div>}
          </div>

          <div className={classes.actionsSecondary}>
            {!selectedFolder && (
              <NewFolder
                className={classes.newFolder}
                onCreate={onCreateNewFolder}
              />
            )}

            <Divider
              className={classes.divider}
              orientation='vertical'
              light
              flexItem
            />

            <AddRemoveColumns
              className={classes.addRemoveColumns}
              columns={columns.map((column:Column) => ({
                content: PLAYER_COLUMN_TITLE[column.name],
                value: column.name,
              }))}
              selectedColumns={columns
                .filter((column:Column) => column.selected)
                .map((column:Column) => column.name)
              }
              onSelect={onSelectColumns}
              onReorder={onReorderColumns}
              onResetColumnsClick={resetColumns}
            />

            {(user.accessLevel.exportAllowanceType !== 'none') && (
              <>
                <Divider
                  className={classes.divider}
                  orientation='vertical'
                  light
                  flexItem
                />
                <Download
                  className={classes.downloadXLS}
                  label='XLS'
                  onClick={onXLSDownload}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <PlayersTable
        items={selectedFolder ? selectedFolder.players : [...folders, ...players]}
        folders={folders}
        players={selectedFolder ? selectedFolder.players : players}
        limit={limit}
        columns={columns
          .filter((column:Column) => column.selected)
          .map((column:Column) => column.name)
        }
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        selectedFolders={selectedFolders}
        setSelectedFolders={setSelectedFolders}
        order={order}
        setOrder={setOrder}
        sortedByColumn={sortedByColumn}
        onSort={onSort}
        onSelectFolder={onSelectFolder}
        onGoBack={selectedFolder ? onGoBack : undefined}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        autoSetAlert={autoSetAlert}
      />

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    newHSPlayersDays: state.configurations.newHSPlayersDays,
    newCollegePlayersDays: state.configurations.newCollegePlayersDays,
    nflAccess: state.ui.nflAccess,
    savedColumns: state.userSettings.savedPlayersColumns,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUserSettings,
      saveColumns: saveSavedPlayersColumns,
    },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedPlayersTable);
