import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { trackPageView } from '../services/google-analytics';
import { COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import MEDIA from '../styles/media';

interface FormPageTemplateProps {
  children: any;
}

const useStyles = makeStyles(theme => ({
  formPageTemplate: {
    width: '100vw',
    height: '100vh',
    minHeight: '600px',
    display: 'flex',
  },

  leftHalf: {
    width: '50%',
    height: '100%',
    position: 'relative',
    backgroundImage: 'url(/images/login-background-left.jpg)',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftHalfImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  logoWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(4),
  },
  logo: {
    width: '300px',
  },

  title: {
    ...theme.typography.h1,
    padding: 0,
    margin: theme.spacing(3, 0, 0),
    textAlign: 'center',
    color: COLOR_WHITE,
  },

  rightHalf: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    padding: theme.spacing(4),
    backgroundColor: COLOR_WHITE,
    backgroundImage: 'url(/images/login-background-right.jpg)',
    backgroundPosition: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },

  [MEDIA.TABLET]: {
    leftHalf: {
      width: '40%',
    },

    rightHalf: {
      width: '60%',
    },
  },

  [MEDIA.MOBILE]: {
    formPageTemplate: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      height: 'auto',
      minHeight: '100vh',
      backgroundColor: COLOR_WHITE,
    },

    leftHalf: {
      width: '100%',
      height: 'auto',
      background: 'none',
    },

    logoWrapper: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      transform: 'none',
    },

    logo: {
      width: '150px',
    },

    title: {
      fontSize: theme.typography.pxToRem(20),
      color: COLOR_TEXT,
    },

    rightHalf: {
      width: '100%',
      height: 'auto',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
    },
  },
}), { name: FormPageTemplate.name });

export default function FormPageTemplate (props: FormPageTemplateProps) {
  const { children } = props;
  const classes = useStyles();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <main className={classes.formPageTemplate}>
      <div className={classes.leftHalf}>
        <div className={classes.logoWrapper}>
          <img
            className={classes.logo}
            src='/images/tracking-football-logo-text.svg'
            alt='Tracking Football®'
          />

          <h2 className={classes.title}>
            Our Data. <strong>Your Advantage.</strong>®
          </h2>
        </div>
      </div>

      <div className={classes.rightHalf}>
        {children}
      </div>
    </main>
  );
}
