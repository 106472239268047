import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import DropDown from '../atoms/DropDown';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Conference from '../types/Conference';

interface ConferenceDropDownProps {
  className?: string;
  itemClassName?: string;
  conferences?: Conference[];
  selectedConference?: Conference;
  onChange: (conference:Conference | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  conferenceDropDown: {
    height: '53px',
  },

  dropDownSelector: {
    height: '100%',

    '&:before': {
      right: '30px',
    },
    '&:after': {
      right: '24px',
    },
  },
  empty: {
    '& $dropDownSelectorRoot': {
      padding: theme.spacing(1.75, 2),
    },
  },

  dropDownSelectorRoot: {
    boxSizing: 'border-box',
    height: '100%',
    padding: theme.spacing(2),
  },

  dropDownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 0),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'normal',
  },

  logo: {
    width: '32px',
    height: 'auto',
    marginRight: theme.spacing(2),
  },
}), { name: ConferenceDropDown.name });

export default function ConferenceDropDown (props:ConferenceDropDownProps) {
  const {
    className,
    itemClassName,
    conferences,
    selectedConference,
    onChange,
  } = props;
  const classes = useStyles();

  if (!conferences || conferences.length === 0) {
    return null;
  }

  const emptyValue = typeof selectedConference === 'undefined';

  return (
    <DropDown
      className={clsx(
        classes.conferenceDropDown,
        emptyValue && classes.empty,
        className,
      )}
      selectorClassName={classes.dropDownSelector}
      selectorRootClassName={classes.dropDownSelectorRoot}
      items={conferences.map((conference:Conference) => ({
        value: String(conference.id),
        content: (
          <div className={clsx(classes.dropDownItem, itemClassName)}>
            <img
              className={classes.logo}
              src={conference.iconUrl}
              alt={`${conference.name} logo`}
            />
            {conference.name}
          </div>
        ),
      }))}
      emptyItem={{
        value: '',
        content: 'Select a Conference'
      }}
      value={selectedConference ? String(selectedConference.id) : ''}
      onChange={(selectedId:string) => onChange(conferences.find(conference => String(conference.id) === selectedId) || undefined)}
    />
  );
}
