import React from 'react';
import Icon from '../atoms/Icon';

function NFLTeamIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(16.5, 16.5) scale(-1, 1) translate(-16.5, -16.5) translate(3, 3)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M16,15 C14.8954305,15 14,15.8954305 14,17 C14,18.1045695 14.8954305,19 16,19 C17.1045695,19 18,18.1045695 18,17 C18,15.8954305 17.1045695,15 16,15 Z M13,5 C11.378,5 9.166,5.778 8.142,7.486 C7.858,7.958 8.012,8.574 8.486,8.858 C8.646,8.954 8.824,9 9,9 C9.34,9 9.67,8.828 9.858,8.514 C10.376,7.652 11.726,7 13,7 C13.552,7 14,6.552 14,6 C14,5.448 13.552,5 13,5 Z' />
          <path d='M25.938,10.978 C25.378,6.356 21.844,2.388 17.342,1.326 C16.38,1.098 15.398,0.968 14.276,0.988 C8.6,1 2.574,4.848 2.03,12.176 C1.998,12.608 2.248,13.012 2.65,13.176 L10,16.172 L10,19 L9.518,19 C9.504,18.998 9.492,19 9.48,19 L1.028,19 C0.478,19 0.03,19.446 0.028,19.996 L0,25.996 C0,26.28 0.118,26.55 0.328,26.742 C0.512,26.908 0.752,27 1,27 C1.034,27 1.066,26.998 1.1,26.996 C1.42,26.964 8.606,26.184 10.272,21.012 C10.918,23.452 12.746,25 15.25,25 C17.19,25 19.32,23.448 20.792,21 L22.528,21 C22.842,21 23.14,20.852 23.328,20.6 C24.896,18.506 26.32,14.142 25.938,10.978 Z M2.006,24.814 L2.022,21 L8.12,21 C6.904,23.454 3.708,24.448 2.006,24.814 Z M22.002,19 L20.21,19 C19.84,19 19.502,19.204 19.328,19.528 C18.234,21.572 16.558,23 15.25,23 C12.854,23 12,20.934 12,19 L12,15.5 C12,15.094 11.754,14.728 11.378,14.574 L4.098,11.608 C4.892,5.706 9.848,2.998 14.278,2.988 C15.176,2.964 16.07,3.082 16.88,3.274 C20.582,4.146 23.49,7.414 23.95,11.22 C24.242,13.606 23.2,17.118 22.002,19 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(NFLTeamIcon);
