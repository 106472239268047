import React from 'react';
import Icon from '../atoms/Icon';

function BackIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M6,16 L28,16 M11.515,22 L5.515,16 L11.515,10'
          stroke='currentColor'
          strokeWidth='2'
        />
      </g>
    </Icon>
  );
}

export default React.forwardRef(BackIcon);
