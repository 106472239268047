import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from './PageContainer';
import SavedPlayersTable from '../organisms/SavedPlayersTable';
import SaveIcon from '../icons/SaveIcon';
import MEDIA from '../styles/media';

const useStyles = makeStyles(theme => ({
  savedPlayersPageTemplate: {
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    padding: 0,
    margin: theme.spacing(5, 5, 0),
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
  },
  headerIcon: {
    width: '22px',
    height: '22px',
    marginRight: theme.spacing(1),
  },

  table: {
    margin: theme.spacing(3, 5, 0),
  },

  [MEDIA.MOBILE]: {
    header: {
      margin: theme.spacing(2, 2, 0),
    },

    table: {
      margin: theme.spacing(2, 2, 0),
    },
  },
}), { name: SavedPlayersPageTemplate.name });

export default function SavedPlayersPageTemplate () {
  const classes = useStyles();

  return (
    <PageContainer className={classes.savedPlayersPageTemplate}>
      <Paper className={classes.paper}>
        <h1 className={classes.header}>
          <SaveIcon  className={classes.headerIcon} />
          Saved Players
        </h1>

        <SavedPlayersTable className={classes.table} />
      </Paper>
    </PageContainer>
  );
}
