import React from 'react';
import Page from './Page';
import CombinePageTemplate from '../templates/CombinePageTemplate';

export default function CombinePage () {
  return (
    <Page title='Combine'>
      <CombinePageTemplate />
    </Page>
  )
}