import React from 'react';
import ModalPageTemplate from '../templates/ModalPageTemplate';
import Unsubscribe from '../organisms/Unsubscribe';

export default function UnsubscribePage () {
  return (
    <ModalPageTemplate>
      <Unsubscribe />
    </ModalPageTemplate>
  );
}