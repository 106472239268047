import React from 'react';
import Icon from '../atoms/Icon';

function MyExportsIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(4, 3)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <polygon points='14 22 24 22 19 27' />
          <polygon points='18 15 20 15 20 24 18 24' />
          <path d='M11.172,22 L2,22 L2,17 L16,17 L16,15 L2,15 L2,9 L18,9 L18,13 L20,13 L20,0 L0,0 L0,24 L13.172,24 L11.172,22 Z M2,2 L18,2 L18,7 L2,7 L2,2 Z' />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(MyExportsIcon);
