import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from './Page';
import PlayerPageTemplate from '../templates/PlayerPageTemplate';

export default function PlayerPage () {
  const location = useLocation();
  const printed = location.pathname.endsWith('/pdf');

  return (
    <Page
      printed={printed}
      title='Player'
    >
      <PlayerPageTemplate printed={printed} />
    </Page>
  );
}
