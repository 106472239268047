import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_ORANGE } from '../styles/colors';

interface MinimizeButtonProps {
  className?: string;
  minimized?: boolean;
  onMinimize: (event: any) => void;
  onMaximize: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  minimizeButton: {
    width: '24px',
    height: '24px',
    padding: 0,
    appearance: 'none',
    background: 'none',
    border: `1px solid transparent`,
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    position: 'relative',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:before': {
      content: '""',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      position: 'absolute',
      background: 'none',
      borderRadius: '2px',
      border: '2px solid currentColor',
      boxSizing: 'border-box',
    },
    '&:after': {
      content: '""',
      width: 'calc(100% - 14px)',
      height: '2px',
      position: 'absolute',
      bottom: '6px',
      left: '7px',
      background: 'currentColor',
    },

    '&:hover': {
      color: COLOR_ORANGE,
    },

    '&:focus': {
      outline: 0,
      borderColor: COLOR_DARK_GRAY,

      '&:before': {
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        top: '4px',
        left: '4px',
      },
    },
  },

  minimized: {
    '&:hover': {
      color: COLOR_BLUE,
    },
    '&:after': {
      display: 'none',
    }
  },
}), { name: MinimizeButton.name });

export default function MinimizeButton (props: MinimizeButtonProps) {
  const {
    className,
    minimized = false,
    onMinimize = () => {},
    onMaximize = () => {},
  } = props;
  const classes = useStyles();
  const onClick = minimized ? onMaximize : onMinimize;

  return (
    <button
      className={clsx(classes.minimizeButton, minimized && classes.minimized, className)}
      onClick={onClick}
    />
  );
}
