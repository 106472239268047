import React from 'react';
import clsx from 'clsx';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

interface IconProps {
  className?: string;
  children: React.ReactNode;
  viewBox?: string
}

const useStyles = makeStyles(() => ({
  icon: {
    color: 'currentColor',

    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}), { name: Icon.name });

function Icon (props: IconProps, ref: React.Ref<any>) {
  const { className, children, viewBox } = props;
  const classes = useStyles();
  const viewBoxCustom = viewBox || '0 0 32 32'
  return (
    <div
      {...props}
      className={clsx(classes.icon, className)}
      ref={ref}
    >
      <SvgIcon viewBox={viewBoxCustom}>
        {children}
      </SvgIcon>
    </div>
  );
}

export default React.forwardRef(Icon);
