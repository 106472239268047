import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import PlusIcon from '../icons/PlusIcon';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import Toast, { ToastType } from '../atoms/Toast';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import gql, { serializeGQLObject } from '../services/gql';
import { State } from '../redux/reducers';
import { fetchDivITeams, fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import Program from '../types/Program';
import Team from '../types/Team';
import HsCombineType from '../types/HsCombineType';
import SearchPrograms, { SearchByProgramName } from '../organisms/SearchPrograms';
import { TEAM_CAMP_STRING } from '../types/ValidationRule';

interface AddEditHsCombineTypeFormmProps {
  className?: string;
  isEditMode: boolean;
  loading: boolean;
  hsCombineType?: HsCombineType;
  setLoading: (loading:boolean) => void;
  collegeTeams: Team[];
  nflTeams: Team[];
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  divITeams: Team[];
  fetchDivITeams:() => void;
}

const useStyles = makeStyles(theme => ({
  addEditProgramForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  input: {
    width: '100%',
    maxWidth: '400px',
    padding: 0,
    marginBottom: theme.spacing(4),
  },
  dropDown: {
    width: '100%',
    maxWidth: '400px',
    marginBottom: theme.spacing(4),
    padding: 0,
  },
  dropDownSelect: {
    height: '50px',
  },
  teamSelect: {
    maxWidth: '400px',
    marginBottom: theme.spacing(4),

    '& input': {
      boxSizing: 'border-box',
      height: '50px',
    },
  },

  searchPlayerNames: {
    marginTop: theme.spacing(2),
  },

  plusIcon: {
    height: '12px',
    width: '12px',
    marginRight: theme.spacing(1),
  },

  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  date: {
    marginRight: theme.spacing(4),
    marginBottom: 0,
  },
  dateCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  combineTypeWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  combineType: {
    margin: theme.spacing(2, 4, 0, 0),
  },
  apiButton: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '50px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  dropdownWrapper: {
    maxWidth: '400px',
    marginTop: theme.spacing(1.5),
  },
  dropdownLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
  },

  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  action: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  disabledCheckboxWrapper: {
    color: '#aaa',
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },

  sectionTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  apiCheckboxes: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  apiCheckboxesRow: {
    display: 'flex',
    alignItems: 'center',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(3),
    },
  },
  apiCheckbox: {
    marginTop: 0,
  },
  ncaaIdCheckbox: {
    marginLeft: 100,
  },
  draftEligiblePlayersCheckbox: {
    marginLeft: 12,
  },

  startYearInput: {
    width: 145,
    margin: theme.spacing(0, 0, 0, 'auto'),
  },

  companyDropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  companyWrapper: {
    display: 'flex',
  },
  companyName: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  companyLogo: {
    height: 24,
    marginLeft: theme.spacing(2),
  },
  createCompanyButton: {
    height: '50px',
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '50px',
  },

  programAutoComplete: {
    width: '100%',
    maxWidth: '30.6%'
  },

  '@media (max-width: 1023px)': {
    dateWrapper: {
      maxWidth: '400px',
    },
    date: {
      maxWidth: 'none',
      flexShrink: 0,
    },
    dateCheckboxWrapper: {
      marginTop: theme.spacing(2),
    },

    combineTypeWrapper: {
      maxWidth: '400px',
    },
    combineType: {
      maxWidth: 'none',
      flexShrink: 0,
    },
  },
}), { name: AddEditHsCombineTypeForm.name });

function AddEditHsCombineTypeForm (props: AddEditHsCombineTypeFormmProps) {
  const {
    className,
    isEditMode = false,
    loading,
    setLoading,
    collegeTeams,
    fetchCollegeTeams,
    hsCombineType,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [searchByProgramName, setSearchByProgramName] = useState<SearchByProgramName[]>([]);

  const [type, setType] = useState<string>('');
  const [teamCamp, setTeamCamp] = useState<string>('');
  const [isAccessControlled, setIsAccessControlled] = useState<boolean>(false);
  const currentYear = new Date().getFullYear();
  const yearsArray = Array.from({length: currentYear - 2022}, (_, index) => currentYear - index);
  const yearsList = yearsArray.map(year => { return { content: year, value: year }});

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      collegePrograms()
    ])
      .finally(() => setLoading(false));
    fetchCollegeTeams();
  }, []);

  useEffect(() => {
    if (
      (!isEditMode)
      && collegeTeams && collegeTeams.length
    ) {
      setLoading(false);
    }
  }, [isEditMode, collegeTeams]);

  useEffect(() => {
    if (isEditMode && hsCombineType
    ) {

      if (hsCombineType?.type) {
        setType(hsCombineType?.type);
      }

      if (hsCombineType?.teamCamp) {
        setTeamCamp(hsCombineType?.teamCamp);
      }

      if (hsCombineType?.isAccessControlled) {
        setIsAccessControlled(hsCombineType.isAccessControlled);
      }

      if (hsCombineType?.programIds) {
        const programIds = hsCombineType?.programIds.map(pid => String(pid));
        setSelectedPrograms(programIds);
        const prName = programs.filter(program => programIds.includes((program.id)?.toString()));
        setSearchByProgramName(prName)
      }
    }
  }, [
    hsCombineType,
    isEditMode,
    programs
  ]);

  function collegePrograms () {
    return gql(`
    collegePrograms {
        id
        name
      }
    `)
      .then((data:any) => data.collegePrograms as Program[])
      .then((programs:Program[]) => {
        if (programs && programs.length) {
          setPrograms(programs);
        } else {
          showToast('Failed to fetch collegePrograms Levels.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch collegePrograms Levels.', ToastType.ERROR);
      });
  }

  function saveHsCombineType () {
    if (type?.toLowerCase() == TEAM_CAMP_STRING?.toLowerCase()) {
      showToast(`Failed to ${isEditMode ? 'update' : 'add'} hs combine type. as unique value for hs combine type and team camp is required`, ToastType.ERROR);
      return ;
    }
    setLoading(true);
    const hsCombineTypePayload = {
      type: type,
      teamCamp: teamCamp,
      isAccessControlled: isAccessControlled,
      year: selectedYear,
      programIds: searchByProgramName.map(pid=> Number(pid?.id))
    } as any;

    if (isEditMode && hsCombineType && hsCombineType.id) {
      hsCombineTypePayload.id = hsCombineType.id;
    }

    gql(`
      mutation {
        saveHsCombineType (
          hsCombineType: ${serializeGQLObject(hsCombineTypePayload)}
        )
      }
    `)
      .then((data:any) => data.saveHsCombineType as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast(`${isEditMode ? 'Updated' : 'HS Combine Type Added'}`, ToastType.SUCCESS);

          if (!isEditMode) {
            resetData();
          }
        } else {
          showToast(`Failed to ${isEditMode ? 'update' : 'add'} hs combine type. as unique value for hs combine type and team camp is required`, ToastType.ERROR);
        }
      })
      .catch((err) => {
        console.log('err', err);
        showToast(`Failed to ${isEditMode ? 'update' : 'add'} hs combine type.`, ToastType.ERROR);
      })
      .finally(() =>  setLoading(false));
  }

  function resetData () {
    
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function getAutocompleteItems(properties: {collegeTeams:Team[]}) {
    return collegeTeams;
  }

  const autocompleteItems:Team[] = getAutocompleteItems
  ? getAutocompleteItems({ collegeTeams })
  : [];

  return (
    <>
      <div className={clsx(classes.addEditProgramForm, className)}>
        <div className={classes.combineTypeWrapper}>
          <Input
            className={clsx(classes.input, classes.combineType)}
            label='HS Combine Type'
            value={type}
            onChange={(value:string) => setType(value)}
          />
        </div>

        <div className={classes.dateWrapper}>
          <div className={classes.dateCheckboxWrapper}>
            <Checkbox
              className={classes.checkbox}
              checked={isAccessControlled}
              onChange={() => setIsAccessControlled(!isAccessControlled)}
            />
            Access Control
          </div>
        </div>
        {
          isAccessControlled && (
            <div className={classes.companyDropdownWrapper}>
              <div>
                <div className={classes.dropdownLabel}>Year:</div>

                <div className={classes.companyWrapper}>
                  <DropDown
                    className={classes.dropDown}
                    selectorClassName={classes.dropDownSelect}
                    emptyItem={{
                      value: '',
                      content: 'Year'
                    }}
                    items={yearsList}
                    value={selectedYear || ''}
                    onChange={(year:number) => setSelectedYear(year)}
                  />
                </div>
              </div>
              <div>
                <div className={classes.dropdownLabel}>Programs:</div>
                <div className={classes.programAutoComplete}>
                <SearchPrograms
                    className={classes.searchPlayerNames}
                    searchByProgramName={searchByProgramName}
                    setSearchByProgramName={setSearchByProgramName}
                  />
                </div>
              </div>
            </div>
            
          )
        }

        <div className={classes.actions}>
          <Button
            className={classes.action}
            primary
            disabled={
              loading
              || (!type && !teamCamp)
            }
            onClick={saveHsCombineType}
          >
            {!isEditMode && <PlusIcon className={classes.plusIcon} />}
            {isEditMode ? 'Update' : 'Save'}
          </Button>

          <Button
            className={classes.action}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </div>

        <Toast
          visible={toastVisible}
          type={toastType}
          onHide={() => setToastVisible(false)}
        >
          {toastMessage}
        </Toast>

        </div>
      </>
    )
  }
const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    nflTeams: state.teams.nflTeams,
    nflDraftYear: state.configurations.nflDraftYear,
    divITeams: state.teams.divITeams,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchDivITeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditHsCombineTypeForm);
