import React from 'react';
import Icon from '../atoms/Icon';

function WatchListIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(5, 5)'>
          <path
            d='M15,18 C10.581722,18 7,19.1192881 7,20.5 C7,21.8807119 10.581722,23 15,23 C19.418278,23 23,21.8807119 23,20.5 C23,19.1192881 19.418278,18 15,18 Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M5.8,3.5 L2.5,5.8 L1.3,4.1 C0.7,3.2 1,2 1.9,1.3 L1.9,1.3 C2.8,0.7 4,1 4.7,1.9 L5.8,3.5 Z'
            stroke='currentColor'
            strokeWidth='2'
          />
          <path
            d='M2.7,9 L6.1,20.9 L8,20.3 L4.4,7.9 L2.7,9 Z M8.6,5.1 L6.9,6.2 L21.3,20 L22.7,18.6 L8.6,5.1 Z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    </Icon>
  );
}

export default React.forwardRef(WatchListIcon);
