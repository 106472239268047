import React from 'react';
import Icon from '../atoms/Icon';

function OurladsIcon(props: any, ref: React.Ref<any>) {
    return (
        <Icon {...props} ref={ref}>
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                width="100%" viewBox="0 0 3000 3000" enable-background="new 0 0 3000 3000">
                <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                    d="
M1584.000000,3002.000000 
	C1056.000122,3002.000000 529.000183,3002.000000 2.000212,3002.000000 
	C2.000141,2002.000244 2.000141,1002.000549 2.000071,2.000615 
	C1001.999512,2.000410 2001.999023,2.000410 3001.999023,2.000205 
	C3001.999268,1001.999329 3001.999268,2001.998657 3001.999512,3001.999023 
	C2529.666748,3002.000000 2057.333252,3002.000000 1584.000000,3002.000000 
M195.833679,1264.641968 
	C177.151886,1329.891724 158.214600,1395.070435 139.991730,1460.448120 
	C136.085587,1474.461914 133.425812,1489.111084 132.498825,1503.614990 
	C129.321564,1553.327637 141.338089,1599.550171 168.347610,1641.407227 
	C199.229935,1689.265869 240.015610,1725.265503 295.049652,1743.267334 
	C322.562927,1752.267090 350.981873,1755.283325 379.765137,1755.333130 
	C438.093872,1755.434082 496.422913,1755.366455 554.751831,1755.368286 
	C558.367615,1755.368408 561.983459,1755.368408 565.732178,1755.368408 
	C566.764832,1758.625366 567.596619,1761.132568 568.356201,1763.661499 
	C602.048950,1875.834595 644.282410,1984.526978 699.993042,2087.769043 
	C776.828003,2230.158447 876.474548,2353.956055 1000.569824,2457.994873 
	C1129.804077,2566.342773 1275.618896,2647.259521 1428.060669,2717.314453 
	C1432.163940,2719.200439 1435.443848,2718.759277 1439.267090,2716.909912 
	C1450.052002,2711.692871 1461.007446,2706.829834 1471.838135,2701.705811 
	C1572.221436,2654.214600 1669.418091,2601.130859 1761.001221,2538.046143 
	C1934.924072,2418.244629 2072.441406,2266.021973 2171.258545,2079.088623 
	C2224.346191,1978.661743 2265.221191,1873.362427 2297.868896,1764.725586 
	C2298.794189,1761.646484 2299.920898,1758.627808 2301.025879,1755.368408 
	C2307.685791,1755.368408 2313.663818,1755.334839 2319.641113,1755.373901 
	C2352.642090,1755.589478 2385.613037,1755.543945 2418.323486,1750.056396 
	C2461.297852,1742.847046 2502.699463,1730.724243 2541.094727,1709.827271 
	C2599.707275,1677.927124 2642.309570,1632.389771 2660.474365,1566.662476 
	C2673.057373,1521.132568 2686.067383,1475.710938 2697.900391,1429.985474 
	C2747.247314,1239.297119 2796.223633,1048.512817 2845.314697,857.758362 
	C2846.364746,853.677490 2847.166260,849.532593 2848.273682,844.565247 
	C2831.601562,844.565247 2815.968994,844.565247 2800.336670,844.565247 
	C2784.671143,844.565247 2769.005615,844.565247 2753.340088,844.565247 
	C2738.007812,844.565247 2722.675781,844.565247 2707.343506,844.565247 
	C2691.677979,844.565247 2676.012695,844.565247 2660.347168,844.565247 
	C2644.681641,844.565247 2629.016113,844.565247 2613.350586,844.565247 
	C2597.685059,844.565247 2582.019775,844.565247 2566.354248,844.565247 
	C2551.021973,844.565247 2535.689941,844.565247 2520.357666,844.565247 
	C2504.692139,844.565247 2489.026855,844.590027 2473.361328,844.558350 
	C2457.878906,844.527100 2442.396484,844.438721 2427.068604,844.376038 
	C2428.120850,797.958740 2429.445312,752.992493 2430.074463,708.016418 
	C2430.704590,662.975220 2432.022705,617.922241 2431.214844,571.650269 
	C2426.928955,574.226685 2424.087891,575.893250 2421.286621,577.624329 
	C2370.496338,609.009094 2317.857422,636.803650 2262.456543,659.200317 
	C2190.956543,688.105164 2117.048340,707.006836 2039.840088,711.335999 
	C1968.948120,715.311096 1899.586914,706.278320 1831.836426,684.983032 
	C1758.134155,661.817017 1690.733765,626.028198 1627.080688,582.743958 
	C1561.033325,537.831604 1500.598999,486.043427 1442.223633,431.736389 
	C1439.329590,429.044067 1436.383911,426.407288 1433.404297,423.691742 
	C1418.841431,436.819031 1404.901489,449.714661 1390.612305,462.210876 
	C1311.503052,531.393982 1226.201294,591.402466 1131.096680,636.823181 
	C1049.572388,675.758118 964.372070,701.876160 874.022400,709.290588 
	C798.160522,715.516052 723.718323,707.491028 650.910950,684.994019 
	C576.308411,661.942383 508.626038,625.485596 445.928833,579.343384 
	C442.865845,577.089172 439.732788,574.930115 435.460327,571.893921 
	C435.327179,663.579834 436.422882,753.687805 439.988556,844.565247 
	C429.800049,844.565247 420.857788,844.489990 411.917755,844.597839 
	C407.601257,844.649902 403.231323,844.679077 398.983948,845.345459 
	C368.196777,850.176025 343.969086,866.285583 324.163177,889.631348 
	C309.536804,906.871948 299.410675,926.716858 292.254120,948.027283 
	C283.887421,972.941162 275.586761,997.891541 267.995453,1023.048828 
	C243.870636,1102.997437 220.088852,1183.049683 195.833679,1264.641968 
z"/>
                <path fill="#121933" opacity="1.000000" stroke="none"
                    d="
M196.004089,1263.851929 
	C220.088852,1183.049683 243.870636,1102.997437 267.995453,1023.048828 
	C275.586761,997.891541 283.887421,972.941162 292.254120,948.027283 
	C299.410675,926.716858 309.536804,906.871948 324.163177,889.631348 
	C343.969086,866.285583 368.196777,850.176025 398.983948,845.345459 
	C403.231323,844.679077 407.601257,844.649902 411.917755,844.597839 
	C420.857788,844.489990 429.800049,844.565247 439.988556,844.565247 
	C436.422882,753.687805 435.327179,663.579834 435.460327,571.893921 
	C439.732788,574.930115 442.865845,577.089172 445.928833,579.343384 
	C508.626038,625.485596 576.308411,661.942383 650.910950,684.994019 
	C723.718323,707.491028 798.160522,715.516052 874.022400,709.290588 
	C964.372070,701.876160 1049.572388,675.758118 1131.096680,636.823181 
	C1226.201294,591.402466 1311.503052,531.393982 1390.612305,462.210876 
	C1404.901489,449.714661 1418.841431,436.819031 1433.404297,423.691742 
	C1436.383911,426.407288 1439.329590,429.044067 1442.223633,431.736389 
	C1500.598999,486.043427 1561.033325,537.831604 1627.080688,582.743958 
	C1690.733765,626.028198 1758.134155,661.817017 1831.836426,684.983032 
	C1899.586914,706.278320 1968.948120,715.311096 2039.840088,711.335999 
	C2117.048340,707.006836 2190.956543,688.105164 2262.456543,659.200317 
	C2317.857422,636.803650 2370.496338,609.009094 2421.286621,577.624329 
	C2424.087891,575.893250 2426.928955,574.226685 2431.214844,571.650269 
	C2432.022705,617.922241 2430.704590,662.975220 2430.074463,708.016418 
	C2429.445312,752.992493 2428.120850,797.958740 2427.068604,844.376038 
	C2442.396484,844.438721 2457.878906,844.527100 2473.361328,844.558350 
	C2489.026855,844.590027 2504.692139,844.565247 2520.357666,844.565247 
	C2535.689941,844.565247 2551.021973,844.565247 2566.354248,844.565247 
	C2582.019775,844.565247 2597.685059,844.565247 2613.350586,844.565247 
	C2629.016113,844.565247 2644.681641,844.565247 2660.347168,844.565247 
	C2676.012695,844.565247 2691.677979,844.565247 2707.343506,844.565247 
	C2722.675781,844.565247 2738.007812,844.565247 2753.340088,844.565247 
	C2769.005615,844.565247 2784.671143,844.565247 2800.336670,844.565247 
	C2815.968994,844.565247 2831.601562,844.565247 2848.273682,844.565247 
	C2847.166260,849.532593 2846.364746,853.677490 2845.314697,857.758362 
	C2796.223633,1048.512817 2747.247314,1239.297119 2697.900391,1429.985474 
	C2686.067383,1475.710938 2673.057373,1521.132568 2660.474365,1566.662476 
	C2642.309570,1632.389771 2599.707275,1677.927124 2541.094727,1709.827271 
	C2502.699463,1730.724243 2461.297852,1742.847046 2418.323486,1750.056396 
	C2385.613037,1755.543945 2352.642090,1755.589478 2319.641113,1755.373901 
	C2313.663818,1755.334839 2307.685791,1755.368408 2301.025879,1755.368408 
	C2299.920898,1758.627808 2298.794189,1761.646484 2297.868896,1764.725586 
	C2265.221191,1873.362427 2224.346191,1978.661743 2171.258545,2079.088623 
	C2072.441406,2266.021973 1934.924072,2418.244629 1761.001221,2538.046143 
	C1669.418091,2601.130859 1572.221436,2654.214600 1471.838135,2701.705811 
	C1461.007446,2706.829834 1450.052002,2711.692871 1439.267090,2716.909912 
	C1435.443848,2718.759277 1432.163940,2719.200439 1428.060669,2717.314453 
	C1275.618896,2647.259521 1129.804077,2566.342773 1000.569824,2457.994873 
	C876.474548,2353.956055 776.828003,2230.158447 699.993042,2087.769043 
	C644.282410,1984.526978 602.048950,1875.834595 568.356201,1763.661499 
	C567.596619,1761.132568 566.764832,1758.625366 565.732178,1755.368408 
	C561.983459,1755.368408 558.367615,1755.368408 554.751831,1755.368286 
	C496.422913,1755.366455 438.093872,1755.434082 379.765137,1755.333130 
	C350.981873,1755.283325 322.562927,1752.267090 295.049652,1743.267334 
	C240.015610,1725.265503 199.229935,1689.265869 168.347610,1641.407227 
	C141.338089,1599.550171 129.321564,1553.327637 132.498825,1503.614990 
	C133.425812,1489.111084 136.085587,1474.461914 139.991730,1460.448120 
	C158.214600,1395.070435 177.151886,1329.891724 196.004089,1263.851929 
M358.401947,917.427124 
	C348.403229,928.827332 340.897522,941.731689 335.585205,955.887268 
	C332.196167,964.917847 328.939026,974.010376 326.001648,983.196289 
	C274.511932,1144.216797 228.086487,1306.738892 182.073135,1469.380127 
	C167.680496,1520.253296 174.860565,1568.609619 202.451370,1613.349121 
	C227.688446,1654.272217 261.913605,1684.923462 309.029358,1699.047363 
	C329.945343,1705.317383 351.450714,1707.644043 373.184143,1707.555786 
	C442.511475,1707.274414 511.837891,1706.772583 581.164673,1706.359131 
	C584.067932,1706.341797 586.971313,1706.357056 589.967834,1706.357056 
	C590.834778,1709.018921 591.535400,1710.859619 592.036560,1712.753174 
	C613.170471,1792.615967 638.344727,1871.130859 669.973450,1947.515015 
	C717.559143,2062.435547 779.213989,2168.949951 858.973145,2264.695557 
	C934.878601,2355.815186 1023.844177,2432.160889 1122.316040,2497.783203 
	C1219.016968,2562.225830 1321.949951,2615.234863 1427.074341,2664.261230 
	C1431.920532,2666.521240 1435.687134,2666.119141 1440.183472,2663.930176 
	C1455.744385,2656.354492 1471.488037,2649.155762 1487.076172,2641.635010 
	C1584.369385,2594.695557 1678.991577,2543.110596 1768.044189,2481.642334 
	C1933.138672,2367.686523 2062.103760,2222.226074 2152.872070,2043.033936 
	C2206.047119,1938.058105 2245.642090,1828.087769 2275.947998,1714.588135 
	C2276.687744,1711.817383 2277.780762,1709.140869 2278.458252,1707.155273 
	C2294.396973,1707.155273 2309.392578,1706.767456 2324.361084,1707.237549 
	C2353.753418,1708.160889 2382.996826,1707.217529 2411.986816,1701.925171 
	C2454.708984,1694.125977 2495.007568,1680.040894 2530.832520,1654.917358 
	C2572.100098,1625.977417 2601.106934,1587.862305 2613.954346,1538.770874 
	C2669.300781,1327.285034 2724.307373,1115.710449 2779.412842,904.161621 
	C2780.371582,900.481873 2781.016846,896.720459 2782.015137,892.025696 
	C2647.290771,892.025696 2513.771973,892.025696 2379.390625,892.025696 
	C2380.099365,864.963135 2380.928955,838.716309 2381.434082,812.463196 
	C2381.933838,786.470215 2382.167480,760.471558 2382.405029,734.474304 
	C2382.642822,708.446594 2383.966797,682.412109 2383.409668,655.078491 
	C2367.855225,663.607483 2353.926758,671.355896 2339.898438,678.918518 
	C2261.766357,721.038757 2178.434570,745.894836 2090.038818,753.570068 
	C2027.232056,759.023438 1964.844360,755.096069 1902.987061,743.322449 
	C1822.628296,728.027283 1746.493774,700.522095 1674.000000,662.781494 
	C1587.909424,617.962402 1510.636841,561.184692 1442.545654,491.968689 
	C1439.629028,489.003967 1436.609497,486.140656 1433.948975,483.534821 
	C1432.442017,484.386261 1432.104004,484.499115 1431.870117,484.721619 
	C1429.455078,487.018433 1427.043579,489.319305 1424.656006,491.644470 
	C1317.533203,595.965881 1192.297729,670.347717 1050.455444,716.805298 
	C948.268250,750.274719 843.741211,764.875366 736.270447,752.082397 
	C687.400635,746.265076 640.231750,733.932495 594.766418,714.970398 
	C559.226074,700.147644 526.601440,680.212769 494.635712,659.043335 
	C491.416565,656.911438 488.096710,654.931458 482.796204,651.610840 
	C484.113647,733.183899 485.394592,812.496277 486.683746,892.316467 
	C460.265869,892.316467 434.621094,892.523804 408.982544,892.215149 
	C398.625305,892.090393 388.969604,894.270996 380.481995,899.805176 
	C373.013672,904.674866 366.366455,910.803772 358.401947,917.427124 
z"/>
                <path fill="#FCFCFC" opacity="1.000000" stroke="none"
                    d="
M358.879272,916.901855 
	C366.366455,910.803772 373.013672,904.674866 380.481995,899.805176 
	C388.969604,894.270996 398.625305,892.090393 408.982544,892.215149 
	C434.621094,892.523804 460.265869,892.316467 486.683746,892.316467 
	C485.394592,812.496277 484.113647,733.183899 482.796204,651.610840 
	C488.096710,654.931458 491.416565,656.911438 494.635712,659.043335 
	C526.601440,680.212769 559.226074,700.147644 594.766418,714.970398 
	C640.231750,733.932495 687.400635,746.265076 736.270447,752.082397 
	C843.741211,764.875366 948.268250,750.274719 1050.455444,716.805298 
	C1192.297729,670.347717 1317.533203,595.965881 1424.656006,491.644470 
	C1427.043579,489.319305 1429.455078,487.018433 1431.870117,484.721619 
	C1432.104004,484.499115 1432.442017,484.386261 1433.948975,483.534821 
	C1436.609497,486.140656 1439.629028,489.003967 1442.545654,491.968689 
	C1510.636841,561.184692 1587.909424,617.962402 1674.000000,662.781494 
	C1746.493774,700.522095 1822.628296,728.027283 1902.987061,743.322449 
	C1964.844360,755.096069 2027.232056,759.023438 2090.038818,753.570068 
	C2178.434570,745.894836 2261.766357,721.038757 2339.898438,678.918518 
	C2353.926758,671.355896 2367.855225,663.607483 2383.409668,655.078491 
	C2383.966797,682.412109 2382.642822,708.446594 2382.405029,734.474304 
	C2382.167480,760.471558 2381.933838,786.470215 2381.434082,812.463196 
	C2380.928955,838.716309 2380.099365,864.963135 2379.390625,892.025696 
	C2513.771973,892.025696 2647.290771,892.025696 2782.015137,892.025696 
	C2781.016846,896.720459 2780.371582,900.481873 2779.412842,904.161621 
	C2724.307373,1115.710449 2669.300781,1327.285034 2613.954346,1538.770874 
	C2601.106934,1587.862305 2572.100098,1625.977417 2530.832520,1654.917358 
	C2495.007568,1680.040894 2454.708984,1694.125977 2411.986816,1701.925171 
	C2382.996826,1707.217529 2353.753418,1708.160889 2324.361084,1707.237549 
	C2309.392578,1706.767456 2294.396973,1707.155273 2278.458252,1707.155273 
	C2277.780762,1709.140869 2276.687744,1711.817383 2275.947998,1714.588135 
	C2245.642090,1828.087769 2206.047119,1938.058105 2152.872070,2043.033936 
	C2062.103760,2222.226074 1933.138672,2367.686523 1768.044189,2481.642334 
	C1678.991577,2543.110596 1584.369385,2594.695557 1487.076172,2641.635010 
	C1471.488037,2649.155762 1455.744385,2656.354492 1440.183472,2663.930176 
	C1435.687134,2666.119141 1431.920532,2666.521240 1427.074341,2664.261230 
	C1321.949951,2615.234863 1219.016968,2562.225830 1122.316040,2497.783203 
	C1023.844177,2432.160889 934.878601,2355.815186 858.973145,2264.695557 
	C779.213989,2168.949951 717.559143,2062.435547 669.973450,1947.515015 
	C638.344727,1871.130859 613.170471,1792.615967 592.036560,1712.753174 
	C591.535400,1710.859619 590.834778,1709.018921 589.967834,1706.357056 
	C586.971313,1706.357056 584.067932,1706.341797 581.164673,1706.359131 
	C511.837891,1706.772583 442.511475,1707.274414 373.184143,1707.555786 
	C351.450714,1707.644043 329.945343,1705.317383 309.029358,1699.047363 
	C261.913605,1684.923462 227.688446,1654.272217 202.451370,1613.349121 
	C174.860565,1568.609619 167.680496,1520.253296 182.073135,1469.380127 
	C228.086487,1306.738892 274.511932,1144.216797 326.001648,983.196289 
	C328.939026,974.010376 332.196167,964.917847 335.585205,955.887268 
	C340.897522,941.731689 348.403229,928.827332 358.879272,916.901855 
M2016.406982,2175.384277 
	C2049.921143,2129.197266 2079.447021,2080.554443 2105.771729,2029.954590 
	C2159.012695,1927.619019 2198.103027,1819.890015 2227.992432,1708.669434 
	C2232.460938,1692.041504 2236.704590,1675.353149 2241.172607,1658.231323 
	C2245.343750,1658.231323 2248.969727,1658.181885 2252.593994,1658.239380 
	C2282.254150,1658.709229 2311.916748,1659.077881 2341.572998,1659.722412 
	C2363.991211,1660.209595 2386.173096,1658.246826 2408.151367,1653.982422 
	C2443.508057,1647.122070 2476.856934,1635.149536 2506.226562,1613.788818 
	C2537.457764,1591.074463 2558.998047,1561.500366 2568.570801,1523.693970 
	C2571.265137,1513.052368 2574.308838,1502.499634 2577.075928,1491.875854 
	C2624.291260,1310.595825 2671.490967,1129.311646 2718.663086,948.020386 
	C2719.279785,945.651001 2719.494141,943.176941 2719.993164,940.169312 
	C2715.038818,940.169312 2711.059326,940.169250 2707.079834,940.169250 
	C1944.120605,940.168457 1181.161377,940.206665 418.202271,939.854675 
	C404.585205,939.848389 395.952423,944.960449 389.417603,955.442688 
	C385.567566,961.618408 382.243195,968.223816 379.517548,974.973206 
	C375.910400,983.905396 372.934082,993.108276 370.006653,1002.296753 
	C319.396729,1161.147339 273.698425,1321.452026 228.087906,1481.783203 
	C223.379990,1498.332642 222.159164,1514.995483 223.594513,1532.182983 
	C228.017868,1585.149658 277.407104,1647.630859 336.146088,1656.277100 
	C348.952545,1658.162231 361.967316,1659.615967 374.884399,1659.602417 
	C446.211456,1659.527832 517.537903,1658.926636 588.864685,1658.533325 
	C601.496521,1658.463623 614.129089,1658.523438 627.248962,1658.523438 
	C628.287292,1662.332520 629.293518,1665.783813 630.168274,1669.268311 
	C651.094727,1752.623901 676.259766,1834.611328 708.037476,1914.543823 
	C793.534973,2129.601562 928.846436,2305.332031 1119.341431,2437.925781 
	C1216.716797,2505.703857 1320.897705,2561.221924 1427.875366,2611.888184 
	C1432.042358,2613.861816 1435.217041,2613.460205 1439.062500,2611.640137 
	C1535.465332,2566.011475 1629.798462,2516.650391 1718.678223,2457.377197 
	C1833.666748,2380.692627 1934.408081,2288.967529 2016.406982,2175.384277 
M789.000000,890.108948 
	C1298.959351,890.043152 1808.918579,889.977356 2318.877930,889.909363 
	C2321.877441,889.908936 2324.877197,889.896973 2327.876221,889.844360 
	C2328.532471,889.832886 2329.196777,889.665833 2329.834229,889.487732 
	C2330.123291,889.406860 2330.363037,889.132446 2330.612549,888.930359 
	C2330.871582,888.720154 2331.115234,888.491089 2331.720215,887.957275 
	C2332.622314,837.788208 2334.882568,787.377258 2334.186279,735.958313 
	C2330.044922,737.706482 2326.998047,738.894226 2324.032227,740.258667 
	C2207.089844,794.060730 2084.454834,812.901306 1956.732300,799.496399 
	C1893.232178,792.831848 1831.380859,778.420410 1771.217163,757.052185 
	C1647.073120,712.960144 1537.111450,645.403137 1440.207275,556.344849 
	C1437.847168,554.175964 1435.342041,552.164917 1433.653076,550.720520 
	C1407.187378,571.847778 1382.148926,593.410339 1355.536865,612.815002 
	C1220.223633,711.481079 1070.979370,776.635559 903.983948,798.440979 
	C845.869812,806.029358 787.674316,806.719788 729.532288,799.521851 
	C663.896545,791.396240 601.156921,773.200378 542.051086,743.132935 
	C539.210266,741.687927 536.234070,740.509033 531.606689,738.439575 
	C532.677673,789.891846 533.713257,839.643188 534.763733,890.109009 
	C619.760315,890.109009 703.380188,890.109009 789.000000,890.108948 
z"/>
                <path fill="#101730" opacity="1.000000" stroke="none"
                    d="
M2016.009521,2175.977051 
	C1934.408081,2288.967529 1833.666748,2380.692627 1718.678223,2457.377197 
	C1629.798462,2516.650391 1535.465332,2566.011475 1439.062500,2611.640137 
	C1435.217041,2613.460205 1432.042358,2613.861816 1427.875366,2611.888184 
	C1320.897705,2561.221924 1216.716797,2505.703857 1119.341431,2437.925781 
	C928.846436,2305.332031 793.534973,2129.601562 708.037476,1914.543823 
	C676.259766,1834.611328 651.094727,1752.623901 630.168274,1669.268311 
	C629.293518,1665.783813 628.287292,1662.332520 627.248962,1658.523438 
	C614.129089,1658.523438 601.496521,1658.463623 588.864685,1658.533325 
	C517.537903,1658.926636 446.211456,1659.527832 374.884399,1659.602417 
	C361.967316,1659.615967 348.952545,1658.162231 336.146088,1656.277100 
	C277.407104,1647.630859 228.017868,1585.149658 223.594513,1532.182983 
	C222.159164,1514.995483 223.379990,1498.332642 228.087906,1481.783203 
	C273.698425,1321.452026 319.396729,1161.147339 370.006653,1002.296753 
	C372.934082,993.108276 375.910400,983.905396 379.517548,974.973206 
	C382.243195,968.223816 385.567566,961.618408 389.417603,955.442688 
	C395.952423,944.960449 404.585205,939.848389 418.202271,939.854675 
	C1181.161377,940.206665 1944.120605,940.168457 2707.079834,940.169250 
	C2711.059326,940.169250 2715.038818,940.169312 2719.993164,940.169312 
	C2719.494141,943.176941 2719.279785,945.651001 2718.663086,948.020386 
	C2671.490967,1129.311646 2624.291260,1310.595825 2577.075928,1491.875854 
	C2574.308838,1502.499634 2571.265137,1513.052368 2568.570801,1523.693970 
	C2558.998047,1561.500366 2537.457764,1591.074463 2506.226562,1613.788818 
	C2476.856934,1635.149536 2443.508057,1647.122070 2408.151367,1653.982422 
	C2386.173096,1658.246826 2363.991211,1660.209595 2341.572998,1659.722412 
	C2311.916748,1659.077881 2282.254150,1658.709229 2252.593994,1658.239380 
	C2248.969727,1658.181885 2245.343750,1658.231323 2241.172607,1658.231323 
	C2236.704590,1675.353149 2232.460938,1692.041504 2227.992432,1708.669434 
	C2198.103027,1819.890015 2159.012695,1927.619019 2105.771729,2029.954590 
	C2079.447021,2080.554443 2049.921143,2129.197266 2016.009521,2175.977051 
M1182.546021,2034.130005 
	C1175.319092,2033.458008 1168.029175,2033.175781 1160.880981,2032.017456 
	C1151.469727,2030.492432 1141.891968,2029.130249 1132.878906,2026.187622 
	C1112.551147,2019.550659 1097.905762,2005.140869 1085.218140,1988.601196 
	C1080.623169,1982.611328 1076.614014,1976.172119 1072.017090,1968.504761 
	C1068.074707,1958.265015 1063.887451,1948.110474 1060.285034,1937.752563 
	C1058.247803,1931.895020 1054.283569,1929.217651 1048.585815,1928.446533 
	C1046.619019,1928.180298 1044.592773,1928.328003 1042.593750,1928.327759 
	C983.934631,1928.318726 925.275574,1928.311401 866.616455,1928.322998 
	C864.290161,1928.323486 861.921875,1928.264893 859.644714,1928.653442 
	C848.679077,1930.524536 845.124329,1937.954712 850.904663,1947.486084 
	C872.364319,1982.871826 896.912109,2016.003296 925.299255,2046.115234 
	C946.313354,2068.406250 968.143066,2089.864746 991.882141,2109.404053 
	C1024.644775,2136.370605 1059.531494,2160.049805 1096.759155,2180.298096 
	C1138.447144,2202.972412 1182.172485,2220.721191 1227.805420,2233.787109 
	C1277.862793,2248.119629 1328.949463,2257.181152 1380.859741,2259.530762 
	C1438.744507,2262.151123 1496.592529,2260.865967 1553.964478,2250.400391 
	C1588.723999,2244.059570 1622.928955,2235.814453 1656.364624,2224.576172 
	C1695.920044,2211.280518 1733.677002,2193.862061 1769.931885,2173.157715 
	C1814.962891,2147.441406 1856.008057,2116.372070 1893.767212,2080.951660 
	C1928.861816,2048.031250 1959.527222,2011.259155 1986.762573,1971.633545 
	C1992.789062,1962.865356 1998.054932,1953.576538 2003.730225,1944.564697 
	C2008.800903,1936.512817 2003.063110,1926.665649 1994.078491,1924.540527 
	C1992.167969,1924.088623 1990.109131,1924.164307 1988.118652,1924.163818 
	C1926.793213,1924.147095 1865.467773,1924.139893 1804.142334,1924.192505 
	C1801.630127,1924.194580 1799.118408,1924.789673 1796.687988,1925.098022 
	C1796.093018,1930.552002 1795.828979,1935.204346 1795.047363,1939.768066 
	C1790.736816,1964.938843 1778.965820,1985.947998 1760.147217,2003.270752 
	C1738.941284,2022.791260 1714.292480,2033.309326 1685.389404,2034.333252 
	C1649.934692,2035.589233 1615.006958,2030.745239 1580.165039,2025.465820 
	C1547.253296,2020.478882 1514.709229,2020.367432 1482.052368,2027.459106 
	C1465.863647,2030.974365 1449.573608,2034.053101 1432.776855,2034.447388 
	C1409.277222,2034.998901 1386.404785,2031.541992 1363.548950,2026.866211 
	C1334.582031,2020.940186 1305.503418,2019.611328 1276.458618,2026.343994 
	C1252.351440,2031.932251 1227.909302,2034.080078 1203.229980,2034.208618 
	C1196.900391,2034.241577 1190.571655,2034.426147 1182.546021,2034.130005 
M918.004822,1410.003906 
	C918.004822,1410.003906 918.051331,1410.043945 918.287292,1411.729980 
	C918.495972,1421.547852 912.166321,1430.671631 914.789917,1441.289307 
	C917.432190,1441.486450 919.703674,1441.802979 921.975220,1441.804077 
	C981.971252,1441.831543 1041.967285,1441.844360 1101.963257,1441.807739 
	C1110.890625,1441.802368 1111.115479,1441.548584 1113.918091,1433.066650 
	C1114.751221,1430.545288 1115.286011,1427.925781 1116.111206,1425.401611 
	C1117.468018,1421.250488 1118.950195,1417.140381 1120.988525,1411.254761 
	C1135.273804,1434.204712 1154.294434,1440.689697 1178.892578,1440.586548 
	C1355.544312,1439.845825 1532.200806,1440.190552 1708.855713,1440.190796 
	C1712.809814,1440.190796 1716.763916,1440.190796 1721.367554,1440.190796 
	C1732.514893,1414.274780 1743.450684,1388.850342 1754.386475,1363.425903 
	C1755.115356,1363.628784 1755.844238,1363.831787 1756.573120,1364.034668 
	C1754.683350,1389.390259 1752.793457,1414.745850 1750.827026,1441.130737 
	C1754.753784,1441.396973 1757.959961,1441.803711 1761.166138,1441.804443 
	C1882.158081,1441.829346 2003.150391,1441.935913 2124.141846,1441.686279 
	C2143.132568,1441.647095 2161.854736,1438.427979 2179.953125,1432.279053 
	C2191.821533,1428.246826 2202.414795,1421.913818 2211.104736,1413.012939 
	C2231.091309,1392.541626 2246.533691,1368.996948 2257.080322,1342.318237 
	C2257.643311,1340.894409 2258.718750,1339.672974 2259.447021,1338.528198 
	C2260.750244,1338.906982 2261.535645,1338.903564 2261.952881,1339.293823 
	C2264.872803,1342.023193 2267.667725,1344.886353 2270.592773,1347.609863 
	C2287.304443,1363.170776 2306.694824,1372.261597 2329.907227,1372.163208 
	C2342.572754,1372.109619 2355.239014,1372.120850 2367.904297,1372.208130 
	C2370.437500,1372.225586 2372.967529,1372.720703 2376.207764,1373.072632 
	C2375.813232,1376.399658 2375.768555,1379.027710 2375.158447,1381.517212 
	C2373.021973,1390.236206 2370.670166,1398.902466 2368.438232,1407.598511 
	C2365.301270,1419.820312 2365.315674,1419.834717 2352.453857,1419.835083 
	C2309.456787,1419.836426 2266.459473,1419.829590 2223.462402,1419.826050 
	C2219.914307,1419.825684 2216.366211,1419.825928 2213.375244,1419.825928 
	C2205.962646,1439.052612 2165.058350,1596.149658 2165.570801,1603.829102 
	C2169.116943,1603.829102 2172.728271,1603.829224 2176.339600,1603.829102 
	C2241.668701,1603.826538 2306.999023,1604.046875 2372.325439,1603.642700 
	C2386.203613,1603.556885 2400.368164,1602.576416 2413.883789,1599.633423 
	C2464.252197,1588.666504 2499.701416,1560.140625 2514.971436,1509.567871 
	C2519.774902,1493.658569 2523.736328,1477.493652 2528.010498,1461.426392 
	C2538.611084,1421.577881 2548.744629,1381.598511 2559.915527,1341.910889 
	C2565.924316,1320.562866 2565.104248,1299.948242 2558.148438,1279.203857 
	C2551.561279,1259.559937 2539.192383,1244.940552 2520.298828,1236.384277 
	C2507.747314,1230.700073 2494.212158,1228.633301 2480.513184,1228.259888 
	C2467.855469,1227.914795 2455.183350,1228.122437 2442.518066,1228.025635 
	C2439.310303,1228.001099 2436.104248,1227.744141 2431.799072,1227.542603 
	C2434.180420,1217.682617 2436.127441,1209.007935 2438.394531,1200.417847 
	C2440.330078,1193.083984 2442.633789,1185.847290 2444.994629,1177.818726 
	C2449.049316,1177.818726 2452.653564,1177.818604 2456.257812,1177.818726 
	C2497.921631,1177.820068 2539.585693,1177.836670 2581.249512,1177.810181 
	C2590.131836,1177.804565 2590.167969,1177.742798 2592.673340,1169.151001 
	C2597.422119,1152.865723 2602.170654,1136.580200 2606.837891,1120.271484 
	C2617.543701,1082.861694 2628.222412,1045.444092 2638.831787,1008.006897 
	C2639.604492,1005.280396 2639.731445,1002.370911 2640.364258,998.187134 
	C2635.207764,998.187134 2631.292969,998.187012 2627.377930,998.187134 
	C2561.715576,998.189392 2496.052979,998.102722 2430.391113,998.277344 
	C2418.780029,998.308228 2407.025635,998.478577 2395.590332,1000.233337 
	C2366.766846,1004.656433 2341.522705,1016.326233 2322.799561,1039.690552 
	C2321.147461,1041.752075 2319.516846,1043.830688 2317.092041,1046.891357 
	C2314.730225,1042.591187 2313.115234,1039.780518 2311.620605,1036.907227 
	C2305.285400,1024.728394 2296.111084,1015.352295 2283.965088,1008.949524 
	C2269.863525,1001.515747 2254.822510,997.879333 2238.870605,997.879272 
	C2161.542480,997.879089 2084.214355,997.840210 2006.886230,997.852173 
	C2004.286743,997.852539 2001.687256,998.249939 1998.418579,998.516907 
	C1984.875977,1046.150513 1970.974243,1093.417603 1957.953247,1142.479980 
	C1965.591553,1142.479980 1971.446655,1142.479980 1978.449951,1142.479980 
	C1977.783691,1147.181763 1977.572998,1150.762817 1976.741333,1154.193359 
	C1971.807739,1174.544312 1966.768555,1194.870117 1961.691162,1215.185791 
	C1955.887207,1238.408813 1949.989014,1261.608154 1944.180054,1284.829712 
	C1943.479980,1287.628174 1943.489990,1290.819824 1938.989502,1291.301147 
	C1938.619019,1289.859497 1937.981201,1288.649170 1938.063721,1287.490112 
	C1938.558960,1280.534180 1939.293091,1273.595337 1939.811157,1266.640747 
	C1941.317505,1246.421387 1942.577515,1226.181885 1944.270142,1205.978516 
	C1946.599243,1178.176147 1949.390137,1150.412231 1951.690674,1122.607788 
	C1953.417480,1101.735962 1954.567505,1080.816895 1956.202148,1059.936646 
	C1957.057129,1049.014771 1958.576538,1038.145508 1959.473389,1027.225952 
	C1960.249634,1017.776184 1960.570557,1008.289001 1961.147705,997.821533 
	C1956.949585,997.821533 1953.689331,997.821594 1950.429077,997.821533 
	C1884.433472,997.820923 1818.437866,997.820190 1752.442261,997.820496 
	C1750.775757,997.820496 1749.109009,997.851013 1747.442749,997.833923 
	C1742.221069,997.780396 1738.567139,1000.062134 1736.516724,1004.938660 
	C1734.323730,1010.153809 1732.057739,1015.339111 1729.765259,1020.511597 
	C1716.006348,1051.557007 1702.159546,1082.563599 1688.479248,1113.643677 
	C1672.923706,1148.984619 1657.396851,1184.339478 1642.146362,1219.812378 
	C1639.502441,1225.962646 1635.895264,1228.033081 1629.241943,1227.982422 
	C1590.246704,1227.685425 1551.248169,1227.851685 1512.250732,1227.805298 
	C1509.696045,1227.802246 1507.141968,1227.332275 1503.844238,1227.005859 
	C1504.240723,1223.749268 1504.239502,1221.104126 1504.912842,1218.643677 
	C1510.795898,1197.149292 1516.746704,1175.673096 1522.789917,1154.223145 
	C1525.702515,1143.885254 1525.811646,1143.916016 1535.992676,1143.818970 
	C1547.235596,1143.711792 1547.213257,1143.705688 1550.395264,1132.062134 
	C1561.880005,1090.038574 1573.396606,1048.023804 1584.831177,1005.986694 
	C1585.402710,1003.885681 1585.316650,1001.605835 1585.660400,998.175659 
	C1581.274048,998.175659 1577.688477,998.175354 1574.102905,998.175720 
	C1510.440430,998.181946 1446.778076,998.148438 1383.115601,998.218445 
	C1363.658447,998.239868 1368.484863,996.587585 1363.705078,1013.296814 
	C1361.103149,1022.392456 1358.602539,1031.517090 1356.014893,1040.776245 
	C1354.705444,1040.171265 1354.326660,1040.104858 1354.122681,1039.879883 
	C1353.677490,1039.388794 1353.306519,1038.827026 1352.931152,1038.276611 
	C1334.147705,1010.749268 1307.613281,997.951782 1274.777832,997.932068 
	C1086.457031,997.819275 898.136292,997.842957 709.815552,997.851440 
	C706.913208,997.851562 704.010803,998.248413 701.464478,998.434143 
	C697.689148,1008.738037 694.214111,1018.222412 690.475891,1028.425049 
	C688.110779,1026.501343 686.742432,1025.577271 685.598450,1024.429810 
	C667.153625,1005.928040 644.588684,998.097839 618.790894,998.162476 
	C576.794006,998.267700 534.795410,997.991516 492.800476,998.292664 
	C484.241425,998.354065 475.433716,999.272949 467.187012,1001.471069 
	C421.182983,1013.733093 393.607483,1044.684326 380.223114,1089.310913 
	C376.022888,1103.315308 372.812134,1117.613525 368.972931,1131.729614 
	C351.343262,1196.550659 333.633850,1261.350098 315.988159,1326.166748 
	C303.670959,1371.410522 291.432770,1416.675781 279.115479,1461.919556 
	C272.738983,1485.341675 266.671082,1508.567627 269.694946,1533.468018 
	C274.567413,1573.591187 302.050568,1597.455200 340.238861,1601.597290 
	C361.021637,1603.851562 382.125641,1603.587646 403.086670,1603.636475 
	C426.044617,1603.689941 449.011658,1602.221924 471.961823,1602.496216 
	C490.029236,1602.712158 506.919464,1598.805786 523.343750,1591.911011 
	C555.598511,1578.370850 579.734253,1557.178833 589.740295,1522.287354 
	C598.358521,1492.234985 607.602417,1462.362061 616.184082,1432.299683 
	C620.797607,1416.137695 624.600159,1399.744141 629.088501,1382.205078 
	C634.862732,1390.966309 639.633667,1398.823975 645.008789,1406.243774 
	C660.691467,1427.892456 682.128601,1439.428589 708.782349,1439.701050 
	C753.441345,1440.157593 798.110596,1440.064941 842.771118,1439.686157 
	C860.215210,1439.538330 877.338623,1436.157349 892.709717,1427.508545 
	C901.604004,1422.504028 909.577393,1415.863037 918.004822,1410.003906 
M1956.999756,1880.063477 
	C1968.328979,1880.062256 1979.659302,1879.962280 1990.986694,1880.104126 
	C1996.222656,1880.169556 2000.842773,1879.648438 2003.882935,1874.501221 
	C2007.018799,1869.191895 2004.577026,1865.163452 2001.886475,1860.715454 
	C1983.753784,1830.738525 1962.911133,1802.813843 1939.910278,1776.379639 
	C1916.479736,1749.451050 1891.224731,1724.401367 1863.910400,1701.525269 
	C1832.216064,1674.981323 1798.062378,1651.936401 1761.935791,1631.712524 
	C1715.471191,1605.701782 1666.356934,1586.359619 1615.130981,1572.373779 
	C1582.417358,1563.442261 1549.184448,1556.690308 1515.319336,1553.722534 
	C1492.165283,1551.693237 1469.006348,1549.150513 1445.800781,1548.442871 
	C1424.222290,1547.784912 1402.558716,1548.843994 1380.970215,1549.909058 
	C1362.399414,1550.825073 1343.752197,1551.907349 1325.368652,1554.529175 
	C1291.818359,1559.313354 1258.624023,1565.956543 1225.983032,1575.466919 
	C1167.754517,1592.432373 1112.810303,1616.521851 1061.637573,1649.078979 
	C1011.983215,1680.669922 966.930237,1717.700073 927.187561,1761.307861 
	C899.509888,1791.677612 874.891296,1824.210693 852.864502,1858.800171 
	C851.439026,1861.038818 850.121582,1863.407471 849.170776,1865.876099 
	C845.751038,1874.754761 850.671936,1882.885864 860.143372,1884.113892 
	C862.114258,1884.369385 864.136719,1884.262939 866.135437,1884.263184 
	C926.779602,1884.268188 987.423706,1884.275635 1048.067871,1884.233643 
	C1050.944580,1884.231689 1053.820923,1883.782959 1057.019775,1883.515381 
	C1057.332886,1880.883911 1057.753662,1878.952393 1057.761841,1877.018921 
	C1057.815308,1864.407349 1061.315918,1852.349976 1066.852051,1841.493164 
	C1088.670532,1798.705322 1123.932739,1775.898560 1172.127808,1774.254883 
	C1184.377563,1773.837280 1196.724976,1774.892944 1208.966553,1775.966064 
	C1224.835205,1777.356812 1240.644409,1779.431030 1256.476685,1781.232910 
	C1275.609619,1783.410522 1294.690308,1786.460327 1313.884277,1787.585327 
	C1337.611572,1788.976074 1360.821045,1783.855591 1383.928711,1779.017822 
	C1403.566528,1774.906372 1423.465210,1772.302124 1443.384033,1774.659790 
	C1465.118042,1777.232544 1486.532349,1782.812012 1508.293701,1784.757690 
	C1527.046143,1786.434448 1546.540405,1787.404785 1564.912354,1784.172607 
	C1603.735596,1777.341797 1642.560059,1773.112671 1681.923218,1774.221802 
	C1695.947998,1774.616943 1709.573853,1777.433350 1722.572266,1783.151245 
	C1748.708252,1794.648682 1767.382568,1813.916016 1780.204102,1838.917603 
	C1785.339355,1848.930908 1788.873901,1859.777466 1792.953003,1870.315918 
	C1795.897217,1877.922241 1797.988525,1879.663696 1806.056030,1880.041382 
	C1808.383545,1880.150391 1810.720215,1880.064575 1813.052734,1880.064575 
	C1860.368530,1880.064575 1907.684204,1880.064209 1956.999756,1880.063477 
M1282.756958,1967.565918 
	C1284.756836,1967.551514 1286.757324,1967.567871 1288.756348,1967.517944 
	C1299.808228,1967.241699 1300.046143,1967.006836 1300.135498,1956.261597 
	C1300.168701,1952.262695 1300.171509,1948.263550 1300.177002,1944.264526 
	C1300.183716,1939.425659 1300.178589,1934.586792 1300.178589,1929.852661 
	C1319.849365,1929.852661 1338.042358,1929.852661 1357.500488,1929.852661 
	C1357.500488,1942.579468 1357.500488,1954.730957 1357.500488,1967.306030 
	C1361.299927,1967.701904 1363.882080,1968.174072 1366.469238,1968.204102 
	C1375.799316,1968.312500 1385.131836,1968.357666 1394.461792,1968.264526 
	C1404.134766,1968.167969 1404.426514,1967.847046 1404.511108,1957.997925 
	C1404.590698,1948.744385 1404.528076,1939.489868 1404.528076,1929.948486 
	C1424.432983,1929.948486 1443.171265,1929.948486 1462.431152,1929.948486 
	C1462.431152,1939.597778 1462.303589,1948.558350 1462.469849,1957.513428 
	C1462.614380,1965.303101 1463.450439,1966.167114 1471.113770,1966.307251 
	C1481.107910,1966.490112 1491.109619,1966.518188 1501.104126,1966.360962 
	C1509.662354,1966.226562 1510.038208,1965.782471 1510.152588,1957.155396 
	C1510.270996,1948.227173 1510.179077,1939.296143 1510.179077,1929.881348 
	C1529.545288,1929.881348 1547.971191,1929.881348 1567.197632,1929.881348 
	C1567.197632,1939.769531 1567.096069,1949.039917 1567.227539,1958.306885 
	C1567.348145,1966.816040 1568.051514,1967.558960 1576.310303,1967.703735 
	C1585.638672,1967.867310 1594.972778,1967.864990 1604.302368,1967.757812 
	C1614.251343,1967.643555 1614.506470,1967.400146 1614.646851,1957.640503 
	C1614.779175,1948.446899 1614.675171,1939.250000 1614.675171,1930.285278 
	C1629.126831,1928.431396 1672.463257,1928.883057 1680.835205,1931.132568 
	C1677.537109,1946.128662 1674.224121,1961.192139 1670.841309,1976.573975 
	C1683.490479,1976.573975 1696.636719,1976.573975 1709.423950,1976.573975 
	C1734.112549,1928.853271 1739.059448,1881.692871 1708.181641,1835.042969 
	C1695.631348,1835.042969 1682.202515,1835.042969 1667.838501,1835.042969 
	C1675.758911,1851.120605 1684.124634,1865.844238 1683.383789,1883.635986 
	C1660.363647,1883.635986 1638.163330,1883.635986 1615.585327,1883.635986 
	C1614.064575,1870.210815 1616.623657,1856.921021 1614.219727,1843.760864 
	C1600.001587,1843.760864 1587.044556,1843.676636 1574.089722,1843.806763 
	C1569.605347,1843.851807 1567.613281,1846.756348 1567.494263,1851.002563 
	C1567.382324,1854.998657 1567.407959,1858.998779 1567.400757,1862.997192 
	C1567.388306,1869.870483 1567.397339,1876.743896 1567.397339,1883.651733 
	C1547.783325,1883.651733 1529.564209,1883.651733 1510.774414,1883.651733 
	C1510.612427,1881.312256 1510.368408,1879.396484 1510.366089,1877.480591 
	C1510.355591,1868.482910 1510.525757,1859.483643 1510.408936,1850.487915 
	C1510.308105,1842.730225 1509.640869,1842.118652 1501.675415,1842.079224 
	C1493.011719,1842.036255 1484.346680,1842.175415 1475.682861,1842.280884 
	C1463.967896,1842.423462 1462.665039,1843.738525 1462.593140,1855.665039 
	C1462.537476,1864.888672 1462.581909,1874.112915 1462.581909,1883.666260 
	C1442.947388,1883.666260 1424.439819,1883.666260 1404.542236,1883.666260 
	C1404.542236,1870.499878 1404.542236,1857.739258 1404.542236,1844.151855 
	C1391.574341,1844.151855 1379.978516,1844.054199 1368.385376,1844.182251 
	C1358.761353,1844.288574 1357.446045,1845.634399 1357.323486,1855.257812 
	C1357.205322,1864.534546 1357.297974,1873.813965 1357.297974,1883.519409 
	C1337.994873,1883.519409 1319.545776,1883.519409 1300.123047,1883.519409 
	C1300.123047,1870.081543 1300.123047,1857.216797 1300.123047,1843.553833 
	C1295.988281,1843.232422 1292.760742,1842.760254 1289.535156,1842.773804 
	C1281.205444,1842.808838 1272.875977,1843.031494 1264.547485,1843.229004 
	C1253.793945,1843.484131 1252.604736,1844.668579 1252.516357,1855.532349 
	C1252.441284,1864.771851 1252.500854,1874.012451 1252.500854,1883.571289 
	C1229.682129,1883.571289 1207.885864,1883.571289 1185.637817,1883.571289 
	C1186.991089,1866.779297 1192.539429,1851.642456 1198.404785,1836.354248 
	C1183.348877,1836.354248 1169.446045,1836.354248 1155.120483,1836.354248 
	C1152.217041,1842.601074 1149.119019,1848.488647 1146.681396,1854.637695 
	C1130.836060,1894.609375 1136.206421,1932.951538 1156.076050,1970.013672 
	C1157.359375,1972.407471 1159.988403,1975.653931 1162.122925,1975.756226 
	C1173.860596,1976.318359 1185.639160,1976.024536 1198.229126,1976.024536 
	C1192.407593,1960.139648 1185.351685,1946.129028 1184.643555,1929.808105 
	C1207.370483,1929.808105 1229.491211,1929.808105 1252.327026,1929.808105 
	C1252.327026,1938.944092 1252.283325,1947.544312 1252.338379,1956.144043 
	C1252.404785,1966.541992 1253.134521,1967.251099 1263.848877,1967.442993 
	C1269.512817,1967.544434 1275.178833,1967.529419 1282.756958,1967.565918 
M1288.957153,2456.065430 
	C1313.470459,2468.760498 1337.683350,2482.088867 1362.611328,2493.909668 
	C1377.271606,2500.862061 1392.874512,2506.050781 1408.427612,2510.810059 
	C1417.483887,2513.581543 1427.449585,2515.837891 1436.708252,2515.006592 
	C1459.722534,2512.939697 1481.283813,2504.544189 1502.457886,2495.677490 
	C1532.878052,2482.938965 1562.184448,2467.950195 1590.441772,2450.889404 
	C1627.646362,2428.426758 1662.726685,2403.224854 1691.954590,2370.796875 
	C1700.799194,2360.983643 1707.879395,2349.567627 1715.587646,2338.757568 
	C1716.708008,2337.186279 1716.817871,2334.894531 1717.797974,2331.570557 
	C1710.328979,2335.872314 1704.458496,2339.614746 1698.279785,2342.749756 
	C1670.844238,2356.671387 1641.688477,2366.015625 1612.077026,2373.810547 
	C1581.902954,2381.753418 1551.383667,2388.110107 1520.430786,2392.563721 
	C1496.750610,2395.970947 1473.062378,2399.181885 1449.182129,2399.735107 
	C1431.268799,2400.149902 1413.267944,2399.327393 1395.388306,2397.971436 
	C1375.555420,2396.467285 1355.601685,2394.885498 1336.085815,2391.262695 
	C1306.088867,2385.693848 1276.174561,2379.288818 1246.678711,2371.517090 
	C1213.547119,2362.787354 1180.809937,2352.417969 1151.759644,2333.243652 
	C1151.083374,2332.797363 1150.031494,2332.919922 1149.261719,2332.794434 
	C1148.959106,2333.377686 1148.631836,2333.725342 1148.706665,2333.910156 
	C1149.192871,2335.114014 1149.647339,2336.356689 1150.328979,2337.451660 
	C1163.108276,2357.978027 1179.415649,2375.267578 1197.633789,2391.130615 
	C1225.608643,2415.489014 1255.869141,2436.465332 1288.957153,2456.065430 
M2029.887939,1685.173218 
	C2044.880859,1704.452026 2059.873535,1723.730713 2075.448730,1743.758179 
	C2109.124023,1671.526367 2134.247070,1597.107056 2156.508301,1520.478271 
	C2025.479980,1520.478271 1895.784790,1520.478271 1763.628540,1520.478271 
	C1766.966309,1521.520020 1768.079956,1521.872681 1769.196777,1522.215332 
	C1797.836914,1531.001831 1825.885254,1541.422607 1853.146606,1553.809692 
	C1911.595703,1580.367554 1963.413940,1616.578369 2008.689941,1662.088135 
	C2015.719727,1669.154175 2022.202515,1676.764648 2029.887939,1685.173218 
M981.476624,1562.386841 
	C996.653931,1554.781982 1011.603394,1546.670410 1027.072876,1539.715332 
	C1042.415527,1532.817383 1058.282104,1527.084351 1073.918945,1520.840332 
	C1073.817871,1520.483521 1073.716675,1520.126587 1073.615601,1519.769653 
	C1069.965576,1519.769653 1066.315674,1519.769775 1062.665649,1519.769775 
	C991.074097,1519.768921 919.482544,1519.768066 847.890991,1519.767090 
	C810.929749,1519.766602 773.968567,1519.740723 737.007324,1519.783691 
	C728.071716,1519.794067 726.538147,1520.819824 726.208557,1528.907104 
	C725.286926,1551.518311 725.213501,1574.167114 728.650269,1596.588867 
	C731.259766,1613.613892 734.341858,1630.602295 738.111755,1647.405518 
	C744.386292,1675.372559 752.982483,1702.606445 766.954590,1727.850830 
	C768.140991,1729.994385 769.881287,1731.831177 770.820801,1733.085205 
	C789.250122,1714.316284 806.472290,1695.407715 825.177307,1678.102051 
	C844.908936,1659.846802 865.612366,1642.561279 886.691040,1625.861694 
	C916.168152,1602.507935 947.572876,1581.865845 981.476624,1562.386841 
z"/>
                <path fill="#4F5A79" opacity="1.000000" stroke="none"
                    d="
M788.000000,890.109009 
	C703.380188,890.109009 619.760315,890.109009 534.763733,890.109009 
	C533.713257,839.643188 532.677673,789.891846 531.606689,738.439575 
	C536.234070,740.509033 539.210266,741.687927 542.051086,743.132935 
	C601.156921,773.200378 663.896545,791.396240 729.532288,799.521851 
	C787.674316,806.719788 845.869812,806.029358 903.983948,798.440979 
	C1070.979370,776.635559 1220.223633,711.481079 1355.536865,612.815002 
	C1382.148926,593.410339 1407.187378,571.847778 1433.653076,550.720520 
	C1435.342041,552.164917 1437.847168,554.175964 1440.207275,556.344849 
	C1537.111450,645.403137 1647.073120,712.960144 1771.217163,757.052185 
	C1831.380859,778.420410 1893.232178,792.831848 1956.732300,799.496399 
	C2084.454834,812.901306 2207.089844,794.060730 2324.032227,740.258667 
	C2326.998047,738.894226 2330.044922,737.706482 2334.186279,735.958313 
	C2334.882568,787.377258 2332.622314,837.788208 2331.720215,887.957275 
	C2331.115234,888.491089 2330.871582,888.720154 2330.612549,888.930359 
	C2330.363037,889.132446 2330.123291,889.406860 2329.834229,889.487732 
	C2329.196777,889.665833 2328.532471,889.832886 2327.876221,889.844360 
	C2324.877197,889.896973 2321.877441,889.908936 2318.877930,889.909363 
	C1808.918579,889.977356 1298.959351,890.043152 788.000000,890.109009 
z"/>
                <path fill="#962123" opacity="1.000000" stroke="none"
                    d="
M917.965210,1409.958496 
	C909.577393,1415.863037 901.604004,1422.504028 892.709717,1427.508545 
	C877.338623,1436.157349 860.215210,1439.538330 842.771118,1439.686157 
	C798.110596,1440.064941 753.441345,1440.157593 708.782349,1439.701050 
	C682.128601,1439.428589 660.691467,1427.892456 645.008789,1406.243774 
	C639.633667,1398.823975 634.862732,1390.966309 629.088501,1382.205078 
	C624.600159,1399.744141 620.797607,1416.137695 616.184082,1432.299683 
	C607.602417,1462.362061 598.358521,1492.234985 589.740295,1522.287354 
	C579.734253,1557.178833 555.598511,1578.370850 523.343750,1591.911011 
	C506.919464,1598.805786 490.029236,1602.712158 471.961823,1602.496216 
	C449.011658,1602.221924 426.044617,1603.689941 403.086670,1603.636475 
	C382.125641,1603.587646 361.021637,1603.851562 340.238861,1601.597290 
	C302.050568,1597.455200 274.567413,1573.591187 269.694946,1533.468018 
	C266.671082,1508.567627 272.738983,1485.341675 279.115479,1461.919556 
	C291.432770,1416.675781 303.670959,1371.410522 315.988159,1326.166748 
	C333.633850,1261.350098 351.343262,1196.550659 368.972931,1131.729614 
	C372.812134,1117.613525 376.022888,1103.315308 380.223114,1089.310913 
	C393.607483,1044.684326 421.182983,1013.733093 467.187012,1001.471069 
	C475.433716,999.272949 484.241425,998.354065 492.800476,998.292664 
	C534.795410,997.991516 576.794006,998.267700 618.790894,998.162476 
	C644.588684,998.097839 667.153625,1005.928040 685.598450,1024.429810 
	C686.742432,1025.577271 688.110779,1026.501343 690.475891,1028.425049 
	C694.214111,1018.222412 697.689148,1008.738037 701.464478,998.434143 
	C704.010803,998.248413 706.913208,997.851562 709.815552,997.851440 
	C898.136292,997.842957 1086.457031,997.819275 1274.777832,997.932068 
	C1307.613281,997.951782 1334.147705,1010.749268 1352.931152,1038.276611 
	C1353.306519,1038.827026 1353.677490,1039.388794 1354.122681,1039.879883 
	C1354.326660,1040.104858 1354.705444,1040.171265 1356.014893,1040.776245 
	C1358.602539,1031.517090 1361.103149,1022.392456 1363.705078,1013.296814 
	C1368.484863,996.587585 1363.658447,998.239868 1383.115601,998.218445 
	C1446.778076,998.148438 1510.440430,998.181946 1574.102905,998.175720 
	C1577.688477,998.175354 1581.274048,998.175659 1585.660400,998.175659 
	C1585.316650,1001.605835 1585.402710,1003.885681 1584.831177,1005.986694 
	C1573.396606,1048.023804 1561.880005,1090.038574 1550.395264,1132.062134 
	C1547.213257,1143.705688 1547.235596,1143.711792 1535.992676,1143.818970 
	C1525.811646,1143.916016 1525.702515,1143.885254 1522.789917,1154.223145 
	C1516.746704,1175.673096 1510.795898,1197.149292 1504.912842,1218.643677 
	C1504.239502,1221.104126 1504.240723,1223.749268 1503.844238,1227.005859 
	C1507.141968,1227.332275 1509.696045,1227.802246 1512.250732,1227.805298 
	C1551.248169,1227.851685 1590.246704,1227.685425 1629.241943,1227.982422 
	C1635.895264,1228.033081 1639.502441,1225.962646 1642.146362,1219.812378 
	C1657.396851,1184.339478 1672.923706,1148.984619 1688.479248,1113.643677 
	C1702.159546,1082.563599 1716.006348,1051.557007 1729.765259,1020.511597 
	C1732.057739,1015.339111 1734.323730,1010.153809 1736.516724,1004.938660 
	C1738.567139,1000.062134 1742.221069,997.780396 1747.442749,997.833923 
	C1749.109009,997.851013 1750.775757,997.820496 1752.442261,997.820496 
	C1818.437866,997.820190 1884.433472,997.820923 1950.429077,997.821533 
	C1953.689331,997.821594 1956.949585,997.821533 1961.147705,997.821533 
	C1960.570557,1008.289001 1960.249634,1017.776184 1959.473389,1027.225952 
	C1958.576538,1038.145508 1957.057129,1049.014771 1956.202148,1059.936646 
	C1954.567505,1080.816895 1953.417480,1101.735962 1951.690674,1122.607788 
	C1949.390137,1150.412231 1946.599243,1178.176147 1944.270142,1205.978516 
	C1942.577515,1226.181885 1941.317505,1246.421387 1939.811157,1266.640747 
	C1939.293091,1273.595337 1938.558960,1280.534180 1938.063721,1287.490112 
	C1937.981201,1288.649170 1938.619019,1289.859497 1938.989502,1291.301147 
	C1943.489990,1290.819824 1943.479980,1287.628174 1944.180054,1284.829712 
	C1949.989014,1261.608154 1955.887207,1238.408813 1961.691162,1215.185791 
	C1966.768555,1194.870117 1971.807739,1174.544312 1976.741333,1154.193359 
	C1977.572998,1150.762817 1977.783691,1147.181763 1978.449951,1142.479980 
	C1971.446655,1142.479980 1965.591553,1142.479980 1957.953247,1142.479980 
	C1970.974243,1093.417603 1984.875977,1046.150513 1998.418579,998.516907 
	C2001.687256,998.249939 2004.286743,997.852539 2006.886230,997.852173 
	C2084.214355,997.840210 2161.542480,997.879089 2238.870605,997.879272 
	C2254.822510,997.879333 2269.863525,1001.515747 2283.965088,1008.949524 
	C2296.111084,1015.352295 2305.285400,1024.728394 2311.620605,1036.907227 
	C2313.115234,1039.780518 2314.730225,1042.591187 2317.092041,1046.891357 
	C2319.516846,1043.830688 2321.147461,1041.752075 2322.799561,1039.690552 
	C2341.522705,1016.326233 2366.766846,1004.656433 2395.590332,1000.233337 
	C2407.025635,998.478577 2418.780029,998.308228 2430.391113,998.277344 
	C2496.052979,998.102722 2561.715576,998.189392 2627.377930,998.187134 
	C2631.292969,998.187012 2635.207764,998.187134 2640.364258,998.187134 
	C2639.731445,1002.370911 2639.604492,1005.280396 2638.831787,1008.006897 
	C2628.222412,1045.444092 2617.543701,1082.861694 2606.837891,1120.271484 
	C2602.170654,1136.580200 2597.422119,1152.865723 2592.673340,1169.151001 
	C2590.167969,1177.742798 2590.131836,1177.804565 2581.249512,1177.810181 
	C2539.585693,1177.836670 2497.921631,1177.820068 2456.257812,1177.818726 
	C2452.653564,1177.818604 2449.049316,1177.818726 2444.994629,1177.818726 
	C2442.633789,1185.847290 2440.330078,1193.083984 2438.394531,1200.417847 
	C2436.127441,1209.007935 2434.180420,1217.682617 2431.799072,1227.542603 
	C2436.104248,1227.744141 2439.310303,1228.001099 2442.518066,1228.025635 
	C2455.183350,1228.122437 2467.855469,1227.914795 2480.513184,1228.259888 
	C2494.212158,1228.633301 2507.747314,1230.700073 2520.298828,1236.384277 
	C2539.192383,1244.940552 2551.561279,1259.559937 2558.148438,1279.203857 
	C2565.104248,1299.948242 2565.924316,1320.562866 2559.915527,1341.910889 
	C2548.744629,1381.598511 2538.611084,1421.577881 2528.010498,1461.426392 
	C2523.736328,1477.493652 2519.774902,1493.658569 2514.971436,1509.567871 
	C2499.701416,1560.140625 2464.252197,1588.666504 2413.883789,1599.633423 
	C2400.368164,1602.576416 2386.203613,1603.556885 2372.325439,1603.642700 
	C2306.999023,1604.046875 2241.668701,1603.826538 2176.339600,1603.829102 
	C2172.728271,1603.829224 2169.116943,1603.829102 2165.570801,1603.829102 
	C2165.058350,1596.149658 2205.962646,1439.052612 2213.375244,1419.825928 
	C2216.366211,1419.825928 2219.914307,1419.825684 2223.462402,1419.826050 
	C2266.459473,1419.829590 2309.456787,1419.836426 2352.453857,1419.835083 
	C2365.315674,1419.834717 2365.301270,1419.820312 2368.438232,1407.598511 
	C2370.670166,1398.902466 2373.021973,1390.236206 2375.158447,1381.517212 
	C2375.768555,1379.027710 2375.813232,1376.399658 2376.207764,1373.072632 
	C2372.967529,1372.720703 2370.437500,1372.225586 2367.904297,1372.208130 
	C2355.239014,1372.120850 2342.572754,1372.109619 2329.907227,1372.163208 
	C2306.694824,1372.261597 2287.304443,1363.170776 2270.592773,1347.609863 
	C2267.667725,1344.886353 2264.872803,1342.023193 2261.952881,1339.293823 
	C2261.535645,1338.903564 2260.750244,1338.906982 2259.447021,1338.528198 
	C2258.718750,1339.672974 2257.643311,1340.894409 2257.080322,1342.318237 
	C2246.533691,1368.996948 2231.091309,1392.541626 2211.104736,1413.012939 
	C2202.414795,1421.913818 2191.821533,1428.246826 2179.953125,1432.279053 
	C2161.854736,1438.427979 2143.132568,1441.647095 2124.141846,1441.686279 
	C2003.150391,1441.935913 1882.158081,1441.829346 1761.166138,1441.804443 
	C1757.959961,1441.803711 1754.753784,1441.396973 1750.827026,1441.130737 
	C1752.793457,1414.745850 1754.683350,1389.390259 1756.573120,1364.034668 
	C1755.844238,1363.831787 1755.115356,1363.628784 1754.386475,1363.425903 
	C1743.450684,1388.850342 1732.514893,1414.274780 1721.367554,1440.190796 
	C1716.763916,1440.190796 1712.809814,1440.190796 1708.855713,1440.190796 
	C1532.200806,1440.190552 1355.544312,1439.845825 1178.892578,1440.586548 
	C1154.294434,1440.689697 1135.273804,1434.204712 1120.988525,1411.254761 
	C1118.950195,1417.140381 1117.468018,1421.250488 1116.111206,1425.401611 
	C1115.286011,1427.925781 1114.751221,1430.545288 1113.918091,1433.066650 
	C1111.115479,1441.548584 1110.890625,1441.802368 1101.963257,1441.807739 
	C1041.967285,1441.844360 981.971252,1441.831543 921.975220,1441.804077 
	C919.703674,1441.802979 917.432190,1441.486450 914.789917,1441.289307 
	C912.166321,1430.671631 918.495972,1421.547852 918.518677,1410.810181 
	C918.488464,1409.913086 918.226807,1409.935791 917.965210,1409.958496 
M1705.333130,1403.683594 
	C1705.609375,1402.463623 1705.885742,1401.243652 1706.027100,1399.996216 
	C1706.027100,1399.996216 1706.089111,1400.119019 1707.240845,1399.345947 
	C1707.553589,1397.576782 1707.866455,1395.807739 1708.022461,1394.023560 
	C1708.022461,1394.023560 1708.132690,1394.136230 1709.372803,1393.736816 
	C1709.640503,1392.498779 1709.908203,1391.260742 1710.030518,1390.004639 
	C1710.030518,1390.004639 1710.112549,1390.126221 1711.300537,1389.459839 
	C1711.646729,1388.145752 1711.992798,1386.831787 1712.387695,1385.226685 
	C1712.387695,1385.226685 1712.117798,1385.107544 1713.353638,1384.155762 
	C1713.658569,1382.658691 1713.963501,1381.161621 1715.088135,1378.598145 
	C1716.019287,1376.854004 1717.097412,1375.171387 1717.858154,1373.355835 
	C1723.638428,1359.563232 1729.622803,1345.846924 1734.980225,1331.890503 
	C1737.602051,1325.060425 1741.323730,1322.060913 1748.999512,1322.401001 
	C1761.942383,1322.974609 1774.936401,1322.395264 1788.057861,1324.065430 
	C1787.994141,1326.378174 1788.079468,1328.706177 1787.843994,1331.001343 
	C1785.514893,1353.703491 1783.120117,1376.399048 1780.779907,1399.100098 
	C1780.475342,1402.055298 1780.112549,1405.053955 1780.362793,1407.982544 
	C1780.426758,1408.731323 1782.730713,1409.288574 1785.839722,1410.345947 
	C1833.110352,1410.744873 1880.380249,1411.337891 1927.651611,1411.497070 
	C1998.584473,1411.735840 2069.518555,1411.674438 2140.451904,1411.730835 
	C2158.023193,1411.744873 2173.719727,1406.356323 2187.744873,1395.713013 
	C2211.872314,1377.403076 2225.419434,1352.325806 2233.740967,1323.985352 
	C2237.673096,1310.593262 2240.728516,1296.946655 2244.418457,1283.480347 
	C2259.879883,1227.052612 2275.463135,1170.658081 2290.925781,1114.230469 
	C2294.543213,1101.029053 2297.024902,1087.583496 2295.694336,1073.845459 
	C2294.287842,1059.321167 2290.926758,1045.557495 2275.819580,1036.469360 
	C2266.275879,1029.795776 2255.373535,1029.240845 2244.264404,1029.249878 
	C2172.637451,1029.307983 2101.010498,1029.291870 2029.383667,1029.300171 
	C2025.795776,1029.300537 2022.207886,1029.300171 2019.186035,1029.300171 
	C2018.443481,1030.363159 2018.175171,1030.601685 2018.086670,1030.894653 
	C2010.114258,1057.276245 2002.142090,1083.657959 1994.282349,1110.073120 
	C1994.105103,1110.668701 1995.212524,1111.646606 1996.134766,1114.010376 
	C1998.149292,1114.682129 2000.135620,1115.807373 2002.183960,1115.934570 
	C2007.000488,1116.234131 2011.846680,1116.057007 2016.520630,1117.701660 
	C2016.198364,1119.332397 2015.962769,1120.985962 2015.541382,1122.590698 
	C1999.253540,1184.617432 1982.951782,1246.640381 1966.640015,1308.660645 
	C1965.394043,1313.397705 1964.058472,1318.111084 1962.645874,1323.268921 
	C1943.997437,1323.268921 1926.097900,1323.268921 1907.918579,1321.529663 
	C1908.487427,1311.595093 1908.920776,1301.650513 1909.649780,1291.727783 
	C1912.278198,1255.956543 1915.070312,1220.197388 1917.676880,1184.424683 
	C1919.970215,1152.952148 1922.024292,1121.462036 1924.337036,1089.990845 
	C1925.188599,1078.403076 1926.833496,1066.866089 1927.443237,1055.270508 
	C1927.852783,1047.481323 1927.079468,1039.629883 1926.462036,1030.088867 
	C1923.732666,1029.478760 1921.004517,1028.342285 1918.273926,1028.336792 
	C1867.295898,1028.233887 1816.317871,1028.258667 1765.339722,1028.290894 
	C1763.074097,1028.292358 1760.808716,1028.618652 1758.104248,1028.827515 
	C1714.579590,1127.205078 1671.131226,1225.410034 1627.616699,1323.764648 
	C1616.533325,1323.764648 1606.601562,1323.764648 1596.017822,1323.764648 
	C1588.468872,1351.488525 1581.049072,1378.738281 1572.535645,1407.052856 
	C1571.124023,1413.833008 1576.318726,1411.873901 1579.375122,1411.888672 
	C1617.558350,1412.072754 1655.742310,1412.114990 1693.926025,1412.170044 
	C1695.908081,1412.172852 1698.058228,1412.386963 1699.811035,1411.694824 
	C1700.899780,1411.264893 1701.386230,1409.310059 1702.004395,1408.027222 
	C1702.004395,1408.027222 1702.096924,1408.131104 1703.399780,1407.813354 
	C1703.652100,1406.547241 1703.904419,1405.281128 1704.013306,1404.023560 
	C1704.013306,1404.023560 1704.123047,1404.116455 1705.333130,1403.683594 
M1287.410522,1411.406860 
	C1288.399780,1411.514893 1289.389160,1411.717407 1290.378418,1411.717529 
	C1377.846191,1411.734375 1465.313965,1411.751343 1552.781738,1411.714233 
	C1561.981445,1411.710327 1562.608398,1411.120972 1565.391113,1401.138550 
	C1577.692627,1357.008423 1589.963501,1312.869995 1602.156128,1268.709839 
	C1602.714111,1266.689331 1602.407349,1264.430176 1602.146606,1260.612305 
	C1599.087524,1259.854492 1596.031982,1258.456055 1592.968628,1258.438477 
	C1563.336548,1258.267822 1533.703247,1258.312988 1504.070190,1258.356445 
	C1501.831299,1258.359741 1499.593140,1258.822876 1497.270142,1259.081665 
	C1491.993042,1280.411865 1486.826904,1301.293091 1481.460938,1322.982666 
	C1470.145996,1322.982666 1459.552979,1322.982666 1449.195557,1321.156250 
	C1449.883301,1317.903564 1450.424072,1314.611572 1451.280640,1311.403931 
	C1463.894165,1264.166016 1476.544189,1216.937866 1489.198486,1169.710815 
	C1493.747437,1152.733521 1498.329834,1135.765137 1503.128784,1117.931763 
	C1510.401367,1117.931763 1517.235596,1117.931763 1524.069214,1117.931763 
	C1525.340698,1114.566040 1526.463013,1112.166382 1527.164673,1109.649536 
	C1533.591797,1086.593628 1539.979736,1063.526611 1546.276978,1040.435059 
	C1547.044556,1037.620361 1547.209595,1034.641357 1548.328003,1030.365601 
	C1548.741455,1028.544678 1549.154785,1026.723633 1549.725098,1024.211060 
	C1494.905884,1024.211060 1440.851807,1024.211060 1386.733154,1024.211060 
	C1385.982422,1026.090942 1385.196289,1027.570923 1384.790161,1029.148804 
	C1378.002441,1055.530273 1371.244385,1081.919312 1364.548950,1108.324219 
	C1364.072266,1110.203979 1363.914673,1112.241943 1364.103760,1114.157471 
	C1364.149536,1114.620850 1366.166870,1114.889648 1369.101196,1115.840576 
	C1374.681274,1115.955322 1380.261475,1116.070068 1385.894409,1117.750488 
	C1368.744995,1183.785400 1351.600220,1249.821655 1334.428955,1315.850830 
	C1333.777466,1318.355591 1332.868896,1320.793457 1331.853027,1323.973633 
	C1322.909546,1323.973633 1314.360229,1323.973633 1305.157104,1323.973633 
	C1297.615967,1353.096069 1290.251953,1381.534912 1282.856567,1410.094727 
	C1284.074829,1410.264893 1285.053345,1410.401611 1287.410522,1411.406860 
M1170.436523,1261.376465 
	C1170.732300,1263.811646 1171.776855,1266.437988 1171.211304,1268.653320 
	C1162.751465,1301.786499 1153.950317,1334.833130 1145.575928,1367.987549 
	C1144.063477,1373.975342 1143.402710,1380.372437 1143.562744,1386.549072 
	C1143.922119,1400.411865 1155.087036,1408.680664 1166.848145,1408.869141 
	C1186.446655,1409.182861 1206.038208,1409.928955 1225.632080,1410.515991 
	C1238.914795,1410.914062 1252.195190,1411.410278 1265.479736,1411.723877 
	C1268.216919,1411.788452 1271.771484,1412.971313 1273.772583,1407.647217 
	C1274.253052,1406.771851 1274.930420,1405.950195 1275.185303,1405.013428 
	C1281.999512,1379.972412 1288.793945,1354.925781 1295.488403,1329.852783 
	C1295.876709,1328.397705 1295.395386,1326.710571 1295.292236,1324.584106 
	C1286.798462,1324.077271 1278.697144,1323.593750 1269.227661,1323.028687 
	C1272.214844,1312.134155 1275.035645,1302.651611 1277.396851,1293.055786 
	C1281.387573,1276.837769 1283.462524,1260.417480 1282.138184,1243.643677 
	C1281.418945,1234.532104 1277.196533,1228.049194 1269.244019,1223.662231 
	C1266.299438,1222.037842 1262.463623,1221.256348 1260.817261,1217.118164 
	C1281.024658,1210.423462 1294.492798,1196.704956 1304.748535,1179.198364 
	C1320.473999,1152.355103 1329.448730,1123.253540 1333.546631,1092.612549 
	C1335.013550,1081.644775 1334.219238,1070.517822 1329.647339,1060.229248 
	C1326.322510,1052.746948 1322.971313,1044.902588 1313.502197,1040.669189 
	C1300.847900,1031.907959 1286.997681,1027.412598 1271.433350,1027.483521 
	C1201.234375,1027.803345 1131.033936,1027.857910 1060.834106,1028.032959 
	C1057.916382,1028.040283 1054.999023,1028.299072 1051.425171,1028.472656 
	C1043.871094,1056.639648 1036.395508,1084.514404 1028.192993,1113.486572 
	C1028.203369,1117.206543 1030.954834,1117.348511 1033.556763,1117.443970 
	C1037.411255,1117.585449 1041.275879,1117.652344 1045.130127,1117.541382 
	C1048.121216,1117.455444 1050.039185,1118.567749 1050.309937,1123.128540 
	C1033.091919,1189.367798 1015.873840,1255.606934 998.559204,1322.217896 
	C991.724609,1322.217896 986.069519,1322.437378 980.439819,1322.153687 
	C975.246643,1321.891846 972.688171,1323.779541 971.444336,1329.078003 
	C967.946838,1343.976440 963.873352,1358.739502 960.033630,1373.557617 
	C957.446106,1383.543335 954.762512,1393.506348 952.364014,1403.537354 
	C951.753967,1406.088623 949.697571,1409.559326 955.735901,1411.626221 
	C997.730835,1411.633057 1039.725830,1411.640137 1081.720703,1411.646118 
	C1082.387329,1411.646118 1083.054321,1411.613525 1083.720459,1411.627319 
	C1087.186157,1411.698853 1089.377808,1410.222168 1090.126953,1406.683594 
	C1090.608521,1404.409424 1091.394043,1402.201050 1091.986084,1399.948242 
	C1103.572998,1355.861816 1115.138794,1311.769775 1126.777100,1267.696899 
	C1127.260132,1265.867432 1128.405151,1264.212646 1129.164795,1262.636353 
	C1143.037231,1261.759644 1156.296265,1260.921753 1170.436523,1261.376465 
M718.024170,1121.760010 
	C707.036865,1162.935059 696.175659,1204.144409 685.017578,1245.273193 
	C677.699097,1272.248779 670.179810,1299.174805 662.328247,1325.999390 
	C655.263245,1350.136963 656.965820,1372.762207 672.425842,1393.348999 
	C674.550598,1396.178223 677.641602,1398.281860 680.571655,1401.437866 
	C680.571655,1401.437866 681.294250,1401.715454 681.294250,1401.715454 
	C681.294250,1401.715454 682.017456,1401.437012 682.911011,1401.662842 
	C682.911011,1401.662842 683.832581,1401.673584 684.624023,1402.972412 
	C687.385376,1404.074463 690.061646,1405.757080 692.923035,1406.176025 
	C704.079224,1407.809814 715.261780,1409.738281 726.494751,1410.258545 
	C742.456543,1410.998169 758.472168,1410.516968 774.463318,1410.692871 
	C801.099915,1410.985962 827.741577,1411.900879 854.369141,1411.573853 
	C874.470825,1411.327026 892.377380,1404.716064 905.049500,1387.997803 
	C909.049011,1382.721313 912.544678,1376.982544 915.659546,1371.129883 
	C926.685608,1350.412842 933.295166,1327.940063 939.544373,1305.556030 
	C955.464111,1248.532837 970.359070,1191.223389 985.619751,1134.016602 
	C990.017273,1117.531738 989.948059,1117.513184 1007.067627,1117.741211 
	C1016.124451,1117.861694 1017.120911,1117.210205 1019.450317,1108.411621 
	C1023.622009,1092.654297 1027.704590,1076.873047 1031.747803,1061.082153 
	C1034.135742,1051.756104 1036.376953,1042.392578 1039.415283,1031.654785 
	C1038.739624,1030.113037 1038.370117,1027.546265 1037.327026,1027.234741 
	C1034.863770,1026.498901 1032.079834,1026.753174 1029.425659,1026.752075 
	C990.151184,1026.735352 950.876709,1026.730591 911.602234,1026.751465 
	C900.643616,1026.757202 900.611816,1026.791382 897.681641,1037.835938 
	C877.813416,1112.722900 857.958618,1187.613159 838.102173,1262.503296 
	C833.415283,1280.180176 828.741089,1297.860474 824.055481,1315.537720 
	C823.036072,1319.383667 822.521362,1323.808472 817.048279,1323.682617 
	C804.454041,1323.393188 791.863037,1322.965454 779.606995,1320.918701 
	C785.782898,1297.775879 791.880066,1274.611694 798.149414,1251.494263 
	C816.351013,1184.378662 834.649597,1117.289185 852.812073,1050.163086 
	C854.273315,1044.762451 854.983521,1039.158569 856.700134,1032.055542 
	C855.685547,1030.330933 855.055298,1027.956665 853.547974,1027.064941 
	C852.050171,1026.178955 849.670105,1026.731934 847.677490,1026.742554 
	C808.114441,1026.953125 768.551331,1027.165405 728.988586,1027.426147 
	C726.738525,1027.440918 724.491211,1027.871826 721.732605,1028.162842 
	C718.947998,1037.581787 716.092163,1046.758667 713.527466,1056.016235 
	C707.945007,1076.166626 702.510986,1096.358154 696.730164,1117.688721 
	C698.750854,1118.375610 700.736572,1119.497437 702.799194,1119.663452 
	C707.902100,1120.074341 713.040222,1120.047852 718.024170,1121.760010 
M2300.345215,1335.656494 
	C2306.492676,1341.011963 2313.976562,1342.213379 2321.712402,1342.230347 
	C2352.226318,1342.297241 2382.740234,1342.247192 2413.827881,1343.801758 
	C2413.705566,1345.747681 2413.889160,1347.771729 2413.416016,1349.628296 
	C2402.774414,1391.383545 2392.084717,1433.126465 2381.308350,1474.847168 
	C2380.586914,1477.640137 2379.107178,1480.237427 2377.297363,1482.505493 
	C2376.678955,1482.457642 2376.251221,1482.720215 2374.139648,1483.323975 
	C2360.883789,1483.296021 2347.628174,1483.268188 2334.219971,1481.715820 
	C2335.797119,1475.122681 2337.496338,1468.555664 2338.901123,1461.925903 
	C2339.633301,1458.471436 2340.164307,1454.899780 2340.089111,1451.395996 
	C2340.067871,1450.404297 2337.589844,1449.465332 2334.336670,1448.406006 
	C2303.336914,1448.389771 2272.337402,1448.365356 2241.337646,1448.361816 
	C2230.544189,1448.360596 2230.571045,1448.391235 2227.717041,1459.447876 
	C2217.978271,1497.175415 2208.218994,1534.897583 2198.076904,1573.837891 
	C2199.499512,1578.745239 2203.428711,1577.785278 2207.061768,1577.783203 
	C2268.333496,1577.746948 2329.605713,1577.621826 2390.876709,1577.820679 
	C2406.777344,1577.872314 2421.860107,1575.708984 2435.982666,1567.797607 
	C2448.341309,1560.874268 2459.186523,1552.469604 2467.811035,1541.101440 
	C2477.879395,1527.829956 2483.410156,1512.582397 2487.827637,1496.856567 
	C2493.580322,1476.375732 2498.888184,1455.770508 2504.497803,1435.248901 
	C2513.527832,1402.214844 2522.797363,1369.245483 2531.653564,1336.165405 
	C2534.934082,1323.913086 2536.567139,1311.329468 2533.929688,1298.686401 
	C2531.355713,1286.347656 2526.838867,1275.134399 2513.379883,1268.471436 
	C2506.579346,1262.549438 2498.558350,1259.953369 2489.655273,1259.857056 
	C2466.779053,1259.609375 2443.901611,1259.434570 2421.025879,1259.145264 
	C2413.089355,1259.044800 2405.156006,1258.692993 2397.541016,1256.768921 
	C2408.540283,1211.596436 2419.539551,1166.423950 2430.714600,1120.530396 
	C2446.198975,1120.530396 2460.769043,1120.530396 2475.222412,1121.987061 
	C2473.393066,1130.324585 2471.563965,1138.662231 2469.530518,1147.930542 
	C2473.398438,1148.213745 2476.279053,1148.603638 2479.160400,1148.607910 
	C2506.631104,1148.647705 2534.101562,1148.653809 2561.572021,1148.568848 
	C2564.500732,1148.559814 2567.427002,1147.908569 2571.654785,1147.170044 
	C2572.449707,1145.391113 2573.502197,1143.681274 2574.000732,1141.822754 
	C2580.695068,1116.872192 2587.418457,1091.928589 2593.900391,1066.922363 
	C2596.881592,1055.420898 2599.350830,1043.786865 2602.717773,1030.884888 
	C2602.208984,1029.830322 2601.902832,1028.076782 2601.151611,1027.859253 
	C2599.292725,1027.320923 2597.230713,1027.387695 2595.249756,1027.381836 
	C2530.291992,1027.190674 2465.334473,1027.013794 2400.376709,1026.880615 
	C2398.747803,1026.877319 2397.089111,1027.278076 2395.492432,1027.681030 
	C2366.229736,1035.066040 2343.158691,1050.384033 2330.422363,1078.784302 
	C2328.110107,1083.939697 2325.676514,1089.123169 2324.147217,1094.533447 
	C2316.367920,1122.054077 2308.770508,1149.627441 2301.297363,1177.233154 
	C2293.301025,1206.770996 2285.225830,1236.292847 2277.737061,1265.961060 
	C2272.783691,1285.584595 2275.970947,1304.404541 2286.589111,1321.641846 
	C2289.784180,1326.828979 2292.968506,1332.632690 2300.345215,1335.656494 
M654.313721,1038.183350 
	C643.825928,1032.900513 632.626038,1030.610596 620.967285,1030.341187 
	C589.015381,1029.603027 557.061951,1028.925049 525.110657,1028.161255 
	C516.794006,1027.962646 508.434357,1027.991577 500.180511,1027.109985 
	C487.732147,1025.780396 475.615692,1027.221069 463.574951,1030.045898 
	C452.592438,1032.622437 443.286987,1038.102661 435.486725,1046.312256 
	C427.122406,1055.115479 420.425873,1065.005615 416.136871,1076.324463 
	C411.663696,1088.129517 407.111237,1099.971924 403.742737,1112.116699 
	C384.279785,1182.288574 365.101715,1252.539795 345.937408,1322.794189 
	C332.286072,1372.838379 318.643860,1422.886353 305.337891,1473.022949 
	C300.381287,1491.699341 296.697968,1510.708618 297.847443,1530.205078 
	C298.700897,1544.680786 304.950897,1556.262695 318.273987,1565.192749 
	C325.382263,1571.499023 334.032990,1573.585693 343.167389,1573.789917 
	C386.748810,1574.764526 430.338135,1575.416626 473.914856,1576.557617 
	C495.483978,1577.122192 514.526733,1570.982178 531.178894,1557.336182 
	C545.032715,1545.983398 556.764954,1533.147583 561.754395,1515.269775 
	C575.158752,1467.240112 588.644531,1419.233032 602.219666,1371.251343 
	C619.413818,1310.478516 636.883972,1249.783325 653.922302,1188.966919 
	C661.990723,1160.167480 669.852539,1131.289062 676.809937,1102.207520 
	C681.017883,1084.618164 678.846436,1067.161133 670.392090,1050.794556 
	C667.211853,1044.637939 662.822693,1040.046143 654.313721,1038.183350 
z"/>
                <path fill="#982B28" opacity="1.000000" stroke="none"
                    d="
M1955.999878,1880.063599 
	C1907.684204,1880.064209 1860.368530,1880.064575 1813.052734,1880.064575 
	C1810.720215,1880.064575 1808.383545,1880.150391 1806.056030,1880.041382 
	C1797.988525,1879.663696 1795.897217,1877.922241 1792.953003,1870.315918 
	C1788.873901,1859.777466 1785.339355,1848.930908 1780.204102,1838.917603 
	C1767.382568,1813.916016 1748.708252,1794.648682 1722.572266,1783.151245 
	C1709.573853,1777.433350 1695.947998,1774.616943 1681.923218,1774.221802 
	C1642.560059,1773.112671 1603.735596,1777.341797 1564.912354,1784.172607 
	C1546.540405,1787.404785 1527.046143,1786.434448 1508.293701,1784.757690 
	C1486.532349,1782.812012 1465.118042,1777.232544 1443.384033,1774.659790 
	C1423.465210,1772.302124 1403.566528,1774.906372 1383.928711,1779.017822 
	C1360.821045,1783.855591 1337.611572,1788.976074 1313.884277,1787.585327 
	C1294.690308,1786.460327 1275.609619,1783.410522 1256.476685,1781.232910 
	C1240.644409,1779.431030 1224.835205,1777.356812 1208.966553,1775.966064 
	C1196.724976,1774.892944 1184.377563,1773.837280 1172.127808,1774.254883 
	C1123.932739,1775.898560 1088.670532,1798.705322 1066.852051,1841.493164 
	C1061.315918,1852.349976 1057.815308,1864.407349 1057.761841,1877.018921 
	C1057.753662,1878.952393 1057.332886,1880.883911 1057.019775,1883.515381 
	C1053.820923,1883.782959 1050.944580,1884.231689 1048.067871,1884.233643 
	C987.423706,1884.275635 926.779602,1884.268188 866.135437,1884.263184 
	C864.136719,1884.262939 862.114258,1884.369385 860.143372,1884.113892 
	C850.671936,1882.885864 845.751038,1874.754761 849.170776,1865.876099 
	C850.121582,1863.407471 851.439026,1861.038818 852.864502,1858.800171 
	C874.891296,1824.210693 899.509888,1791.677612 927.187561,1761.307861 
	C966.930237,1717.700073 1011.983215,1680.669922 1061.637573,1649.078979 
	C1112.810303,1616.521851 1167.754517,1592.432373 1225.983032,1575.466919 
	C1258.624023,1565.956543 1291.818359,1559.313354 1325.368652,1554.529175 
	C1343.752197,1551.907349 1362.399414,1550.825073 1380.970215,1549.909058 
	C1402.558716,1548.843994 1424.222290,1547.784912 1445.800781,1548.442871 
	C1469.006348,1549.150513 1492.165283,1551.693237 1515.319336,1553.722534 
	C1549.184448,1556.690308 1582.417358,1563.442261 1615.130981,1572.373779 
	C1666.356934,1586.359619 1715.471191,1605.701782 1761.935791,1631.712524 
	C1798.062378,1651.936401 1832.216064,1674.981323 1863.910400,1701.525269 
	C1891.224731,1724.401367 1916.479736,1749.451050 1939.910278,1776.379639 
	C1962.911133,1802.813843 1983.753784,1830.738525 2001.886475,1860.715454 
	C2004.577026,1865.163452 2007.018799,1869.191895 2003.882935,1874.501221 
	C2000.842773,1879.648438 1996.222656,1880.169556 1990.986694,1880.104126 
	C1979.659302,1879.962280 1968.328979,1880.062256 1955.999878,1880.063599 
M1095.560791,1678.119263 
	C1097.447754,1677.528320 1099.460449,1677.187256 1101.202759,1676.309326 
	C1132.845215,1660.366089 1166.175049,1648.985718 1200.569580,1640.999268 
	C1225.410767,1635.231445 1250.534058,1630.518555 1275.703857,1626.384033 
	C1291.372192,1623.810181 1307.353882,1623.098511 1323.209717,1621.721558 
	C1338.400513,1620.402588 1353.592529,1618.668579 1368.815552,1618.240479 
	C1400.725464,1617.342773 1432.484375,1619.274048 1464.166992,1623.721802 
	C1493.186890,1627.795532 1521.593994,1634.207520 1549.495972,1642.909180 
	C1595.111084,1657.135254 1638.133911,1677.233765 1678.591431,1702.525879 
	C1710.186157,1722.277466 1740.243774,1744.259888 1767.190430,1770.178955 
	C1776.793335,1779.415771 1786.183105,1788.884644 1795.408447,1798.498779 
	C1799.109619,1802.356079 1802.940063,1804.225098 1808.474731,1804.183960 
	C1840.791504,1803.943359 1873.111084,1804.082031 1905.429688,1804.039185 
	C1907.168823,1804.036865 1908.907471,1803.551758 1911.519531,1803.160767 
	C1908.924561,1799.482178 1907.215942,1796.634399 1905.100098,1794.130615 
	C1892.670654,1779.421753 1879.494873,1765.474365 1865.594849,1752.075562 
	C1834.756470,1722.349365 1800.471313,1697.507202 1763.746460,1675.664429 
	C1726.132080,1653.292725 1686.524536,1635.364624 1645.356445,1620.808594 
	C1614.335449,1609.840332 1582.812500,1600.346191 1550.392212,1594.582031 
	C1523.009399,1589.713379 1495.504517,1584.447388 1467.834229,1582.537354 
	C1427.354370,1579.743286 1386.750488,1581.661499 1346.586060,1588.339600 
	C1303.961548,1595.426514 1262.148315,1605.938965 1221.093628,1619.418335 
	C1182.759399,1632.004761 1145.780884,1647.799683 1110.282715,1666.976318 
	C1104.537231,1670.080200 1099.378784,1674.270752 1093.291748,1678.192017 
	C1093.544556,1678.148193 1093.797485,1678.104248 1095.560791,1678.119263 
M1088.983154,1681.282593 
	C1088.983154,1681.282593 1089.200928,1680.911133 1089.200928,1680.911133 
	C1089.200928,1680.911133 1088.752686,1680.962769 1088.983154,1681.282593 
z"/>
                <path fill="#972B28" opacity="1.000000" stroke="none"
                    d="
M1184.242554,2034.541016 
	C1190.571655,2034.426147 1196.900391,2034.241577 1203.229980,2034.208618 
	C1227.909302,2034.080078 1252.351440,2031.932251 1276.458618,2026.343994 
	C1305.503418,2019.611328 1334.582031,2020.940186 1363.548950,2026.866211 
	C1386.404785,2031.541992 1409.277222,2034.998901 1432.776855,2034.447388 
	C1449.573608,2034.053101 1465.863647,2030.974365 1482.052368,2027.459106 
	C1514.709229,2020.367432 1547.253296,2020.478882 1580.165039,2025.465820 
	C1615.006958,2030.745239 1649.934692,2035.589233 1685.389404,2034.333252 
	C1714.292480,2033.309326 1738.941284,2022.791260 1760.147217,2003.270752 
	C1778.965820,1985.947998 1790.736816,1964.938843 1795.047363,1939.768066 
	C1795.828979,1935.204346 1796.093018,1930.552002 1796.687988,1925.098022 
	C1799.118408,1924.789673 1801.630127,1924.194580 1804.142334,1924.192505 
	C1865.467773,1924.139893 1926.793213,1924.147095 1988.118652,1924.163818 
	C1990.109131,1924.164307 1992.167969,1924.088623 1994.078491,1924.540527 
	C2003.063110,1926.665649 2008.800903,1936.512817 2003.730225,1944.564697 
	C1998.054932,1953.576538 1992.789062,1962.865356 1986.762573,1971.633545 
	C1959.527222,2011.259155 1928.861816,2048.031250 1893.767212,2080.951660 
	C1856.008057,2116.372070 1814.962891,2147.441406 1769.931885,2173.157715 
	C1733.677002,2193.862061 1695.920044,2211.280518 1656.364624,2224.576172 
	C1622.928955,2235.814453 1588.723999,2244.059570 1553.964478,2250.400391 
	C1496.592529,2260.865967 1438.744507,2262.151123 1380.859741,2259.530762 
	C1328.949463,2257.181152 1277.862793,2248.119629 1227.805420,2233.787109 
	C1182.172485,2220.721191 1138.447144,2202.972412 1096.759155,2180.298096 
	C1059.531494,2160.049805 1024.644775,2136.370605 991.882141,2109.404053 
	C968.143066,2089.864746 946.313354,2068.406250 925.299255,2046.115234 
	C896.912109,2016.003296 872.364319,1982.871826 850.904663,1947.486084 
	C845.124329,1937.954712 848.679077,1930.524536 859.644714,1928.653442 
	C861.921875,1928.264893 864.290161,1928.323486 866.616455,1928.322998 
	C925.275574,1928.311401 983.934631,1928.318726 1042.593750,1928.327759 
	C1044.592773,1928.328003 1046.619019,1928.180298 1048.585815,1928.446533 
	C1054.283569,1929.217651 1058.247803,1931.895020 1060.285034,1937.752563 
	C1063.887451,1948.110474 1068.074707,1958.265015 1071.534790,1969.803467 
	C1067.436157,1971.397217 1063.820190,1971.943970 1060.203491,1971.948853 
	C1015.894470,1972.008179 971.585388,1971.978027 927.276367,1972.008545 
	C923.802490,1972.010864 920.328674,1972.293701 914.373230,1972.555176 
	C925.487854,1985.429321 934.592163,1996.695801 944.460144,2007.248291 
	C962.642944,2026.692749 982.390625,2044.528809 1003.634521,2060.557617 
	C1024.260376,2076.119629 1044.760986,2092.010254 1066.514893,2105.876953 
	C1099.016235,2126.594238 1132.339844,2146.113281 1168.063721,2160.997559 
	C1191.968384,2170.957275 1215.771362,2181.256592 1240.099121,2190.082764 
	C1275.676880,2202.990234 1312.240479,2212.257324 1349.661987,2218.629639 
	C1374.933716,2222.932861 1400.307983,2224.392090 1425.740723,2225.769531 
	C1434.601196,2226.249512 1443.570679,2224.671631 1452.492798,2224.069336 
	C1462.408813,2223.399902 1472.376099,2223.186279 1482.235229,2222.038086 
	C1502.595825,2219.667725 1522.933838,2217.043945 1543.219482,2214.099854 
	C1579.400513,2208.848877 1614.570923,2199.261230 1649.386353,2188.361328 
	C1676.677002,2179.817627 1703.699219,2170.430420 1729.226074,2157.384766 
	C1745.167603,2149.237793 1760.736694,2140.357422 1776.385376,2131.648926 
	C1777.755493,2130.886475 1778.471802,2128.948730 1780.831787,2125.728271 
	C1776.563232,2126.800781 1774.420044,2127.199463 1772.368774,2127.876221 
	C1754.096802,2133.904053 1736.043335,2140.708252 1717.528809,2145.856934 
	C1694.878662,2152.155518 1671.768921,2156.572266 1648.200684,2158.146240 
	C1631.683838,2159.249023 1615.196899,2161.601562 1598.682495,2161.741699 
	C1577.114258,2161.925049 1555.561523,2162.291504 1533.911377,2160.475586 
	C1510.486572,2158.510986 1487.059082,2156.795410 1463.658813,2154.322266 
	C1440.908691,2151.917969 1418.365845,2148.588867 1395.981567,2144.041016 
	C1358.082886,2136.340576 1321.561646,2124.374756 1286.442749,2108.177979 
	C1261.296875,2096.580811 1237.588379,2082.470459 1215.661133,2065.610352 
	C1205.425781,2057.740723 1196.252563,2048.476807 1186.754883,2039.675537 
	C1185.451050,2038.467285 1185.057495,2036.276733 1184.242554,2034.541016 
M1782.774292,2124.968262 
	C1782.774292,2124.968262 1783.030518,2124.813965 1782.774292,2124.968262 
z"/>
                <path fill="#651516" opacity="1.000000" stroke="none"
                    d="
M1183.394287,2034.335449 
	C1185.057495,2036.276733 1185.451050,2038.467285 1186.754883,2039.675537 
	C1196.252563,2048.476807 1205.425781,2057.740723 1215.661133,2065.610352 
	C1237.588379,2082.470459 1261.296875,2096.580811 1286.442749,2108.177979 
	C1321.561646,2124.374756 1358.082886,2136.340576 1395.981567,2144.041016 
	C1418.365845,2148.588867 1440.908691,2151.917969 1463.658813,2154.322266 
	C1487.059082,2156.795410 1510.486572,2158.510986 1533.911377,2160.475586 
	C1555.561523,2162.291504 1577.114258,2161.925049 1598.682495,2161.741699 
	C1615.196899,2161.601562 1631.683838,2159.249023 1648.200684,2158.146240 
	C1671.768921,2156.572266 1694.878662,2152.155518 1717.528809,2145.856934 
	C1736.043335,2140.708252 1754.096802,2133.904053 1772.368774,2127.876221 
	C1774.420044,2127.199463 1776.563232,2126.800781 1780.831787,2125.728271 
	C1778.471802,2128.948730 1777.755493,2130.886475 1776.385376,2131.648926 
	C1760.736694,2140.357422 1745.167603,2149.237793 1729.226074,2157.384766 
	C1703.699219,2170.430420 1676.677002,2179.817627 1649.386353,2188.361328 
	C1614.570923,2199.261230 1579.400513,2208.848877 1543.219482,2214.099854 
	C1522.933838,2217.043945 1502.595825,2219.667725 1482.235229,2222.038086 
	C1472.376099,2223.186279 1462.408813,2223.399902 1452.492798,2224.069336 
	C1443.570679,2224.671631 1434.601196,2226.249512 1425.740723,2225.769531 
	C1400.307983,2224.392090 1374.933716,2222.932861 1349.661987,2218.629639 
	C1312.240479,2212.257324 1275.676880,2202.990234 1240.099121,2190.082764 
	C1215.771362,2181.256592 1191.968384,2170.957275 1168.063721,2160.997559 
	C1132.339844,2146.113281 1099.016235,2126.594238 1066.514893,2105.876953 
	C1044.760986,2092.010254 1024.260376,2076.119629 1003.634521,2060.557617 
	C982.390625,2044.528809 962.642944,2026.692749 944.460144,2007.248291 
	C934.592163,1996.695801 925.487854,1985.429321 914.373230,1972.555176 
	C920.328674,1972.293701 923.802490,1972.010864 927.276367,1972.008545 
	C971.585388,1971.978027 1015.894470,1972.008179 1060.203491,1971.948853 
	C1063.820190,1971.943970 1067.436157,1971.397217 1071.695068,1970.520020 
	C1076.614014,1976.172119 1080.623169,1982.611328 1085.218140,1988.601196 
	C1097.905762,2005.140869 1112.551147,2019.550659 1132.878906,2026.187622 
	C1141.891968,2029.130249 1151.469727,2030.492432 1160.880981,2032.017456 
	C1168.029175,2033.175781 1175.319092,2033.458008 1183.394287,2034.335449 
z"/>
                <path fill="#FBFBFB" opacity="1.000000" stroke="none"
                    d="
M1281.800537,1967.566406 
	C1275.178833,1967.529419 1269.512817,1967.544434 1263.848877,1967.442993 
	C1253.134521,1967.251099 1252.404785,1966.541992 1252.338379,1956.144043 
	C1252.283325,1947.544312 1252.327026,1938.944092 1252.327026,1929.808105 
	C1229.491211,1929.808105 1207.370483,1929.808105 1184.643555,1929.808105 
	C1185.351685,1946.129028 1192.407593,1960.139648 1198.229126,1976.024536 
	C1185.639160,1976.024536 1173.860596,1976.318359 1162.122925,1975.756226 
	C1159.988403,1975.653931 1157.359375,1972.407471 1156.076050,1970.013672 
	C1136.206421,1932.951538 1130.836060,1894.609375 1146.681396,1854.637695 
	C1149.119019,1848.488647 1152.217041,1842.601074 1155.120483,1836.354248 
	C1169.446045,1836.354248 1183.348877,1836.354248 1198.404785,1836.354248 
	C1192.539429,1851.642456 1186.991089,1866.779297 1185.637817,1883.571289 
	C1207.885864,1883.571289 1229.682129,1883.571289 1252.500854,1883.571289 
	C1252.500854,1874.012451 1252.441284,1864.771851 1252.516357,1855.532349 
	C1252.604736,1844.668579 1253.793945,1843.484131 1264.547485,1843.229004 
	C1272.875977,1843.031494 1281.205444,1842.808838 1289.535156,1842.773804 
	C1292.760742,1842.760254 1295.988281,1843.232422 1300.123047,1843.553833 
	C1300.123047,1857.216797 1300.123047,1870.081543 1300.123047,1883.519409 
	C1319.545776,1883.519409 1337.994873,1883.519409 1357.297974,1883.519409 
	C1357.297974,1873.813965 1357.205322,1864.534546 1357.323486,1855.257812 
	C1357.446045,1845.634399 1358.761353,1844.288574 1368.385376,1844.182251 
	C1379.978516,1844.054199 1391.574341,1844.151855 1404.542236,1844.151855 
	C1404.542236,1857.739258 1404.542236,1870.499878 1404.542236,1883.666260 
	C1424.439819,1883.666260 1442.947388,1883.666260 1462.581909,1883.666260 
	C1462.581909,1874.112915 1462.537476,1864.888672 1462.593140,1855.665039 
	C1462.665039,1843.738525 1463.967896,1842.423462 1475.682861,1842.280884 
	C1484.346680,1842.175415 1493.011719,1842.036255 1501.675415,1842.079224 
	C1509.640869,1842.118652 1510.308105,1842.730225 1510.408936,1850.487915 
	C1510.525757,1859.483643 1510.355591,1868.482910 1510.366089,1877.480591 
	C1510.368408,1879.396484 1510.612427,1881.312256 1510.774414,1883.651733 
	C1529.564209,1883.651733 1547.783325,1883.651733 1567.397339,1883.651733 
	C1567.397339,1876.743896 1567.388306,1869.870483 1567.400757,1862.997192 
	C1567.407959,1858.998779 1567.382324,1854.998657 1567.494263,1851.002563 
	C1567.613281,1846.756348 1569.605347,1843.851807 1574.089722,1843.806763 
	C1587.044556,1843.676636 1600.001587,1843.760864 1614.219727,1843.760864 
	C1616.623657,1856.921021 1614.064575,1870.210815 1615.585327,1883.635986 
	C1638.163330,1883.635986 1660.363647,1883.635986 1683.383789,1883.635986 
	C1684.124634,1865.844238 1675.758911,1851.120605 1667.838501,1835.042969 
	C1682.202515,1835.042969 1695.631348,1835.042969 1708.181641,1835.042969 
	C1739.059448,1881.692871 1734.112549,1928.853271 1709.423950,1976.573975 
	C1696.636719,1976.573975 1683.490479,1976.573975 1670.841309,1976.573975 
	C1674.224121,1961.192139 1677.537109,1946.128662 1680.835205,1931.132568 
	C1672.463257,1928.883057 1629.126831,1928.431396 1614.675171,1930.285278 
	C1614.675171,1939.250000 1614.779175,1948.446899 1614.646851,1957.640503 
	C1614.506470,1967.400146 1614.251343,1967.643555 1604.302368,1967.757812 
	C1594.972778,1967.864990 1585.638672,1967.867310 1576.310303,1967.703735 
	C1568.051514,1967.558960 1567.348145,1966.816040 1567.227539,1958.306885 
	C1567.096069,1949.039917 1567.197632,1939.769531 1567.197632,1929.881348 
	C1547.971191,1929.881348 1529.545288,1929.881348 1510.179077,1929.881348 
	C1510.179077,1939.296143 1510.270996,1948.227173 1510.152588,1957.155396 
	C1510.038208,1965.782471 1509.662354,1966.226562 1501.104126,1966.360962 
	C1491.109619,1966.518188 1481.107910,1966.490112 1471.113770,1966.307251 
	C1463.450439,1966.167114 1462.614380,1965.303101 1462.469849,1957.513428 
	C1462.303589,1948.558350 1462.431152,1939.597778 1462.431152,1929.948486 
	C1443.171265,1929.948486 1424.432983,1929.948486 1404.528076,1929.948486 
	C1404.528076,1939.489868 1404.590698,1948.744385 1404.511108,1957.997925 
	C1404.426514,1967.847046 1404.134766,1968.167969 1394.461792,1968.264526 
	C1385.131836,1968.357666 1375.799316,1968.312500 1366.469238,1968.204102 
	C1363.882080,1968.174072 1361.299927,1967.701904 1357.500488,1967.306030 
	C1357.500488,1954.730957 1357.500488,1942.579468 1357.500488,1929.852661 
	C1338.042358,1929.852661 1319.849365,1929.852661 1300.178589,1929.852661 
	C1300.178589,1934.586792 1300.183716,1939.425659 1300.177002,1944.264526 
	C1300.171509,1948.263550 1300.168701,1952.262695 1300.135498,1956.261597 
	C1300.046143,1967.006836 1299.808228,1967.241699 1288.756348,1967.517944 
	C1286.757324,1967.567871 1284.756836,1967.551514 1281.800537,1967.566406 
z"/>
                <path fill="#4A5676" opacity="1.000000" stroke="none"
                    d="
M1288.360840,2455.645508 
	C1255.869141,2436.465332 1225.608643,2415.489014 1197.633789,2391.130615 
	C1179.415649,2375.267578 1163.108276,2357.978027 1150.328979,2337.451660 
	C1149.647339,2336.356689 1149.192871,2335.114014 1148.706665,2333.910156 
	C1148.631836,2333.725342 1148.959106,2333.377686 1149.261719,2332.794434 
	C1150.031494,2332.919922 1151.083374,2332.797363 1151.759644,2333.243652 
	C1180.809937,2352.417969 1213.547119,2362.787354 1246.678711,2371.517090 
	C1276.174561,2379.288818 1306.088867,2385.693848 1336.085815,2391.262695 
	C1355.601685,2394.885498 1375.555420,2396.467285 1395.388306,2397.971436 
	C1413.267944,2399.327393 1431.268799,2400.149902 1449.182129,2399.735107 
	C1473.062378,2399.181885 1496.750610,2395.970947 1520.430786,2392.563721 
	C1551.383667,2388.110107 1581.902954,2381.753418 1612.077026,2373.810547 
	C1641.688477,2366.015625 1670.844238,2356.671387 1698.279785,2342.749756 
	C1704.458496,2339.614746 1710.328979,2335.872314 1717.797974,2331.570557 
	C1716.817871,2334.894531 1716.708008,2337.186279 1715.587646,2338.757568 
	C1707.879395,2349.567627 1700.799194,2360.983643 1691.954590,2370.796875 
	C1662.726685,2403.224854 1627.646362,2428.426758 1590.441772,2450.889404 
	C1562.184448,2467.950195 1532.878052,2482.938965 1502.457886,2495.677490 
	C1481.283813,2504.544189 1459.722534,2512.939697 1436.708252,2515.006592 
	C1427.449585,2515.837891 1417.483887,2513.581543 1408.427612,2510.810059 
	C1392.874512,2506.050781 1377.271606,2500.862061 1362.611328,2493.909668 
	C1337.683350,2482.088867 1313.470459,2468.760498 1288.360840,2455.645508 
z"/>
                <path fill="#4A5777" opacity="1.000000" stroke="none"
                    d="
M2029.413086,1684.648193 
	C2022.202515,1676.764648 2015.719727,1669.154175 2008.689941,1662.088135 
	C1963.413940,1616.578369 1911.595703,1580.367554 1853.146606,1553.809692 
	C1825.885254,1541.422607 1797.836914,1531.001831 1769.196777,1522.215332 
	C1768.079956,1521.872681 1766.966309,1521.520020 1763.628540,1520.478271 
	C1895.784790,1520.478271 2025.479980,1520.478271 2156.508301,1520.478271 
	C2134.247070,1597.107056 2109.124023,1671.526367 2075.448730,1743.758179 
	C2059.873535,1723.730713 2044.880859,1704.452026 2029.413086,1684.648193 
z"/>
                <path fill="#4A5777" opacity="1.000000" stroke="none"
                    d="
M980.852844,1562.779297 
	C947.572876,1581.865845 916.168152,1602.507935 886.691040,1625.861694 
	C865.612366,1642.561279 844.908936,1659.846802 825.177307,1678.102051 
	C806.472290,1695.407715 789.250122,1714.316284 770.820801,1733.085205 
	C769.881287,1731.831177 768.140991,1729.994385 766.954590,1727.850830 
	C752.982483,1702.606445 744.386292,1675.372559 738.111755,1647.405518 
	C734.341858,1630.602295 731.259766,1613.613892 728.650269,1596.588867 
	C725.213501,1574.167114 725.286926,1551.518311 726.208557,1528.907104 
	C726.538147,1520.819824 728.071716,1519.794067 737.007324,1519.783691 
	C773.968567,1519.740723 810.929749,1519.766602 847.890991,1519.767090 
	C919.482544,1519.768066 991.074097,1519.768921 1062.665649,1519.769775 
	C1066.315674,1519.769775 1069.965576,1519.769653 1073.615601,1519.769653 
	C1073.716675,1520.126587 1073.817871,1520.483521 1073.918945,1520.840332 
	C1058.282104,1527.084351 1042.415527,1532.817383 1027.072876,1539.715332 
	C1011.603394,1546.670410 996.653931,1554.781982 980.852844,1562.779297 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M1784.005371,1409.933838 
	C1782.730713,1409.288574 1780.426758,1408.731323 1780.362793,1407.982544 
	C1780.112549,1405.053955 1780.475342,1402.055298 1780.779907,1399.100098 
	C1783.120117,1376.399048 1785.514893,1353.703491 1787.843994,1331.001343 
	C1788.079468,1328.706177 1787.994141,1326.378174 1788.719482,1323.171997 
	C1807.584839,1310.035034 1825.789551,1297.792969 1843.972656,1285.518921 
	C1844.096436,1285.435303 1843.922119,1284.909790 1843.855591,1284.304810 
	C1795.470337,1284.304810 1747.055054,1284.304810 1698.499268,1284.304810 
	C1696.064331,1289.520386 1693.733276,1294.299927 1691.581543,1299.158691 
	C1682.552368,1319.547852 1673.211670,1339.811523 1664.774780,1360.443970 
	C1661.721558,1367.910522 1657.663330,1370.623169 1649.803345,1369.905762 
	C1642.204468,1369.212158 1634.486450,1369.946777 1626.833130,1369.686523 
	C1621.918701,1369.519531 1617.996338,1370.702515 1614.158081,1374.084229 
	C1601.671021,1385.086304 1588.835693,1395.693481 1575.510742,1406.322632 
	C1574.467773,1406.124390 1574.048584,1406.056274 1573.629272,1405.988037 
	C1581.049072,1378.738281 1588.468872,1351.488525 1596.017822,1323.764648 
	C1606.601562,1323.764648 1616.533325,1323.764648 1627.616699,1323.764648 
	C1671.131226,1225.410034 1714.579590,1127.205078 1758.104248,1028.827515 
	C1760.808716,1028.618652 1763.074097,1028.292358 1765.339722,1028.290894 
	C1816.317871,1028.258667 1867.295898,1028.233887 1918.273926,1028.336792 
	C1921.004517,1028.342285 1923.732666,1029.478760 1925.878906,1030.940308 
	C1909.570435,1043.466675 1893.763794,1055.035278 1878.217773,1066.944214 
	C1876.148926,1068.529053 1874.487915,1071.739868 1874.196411,1074.370361 
	C1872.552124,1089.213379 1871.330933,1104.104492 1870.066895,1118.987793 
	C1868.493408,1137.515747 1867.103638,1156.059692 1865.481079,1174.583252 
	C1862.990356,1203.020630 1860.410645,1231.450317 1857.787842,1259.875854 
	C1856.110840,1278.051636 1854.042114,1296.194824 1852.607178,1314.388672 
	C1851.409058,1329.580078 1850.876465,1344.823730 1849.733398,1360.047119 
	C1849.601196,1360.020264 1849.784912,1359.990723 1850.675537,1359.985352 
	C1866.827881,1349.623413 1882.235596,1339.180054 1897.749390,1328.897095 
	C1901.033203,1326.720459 1904.705322,1325.129639 1908.198486,1323.269043 
	C1926.097900,1323.268921 1943.997437,1323.268921 1962.645874,1323.268921 
	C1964.058472,1318.111084 1965.394043,1313.397705 1966.640015,1308.660645 
	C1982.951782,1246.640381 1999.253540,1184.617432 2015.541382,1122.590698 
	C2015.962769,1120.985962 2016.198364,1119.332397 2017.280273,1116.890991 
	C2020.327881,1114.809082 2022.771606,1113.753174 2024.878174,1112.230469 
	C2036.163086,1104.073853 2047.389648,1095.835205 2058.583008,1087.552856 
	C2064.010498,1083.536865 2069.321045,1079.363159 2074.684570,1075.260864 
	C2063.072021,1073.737793 2052.187012,1074.023926 2041.327271,1073.622437 
	C2035.503662,1073.407104 2031.383301,1075.313843 2027.456421,1079.541870 
	C2017.088379,1090.705444 2006.326050,1101.502808 1995.720703,1112.446045 
	C1995.212524,1111.646606 1994.105103,1110.668701 1994.282349,1110.073120 
	C2002.142090,1083.657959 2010.114258,1057.276245 2018.086670,1030.894653 
	C2018.175171,1030.601685 2018.443481,1030.363159 2019.186035,1029.300171 
	C2022.207886,1029.300171 2025.795776,1029.300537 2029.383667,1029.300171 
	C2101.010498,1029.291870 2172.637451,1029.307983 2244.264404,1029.249878 
	C2255.373535,1029.240845 2266.275879,1029.795776 2275.740723,1037.535645 
	C2261.551514,1050.620239 2247.441406,1062.638550 2233.258301,1074.718994 
	C2238.919922,1088.399536 2237.095947,1101.423950 2233.363037,1114.419678 
	C2232.260742,1118.257446 2231.040771,1122.063110 2230.018311,1125.921509 
	C2211.946533,1194.104248 2193.904297,1262.294678 2175.835205,1330.477905 
	C2172.701904,1342.300659 2165.963379,1351.471069 2155.377197,1357.703613 
	C2143.287354,1364.821655 2130.060791,1367.709595 2116.188965,1367.718872 
	C2034.193726,1367.773804 1952.196777,1368.028564 1870.204956,1367.504883 
	C1854.998535,1367.407715 1841.734253,1369.557129 1829.285034,1379.077637 
	C1814.798218,1390.156006 1799.149658,1399.715332 1784.005371,1409.933838 
M1772.351440,1245.339233 
	C1782.507568,1245.339233 1792.663696,1245.339233 1802.727295,1245.339233 
	C1803.283447,1242.840698 1803.687622,1241.587158 1803.829102,1240.304565 
	C1805.753052,1222.869629 1807.734009,1205.440063 1809.509277,1187.989746 
	C1811.139404,1171.965698 1812.537720,1155.918213 1815.036499,1139.033691 
	C1814.578735,1133.008789 1815.854980,1127.371094 1818.361938,1121.871582 
	C1820.842407,1116.430298 1823.519409,1110.962891 1825.035522,1105.223267 
	C1827.633667,1095.386719 1833.341064,1089.183105 1842.864624,1085.600098 
	C1848.414307,1083.512085 1853.995728,1080.855835 1859.875732,1076.352295 
	C1835.574707,1076.352295 1813.056885,1076.352295 1790.887817,1076.352295 
	C1778.274658,1099.497070 1704.907837,1270.045044 1704.257690,1277.925781 
	C1726.497925,1266.929443 1748.535522,1256.033325 1772.351440,1245.339233 
M2174.419922,1119.826782 
	C2188.915039,1105.859619 2203.410156,1091.892456 2217.905273,1077.925293 
	C2217.563721,1077.291626 2217.222412,1076.658081 2216.880859,1076.024414 
	C2173.535645,1075.396240 2130.190186,1074.768066 2086.624268,1074.136719 
	C2060.904785,1169.669067 2035.517334,1263.967773 2009.596436,1360.248047 
	C2033.599731,1347.312622 2055.759033,1335.370972 2079.820312,1323.635986 
	C2088.792480,1323.650146 2097.764648,1323.683960 2106.736816,1323.673828 
	C2118.706055,1323.660156 2118.710693,1323.642090 2121.728027,1312.487305 
	C2131.089600,1277.875000 2140.519775,1243.280884 2149.791748,1208.644653 
	C2157.688477,1179.146240 2165.403320,1149.599121 2174.419922,1119.826782 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M317.732605,1563.832520 
	C304.950897,1556.262695 298.700897,1544.680786 297.847443,1530.205078 
	C296.697968,1510.708618 300.381287,1491.699341 305.337891,1473.022949 
	C318.643860,1422.886353 332.286072,1372.838379 345.937408,1322.794189 
	C365.101715,1252.539795 384.279785,1182.288574 403.742737,1112.116699 
	C407.111237,1099.971924 411.663696,1088.129517 416.136871,1076.324463 
	C420.425873,1065.005615 427.122406,1055.115479 435.486725,1046.312256 
	C443.286987,1038.102661 452.592438,1032.622437 463.574951,1030.045898 
	C475.615692,1027.221069 487.732147,1025.780396 500.180511,1027.109985 
	C508.434357,1027.991577 516.794006,1027.962646 525.110657,1028.161255 
	C557.061951,1028.925049 589.015381,1029.603027 620.967285,1030.341187 
	C632.626038,1030.610596 643.825928,1032.900513 654.591064,1038.743408 
	C642.181519,1048.991089 629.484131,1058.665039 616.815491,1068.376465 
	C613.809387,1070.680908 610.906067,1073.350708 612.787537,1077.574219 
	C618.496155,1090.388428 615.536011,1103.087280 612.352783,1115.684204 
	C598.997437,1168.534912 585.539795,1221.359985 571.988159,1274.160889 
	C554.800110,1341.130127 537.463135,1408.061279 520.241516,1475.021973 
	C516.585510,1489.237183 509.132568,1501.233398 499.370911,1511.975342 
	C488.159546,1524.312866 474.179932,1529.915771 457.596710,1529.638184 
	C426.735138,1529.121704 395.834686,1530.755859 365.008575,1527.656860 
	C361.748871,1527.329102 357.471313,1528.785522 354.943604,1530.924194 
	C342.305756,1541.616943 330.094086,1552.813232 317.732605,1563.832520 
M422.115112,1485.572754 
	C433.670441,1485.572754 445.225769,1485.572754 457.401672,1485.572754 
	C458.241699,1482.876221 459.299774,1479.777954 460.174408,1476.628662 
	C487.919189,1376.733154 515.680908,1276.842285 543.344055,1176.924194 
	C548.389893,1158.698730 553.022095,1140.358887 558.332642,1120.804932 
	C569.172058,1111.028564 579.988220,1101.226196 590.861694,1091.487915 
	C595.485962,1087.346436 600.229980,1083.338867 604.678589,1079.479492 
	C601.913574,1072.735840 597.255676,1072.123901 591.950073,1072.138672 
	C561.967468,1072.221313 531.969788,1072.696167 502.008148,1071.889893 
	C488.118530,1071.515991 477.852905,1077.008179 470.343811,1087.369995 
	C464.767731,1095.064209 460.347076,1103.916626 457.048126,1112.856934 
	C453.150574,1123.419678 451.074738,1134.648682 448.104462,1145.560425 
	C424.687866,1231.584839 401.266846,1317.607910 377.800385,1403.618652 
	C370.445251,1430.577271 362.809204,1457.459839 355.579407,1484.451660 
	C352.481995,1496.015503 352.060516,1507.567139 359.607391,1517.939331 
	C365.305115,1525.770386 368.412170,1526.235840 375.972290,1520.472900 
	C380.737488,1516.840576 385.329285,1512.981079 390.008240,1509.235107 
	C400.122162,1501.137939 410.242065,1493.048462 422.115112,1485.572754 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M2397.221191,1258.455933 
	C2405.156006,1258.692993 2413.089355,1259.044800 2421.025879,1259.145264 
	C2443.901611,1259.434570 2466.779053,1259.609375 2489.655273,1259.857056 
	C2498.558350,1259.953369 2506.579346,1262.549438 2513.066406,1269.276001 
	C2498.168701,1281.002808 2483.584717,1291.925049 2468.579834,1303.162476 
	C2474.448486,1311.191895 2477.259521,1319.135864 2475.400635,1328.191650 
	C2474.664551,1331.775391 2474.188477,1335.419434 2473.311035,1338.966064 
	C2461.889404,1385.118774 2450.596436,1431.304810 2438.818359,1477.366699 
	C2436.198242,1487.613770 2432.740234,1497.866089 2428.054443,1507.316040 
	C2420.324707,1522.904175 2407.549805,1530.862793 2389.392090,1530.351929 
	C2352.114746,1529.303101 2314.815674,1529.050049 2277.531250,1528.223267 
	C2271.577148,1528.091431 2266.649170,1529.483643 2261.695557,1532.811157 
	C2244.284912,1544.507080 2226.667480,1555.897217 2209.035400,1567.259155 
	C2205.732422,1569.387573 2202.000977,1570.851196 2198.467285,1572.621826 
	C2208.218994,1534.897583 2217.978271,1497.175415 2227.717041,1459.447876 
	C2230.571045,1448.391235 2230.544189,1448.360596 2241.337646,1448.361816 
	C2272.337402,1448.365356 2303.336914,1448.389771 2335.095215,1449.104248 
	C2333.294678,1452.279785 2331.000000,1455.127441 2328.132812,1457.171875 
	C2313.516602,1467.593262 2298.721436,1477.763672 2284.067139,1488.132202 
	C2281.390381,1490.026123 2277.537598,1492.038574 2276.868408,1494.668335 
	C2274.485107,1504.034424 2271.513428,1513.481567 2272.061523,1524.927979 
	C2276.277832,1522.492554 2279.189941,1521.048462 2281.850342,1519.235107 
	C2299.387939,1507.281250 2316.871338,1495.247803 2334.372314,1483.240356 
	C2347.628174,1483.268188 2360.883789,1483.296021 2375.418701,1483.533203 
	C2377.349365,1483.822266 2377.776855,1483.550049 2377.980225,1482.925781 
	C2379.107178,1480.237427 2380.586914,1477.640137 2381.308350,1474.847168 
	C2392.084717,1433.126465 2402.774414,1391.383545 2413.416016,1349.628296 
	C2413.889160,1347.771729 2413.705566,1345.747681 2414.319092,1343.040527 
	C2421.428467,1337.363403 2428.209961,1332.650024 2434.625732,1327.483154 
	C2443.545166,1320.299927 2452.190918,1312.776855 2462.370361,1304.206665 
	C2451.518799,1303.503174 2442.701660,1302.640381 2433.868408,1302.419800 
	C2415.596436,1301.963501 2397.302734,1302.195312 2379.044678,1301.468384 
	C2367.520508,1301.009399 2356.056885,1299.154053 2344.536865,1298.342041 
	C2342.258301,1298.181396 2339.427002,1299.397095 2337.564697,1300.876709 
	C2327.943848,1308.519897 2318.452148,1316.335205 2309.139648,1324.351196 
	C2305.706055,1327.306885 2302.886963,1330.976562 2299.787842,1334.320679 
	C2292.968506,1332.632690 2289.784180,1326.828979 2286.589111,1321.641846 
	C2275.970947,1304.404541 2272.783691,1285.584595 2277.737061,1265.961060 
	C2285.225830,1236.292847 2293.301025,1206.770996 2301.297363,1177.233154 
	C2308.770508,1149.627441 2316.367920,1122.054077 2324.147217,1094.533447 
	C2325.676514,1089.123169 2328.110107,1083.939697 2330.422363,1078.784302 
	C2343.158691,1050.384033 2366.229736,1035.066040 2395.492432,1027.681030 
	C2397.089111,1027.278076 2398.747803,1026.877319 2400.376709,1026.880615 
	C2465.334473,1027.013794 2530.291992,1027.190674 2595.249756,1027.381836 
	C2597.230713,1027.387695 2599.292725,1027.320923 2601.151611,1027.859253 
	C2601.902832,1028.076782 2602.208984,1029.830322 2601.686279,1031.534058 
	C2581.322021,1045.024414 2561.911621,1057.751587 2542.755615,1070.851562 
	C2540.372314,1072.481323 2538.595215,1075.890991 2537.826660,1078.814819 
	C2535.809082,1086.491821 2534.849609,1094.448608 2532.795898,1102.113770 
	C2531.379395,1107.400146 2532.892822,1110.831421 2536.817139,1114.264893 
	C2546.306152,1122.567749 2555.604492,1131.095947 2564.765381,1139.759888 
	C2567.045898,1141.916870 2568.513184,1144.933716 2570.354492,1147.555420 
	C2567.427002,1147.908569 2564.500732,1148.559814 2561.572021,1148.568848 
	C2534.101562,1148.653809 2506.631104,1148.647705 2479.160400,1148.607910 
	C2476.279053,1148.603638 2473.398438,1148.213745 2469.530518,1147.930542 
	C2471.563965,1138.662231 2473.393066,1130.324585 2475.747559,1120.700684 
	C2494.641113,1104.836182 2513.009521,1090.258057 2531.565430,1075.531128 
	C2530.277344,1074.528931 2529.825439,1073.868530 2529.350586,1073.851562 
	C2495.125732,1072.626709 2460.892090,1070.388550 2426.674805,1070.618042 
	C2400.176025,1070.795776 2382.413330,1083.317383 2375.333984,1111.640381 
	C2363.171143,1160.302124 2349.552002,1208.598999 2336.796631,1257.115356 
	C2335.127441,1263.464600 2334.277100,1270.205444 2334.271729,1276.765747 
	C2334.266602,1283.051758 2335.812500,1289.362915 2343.119629,1292.697388 
	C2346.081299,1290.762817 2349.154541,1288.842529 2352.137939,1286.791626 
	C2364.199463,1278.499512 2376.161133,1270.059448 2388.334961,1261.936157 
	C2390.919922,1260.211182 2394.239990,1259.587280 2397.221191,1258.455933 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M680.285278,1400.721436 
	C677.641602,1398.281860 674.550598,1396.178223 672.425842,1393.348999 
	C656.965820,1372.762207 655.263245,1350.136963 662.328247,1325.999390 
	C670.179810,1299.174805 677.699097,1272.248779 685.017578,1245.273193 
	C696.175659,1204.144409 707.036865,1162.935059 718.728149,1120.723877 
	C740.082275,1104.971802 760.732361,1090.255737 781.382446,1075.539673 
	C781.181213,1074.771118 780.980042,1074.002563 780.778809,1073.234009 
	C767.997009,1072.765137 755.213135,1071.990356 742.434998,1072.075073 
	C739.748108,1072.092773 736.593018,1074.813965 734.496033,1077.071899 
	C727.508728,1084.595581 721.106628,1092.664551 714.087769,1100.156982 
	C708.704590,1105.903442 702.728638,1111.094727 697.016235,1116.532959 
	C702.510986,1096.358154 707.945007,1076.166626 713.527466,1056.016235 
	C716.092163,1046.758667 718.947998,1037.581787 721.732605,1028.162842 
	C724.491211,1027.871826 726.738525,1027.440918 728.988586,1027.426147 
	C768.551331,1027.165405 808.114441,1026.953125 847.677490,1026.742554 
	C849.670105,1026.731934 852.050171,1026.178955 853.547974,1027.064941 
	C855.055298,1027.956665 855.685547,1030.330933 855.646240,1032.932373 
	C835.446533,1045.837158 816.344299,1057.935791 797.121094,1069.838867 
	C792.831299,1072.495117 790.874878,1076.020386 789.565796,1080.854736 
	C772.891479,1142.433472 755.972961,1203.945923 739.124512,1265.477539 
	C733.069580,1287.590454 726.853210,1309.661621 721.083252,1331.848877 
	C718.790344,1340.665527 718.970642,1349.407349 726.778748,1357.547363 
	C736.311646,1351.104492 745.310791,1344.954468 754.381531,1338.911987 
	C762.637512,1333.412109 770.971252,1328.028687 779.270691,1322.593994 
	C791.863037,1322.965454 804.454041,1323.393188 817.048279,1323.682617 
	C822.521362,1323.808472 823.036072,1319.383667 824.055481,1315.537720 
	C828.741089,1297.860474 833.415283,1280.180176 838.102173,1262.503296 
	C857.958618,1187.613159 877.813416,1112.722900 897.681641,1037.835938 
	C900.611816,1026.791382 900.643616,1026.757202 911.602234,1026.751465 
	C950.876709,1026.730591 990.151184,1026.735352 1029.425659,1026.752075 
	C1032.079834,1026.753174 1034.863770,1026.498901 1037.327026,1027.234741 
	C1038.370117,1027.546265 1038.739624,1030.113037 1038.380615,1032.277100 
	C1026.484009,1043.203003 1015.444946,1053.328857 1004.843079,1063.893555 
	C1000.297241,1068.423584 995.601440,1070.495361 989.053833,1070.520508 
	C974.801331,1070.575317 960.552429,1071.568726 945.824646,1072.198853 
	C944.780212,1075.593628 943.701538,1078.682495 942.877625,1081.837769 
	C922.552307,1159.677368 902.243530,1237.521362 881.960632,1315.371948 
	C878.385803,1329.092896 872.425781,1341.628052 863.459595,1352.628052 
	C857.612183,1359.802002 850.420532,1365.136841 840.899841,1366.040894 
	C832.628601,1366.826416 824.308105,1367.362305 816.002625,1367.464600 
	C795.024414,1367.722900 774.042175,1367.739868 753.062073,1367.686523 
	C749.459900,1367.677368 745.515076,1367.635010 742.324341,1366.242554 
	C733.239563,1362.278198 725.626038,1363.258179 719.213867,1371.097412 
	C718.806213,1371.595825 718.120483,1371.855835 717.619812,1372.290771 
	C706.352783,1382.079712 695.093323,1391.877441 683.832581,1401.673584 
	C683.832581,1401.673584 682.911011,1401.662842 682.301331,1401.208496 
	C681.231323,1400.379272 680.762512,1400.368408 680.285278,1400.721436 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M954.436829,1410.798706 
	C949.697571,1409.559326 951.753967,1406.088623 952.364014,1403.537354 
	C954.762512,1393.506348 957.446106,1383.543335 960.033630,1373.557617 
	C963.873352,1358.739502 967.946838,1343.976440 971.444336,1329.078003 
	C972.688171,1323.779541 975.246643,1321.891846 980.439819,1322.153687 
	C986.069519,1322.437378 991.724609,1322.217896 998.559204,1322.217896 
	C1015.873840,1255.606934 1033.091919,1189.367798 1050.988403,1121.933350 
	C1053.115479,1113.158936 1060.031982,1110.778809 1065.257202,1107.152954 
	C1080.025024,1096.905640 1095.189941,1087.231812 1110.146362,1077.253906 
	C1111.761719,1076.176270 1113.036621,1074.588257 1115.906494,1071.881714 
	C1102.098511,1072.547485 1090.480225,1073.389771 1078.853149,1073.537964 
	C1073.758057,1073.603027 1070.067749,1075.224731 1066.545166,1078.765503 
	C1057.149536,1088.209595 1047.574097,1097.482422 1037.844360,1106.581909 
	C1035.291870,1108.969238 1031.917114,1110.477295 1028.919922,1112.389160 
	C1036.395508,1084.514404 1043.871094,1056.639648 1051.425171,1028.472656 
	C1054.999023,1028.299072 1057.916382,1028.040283 1060.834106,1028.032959 
	C1131.033936,1027.857910 1201.234375,1027.803345 1271.433350,1027.483521 
	C1286.997681,1027.412598 1300.847900,1031.907959 1313.103638,1041.275513 
	C1280.587769,1059.900513 1270.823242,1066.905029 1266.415283,1075.088013 
	C1274.832275,1088.856323 1274.819336,1088.853760 1271.704346,1104.940918 
	C1271.009521,1108.529785 1270.353271,1112.130493 1269.828491,1115.747314 
	C1266.347168,1139.739014 1260.386230,1162.346558 1243.311646,1181.229004 
	C1221.694336,1205.135132 1196.755249,1220.576904 1163.513672,1220.107544 
	C1140.857544,1219.787720 1118.194214,1219.995605 1095.534058,1219.968628 
	C1092.310913,1219.964844 1089.087646,1219.968140 1085.422607,1219.968140 
	C1071.348022,1269.804443 1057.540894,1318.694214 1043.546997,1368.244995 
	C1039.618652,1368.244995 1036.339233,1368.245361 1033.059692,1368.244873 
	C1021.395386,1368.243530 1009.726013,1368.443237 998.069031,1368.150391 
	C992.694275,1368.015381 988.624634,1369.312866 984.950989,1373.559448 
	C976.017761,1383.885864 966.538879,1393.738647 957.501160,1403.977661 
	C955.925781,1405.762451 955.430786,1408.500732 954.436829,1410.798706 
M1155.510864,1176.249023 
	C1168.739624,1176.249023 1181.968384,1176.249023 1195.902466,1176.249023 
	C1199.338867,1162.413086 1202.945435,1148.264526 1206.330811,1134.062988 
	C1207.547119,1128.960815 1208.230957,1123.731934 1210.058960,1117.413818 
	C1225.844971,1104.099487 1241.631104,1090.785156 1258.895264,1076.224243 
	C1211.792725,1076.224243 1167.043457,1076.224243 1121.309692,1076.224243 
	C1109.545654,1122.096680 1096.923340,1167.525391 1086.648071,1213.922485 
	C1109.737549,1201.869141 1132.378662,1190.141479 1155.510864,1176.249023 
z"/>
                <path fill="#8690AD" opacity="1.000000" stroke="none"
                    d="
M318.003296,1564.512695 
	C330.094086,1552.813232 342.305756,1541.616943 354.943604,1530.924194 
	C357.471313,1528.785522 361.748871,1527.329102 365.008575,1527.656860 
	C395.834686,1530.755859 426.735138,1529.121704 457.596710,1529.638184 
	C474.179932,1529.915771 488.159546,1524.312866 499.370911,1511.975342 
	C509.132568,1501.233398 516.585510,1489.237183 520.241516,1475.021973 
	C537.463135,1408.061279 554.800110,1341.130127 571.988159,1274.160889 
	C585.539795,1221.359985 598.997437,1168.534912 612.352783,1115.684204 
	C615.536011,1103.087280 618.496155,1090.388428 612.787537,1077.574219 
	C610.906067,1073.350708 613.809387,1070.680908 616.815491,1068.376465 
	C629.484131,1058.665039 642.181519,1048.991089 655.349121,1038.880371 
	C662.822693,1040.046143 667.211853,1044.637939 670.392090,1050.794556 
	C678.846436,1067.161133 681.017883,1084.618164 676.809937,1102.207520 
	C669.852539,1131.289062 661.990723,1160.167480 653.922302,1188.966919 
	C636.883972,1249.783325 619.413818,1310.478516 602.219666,1371.251343 
	C588.644531,1419.233032 575.158752,1467.240112 561.754395,1515.269775 
	C556.764954,1533.147583 545.032715,1545.983398 531.178894,1557.336182 
	C514.526733,1570.982178 495.483978,1577.122192 473.914856,1576.557617 
	C430.338135,1575.416626 386.748810,1574.764526 343.167389,1573.789917 
	C334.032990,1573.585693 325.382263,1571.499023 318.003296,1564.512695 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M955.086365,1411.212402 
	C955.430786,1408.500732 955.925781,1405.762451 957.501160,1403.977661 
	C966.538879,1393.738647 976.017761,1383.885864 984.950989,1373.559448 
	C988.624634,1369.312866 992.694275,1368.015381 998.069031,1368.150391 
	C1009.726013,1368.443237 1021.395386,1368.243530 1033.059692,1368.244873 
	C1036.339233,1368.245361 1039.618652,1368.244995 1043.546997,1368.244995 
	C1057.540894,1318.694214 1071.348022,1269.804443 1085.422607,1219.968140 
	C1089.087646,1219.968140 1092.310913,1219.964844 1095.534058,1219.968628 
	C1118.194214,1219.995605 1140.857544,1219.787720 1163.513672,1220.107544 
	C1196.755249,1220.576904 1221.694336,1205.135132 1243.311646,1181.229004 
	C1260.386230,1162.346558 1266.347168,1139.739014 1269.828491,1115.747314 
	C1270.353271,1112.130493 1271.009521,1108.529785 1271.704346,1104.940918 
	C1274.819336,1088.853760 1274.832275,1088.856323 1266.415283,1075.088013 
	C1270.823242,1066.905029 1280.587769,1059.900513 1313.432007,1041.881836 
	C1322.971313,1044.902588 1326.322510,1052.746948 1329.647339,1060.229248 
	C1334.219238,1070.517822 1335.013550,1081.644775 1333.546631,1092.612549 
	C1329.448730,1123.253540 1320.473999,1152.355103 1304.748535,1179.198364 
	C1294.492798,1196.704956 1281.024658,1210.423462 1260.817261,1217.118164 
	C1262.463623,1221.256348 1266.299438,1222.037842 1269.244019,1223.662231 
	C1277.196533,1228.049194 1281.418945,1234.532104 1282.138184,1243.643677 
	C1283.462524,1260.417480 1281.387573,1276.837769 1277.396851,1293.055786 
	C1275.035645,1302.651611 1272.214844,1312.134155 1269.227661,1323.028687 
	C1278.697144,1323.593750 1286.798462,1324.077271 1295.292236,1324.584106 
	C1295.395386,1326.710571 1295.876709,1328.397705 1295.488403,1329.852783 
	C1288.793945,1354.925781 1281.999512,1379.972412 1275.185303,1405.013428 
	C1274.930420,1405.950195 1274.253052,1406.771851 1272.461182,1407.777344 
	C1265.103271,1396.422363 1258.967896,1384.982666 1253.062378,1373.425537 
	C1251.195190,1369.771484 1248.721436,1368.009277 1244.534790,1367.794678 
	C1236.569214,1367.386475 1228.627075,1366.512695 1220.677368,1365.806763 
	C1211.280151,1364.972412 1207.199097,1361.054321 1208.134888,1351.624878 
	C1208.880981,1344.107178 1210.757935,1336.532837 1213.288452,1329.393311 
	C1220.216797,1309.846680 1224.606445,1289.777954 1227.597900,1269.309814 
	C1229.793091,1254.291016 1225.814575,1242.452026 1212.018311,1234.658569 
	C1205.105103,1230.753052 1204.800171,1230.457397 1199.182983,1235.788696 
	C1193.140259,1241.524048 1187.476440,1247.683838 1181.181274,1253.118530 
	C1177.812500,1256.026978 1173.462524,1257.799072 1169.555298,1260.083862 
	C1156.296265,1260.921753 1143.037231,1261.759644 1129.164795,1262.636353 
	C1128.405151,1264.212646 1127.260132,1265.867432 1126.777100,1267.696899 
	C1115.138794,1311.769775 1103.572998,1355.861816 1091.986084,1399.948242 
	C1091.394043,1402.201050 1090.608521,1404.409424 1090.126953,1406.683594 
	C1089.377808,1410.222168 1087.186157,1411.698853 1083.720459,1411.627319 
	C1083.054321,1411.613525 1082.387329,1411.646118 1081.720703,1411.646118 
	C1039.725830,1411.640137 997.730835,1411.633057 955.086365,1411.212402 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M1367.277588,1115.248047 
	C1366.166870,1114.889648 1364.149536,1114.620850 1364.103760,1114.157471 
	C1363.914673,1112.241943 1364.072266,1110.203979 1364.548950,1108.324219 
	C1371.244385,1081.919312 1378.002441,1055.530273 1384.790161,1029.148804 
	C1385.196289,1027.570923 1385.982422,1026.090942 1386.733154,1024.211060 
	C1440.851807,1024.211060 1494.905884,1024.211060 1549.725098,1024.211060 
	C1549.154785,1026.723633 1548.741455,1028.544678 1547.317383,1031.133301 
	C1537.762085,1040.728027 1528.493408,1048.977905 1520.868408,1058.538452 
	C1512.640259,1068.855347 1503.287842,1073.405518 1489.992676,1071.972046 
	C1479.513916,1070.842285 1468.816162,1071.745117 1457.466064,1071.745117 
	C1431.574463,1168.666992 1405.883667,1264.837158 1379.687866,1362.898315 
	C1384.259033,1360.450562 1386.892090,1359.141235 1389.428955,1357.666504 
	C1409.283081,1346.123047 1429.118652,1334.547974 1448.959961,1322.982666 
	C1459.552979,1322.982666 1470.145996,1322.982666 1481.460938,1322.982666 
	C1486.826904,1301.293091 1491.993042,1280.411865 1497.270142,1259.081665 
	C1499.593140,1258.822876 1501.831299,1258.359741 1504.070190,1258.356445 
	C1533.703247,1258.312988 1563.336548,1258.267822 1592.968628,1258.438477 
	C1596.031982,1258.456055 1599.087524,1259.854492 1601.635498,1261.588867 
	C1583.023682,1273.763794 1564.946167,1284.999878 1546.802246,1296.128174 
	C1543.209595,1298.331787 1540.863403,1301.030273 1539.918091,1305.326538 
	C1538.205933,1313.107544 1535.995972,1320.779907 1533.957520,1328.487915 
	C1530.496460,1341.574219 1527.007202,1354.652954 1523.394653,1368.242798 
	C1518.948364,1368.242798 1515.332275,1368.243042 1511.716064,1368.242920 
	C1452.810791,1368.241943 1393.905640,1368.234619 1335.000366,1368.253052 
	C1330.747559,1368.254272 1326.470703,1367.604004 1322.962524,1371.384766 
	C1310.759888,1384.535767 1298.357910,1397.501831 1286.031738,1410.538330 
	C1285.053345,1410.401611 1284.074829,1410.264893 1282.856567,1410.094727 
	C1290.251953,1381.534912 1297.615967,1353.096069 1305.157104,1323.973633 
	C1314.360229,1323.973633 1322.909546,1323.973633 1331.853027,1323.973633 
	C1332.868896,1320.793457 1333.777466,1318.355591 1334.428955,1315.850830 
	C1351.600220,1249.821655 1368.744995,1183.785400 1386.490112,1116.655029 
	C1394.114624,1110.535645 1401.115845,1105.472656 1408.179932,1100.498779 
	C1414.988770,1095.704590 1421.965942,1091.143677 1428.678101,1086.219849 
	C1435.363770,1081.315186 1443.121094,1077.690186 1449.587158,1070.195068 
	C1441.664062,1070.195068 1435.261353,1070.872803 1429.053223,1070.061646 
	C1413.580078,1068.039917 1401.733398,1072.022949 1392.565674,1085.802979 
	C1385.453003,1096.493896 1375.800903,1105.495483 1367.277588,1115.248047 
z"/>
                <path fill="#868FAC" opacity="1.000000" stroke="none"
                    d="
M1784.922607,1410.139893 
	C1799.149658,1399.715332 1814.798218,1390.156006 1829.285034,1379.077637 
	C1841.734253,1369.557129 1854.998535,1367.407715 1870.204956,1367.504883 
	C1952.196777,1368.028564 2034.193726,1367.773804 2116.188965,1367.718872 
	C2130.060791,1367.709595 2143.287354,1364.821655 2155.377197,1357.703613 
	C2165.963379,1351.471069 2172.701904,1342.300659 2175.835205,1330.477905 
	C2193.904297,1262.294678 2211.946533,1194.104248 2230.018311,1125.921509 
	C2231.040771,1122.063110 2232.260742,1118.257446 2233.363037,1114.419678 
	C2237.095947,1101.423950 2238.919922,1088.399536 2233.258301,1074.718994 
	C2247.441406,1062.638550 2261.551514,1050.620239 2276.186523,1038.113037 
	C2290.926758,1045.557495 2294.287842,1059.321167 2295.694336,1073.845459 
	C2297.024902,1087.583496 2294.543213,1101.029053 2290.925781,1114.230469 
	C2275.463135,1170.658081 2259.879883,1227.052612 2244.418457,1283.480347 
	C2240.728516,1296.946655 2237.673096,1310.593262 2233.740967,1323.985352 
	C2225.419434,1352.325806 2211.872314,1377.403076 2187.744873,1395.713013 
	C2173.719727,1406.356323 2158.023193,1411.744873 2140.451904,1411.730835 
	C2069.518555,1411.674438 1998.584473,1411.735840 1927.651611,1411.497070 
	C1880.380249,1411.337891 1833.110352,1410.744873 1784.922607,1410.139893 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M684.228271,1402.322998 
	C695.093323,1391.877441 706.352783,1382.079712 717.619812,1372.290771 
	C718.120483,1371.855835 718.806213,1371.595825 719.213867,1371.097412 
	C725.626038,1363.258179 733.239563,1362.278198 742.324341,1366.242554 
	C745.515076,1367.635010 749.459900,1367.677368 753.062073,1367.686523 
	C774.042175,1367.739868 795.024414,1367.722900 816.002625,1367.464600 
	C824.308105,1367.362305 832.628601,1366.826416 840.899841,1366.040894 
	C850.420532,1365.136841 857.612183,1359.802002 863.459595,1352.628052 
	C872.425781,1341.628052 878.385803,1329.092896 881.960632,1315.371948 
	C902.243530,1237.521362 922.552307,1159.677368 942.877625,1081.837769 
	C943.701538,1078.682495 944.780212,1075.593628 945.824646,1072.198853 
	C960.552429,1071.568726 974.801331,1070.575317 989.053833,1070.520508 
	C995.601440,1070.495361 1000.297241,1068.423584 1004.843079,1063.893555 
	C1015.444946,1053.328857 1026.484009,1043.203003 1038.015137,1032.972656 
	C1036.376953,1042.392578 1034.135742,1051.756104 1031.747803,1061.082153 
	C1027.704590,1076.873047 1023.622009,1092.654297 1019.450317,1108.411621 
	C1017.120911,1117.210205 1016.124451,1117.861694 1007.067627,1117.741211 
	C989.948059,1117.513184 990.017273,1117.531738 985.619751,1134.016602 
	C970.359070,1191.223389 955.464111,1248.532837 939.544373,1305.556030 
	C933.295166,1327.940063 926.685608,1350.412842 915.659546,1371.129883 
	C912.544678,1376.982544 909.049011,1382.721313 905.049500,1387.997803 
	C892.377380,1404.716064 874.470825,1411.327026 854.369141,1411.573853 
	C827.741577,1411.900879 801.099915,1410.985962 774.463318,1410.692871 
	C758.472168,1410.516968 742.456543,1410.998169 726.494751,1410.258545 
	C715.261780,1409.738281 704.079224,1407.809814 692.923035,1406.176025 
	C690.061646,1405.757080 687.385376,1404.074463 684.228271,1402.322998 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M2198.271973,1573.229736 
	C2202.000977,1570.851196 2205.732422,1569.387573 2209.035400,1567.259155 
	C2226.667480,1555.897217 2244.284912,1544.507080 2261.695557,1532.811157 
	C2266.649170,1529.483643 2271.577148,1528.091431 2277.531250,1528.223267 
	C2314.815674,1529.050049 2352.114746,1529.303101 2389.392090,1530.351929 
	C2407.549805,1530.862793 2420.324707,1522.904175 2428.054443,1507.316040 
	C2432.740234,1497.866089 2436.198242,1487.613770 2438.818359,1477.366699 
	C2450.596436,1431.304810 2461.889404,1385.118774 2473.311035,1338.966064 
	C2474.188477,1335.419434 2474.664551,1331.775391 2475.400635,1328.191650 
	C2477.259521,1319.135864 2474.448486,1311.191895 2468.579834,1303.162476 
	C2483.584717,1291.925049 2498.168701,1281.002808 2513.422607,1269.865845 
	C2526.838867,1275.134399 2531.355713,1286.347656 2533.929688,1298.686401 
	C2536.567139,1311.329468 2534.934082,1323.913086 2531.653564,1336.165405 
	C2522.797363,1369.245483 2513.527832,1402.214844 2504.497803,1435.248901 
	C2498.888184,1455.770508 2493.580322,1476.375732 2487.827637,1496.856567 
	C2483.410156,1512.582397 2477.879395,1527.829956 2467.811035,1541.101440 
	C2459.186523,1552.469604 2448.341309,1560.874268 2435.982666,1567.797607 
	C2421.860107,1575.708984 2406.777344,1577.872314 2390.876709,1577.820679 
	C2329.605713,1577.621826 2268.333496,1577.746948 2207.061768,1577.783203 
	C2203.428711,1577.785278 2199.499512,1578.745239 2198.271973,1573.229736 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M1449.077759,1322.069458 
	C1429.118652,1334.547974 1409.283081,1346.123047 1389.428955,1357.666504 
	C1386.892090,1359.141235 1384.259033,1360.450562 1379.687866,1362.898315 
	C1405.883667,1264.837158 1431.574463,1168.666992 1457.466064,1071.745117 
	C1468.816162,1071.745117 1479.513916,1070.842285 1489.992676,1071.972046 
	C1503.287842,1073.405518 1512.640259,1068.855347 1520.868408,1058.538452 
	C1528.493408,1048.977905 1537.762085,1040.728027 1546.980469,1031.819824 
	C1547.209595,1034.641357 1547.044556,1037.620361 1546.276978,1040.435059 
	C1539.979736,1063.526611 1533.591797,1086.593628 1527.164673,1109.649536 
	C1526.463013,1112.166382 1525.340698,1114.566040 1524.069214,1117.931763 
	C1517.235596,1117.931763 1510.401367,1117.931763 1503.128784,1117.931763 
	C1498.329834,1135.765137 1493.747437,1152.733521 1489.198486,1169.710815 
	C1476.544189,1216.937866 1463.894165,1264.166016 1451.280640,1311.403931 
	C1450.424072,1314.611572 1449.883301,1317.903564 1449.077759,1322.069458 
z"/>
                <path fill="#8890AD" opacity="1.000000" stroke="none"
                    d="
M1286.721191,1410.972534 
	C1298.357910,1397.501831 1310.759888,1384.535767 1322.962524,1371.384766 
	C1326.470703,1367.604004 1330.747559,1368.254272 1335.000366,1368.253052 
	C1393.905640,1368.234619 1452.810791,1368.241943 1511.716064,1368.242920 
	C1515.332275,1368.243042 1518.948364,1368.242798 1523.394653,1368.242798 
	C1527.007202,1354.652954 1530.496460,1341.574219 1533.957520,1328.487915 
	C1535.995972,1320.779907 1538.205933,1313.107544 1539.918091,1305.326538 
	C1540.863403,1301.030273 1543.209595,1298.331787 1546.802246,1296.128174 
	C1564.946167,1284.999878 1583.023682,1273.763794 1601.813965,1262.423828 
	C1602.407349,1264.430176 1602.714111,1266.689331 1602.156128,1268.709839 
	C1589.963501,1312.869995 1577.692627,1357.008423 1565.391113,1401.138550 
	C1562.608398,1411.120972 1561.981445,1411.710327 1552.781738,1411.714233 
	C1465.313965,1411.751343 1377.846191,1411.734375 1290.378418,1411.717529 
	C1289.389160,1411.717407 1288.399780,1411.514893 1286.721191,1410.972534 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M779.438843,1321.756348 
	C770.971252,1328.028687 762.637512,1333.412109 754.381531,1338.911987 
	C745.310791,1344.954468 736.311646,1351.104492 726.778748,1357.547363 
	C718.970642,1349.407349 718.790344,1340.665527 721.083252,1331.848877 
	C726.853210,1309.661621 733.069580,1287.590454 739.124512,1265.477539 
	C755.972961,1203.945923 772.891479,1142.433472 789.565796,1080.854736 
	C790.874878,1076.020386 792.831299,1072.495117 797.121094,1069.838867 
	C816.344299,1057.935791 835.446533,1045.837158 855.317566,1033.729248 
	C854.983521,1039.158569 854.273315,1044.762451 852.812073,1050.163086 
	C834.649597,1117.289185 816.351013,1184.378662 798.149414,1251.494263 
	C791.880066,1274.611694 785.782898,1297.775879 779.438843,1321.756348 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M1850.049194,1360.044312 
	C1850.876465,1344.823730 1851.409058,1329.580078 1852.607178,1314.388672 
	C1854.042114,1296.194824 1856.110840,1278.051636 1857.787842,1259.875854 
	C1860.410645,1231.450317 1862.990356,1203.020630 1865.481079,1174.583252 
	C1867.103638,1156.059692 1868.493408,1137.515747 1870.066895,1118.987793 
	C1871.330933,1104.104492 1872.552124,1089.213379 1874.196411,1074.370361 
	C1874.487915,1071.739868 1876.148926,1068.529053 1878.217773,1066.944214 
	C1893.763794,1055.035278 1909.570435,1043.466675 1926.065430,1031.799072 
	C1927.079468,1039.629883 1927.852783,1047.481323 1927.443237,1055.270508 
	C1926.833496,1066.866089 1925.188599,1078.403076 1924.337036,1089.990845 
	C1922.024292,1121.462036 1919.970215,1152.952148 1917.676880,1184.424683 
	C1915.070312,1220.197388 1912.278198,1255.956543 1909.649780,1291.727783 
	C1908.920776,1301.650513 1908.487427,1311.595093 1908.058594,1322.399414 
	C1904.705322,1325.129639 1901.033203,1326.720459 1897.749390,1328.897095 
	C1882.235596,1339.180054 1866.827881,1349.623413 1850.693970,1360.007324 
	C1850.005493,1360.005005 1850.049194,1360.044312 1850.049194,1360.044312 
z"/>
                <path fill="#8791AE" opacity="1.000000" stroke="none"
                    d="
M2397.381104,1257.612427 
	C2394.239990,1259.587280 2390.919922,1260.211182 2388.334961,1261.936157 
	C2376.161133,1270.059448 2364.199463,1278.499512 2352.137939,1286.791626 
	C2349.154541,1288.842529 2346.081299,1290.762817 2343.119629,1292.697388 
	C2335.812500,1289.362915 2334.266602,1283.051758 2334.271729,1276.765747 
	C2334.277100,1270.205444 2335.127441,1263.464600 2336.796631,1257.115356 
	C2349.552002,1208.598999 2363.171143,1160.302124 2375.333984,1111.640381 
	C2382.413330,1083.317383 2400.176025,1070.795776 2426.674805,1070.618042 
	C2460.892090,1070.388550 2495.125732,1072.626709 2529.350586,1073.851562 
	C2529.825439,1073.868530 2530.277344,1074.528931 2531.565430,1075.531128 
	C2513.009521,1090.258057 2494.641113,1104.836182 2475.805908,1119.972412 
	C2460.769043,1120.530396 2446.198975,1120.530396 2430.714600,1120.530396 
	C2419.539551,1166.423950 2408.540283,1211.596436 2397.381104,1257.612427 
z"/>
                <path fill="#8690AD" opacity="1.000000" stroke="none"
                    d="
M1576.134521,1406.452637 
	C1588.835693,1395.693481 1601.671021,1385.086304 1614.158081,1374.084229 
	C1617.996338,1370.702515 1621.918701,1369.519531 1626.833130,1369.686523 
	C1634.486450,1369.946777 1642.204468,1369.212158 1649.803345,1369.905762 
	C1657.663330,1370.623169 1661.721558,1367.910522 1664.774780,1360.443970 
	C1673.211670,1339.811523 1682.552368,1319.547852 1691.581543,1299.158691 
	C1693.733276,1294.299927 1696.064331,1289.520386 1698.499268,1284.304810 
	C1747.055054,1284.304810 1795.470337,1284.304810 1843.855591,1284.304810 
	C1843.922119,1284.909790 1844.096436,1285.435303 1843.972656,1285.518921 
	C1825.789551,1297.792969 1807.584839,1310.035034 1788.645020,1322.289551 
	C1774.936401,1322.395264 1761.942383,1322.974609 1748.999512,1322.401001 
	C1741.323730,1322.060913 1737.602051,1325.060425 1734.980225,1331.890503 
	C1729.622803,1345.846924 1723.638428,1359.563232 1717.858154,1373.355835 
	C1717.097412,1375.171387 1716.019287,1376.854004 1714.080322,1379.631104 
	C1712.754272,1382.145142 1712.436035,1383.626343 1712.117798,1385.107544 
	C1712.117798,1385.107544 1712.387695,1385.226685 1711.802124,1385.779785 
	C1710.848511,1387.597290 1710.480469,1388.861816 1710.112549,1390.126221 
	C1710.112549,1390.126221 1710.030518,1390.004639 1709.542725,1390.301025 
	C1708.747437,1391.776978 1708.440063,1392.956543 1708.132690,1394.136230 
	C1708.132690,1394.136230 1708.022461,1394.023560 1707.518433,1394.405273 
	C1706.705933,1396.564453 1706.397461,1398.341675 1706.089111,1400.119019 
	C1706.089111,1400.119019 1706.027100,1399.996216 1705.517822,1400.274414 
	C1704.713379,1401.740479 1704.418213,1402.928467 1704.123047,1404.116455 
	C1704.123047,1404.116455 1704.013306,1404.023560 1703.518066,1404.299072 
	C1702.714111,1405.760132 1702.405518,1406.945679 1702.096924,1408.131104 
	C1702.096924,1408.131104 1702.004395,1408.027222 1701.286255,1408.337891 
	C1697.330566,1409.092773 1694.094116,1409.912842 1690.855103,1409.923462 
	C1655.012207,1410.040771 1619.168945,1410.035034 1583.325684,1410.052490 
	C1581.004639,1410.053589 1578.671997,1410.066162 1576.381592,1409.779297 
	C1576.163696,1409.751953 1576.210571,1407.611816 1576.134521,1406.452637 
z"/>
                <path fill="#FDFCFC" opacity="1.000000" stroke="none"
                    d="
M1169.995850,1260.730225 
	C1173.462524,1257.799072 1177.812500,1256.026978 1181.181274,1253.118530 
	C1187.476440,1247.683838 1193.140259,1241.524048 1199.182983,1235.788696 
	C1204.800171,1230.457397 1205.105103,1230.753052 1212.018311,1234.658569 
	C1225.814575,1242.452026 1229.793091,1254.291016 1227.597900,1269.309814 
	C1224.606445,1289.777954 1220.216797,1309.846680 1213.288452,1329.393311 
	C1210.757935,1336.532837 1208.880981,1344.107178 1208.134888,1351.624878 
	C1207.199097,1361.054321 1211.280151,1364.972412 1220.677368,1365.806763 
	C1228.627075,1366.512695 1236.569214,1367.386475 1244.534790,1367.794678 
	C1248.721436,1368.009277 1251.195190,1369.771484 1253.062378,1373.425537 
	C1258.967896,1384.982666 1265.103271,1396.422363 1271.856689,1408.198486 
	C1271.771484,1412.971313 1268.216919,1411.788452 1265.479736,1411.723877 
	C1252.195190,1411.410278 1238.914795,1410.914062 1225.632080,1410.515991 
	C1206.038208,1409.928955 1186.446655,1409.182861 1166.848145,1408.869141 
	C1155.087036,1408.680664 1143.922119,1400.411865 1143.562744,1386.549072 
	C1143.402710,1380.372437 1144.063477,1373.975342 1145.575928,1367.987549 
	C1153.950317,1334.833130 1162.751465,1301.786499 1171.211304,1268.653320 
	C1171.776855,1266.437988 1170.732300,1263.811646 1169.995850,1260.730225 
z"/>
                <path fill="#8791AE" opacity="1.000000" stroke="none"
                    d="
M2300.066406,1334.988525 
	C2302.886963,1330.976562 2305.706055,1327.306885 2309.139648,1324.351196 
	C2318.452148,1316.335205 2327.943848,1308.519897 2337.564697,1300.876709 
	C2339.427002,1299.397095 2342.258301,1298.181396 2344.536865,1298.342041 
	C2356.056885,1299.154053 2367.520508,1301.009399 2379.044678,1301.468384 
	C2397.302734,1302.195312 2415.596436,1301.963501 2433.868408,1302.419800 
	C2442.701660,1302.640381 2451.518799,1303.503174 2462.370361,1304.206665 
	C2452.190918,1312.776855 2443.545166,1320.299927 2434.625732,1327.483154 
	C2428.209961,1332.650024 2421.428467,1337.363403 2414.032227,1342.259888 
	C2382.740234,1342.247192 2352.226318,1342.297241 2321.712402,1342.230347 
	C2313.976562,1342.213379 2306.492676,1341.011963 2300.066406,1334.988525 
z"/>
                <path fill="#8890AD" opacity="1.000000" stroke="none"
                    d="
M2571.004395,1147.362793 
	C2568.513184,1144.933716 2567.045898,1141.916870 2564.765381,1139.759888 
	C2555.604492,1131.095947 2546.306152,1122.567749 2536.817139,1114.264893 
	C2532.892822,1110.831421 2531.379395,1107.400146 2532.795898,1102.113770 
	C2534.849609,1094.448608 2535.809082,1086.491821 2537.826660,1078.814819 
	C2538.595215,1075.890991 2540.372314,1072.481323 2542.755615,1070.851562 
	C2561.911621,1057.751587 2581.322021,1045.024414 2601.353516,1032.197998 
	C2599.350830,1043.786865 2596.881592,1055.420898 2593.900391,1066.922363 
	C2587.418457,1091.928589 2580.695068,1116.872192 2574.000732,1141.822754 
	C2573.502197,1143.681274 2572.449707,1145.391113 2571.004395,1147.362793 
z"/>
                <path fill="#8C91AC" opacity="1.000000" stroke="none"
                    d="
M2334.296143,1482.478027 
	C2316.871338,1495.247803 2299.387939,1507.281250 2281.850342,1519.235107 
	C2279.189941,1521.048462 2276.277832,1522.492554 2272.061523,1524.927979 
	C2271.513428,1513.481567 2274.485107,1504.034424 2276.868408,1494.668335 
	C2277.537598,1492.038574 2281.390381,1490.026123 2284.067139,1488.132202 
	C2298.721436,1477.763672 2313.516602,1467.593262 2328.132812,1457.171875 
	C2331.000000,1455.127441 2333.294678,1452.279785 2336.045898,1449.152344 
	C2337.589844,1449.465332 2340.067871,1450.404297 2340.089111,1451.395996 
	C2340.164307,1454.899780 2339.633301,1458.471436 2338.901123,1461.925903 
	C2337.496338,1468.555664 2335.797119,1475.122681 2334.296143,1482.478027 
z"/>
                <path fill="#8A93AF" opacity="1.000000" stroke="none"
                    d="
M696.873169,1117.110840 
	C702.728638,1111.094727 708.704590,1105.903442 714.087769,1100.156982 
	C721.106628,1092.664551 727.508728,1084.595581 734.496033,1077.071899 
	C736.593018,1074.813965 739.748108,1072.092773 742.434998,1072.075073 
	C755.213135,1071.990356 767.997009,1072.765137 780.778809,1073.234009 
	C780.980042,1074.002563 781.181213,1074.771118 781.382446,1075.539673 
	C760.732361,1090.255737 740.082275,1104.971802 718.798218,1119.942505 
	C713.040222,1120.047852 707.902100,1120.074341 702.799194,1119.663452 
	C700.736572,1119.497437 698.750854,1118.375610 696.873169,1117.110840 
z"/>
                <path fill="#8B93AE" opacity="1.000000" stroke="none"
                    d="
M1028.556396,1112.937866 
	C1031.917114,1110.477295 1035.291870,1108.969238 1037.844360,1106.581909 
	C1047.574097,1097.482422 1057.149536,1088.209595 1066.545166,1078.765503 
	C1070.067749,1075.224731 1073.758057,1073.603027 1078.853149,1073.537964 
	C1090.480225,1073.389771 1102.098511,1072.547485 1115.906494,1071.881714 
	C1113.036621,1074.588257 1111.761719,1076.176270 1110.146362,1077.253906 
	C1095.189941,1087.231812 1080.025024,1096.905640 1065.257202,1107.152954 
	C1060.031982,1110.778809 1053.115479,1113.158936 1051.217285,1121.132080 
	C1050.039185,1118.567749 1048.121216,1117.455444 1045.130127,1117.541382 
	C1041.275879,1117.652344 1037.411255,1117.585449 1033.556763,1117.443970 
	C1030.954834,1117.348511 1028.203369,1117.206543 1028.556396,1112.937866 
z"/>
                <path fill="#8B95B0" opacity="1.000000" stroke="none"
                    d="
M1368.189453,1115.544312 
	C1375.800903,1105.495483 1385.453003,1096.493896 1392.565674,1085.802979 
	C1401.733398,1072.022949 1413.580078,1068.039917 1429.053223,1070.061646 
	C1435.261353,1070.872803 1441.664062,1070.195068 1449.587158,1070.195068 
	C1443.121094,1077.690186 1435.363770,1081.315186 1428.678101,1086.219849 
	C1421.965942,1091.143677 1414.988770,1095.704590 1408.179932,1100.498779 
	C1401.115845,1105.472656 1394.114624,1110.535645 1386.463623,1115.872314 
	C1380.261475,1116.070068 1374.681274,1115.955322 1368.189453,1115.544312 
z"/>
                <path fill="#8A93AF" opacity="1.000000" stroke="none"
                    d="
M1995.927734,1113.228271 
	C2006.326050,1101.502808 2017.088379,1090.705444 2027.456421,1079.541870 
	C2031.383301,1075.313843 2035.503662,1073.407104 2041.327271,1073.622437 
	C2052.187012,1074.023926 2063.072021,1073.737793 2074.684570,1075.260864 
	C2069.321045,1079.363159 2064.010498,1083.536865 2058.583008,1087.552856 
	C2047.389648,1095.835205 2036.163086,1104.073853 2024.878174,1112.230469 
	C2022.771606,1113.753174 2020.327881,1114.809082 2017.360352,1116.076660 
	C2011.846680,1116.057007 2007.000488,1116.234131 2002.183960,1115.934570 
	C2000.135620,1115.807373 1998.149292,1114.682129 1995.927734,1113.228271 
z"/>
                <path fill="#CDB4BE" opacity="1.000000" stroke="none"
                    d="
M1575.510742,1406.322632 
	C1576.210571,1407.611816 1576.163696,1409.751953 1576.381592,1409.779297 
	C1578.671997,1410.066162 1581.004639,1410.053589 1583.325684,1410.052490 
	C1619.168945,1410.035034 1655.012207,1410.040771 1690.855103,1409.923462 
	C1694.094116,1409.912842 1697.330566,1409.092773 1701.355591,1408.344238 
	C1701.386230,1409.310059 1700.899780,1411.264893 1699.811035,1411.694824 
	C1698.058228,1412.386963 1695.908081,1412.172852 1693.926025,1412.170044 
	C1655.742310,1412.114990 1617.558350,1412.072754 1579.375122,1411.888672 
	C1576.318726,1411.873901 1571.124023,1413.833008 1573.082520,1406.520508 
	C1574.048584,1406.056274 1574.467773,1406.124390 1575.510742,1406.322632 
z"/>
                <path fill="#C3A2AD" opacity="1.000000" stroke="none"
                    d="
M1706.664917,1399.732422 
	C1706.397461,1398.341675 1706.705933,1396.564453 1707.596924,1394.412842 
	C1707.866455,1395.807739 1707.553589,1397.576782 1706.664917,1399.732422 
z"/>
                <path fill="#BB9AA7" opacity="1.000000" stroke="none"
                    d="
M1710.706543,1389.792969 
	C1710.480469,1388.861816 1710.848511,1387.597290 1711.777832,1385.925293 
	C1711.992798,1386.831787 1711.646729,1388.145752 1710.706543,1389.792969 
z"/>
                <path fill="#BB9AA7" opacity="1.000000" stroke="none"
                    d="
M1712.735718,1384.631592 
	C1712.436035,1383.626343 1712.754272,1382.145142 1713.670410,1380.164307 
	C1713.963501,1381.161621 1713.658569,1382.658691 1712.735718,1384.631592 
z"/>
                <path fill="#C3A2AD" opacity="1.000000" stroke="none"
                    d="
M1708.752686,1393.936523 
	C1708.440063,1392.956543 1708.747437,1391.776978 1709.615234,1390.310059 
	C1709.908203,1391.260742 1709.640503,1392.498779 1708.752686,1393.936523 
z"/>
                <path fill="#CDB4BE" opacity="1.000000" stroke="none"
                    d="
M1702.748291,1407.972168 
	C1702.405518,1406.945679 1702.714111,1405.760132 1703.589722,1404.294922 
	C1703.904419,1405.281128 1703.652100,1406.547241 1702.748291,1407.972168 
z"/>
                <path fill="#C3A2AD" opacity="1.000000" stroke="none"
                    d="
M1704.728027,1403.900024 
	C1704.418213,1402.928467 1704.713379,1401.740479 1705.585205,1400.288086 
	C1705.885742,1401.243652 1705.609375,1402.463623 1704.728027,1403.900024 
z"/>
                <path fill="#8C91AC" opacity="1.000000" stroke="none"
                    d="
M2377.638672,1482.715576 
	C2377.776855,1483.550049 2377.349365,1483.822266 2376.355713,1483.517822 
	C2376.251221,1482.720215 2376.678955,1482.457642 2377.638672,1482.715576 
z"/>
                <path fill="#101730" opacity="1.000000" stroke="none"
                    d="
M917.984985,1409.981201 
	C918.226807,1409.935791 918.488464,1409.913086 918.400696,1409.967163 
	C918.051331,1410.043945 918.004822,1410.003906 917.984985,1409.981201 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M680.428467,1401.079590 
	C680.762512,1400.368408 681.231323,1400.379272 681.854553,1401.095703 
	C682.017456,1401.437012 681.294250,1401.715454 681.294250,1401.715454 
	C681.294250,1401.715454 680.571655,1401.437866 680.428467,1401.079590 
z"/>
                <path fill="#EA453F" opacity="1.000000" stroke="none"
                    d="
M1093.949097,1677.958984 
	C1099.378784,1674.270752 1104.537231,1670.080200 1110.282715,1666.976318 
	C1145.780884,1647.799683 1182.759399,1632.004761 1221.093628,1619.418335 
	C1262.148315,1605.938965 1303.961548,1595.426514 1346.586060,1588.339600 
	C1386.750488,1581.661499 1427.354370,1579.743286 1467.834229,1582.537354 
	C1495.504517,1584.447388 1523.009399,1589.713379 1550.392212,1594.582031 
	C1582.812500,1600.346191 1614.335449,1609.840332 1645.356445,1620.808594 
	C1686.524536,1635.364624 1726.132080,1653.292725 1763.746460,1675.664429 
	C1800.471313,1697.507202 1834.756470,1722.349365 1865.594849,1752.075562 
	C1879.494873,1765.474365 1892.670654,1779.421753 1905.100098,1794.130615 
	C1907.215942,1796.634399 1908.924561,1799.482178 1911.519531,1803.160767 
	C1908.907471,1803.551758 1907.168823,1804.036865 1905.429688,1804.039185 
	C1873.111084,1804.082031 1840.791504,1803.943359 1808.474731,1804.183960 
	C1802.940063,1804.225098 1799.109619,1802.356079 1795.408447,1798.498779 
	C1786.183105,1788.884644 1776.793335,1779.415771 1767.190430,1770.178955 
	C1740.243774,1744.259888 1710.186157,1722.277466 1678.591431,1702.525879 
	C1638.133911,1677.233765 1595.111084,1657.135254 1549.495972,1642.909180 
	C1521.593994,1634.207520 1493.186890,1627.795532 1464.166992,1623.721802 
	C1432.484375,1619.274048 1400.725464,1617.342773 1368.815552,1618.240479 
	C1353.592529,1618.668579 1338.400513,1620.402588 1323.209717,1621.721558 
	C1307.353882,1623.098511 1291.372192,1623.810181 1275.703857,1626.384033 
	C1250.534058,1630.518555 1225.410767,1635.231445 1200.569580,1640.999268 
	C1166.175049,1648.985718 1132.845215,1660.366089 1101.202759,1676.309326 
	C1099.460449,1677.187256 1097.447754,1677.528320 1094.782104,1678.062378 
	C1094.003418,1678.005493 1093.949097,1677.958984 1093.949097,1677.958984 
z"/>
                <path fill="#EA453F" opacity="1.000000" stroke="none"
                    d="
M1094.026855,1678.032959 
	C1093.797485,1678.104248 1093.544556,1678.148193 1093.620361,1678.075439 
	C1093.949097,1677.958984 1094.003418,1678.005493 1094.026855,1678.032959 
z"/>
                <path fill="#EA453F" opacity="1.000000" stroke="none"
                    d="
M1088.867920,1681.122681 
	C1088.752686,1680.962769 1089.200928,1680.911133 1089.200928,1680.911133 
	C1089.200928,1680.911133 1088.983154,1681.282593 1088.867920,1681.122681 
z"/>
                <path fill="#651516" opacity="1.000000" stroke="none"
                    d="
M1782.902344,2124.891113 
	C1783.030518,2124.813965 1782.774292,2124.968262 1782.902344,2124.891113 
z"/>
                <path fill="#8790AE" opacity="1.000000" stroke="none"
                    d="
M2077.918457,1323.429199 
	C2055.759033,1335.370972 2033.599731,1347.312622 2009.596436,1360.248047 
	C2035.517334,1263.967773 2060.904785,1169.669067 2086.624268,1074.136719 
	C2130.190186,1074.768066 2173.535645,1075.396240 2216.880859,1076.024414 
	C2217.222412,1076.658081 2217.563721,1077.291626 2217.905273,1077.925293 
	C2203.410156,1091.892456 2188.915039,1105.859619 2172.788086,1119.881714 
	C2158.258789,1119.936646 2145.361328,1119.936646 2132.353271,1119.936646 
	C2131.334961,1122.922852 2130.478516,1125.047363 2129.881104,1127.242432 
	C2115.241699,1181.038696 2100.558105,1234.823364 2086.070312,1288.660767 
	C2082.978516,1300.149414 2080.616455,1311.834595 2077.918457,1323.429199 
z"/>
                <path fill="#8791AD" opacity="1.000000" stroke="none"
                    d="
M1770.573242,1245.137207 
	C1748.535522,1256.033325 1726.497925,1266.929443 1704.257690,1277.925781 
	C1704.907837,1270.045044 1778.274658,1099.497070 1790.887817,1076.352295 
	C1813.056885,1076.352295 1835.574707,1076.352295 1859.875732,1076.352295 
	C1853.995728,1080.855835 1848.414307,1083.512085 1842.864624,1085.600098 
	C1833.341064,1089.183105 1827.633667,1095.386719 1825.035522,1105.223267 
	C1823.519409,1110.962891 1820.842407,1116.430298 1818.361938,1121.871582 
	C1815.854980,1127.371094 1814.578735,1133.008789 1813.974121,1139.876953 
	C1803.237793,1163.715454 1793.418823,1186.651123 1783.950684,1209.730713 
	C1779.164062,1221.398438 1775.012085,1233.326660 1770.573242,1245.137207 
z"/>
                <path fill="#972324" opacity="1.000000" stroke="none"
                    d="
M2078.869141,1323.532715 
	C2080.616455,1311.834595 2082.978516,1300.149414 2086.070312,1288.660767 
	C2100.558105,1234.823364 2115.241699,1181.038696 2129.881104,1127.242432 
	C2130.478516,1125.047363 2131.334961,1122.922852 2132.353271,1119.936646 
	C2145.361328,1119.936646 2158.258789,1119.936646 2172.177246,1120.005127 
	C2165.403320,1149.599121 2157.688477,1179.146240 2149.791748,1208.644653 
	C2140.519775,1243.280884 2131.089600,1277.875000 2121.728027,1312.487305 
	C2118.710693,1323.642090 2118.706055,1323.660156 2106.736816,1323.673828 
	C2097.764648,1323.683960 2088.792480,1323.650146 2078.869141,1323.532715 
z"/>
                <path fill="#972627" opacity="1.000000" stroke="none"
                    d="
M1771.462280,1245.238159 
	C1775.012085,1233.326660 1779.164062,1221.398438 1783.950684,1209.730713 
	C1793.418823,1186.651123 1803.237793,1163.715454 1813.474854,1140.300659 
	C1812.537720,1155.918213 1811.139404,1171.965698 1809.509277,1187.989746 
	C1807.734009,1205.440063 1805.753052,1222.869629 1803.829102,1240.304565 
	C1803.687622,1241.587158 1803.283447,1242.840698 1802.727295,1245.339233 
	C1792.663696,1245.339233 1782.507568,1245.339233 1771.462280,1245.238159 
z"/>
                <path fill="#8790AD" opacity="1.000000" stroke="none"
                    d="
M1849.987061,1359.983154 
	C1849.784912,1359.990723 1849.601196,1360.020264 1849.733398,1360.047119 
	C1850.049194,1360.044312 1850.005493,1360.005005 1849.987061,1359.983154 
z"/>
                <path fill="#8690AE" opacity="1.000000" stroke="none"
                    d="
M420.359467,1484.955811 
	C410.242065,1493.048462 400.122162,1501.137939 390.008240,1509.235107 
	C385.329285,1512.981079 380.737488,1516.840576 375.972290,1520.472900 
	C368.412170,1526.235840 365.305115,1525.770386 359.607391,1517.939331 
	C352.060516,1507.567139 352.481995,1496.015503 355.579407,1484.451660 
	C362.809204,1457.459839 370.445251,1430.577271 377.800385,1403.618652 
	C401.266846,1317.607910 424.687866,1231.584839 448.104462,1145.560425 
	C451.074738,1134.648682 453.150574,1123.419678 457.048126,1112.856934 
	C460.347076,1103.916626 464.767731,1095.064209 470.343811,1087.369995 
	C477.852905,1077.008179 488.118530,1071.515991 502.008148,1071.889893 
	C531.969788,1072.696167 561.967468,1072.221313 591.950073,1072.138672 
	C597.255676,1072.123901 601.913574,1072.735840 604.678589,1079.479492 
	C600.229980,1083.338867 595.485962,1087.346436 590.861694,1091.487915 
	C579.988220,1101.226196 569.172058,1111.028564 557.250977,1121.367920 
	C543.300720,1121.244385 530.432007,1120.557861 517.514893,1119.868896 
	C516.593506,1122.692993 516.027100,1124.201782 515.606812,1125.750244 
	C501.935791,1176.117798 488.282562,1226.490234 474.618927,1276.859863 
	C456.691620,1342.947021 438.730133,1409.024902 420.913879,1475.141968 
	C420.079590,1478.238159 420.515259,1481.676758 420.359467,1484.955811 
z"/>
                <path fill="#972224" opacity="1.000000" stroke="none"
                    d="
M421.237305,1485.264282 
	C420.515259,1481.676758 420.079590,1478.238159 420.913879,1475.141968 
	C438.730133,1409.024902 456.691620,1342.947021 474.618927,1276.859863 
	C488.282562,1226.490234 501.935791,1176.117798 515.606812,1125.750244 
	C516.027100,1124.201782 516.593506,1122.692993 517.514893,1119.868896 
	C530.432007,1120.557861 543.300720,1121.244385 557.007324,1122.001221 
	C553.022095,1140.358887 548.389893,1158.698730 543.344055,1176.924194 
	C515.680908,1276.842285 487.919189,1376.733154 460.174408,1476.628662 
	C459.299774,1479.777954 458.241699,1482.876221 457.401672,1485.572754 
	C445.225769,1485.572754 433.670441,1485.572754 421.237305,1485.264282 
z"/>
                <path fill="#8691AE" opacity="1.000000" stroke="none"
                    d="
M1153.636230,1175.974243 
	C1132.378662,1190.141479 1109.737549,1201.869141 1086.648071,1213.922485 
	C1096.923340,1167.525391 1109.545654,1122.096680 1121.309692,1076.224243 
	C1167.043457,1076.224243 1211.792725,1076.224243 1258.895264,1076.224243 
	C1241.631104,1090.785156 1225.844971,1104.099487 1208.731079,1118.042236 
	C1194.551880,1118.670654 1181.700562,1118.670654 1167.928101,1118.670654 
	C1163.083618,1138.094849 1158.359985,1157.034546 1153.636230,1175.974243 
z"/>
                <path fill="#982526" opacity="1.000000" stroke="none"
                    d="
M1154.573486,1176.111572 
	C1158.359985,1157.034546 1163.083618,1138.094849 1167.928101,1118.670654 
	C1181.700562,1118.670654 1194.551880,1118.670654 1208.280518,1118.615723 
	C1208.230957,1123.731934 1207.547119,1128.960815 1206.330811,1134.062988 
	C1202.945435,1148.264526 1199.338867,1162.413086 1195.902466,1176.249023 
	C1181.968384,1176.249023 1168.739624,1176.249023 1154.573486,1176.111572 
z"/>
            </svg>
        </Icon>
    )
}

export default React.forwardRef(OurladsIcon);