import React from 'react';
import Icon from '../atoms/Icon';

function CheckIcon (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fill='currentColor'
        fillRule='nonzero'
        d='M28.814 5L12.675 20.821l-9.489-9.302L0 14.642l11.082 10.864L12.675 27l1.593-1.494L32 8.123z'
      />
    </Icon>
  );
}

export default React.forwardRef(CheckIcon);