export enum Sport {
  Baseball = 'Baseball',
  Basketball = 'Basketball',
  Golf = 'Golf',
  Hockey = 'Hockey',
  Lacrosse = 'Lacrosse',
  Powerlifting = 'Powerlifting',
  Rugby = 'Rugby',
  Soccer = 'Soccer',
  Swimming = 'Swimming',
  Tennis = 'Tennis',
  Track = 'Track',
  Volleyball = 'Volleyball',
  Wrestling = 'Wrestling',
}
